import { Table } from "antd";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { DEFAULT_SORTER } from "../../utils/constants";
import { getSessionStorage, getSorterFilter, getSorterObj, searchQueryGenerator } from "../../utils/utils";
import Pagination from "../Pagination/Pagination";
import "./SearchListView.scss";

const SearchListView = props => {
  const {
    columns,
    dataSource,
    getList,
    loading,
    pagination,
    rowKey,
    scroll,
    size,
    filterCriteria,
    setSortInfo,
    setFilterInfo,
    preventInitialFetchList,
    defaultSort,
    clearStore,
    retainFilers,
    paginationKey,
    rowSelection,
  } = props;

  // For api query
  const [sorterFilterPage, setSorterFilterPage] = useState(() => {
    let cPage = _get(pagination, "number");
    cPage = _isNumber(cPage) ? cPage + 1 : 1;
    return {
      currentPage: cPage,
      pageSize: _get(pagination, "size", 20),
      sorter: defaultSort || DEFAULT_SORTER,
      filters: {},
      filterCriteria,
    };
  });

  useEffect(() => {
    if (!preventInitialFetchList) {
      let _query;
      if (!_isEmpty(pagination) && retainFilers) {
        _query = pagination;
      } else if (paginationKey && !_isEmpty(getSessionStorage(paginationKey))) {
        _query = getSessionStorage(paginationKey);
      } else {
        _query = searchQueryGenerator(sorterFilterPage);
      }
      getList(_query);
    }
  }, [preventInitialFetchList]);

  useEffect(() => () => clearStore && clearStore(), []);

  useEffect(() => {
    if (!_isEmpty(pagination) && (!_isEmpty(pagination.keyword) || !_isEmpty(pagination.filterCriteria?.id))) {
      setSortInfo(null);
    } else {
      let sorter = defaultSort || DEFAULT_SORTER;
      if (!_isEmpty(pagination.sort)) {
        sorter = getSorterObj(pagination.sort);
      }
      updateSortInfo(sorter);
    }
  }, [pagination]);

  useEffect(() => {
    if (!_isEmpty(pagination) && !_isEmpty(pagination.sort)) {
      const sorter = getSorterObj(pagination.sort);
      updateSortInfo(sorter);
    }
  }, [pagination]);

  const _rowSelection = !_isEmpty(rowSelection) ? rowSelection : false;

  const updateSortInfo = sorter => {
    const _sortInfo =
      _isArray(sorter) && sorter.length > 1
        ? {
          ...sorter[sorter.length - 1],
        }
        : {
          ...sorter,
        };
    setSortInfo({ ..._sortInfo });
  };

  const handleTableChange = async (pageInfo, filters, sorter) => {
    if (_isEmpty(sorter?.order)) {
      sorter = defaultSort || DEFAULT_SORTER;
    }
    const payload = {
      ...pagination,
      currentPage: pagination.page + 1,
      pageSize: pagination.size,
      sorter,
      filters,
      keyword: pagination.keyword,
      filterCriteria: pagination.filterCriteria,
    };

    setSorterFilterPage(getSorterFilter(sorter, payload));
    const query = searchQueryGenerator(payload);
    await getList(query);
    setFilterInfo(filters);
  };

  const _dataSource = _isArray(dataSource) ? dataSource : [];

  return (
    <Fragment>
      <div className="search-list-view">
        <Table
          showSorterTooltip={false}
          dataSource={_dataSource}
          columns={columns}
          size={size}
          onChange={handleTableChange}
          pagination={false}
          loading={loading}
          rowKey={rowKey || columns[0].key}
          scroll={scroll}
          rowSelection={_rowSelection}
        ></Table>
        {!_isEmpty(dataSource) && <Pagination pagination={pagination} getList={getList} />}
      </div>
    </Fragment>
  );
};

SearchListView.defaultProps = {
  dataSource: [],
  rowSelection: {},
  size: "middle",
  pagination: {},
  scroll: null,
  preventInitialFetchList: false,
  retainFilers: true,
};

SearchListView.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  getList: PropTypes.func,
  rowSelection: PropTypes.shape({
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
  }),
  size: PropTypes.string,
  rowKey: PropTypes.string,
  pagination: PropTypes.object,
  columns: PropTypes.array.isRequired,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.string,
  }),
  filterCriteria: PropTypes.object,
  setFilterInfo: PropTypes.func,
  setSortInfo: PropTypes.func,
  preventInitialFetchList: PropTypes.bool,
  defaultSort: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  clearStore: PropTypes.func,
  retainFilers: PropTypes.bool,
  paginationKey: PropTypes.string,
};

export default SearchListView;
