import PropTypes from "prop-types";
import React from "react";
import CustomIcon from "../../components/CustomIcon/CustomIcon";

const PDFIcon = ({ className, item, isPDF, size }) => {
  if (true || !isPDF) return null;
  return (
    <CustomIcon
      onClick={e => {
        e.stopPropagation();
        window.open(item.url);
      }}
      name="ExportOutlined"
      size={size || 15}
      style={{ fontSize: 35 }}
      className={`external-link ${className}`}
      //   style={{
      //     fontSize: "20px",
      //     color: "#5C5E5D",
      //     position: "absolute",
      //     bottom: 30,
      //     right: 30,
      //     cursor: "pointer",
      //   }}
    />
  );
};

PDFIcon.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  isPDF: PropTypes.bool,
  size: PropTypes.number,
};

export default PDFIcon;
