import { Button, Card, Col, Divider, Dropdown, Input, Menu, Row, Select, Space, Tooltip, Typography } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _map from "lodash/map";
import { addAuditIssueComment } from "../../store/actions/property.actions";
import {
  AUDIT_LANGUAGE_NAME_LOOKUP,
  AUDIT_STATUS,
  AUDIT_STATUS_UI_MAPPING,
  BUILDING_TABS_KEYS as _tabsKey,
  BUILDING_TABS_LOOKUP as _tabs,
  FORM_FIELDS_LOOKUP,
  PROPERTY_TYPE_KEYS,
  LAND_TABS_LOOKUP as _landTabs,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { renderAuditStatus } from "../../utils/property.util";
import { getFieldLabel, replaceIfNull } from "../../utils/utils";
import AvatarWithComment from "../AvatarWithComment/AvatarWithComment";
import CustomIcon from "../CustomIcon/CustomIcon";
import PopUp from "../PopUp/PopUp";
import AppModal from "../AppModal/AppModal";
import FilePreview from "../FilePreview";

const { Text } = Typography;

const AuditIssueItem = ({
  viewOnlyMode,
  item,
  resolveAuditIssue,
  navigateToIssueTab,
  setAuditToEdit,
  setAuditToDelete,
  auditStatusChanged,
}) => {
  const {
    id,
    field,
    propertyType,
    status,
    auditor,
    createdOn,
    issue,
    attachments,
    unitCode,
    language,
    comments,
    lastModifiedBy,
  } = item;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auditLoading } = useSelector(state => ({
    ...state.property,
  }));

  const [visibleTextArea, setVisibleTextArea] = useState(false);
  const [visibleComments, setVisibleComments] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState(null);

  const canAuditProperty = permissionsUtil.checkAuth("audit_add");
  const canUpdateAuditStatus = permissionsUtil.checkAuth("audit_status_edit");
  const canUpdateAudit = permissionsUtil.checkAuth("audit_edit");
  const canDeleteAudit = permissionsUtil.checkAuth("audit_delete");
  const disabledResolveLink = status !== AUDIT_STATUS.ready_for_review;
  const selectedStatus = { value: status, label: AUDIT_STATUS_UI_MAPPING[status] };

  const onVisibleTextArea = () => {
    setVisibleTextArea(true);
  };

  const onHideTextArea = () => {
    setVisibleTextArea(false);
  };

  const onToggleVisibleComments = () => {
    setVisibleComments(!visibleComments);
    if (visibleComments) {
      setVisibleTextArea(false);
    }
  };

  const onCommentChange = e => {
    setComment(e.target.value);
  };

  const issueItem = {
    user: auditor,
    commentedAt: createdOn,
    comment: issue,
  };

  const addComment = async () => {
    try {
      await dispatch(addAuditIssueComment(id, { comment }));
      setComment("");
      setVisibleTextArea("");
    } catch (ex) {
      console.log("ex: ", ex);
    }
  };

  const onChangeTogglePopupVisibility = () => {
    togglePopupVisibility(!popupVisible);
  };

  const toggleStatusDropdowbOpen = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const getMenu = (_status, _id, _attachments, _issue) => {
    const canEdit = canUpdateAudit && _status === AUDIT_STATUS.audit_issue;
    const canDelete = canDeleteAudit && _status !== AUDIT_STATUS.audit_passed;

    const setAuditToEditHandler = () => {
      setAuditToEdit({
        attachments: _attachments,
        issue: _issue,
        id: _id,
      });
    };

    const setAuditToDeleteHandler = () => {
      setAuditToDelete(_id);
    };

    return (
      <Menu>
        {canEdit && (
          <Menu.Item key="edit" onClick={setAuditToEditHandler}>
            {t("Buttons.Edit")}
          </Menu.Item>
        )}
        {canDelete && (
          <Menu.Item key="delete" onClick={setAuditToDeleteHandler}>
            {t("Buttons.Delete")}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const getAuditStatusOptions = () =>
    _map(
      Object.keys(AUDIT_STATUS_UI_MAPPING).filter(
        audit => audit !== AUDIT_STATUS.audit_passed && audit !== AUDIT_STATUS.audit_issue,
      ),
      auditStatus => ({ value: auditStatus, label: AUDIT_STATUS_UI_MAPPING[auditStatus] }),
    );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const previewFile = _item => {
    setIsModalOpen(true);
    setPreviewItem(_item);
  };

  const collapsableIcon = visibleComments ? "UpOutlined" : "DownOutlined";
  const dropdownIcon = statusDropdownOpen ? "UpOutlined" : "DownOutlined";
  const _propertyType = propertyType === PROPERTY_TYPE_KEYS.land ? _landTabs : _tabs;

  return (
    <>
      <Col span={24}>
        <Card
          bordered={false}
          title={
            <>
              <div>
                <Text type="success">{id}</Text>
              </div>
              <div>
                <Button type="link" onClick={navigateToIssueTab}>
                  {_propertyType[field.tab].tabTitle}
                  :&nbsp;
                  {getFieldLabel(propertyType, field, FORM_FIELDS_LOOKUP, _tabsKey)}
                </Button>
              </div>
            </>
          }
          extra={
            !viewOnlyMode &&
            canAuditProperty &&
            status !== AUDIT_STATUS.audit_passed && (
              <Dropdown trigger="click" overlay={getMenu(status, id, attachments, issue)} placement="bottomRight">
                <CustomIcon name="MoreOutlined" />
              </Dropdown>
            )
          }
        >
          <Row>
            <AvatarWithComment
              viewOnlyMode={viewOnlyMode}
              hideButton
              hideDivider={false}
              item={issueItem}
              onVisibleTextArea={onVisibleTextArea}
            />
            {attachments?.length > 0 && (
              <>
                <Col sm={6}>
                  <div className="font-12">
                    <span className="font-grey">
                      {t("AddEditProperty.Audit.Attachment")}
                      :&nbsp;
                    </span>
                  </div>
                </Col>
                <Col sm={18}>
                  {attachments.map(_item => {
                    const onClickPreviewItem = () => previewFile(_item);
                    return (
                      <Tooltip key={_item.originalFileName} title={_item.originalFileName}>
                        <Button type="link" className="attachment-file" onDoubleClick={onClickPreviewItem}>
                          <span>{_item.originalFileName}</span>
                        </Button>
                      </Tooltip>
                    );
                  })}
                </Col>
              </>
            )}
            <Col sm={24}>
              <div className="font-12">
                <span className="font-grey">
                  {t("AddEditProperty.Audit.LastEditedBy")}
                  :&nbsp;
                </span>
                {replaceIfNull(lastModifiedBy?.id)}
              </div>
              {field.tab === "unitInfo" && (
                <div className="font-12">
                  <span className="font-grey">
                    {t("AddEditProperty.Audit.UnitCode")}
                    :&nbsp;
                  </span>
                  {unitCode}
                </div>
              )}
              <div className="font-12">
                <span className="font-grey">
                  {t("AddEditProperty.Audit.Language")}
                  :&nbsp;
                </span>
                {AUDIT_LANGUAGE_NAME_LOOKUP[language]}
              </div>
              <div className="font-12">
                <span className="font-grey">
                  {t("AuditReportTable.Status")}
                  :&nbsp;
                </span>
                {viewOnlyMode || !canUpdateAuditStatus || status === AUDIT_STATUS.audit_passed ? (
                  renderAuditStatus(status)
                ) : (
                  <Select
                    open={statusDropdownOpen}
                    bordered={false}
                    className={`width-162 ${status}-color`}
                    size="small"
                    value={selectedStatus}
                    labelInValue
                    placeholder={t("AuditReportTable.Status")}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={(selectedOption, option) => auditStatusChanged(selectedOption.value, id)}
                    options={getAuditStatusOptions()}
                    suffixIcon={<CustomIcon name={dropdownIcon} onClick={toggleStatusDropdowbOpen} />}
                    onDropdownVisibleChange={toggleStatusDropdowbOpen}
                  />
                )}
              </div>
            </Col>
            {!viewOnlyMode && <Divider />}
            {(!_isEmpty(comments) || !viewOnlyMode) && (
              <Col span={24} className="text-end">
                <Space>
                  {!viewOnlyMode && canAuditProperty && (
                    <PopUp
                      title={t("Constants.ConfirmMessages.ResolveAuditIssue")}
                      placement="topRight"
                      onConfirm={resolveAuditIssue}
                      okText={t("Buttons.Yes")}
                      cancelText={t("Buttons.No")}
                      popupVisible={popupVisible}
                      onVisibleChange={onChangeTogglePopupVisibility}
                    >
                      <Button type="link" disabled={disabledResolveLink}>
                        {t("AddEditProperty.Audit.ResolveLink")}
                      </Button>
                    </PopUp>
                  )}
                  {!_isEmpty(comments) ? (
                    <Button type="link" onClick={onToggleVisibleComments}>
                      {comments.length}
                      &nbsp;
                      {t("AddEditProperty.Audit.ReplyLink")}
                      <CustomIcon name={collapsableIcon} />
                    </Button>
                  ) : (
                    <>
                      {!viewOnlyMode && (
                        <Button type="link" onClick={onVisibleTextArea}>
                          {t("AddEditProperty.Audit.ReplyLink")}
                        </Button>
                      )}
                    </>
                  )}
                </Space>
              </Col>
            )}
            {!_isEmpty(comments) &&
              visibleComments &&
              comments.map((_item, index) => (
                <AvatarWithComment
                  viewOnlyMode={viewOnlyMode}
                  hideDivider
                  key={_item.id}
                  hideButton={index !== comments.length - 1}
                  item={_item}
                  onVisibleTextArea={onVisibleTextArea}
                  visibleTextArea={visibleTextArea}
                />
              ))}
            {visibleTextArea && (
              <Col span={24} className="text-end">
                <Input.TextArea
                  disabled={auditLoading}
                  placeholder={t("AddEditProperty.Audit.TypeAReply")}
                  row={3}
                  onChange={onCommentChange}
                />
                <Button type="text" onClick={onHideTextArea}>
                  {t("Buttons.Cancel")}
                </Button>
                <Button type="link" disabled={_isEmpty(comment)} loading={auditLoading} onClick={addComment}>
                  {t("Buttons.Submit")}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
      <AppModal
        className="media-preview"
        title="File Preview"
        width="80%"
        visible={isModalOpen}
        onCancel={toggleModal}
        maxHeight="100vh"
        overFlowY="auto"
        footer={null}
        destroyOnClose
      >
        <FilePreview file={previewItem} imageUrl={previewItem?.url} />
      </AppModal>
    </>
  );
};

AuditIssueItem.propTypes = {
  item: PropTypes.object,
  resolveAuditIssue: PropTypes.func,
  navigateToIssueTab: PropTypes.func,
  setAuditToEdit: PropTypes.func,
  setAuditToDelete: PropTypes.func,
  auditStatusChanged: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
};

export default AuditIssueItem;
