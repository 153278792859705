import { Col, Divider, Row } from "antd";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useLanguage } from "../../hooks";
import { getLocalTranslatedValue } from "../../i18n";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import previewBanner from "../../images/preview-banner.png";
import { COUNTRY_NAME, FIELD_TYPE, UK_BROKER_IMAGES, USER_TYPE } from "../../utils/constants";

const ContactDetails = ({ values, propertyType, employeeList = [], brokerList = [], selectedContacts }) => {
  const { language } = useLanguage();
  const [propertySecondaryContact, setPropertySecondaryContacts] = useState([]);

  const { contacts } = values;
  const { country } = propertyType === FIELD_TYPE.building ? values.buildingInfo.address : values.landInfo.address;
  const isUkCountry = country.toLowerCase() === COUNTRY_NAME.UNITED_KINGDOM;
  const isCountryUnitedStates = country.toLowerCase() === COUNTRY_NAME.UNITED_STATES;
  const isAdvertisementBanner =
    country.toLowerCase() === COUNTRY_NAME.UNITED_STATES || country.toLowerCase() === COUNTRY_NAME.CANADA;

  useEffect(() => {
    if (contacts?.secondaryContacts) {
      setPropertySecondaryContacts(selectedContacts.secondaryContacts);
    }
  }, [values, isUkCountry, isCountryUnitedStates]);

  let _primaryContact = contacts.prologisContact;
  let _secondaryContact = {};
  let _additionalContacts = {};
  // Essentials Contact to be added on all properties in United States.
  const _essentialsContact = {
    firstName: "Elise",
    lastName: "Powers",
    jobTitle: "Essentials Specialist",
    phone: "+1 303-567-5326",
    contactText: "Your source to get operational in new space quickly, whether it's in a Prologis building or not.",
  };

  if (_isEmpty(_primaryContact.firstName)) {
    _primaryContact = employeeList.find(_contact => _contact.id === contacts.prologisContact.value);
  }
  if (contacts?.secondaryContacts && !isUkCountry) {
    if (!_isEmpty(contacts?.secondaryContacts[0]?.firstName)) {
      [_secondaryContact] = contacts.secondaryContacts;
    } else {
      const arr = [...propertySecondaryContact, ...brokerList];
      _secondaryContact = arr.find(_contact => _contact.id === contacts?.secondaryContacts[0]?.value);
    }
  }

  if (contacts?.secondaryContacts && isUkCountry) {
    if (!_isEmpty(contacts?.secondaryContacts[0]?.firstName)) {
      _additionalContacts = contacts.secondaryContacts;
    } else {
      const arr = [...propertySecondaryContact, ...brokerList];
      const uniqueData = [...arr.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
      _additionalContacts = uniqueData.filter(_contact => {
        const foundContact = contacts.secondaryContacts.find(_item => _item.value === _contact.id);
        if (foundContact) {
          return true;
        }
        return false;
      });
    }
    _secondaryContact = _additionalContacts.find(contact => contact.type === USER_TYPE.EMPLOYEE);

    _additionalContacts = _additionalContacts
      .filter(contact => contact.type === USER_TYPE.BROKER)
      .reduce((acc, curr) => {
        if (acc[curr.company]) {
          acc[curr.company] = [...acc[curr.company], curr];
        } else {
          acc[curr.company] = [curr];
        }
        return acc;
      }, {});
  }

  const removedSpecialCharactersAddUnderscore = inputString => {
    const brokerImages = inputString
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, "_")
      .toLowerCase();
    return UK_BROKER_IMAGES[brokerImages];
  };

  return (
    <div id="contact" className="contact-details w-container">
      {isAdvertisementBanner && (
        <Row className="pad-top-60 pad-bottom-50">
          <Col xs={24}>
            <img src={previewBanner} width="100%" alt="preview-banner"></img>
          </Col>
        </Row>
      )}
      <Row className="pad-bottom-10 pad-top-10">
        <Col xs={24} className="property-heading">
          {getLocalTranslatedValue(language.current, "ContactUs")}
        </Col>
        <Col xs={24}>
          <p className="property-item-value contact-item">{getLocalTranslatedValue(language.current, "ContactText")}</p>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Row>
            <Col xs={24} className="property-item-title">
              {getLocalTranslatedValue(language.current, "PropertyContacts")}
            </Col>
            <Col xs={24} className="property-item-value property-info-item-contact-name">
              {`${_get(_primaryContact, "firstName", "")} ${_get(_primaryContact, "lastName", "")}`}
            </Col>
            <Col xs={24} className="property-item-value property-info-item-contact-job-title">
              {_get(_primaryContact, "jobTitle", "")}
            </Col>
            <Col xs={24} className="property-item-value property-info-item-contact-company">
              {_get(_primaryContact, "company", "")}
            </Col>
            <Col xs={24} className="property-item-value">
              <CustomIcon name="phoneIcon" className="phone-icon" />
              <span className="property-item-value property-info-item-contact-number">
                {_get(_primaryContact, "phone", "")}
              </span>
            </Col>
            <Col xs={24} className="property-item-value">
              <CustomIcon name="emailIcon" className="email-icon" />
              <span className="property-info-item-contact">{getLocalTranslatedValue(language.current, "Contact")}</span>
            </Col>
          </Row>
        </Col>
        {!_isEmpty(_secondaryContact) && (
          <Col xs={24} md={8} lg={6}>
            <Row>
              <Col xs={24} className="property-item-title">
                &nbsp;
              </Col>
              <Col xs={24} className="property-item-value property-info-item-contact-name">
                {`${_get(_secondaryContact, "firstName", "")} ${_get(_secondaryContact, "lastName", "")}`}
              </Col>
              <Col xs={24} className="property-item-value property-info-item-contact-job-title">
                {_get(_secondaryContact, "jobTitle", "")}
              </Col>
              <Col xs={24} className="property-item-value property-info-item-contact-company">
                {_get(_secondaryContact, "company", "")}
              </Col>
              <Col xs={24} className="property-item-value">
                <CustomIcon name="phoneIcon" className="phone-icon" />
                <span className="property-item-value property-info-item-contact-number">
                  {_get(_secondaryContact, "phone", "")}
                </span>
              </Col>
              <Col xs={24} className="property-item-value">
                <CustomIcon name="emailIcon" className="email-icon" />
                <span className="property-info-item-contact">
                  {getLocalTranslatedValue(language.current, "Contact")}
                </span>
              </Col>
            </Row>
          </Col>
        )}
        {_isEmpty(_secondaryContact) && isCountryUnitedStates && <Col xs={24} md={8} lg={6}></Col>}
        {isCountryUnitedStates && (
          <Col xs={24} md={8} lg={12} className="contact-details essentials-contact">
            <Row>
              <Col xs={24} className="property-item-title">
                {getLocalTranslatedValue(language.current, "EssentialsContact")}
              </Col>
              <Col xs={24} className="property-item-value property-info-item-contact-name">
                {`${_get(_essentialsContact, "firstName", "")} ${_get(_essentialsContact, "lastName", "")}`}
              </Col>
              <Col xs={24} className="property-item-value property-info-item-contact-job-title">
                {_get(_essentialsContact, "jobTitle", "")}
              </Col>
              <Col xs={24} className="property-item-value">
                <CustomIcon name="phoneIcon" className="phone-icon" />
                <span className="property-item-value property-info-item-contact-number">
                  {_get(_essentialsContact, "phone", "")}
                </span>
              </Col>
              <Col xs={24} className="property-item-value">
                <CustomIcon name="emailIcon" className="email-icon" />
                <span className="property-info-item-contact">
                  {getLocalTranslatedValue(language.current, "Contact")}
                </span>
              </Col>
              <Col xs={24} className="contact-details essentials-contacts esm_block_footer_text">
                {_essentialsContact.contactText}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {isUkCountry && !_isEmpty(_additionalContacts) && (
        <>
          <Divider />
          <Row>
            <Col xs={24} className="property-item-title pad-top-i-0 pad-bottom-15">
              {getLocalTranslatedValue(language.current, "AdditionalContacts")}
            </Col>
            {Object.keys(_additionalContacts).map(company => (
              <Col xs={24} md={8} lg={6} key={company}>
                <Row>
                  <Col xs={24} className="broker-logo">
                    <img src={removedSpecialCharactersAddUnderscore(company)} width="100%" alt=""></img>
                  </Col>
                  {_additionalContacts[company].map(contact => (
                    <div className="mg-bottom-20" key={contact.id}>
                      <Col xs={24} className="property-item-value property-info-item-contact-name">
                        {`${_get(contact, "firstName", "")} ${_get(contact, "lastName", "")}`}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {_get(contact, "jobTitle", "")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {_get(contact, "company", "")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        <CustomIcon name="phoneIcon" className="phone-icon" />
                        <span className="property-item-value property-info-item-contact-number">
                          {_get(contact, "phone", "")}
                        </span>
                      </Col>
                      <Col xs={24} className="property-item-value">
                        <CustomIcon name="emailIcon" className="email-icon" />
                        <span className="property-info-item-contact">
                          {getLocalTranslatedValue(language.current, "Contact")}
                        </span>
                      </Col>
                    </div>
                  ))}
                </Row>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

ContactDetails.propTypes = {
  values: PropTypes.object,
  propertyType: PropTypes.string,
  employeeList: PropTypes.array,
  brokerList: PropTypes.array,
  selectedContacts: PropTypes.object,
};

export default ContactDetails;
