import BrokerService from "../../services/broker.service";
import { ERROR_MESSAGE, PAGINATOIN, SUCCESS_MESSAGE } from "../../utils/constants";
import { downloadFile, setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  BROKER_LOADING: "loading",
  ASSOCIATED_PROPERTIES_LOADING: "loadingAssociatedProperties",
};

export function brokerRequest(key = LOADING.BROKER_LOADING) {
  return { type: ACTIONS.BROKER_REQUEST, key };
}

export function brokerSuggestionRequest() {
  return { type: ACTIONS.BROKER_SUGGESTION_REQUEST };
}

export function brokerSuccess(response) {
  return { type: ACTIONS.BROKER_SUCCESS, response };
}

export function brokerSuggestionSuccess(response) {
  return { type: ACTIONS.BROKER_SUGGESTION_SUCCESS, response };
}

export function brokerAssociatedPropertiesSuccess(response) {
  return { type: ACTIONS.BROKER_ASSOCIATED_PROPERTIES_SUCCESS, response };
}

export function getAllBrokersSuccess(response) {
  return { type: ACTIONS.GET_ALL_BROKERS_SUCCESS, response };
}

export function getBrokerByIdSuccess(response) {
  return { type: ACTIONS.GET_BROKER_BY_ID_SUCCESS, response };
}

export function brokerError(error, key = LOADING.BROKER_LOADING) {
  return { type: ACTIONS.BROKER_ERROR, error, key };
}

export function brokerSuggestionError(error) {
  return { type: ACTIONS.BROKER_SUGGESTION_ERROR, error };
}

export function getBrokers(query) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.searchBrokers(query));
      if (err || !response) throwError(err); // TODO: neeed to verify why this return empty string
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllBrokersSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.brokers, pagination);
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getBrokersSuggestion(payload) {
  return async dispatch => {
    dispatch(brokerSuggestionRequest());
    try {
      const [err, response] = await to(BrokerService.suggestions(payload));
      if (err) throwError(err);
      dispatch(brokerSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(brokerSuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getBrokerById(id) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.getBrokerById(id));
      if (err) throwError(err);
      dispatch(getBrokerByIdSuccess(response));
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function getBrokerAssociatedProperties(id) {
  return async dispatch => {
    dispatch(brokerRequest(LOADING.ASSOCIATED_PROPERTIES_LOADING));
    try {
      const [err, response] = await to(BrokerService.getBrokerAssociatedProperties(id));
      if (err) throwError(err);
      dispatch(brokerAssociatedPropertiesSuccess(response));
    } catch (error) {
      dispatch(brokerError(error, LOADING.ASSOCIATED_PROPERTIES_LOADING));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function exportBrokers() {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.exportBrokers());
      if (err) throwError(err);
      if (response) downloadFile(response, "Brokers.xlsx");
      dispatch(getBrokerByIdSuccess({}));
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}

export function importBrokers(payload, getProgress) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const data = new FormData();
      data.append("file", payload);
      const [err, response] = await to(BrokerService.importBrokers(data, getProgress));
      if (err) throwError(err);
      dispatch(getBrokerByIdSuccess({}));
      toastMessage("success", SUCCESS_MESSAGE.UPLOAD);
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.UPLOAD);
      throwError(error);
    }
  };
}

export function updateBroker(id, payload) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.updateBroker(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(getBrokerByIdSuccess({}));
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createBroker(payload) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.createBroker({ type: "broker", ...payload }));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(getBrokerByIdSuccess({}));
      return response;
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.ADDED);
      return throwError(error);
    }
  };
}

export function deleteBroker(id) {
  return async dispatch => {
    dispatch(brokerRequest());
    try {
      const [err, response] = await to(BrokerService.removeBroker(id));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
      dispatch(brokerSuccess({}));
    } catch (error) {
      dispatch(brokerError(error));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      throwError(error);
    }
  };
}
