import _get from "lodash/get";
import AnalyticService from "../../services/analytic.service";

export function sendAnalyticsEvent(eventName, opts) {
  return (dispatch, getState) => {
    const state = getState();
    const variables = {
      email: _get(state, "user.value.idTokenClaims.preferred_username"),
      userName: _get(state, "user.value.idTokenClaims.name"),
      ...opts,
    };
    return AnalyticService.sendCustomEvent(eventName, variables);
  };
}
