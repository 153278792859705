import _map from "lodash/map";
import auditService from "../../services/audit.service";
import BrokerService from "../../services/broker.service";
import CountryAddressFieldService from "../../services/countryAddressField.service";
import EmployeeService from "../../services/employee.service";
import MarketService from "../../services/market.service";
import propertyManagementService from "../../services/property.service";
import propertyRollUpService from "../../services/propertyRollUp.service";
import SubMarketService from "../../services/subMarket.service";
import { ERROR_MESSAGE, PAGINATOIN, SUCCESS_MESSAGE, TAG } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  PROPERTY_LOADING: "loading",
  PROPERTY_CODES_LOADING: "loadingCodes",
  MEDIA_LOADING: "loadingMedia",
  BROKERS_LOADING: "loadingBrokers",
  PROPERTYROLLUP_LOADING: "loadingPropertyRollUp",
  EMPLOYEES_LOADING: "loadingEmployees",
  ACTIVE_MARKET_LOADING: "loadingActiveSubMarkets",
  MARKET_LOADING: "loadingMarkets",
  SUB_MARKET_LOADING: "loadingSubMarkets",
  AUDIT_LOADING: "auditLoading",
  ESM_LOADING: "loadingESM",
  CONVERT_PROPERTY_LOADING: "loadingConvertProperty",
};

export function propertyRequest(key = LOADING.PROPERTY_LOADING) {
  return { type: ACTIONS.PROPERTY_REQUEST, key };
}

export function propertySuggestionRequest() {
  return { type: ACTIONS.PROPERTY_SUGGESTION_REQUEST };
}

export function propertySuccess(response) {
  return { type: ACTIONS.PROPERTY_SUCCESS, response };
}

export function propertyAddressFieldsListSuccess(response) {
  return { type: ACTIONS.PROPERTY_ADDRESS_FIELDS_LIST_SUCCESS, response };
}

export function propertyCodeDetailsSuccess() {
  return { type: ACTIONS.PROPERTY_CODE_DETAILS_SUCCESS };
}

export function propertySuggestionSuccess(response) {
  return { type: ACTIONS.PROPERTY_SUGGESTION_SUCCESS, response };
}

export function propertySuggestionError(error) {
  return { type: ACTIONS.PROPERTY_SUGGESTION_ERROR, error };
}

export function propertyError(payload) {
  return { type: ACTIONS.PROPERTY_ERROR, payload };
}

export function propertyListSuccess(response) {
  return { type: ACTIONS.PROPERTY_LIST_SUCCESS, response };
}

export function currentVacantPropertyListSuccess(response) {
  return { type: ACTIONS.CURRENT_VACANT_PROPERTY_LIST_SUCCESS, response };
}

export function selectedPropertySuccess(response) {
  return { type: ACTIONS.SELECTED_PROPERTY_SUCCESS, response };
}

export function propertyTagsSuccess(response) {
  return { type: ACTIONS.PROPERTY_TAGS_SUCCESS, response };
}

export function currentVacantPropertiesTagsSuccess(response) {
  return { type: ACTIONS.CURRENT_VACANT_PROPERTIES_TAGS_SUCCESS, response };
}

export function getAllPropertyRollUpSuccess(response) {
  return { type: ACTIONS.PROPERTY_ACTIVE_PROPERTYROLLUP_SUCCESS, response };
}

export function propertyCodesSuccess(response) {
  return { type: ACTIONS.PROPERTY_CODES_SUCCESS, response };
}

export function propertyMediaSuccess(response) {
  return { type: ACTIONS.PROPERTY_MEDIA_SUCCESS, response };
}

export function getAllPropertyBrokersSuccess(response) {
  return { type: ACTIONS.GET_ALL_PROPERTY_BROKERS_SUCCESS, response };
}
export function getPropertyBrokerSuccess(response) {
  return { type: ACTIONS.GET_PROPERTY_BROKER_SUCCESS, response };
}

export function getAllPropertyEmployeesSuccess(response) {
  return { type: ACTIONS.GET_ALL_PROPERTY_EMPLOYEES_SUCCESS, response };
}

export function getAllPropertyESMContactSuccess(response) {
  return { type: ACTIONS.GET_ALL_PROPERTY_ESM_SUCCESS, response };
}

export function getAllPropertyMarketsSuccess(response) {
  return { type: ACTIONS.GET_ALL_PROPERTY_MARKETS_SUCCESS, response };
}

export function activePropertySubMarketsSuccess(response) {
  return { type: ACTIONS.ACTIVE_PROPERTY_SUB_MARKET_SUCCESS, response };
}

export function activePropertyMarketsSuccess(response) {
  return { type: ACTIONS.ACTIVE_PROPERTY_MARKETS_SUCCESS, response };
}

export function auditSuccess(response) {
  return { type: ACTIONS.AUDIT_SUCCESS, response };
}

export function updateAuditIssueIdNumber(maxAuditIssueIdNumber) {
  return { type: ACTIONS.AUDIT_ISSUE_ID_NUMBER, maxAuditIssueIdNumber };
}

export function propertyConvertSuccess() {
  return { type: ACTIONS.PROPERTY_CONVERT_SUCCESS };
}

export function getDashboardPropertiesList(query, isFromDashboard = false, getPropertyType, isFromHome) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.PROPERTY_LOADING));
    try {
      setSessionStorage(TAG.currentVacantProperties, []);
      const _tags = [
        ...(query?.filterCriteria?.status || []),
        ...(query?.filterCriteria?.propertyType || []),
        ...(query?.filterCriteria?.market || []),
        ...(query?.filterCriteria?.subMarket || []),
      ];
      query.isMarketable = true;
      const [err, response] = await to(propertyManagementService.searchDashboardProperties(query, getPropertyType));

      if (err) throwError(err);
      dispatch(propertySuccess(response));
      const pagination = { ...query, totalElements: response.total };
      dispatch(currentVacantPropertyListSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.currentVacantPagination, pagination);
      dispatch(currentVacantPropertiesTagsSuccess(_tags));
      setSessionStorage(TAG.currentVacantProperties, _tags);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.PROPERTY_LOADING }));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertiesList(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.PROPERTY_LOADING));
    try {
      setSessionStorage(TAG.properties, []);
      const _tags = [
        ...(query?.filterCriteria?.status || []),
        ...(query?.filterCriteria?.propertyType || []),
        ...(query?.filterCriteria?.market || []),
        ...(query?.filterCriteria?.subMarket || []),
      ];
      const [err, response] = await to(propertyManagementService.searchProperties(query));
      if (err) throwError(err);
      dispatch(propertySuccess(response));
      const pagination = { ...query, totalElements: response.total };
      dispatch(propertyListSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.properties, pagination);
      dispatch(propertyTagsSuccess(_tags));
      setSessionStorage(TAG.properties, _tags);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.PROPERTY_LOADING }));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertiesSuggestion(payload) {
  return async dispatch => {
    dispatch(propertySuggestionRequest());
    try {
      const [err, response] = await to(propertyManagementService.suggestions(payload));
      if (err) throwError(err);
      dispatch(propertySuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(propertySuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function removeProperty(id) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.PROPERTY_LOADING));
    try {
      const [err, response] = await to(propertyManagementService.removeProperty(id));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
      dispatch(selectedPropertySuccess({}));
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.PROPERTY_LOADING }));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      throwError(error);
    }
  };
}

export function getPropertyCodes(params) {
  return async dispatch => {
    const { id } = params;
    dispatch(propertyRequest(LOADING.PROPERTY_CODES_LOADING));
    try {
      const [err, response] = await to(propertyManagementService.getPropertyCodes(params));
      if (err) throwError(err);
      if (!id) {
        dispatch(propertyCodesSuccess(response));
      } else {
        dispatch(propertyCodeDetailsSuccess());
      }
      return response;
    } catch (error) {
      console.log(error);
      dispatch(propertyError({ error, key: LOADING.PROPERTY_CODES_LOADING }));
      toastMessage("error", ERROR_MESSAGE.INFO);
      return throwError(error);
    }
  };
}

export function getPropertyMedia(payload) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.MEDIA_LOADING));
    try {
      const [err, response] = await to(propertyManagementService.getPropertyMedia(payload));
      if (err) throwError(err);
      dispatch(propertyMediaSuccess(response.elements));
    } catch (error) {
      dispatch(propertyMediaSuccess([])); // to empty the previous media assets
      dispatch(propertyError({ error, key: LOADING.MEDIA_LOADING }));
    }
  };
}

export function getAllCountryAddressFields() {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.PROPERTY_LOADING));
    try {
      const [err, response] = await to(CountryAddressFieldService.getAll());
      if (err) throwError(err);
      dispatch(propertyAddressFieldsListSuccess(response));
    } catch (error) {
      dispatch(propertyAddressFieldsListSuccess([]));
      console.log(error);
      dispatch(propertyError({ error, key: LOADING.PROPERTY_LOADING }));
    }
  };
}

export function getPropertyBrokerById(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.BROKERS_LOADING));
    try {
      const [err, response] = await to(BrokerService.getBrokerById(query));
      if (err || !response) throwError(err); // TODO: neeed to verify why this return empty string
      return response;
    } catch (error) {
      dispatch(propertyError(error, LOADING.BROKERS_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getPropertyBrokers(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.BROKERS_LOADING));
    try {
      const [err, response] = await to(BrokerService.searchBrokers(query));
      if (err || !response) throwError(err); // TODO: neeed to verify why this return empty string
      const pagination = { ...query, totalElements: response.total };
      const filteredList = response.content?.filter(item => item.type !== "auditor");
      dispatch(getAllPropertyBrokersSuccess({ list: filteredList, pagination }));
    } catch (error) {
      dispatch(propertyError(error, LOADING.BROKERS_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertyEmployeeContacts(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.EMPLOYEES_LOADING));
    try {
      const [err, response] = await to(EmployeeService.searchEmployees(query));
      if (err || !response) throwError(err); // TODO: neeed to verify why this return empty string
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllPropertyEmployeesSuccess({ list: response.content, pagination }));
    } catch (error) {
      dispatch(propertyError(error, LOADING.BROKERS_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertyESMContacts(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.ESM_LOADING));
    try {
      const [err, response] = await to(EmployeeService.searchESMContact(query));
      if (err || !response) throwError(err); // TODO: neeed to verify why this return empty string
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllPropertyESMContactSuccess({ list: response.content, pagination }));
    } catch (error) {
      dispatch(propertyError(error, LOADING.ESM_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getActivePropertyMarkets(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.ACTIVE_MARKET_LOADING));
    try {
      const [err, response] = await to(MarketService.searchMarkets(query));
      if (err) throwError(err);
      dispatch(activePropertyMarketsSuccess(response.content));
    } catch (error) {
      dispatch(propertyError(error, LOADING.ACTIVE_MARKET_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertyAllMarkets(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.MARKET_LOADING));
    try {
      const [err, response] = await to(MarketService.getAllMarkets());
      if (err) throwError(err);
      dispatch(
        getAllPropertyMarketsSuccess({
          list: response /* , pagination: { ...query, totalElements: response.total } */,
        }),
      );
    } catch (error) {
      dispatch(propertyError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getActivePropertySubMarkets(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.ACTIVE_MARKET_LOADING));
    try {
      const [err, response] = await to(SubMarketService.search(query));
      if (err) throwError(err);
      dispatch(activePropertySubMarketsSuccess(response.content));
    } catch (error) {
      dispatch(propertyError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

const getMaxIssueId = arr => {
  const auditIssueIdNumber = arr.map(audit => +audit.id.split("~")[1]);
  return auditIssueIdNumber.length ? Math.max(...auditIssueIdNumber) : 0;
};

export function getAuditList(pCode) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      const [err, response] = await to(auditService.get(pCode));
      if (err) throwError(err);
      dispatch(updateAuditIssueIdNumber(getMaxIssueId(response)));
      dispatch(auditSuccess(response));
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function addAuditIssue(auditIssue) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.add(auditIssue));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(auditIssue.propertyCode));
      if (err) throwError(err);
      dispatch(updateAuditIssueIdNumber(getMaxIssueId(response)));
      dispatch(auditSuccess(response));
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function updateAuditIssue(id, body) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.update(id, body));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(response.propertyCode));
      if (err) throwError(err);
      dispatch(auditSuccess(response));
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function removeAuditIssue(propertyCode, auditId) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.remove(propertyCode, auditId));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(propertyCode));
      if (err) throwError(err);
      dispatch(auditSuccess(response));
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function resolveAllIssues(id) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.resolveAll(id));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(id));
      if (err) throwError(err);
      dispatch(auditSuccess(response));
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function addAuditIssueComment(id, body) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.comment(id, body));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(response.propertyCode));
      if (err) throwError(err);
      dispatch(auditSuccess(response));
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function sendEmailNotification(body) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.AUDIT_LOADING));
    try {
      let [err, response] = await to(auditService.sendNotification(body));
      if (err) throwError(err);
      [err, response] = await to(auditService.get(body.propertyCode));
      if (err) throwError(err);
      dispatch(auditSuccess(response));
      toastMessage("success", SUCCESS_MESSAGE.EMAIL);
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.AUDIT_LOADING }));
      throwError(error);
    }
  };
}
export function getPropertyRollUps(query) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.PROPERTYROLLUP_LOADING));
    try {
      const [err, response] = await to(propertyRollUpService.searchPropertyRollUp(query));
      if (err) throwError(err);
      dispatch(getAllPropertyRollUpSuccess({ list: response.content }));
    } catch (error) {
      dispatch(propertyError({ error, key: LOADING.PROPERTYROLLUP_LOADING }));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function convertProperty(body) {
  return async dispatch => {
    dispatch(propertyRequest(LOADING.CONVERT_PROPERTY_LOADING));
    try {
      const [err, response] = await to(propertyManagementService.convertProperty(body));
      if (err) throwError(err);

      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(propertyConvertSuccess());
    } catch (error) {
      console.log("error: ", error);
      dispatch(propertyError({ error, key: LOADING.CONVERT_PROPERTY_LOADING }));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
