import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Divider } from "antd";
import "./PageTitle.scss";

const PageTitle = ({ title, propertyStatus, items = [] }) => {
  const breadcrumbItems = items.length > 0 && (
    <Breadcrumb separator=">" className="breadcrumbs">
      {items.map((item, index) => (
        <Breadcrumb.Item key={item.title}>
          {item.path && items.length - 1 !== index ? <a href={item.path}>{item.title}</a> : item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );

  return (
    <div className="PageTitle">
      {breadcrumbItems}
      <Divider orientation="center" className="form-divider first">
        <span className="page-title">{title}</span>
      </Divider>
      {propertyStatus}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  propertyStatus: PropTypes.node,
};

export default PageTitle;
