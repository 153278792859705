import { get, post, put } from "./http.service";

const BuildingService = {
  addBuilding: body => post(`property`, body),
  getBuildingById: id => get(`property/${id}`),
  updateBuildingById: (id, body) => put(`property/${id}`, body),
  updateUnit: body => put(`property/unit`, body),
  updateAuditStatus: (id, body) => put(`property/updateAuditStatus/${id}`, body),
};
export default BuildingService;
