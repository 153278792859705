import { Button, Col, Row, Tag, Tooltip } from "antd";
import _map from "lodash/map";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import SearchListView from "../../components/SearchListView/SearchListView";
import {
  deleteBroker,
  exportBrokers,
  getBrokerByIdSuccess,
  getBrokers,
  importBrokers,
  getBrokerAssociatedProperties,
} from "../../store/actions/broker.actions";

import { PAGINATOIN, SORT_DIRECTION } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { replaceIfNull } from "../../utils/utils";
import AddEditBroker from "../AddEditBroker/AddEditBroker";
import "./Brokers.scss";

const Brokers = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canViewUser = permissionsUtil.checkAuth("user_view");
  const canEditUser = permissionsUtil.checkAuth("user_edit");
  const canCreateUser = permissionsUtil.checkAuth("user_create");

  const { list, loading, pagination, associatedProperties } = useSelector(store => store.broker);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportingBrokers, setExportingBrokers] = useState(false);
  const [importingBrokers, setImportingBrokers] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(false);
  const [deleteBrokerModalVisible, toggleDeleteBrokerModal] = useState(false);

  const [brokerId, setBrokerId] = useState(null);

  const fetchBrokers = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getBrokers(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const _onSuccess = () => {
    fetchBrokers();
  };

  const exportAction = async () => {
    try {
      setExportingBrokers(true);
      await dispatch(exportBrokers());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingBrokers(false);
    }
  };

  const importAction = async info => {
    try {
      setImportingBrokers(true);
      await dispatch(importBrokers(info.file, getProgress));
    } catch (ex) {
      console.log(ex);
    } finally {
      setImportingBrokers(false);
      fetchBrokers();
    }
  };

  const getProgress = ({ loaded, total }) => {
    const progress = Math.floor((loaded / total) * 100);
    if (progress !== 100) {
      setDownloadPercent(progress);
      return;
    }
    setDownloadPercent(100);
  };

  const toggleModal = async (modalState, id) => {
    setBrokerId(id);
    setIsModalOpen(modalState);
  };

  const showDeleteBrokerModal = async id => {
    setBrokerId(id);
    await dispatch(getBrokerAssociatedProperties(id));
    toggleDeleteBrokerModal(true);
  };

  const removeBroker = async () => {
    try {
      await dispatch(deleteBroker(brokerId));
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      fetchBrokers();
      toggleDeleteBrokerModal(false);
      setBrokerId(null);
    }
  };

  const renderMarketTags = markets => {
    if (markets && markets.length) {
      return _map(markets, market =>
        market ? (
          <Tag key={market} className="text-capitalize" color="processing">
            {market}
          </Tag>
        ) : null,
      );
    }
    return "N/A";
  };

  const _closeBrokerDeleteModal = () => {
    toggleDeleteBrokerModal(false);
    setBrokerId(null);
  };

  const deleteBrokerKeyAction = (event, data) => {
    if (event.keyCode === 13) showDeleteBrokerModal(data);
  };

  const columns = [
    {
      title: t("Table.DisplayName"),
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "displayName" && sortInfo?.order,
      fixed: "left",
      width: 300,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.MarketCodes"),
      dataIndex: "markets",
      key: "markets",
      render: (markets, record) => renderMarketTags(markets),
      width: 300,
    },
    {
      title: t("Table.Company"),
      dataIndex: "company",
      key: "company",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "company" && sortInfo?.order,
      width: 100,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.License"),
      dataIndex: "licenseNumber",
      key: "licenseNumber",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "licenseNumber" && sortInfo?.order,
      width: 100,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.Active"),
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "isActive" && sortInfo?.order,
      // filters: ACTIVE_STATUS,
      // onFilter: (value, record) => record.isActive === value,
      // filteredValue: filterInfo?.isActive || null,
      render: text => (text ? "True" : "False"),
      width: 75,
    },
  ];

  if (canEditUser) {
    columns.push({
      title: t("Table.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 75,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Table.Edit")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => {
                if (ev.keyCode === 13) toggleModal(true, record.id);
              }}
              onClick={() => {
                toggleModal(true, record.id);
              }}
            >
              <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
              <p style={{ color: "#5C5E5D" }}>{t("Table.Edit")}</p>
            </div>
          </Tooltip>
          <Tooltip placement="bottom" title={t("Buttons.Remove")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => deleteBrokerKeyAction(ev, record.id)}
              onClick={() => {
                showDeleteBrokerModal(record.id);
              }}
            >
              <CustomIcon name="deleteIcon" size={22} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Remove")}</p>
            </div>
          </Tooltip>
        </div>
      ),
    });
  }

  return (
    <div className="brokers">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canViewUser && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block loading={exportingBrokers} onClick={exportAction}>
              {t("Table.Export")}
            </Button>
          </Col>
        )}
        {/* <Col xs={24} sm={24} md={5} lg={4}>
          {canViewUser && (
          <Upload beforeUpload={beforeUploadExcel} customRequest={importAction} showUploadList={false}>
          <Button disabled={importingBrokers} block>
              {importingBrokers ? (
                <Progress strokeLinecap="square" strokeColor="#F7931E" percent={downloadPercent} />
              ) : (
                "Import"
              )}
            </Button>
          </Upload>
          )}
        </Col> */}
        {canCreateUser && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block type="primary" onClick={() => toggleModal(true)}>
              {t("Table.CreateBroker")}
            </Button>
          </Col>
        )}
      </Row>
      <SearchListView
        paginationKey={PAGINATOIN.brokers}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        loading={loading}
        dataSource={list}
        columns={columns}
        getList={fetchBrokers}
        pagination={pagination}
        scroll={{ x: 950, y: "calc(100vh - 560px)" }}
        filterCriteria={{ type: "broker" }}
      />
      <AppModal
        title={`${brokerId ? t("AddEditBroker.EditBroker") : t("AddEditBroker.CreateBroker")}`}
        width={800}
        visible={isModalOpen}
        onCancel={() => {
          toggleModal(false);
          dispatch(getBrokerByIdSuccess({}));
        }}
        footer={null}
        destroyOnClose
      >
        <AddEditBroker brokerId={brokerId} onSuccess={_onSuccess} onCloseModal={toggleModal} />
      </AppModal>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title="Warning"
        width={600}
        minHeight={isEmpty(associatedProperties) ? "10vh" : "20vh"}
        maxHeight={isEmpty(associatedProperties) ? "20vh" : "60vh"}
        overFlowY="auto"
        visible={deleteBrokerModalVisible}
        onCancel={_closeBrokerDeleteModal}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block disabled={loading} onClick={_closeBrokerDeleteModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={removeBroker} loading={loading}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        {isEmpty(associatedProperties) ? (
          <p>{t("Constants.ConfirmMessages.DeleteBroker")}</p>
        ) : (
          <>
            <p>{t("AddEditBroker.Description")}</p>
            <Row gutter={[8, 8]} justify="center">
              {_map(associatedProperties, (val, key) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} className="associated-property">
                  {val.id}
                </Col>
              ))}
            </Row>
          </>
        )}
      </AppModal>
    </div>
  );
};

export default Brokers;
