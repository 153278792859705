import { Button, Col, Form, Row, Select } from "antd";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllMarkets, setSelectedMarkets } from "../../store/actions/market.actions";
import { setRegions } from "../../store/actions/region.actions";
import { setDivisions } from "../../store/actions/division.actions";
import { setSelectedSubMarkets } from "../../store/actions/subMarket.actions";
import {
  getUnitsToReview,
  getActiveAudits,
  getMarketableUnits,
  getUnitsToReviewWithFilters,
  getActiveAuditsWithFilters,
  getMarketableUnitsWithFilters,
  homePaginationSuccess,
  homeTagsSuccess,
} from "../../store/actions/home.actions";
import {
  currentVacantPropertyListSuccess,
  currentVacantPropertiesTagsSuccess,
} from "../../store/actions/property.actions";
import {
  upcomingVacantListSuccess,
  upcomingVacantPropertiesTagsSuccess,
} from "../../store/actions/unitVacancy.actions";
import { openAuditsTagSuccess, openAuditsListSuccess } from "../../store/actions/audit.action";

import { PAGINATOIN, TAG, SUGGESTION_SEARCH_STATE, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import {
  getFeatureFlags,
  sortAlphabetically,
  removeEmptyElements,
  sortAlphabeticallyBySingleParameter,
  setSessionStorage,
} from "../../utils/utils";

const { Option } = Select;

const FilterPropertyHorizontal = ({ homePagination, homeTags, defaultSort, searchState, setIsLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // Add state and region here
  const { list: divisions } = useSelector(state => state.division);
  const { list: regions } = useSelector(state => state.region);
  const { list: markets, selectedList: selectedMarkets, loading } = useSelector(state => state.market);
  const { selectedList: selectedSubMarkets, loading: subMarketLoading } = useSelector(state => state.subMarket);
  const FEATURE_FLAG = getFeatureFlags();
  const { SUBMARKET } = FEATURE_FLAG;

  /**
   * Initial search call
   */
  useEffect(() => {
    (async () => {
      await dispatch(getAllMarkets());
    })();
  }, []);

  /**
   * Setting initial dropdown values based on all markets.
   */
  useEffect(() => {
    if (!_isEmpty(markets)) {
      // Need to add loading for all dispatches
      (async () => {
        await _setInitialDropDownFields();
        await _setInitialDropDownValues();
      })();
    }
  }, [markets]);

  const _setInitialDropDownValues = async () => {
    if (_isEmpty(homePagination) || _isEmpty(homeTags)) {
      await _updateDashboardValues();
    }

    if (!_isEmpty(homePagination) && !_isEmpty(homePagination.filterCriteria)) {
      form.setFieldsValue({
        division: homePagination.filterCriteria?.division || [],
        region: homePagination.filterCriteria?.region || [],
        market: homePagination.filterCriteria?.market || [],
        subMarket: homePagination.filterCriteria?.subMarket || [],
      });
      await _updateDashboardValuesWithFilters();
    }
  };

  /**
   * Sync form filters and table sorts
   * with homePagination object
   */
  const _setInitialDropDownFields = async (initialLoad = false) => {
    let allDivisions = [],
      allRegions = [],
      allMarkets = [],
      allSubMarkets = [];
    if (!_isEmpty(markets)) {
      allDivisions = [...new Set(markets.map(element => element.division).filter(item => typeof item === "string"))];
      allRegions = [...new Set(markets.map(element => element.region?.code).filter(item => typeof item === "string"))];
      allMarkets = markets;
      const filteredMarkets = homePagination?.filterCriteria?.market || [];
      if (initialLoad || _isEmpty(homePagination?.filterCriteria?.market)) {
        allSubMarkets = markets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
      } else {
        allSubMarkets = markets
          .filter(market => filteredMarkets.includes(market.id))
          .reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
      }
      (async () => {
        await dispatch(setDivisions(allDivisions));
        await dispatch(setRegions(allRegions));
        await dispatch(setSelectedMarkets(allMarkets));
        await dispatch(setSelectedSubMarkets(allSubMarkets));
      })();

      // Add additional code to fix submarket load for filter views
    }
  };

  /**
   * get filter criteria from form fields
   */
  const getFilterCriteria = fieldValues => ({
    division: removeEmptyElements(fieldValues.division || []),
    region: removeEmptyElements(fieldValues.region || []),
    market: removeEmptyElements(fieldValues.market || []),
    subMarket: removeEmptyElements(fieldValues.subMarket || []),
  });

  /**
   * Update region, market, submarket when user selects / deselects division
   *
   *
   * Need to add current selected values here
   */
  const _onChangeDivision = keys => {
    setIsLoading(true);
    let availableRegions = [],
      availableMarkets = [],
      availableSubMarkets = [];

    if (!_isEmpty(keys)) {
      availableRegions = [
        ...new Set(markets.filter(({ division }) => keys.includes(division)).map(el => el.region?.code)),
      ];
      availableMarkets = [...new Set(markets.filter(({ division }) => keys.includes(division)))];
      availableSubMarkets = markets
        .filter(({ division }) => keys.includes(division))
        .reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    } else {
      availableRegions = [
        ...new Set(markets.map(element => element.region?.code).filter(item => typeof item === "string")),
      ];
      availableMarkets = markets;
      availableSubMarkets = markets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    }

    const selectedRegions = form.getFieldValue("region");
    if (!_isEmpty(selectedRegions)) {
      /**
       * If regions are selected previously
       * then reflect changes as per selected divisions
       */
      const matchedRegions = selectedRegions.filter(_selectedRegion =>
        availableRegions.some(_region => _region === _selectedRegion),
      );
      form.setFieldsValue({ region: matchedRegions });
    }

    const selectedMarketsInDropDown = form.getFieldValue("market");
    if (!_isEmpty(selectedMarketsInDropDown)) {
      /**
       * If markets are selected previously
       * then reflect changes as per selected divisions
       */
      const matchedMarkets = selectedMarketsInDropDown.filter(_selectedMarket =>
        availableMarkets.some(_market => _market.id === _selectedMarket),
      );
      form.setFieldsValue({ market: matchedMarkets });
    }

    const subMarketFilters = form.getFieldValue("subMarket");
    if (!_isEmpty(subMarketFilters)) {
      /**
       * If submarkets are selected previously
       * then reflect changes as per selected divisions
       */
      const matchedSubMarkets = subMarketFilters.filter(_subMarketFilter =>
        availableSubMarkets.some(_subMarket => _subMarket.id === _subMarketFilter),
      );
      form.setFieldsValue({ subMarket: matchedSubMarkets });
    }

    (async () => {
      await dispatch(setRegions(availableRegions));
      await dispatch(setSelectedMarkets(availableMarkets));
      await dispatch(setSelectedSubMarkets(availableSubMarkets));
    })();

    _updateDashboardValuesWithFilters();
  };

  /**
   * Update market, submarket when user selects / deselects regions
   *
   *
   * Need to add current selected values here
   */
  const _onChangeRegion = keys => {
    setIsLoading(true);
    let availableMarkets = [],
      availableSubMarkets = [];
    if (!_isEmpty(keys)) {
      availableMarkets = [...new Set(markets.filter(market => keys.includes(market.region?.code)))];
      availableSubMarkets = markets
        .filter(market => keys.includes(market.region?.code))
        .reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    } else {
      availableMarkets = markets;
      availableSubMarkets = markets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    }

    const selectedMarketsInDropDown = form.getFieldValue("subMarket");
    if (!_isEmpty(selectedMarketsInDropDown)) {
      /**
       * If markets are selected previously
       * then reflect changes as per selected regions
       */
      const matchedMarkets = selectedMarketsInDropDown.filter(_selectedMarket =>
        availableMarkets.some(_market => _market.id === _selectedMarket),
      );
      form.setFieldsValue({ market: matchedMarkets });
    }

    const subMarketFilters = form.getFieldValue("subMarket");
    if (!_isEmpty(subMarketFilters)) {
      /**
       * If submarkets are selected previously
       * then reflect changes as per selected regions
       */
      const matchedSubMarkets = subMarketFilters.filter(_subMarketFilter =>
        availableSubMarkets.some(_subMarket => _subMarket.id === _subMarketFilter),
      );
      form.setFieldsValue({ subMarket: matchedSubMarkets });
    }

    (async () => {
      await dispatch(setSelectedMarkets(availableMarkets));
      await dispatch(setSelectedSubMarkets(availableSubMarkets));
    })();
    _updateDashboardValuesWithFilters();
  };

  /**
   * Update submarkets when user selects / deselects markets
   */
  const _onChangeMarket = keys => {
    setIsLoading(true);
    let availableSubMarkets = [];
    if (!_isEmpty(keys)) {
      availableSubMarkets = markets
        .filter(market => keys.includes(market.id))
        .reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    } else {
      availableSubMarkets = markets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    }

    const subMarketFilters = form.getFieldValue("subMarket");
    if (!_isEmpty(subMarketFilters)) {
      /**
       * If submarkets are selected previously
       * then reflect changes as per selected markets
       */
      const matchedSubMarkets = subMarketFilters.filter(_subMarketFilter =>
        availableSubMarkets.some(_subMarket => _subMarket.id === _subMarketFilter),
      );
      form.setFieldsValue({ subMarket: matchedSubMarkets });
    }

    (async () => {
      await dispatch(setSelectedSubMarkets(availableSubMarkets));
    })();
    _updateDashboardValuesWithFilters();
  };

  /**
   * Update units to review when user selects / deselects submarket
   */
  const _onChangeSubMarket = key => {
    setIsLoading(true);
    _updateDashboardValuesWithFilters();
  };

  const _updateDashboardValues = async () => {
    try {
      await dispatch(homePaginationSuccess());
      await dispatch(getUnitsToReview());
      await dispatch(getActiveAudits());
      await dispatch(getMarketableUnits());
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setIsLoading(false);
    }
  };

  const _updateDashboardValuesWithFilters = async () => {
    const fieldValues = form.getFieldsValue();
    const filterCriteria = getFilterCriteria(fieldValues);
    const _tags = [
      ...(filterCriteria?.division || []),
      ...(filterCriteria?.region || []),
      ...(filterCriteria?.market || []),
      ...(filterCriteria?.subMarket || []),
    ];
    setSessionStorage(PAGINATOIN.home, { filterCriteria });
    setSessionStorage(TAG.home, _tags);
    dispatch(homePaginationSuccess({ filterCriteria }));
    dispatch(homeTagsSuccess(_tags));
    try {
      await dispatch(getUnitsToReviewWithFilters(filterCriteria));
      await dispatch(getActiveAuditsWithFilters(filterCriteria));
      await dispatch(getMarketableUnitsWithFilters(filterCriteria));
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormFields = () => {
    form.setFieldsValue({
      division: [],
      region: [],
      market: [],
      subMarket: [],
    });
  };

  const _resetDropDownValuesInState = async () => {
    // Clear form selections on reset
    resetFormFields();
    await _setInitialDropDownFields(true);
    await _updateDashboardValues();
  };

  const _resetHomePage = async () => {
    setSessionStorage(PAGINATOIN.home, { filterCriteria: {} });
    setSessionStorage(TAG.home, []);
    await dispatch(homePaginationSuccess({ list: [], pagination: { filterCriteria: {} } }));
    await dispatch(homeTagsSuccess([]));
  };

  const _resetPropertiesPage = async () => {
    setSessionStorage(PAGINATOIN.currentVacantPagination, { filterCriteria: {} });
    setSessionStorage(TAG.currentVacantProperties, []);
    await dispatch(currentVacantPropertyListSuccess({ list: [], pagination: { filterCriteria: {} } }));
    await dispatch(currentVacantPropertiesTagsSuccess([]));
  };

  const _resetAuditPage = async () => {
    setSessionStorage(PAGINATOIN.openAuditPagination, { filterCriteria: {} });
    setSessionStorage(TAG.openAudits, []);
    await dispatch(openAuditsListSuccess({ list: [], pagination: { filterCriteria: {} } }));
    await dispatch(openAuditsTagSuccess([]));
  };

  const _resetAvailabilityPage = async () => {
    setSessionStorage(PAGINATOIN.upcomingPropertyPagination, { filterCriteria: {} });
    setSessionStorage(TAG.upcomingVacantProperties, []);
    await dispatch(upcomingVacantPropertiesTagsSuccess([]));
    await dispatch(upcomingVacantListSuccess({ list: [], pagination: { filterCriteria: {} } }));
  };

  /**
   * Reset filters to initial state
   */
  const _onClickReset = async () => {
    setIsLoading(true);
    await _resetHomePage();
    await _resetAuditPage();
    await _resetPropertiesPage();
    await _resetAvailabilityPage();
    await _resetDropDownValuesInState();
  };

  return (
    <div className="filter-property-horizontal">
      <Form
        requiredMark
        form={form}
        className="filter-form-properties"
        // onFinish={onFormFinish}
        // initialValues={initialValues}
        validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Row gutter={10} className="filter-ant-row">
              <Col xs={24} lg={8} xl={4} xxl={3}>
                <Form.Item name="division">
                  <Select
                    autoClearSearchValue={false}
                    loading={loading}
                    mode="multiple"
                    onChange={_onChangeDivision}
                    showSearch
                    showArrow
                    placeholder={t("PropertiesTable.Division")}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    autoComplete="off"
                    maxTagCount={1}
                    maxTagTextLength={3}
                  >
                    {_map(sortAlphabeticallyBySingleParameter(divisions), division => (
                      <Option key={division} value={division}>
                        {division}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={4} xxl={3}>
                <Form.Item name="region">
                  <Select
                    autoClearSearchValue={false}
                    loading={loading}
                    mode="multiple"
                    onChange={_onChangeRegion}
                    showSearch
                    showArrow
                    placeholder={t("PropertiesTable.Region")}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    autoComplete="off"
                    maxTagCount={1}
                    maxTagTextLength={3}
                  >
                    {_map(sortAlphabeticallyBySingleParameter(regions), region => (
                      <Option key={region} value={region}>
                        {region}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={4} xxl={3}>
                <Form.Item name="market">
                  <Select
                    autoClearSearchValue={false}
                    loading={loading}
                    mode="multiple"
                    onChange={_onChangeMarket}
                    showSearch
                    showArrow
                    placeholder={t("PropertiesTable.Market")}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    autoComplete="off"
                    maxTagCount={1}
                    maxTagTextLength={3}
                  >
                    {_map(sortAlphabetically(selectedMarkets), market => (
                      <Option key={market.id} value={market.id}>
                        {market.id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {searchState === SUGGESTION_SEARCH_STATE.property && (
                <>
                  {SUBMARKET?.status && (
                    <Col xs={24} lg={8} xl={4} xxl={3}>
                      <Form.Item name="subMarket">
                        <Select
                          autoClearSearchValue={false}
                          loading={subMarketLoading}
                          mode="multiple"
                          onChange={_onChangeSubMarket}
                          showSearch
                          showArrow
                          placeholder={t("PropertiesTable.Submarket")}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          autoComplete="off"
                          maxTagCount={1}
                          maxTagTextLength={3}
                        >
                          {_map(sortAlphabetically(selectedSubMarkets), subMarket => (
                            <Option key={subMarket.id} value={subMarket.id}>
                              {subMarket.id}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
              <Col xs={24} lg={8} xl={2} xxl={2}>
                <Button block className="reset-filters-btn" onClick={_onClickReset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

FilterPropertyHorizontal.propTypes = {
  homePagination: PropTypes.object,
  homeTags: PropTypes.array,
  defaultSort: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  searchState: PropTypes.string,
  setIsLoading: PropTypes.func,
};

FilterPropertyHorizontal.defaultProps = {
  searchState: SUGGESTION_SEARCH_STATE.property,
};

export default FilterPropertyHorizontal;
