import { Form } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { useLanguage } from "../../hooks";
import { LANGUAGE_CODES } from "../../utils/constants";

const CustomFormItem = ({ name: fieldName, isUnit, children, ...props }) => {
  const { language } = useLanguage();

  const isEnglish = language.current === LANGUAGE_CODES.English.value;

  const translationFormItemName = {};

  if (!_isEmpty(language.secondary)) {
    language.secondary.forEach(lang => {
      if (isUnit) {
        translationFormItemName[lang] = [...fieldName.slice(0, 1), "translations", lang, ...fieldName.slice(1)];
      } else {
        translationFormItemName[lang] = ["translations", lang, ...fieldName];
      }
    });
  }

  return (
    <>
      <Form.Item
        {...props}
        style={{
          display: isEnglish ? "block" : "none",
        }}
        className={isEnglish ? `${props.className} form-item-primary` : props.className}
        name={fieldName}
      >
        {children}
      </Form.Item>
      {language.secondary &&
        language.secondary.map(lang => (
          <Form.Item
            key={lang}
            {...props}
            style={{
              display: isEnglish || language.current !== lang ? "none" : "block",
            }}
            className={
              isEnglish || language.current !== lang ? props.className : `${props.className} form-item-secondary`
            }
            name={translationFormItemName[lang]}
          >
            {children}
          </Form.Item>
        ))}
    </>
  );
};

CustomFormItem.propTypes = {
  name: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.any,
  isUnit: PropTypes.bool,
};

export default CustomFormItem;
