import uploadService from "../../services/upload.service";
import { ERROR_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  UPLOAD_LOADING: "loading",
};

export function uploadRequest(key = LOADING.UPLOAD_LOADING) {
  return { type: ACTIONS.UPLOAD_REQUEST, key };
}

export function uploadError(error) {
  return { type: ACTIONS.UPLOAD_ERROR, error };
}

export function uploadSuccess() {
  return { type: ACTIONS.UPLOAD_SUCCESS };
}

export function fileUpload(files) {
  return async dispatch => {
    dispatch(uploadRequest(LOADING.UPLOAD_LOADING));
    try {
      const [err, response] = await to(uploadService.add(files));
      if (err) throwError(err);
      dispatch(uploadSuccess());
      return response;
    } catch (error) {
      dispatch(uploadError(error));
      toastMessage("error", ERROR_MESSAGE.UPLOAD);
      return throwError(error);
    }
  };
}

export function removeAttachment(files) {
  return async dispatch => {
    dispatch(uploadRequest(LOADING.UPLOAD_LOADING));
    try {
      const [err, response] = await to(uploadService.remove(files));
      if (err) throwError(err);
      dispatch(uploadSuccess());
      return response;
    } catch (error) {
      dispatch(uploadError(error));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      return throwError(error);
    }
  };
}
