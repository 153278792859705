import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const unmarketReason = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.UNMARKET_REASONS_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.UNMARKET_REASONS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.value = action.response;
      return draft;
    case ACTIONS.UNMARKET_REASONS_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      return draft;
    case ACTIONS.UNMARKET_PROPERTIES_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.unmarketPropertiesList = action.response.unmarketPropertiesList;
      return draft;
    case ACTIONS.UNMARKET_REASONS_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default unmarketReason;
