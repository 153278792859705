import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const audit = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.AUDIT_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.GET_ALL_AUDITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    case ACTIONS.AUDIT_ERROR:
      draft[action.key] = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.AUDIT_TAGS_SUCCESS:
      draft.tags = action.response;
      return draft;
    case ACTIONS.OPEN_AUDIT_TAG_SUCCESS:
      draft.openAudits = action.response;
      return draft;
    case ACTIONS.OPEN_AUDIT_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.auditPagination = action.response.pagination;
      return draft;
    case ACTIONS.AUDIT_SUGGESTION_SUCCESS:
      draft.suggestionLoading = false;
      draft.error = null;
      return draft;
    default:
      return draft;
  }
});

export default audit;
