import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import { useLocationHash } from "../../hooks";
import permissionsUtil from "../../utils/permissions.util";
import Auditors from "../Auditors/Auditors";
import Brokers from "../Brokers/Brokers";
import EmployeeContacts from "../EmployeeContacts/EmployeeContacts";
import FeatureFlag from "../FeatureFlag/FeatureFlag";
import HelperText from "../HelperText/HelperText";
import Markets from "../Markets/Markets";
import PropertyContactsHistory from "../PropertyContactsHistory/PropertyContactsHistory";
import PropertyRollUp from "../PropertyRollUp/PropertyRollUp";
import Template from "../Template/Template";
import UnmarketReasons from "../UnmarketReasons/UnmarketReasons";
import "./Dashboard.scss";

const { TabPane } = Tabs;

const _tabsKey = {
  1: "employeeContacts",
  2: "brokers",
  3: "auditors",
  4: "markets",
  5: "propertyRollUp",
  6: "contactsHistory",
  7: "helperText",
  8: "templates",
  9: "featureFlag",
  10: "unmarketReason",
};

const DashboardContainer = props => {
  const { t } = useTranslation();
  const _tabs = {
    employeeContacts: { tabTitle: t("DashboardContainerTabs.EmployeeContacts"), key: "1" },
    brokers: { tabTitle: t("DashboardContainerTabs.Brokers"), key: "2" },
    auditors: { tabTitle: t("DashboardContainerTabs.Auditors"), key: "3" },
    markets: { tabTitle: t("DashboardContainerTabs.Markets"), key: "4" },
    propertyRollUp: { tabTitle: t("DashboardContainerTabs.PropertyRollUp"), key: "5" },
    contactsHistory: { tabTitle: t("DashboardContainerTabs.ContactsChangeHistory"), key: "6" },
    helperText: { tabTitle: t("DashboardContainerTabs.HelperText"), key: "7" },
    templates: { tabTitle: t("DashboardContainerTabs.Templates"), key: "8" },
    featureFlag: { tabTitle: t("DashboardContainerTabs.FeatureFlag"), key: "9" },
    unmarketReason: { tabTitle: t("DashboardContainerTabs.UnmarketReason"), key: "10" },
  };
  const [type, setType] = useState(_tabsKey[1]);
  const { activeTabKey, setActiveTabKey } = useLocationHash({
    defaultTabKey: _tabs.employeeContacts.key,
    _tabs,
    _tabsKey,
  });

  useEffect(() => {
    const activeTab = window.location.hash?.split("#")?.pop();
    if (activeTab) {
      setType(activeTab);
    }
  }, []);

  const _onTabChange = acKey => {
    setType(_tabsKey[acKey]);
    setActiveTabKey(acKey);
    window.location.hash = _tabsKey[acKey];
  };

  const _renderTabTitle = _id => _tabs[_id].tabTitle;

  const canViewUser = permissionsUtil.checkAuth("user_view");
  const canViewMarket = permissionsUtil.checkAuth("market_view");
  const canViewPropertyRollUp = permissionsUtil.checkAuth("property_roll_up_view");
  const canExportPropertyContactHistory = permissionsUtil.checkAuth("property_contact_history_export");
  const canViewHelperText = permissionsUtil.checkAuth("helper_text_view");
  const canViewTemplate = permissionsUtil.checkAuth("template_view");
  const canViewFeatureFlag = permissionsUtil.checkAuth("feature_flag_view");
  const canViewUnmarketReason = permissionsUtil.checkAuth("unmarket_reason_view");

  return (
    <div className="dashboard">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AdminSearch tabsKey={_tabsKey} type={type} />
          <Tabs className="tabs__container" onChange={_onTabChange} activeKey={activeTabKey}>
            {canViewUser && (
              <>
                <TabPane
                  className="tabs__container-item"
                  tab={_renderTabTitle(_tabsKey[1])}
                  key={_tabs.employeeContacts.key}
                >
                  <EmployeeContacts />
                </TabPane>
                <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[2])} key={_tabs.brokers.key}>
                  <Brokers />
                </TabPane>
                {/* <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[3])} key={_tabs.auditors.key}>
                  <Auditors />
                </TabPane> */}
              </>
            )}
            {canViewMarket && (
              <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[4])} key={_tabs.markets.key}>
                <Markets />
              </TabPane>
            )}
            {canViewPropertyRollUp && (
              <TabPane
                className="tabs__container-item"
                tab={_renderTabTitle(_tabsKey[5])}
                key={_tabs.propertyRollUp.key}
              >
                <PropertyRollUp />
              </TabPane>
            )}

            {canExportPropertyContactHistory && (
              <TabPane
                className="tabs__container-item"
                tab={_renderTabTitle(_tabsKey[6])}
                key={_tabs.contactsHistory.key}
              >
                <PropertyContactsHistory />
              </TabPane>
            )}
            {canViewHelperText && (
              <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[7])} key={_tabs.helperText.key}>
                <HelperText />
              </TabPane>
            )}
            {canViewTemplate && (
              <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[8])} key={_tabs.templates.key}>
                <Template />
              </TabPane>
            )}
            {canViewFeatureFlag && (
              <TabPane className="tabs__container-item" tab={_renderTabTitle(_tabsKey[9])} key={_tabs.featureFlag.key}>
                <FeatureFlag />
              </TabPane>
            )}
            {canViewUnmarketReason && (
              <TabPane
                className="tabs__container-item"
                tab={_renderTabTitle(_tabsKey[10])}
                key={_tabs.unmarketReason.key}
              >
                <UnmarketReasons />
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardContainer;
