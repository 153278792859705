import { Button, Col, Form, Row, Spin, Tag, Tooltip, Typography } from "antd";
import _map from "lodash/map";
import _find from "lodash/find";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SortableHandle } from "react-sortable-hoc";
import { DragOutlined } from "@ant-design/icons";
import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import ListView from "../../components/ListView/ListView";
import PopUp from "../../components/PopUp/PopUp";
import UnmarketReasonsEditor from "../../components/UnmarketReasonsEditor/UnmarketReasonsEditor";
import {
  deleteAndUpdateUnmarketReasons,
  exportUnmarketProperties,
  getAllUnmarketPropertiesList,
  getAllUnmarketReasons,
  updateUnmarketReasonsOrder,
} from "../../store/actions/unmarketReasons.action";
import { PROPERTY_TYPE_UI_MAPPING, TEMPLATE_TYPE_UI_MAPPING } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import PageTitle from "../../components/PageTitle/PageTitle";

import "./UnmarketReason.scss";
import { renderPropertyStatus, renderUnmarketDateAndLabel, wrapUnmarketInTag } from "../../utils/property.util";
import UnmarketPropertiesForm from "../../components/UnmarketPropertiesForm/UnmarketPropertiesForm";
import { toastMessage } from "../../utils/utils";

const { Text } = Typography;

const DragHandle = SortableHandle(() => (
  <DragOutlined
    style={{
      cursor: "grab",
      fontSize: "16px",
      padding: "0 6px",
    }}
  />
));

const UnmarketReasons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const canEditUnmarketReasons = permissionsUtil.checkAuth("unmarket_reason_edit");
  const canCreateUnmarketReasons = permissionsUtil.checkAuth("unmarket_reason_create");

  const { list, loading, unmarketPropertiesList } = useSelector(state => state.unmarketReason);

  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedUnmarketReason, setSelectedUnmarketReason] = useState(null);
  const [popupVisible, togglePopupVisibility] = useState(null);
  const [isDraggable, setIsDraggable] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [unmarketReasonListModalOpened, setUnmarketReasonListModalOpened] = useState(false);
  const [updateUnmarketReasonModal, setUpdateUnmarketReasonModal] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState("");

  useEffect(() => {
    if (unmarketPropertiesList.length) {
      setPopupTitle(
        t("Constants.ConfirmMessages.DeleteUnmarketReasonWithCount", { count: unmarketPropertiesList.length }),
      );
    } else {
      setPopupTitle(t("Constants.ConfirmMessages.DeleteUnmarketReason", { count: unmarketPropertiesList.length }));
    }
  }, [unmarketPropertiesList]);

  /**
   * fetch list of unmarked reasons
   */
  const fetchUnmarketReasons = async () => {
    try {
      await dispatch(getAllUnmarketReasons());
    } catch (ex) {
      console.log(ex);
    }
  };

  const toggleModal = modalState => {
    setIsModalOpened(modalState);
  };

  const toggleUnmarketReasonModal = async (id, modalState) => {
    if (unmarketPropertiesList?.length > 0) {
      setUnmarketReasonListModalOpened(modalState);
    } else {
      try {
        await dispatch(deleteAndUpdateUnmarketReasons(id, []));
        fetchUnmarketReasons();
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  /**
   * delete and update unmarked reasons
   */
  const deleteRecord = async () => {
    const unmarketProperties = form.getFieldValue();

    return form
      .validateFields()
      .then(async () => {
        const properties = unmarketPropertiesList.map(property => {
          const _property = unmarketProperties[property.id];

          if (unmarketProperties?.isSameReason) {
            const _sameUnmarketReason = _find(list, resason => resason.id === unmarketProperties.sameReason.value);
            const _data = {
              propertyCode: property?.propertyCode,
              propertyType: property?.propertyType,
              propertyStatus: property?.propertyStatus,
              lastUnmarketDate: property?.lastUnmarketDate,
              unmarketReason: unmarketProperties.sameReason.value,
              unmarketReasonLabel: _sameUnmarketReason.unmarketingReason,
              ...(unmarketProperties.sameReason?.unmarketAdditionalInformation && {
                unmarketAdditionalInformation: unmarketProperties.sameReason.unmarketAdditionalInformation,
              }),
              ...(unmarketProperties.sameReason?.unmarketLeaseDate && {
                unmarketLeaseDate: unmarketProperties.sameReason.unmarketLeaseDate
                  ? dayjs(unmarketProperties.sameReason.unmarketLeaseDate).valueOf()
                  : undefined,
              }),
              dateFieldLabel: _sameUnmarketReason.dateFieldLabel,
            };
            return { ..._data };
          }
          const _unmarketReason = _find(list, resason => resason.id === _property.value);
          const _data = {
            propertyCode: property?.propertyCode,
            propertyType: property?.propertyType,
            propertyStatus: property?.propertyStatus,
            lastUnmarketDate: property?.lastUnmarketDate,
            unmarketReason: _property.value,
            unmarketReasonLabel: _unmarketReason.unmarketingReason,
            ...(_property?.unmarketAdditionalInformation && {
              unmarketAdditionalInformation: _property.unmarketAdditionalInformation,
            }),
            ...(_property?.unmarketLeaseDate && {
              unmarketLeaseDate: _property.unmarketLeaseDate ? dayjs(_property.unmarketLeaseDate).valueOf() : undefined,
            }),
            dateFieldLabel: _unmarketReason.dateFieldLabel,
          };
          return { ..._data };
        });

        await dispatch(deleteAndUpdateUnmarketReasons(selectedReasonId, properties));
        fetchUnmarketReasons();
        setUpdateUnmarketReasonModal(false);
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        toastMessage("error", errorFields?.[0]?.errors?.[0]);
      });
  };

  const _onCloseModal = () => {
    setSelectedUnmarketReason(null);
    toggleModal(false);
  };

  const _onUnmarketReasonCloseModal = () => {
    setUnmarketReasonListModalOpened(false);
    setSelectedReasonId("");
  };

  const getAllUnmarketProperties = async reasonId => {
    togglePopupVisibility(null);
    try {
      await dispatch(getAllUnmarketPropertiesList(reasonId));
      togglePopupVisibility(reasonId);
    } catch (err) {
      console.log("err: ", err);
      togglePopupVisibility(null);
    }
  };

  const handlePopoverVisibleChange = async (visible, reasonId) => {
    setSelectedReasonId(reasonId);
    if (!visible) {
      togglePopupVisibility(null);
    } else {
      await getAllUnmarketProperties(reasonId);
    }
  };

  const openUpdateUnmarketReasonModal = () => {
    setUnmarketReasonListModalOpened(false);
    setUpdateUnmarketReasonModal(true);
  };

  const _onCloseUpdateUnmarketReasonModal = () => {
    setUpdateUnmarketReasonModal(false);
  };

  const renderTypes = types => {
    if (types && types.length) {
      return _map(types, _type =>
        _type ? (
          <Tag key={_type} className="text-capitalize" color="processing">
            {TEMPLATE_TYPE_UI_MAPPING[_type]}
          </Tag>
        ) : null,
      );
    }
    return t("Table.NA");
  };

  const columns = [
    {
      title: t("Table.UnmarketReasons.Reason"),
      dataIndex: "unmarketingReason",
      key: "unmarketingReason",
      sorter: (a, b) => a.unmarketingReason.localeCompare(b.unmarketingReason),
      render: text => (
        <>
          {isDraggable && <DragHandle />}
          {text}
        </>
      ),
    },
    {
      title: t("Table.Template.Type"),
      dataIndex: "type",
      key: "type",
      render: text => renderTypes(text),
      columnFilter: true,
      width: 200,
    },
    {
      title: t("Table.UnmarketReasons.IncludeAdditionalInformation"),
      dataIndex: "moreInformationRequired",
      key: "moreInformationRequired",
      render: text => (text ? t("Table.True") : t("Table.False")),
    },
  ];

  if (canEditUnmarketReasons) {
    columns.push({
      title: t("Table.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 150,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Buttons.Edit")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => {
                // if (ev.keyCode === 13) toggleModal(true, record.id);
              }}
              onClick={() => {
                setSelectedUnmarketReason(record);
                toggleModal(true);
              }}
            >
              <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Edit")}</p>
            </div>
          </Tooltip>
          <Tooltip placement="bottom" title={t("Buttons.Remove")}>
            <div className="action-buttons-item" role="button" tabIndex={0} onKeyDown={ev => {}}>
              <PopUp
                title={popupTitle}
                onConfirm={() => toggleUnmarketReasonModal(record.id, true)}
                okText={t("Buttons.Yes")}
                cancelText={t("Buttons.No")}
                className="action-buttons-item"
                placement="topRight"
                visible={popupVisible === record.id}
                onVisibleChange={visible => handlePopoverVisibleChange(visible, record.id)}
              >
                <CustomIcon name="deleteIcon" size={22} />
                <p style={{ color: "#5C5E5D" }}>{t("Buttons.Remove")}</p>
              </PopUp>
            </div>
          </Tooltip>
        </div>
      ),
    });
  }

  const _renderUnmarketStatus = property => wrapUnmarketInTag(property.lastUnmarketDate);

  const unmarketColumns = [
    {
      title: t("PropertiesTable.PropertyCode"),
      dataIndex: "propertyCode",
      key: "propertyCode",
      fixed: "left",
      width: 170,
      render: (text, record) => (
        <>
          <Text type="success">{text}</Text>
          {record?.isDraft && (
            <>
              <Tag className="text-capitalize float-right" color="warning">
                {t("PropertyStatusUiMapping.draft")}
              </Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: t("SearchProperty.PropertyStatus"),
      dataIndex: "propertyStatus",
      key: "propertyStatus",
      width: 170,
      render: text => renderPropertyStatus(text),
    },
    {
      title: t("SearchProperty.PropertyType"),
      dataIndex: "propertyType",
      key: "propertyType",
      width: 120,
      render: text => PROPERTY_TYPE_UI_MAPPING[text],
    },
    {
      title: t("UnitVacancy.NotMarketingStateWithTimeStamp"),
      key: "lastUnmarketDate",
      dataIndex: "lastUnmarketDate",
      width: 350,
      render: (text, record) => _renderUnmarketStatus(record),
    },
    {
      title: t("UnitVacancy.NotMarketingReason"),
      key: "unmarketReason",
      dataIndex: "unmarketReason",
      width: 300,
      render: (text, record) => record?.unmarketReason?.unmarketingReason || record?.unmarketReason || "",
    },
    {
      title: t("UnitVacancy.AdditionalInformation"),
      key: "unmarketAdditionalInformation",
      dataIndex: "unmarketAdditionalInformation",
      width: 300,
      render: (text, record) => record.unmarketAdditionalInformation && text,
    },
    {
      title: t("UnitVacancy.UnavailableReasonDate"),
      key: "unmarketLeaseDate",
      dataIndex: "unmarketLeaseDate",
      width: 200,
      render: (text, record) => renderUnmarketDateAndLabel(record),
    },
  ];

  const getUnmarketReasons = () =>
    list.map(item => ({
      index: item.order,
      ...item,
    }));

  const data = getUnmarketReasons();
  const [dragTableData, setDragTableData] = useState(data);

  useEffect(() => {
    setDragTableData(data);
  }, [list]);

  /**
   * updated order of unmarked reasons list
   */
  const onUpdateOrder = async () => {
    const payload = dragTableData.map((item, index) => ({
      order: dragTableData.length - index - 1,
      id: item.id,
    }));
    try {
      await dispatch(updateUnmarketReasonsOrder(payload));
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      fetchUnmarketReasons();
      setIsDraggable(false);
    }
  };

  const exportAction = async () => {
    try {
      await dispatch(exportUnmarketProperties(selectedReasonId));
    } catch (ex) {
      console.log(ex);
    }
  };

  const notMarketedModalTitle = () => (
    <>
      <Row gutter={[16, 0]} align="middle">
        <Col span={8}>
          <Text type="success">
            {t("UnitUnmarket.PropertiesWithReason")}
            &nbsp;
            {unmarketPropertiesList[0]?.unmarketReason}
          </Text>
        </Col>
        <Col span={8} offset={7}>
          <Row gutter={[8, 16]} justify="end">
            <Col xs={5}>
              <Button type="primary" block disabled={loading} onClick={openUpdateUnmarketReasonModal}>
                {t("Buttons.Next")}
              </Button>
            </Col>
            <Col xs={7}>
              <Button type="primary" block onClick={exportAction} loading={loading}>
                {t("Table.Export")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <PageTitle title="Properties Not Marketed" />
    </>
  );

  return (
    <>
      {loading ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <div className="helper-text-container">
        <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
          {canCreateUnmarketReasons && (
            <>
              {isDraggable ? (
                <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                  <Button block type="primary" onClick={onUpdateOrder}>
                    {t("Buttons.Save")}
                  </Button>
                </Col>
              ) : (
                <>
                  <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                    <Button block type="primary" onClick={() => setIsDraggable(true)}>
                      {t("Buttons.UpdateOrder")}
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
                    <Button block type="primary" onClick={() => toggleModal(true)}>
                      {t("Table.UnmarketReasons.Create")}
                    </Button>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <ListView
          setFilterInfo={setFilterInfo}
          setSortInfo={setSortInfo}
          rowKey="id"
          dataSource={getUnmarketReasons()}
          getList={fetchUnmarketReasons}
          columns={columns}
          scroll={{ y: "calc(100vh - 500px)" }}
          loading={loading}
          isDraggable={isDraggable}
          dragTableData={dragTableData}
          setDragTableData={setDragTableData}
        />

        <AppModal
          title={
            selectedUnmarketReason
              ? t("Table.UnmarketReasons.EditUnmarketReason")
              : t("Table.UnmarketReasons.CreateUnmarketReason")
          }
          width={600}
          visible={isModalOpened}
          onCancel={_onCloseModal}
          footer={null}
          destroyOnClose
        >
          <UnmarketReasonsEditor
            selectedUnmarketReason={selectedUnmarketReason}
            onSuccess={fetchUnmarketReasons}
            onCloseModal={_onCloseModal}
          />
        </AppModal>

        <AppModal
          title={notMarketedModalTitle()}
          width="90vw"
          visible={unmarketReasonListModalOpened}
          onCancel={_onUnmarketReasonCloseModal}
          footer={null}
          destroyOnClose
          className="unmarket-properties"
        >
          <ListView
            rowKey="id"
            dataSource={unmarketPropertiesList}
            columns={unmarketColumns}
            loading={loading}
            scroll={{ y: "calc(100vh - 500px)" }}
          />
        </AppModal>

        <AppModal
          title={t("UnitUnmarket.UnmarketPropertiesTitle")}
          width={700}
          minHeight="20vh"
          maxHeight="70vh"
          overFlowY="auto"
          visible={updateUnmarketReasonModal}
          onCancel={_onCloseUpdateUnmarketReasonModal}
          destroyOnClose
          footer={
            <Row justify="center" gutter={24}>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Button block disabled={loading} onClick={_onCloseUpdateUnmarketReasonModal}>
                  {t("Buttons.Discard")}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={10} lg={10}>
                <Button block type="primary" loading={loading} onClick={deleteRecord}>
                  {t("Buttons.UpdateAndRemoveReason")}
                </Button>
              </Col>
            </Row>
          }
        >
          <UnmarketPropertiesForm
            unmarketData={unmarketPropertiesList}
            form={form}
            selectedReasonId={selectedReasonId}
          />
        </AppModal>
      </div>
    </>
  );
};

export default UnmarketReasons;
