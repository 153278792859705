import { getQueryParams, getSearchListQuery } from "../utils/utils";
import { get, put } from "./http.service";

const landVacancyService = {
  searchLandVacancy: query => get(`property/search`, { params: getQueryParams(query) }),
  suggestions: query => get(`property/suggestion${getSearchListQuery(query)}`),
  unmarketLand: body => put(`land/unmarket`, body),
  marketLand: body => put(`land/market`, body),
};
export default landVacancyService;
