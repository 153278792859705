import { Button, Col, Popconfirm, Row, Tooltip, Dropdown, Menu, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";

import ListView from "../../components/ListView/ListView";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import AppModal from "../../components/AppModal/AppModal";
import HelperTextEditor from "../../components/HelperTextEditor/HelperTextEditor";
import { deleteHelperText, exportHelperTexts, getAllHelperTexts } from "../../store/actions/helperText.actions";

import permissionsUtil from "../../utils/permissions.util";

import "./HelperText.scss";
import { getDangerouslySetHTML } from "../../utils/utils";
import { FIELD_TYPE_UI_MAPPING, LANGUAGE_CODES } from "../../utils/constants";
import PopUp from "../../components/PopUp/PopUp";
import CustomTableFilterList from "../../components/CustomTableFilterList/CustomTableFilterList";

const LANGUAGE_OPTIONS = _sortBy(
  Object.keys(LANGUAGE_CODES).map(_lang => ({
    label: LANGUAGE_CODES[_lang].label,
    value: LANGUAGE_CODES[_lang].value,
  })),
  l => l.label,
);

const HelperText = () => {
  // const canCreateMarket = permissionsUtil.checkAuth("market_create");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const canEditHelperText = permissionsUtil.checkAuth("helper_text_edit");
  const canExportHelperText = permissionsUtil.checkAuth("helper_text_export");
  const canCreateHelperText = permissionsUtil.checkAuth("helper_text_create");

  const { list, loading } = useSelector(state => state.helperText);

  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedLang, setSelectedLang] = useState(LANGUAGE_CODES.English);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [exportingHelperText, setExportingHelperText] = useState(false);
  const { Option } = Select;

  const fetchHelperTexts = async () => {
    try {
      await dispatch(getAllHelperTexts());
    } catch (ex) {
      console.log(ex);
    }
  };

  const toggleModal = modalState => {
    setIsModalOpened(modalState);
  };

  const deleteRecord = async id => {
    try {
      await dispatch(deleteHelperText(id));
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      fetchHelperTexts();
      togglePopupVisibility(false);
    }
  };

  const getModalTitle = () => {
    let title = "";
    if (selectedField) {
      const { type, fieldLabel } = selectedField;
      title = fieldLabel;

      if (type) {
        title = `${FIELD_TYPE_UI_MAPPING[type]}: ${title}`;
      }
    } else {
      title = "Create New Helper Text";
    }

    return title;
  };

  const onPressMenuItem = key => {
    setSelectedLang(key);
  };

  const exportAction = async () => {
    try {
      setExportingHelperText(true);
      await dispatch(exportHelperTexts());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingHelperText(false);
    }
  };

  const _onCloseModal = () => {
    setSelectedField(null);
    toggleModal(false);
  };

  const columns = [
    {
      title: t("Table.FieldLabel"),
      dataIndex: "fieldLabel",
      key: "fieldLabel",
      sorter: (a, b) => a.fieldLabel.localeCompare(b.fieldLabel),
      columnFilter: true,
    },
    {
      title: t("Table.FieldType"),
      dataIndex: "type",
      key: "type",
      render: text => <div>{FIELD_TYPE_UI_MAPPING[text]}</div>,
      sorter: (a, b) => a.type.localeCompare(b.type),
      columnFilter: true,
    },
    {
      title: t("Table.PrimaryLanguage"),
      dataIndex: "helperTextDiv",
      key: "helperTextDiv",
    },
    {
      title: t("Table.SecondaryLanguage"),
      dataIndex: "helperTextDivTranslated",
      key: "helperTextDivTranslated",
      filterDropdown: options => (
        <CustomTableFilterList
          {...options}
          dataSource={LANGUAGE_OPTIONS}
          language={selectedLang}
          setValue={setSelectedLang}
        />
      ),
      filterIcon: () => (
        <>
          <span className={`language-filter-placeholder ${_isEmpty(selectedLang) ? "placeholder" : ""}`}>
            {selectedLang?.label || "Code"}
            &nbsp;
          </span>
          <CustomIcon name="DownOutlined" className="custom-table-filter-icon" />
        </>
      ),
      onFilter: true,
    },
  ];

  if (canEditHelperText) {
    columns.push({
      title: t("Table.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 150,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Buttons.Edit")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => {
                // if (ev.keyCode === 13) toggleModal(true, record.id);
              }}
              onClick={() => {
                setSelectedField(record);
                toggleModal(true);
              }}
            >
              <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Edit")}</p>
            </div>
          </Tooltip>
          <Tooltip placement="bottom" title={t("Buttons.Remove")}>
            <PopUp
              title={t("Constants.ConfirmMessages.DeleteHelperText")}
              onConfirm={() => deleteRecord(record.id)}
              okText={t("Buttons.Yes")}
              cancelText={t("Buttons.No")}
              className="action-buttons-item"
              placement="topRight"
              popupVisible={popupVisible}
              onVisibleChange={() => togglePopupVisibility(!popupVisible)}
            >
              <CustomIcon name="deleteIcon" size={22} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Remove")}</p>
            </PopUp>
          </Tooltip>
        </div>
      ),
    });
  }

  const getHelperTexts = () =>
    list.map(item => ({
      ...item,
      helperTextDiv: getDangerouslySetHTML(item.helperText),
      helperTextDivTranslated: getDangerouslySetHTML(_get(item, `translations[${selectedLang?.value}].helperText`, "")),
    }));

  return (
    <div className="helper-text-container">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canExportHelperText && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block onClick={exportAction} loading={exportingHelperText}>
              {t("Table.Export")}
            </Button>
          </Col>
        )}
        {canCreateHelperText && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block type="primary" onClick={() => toggleModal(true)}>
              {t("Table.HelperText.Create")}
            </Button>
          </Col>
        )}
      </Row>
      <ListView
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        dataSource={getHelperTexts()}
        getList={fetchHelperTexts}
        columns={columns}
        scroll={{ y: "calc(100vh - 500px)" }}
        loading={loading}
      />
      <AppModal
        title={getModalTitle()}
        width={700}
        visible={isModalOpened}
        onCancel={_onCloseModal}
        footer={null}
        destroyOnClose
      >
        <HelperTextEditor helperTextItem={selectedField} onSuccess={fetchHelperTexts} onCloseModal={_onCloseModal} />
      </AppModal>
    </div>
  );
};

export default HelperText;
