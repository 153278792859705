import { Button, Card, Checkbox, Col, Collapse, Form, Input, Row, Select, Tag, Tooltip, Typography } from "antd";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _pick from "lodash/pick";
import _some from "lodash/some";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import CustomFormItem from "../../components/CustomFormItem/CustomFormItem";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import CustomTooltipHelper from "../../components/CustomTooltipHelper/CustomTooltipHelper";
import DraftComments from "../../components/DraftComments/DraftComments";
import LabelWithSwitch from "../../components/LabelWithSwitch/LabelWithSwitch";
import PopUp from "../../components/PopUp/PopUp";
import { useLanguage } from "../../hooks";
import {
  buildingUOMSuccess,
  publishedUnitsSuccess,
  updateCombinedUnitNumber,
} from "../../store/actions/building.actions";
import {
  BUILDING_REQUIRED_FIELDS_LOOKUP,
  BUILDING_TABS_KEYS,
  CLEAR_HEIGHT_MEASUREMENT_UNITS,
  FIELD_TYPE,
  FIRE_SUPPRESSION_SYSTEMS,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  HELPER_TEXT_KEYS,
  KEY_FEATURE_TEMPLATE_KEYS,
  MEASUREMENT_UNITS,
  PROPERTY_STATUS,
  PROPERTY_STATUS_UI_MAPPING,
  PROPERTY_TYPE_UI_MAPPING,
  WAREHOUSE_LIGHTING_TYPE,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import {
  getPropertyDraftVersion,
  getPropertyModifiedDate,
  getUnitLabel,
  wrapFutureInTag,
  wrapVacantInTag,
  wrapUnmarketInTag,
} from "../../utils/property.util";
import {
  filterOption,
  getFeatureFlags,
  getTemplateList,
  getTooltip,
  isPDFFileType,
  sortAlphabetically,
  toastMessage,
  validateAlphaNumericWithoutSpace,
  validateDependant,
  validateInteger,
  validateMarketingDescription,
  validateMinMax,
  validateMinValue,
  validateNumericField,
} from "../../utils/utils";
import PDFIcon from "../PropertyMedia/PDFIcon";
import UnitCharacteristics from "./UnitCharacteristics";
import "./UnitInfoForm.scss";
import UnitKeyFeature from "./UnitKeyFeature";
import UnitMediaModal from "./UnitMediaModal";

const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;

const ClearHeightUOM = ({ fieldName, updateUnitsUOM, uom, viewOnlyMode, t }) => (
  <Form.Item
    label={t("UnitInfoForm.ClearHeightUOM")}
    name={[fieldName, "clearHeightUOM"]}
    dependencies={[["unitInfo", fieldName, "clearHeight"]]}
    noStyle
    initialValue={uom}
  >
    <Select
      style={{ width: 140 }}
      placeholder={t("UnitInfoForm.SelectUOM")}
      onChange={_uom => updateUnitsUOM(fieldName, _uom)}
      disabled={viewOnlyMode}
    >
      {_map(sortAlphabetically(CLEAR_HEIGHT_MEASUREMENT_UNITS), _unit => (
        <Option key={_unit.value} value={_unit.value}>
          {_unit.text}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const OfficeSizeUOM = ({ fieldName, viewOnlyMode, disableOfficeSizeUOM, updateUnitsUOM, uom, form, t }) => {
  const _disableOfficeSizeUOM = viewOnlyMode || disableOfficeSizeUOM;
  let _uom;
  const officeSize = form.getFieldValue(["unitInfo", fieldName, "officeSize"]);
  if (officeSize) {
    _uom = uom;
  }
  return (
    <Form.Item
      label="Office Size UOM"
      name={[fieldName, "officeSizeUOM"]}
      dependencies={[["unitInfo", fieldName, "officeSize"]]}
      noStyle
      rules={[
        () => ({
          validator(rule, value) {
            return !officeSize && value ? Promise.reject(new Error(`Office Size is required!`)) : Promise.resolve();
          },
        }),
      ]}
      initialValue={_uom}
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("UnitInfoForm.SelectUOM")}
        onChange={u => updateUnitsUOM(fieldName, u)}
        disabled={_disableOfficeSizeUOM}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MezzanineSpaceUOM = ({ fieldName, viewOnlyMode, disableMezzanineSpaceUOM, updateUnitsUOM, uom, form, t }) => {
  const _disableMezzanineSpaceUOM = viewOnlyMode || disableMezzanineSpaceUOM;
  let _uom;
  const mezzanineSpace = form.getFieldValue(["unitInfo", fieldName, "mezzanineSpace"]);
  if (mezzanineSpace) {
    _uom = uom;
  }
  return (
    <Form.Item
      label="Mezzanine Space UOM"
      name={[fieldName, "mezzanineSpaceUOM"]}
      dependencies={[["unitInfo", fieldName, "mezzanineSpace"]]}
      noStyle
      rules={[
        () => ({
          validator(rule, value) {
            return !mezzanineSpace && value
              ? Promise.reject(new Error(`Mezzanine Space is required!`))
              : Promise.resolve();
          },
        }),
      ]}
      initialValue={_uom}
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("UnitInfoForm.SelectUOM")}
        onChange={u => updateUnitsUOM(fieldName, u)}
        disabled={_disableMezzanineSpaceUOM}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MaxSpaceAvailableUOM = ({ fieldName, updateUnitsUOM, uom, viewOnlyMode, t }) => (
  <Form.Item
    label={t("UnitInfoForm.MaxSpaceAvailableUOM")}
    name={[fieldName, "maxSpaceAvailableUOM"]}
    dependencies={[["unitInfo", fieldName, "maxSpaceAvailable"]]}
    noStyle
    initialValue={uom}
  >
    <Select
      style={{ width: 140 }}
      placeholder={t("UnitInfoForm.SelectUOM")}
      onChange={_uom => updateUnitsUOM(fieldName, _uom)}
      disabled={viewOnlyMode}
    >
      {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
        <Option key={_unit.value} value={_unit.value}>
          {_unit.text}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const MinSpaceAvailableUOM = ({ fieldName, updateUnitsUOM, uom, viewOnlyMode, t }) => (
  <Form.Item
    label={t("UnitInfoForm.MinSpaceAvailableUOM")}
    name={[fieldName, "minSpaceAvailableUOM"]}
    dependencies={[["unitInfo", fieldName, "minSpaceAvailable"]]}
    noStyle
    initialValue={uom}
  >
    <Select
      style={{ width: 140 }}
      placeholder={t("UnitInfoForm.SelectUOM")}
      onChange={_uom => updateUnitsUOM(fieldName, _uom)}
      disabled={viewOnlyMode}
    >
      {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
        <Option key={_unit.value} value={_unit.value}>
          {_unit.text}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const UnitInfoForm = ({
  form,
  isEditView,
  propertyStatus,
  selectedProperty,
  selectedPropertyUnits,
  save,
  updateAvailableUnits,
  viewOnlyMode,
  removeUnit,
  fieldName,
  previewFile,
  notAvailable,
  updateNotAvailableFields,
  clearHeightUOM,
  setClearHeightUOM,
  auditPanelDrawerVisible,
  ...collapsePanelProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { publishedUnits, uom, selected, maxCombinedUnitNumber, loading } = useSelector(state => ({
    ...state.building,
  }));
  const { unitKeyFeatureTemplateList } = useSelector(state => ({
    unitKeyFeatureTemplateList: getTemplateList(state.template.list, "keyFeatures", "unit"),
  }));
  const [disableOfficeSizeUOM, setDisableOfficeSizeUOM] = useState(true);
  const [disableMezzanineSpaceUOM, setDisableMezzanineSpaceUOM] = useState(true);
  const [keyword, setKeyword] = useState(undefined);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [fileSelector, setFileSelector] = useState({});
  const [useSingleMedia, setUseSingleMedia] = useState(false);
  const [translatedFile, setTranslatedFile] = useState({});
  const [_unitDraftCommentsModal, _setUnitDraftCommentsModal] = useState(false);
  const unitOptions = form.getFieldValue(["unitInfo", fieldName, "unitOptions"]);
  const [options, setOptions] = useState(unitOptions);
  const { lookupList } = useSelector(state => state.helperText);
  const { selectedPropertyMedia } = useSelector(state => ({
    ...state.property,
  }));
  const { language } = useLanguage();
  const fieldsType = FORM_FIELDS_TYPE.regular;

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    setOptions(unitOptions);
  }, [unitOptions]);

  useEffect(() => {
    const _publishedUnits = _filter(
      form.getFieldValue("unitInfo"),
      u => u?.status === PROPERTY_STATUS.published && !u?.isDraft,
    );
    dispatch(publishedUnitsSuccess(_publishedUnits));
  }, [form.getFieldValue("unitInfo")]);

  useEffect(() => {
    const _unitInfo = { ...form.getFieldValue(["unitInfo", fieldName]) };
    if (!_unitInfo?.officeSize) {
      setDisableOfficeSizeUOM(true);
    } else {
      setDisableOfficeSizeUOM(false);
    }
  }, [form.getFieldValue(["unitInfo", fieldName, "officeSize"])]);

  useEffect(() => {
    const _unitInfo = { ...form.getFieldValue(["unitInfo", fieldName]) };
    if (!_unitInfo?.mezzanineSpace) {
      setDisableMezzanineSpaceUOM(true);
    } else {
      setDisableMezzanineSpaceUOM(false);
    }
  }, [form.getFieldValue(["unitInfo", fieldName, "mezzanineSpace"])]);

  const canPublishUnit = permissionsUtil.checkAuth("unit_publish");
  const canEditUnit = permissionsUtil.checkAuth("unit_edit");
  const canUnpublishUnit = permissionsUtil.checkAuth("unit_unpublish");

  const updateUnitFields = (index, unitCode) => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));
    const propertyCode = _cloneDeep(form.getFieldValue(["buildingInfo", "propertyCode"]));

    _forEach(_unitInfo[index].unitCode, _unitCode => {
      _unitCode.label = getUnitLabel(_unitCode.label)?.replace(" (New Unit Code)", "");
    });

    if (unitCode.length <= 1) {
      const _unit = _find(selectedPropertyUnits, { unitId: unitCode[0]?.value });

      const _fields = _pick(_unit?.characteristics, [
        "warehouseLightingType",
        "clearHeight",
        "gradeLevelDoors",
        "fireSuppressionSystem",
        "dockHighDoors",
        "mainBreakerSize",
      ]);

      const clearHeight = _fields?.clearHeight || undefined;
      const mainBreakerSize = _fields?.mainBreakerSize || undefined;
      const gradeLevelDoors =
        (!notAvailable.unitInfo[fieldName]?.gradeLevelDoors && _fields?.gradeLevelDoors) || undefined;
      const dockHighDoors = (!notAvailable.unitInfo[fieldName]?.dockHighDoors && _fields?.dockHighDoors) || undefined;

      _unitInfo[index] = {
        ..._unitInfo[index],
        unitId: _unit?.unitId,
        unitCode: _unitInfo[index].unitCode,
        clearHeight,
        gradeLevelDoors,
        dockHighDoors,
        warehouseLightingType: filterOption(WAREHOUSE_LIGHTING_TYPE, _fields?.warehouseLightingType),
        fireSuppressionSystem: filterOption(FIRE_SUPPRESSION_SYSTEMS, _fields?.fireSuppressionSystem),
        mainBreakerSize,
      };
      if (_unitInfo[index].ghostUnitCode) {
        _unitInfo[index].ghostUnitCode = undefined;
      }
      if (!_unit) {
        _unitInfo[index].isUnmarket = false;
      } else {
        _unitInfo[index].isUnmarket = _unitInfo[index].unitOptions.find(
          _unitOption => _unitOption.value === _unitInfo[index].unitCode[0].value,
        ).isUnmarket;
      }
    } else {
      _unitInfo[index] = {
        unitCode: _unitInfo[index].unitCode,
        status: PROPERTY_STATUS.never_published,
        ghostUnitCode: _unitInfo[index].ghostUnitCode,
        unitOptions: _unitInfo[index].unitOptions,
        minSpaceAvailableUOM: _unitInfo[index]?.minSpaceAvailableUOM,
        maxSpaceAvailableUOM: _unitInfo[index]?.maxSpaceAvailableUOM,
      };

      if (!_unitInfo[index].ghostUnitCode) {
        _unitInfo[index] = {
          ..._unitInfo[index],
          ghostUnitCode: `${propertyCode.value}~MRG${maxCombinedUnitNumber + 1}`,
        };

        dispatch(updateCombinedUnitNumber(maxCombinedUnitNumber + 1));
      }

      const unitIds = _unitInfo[index].unitCode.map(_unitId => _unitId.value);
      _unitInfo[index].isUnmarket = _unitInfo[index].unitOptions.some(
        _unitOption => unitIds.indexOf(_unitOption.value) !== -1 && _unitOption.isUnmarket,
      );
    }

    form.setFieldsValue({ unitInfo: _unitInfo });
  };

  const updateUnitsUOM = async (index, _uom) => {
    await dispatch(buildingUOMSuccess(_uom));
    const keysToValidate = ["minSpaceAvailable", "maxSpaceAvailable"];
    if (!_isEmpty(form.getFieldValue(["unitInfo", index, "officeSize"]))) {
      keysToValidate.push("officeSize");
    }
    if (!_isEmpty(form.getFieldValue(["unitInfo", index, "mezzanineSpace"]))) {
      keysToValidate.push("mezzanineSpace");
    }
    validateDependant(form, "unitInfo", keysToValidate, index);
  };

  const updateClearHeightUOM = (index, _uom) => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));
    _map(_unitInfo, (v, i) => {
      _unitInfo[i] = { ..._unitInfo[i], clearHeightUOM: _uom };
    });
    setClearHeightUOM(_uom);

    form.setFieldsValue({ unitInfo: _unitInfo });
    validateDependant(form, "unitInfo", ["clearHeight"], index);
  };

  const _saveUnit = (newStatus, index, isDraft, handleSaveSuccess) => {
    const _unitInfo = [...form.getFieldValue("unitInfo")];
    _unitInfo[index] = { ..._unitInfo[index], status: newStatus };
    const resMessageStatus = isDraft ? "saved" : PROPERTY_STATUS_UI_MAPPING[newStatus];

    // for separate unit's save function
    save(_unitInfo, index, newStatus, `Unit ${resMessageStatus} successfully!`, isDraft, handleSaveSuccess);
  };

  const _onSearch = input => {
    if (!validateAlphaNumericWithoutSpace(input)) {
      setKeyword(keyword || "");
      return;
    }
    const _input = input?.toLocaleUpperCase();
    const _options = _filter(unitOptions, option => {
      const uCode = getUnitLabel(option.label)?.split("~").pop();
      return uCode.toLocaleUpperCase().indexOf(_input) >= 0;
    });
    setOptions(!_isEmpty(input) ? _options : unitOptions);
    setKeyword(_input);
  };

  let _options = [];
  const isNewCode = !_some(options, option => {
    const uCode = getUnitLabel(option.label)?.split("~").pop();
    return uCode.toLocaleUpperCase() === keyword;
  });
  if (!_isEmpty(keyword) && isNewCode) {
    const _propertyCode = _get(form.getFieldValue("buildingInfo"), "propertyCode.value");
    _options = [
      {
        label: `${_propertyCode}~${keyword} (New Unit Code)`,
        value: `${_propertyCode}~${keyword}`,
        isAvailable: true,
        isNew: true,
      },
    ];
  }
  if (!_isEmpty(options)) {
    _options = [..._options, ...options];
  }

  const isTemporary = useMemo(() => {
    const _currentUnitCodes = _get(form.getFieldValue("unitInfo"), `[${fieldName}].unitCode`);
    return (
      _currentUnitCodes?.length === 1 &&
      _get(_find(selectedPropertyUnits, { unitId: _currentUnitCodes[0]?.value }), "isNew", false)
    );
  }, [selectedProperty.units, _get(form.getFieldValue("unitInfo"), `[${fieldName}].unitCode`)]);

  const _onChangeToggle = (key, index) => {
    updateNotAvailableFields(key, "unitInfo", index);
  };

  const _onChangeMaxSpaceAvailable = async () => {
    validateDependant(form, "unitInfo", ["maxSpaceAvailableUOM"], fieldName);
  };

  const notAvailableParams = {
    gradeLevelDoors: {},
    dockHighDoors: {},
  };
  if (notAvailable.unitInfo[fieldName]?.gradeLevelDoors) {
    notAvailableParams.gradeLevelDoors = {
      validateStatus: "",
      help: "",
      placeholder: t("Property.NotApplicable"),
      rules: [],
    };
  }
  if (notAvailable.unitInfo[fieldName]?.dockHighDoors) {
    notAvailableParams.dockHighDoors = {
      validateStatus: "",
      help: "",
      placeholder: t("Property.NotApplicable"),
      rules: [],
    };
  }

  const ghostUnitCode = form.getFieldValue(["unitInfo", fieldName, "ghostUnitCode"]);
  const unitCode = form.getFieldValue(["unitInfo", fieldName, "unitCode"]);

  const _onSelect = async (selectedItem, isDeselected = false) => {
    const isTempUnit = !_some(unitOptions, _unitOption => selectedItem.value === _unitOption.value);

    if (isTempUnit) {
      let _unitInfo = form.getFieldValue("unitInfo");
      _unitInfo = _map(_unitInfo, _unit => ({
        ..._unit,
        unitOptions: [
          ..._unit.unitOptions,
          {
            label: getUnitLabel(selectedItem.value),
            value: selectedItem.value,
            isAvailable: true,
            isNew: true,
          },
        ],
      }));

      await form.setFieldsValue({ unitInfo: _unitInfo });
    }
    updateAvailableUnits([selectedItem.value], fieldName, isDeselected);
  };

  const updateOfficeSizeUOM = value => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));

    if (_isEmpty(value)) {
      _unitInfo[fieldName] = {
        ..._unitInfo[fieldName],
        officeSizeUOM: undefined,
      };

      form.setFieldsValue({ unitInfo: _unitInfo });
      setDisableOfficeSizeUOM(true);
    } else {
      setDisableOfficeSizeUOM(false);
    }
  };

  const updateMezzanineSpaceUOM = value => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));

    if (_isEmpty(value)) {
      _unitInfo[fieldName] = {
        ..._unitInfo[fieldName],
        mezzanineSpaceUOM: undefined,
      };

      form.setFieldsValue({ unitInfo: _unitInfo });
      setDisableMezzanineSpaceUOM(true);
    } else {
      setDisableMezzanineSpaceUOM(false);
    }
  };

  /**
   * Display the status of a unit along with the date
   */
  const _renderStatus = index => (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const _status = _get(getFieldValue("unitInfo"), `[${index}].status`);
        const _unitInfo = getFieldValue("unitInfo");
        let _unit;

        if (_unitInfo) {
          _unit = _unitInfo[fieldName];
        }

        return (
          <>
            <Form.Item name={[index, "status"]} initialValue={PROPERTY_STATUS.never_published} noStyle>
              {getPropertyModifiedDate(_status, _unit, FIELD_TYPE.unit)}
              {_unit.isDraft && <>&nbsp; |{getPropertyDraftVersion(_unit.updatedAt)}</>}
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );

  const _renderUnmarketTag = index => (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const _unitInfo = getFieldValue("unitInfo");
        let _unit;

        if (_unitInfo) {
          _unit = _unitInfo[fieldName];
        }

        return (
          <>
            {_unit.isUnmarket && (
              <Form.Item name={[index, "isUnmarket"]} noStyle>
                {wrapUnmarketInTag()}
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.Item>
  );

  const _renderVacantTag = index => (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const _unitInfo = getFieldValue("unitInfo");
        let _unit;

        if (_unitInfo) {
          _unit = _unitInfo[fieldName];
        }

        return (
          <>
            {_unit?.isVacant && (
              <Form.Item name={[index, "isVacant"]} noStyle>
                {wrapVacantInTag(_unit.vacantDate)}
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.Item>
  );

  const _renderVacantFutureTag = index => (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const _unitInfo = getFieldValue("unitInfo");
        let _unit;

        if (_unitInfo) {
          _unit = _unitInfo[fieldName];
        }

        return (
          <>
            {_unit?.isFuture && (
              <Form.Item name={[index, "isFutureVacant"]} noStyle>
                {wrapFutureInTag(_unit.futureDate)}
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.Item>
  );

  const _onDraftCommentsModalClose = () => {
    _setUnitDraftCommentsModal(false);
  };

  const _onDraftCommentsModalOpen = () => {
    const _unitInfo = form.getFieldValue("unitInfo");

    if (selected?.unitInfo[fieldName]?.isDraft) {
      form.setFieldsValue({
        unitInfo: _unitInfo.map(unitInfoItem => {
          if (unitInfoItem.isDraft) {
            _unitInfo[fieldName].draftComments = selected?.unitInfo[fieldName]?.draftComments;
          }
          return { ...unitInfoItem };
        }),
      });
    } else {
      form.setFieldsValue({
        unitInfo: _unitInfo.map(unitInfoItem => ({
          ...unitInfoItem,
          draftComments: !unitInfoItem.isDraft && undefined,
        })),
      });
    }
    _setUnitDraftCommentsModal(true);
  };

  const getPopupTitle = _fieldName => {
    const _unitInfo = form.getFieldValue("unitInfo");
    const { isUnmarket } = _unitInfo[_fieldName];

    if (isUnmarket) {
      return t("UnitInfoForm.ConfirmUnmarketedPublish");
    }
    return t("UnitInfoForm.ConfirmUnitPublish");
  };

  const handleSaveSuccess = () => {
    if (FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status) {
      _setUnitDraftCommentsModal(false);
    }
  };

  const unitDraft = () => {
    const unitStatus = _get(form.getFieldValue("unitInfo"), `[${fieldName}].status`);
    _saveUnit(unitStatus, fieldName, true, handleSaveSuccess);
  };

  const _renderFormActionButtons = () =>
    publishedUnits.length === 1 &&
    publishedUnits[0].unitCode[0]?.value === _get(form.getFieldValue("unitInfo"), `[${fieldName}].unitCode[0].value`) &&
    propertyStatus === PROPERTY_STATUS.published &&
    !selected.isDraft ? null : (
      <Row justify="center" align="middle">
        {form.getFieldValue(["unitInfo", fieldName, "status"]) !== PROPERTY_STATUS.published && (
          <Col className="header-action-buttons" onClick={removeUnit}>
            <CustomIcon name="deleteIcon" size={22} className="action-buttons-item" />
            <p>{t("Buttons.Remove")}</p>
          </Col>
        )}

        {FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status ? (
          <Col className="header-action-buttons" onClick={_onDraftCommentsModalOpen}>
            <CustomIcon name="SaveFilled" size={34} style={{ fontSize: "21px", color: "#08c" }} />
            <p>{t("Buttons.SaveForLater")}</p>
          </Col>
        ) : (
          <Col className="header-action-buttons" onClick={unitDraft}>
            <CustomIcon name="SaveFilled" size={34} style={{ fontSize: "21px", color: "#08c" }} />
            <p>{t("Buttons.SaveForLater")}</p>
          </Col>
        )}

        {propertyStatus === PROPERTY_STATUS.published &&
          !selected.isDraft &&
          canUnpublishUnit &&
          form.getFieldValue(["unitInfo", fieldName, "status"]) === PROPERTY_STATUS.published && (
            <Col className="header-action-buttons">
              <PopUp
                title={t("UnitInfoForm.ConfirmUnitUnpublish")}
                onConfirm={() => _saveUnit(PROPERTY_STATUS.unpublished, fieldName)}
                okText={t("Buttons.Yes")}
                cancelText={t("Buttons.No")}
                popupVisible={popupVisible}
                onVisibleChange={() => togglePopupVisibility(!popupVisible)}
              >
                <CustomIcon name="unpublish" size={22} />
                <p>{t("Buttons.Unpublish")}</p>
              </PopUp>
            </Col>
          )}
        {propertyStatus === PROPERTY_STATUS.published &&
          !selected.isDraft &&
          canPublishUnit &&
          form.getFieldValue(["unitInfo", fieldName, "status"]) !== PROPERTY_STATUS.published && (
            <Col className="header-action-buttons">
              <PopUp
                title={getPopupTitle(fieldName)}
                onConfirm={() => _saveUnit(PROPERTY_STATUS.published, fieldName)}
                okText={t("Buttons.Yes")}
                cancelText={t("Buttons.No")}
                popupVisible={popupVisible}
                onVisibleChange={() => togglePopupVisibility(!popupVisible)}
              >
                <CustomIcon name="publish" size={22} />
                <p>{t("Buttons.Publish")}</p>
              </PopUp>
            </Col>
          )}
      </Row>
    );

  const updateUnitMarketingCollateral = (index, key, value, translatedPayload, _useSingleMedia) => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));
    _unitInfo[index][key] = value;

    if (_useSingleMedia) {
      _forEach(language.secondary, _lang => {
        if (!_isEmpty(_unitInfo[index].translations) && _unitInfo[index].translations[_lang]) {
          _unitInfo[index].translations[_lang] = {
            ..._unitInfo[index].translations[_lang],
            [key]: value,
          };
        } else {
          _unitInfo[index].translations = {
            ..._unitInfo[index].translations,
            [_lang]: { [key]: value },
          };
        }
      });
    } else if (!_isEmpty(translatedPayload)) {
      _forEach(language.secondary, _lang => {
        if (!_isEmpty(translatedPayload?.[_lang]?.[key])) {
          _unitInfo[index].translations = {
            ..._unitInfo[index]?.translations,
            [_lang]: {
              ..._unitInfo[index]?.translations?.[_lang],
              [key]: translatedPayload[_lang][key][0],
            },
          };
        } else {
          _unitInfo[index].translations = {
            ..._unitInfo[index]?.translations,
            [_lang]: {
              ..._unitInfo[index]?.translations?.[_lang],
              [key]: undefined,
            },
          };
        }
      });
    } else {
      _forEach(language.secondary, _lang => {
        _unitInfo[index].translations = {
          ..._unitInfo[index]?.translations,
          [_lang]: {
            ..._unitInfo[index]?.translations?.[_lang],
            [key]: undefined,
          },
        };
      });
    }

    setUseSingleMedia(_useSingleMedia);
    form.setFieldsValue({ unitInfo: _unitInfo });
  };

  const _saveMedia = (file, translatedPayload, _useSingleMedia) => {
    updateUnitMarketingCollateral(
      fileSelector.name,
      "unitMarketingCollateral",
      file,
      translatedPayload,
      _useSingleMedia,
    );
  };

  const _discardMedia = () => {
    setFileSelector({});
    setTranslatedFile({});
    setUseSingleMedia(false);
  };

  const _onClickSelectUnitCollateral = () => {
    const unit = _get(form.getFieldsValue(["unitInfo"]), `unitInfo[${fieldName}]`, {});
    if (!_isEmpty(unit?.unitCode)) {
      setFileSelector({
        name: fieldName,
        file: unit.unitMarketingCollateral,
      });

      let _translatedFiles = {};
      _forEach(language.secondary, _lang => {
        if (!_isEmpty(unit.translations?.[_lang]?.unitMarketingCollateral)) {
          _translatedFiles = {
            ..._translatedFiles,
            [_lang]: {
              name: fieldName,
              file: unit.translations[_lang].unitMarketingCollateral,
            },
          };
        }
      });
      setTranslatedFile(_translatedFiles);
    } else toastMessage("error", t("ErrorMessages.SelectUnitCode"));
  };

  const _renderUnitCollateral = () => {
    const unitCollateralKey = `unitInfo[${fieldName}].unitMarketingCollateral`;
    let translatedUnitCollateralKey = {};
    _forEach(language.secondary, _lang => {
      translatedUnitCollateralKey = {
        ...translatedUnitCollateralKey,
        [_lang]: `unitInfo[${fieldName}].translations.${_lang}.unitMarketingCollateral`,
      };
    });
    const unitMarketingCollateralFieldName =
      language.current === "en" ? unitCollateralKey : translatedUnitCollateralKey[language.current];
    const file = _get(form.getFieldsValue(["unitInfo"]), unitMarketingCollateralFieldName, {});
    const _unitCode = _get(form.getFieldsValue(["unitInfo"]), `unitInfo[${fieldName}].unitCode`, {});
    if (_isEmpty(_unitCode[0]?.value)) return null;
    if (
      (!_isEmpty(_get(form.getFieldsValue(["unitInfo"]), unitCollateralKey, {})) ||
        !_some(language.secondary, _lang =>
          _isEmpty(_get(form.getFieldsValue(["unitInfo"]), translatedUnitCollateralKey[_lang], {})),
        )) &&
      !_some(
        language.secondary,
        _lang =>
          _get(form.getFieldsValue(["unitInfo"]), unitCollateralKey, {})?.asset_id !==
          _get(form.getFieldsValue(["unitInfo"]), translatedUnitCollateralKey[_lang], {})?.asset_id,
      )
    ) {
      setUseSingleMedia(true);
    }
    if (_isEmpty(file)) return <Text type="secondary">{t("ErrorMessages.NoFileAttached")}</Text>;
    return (
      <>
        <CustomFormItem name={[fieldName, "unitMarketingCollateral"]} isUnit>
          <div className="item-image-container" onDoubleClick={() => previewFile(file)}>
            <img className="item-image" src={file.thumbnails_webimage_url} alt={file.asset_name} />
            <PDFIcon item={file} isPDF size={35} className="pdf" />
            <div>{file.asset_name}</div>
            <div>{file.asset_id}</div>
          </div>
        </CustomFormItem>
      </>
    );
  };

  const allFiles = useMemo(() => _filter(selectedPropertyMedia, f => isPDFFileType(f)), [selectedPropertyMedia]);
  const actionsButtonVisible =
    !viewOnlyMode &&
    isEditView &&
    canEditUnit &&
    propertyStatus !== PROPERTY_STATUS.never_published &&
    !selected.isDraft;

  const isDraftCommentAvailable =
    FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status &&
    !_isEmpty(selected) &&
    selected?.unitInfo[fieldName]?.isDraft &&
    selected?.unitInfo[fieldName]?.draftComments;

  return (
    <Panel
      {...collapsePanelProps}
      header={
        <div className="unit-header">
          <div className="unit-header-icon-container">
            {isTemporary ? (
              <Tooltip title="Temporary Unit">
                <CustomIcon name="FileProtectOutlined" style={{ color: "green" }} />
              </Tooltip>
            ) : (
              form.getFieldValue(["unitInfo", fieldName, "ghostUnitCode"]) && <CustomIcon name="mergedUnit" size={14} />
            )}
          </div>
          {ghostUnitCode ||
            (unitCode && getUnitLabel(unitCode[0]?.label)) ||
            `${t("UnitInfoForm.Unit")} # ${fieldName + 1} `}
          {_renderStatus(fieldName)}
          {form.getFieldValue(["unitInfo", fieldName, "isUnmarket"]) && _renderUnmarketTag(fieldName)}
          {form.getFieldValue(["unitInfo", fieldName, "isVacant"]) && _renderVacantTag(fieldName)}
          {form.getFieldValue(["unitInfo", fieldName, "isFutureVacant"]) && _renderVacantFutureTag(fieldName)}
        </div>
      }
      className="unit-info-form"
      forceRender
    >
      <Row gutter={[20, 0]} key={`unitCard-${fieldName}`}>
        <Col xs={24} sm={24} md={16} lg={16} className="unit-info-form-col">
          <Card
            extra={
              actionsButtonVisible
                ? _renderFormActionButtons()
                : !viewOnlyMode &&
                  canEditUnit && (
                    <Row justify="center" align="middle">
                      <Col className="header-action-buttons" onClick={removeUnit}>
                        <CustomIcon name="deleteIcon" size={22} className="action-buttons-item" />
                        <p>{t("Buttons.Remove")}</p>
                      </Col>
                    </Row>
                  )
            }
            className="unit-info-form-card-container"
          >
            <Row className="fields-row" gutter={20}>
              <Col md={18} className="mg-minus-top">
                {isDraftCommentAvailable && (
                  <Collapse defaultActiveKey={["1"]} className="ant-collapse-warning">
                    <Panel
                      header={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.unit })}
                      key="1"
                    >
                      <p className="mg-bottom-0">{selected?.unitInfo[fieldName]?.draftComments}</p>
                    </Panel>
                  </Collapse>
                )}
              </Col>
              <Col span={8} xs={24} sm={12} lg={12}>
                <Form.Item
                  tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.unit.unitCode], language)}
                  label={t("UnitInfoForm.UnitCode")}
                  name={[fieldName, "unitCode"]}
                  fieldKey={[fieldName, "unitCode"]}
                  rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.unitCode }]}
                  className="unit-code"
                  initialValue={[]}
                >
                  <Select
                    searchValue={keyword}
                    onSearch={_onSearch}
                    labelInValue
                    showSearch
                    showArrow={false}
                    placeholder={t("UnitInfoForm.SelectUnitCode")}
                    filterOption={false}
                    autoComplete="off"
                    onChange={_unitCode => updateUnitFields(fieldName, _unitCode)}
                    onSelect={selectedItem => _onSelect(selectedItem)}
                    onDeselect={selectedItem => _onSelect(selectedItem, true)}
                    onBlur={() => _onSearch()}
                    disabled={viewOnlyMode || !canEditUnit}
                    mode="multiple"
                    listHeight={300}
                    tagRender={({ label, onClose }) => (
                      <Tag closable={!viewOnlyMode || !canEditUnit} onClose={onClose}>
                        {getUnitLabel(label)}
                      </Tag>
                    )}
                  >
                    {_map(sortAlphabetically(_options) || [], _unit => (
                      <Option
                        key={_unit.value}
                        value={_unit.value}
                        disabled={!_unit.isAvailable}
                        className="unit-code-item"
                      >
                        <Checkbox
                          disabled={!_unit.isAvailable}
                          checked={form
                            .getFieldValue(["unitInfo", fieldName, "unitCode"])
                            ?.some(item => item.value === _unit.value)}
                        />
                        &nbsp;
                        {getUnitLabel(_unit.label)}
                        &nbsp;
                        {_unit.isNew && <CustomIcon name="FileProtectOutlined" style={{ color: "green" }} />}
                        &nbsp;
                        {_unit.isUnmarket && wrapUnmarketInTag()}
                        &nbsp;
                        {_unit.leaseInfo && _unit.leaseInfo.leaseStatus === "N/A" && wrapVacantInTag()}
                        &nbsp;
                        {_unit.leaseInfo && _unit.leaseInfo.leaseStatus === "Current" && wrapFutureInTag(_unit)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("BuildingInfo.MinSpaceAvailable")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.minSpaceAvailable.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.minSpaceAvailable]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "minSpaceAvailable"]}
                  dependencies={[
                    ["unitInfo", fieldName, "maxSpaceAvailable"],
                    ["unitInfo", fieldName, "minSpaceAvailableUOM"],
                  ]}
                  fieldKey={[fieldName, "minSpaceAvailable"]}
                  validateFirst
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.minSpaceAvailable,
                      message: t("BuildingInfo.MinSpaceAvailableRulesMessage"),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("BuildingInfo.MinSpaceAvailable"), 100),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        return validateMinMax({
                          currValue: value,
                          depValue: getFieldValue(["unitInfo", fieldName, "maxSpaceAvailable"]),
                          type: "max",
                          fieldName: t("BuildingInfo.MinSpaceAvailable"),
                          depLabel: t("UnitInfoForm.MaxAvailableSpace"),
                        });
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const isUOMEmpty = _isEmpty(getFieldValue(["unitInfo", fieldName, "minSpaceAvailableUOM"]));
                        return isUOMEmpty && value
                          ? Promise.reject(new Error(`Min Space Available UOM is required!`))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    min="100"
                    step="any"
                    placeholder={t("BuildingInfo.MinSpaceAvailable")}
                    disabled={viewOnlyMode || !canEditUnit}
                    onChange={() => validateDependant(form, "unitInfo", ["minSpaceAvailableUOM"], fieldName)}
                    addonAfter={
                      <MinSpaceAvailableUOM
                        fieldName={fieldName}
                        updateUnitsUOM={updateUnitsUOM}
                        uom={uom}
                        viewOnlyMode={viewOnlyMode || !canEditUnit}
                        t={t}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("BuildingInfo.MaxSpaceAvailable")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.maxSpaceAvailable.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.maxSpaceAvailable]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "maxSpaceAvailable"]}
                  dependencies={[
                    ["unitInfo", fieldName, "minSpaceAvailable"],
                    ["unitInfo", fieldName, "maxSpaceAvailableUOM"],
                  ]}
                  fieldKey={[fieldName, "maxSpaceAvailable"]}
                  validateFirst
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.maxSpaceAvailable,
                      message: t("BuildingInfo.MaxSpaceAvailableRulesMessage"),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("BuildingInfo.MaxSpaceAvailable"), 100),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        return validateMinMax({
                          currValue: value,
                          depValue: getFieldValue(["unitInfo", fieldName, "minSpaceAvailable"]),
                          type: "min",
                          fieldName: t("BuildingInfo.MaxSpaceAvailable"),
                          depLabel: t("UnitInfoForm.MinAvailableSpace"),
                        });
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const isUOMEmpty = _isEmpty(getFieldValue(["unitInfo", fieldName, "maxSpaceAvailableUOM"]));
                        return isUOMEmpty && value
                          ? Promise.reject(new Error(`Max Space Available UOM is required!`))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    min="100"
                    step="any"
                    placeholder={t("BuildingInfo.MaxSpaceAvailable")}
                    disabled={viewOnlyMode || !canEditUnit}
                    onChange={_onChangeMaxSpaceAvailable}
                    addonAfter={
                      <MaxSpaceAvailableUOM
                        fieldName={fieldName}
                        updateUnitsUOM={updateUnitsUOM}
                        uom={uom}
                        viewOnlyMode={viewOnlyMode || !canEditUnit}
                        t={t}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitInfoForm.OfficeSize")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.officeSize.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.officeSize]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "officeSize"]}
                  dependencies={[["unitInfo", fieldName, "officeSizeUOM"]]}
                  fieldKey={[fieldName, "officeSize"]}
                  validateFirst
                  rules={[
                    {
                      validator: (rule, value) => validateMinValue(value, "Office Size", 1),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const isUOMEmpty = _isEmpty(getFieldValue(["unitInfo", fieldName, "officeSizeUOM"]));
                        return isUOMEmpty && value
                          ? Promise.reject(new Error(`Office Size UOM is required!`))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                  onChange={event => {
                    updateOfficeSizeUOM(event.target.value);
                  }}
                >
                  <Input
                    type="number"
                    min="1"
                    step="any"
                    placeholder={t("UnitInfoForm.OfficeSize")}
                    disabled={viewOnlyMode || !canEditUnit}
                    addonAfter={
                      <OfficeSizeUOM
                        form={form}
                        fieldName={fieldName}
                        viewOnlyMode={viewOnlyMode || !canEditUnit}
                        disableOfficeSizeUOM={disableOfficeSizeUOM}
                        updateUnitsUOM={updateUnitsUOM}
                        uom={uom}
                        t={t}
                      />
                    }
                  />
                </Form.Item>

                <UnitKeyFeature
                  templateList={unitKeyFeatureTemplateList}
                  form={form}
                  viewOnlyMode={viewOnlyMode || !canEditUnit}
                  label="KeyFeatures.KeyFeature1"
                  name={[fieldName, "keyFeatures", "feature1"]}
                  tab={BUILDING_TABS_KEYS[5]}
                  fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.keyFeatures.feature1.key}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                  templateName={[fieldName, "keyFeatures", KEY_FEATURE_TEMPLATE_KEYS.feature1.templateKey]}
                  rules={[
                    {
                      whitespace: true,
                      message: t("BuildingInfo.KeyFeature1WhitespaceMessage"),
                    },
                    {
                      max: 255,
                    },
                    {
                      validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature1")),
                    },
                  ]}
                  unitIndex={fieldName}
                  tooltip={lookupList[HELPER_TEXT_KEYS.unit.keyFeature1]}
                />
                <UnitKeyFeature
                  templateList={unitKeyFeatureTemplateList}
                  form={form}
                  viewOnlyMode={viewOnlyMode || !canEditUnit}
                  label="KeyFeatures.KeyFeature2"
                  name={[fieldName, "keyFeatures", "feature2"]}
                  tab={BUILDING_TABS_KEYS[5]}
                  fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.keyFeatures.feature2.key}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                  templateName={[fieldName, "keyFeatures", KEY_FEATURE_TEMPLATE_KEYS.feature2.templateKey]}
                  rules={[
                    {
                      whitespace: true,
                      message: t("BuildingInfo.KeyFeature2WhitespaceMessage"),
                    },
                    {
                      max: 255,
                    },
                    {
                      validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature2")),
                    },
                  ]}
                  unitIndex={fieldName}
                  tooltip={lookupList[HELPER_TEXT_KEYS.unit.keyFeature2]}
                />
                <UnitKeyFeature
                  templateList={unitKeyFeatureTemplateList}
                  form={form}
                  viewOnlyMode={viewOnlyMode || !canEditUnit}
                  label="KeyFeatures.KeyFeature3"
                  name={[fieldName, "keyFeatures", "feature3"]}
                  tab={BUILDING_TABS_KEYS[5]}
                  fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.keyFeatures.feature3.key}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                  templateName={[fieldName, "keyFeatures", KEY_FEATURE_TEMPLATE_KEYS.feature3.templateKey]}
                  rules={[
                    {
                      whitespace: true,
                      message: t("BuildingInfo.KeyFeature3WhitespaceMessage"),
                    },
                    {
                      max: 255,
                    },
                    {
                      validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature3")),
                    },
                  ]}
                  unitIndex={fieldName}
                  tooltip={lookupList[HELPER_TEXT_KEYS.unit.keyFeature3]}
                />
                <UnitKeyFeature
                  templateList={unitKeyFeatureTemplateList}
                  form={form}
                  viewOnlyMode={viewOnlyMode || !canEditUnit}
                  label="KeyFeatures.KeyFeature4"
                  name={[fieldName, "keyFeatures", "feature4"]}
                  tab={BUILDING_TABS_KEYS[5]}
                  fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.keyFeatures.feature4.key}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                  templateName={[fieldName, "keyFeatures", KEY_FEATURE_TEMPLATE_KEYS.feature4.templateKey]}
                  rules={[
                    {
                      whitespace: true,
                      message: t("BuildingInfo.KeyFeature4WhitespaceMessage"),
                    },
                    {
                      max: 255,
                    },
                    {
                      validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature4")),
                    },
                  ]}
                  unitIndex={fieldName}
                  tooltip={lookupList[HELPER_TEXT_KEYS.unit.keyFeature4]}
                />
              </Col>
              <Col span={8} xs={24} sm={12} lg={12}>
                <CustomFormItem
                  label={
                    <CustomTooltipHelper
                      label={t("UnitInfoForm.MarketingUnitName")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.marketingUnitName.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.marketingUnitName]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "marketingUnitName"]}
                  fieldKey={[fieldName, "marketingUnitName"]}
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.marketingUnitName,
                      message: t("BuildingInfo.MarketingUnitNameRulesMessage"),
                    },
                    { whitespace: true, message: t("BuildingInfo.MarketingUnitNameWhitespaceMessage") },
                  ]}
                  isUnit
                >
                  <Input placeholder={t("UnitInfoForm.MarketingUnitName")} disabled={viewOnlyMode || !canEditUnit} />
                </CustomFormItem>

                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitCharacteristics.WarehouseLightingType")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.warehouseLightingType.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.warehouseLightingType]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "warehouseLightingType"]}
                  fieldKey={[fieldName, "warehouseLightingType"]}
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.warehouseLightingType,
                      message: t("BuildingInfo.WarehouseLightingTypeRulesMessage"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("UnitCharacteristics.SelectLightingType")}
                    disabled={viewOnlyMode || !canEditUnit}
                  >
                    {_map(sortAlphabetically(WAREHOUSE_LIGHTING_TYPE), _unit => (
                      <Option key={_unit.value} value={_unit.value}>
                        {_unit.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitCharacteristics.ClearHeight")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.clearHeight.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.clearHeight]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "clearHeight"]}
                  dependencies={[["unitInfo", fieldName, "clearHeightUOM"]]}
                  fieldKey={[fieldName, "clearHeight"]}
                  validateFirst
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.clearHeight,
                      message: t("BuildingInfo.ClearHeightRulesMessage"),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("UnitCharacteristics.ClearHeight"), 1),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const isUOMEmpty = _isEmpty(getFieldValue(["unitInfo", fieldName, "clearHeightUOM"]));
                        return isUOMEmpty && value
                          ? Promise.reject(new Error(`Clear Height UOM is required!`))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    min="1"
                    step="any"
                    placeholder={t("UnitCharacteristics.ClearHeight")}
                    disabled={viewOnlyMode || !canEditUnit}
                    onChange={() => validateDependant(form, "unitInfo", ["clearHeightUOM"], fieldName)}
                    addonAfter={
                      <ClearHeightUOM
                        fieldName={fieldName}
                        updateUnitsUOM={updateClearHeightUOM}
                        uom={clearHeightUOM}
                        viewOnlyMode={viewOnlyMode || !canEditUnit}
                        t={t}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitCharacteristics.MainBreakerSizeInAmps")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.mainBreakerSize.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.mainBreakerSize]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "mainBreakerSize"]}
                  fieldKey={[fieldName, "mainBreakerSize"]}
                  validateFirst
                  rules={[
                    {
                      validator: (rule, value) => validateNumericField(value, t("UnitCharacteristics.MainBreakerSize")),
                    },
                  ]}
                  shouldUpdate
                >
                  <Input
                    onKeyDown={e => {
                      if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
                    }}
                    onWheel={e => e.preventDefault()}
                    className="hide-counter"
                    placeholder={t("UnitCharacteristics.MainBreakerSizeInAmps")}
                    disabled={viewOnlyMode || !canEditUnit}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitInfoForm.MezzanineSpace")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.mezzanineSpace.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.mezzanineSpace]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "mezzanineSpace"]}
                  dependencies={[["unitInfo", fieldName, "mezzanineSpaceUOM"]]}
                  fieldKey={[fieldName, "mezzanineSpace"]}
                  validateFirst
                  rules={[
                    {
                      validator: (rule, value) => validateMinValue(value, "Mezzanine Space", 1),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const isUOMEmpty = _isEmpty(getFieldValue(["unitInfo", fieldName, "mezzanineSpaceUOM"]));
                        return isUOMEmpty && value
                          ? Promise.reject(new Error(`Mezzanine Space UOM is required!`))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                  onChange={event => {
                    updateMezzanineSpaceUOM(event.target.value);
                  }}
                >
                  <Input
                    type="number"
                    min="1"
                    step="any"
                    placeholder={t("UnitInfoForm.MezzanineSpace")}
                    disabled={viewOnlyMode || !canEditUnit}
                    addonAfter={
                      <MezzanineSpaceUOM
                        form={form}
                        fieldName={fieldName}
                        viewOnlyMode={viewOnlyMode || !canEditUnit}
                        disableMezzanineSpaceUOM={disableMezzanineSpaceUOM}
                        updateUnitsUOM={updateUnitsUOM}
                        uom={uom}
                        t={t}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <CustomTooltipHelper
                      label={t("UnitCharacteristics.FireSuppressionSystem")}
                      form={form}
                      unitIndex={fieldName}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.fireSuppressionSystem.key}
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.fireSuppressionSystem]}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "fireSuppressionSystem"]}
                  fieldKey={[fieldName, "fireSuppressionSystem"]}
                  rules={[
                    {
                      required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].unitInfo.fireSuppressionSystem,
                      message: t("BuildingInfo.FireSuppressionSystemRulesMessage"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("UnitCharacteristics.SelectFireSuppressionSystem")}
                    disabled={viewOnlyMode || !canEditUnit}
                  >
                    {_map(sortAlphabetically(FIRE_SUPPRESSION_SYSTEMS), _unit => (
                      <Option key={_unit.value} value={_unit.value}>
                        {_unit.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  className="form-item-label-with-switch"
                  label={
                    <LabelWithSwitch
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.gradeLevelDoors]}
                      checked={notAvailable.unitInfo[fieldName]?.gradeLevelDoors}
                      label={t("UnitCharacteristics.GradeLevelDoors")}
                      viewOnlyMode={viewOnlyMode}
                      _onChangeToggle={_onChangeToggle}
                      index={fieldName}
                      form={form}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.gradeLevelDoors.key}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "gradeLevelDoors"]}
                  fieldKey={[fieldName, "gradeLevelDoors"]}
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: t("UnitCharacteristics.GradeLevelDoorsRequired"),
                    },
                    {
                      validator: (_, value) => validateInteger(value, t("UnitCharacteristics.GradeLevelDoors")),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("UnitCharacteristics.GradeLevelDoors"), 1),
                    },
                  ]}
                  {...notAvailableParams.gradeLevelDoors}
                >
                  <Input
                    placeholder={
                      notAvailableParams.gradeLevelDoors.placeholder || t("UnitCharacteristics.GradeLevelDoors")
                    }
                    type="number"
                    min="1"
                    className="w-full"
                    disabled={viewOnlyMode || !canEditUnit || notAvailable.unitInfo[fieldName]?.gradeLevelDoors}
                  />
                </Form.Item>

                <Form.Item
                  className="form-item-label-with-switch"
                  label={
                    <LabelWithSwitch
                      tooltip={lookupList[HELPER_TEXT_KEYS.unit.dockHighDoors]}
                      checked={notAvailable.unitInfo[fieldName]?.dockHighDoors}
                      label={t("UnitCharacteristics.DockHighDoors")}
                      viewOnlyMode={viewOnlyMode}
                      _onChangeToggle={_onChangeToggle}
                      index={fieldName}
                      form={form}
                      tab={BUILDING_TABS_KEYS[5]}
                      fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.dockHighDoors.key}
                      auditPanelDrawerVisible={auditPanelDrawerVisible}
                    />
                  }
                  name={[fieldName, "dockHighDoors"]}
                  fieldKey={[fieldName, "dockHighDoors"]}
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: t("UnitCharacteristics.DockHighDoorsRequired"),
                    },
                    {
                      validator: (_, value) => validateInteger(value, t("UnitCharacteristics.DockHighDoors")),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("UnitCharacteristics.DockHighDoors"), 1),
                    },
                  ]}
                  {...notAvailableParams.dockHighDoors}
                >
                  <Input
                    placeholder={notAvailableParams.dockHighDoors.placeholder || t("UnitCharacteristics.DockHighDoors")}
                    type="number"
                    min="1"
                    className="w-full"
                    disabled={viewOnlyMode || !canEditUnit || notAvailable.unitInfo[fieldName]?.dockHighDoors}
                  />
                </Form.Item>
                <Form.Item
                  className="unit-marketing-collateral-field"
                  label={
                    <>
                      <span>
                        <CustomTooltipHelper
                          label={t("UnitInfoForm.UnitMarketingCollateral")}
                          tooltip={lookupList[HELPER_TEXT_KEYS.unit.unitMarketingCollateral]}
                          form={form}
                          tab={BUILDING_TABS_KEYS[5]}
                          fieldName={FORM_FIELDS_LOOKUP.building.unitInfo.unitMarketingCollateral.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                          unitIndex={fieldName}
                        />
                      </span>
                      {!viewOnlyMode && (
                        <Button
                          size="small"
                          type="default"
                          icon={<CustomIcon name="FileAddOutlined" />}
                          onClick={_onClickSelectUnitCollateral}
                          disabled={viewOnlyMode || !canEditUnit}
                        >
                          {t("UnitInfoForm.SelectFile")}
                        </Button>
                      )}
                    </>
                  }
                  shouldUpdate
                >
                  {_renderUnitCollateral}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} className="unit-info-form-col">
          <Form.Item shouldUpdate>
            {() => {
              const _unitCodes = _get(form.getFieldsValue(["unitInfo"]), `unitInfo[${fieldName}].unitCode`, {});

              return (
                <UnitCharacteristics
                  _unitCodes={_unitCodes}
                  selectedPropertyUnits={selectedPropertyUnits}
                  selectedProperty={selected}
                  form={form}
                  notAvailable={notAvailable}
                  isTemporary={isTemporary}
                  index={fieldName}
                  viewOnlyMode={viewOnlyMode}
                  updateNotAvailableFields={_onChangeToggle}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <AppModal
        className="unit-media-modal"
        title={t("UnitInfoForm.SelectFile")}
        width="80%"
        visible={!_isEmpty(fileSelector)}
        onCancel={_discardMedia}
        maxHeight="100vh"
        overFlowY="auto"
        footer={null}
        destroyOnClose
        isNotDraggable
      >
        <UnitMediaModal
          useSingleMedia={useSingleMedia}
          closeModal={_discardMedia}
          previewFile={previewFile}
          selectedFile={fileSelector.file}
          translatedFile={translatedFile}
          allFiles={allFiles}
          updateFiles={_saveMedia}
        />
      </AppModal>
      <AppModal
        title={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.unit })}
        width="700px"
        visible={_unitDraftCommentsModal}
        onCancel={_onDraftCommentsModalClose}
        destroyOnClose
        className="draft-comment"
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={10} lg={10}>
              <Button block type="primary" loading={loading} onClick={unitDraft}>
                {t("Buttons.Save")}
              </Button>
            </Col>
          </Row>
        }
      >
        <DraftComments form={form} type={FIELD_TYPE.unit} fieldName={fieldName} />
      </AppModal>
    </Panel>
  );
};

UnitInfoForm.propTypes = {
  form: PropTypes.object,
  isEditView: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
  propertyStatus: PropTypes.oneOf([
    PROPERTY_STATUS.published,
    PROPERTY_STATUS.unpublished,
    PROPERTY_STATUS.never_published,
    "",
  ]),
  selectedProperty: PropTypes.object,
  selectedPropertyUnits: PropTypes.array,
  updateAvailableUnits: PropTypes.func,
  save: PropTypes.func,
  removeUnit: PropTypes.func,
  fieldName: PropTypes.number,
  previewFile: PropTypes.func,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  clearHeightUOM: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setClearHeightUOM: PropTypes.func,
  auditPanelDrawerVisible: PropTypes.bool,
};

ClearHeightUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  fieldName: PropTypes.number,
  updateUnitsUOM: PropTypes.func,
  uom: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  t: PropTypes.func,
};

MaxSpaceAvailableUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  fieldName: PropTypes.number,
  updateUnitsUOM: PropTypes.func,
  uom: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  t: PropTypes.func,
};

MinSpaceAvailableUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  fieldName: PropTypes.number,
  updateUnitsUOM: PropTypes.func,
  uom: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  t: PropTypes.func,
};

OfficeSizeUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  fieldName: PropTypes.number,
  disableOfficeSizeUOM: PropTypes.bool,
  updateUnitsUOM: PropTypes.func,
  uom: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  form: PropTypes.object,
  t: PropTypes.func,
};

MezzanineSpaceUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  fieldName: PropTypes.number,
  disableMezzanineSpaceUOM: PropTypes.bool,
  updateUnitsUOM: PropTypes.func,
  uom: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  form: PropTypes.object,
  t: PropTypes.func,
};

export default UnitInfoForm;
