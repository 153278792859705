import { Empty, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import { validateAlphaNumeric } from "../../utils/utils";
import CustomIcon from "../CustomIcon/CustomIcon";
import "./SearchInput.scss";

const { Option } = Select;

const SearchInput = ({
  value,
  setValue,
  onEnter,
  loading,
  onSelectValue,
  onSearch,
  getOptions,
  valuePropName,
  labelPropNames,
  labelSeparator,
  renderOptions,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(undefined);

  const searchRef = useRef();

  const _onEnter = e => {
    if (e.keyCode === 13) {
      onEnter(false);
      searchRef.current.blur();
    } else if ((e.keyCode === 8 || e.keyCode === 46) && searchValue?.length === 1) {
      setSearchValue(undefined);
      setValue([]);
      if (!_isEmpty(value)) onSelectValue(false);
    }
  };

  const _onClear = () => {
    setValue([]);
    setSearchValue(undefined);
    setOptions([]);
    onSelectValue(false);
    searchRef.current.blur();
  };

  const _onSearch = val => {
    if (!validateAlphaNumeric(val)) {
      setSearchValue(searchValue || "");
      return;
    }
    if (val) setValue(val);
    setSearchValue(val);
    onChangeValue(val);
  };

  const _onSelect = (val, option) => {
    setSearchValue(val);
    if (val) {
      setValue(val);
      onSelectValue(false, option);
    }
    searchRef.current.blur();
  };

  const _onFocus = async () => {
    if (!_isEmpty(value)) onChangeValue(value);
  };

  const onChangeValue = useCallback(
    debounce(async val => {
      try {
        let res = [];
        if (val) {
          const result = await getOptions(val);
          res = result.content;
        }
        setOptions(res);
      } catch (err) {
        console.log(err);
      }
    }, 500),
    [],
  );

  const Label = ({ labels, item }) => {
    let label = "";
    let _labelSeparator = labelSeparator;
    if (!_labelSeparator) {
      _labelSeparator = " ";
    }
    labels.forEach(val => {
      if (label) {
        label += _labelSeparator;
      }
      label += item[val];
    });
    return label;
  };

  const _renderOptions = item => {
    if (valuePropName) return item[valuePropName];
    return `${item.id}`;
  };

  const _options = _map(options, d => {
    if (renderOptions) return renderOptions(d);
    return (
      <Option key={d.id} value={d[valuePropName] || d.id}>
        {_renderOptions(d)}
      </Option>
    );
  });

  // const _options = options.map(item => (
  //   <Option key={item.id} value={item[valuePropName]}>
  //     {labelPropNames && labelPropNames.length ? <Label labels={labelPropNames} item={item} /> : item[valuePropName]}
  //   </Option>
  // ));

  const _notFoundContent = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Spin size="default" />
        </div>
      );
    }
    if (!loading && searchValue?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return null;
  };

  return (
    <div className="search-input">
      <Select
        value={value}
        searchValue={searchValue}
        onKeyUp={_onEnter}
        showSearch
        onSearch={_onSearch}
        onSelect={_onSelect}
        onFocus={_onFocus}
        defaultActiveFirstOption={false}
        filterOption={false}
        dropdownClassName="search-dropdown"
        notFoundContent={_notFoundContent()}
        suffixIcon={
          value?.length > 0 ? (
            <CustomIcon onClick={_onClear} name="CloseCircleFilled" />
          ) : (
            <CustomIcon name="SearchOutlined" />
          )
        }
        ref={searchRef}
        {...rest}
      >
        {_options}
      </Select>
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setValue: PropTypes.func,
  onSelectValue: PropTypes.func,
  onEnter: PropTypes.func,
  onSearch: PropTypes.func,
  getOptions: PropTypes.func,
  loading: PropTypes.bool,
  valuePropName: PropTypes.string,
  labelSeparator: PropTypes.string,
  labelPropNames: PropTypes.array,
  renderOptions: PropTypes.func,
};

export default SearchInput;
