import { Row } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  BUILDING_REQUIRED_FIELDS_LOOKUP,
  BUILDING_TABS_KEYS,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  HELPER_TEXT_KEYS,
  PROPERTY_KEY_FEATURE_TEMPLATE_KEYS,
} from "../../utils/constants";
import { getTooltip, validateMarketingDescription } from "../../utils/utils";
import KeyFeature from "../../components/KeyFeature/KeyFeature";
import { useLanguage } from "../../hooks";

const KeyFeatures = ({ viewOnlyMode, form, templateList, auditPanelDrawerVisible }) => {
  const { lookupList } = useSelector(state => state.helperText);
  const { t } = useTranslation();
  const { language } = useLanguage();

  const fieldsType = FORM_FIELDS_TYPE.regular;

  return (
    <>
      <Row className="fields-row" gutter={20}>
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature1]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature1.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature1"
          name={["keyFeatures", "feature1"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature1.templateKey]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].keyFeatures.feature1,
              message: t("BuildingInfo.KeyFeature1RulesMessage"),
            },
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature1WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature1")),
            },
          ]}
        />
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature2]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature2.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature2"
          name={["keyFeatures", "feature2"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature2.templateKey]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].keyFeatures.feature2,
              message: t("BuildingInfo.KeyFeature2RulesMessage"),
            },
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature2WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature2")),
            },
          ]}
        />
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature3]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature3.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature3"
          name={["keyFeatures", "feature3"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature3.templateKey]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].keyFeatures.feature3,
              message: t("BuildingInfo.KeyFeature3RulesMessage"),
            },
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature3WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature3")),
            },
          ]}
        />
      </Row>
      <Row className="fields-row" gutter={20}>
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature4]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature4.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature4"
          name={["keyFeatures", "feature4"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature4.templateKey]}
          rules={[
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature4WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature4")),
            },
          ]}
        />
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature5]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature5.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature5"
          name={["keyFeatures", "feature5"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature5.templateKey]}
          rules={[
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature5WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature5")),
            },
          ]}
        />
        <KeyFeature
          parentId="buildingInfo"
          templateList={templateList}
          form={form}
          viewOnlyMode={viewOnlyMode}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.keyFeature6]}
          tab={BUILDING_TABS_KEYS[2]}
          fieldName={FORM_FIELDS_LOOKUP.building.keyFeatures.feature6.key}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          label="KeyFeatures.KeyFeature6"
          name={["keyFeatures", "feature6"]}
          templateName={["keyFeatures", PROPERTY_KEY_FEATURE_TEMPLATE_KEYS.feature6.templateKey]}
          rules={[
            {
              whitespace: true,
              message: t("BuildingInfo.KeyFeature6WhitespaceMessage"),
            },
            {
              max: 255,
            },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("KeyFeatures.KeyFeature6")),
            },
          ]}
        />
      </Row>
    </>
  );
};
KeyFeatures.propTypes = {
  viewOnlyMode: PropTypes.bool,
  form: PropTypes.object,
  templateList: PropTypes.array,
  auditPanelDrawerVisible: PropTypes.bool,
};
export default KeyFeatures;
