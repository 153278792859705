import React, { useState, useEffect } from "react";
import { Pagination as AntPagination } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _map from "lodash/map";
import { useTranslation } from "react-i18next";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import { DEFAULT_SORTER } from "../../utils/constants";
import { getSorterFilter, getSorterObj, searchQueryGenerator } from "../../utils/utils";
import "./Pagination.scss";

const Pagination = ({ pagination, getList, ...props }) => {
  const { t } = useTranslation();
  // For api query
  const [sorterFilterPage, setSorterFilterPage] = useState(() => {
    let cPage = _get(pagination, "number");
    cPage = _isNumber(cPage) ? cPage + 1 : 1;
    return {
      currentPage: cPage,
      pageSize: _get(pagination, "size", 20),
      sorter: DEFAULT_SORTER,
      filters: {},
    };
  });
  // For antd pagination
  const [_pagination, setPagination] = useState({
    current: sorterFilterPage.currentPage,
    showSizeChanger: true,
    size: "small",
    pageSize: sorterFilterPage.pageSize,
    showQuickJumper: true,
    ...pagination,
  });

  const handlePageChange = async (currentPage, pageSize) => {
    let sorter;
    if (!_isEmpty(pagination.sort)) {
      sorter = getSorterObj(pagination.sort);
    } else {
      sorter = null;
    }
    const payload = {
      ...pagination,
      currentPage,
      pageSize,
      sorter,
      keyword: pagination.keyword,
      filterCriteria: pagination.filterCriteria,
    };

    setSorterFilterPage(getSorterFilter(sorter, payload));
    const query = searchQueryGenerator(payload);
    await getList(query);
    setPagination({ ..._pagination, ...payload });
  };

  const paginationProps = {
    current: _get(pagination, "page", 0) + 1,
    total: _get(pagination, "totalElements", 0),
    showTotal: (total, range) => `${range[0]}-${range[1]} ${t("Pagination.Of")} ${total} ${t("Pagination.Items")}`,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: _pagination.showSizeChanger,
    pageSize: _pagination.pageSize,
    showQuickJumper: _pagination.showQuickJumper,
    size: "small",
    onShowSizeChange: (current, _size) => {
      const paginationWithUpdatedPageSize = _pagination;
      paginationWithUpdatedPageSize.pageSize = _size;
      setPagination(paginationWithUpdatedPageSize);
    },
    onChange: handlePageChange,
  };

  return (
    <div className="custom-pagination">
      <AntPagination {...paginationProps} />
    </div>
  );
};

Pagination.propTypes = {
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalElements: PropTypes.number,
    size: PropTypes.number,
  }),
  getList: PropTypes.func,
};

export default Pagination;
