import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const language = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.SET_LANGUAGE:
      draft = action.language;
      return draft;
    default:
      return draft;
  }
});

export default language;
