import { Alert, Button, Col, Collapse, Form, Row, Switch, Tabs } from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";
import _mapValues from "lodash/mapValues";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Sticky, StickyContainer } from "react-sticky";
import AppModal from "../../components/AppModal/AppModal";
import AuditDrawer from "../../components/AuditDrawer/AuditDrawer";
import Contacts from "../../components/ContactsTab/ContactsTab";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import DraftComments from "../../components/DraftComments/DraftComments";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useLanguage } from "../../hooks";
import { sendAnalyticsEvent } from "../../store/actions/analytics.actions";
import {
  addBuilding,
  buildingCodeSuccess,
  getBuildingById,
  updateBuilding,
  updateBuildingAuditStatus,
  updateUnit,
} from "../../store/actions/building.actions";
import { setLanguage } from "../../store/actions/language.actions";
import {
  ERROR_MESSAGE,
  GTAG_EVENTS,
  HELPER_TEXT_KEYS,
  PROPERTY_AUDIT_STATUS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
  ROUTES,
  BUILDING_TABS_KEYS as _tabsKey,
  UNIT_INFO_KEYS as unitInfoKeys,
  PROPERTY_TYPE_UI_MAPPING,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import {
  getUnitLabel,
  isAsianCountry,
  mapMediaContentWithAlt,
  removeMediaContent,
  renderPropertyStatusWithModifiedDate,
} from "../../utils/property.util";
import { checkEmptyObj, getFeatureFlags, getTooltip, toastMessage } from "../../utils/utils";
import PropertyMediaContainer from "../PropertyMedia";
import AuditIssues from "./AuditIssues";
import "./BuildingFormGroup.scss";
import BuildingInfo from "./BuildingInfo";
import KeyFeatures from "./KeyFeatures";
import UnitInformation from "./UnitInformation";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const BuildingFormGroup = ({
  viewOnlyMode,
  form,
  _tabs,
  status,
  activeTabKey,
  setActiveTabKey,
  notAvailable,
  setNotAvailable,
  disabledStatus,
  isTemporaryProperty,
  setLoadingData,
  _setFiles,
  t,
  mediaErrors,
  setMediaErrors,
  setIsModalOpen,
  errorTabs,
  setErrorTabs,
  onTabChange,
  fetchCountryAddressFields,
  countryAddressFields,
  isEditView,
  _files,
  _translatedFiles,
  editTimestamp,
  isTranslated,
  marketingDescriptionTemplateList,
  keyFeatureTemplateList,
  propertyStatus,
  title,
  onLanguageChange,
  setIsPopulated,
  setIsPreviewActive,
  updateErrorState,
  openModalByAuditPass,
}) => {
  const {
    codeDetail: selectedProperty,
    loading,
    selected,
  } = useSelector(state => ({
    ...state.building,
  }));
  const { loadingMedia, selectedPropertyMedia, auditList } = useSelector(state => ({
    ...state.property,
  }));
  const { lookupList } = useSelector(state => state.helperText);
  const { language, languageStatus } = useLanguage();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [auditPanelDrawerVisible, setAuditPanelDrawerVisible] = useState(false);
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([0]);
  const [auditCountByStatus, setAuditCountByStatus] = useState();
  const [draftCommentsModal, setDraftCommentsModal] = useState(false);
  const canEditProperty = permissionsUtil.checkAuth("property_edit");
  const canAuditProperty = permissionsUtil.checkAuth("audit_add");
  const canViewAuditIssues = permissionsUtil.checkAuth("audit_view");

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    if (history?.location?.state?._tabKey) {
      const { _tabKey, _language, unitCode, shouldOpenDrawer } = history?.location?.state;
      navigateToIssueTab(_tabKey, _language, unitCode, shouldOpenDrawer);
    }
  }, []);

  const _renderTabTitle = _id => {
    const hasError =
      languageStatus === "primary"
        ? errorTabs[languageStatus]?.[_id]
        : errorTabs[languageStatus]?.[language.current]?.[_id];
    return <span className={hasError ? "tab-has-error" : ""}>{_tabs[_id].tabTitle}</span>;
  };

  const _renderAuditTabTitle = (_id, isDisabled) => (
    <span className={isDisabled ? "disable-tab" : ""}>
      {_tabs[_id].tabTitle}
      &nbsp;
      {auditCountByStatus > 0 ? (
        <Button type="primary" shape="circle">
          {auditCountByStatus}
        </Button>
      ) : (
        <></>
      )}
    </span>
  );

  const _updateFiles = (payload, translatedPayload) => {
    const { hero = [], aerial = [], gallery = [], sitePlan = [], propertyMarketingCollateral = [] } = payload;

    if (!_isEmpty(hero)) setMediaErrors({});
    _setFiles({ hero, aerial, gallery, sitePlan, propertyMarketingCollateral }, translatedPayload);
  };

  const onCancel = () => {
    dispatch(sendAnalyticsEvent(GTAG_EVENTS.CANCEL_PROPERTY_VIEW, { propertyCode: id }));
    history.replace(ROUTES.PROPERTIES.path);
  };

  const disableLatLng = !!(
    selected.latitude ||
    selected.longitude ||
    selectedProperty.latitude ||
    selectedProperty.longitude
  );

  const helperText = {
    hero: lookupList[HELPER_TEXT_KEYS.building.hero],
    aerial: lookupList[HELPER_TEXT_KEYS.building.aerial],
    sitePlan: lookupList[HELPER_TEXT_KEYS.building.sitePlan],
    marketingCollateral: lookupList[HELPER_TEXT_KEYS.building.propertyMarketingCollateral],
    imageGallery: lookupList[HELPER_TEXT_KEYS.building.imageGallery],
    propertyAvailable: lookupList[HELPER_TEXT_KEYS.propertyAvailable],
  };

  const contactsHelperText = {
    prologisContact: lookupList[HELPER_TEXT_KEYS.building.prologisContact],
    secondaryContacts: lookupList[HELPER_TEXT_KEYS.building.secondaryContacts],
    esmContact: lookupList[HELPER_TEXT_KEYS.building.esmContact],
  };

  const isStartAuditVisible =
    canAuditProperty &&
    selected.auditStatus !== PROPERTY_AUDIT_STATUS.pending &&
    selected.status === PROPERTY_STATUS.published &&
    !selected.isDraft;

  const updateNotAvailableFields = (key, parentKey, index) => {
    let _parentKey = form.getFieldValue(parentKey);
    if (_isArray(_parentKey)) {
      const _unitInfo = _cloneDeep(_parentKey);
      _unitInfo[index] = { ..._unitInfo[index], [key]: undefined };
      const _notAvailable = [...notAvailable[parentKey]];
      _notAvailable[index][key] = !_notAvailable[index][key];
      setNotAvailable(prev => ({ ...prev, [parentKey]: _notAvailable }));

      form.setFieldsValue({ [parentKey]: _unitInfo });
    } else {
      _parentKey = { ..._parentKey };
      setNotAvailable({ ...notAvailable, [key]: !notAvailable[key] });
      form.setFieldsValue({ [parentKey]: { ..._parentKey, [key]: undefined } });
    }
    if (form.getFieldValue(["buildingInfo", "propertyCode"])) {
      form.validateFields([["buildingInfo", "propertyCode"]]);
    }
  };

  const syncNotAvailableFields = (parentKey, index, keys = [], unitsCount) => {
    const _notAvailable = [...notAvailable[parentKey]];
    if (index >= 0) {
      _notAvailable.splice(index, 1);
    } else {
      for (let i = 0; i < unitsCount; i += 1) {
        const obj = {};
        _forEach(keys, key => {
          obj[key] = false;
        });
        _notAvailable.push(obj);
      }
    }

    setNotAvailable({ ...notAvailable, [parentKey]: [..._notAvailable] });
  };

  const updateAvailableUnits = (unitCode, unitIndex, isDeselected) => {
    let _unitInfo = form.getFieldValue("unitInfo");
    const pCodeUnits = new Set(selectedProperty.units.map(d => d.unitId));

    const _unitOptions = _unitInfo[0]?.unitOptions || [];
    const filteredUnits = _filter(_unitOptions, _option => !pCodeUnits.has(_option.value));

    _unitInfo = _unitInfo.map((_unitItem, index) => {
      _unitItem = {
        ..._unitItem,
        unitOptions: _unitItem.unitOptions.map(_unitOption => {
          if (unitCode.indexOf(_unitOption.value) !== -1) {
            // if unit code contains current unit option
            if (!isDeselected) {
              // if unit code is selected
              if (index !== unitIndex) {
                // if unit code and unit option exist in same form
                _unitOption = {
                  ..._unitOption,
                  isAvailable: false,
                };
              } else {
                // if unit code and unit option exist in different form
                _unitOption = {
                  ..._unitOption,
                  isAvailable: true,
                };
              }
            } else {
              // if unit code is deselected
              _unitOption = {
                ..._unitOption,
                isAvailable: true,
              };
            }
          }
          return _unitOption;
        }),
      };

      return _unitItem;
    });
    form.setFieldsValue({ unitInfo: _unitInfo });

    if (filteredUnits.length) {
      const updatedUnits = [
        ...selectedProperty.units,
        ...filteredUnits.map(_unit => ({
          ..._unit,
          isAvailable: false,
          id: getUnitLabel(_unit.label),
          unitCode: getUnitLabel(_unit.label),
          unitId: _unit.value,
        })),
      ];
      dispatch(buildingCodeSuccess({ ...selectedProperty, units: updatedUnits }));
    }
  };

  /**
   * Function for generating a payload
   * and invoking the API for updating units
   */
  const saveUnits = (_unitInfo, unitIndexToSave, newStatus, resMsg, isDraft, handleSaveSuccess) => {
    const uInfo = _unitInfo[unitIndexToSave];
    const optionalUnitKeys = [];
    let keysToValidate;
    if (newStatus === PROPERTY_STATUS.published && !isDraft) {
      keysToValidate = _map(unitInfoKeys, v1 => ["unitInfo", unitIndexToSave, v1]);
      if (!_isEmpty(language.secondary)) {
        _map(language.secondary, _lang =>
          keysToValidate.push(["unitInfo", unitIndexToSave, "translations", _lang, "marketingUnitName"]),
        );
      }
    } else {
      keysToValidate = [["unitInfo", unitIndexToSave, "unitCode"]];
    }

    if (newStatus === PROPERTY_STATUS.published && !isDraft) {
      keysToValidate.push(
        ["unitInfo", unitIndexToSave, "gradeLevelDoors"],
        ["unitInfo", unitIndexToSave, "dockHighDoors"],
        ["unitInfo", unitIndexToSave, "officeSize"],
        ["unitInfo", unitIndexToSave, "mezzanineSpace"],
        ["unitInfo", unitIndexToSave, "officeSizeUOM"],
        ["unitInfo", unitIndexToSave, "mezzanineSpaceUOM"],
      );
      optionalUnitKeys.push(
        ["unitInfo", unitIndexToSave, "mainBreakerSize"],
        ["unitInfo", unitIndexToSave, "leaseRateCurrency"],
      );
    }

    if (FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status) {
      keysToValidate.push(["unitInfo", unitIndexToSave, "draftComments"]);
    }

    setLoadingData(true);
    form
      .validateFields([...keysToValidate, ...optionalUnitKeys])
      .then(async () => {
        const _payload = {
          parent: id,
          unitInfo: uInfo.unitCode.map(_unit => {
            let combinedUnit = {
              ...uInfo,
              unitCode: getUnitLabel(_unit.label),
              unitId: _unit.value,
              minSpaceAvailableUOM:
                !_isEmpty(uInfo.minSpaceAvailableUOM) && uInfo.minSpaceAvailable
                  ? uInfo.minSpaceAvailableUOM
                  : undefined,
              maxSpaceAvailableUOM:
                !_isEmpty(uInfo.maxSpaceAvailableUOM) && uInfo.maxSpaceAvailable
                  ? uInfo.maxSpaceAvailableUOM
                  : undefined,
              officeSizeUOM: !_isEmpty(uInfo.officeSizeUOM) && uInfo.officeSize ? uInfo.officeSizeUOM : undefined,
              mezzanineSpaceUOM:
                !_isEmpty(uInfo.mezzanineSpaceUOM) && uInfo.mezzanineSpace ? uInfo.mezzanineSpaceUOM : undefined,
              clearHeightUOM: !_isEmpty(uInfo.clearHeightUOM) && uInfo.clearHeight ? uInfo.clearHeightUOM : undefined,
              id: getUnitLabel(_unit.label),
              mainBreakerSize:
                uInfo.mainBreakerSize && !Number.isNaN(+uInfo.mainBreakerSize) ? +uInfo.mainBreakerSize : undefined,
              minSpaceAvailable: uInfo.minSpaceAvailable ? +uInfo.minSpaceAvailable : undefined,
              maxSpaceAvailable: uInfo.maxSpaceAvailable ? +uInfo.maxSpaceAvailable : undefined,
              officeSize: uInfo.officeSize ? +uInfo.officeSize : undefined,
              mezzanineSpace: uInfo.mezzanineSpace ? +uInfo.mezzanineSpace : undefined,
              clearHeight: uInfo.clearHeight ? +Number(uInfo.clearHeight).toFixed(4) : undefined,
              gradeLevelDoors: uInfo.gradeLevelDoors ? +uInfo.gradeLevelDoors : 0,
              dockHighDoors: uInfo.dockHighDoors ? +uInfo.dockHighDoors : 0,
              initialPublishDate: uInfo.initialPublishDate ? dayjs(uInfo.initialPublishDate).valueOf() : undefined,
              lastPublishDate: uInfo.lastPublishDate ? dayjs(uInfo.lastPublishDate).valueOf() : undefined,
              lastUnpublishDate: uInfo.lastUnpublishDate ? dayjs(uInfo.lastUnpublishDate).valueOf() : undefined,
              unitMarketingCollateral: _mapValues(uInfo.unitMarketingCollateral, e => e || ""),
              isDraft: !!isDraft,
              ...(FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status && isDraft && { draftComments: uInfo?.draftComments }),
            };

            if (uInfo.unitCode.length > 1) {
              combinedUnit = {
                ...combinedUnit,
                combinedUnits: uInfo.unitCode.map(u => u.value),
              };
            }

            delete combinedUnit.unitOptions;
            delete combinedUnit.label;

            return combinedUnit;
          }),
          editTimestamp,
        };

        await dispatch(updateUnit(_payload, resMsg, unitIndexToSave));
        handleSaveSuccess();
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields, values } = ex;
        if (!_isEmpty(errorFields)) {
          const missingReqFields = _find(errorFields, ({ name }) => {
            const value = _get(values, name);
            const isEmpty = (!_isNumber(value) && !_isBoolean(value) && _isEmpty(value)) || value === "NaN";
            return _find(keysToValidate, val => isEmpty && val[val.length - 1] === name[name.length - 1]);
          });

          if (_get(errorFields, "0.name.2") === "translations") {
            toastMessage("error", t("ErrorMessages.ToastMessage3"));
          } else if (!_isEmpty(missingReqFields)) {
            toastMessage("error", t("ErrorMessages.ToastMessage1"));
          } else toastMessage("error", t("ErrorMessages.ToastMessage2"));
        } else toastMessage("error", ERROR_MESSAGE.EDITED);
      })
      .finally(() => setLoadingData(false));
  };

  /**
   * Function for producing a payload
   * and changing the statuses of the buildings to Draft and Unpublished
   */
  const saveForLater = (_status, _gTagEvent, isDraft) => {
    const keysToValidate = [
      ["buildingInfo", "propertyCode"],
      ["buildingInfo", "marketId"],
    ];

    if (FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status) {
      keysToValidate.push(["draftComments"]);
    }

    const _unitInfo = form.getFieldValue("unitInfo");
    _forEach(_unitInfo, (v1, i) => keysToValidate.push(["unitInfo", i, "unitCode"]));
    setLoadingData(true);
    return form
      .validateFields(keysToValidate)
      .then(async () => {
        let values = { ...form.getFieldsValue(true) };
        const { contacts } = values;
        const { marketId, subMarketId, latitude, longitude } = values.buildingInfo;

        if (contacts?.prologisContact?.value) {
          contacts.prologisContact = contacts?.prologisContact?.value;
        }

        if (contacts?.esmContact?.value) {
          contacts.esmContact = contacts?.esmContact?.value;
        }

        if (contacts.secondaryContacts?.length > 0) {
          contacts.secondaryContacts = [
            ...contacts.secondaryContacts.map(_secondaryContact => _secondaryContact.value),
          ];
        }
        values = {
          ...values,
          translations: checkEmptyObj(values.translations),
          id: values?.buildingInfo?.propertyCode?.value,
          status: _status,
          market: marketId,
          subMarket: subMarketId,
          contacts,
          latitude: latitude ? Number(latitude) : 0,
          longitude: longitude ? Number(longitude) : 0,
          isTemp: isTemporaryProperty,
          isDraft: !!isDraft,
          ...(FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status && isDraft && { draftComments: values?.draftComments }),
          buildingInfo: {
            ...values.buildingInfo,
            propertyCode: values?.buildingInfo?.propertyCode?.value,
            propertyType: PROPERTY_TYPE_KEYS.building,
            availableDate: values?.buildingInfo?.availableDate
              ? dayjs(values.buildingInfo.availableDate).valueOf()
              : undefined,
            minSpaceAvailable: values?.buildingInfo?.minSpaceAvailable
              ? +values?.buildingInfo?.minSpaceAvailable
              : undefined,
            maxSpaceAvailable: values?.buildingInfo?.maxSpaceAvailable
              ? +values?.buildingInfo?.maxSpaceAvailable
              : undefined,
            totalBuildingSize: values?.buildingInfo?.totalBuildingSize
              ? +values?.buildingInfo?.totalBuildingSize
              : undefined,
            truckCourtDepth: values?.buildingInfo?.truckCourtDepth
              ? Number(values?.buildingInfo?.truckCourtDepth).toFixed(4)
              : "0",
            minSpaceAvailableUOM: _isEmpty(values?.buildingInfo?.minSpaceAvailableUOM)
              ? undefined
              : values?.buildingInfo?.minSpaceAvailableUOM,
            maxSpaceAvailableUOM: _isEmpty(values?.buildingInfo?.maxSpaceAvailableUOM)
              ? undefined
              : values?.buildingInfo?.maxSpaceAvailableUOM,
            totalBuildingSizeUOM: _isEmpty(values?.buildingInfo?.totalBuildingSizeUOM)
              ? undefined
              : values?.buildingInfo?.totalBuildingSizeUOM,
            propertyRollUp: values?.buildingInfo?.propertyRollUp?.value,
          },
          media: mapMediaContentWithAlt(_files, false, form),
          unitInfo: values.unitInfo.reduce((prev, next) => {
            let _units;
            let _unit;
            /**
             * identifying merge units
             */
            if (next.unitCode.length > 1) {
              _units = next.unitCode.map(u => {
                _unit = {
                  ...next,
                  id: getUnitLabel(u.label),
                  unitId: u.value,
                  unitCode: getUnitLabel(u.label),
                  status: isDraft ? next.status : _status,
                  mainBreakerSize: next.mainBreakerSize ? +next.mainBreakerSize : undefined,
                  minSpaceAvailable: next.minSpaceAvailable ? +next.minSpaceAvailable : undefined,
                  maxSpaceAvailable: next.maxSpaceAvailable ? +next.maxSpaceAvailable : undefined,
                  officeSize: next.officeSize ? +next.officeSize : undefined,
                  mezzanineSpace: next.mezzanineSpace ? +next.mezzanineSpace : undefined,
                  clearHeight: next.clearHeight ? +Number(next.clearHeight).toFixed(4) : 0,
                  gradeLevelDoors: next.gradeLevelDoors ? +next.gradeLevelDoors : 0,
                  dockHighDoors: next.dockHighDoors ? +next.dockHighDoors : 0,
                  initialPublishDate: next.initialPublishDate ? dayjs(next.initialPublishDate).valueOf() : undefined,
                  lastPublishDate: next.lastPublishDate ? dayjs(next.lastPublishDate).valueOf() : undefined,
                  lastUnpublishDate: next.lastUnpublishDate ? dayjs(next.lastUnpublishDate).valueOf() : undefined,
                  unitMarketingCollateral: _mapValues(next.unitMarketingCollateral, e => e || ""),
                  combinedUnits: next.unitCode.map(_code => _code.value),
                  officeSizeUOM: _isEmpty(next.officeSizeUOM) ? undefined : next.officeSizeUOM,
                  mezzanineSpaceUOM: _isEmpty(next.mezzanineSpaceUOM) ? undefined : next.mezzanineSpaceUOM,
                  minSpaceAvailableUOM: _isEmpty(next.minSpaceAvailableUOM) ? undefined : next.minSpaceAvailableUOM,
                  maxSpaceAvailableUOM: _isEmpty(next.maxSpaceAvailableUOM) ? undefined : next.maxSpaceAvailableUOM,
                  clearHeightUOM: _isEmpty(next.clearHeightUOM) ? undefined : next.clearHeightUOM,
                  isDraft: !!isDraft,
                };

                delete _unit.unitOptions;
                delete _unit.label;

                return _unit;
              });
            } else {
              _unit = {
                ...next,
                id: getUnitLabel(next.unitCode[0].label),
                unitId: next.unitCode[0].value,
                unitCode: getUnitLabel(next.unitCode[0].label),
                status: isDraft ? next.status : _status,
                mainBreakerSize: next.mainBreakerSize ? +next.mainBreakerSize : undefined,
                minSpaceAvailable: next.minSpaceAvailable ? +next.minSpaceAvailable : undefined,
                maxSpaceAvailable: next.maxSpaceAvailable ? +next.maxSpaceAvailable : undefined,
                officeSize: next.officeSize ? +next.officeSize : undefined,
                mezzanineSpace: next.mezzanineSpace ? +next.mezzanineSpace : undefined,
                clearHeight: next.clearHeight ? +Number(next.clearHeight).toFixed(4) : 0,
                gradeLevelDoors: next.gradeLevelDoors ? +next.gradeLevelDoors : 0,
                dockHighDoors: next.dockHighDoors ? +next.dockHighDoors : 0,
                initialPublishDate: next.initialPublishDate ? dayjs(next.initialPublishDate).valueOf() : undefined,
                lastPublishDate: next.lastPublishDate ? dayjs(next.lastPublishDate).valueOf() : undefined,
                lastUnpublishDate: next.lastUnpublishDate ? dayjs(next.lastUnpublishDate).valueOf() : undefined,
                unitMarketingCollateral: _mapValues(next.unitMarketingCollateral, e => e || ""),
                officeSizeUOM: _isEmpty(next.officeSizeUOM) ? undefined : next.officeSizeUOM,
                mezzanineSpaceUOM: _isEmpty(next.mezzanineSpaceUOM) ? undefined : next.mezzanineSpaceUOM,
                minSpaceAvailableUOM: _isEmpty(next.minSpaceAvailableUOM) ? undefined : next.minSpaceAvailableUOM,
                maxSpaceAvailableUOM: _isEmpty(next.maxSpaceAvailableUOM) ? undefined : next.maxSpaceAvailableUOM,
                clearHeightUOM: _isEmpty(next.clearHeightUOM) ? undefined : next.clearHeightUOM,
                isDraft: !!isDraft,
              };

              delete _unit.unitOptions;
              delete _unit.ghostUnitCode;
              delete _unit.label;

              _units = [_unit];
            }
            return [...prev, ..._units];
          }, []),
          editTimestamp,
        };

        if (!values?.buildingInfo?.availableDate) {
          delete values.buildingInfo.availableDate;
        }
        delete values.buildingInfo.marketId;
        delete values.buildingInfo.subMarketId;
        delete values.buildingInfo.latitude;
        delete values.buildingInfo.longitude;

        try {
          if (isEditView && status === PROPERTY_STATUS.never_published && !selected.isDraft) {
            Object.keys(values.translations || {}).forEach(item => {
              values.translations[item].media = mapMediaContentWithAlt(
                _files,
                _translatedFiles[item]?.media,
                form,
                item,
              );
              values.translations[item].media = removeMediaContent(values.translations[item].media);
            });

            await dispatch(addBuilding(values));
            dispatch(sendAnalyticsEvent(_gTagEvent, { propertyCode: values.id }));
          } else {
            Object.keys(values.translations || {}).forEach(item => {
              values.translations[item].media = mapMediaContentWithAlt(
                _files,
                _translatedFiles[item]?.media,
                form,
                item,
              );
              values.translations[item].media = removeMediaContent(values.translations[item].media);
            });

            const _payload = { ...values };
            delete _payload.createdAt;
            delete _payload.updatedAt;
            delete _payload.lastPublishDate;
            delete _payload.initialPublishDate;
            delete _payload.lastUnpublishDate;
            delete _payload.id;

            await dispatch(updateBuilding(id, _payload));
            dispatch(sendAnalyticsEvent(_gTagEvent, { propertyCode: id }));
            setDraftCommentsModal(false);
          }

          if (!values.isDraft) {
            history.push(ROUTES.PROPERTIES.path);
          } else {
            await dispatch(getBuildingById(id));
            setIsPopulated(false);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        const currentTabHasError = errorFields?.some(({ name }) => name.indexOf(_tabsKey[activeTabKey]) > -1);
        const _tabKey = _tabs?.[errorFields?.[0]?.name[0]]?.key;
        if (!currentTabHasError) setActiveTabKey(_tabKey);
        toastMessage("error", errorFields?.[0]?.errors?.[0] || t("ErrorMessages.SelectPropertyCode"));
      })
      .finally(() => setLoadingData(false));
  };
  const showAuditPanelDrawer = () => {
    setAuditPanelDrawerVisible(true);
    if (activeTabKey === _tabs.auditIssues.key) setActiveTabKey(_tabs.buildingInfo.key);
  };

  const startAudit = async () => {
    try {
      const payload = {
        auditStatus: PROPERTY_AUDIT_STATUS.pending,
      };
      setLoadingData(true);
      await dispatch(updateBuildingAuditStatus(selected.buildingInfo.propertyCode, payload));
      setAuditPanelDrawerVisible(true);
      if (activeTabKey === _tabs.auditIssues.key) setActiveTabKey(_tabs.buildingInfo.key);
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setLoadingData(false);
    }
  };

  const _onDraftCommentsModalClose = () => {
    setDraftCommentsModal(false);
  };

  const _onDraftCommentsModalOpen = () => {
    if (selected?.isDraft) {
      form.setFieldsValue({ draftComments: selected?.draftComments });
    }
    setDraftCommentsModal(true);
  };

  const auditButtonTitle =
    propertyStatus === PROPERTY_STATUS.published && !selected.isDraft && canAuditProperty
      ? t("Buttons.NewAuditIssue")
      : t("Buttons.ViewAuditIssue");

  const _renderExtraContent = () => {
    const isPublishDisabled = disabledStatus || _isEmpty(_files.hero) || loadingMedia;

    return (
      <Row className="fields-row" gutter={24} justify="center">
        <Col span={8} xs={24} sm={24} lg={24}>
          <Row className="fields-row tab-btn-actions row-vertical-padding-4" gutter={24} type="flex">
            {!viewOnlyMode && (
              <Button
                type="info"
                disabled={isPublishDisabled}
                className="btn-small"
                onClick={() => setIsPreviewActive(true)}
              >
                {t("Buttons.Preview")}
              </Button>
            )}
            {!viewOnlyMode && (
              <Button type="info" onClick={onCancel} className="btn-small">
                {t("Buttons.Discard")}
              </Button>
            )}

            {viewOnlyMode && canEditProperty && (
              <Link to={{ pathname: `${ROUTES.EDIT_BUILDING.path}/${id}`, state: { activeTabKey } }}>
                <Button type="primary" className="btn-icon" icon={<CustomIcon name="EditFilled" />}>
                  {t("Buttons.Edit")}
                </Button>
              </Link>
            )}
            {!viewOnlyMode && (
              <>
                {FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status ? (
                  <Button type="info" className="btn-small" onClick={_onDraftCommentsModalOpen}>
                    {t("Buttons.SaveForLater")}
                  </Button>
                ) : (
                  <Button
                    type="info"
                    className="btn-small"
                    onClick={() => saveForLater(selected?.status, GTAG_EVENTS.DRAFT_PROPERTY, true)}
                  >
                    {t("Buttons.SaveForLater")}
                  </Button>
                )}
              </>
            )}
            {!viewOnlyMode && (
              <Button
                type="primary"
                onClick={() => saveForLater(PROPERTY_STATUS.unpublished, GTAG_EVENTS.UNPUBLISH_PROPERTY)}
              >
                {t("Buttons.Unpublish")}
              </Button>
            )}
            {!viewOnlyMode && (
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <Button disabled={isPublishDisabled} onClick={() => setIsModalOpen(true)} type="primary">
                    {t("Buttons.Publish")}
                  </Button>
                )}
              </Form.Item>
            )}
            {!viewOnlyMode && (
              <>
                {isStartAuditVisible ? (
                  <Button type="primary" onClick={startAudit}>
                    {t("Buttons.StartAudit")}
                  </Button>
                ) : (
                  <Button type="primary" onClick={showAuditPanelDrawer}>
                    {auditButtonTitle}
                  </Button>
                )}
              </>
            )}
          </Row>
          {!viewOnlyMode && (
            <Row justify="end" className="tab-btn-actions">
              {isAsianCountry(form, "buildingInfo") && (
                <Form.Item
                  tooltip={getTooltip(helperText.propertyAvailable, language)}
                  valuePropName="checked"
                  label={t("Property.PropertyAvailable")}
                  name="propertyAvailable"
                  className="property-available-switch"
                >
                  <Switch disabled={viewOnlyMode || isPublishDisabled} />
                </Form.Item>
              )}

              <Col span={4} className="text-right">
                {t("AddEditProperty.Audit.AuditIssues")}
                :&nbsp;
                {auditList.length}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const auditAlertMessage =
    (canAuditProperty && t("AddEditProperty.Audit.AuditorAlertMessage")) ||
    (canViewAuditIssues && t("AddEditProperty.Audit.EditorAlertMessage"));

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky topOffset={-60}>
      {({ style, isSticky }) => (
        <div
          className={`custom-sticky-tab-bar ${selected?.isDraft ? "isDraft" : ""}`}
          style={{
            ...style,
            marginTop: isSticky ? 56 : 0,
          }}
        >
          {auditPanelDrawerVisible && (
            <Row className="row-vertical-padding-4">
              <Col flex="1 1 200px">
                {!viewOnlyMode && <Alert message={auditAlertMessage} type="info" showIcon closable />}
              </Col>
              <Col flex="0 1 410px"></Col>
            </Row>
          )}

          {FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status && selected?.isDraft && selected?.draftComments && (
            <Collapse defaultActiveKey={["1"]} className="ant-collapse-warning">
              <Panel
                header={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.building })}
                key="1"
              >
                <p>{selected?.draftComments}</p>
              </Panel>
            </Collapse>
          )}

          <LanguageSelect
            form={form}
            propertyType="buildingInfo"
            errorTabs={errorTabs}
            isTranslated={isTranslated}
            onLanguageChange={onLanguageChange}
          />
          <PageTitle
            title={title}
            items={[ROUTES.PROPERTIES, isEditView ? { title: id } : ROUTES.ADD_BUILDING]}
            propertyStatus={renderPropertyStatusWithModifiedDate(selected, isTemporaryProperty)}
          />

          <DefaultTabBar {...props} />
        </div>
      )}
    </Sticky>
  );
  const auditCountByStatusDisplay = count => {
    setAuditCountByStatus(count);
  };

  const navigateToIssueTab = (_tabKey, _language, unitCode, shouldOpenDrawer) => {
    if (_tabKey === "5") {
      const value = form.getFieldValue("unitInfo");
      const unitInfo = [...value].reverse();
      const isMergedUnit = unitCode.indexOf("MRG") !== -1;
      const index = unitInfo.findIndex(({ unitCode: _unitCode, ghostUnitCode }) => {
        const _id = _unitCode[0].label;
        if (isMergedUnit) {
          return ghostUnitCode === unitCode;
        }
        return unitCode === _id;
      });
      setActiveCollapseKeys([index]);
    }
    const _state = updateErrorState(language.current);
    onTabChange(_tabKey, _language, _state);
    const payload = {
      ...language,
      current: _language,
    };

    if (shouldOpenDrawer) {
      setAuditPanelDrawerVisible(shouldOpenDrawer);
    }
    dispatch(setLanguage(payload));
  };

  const handleNavigateToIssueTab = async (_tabKey, _language, unitCode, shouldOpenDrawer) => {
    if (viewOnlyMode && canEditProperty) {
      history.push({
        pathname: `${ROUTES.EDIT_BUILDING.path}/${id}`,
        state: { _tabKey, _language, unitCode, shouldOpenDrawer },
      });
    } else {
      navigateToIssueTab(_tabKey, _language, unitCode, shouldOpenDrawer);
    }
  };

  return (
    <StickyContainer className="building-form-group">
      <AuditDrawer
        viewOnlyMode={viewOnlyMode}
        propertyCode={selected.buildingInfo?.propertyCode}
        auditList={auditList}
        auditPanelDrawerVisible={auditPanelDrawerVisible}
        setAuditPanelDrawerVisible={setAuditPanelDrawerVisible}
        navigateToIssueTab={handleNavigateToIssueTab}
        propertyType={selected.buildingInfo?.propertyType}
        openModalByAuditPass={openModalByAuditPass}
      />
      <Tabs
        activeKey={activeTabKey}
        className="tabs__container"
        onChange={onTabChange}
        renderTabBar={renderTabBar}
        tabBarExtraContent={_renderExtraContent()}
      >
        <TabPane tab={_renderTabTitle(_tabsKey[1])} key={_tabs.buildingInfo.key} forceRender>
          <BuildingInfo
            templateList={marketingDescriptionTemplateList}
            disableLatLng={disableLatLng}
            form={form}
            viewOnlyMode={viewOnlyMode}
            isTemporaryProperty={isTemporaryProperty}
            notAvailable={notAvailable}
            updateNotAvailableFields={updateNotAvailableFields}
            fetchCountryAddressFields={fetchCountryAddressFields}
            countryAddressFields={countryAddressFields}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
          />
        </TabPane>
        <TabPane tab={_renderTabTitle(_tabsKey[2])} key={_tabs.keyFeatures.key} forceRender>
          <KeyFeatures
            viewOnlyMode={viewOnlyMode}
            form={form}
            templateList={keyFeatureTemplateList}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
          />
        </TabPane>
        <TabPane tab={_renderTabTitle(_tabsKey[3])} key={_tabs.contacts.key} forceRender>
          <Contacts
            form={form}
            viewOnlyMode={viewOnlyMode}
            helperText={contactsHelperText}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
            propertyType={PROPERTY_TYPE_KEYS.building}
          />
        </TabPane>
        <TabPane tab={_renderTabTitle(_tabsKey[4])} key={_tabs.media.key} forceRender>
          <PropertyMediaContainer
            helperText={helperText}
            marketingCollateralLabel={t("Property.PropertyMarketingCollateral")}
            mediaErrors={mediaErrors}
            updateFiles={_updateFiles}
            isEditView={isEditView}
            selectedPropertyCode={selectedProperty.id}
            propertyMedia={selectedPropertyMedia}
            selectedMedia={_files}
            translatedSelectedMedia={_translatedFiles}
            viewOnlyMode={viewOnlyMode}
            loading={loadingMedia}
            language={language}
            languageStatus={languageStatus}
            form={form}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
            propertyType={PROPERTY_TYPE_KEYS.building}
          />
        </TabPane>
        <TabPane className="unit-info-tabpane" tab={_renderTabTitle(_tabsKey[5])} key={_tabs.unitInfo.key} forceRender>
          <UnitInformation
            errorTabs={errorTabs}
            setErrorTabs={setErrorTabs}
            isTranslated={!!isTranslated}
            form={form}
            isEditView={isEditView}
            loading={loading}
            selectedProperty={selectedProperty}
            selectedPropertyMedia={selectedPropertyMedia}
            propertyStatus={status}
            updateAvailableUnits={updateAvailableUnits}
            save={saveUnits}
            viewOnlyMode={viewOnlyMode}
            notAvailable={notAvailable}
            updateNotAvailableFields={updateNotAvailableFields}
            syncNotAvailableFields={syncNotAvailableFields}
            activeCollapseKeys={activeCollapseKeys}
            setActiveCollapseKeys={setActiveCollapseKeys}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
          />
        </TabPane>
        <TabPane
          tab={_renderAuditTabTitle(_tabsKey[6], auditPanelDrawerVisible)}
          key={_tabs.auditIssues.key}
          forceRender
          disabled={auditPanelDrawerVisible}
        >
          <AuditIssues
            auditCountByStatus={auditCountByStatusDisplay}
            navigateToIssueTab={handleNavigateToIssueTab}
            mainForm={form}
            propertyType={PROPERTY_TYPE_KEYS.building}
          />
        </TabPane>
      </Tabs>
      <AppModal
        title={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.building })}
        width="700px"
        visible={draftCommentsModal}
        onCancel={_onDraftCommentsModalClose}
        destroyOnClose
        className="draft-comment"
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={10} lg={10}>
              <Button
                block
                type="primary"
                loading={loading}
                onClick={() => saveForLater(selected?.status, GTAG_EVENTS.DRAFT_PROPERTY, true)}
              >
                {t("Buttons.Save")}
              </Button>
            </Col>
          </Row>
        }
      >
        <DraftComments form={form} />
      </AppModal>
    </StickyContainer>
  );
};

BuildingFormGroup.propTypes = {
  viewOnlyMode: PropTypes.bool,
  form: PropTypes.object,
  _tabs: PropTypes.object,
  status: PropTypes.string,
  onTabChange: PropTypes.func,
  errorTabs: PropTypes.object,
  activeTabKey: PropTypes.string,
  setActiveTabKey: PropTypes.func,
  notAvailable: PropTypes.object,
  setNotAvailable: PropTypes.func,
  disabledStatus: PropTypes.bool,
  isTemporaryProperty: PropTypes.bool,
  setLoadingData: PropTypes.func,
  _setFiles: PropTypes.func,
  t: PropTypes.func,
  mediaErrors: PropTypes.object,
  setMediaErrors: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  fetchCountryAddressFields: PropTypes.func,
  countryAddressFields: PropTypes.object,
  isEditView: PropTypes.bool,
  _files: PropTypes.object,
  _translatedFiles: PropTypes.object,
  editTimestamp: PropTypes.number,
  isTranslated: PropTypes.object,
  setErrorTabs: PropTypes.func,
  marketingDescriptionTemplateList: PropTypes.array,
  keyFeatureTemplateList: PropTypes.array,
  title: PropTypes.string,
  propertyStatus: PropTypes.string,
  onLanguageChange: PropTypes.func,
  setIsPopulated: PropTypes.func,
  setIsPreviewActive: PropTypes.func,
  updateErrorState: PropTypes.func,
  openModalByAuditPass: PropTypes.func,
};

export default BuildingFormGroup;
