import { Col, DatePicker, Divider, Form, Input, Row, Select, Space, Switch } from "antd";
import dayjs from "dayjs";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DATE_FORMAT, FIELD_TYPE, PROPERTY_TYPE_UI_MAPPING } from "../../utils/constants";

import "./UnmarketForm.scss";

const { Option } = Select;
const { TextArea } = Input;

const UnmarketForm = ({ unmarketData, form, propertyType }) => {
  const { t } = useTranslation();
  const [isSameReason, setIsSameReason] = useState(false);
  const [reasonOptions, setReasonsOptions] = useState({});
  const [sameReasonOption, setSameReasonOption] = useState("");
  const [sameReasonDateOption, setSameReasonDateOption] = useState({});
  const [filteredList, setFilterdList] = useState([]);

  const { list } = useSelector(state => ({
    ...state.unmarketReason,
  }));

  /**
   * Filtered list of unmarked reasons based on property type
   */
  useEffect(() => {
    const unmarketReasons = list.filter(_list => _list?.type?.includes(propertyType));
    setFilterdList(unmarketReasons);

    const _value = _map(unmarketData, d => {
      const key = d.propertyType === FIELD_TYPE.land ? d.id : d.unitId;

      return {
        [key]: {
          value: d.unmarketReasonId,
          moreInformationRequired: d.moreInformationRequired && d.moreInformationRequired.toString(),
          unmarketAdditionalInformation: d.unmarketAdditionalInformation,
          dateFieldLabel: d.dateFieldLabel,
          dateFieldRequired: d.dateFieldRequired && d.dateFieldRequired.toString(),
          unmarketLeaseDate: d.unmarketLeaseDate ? dayjs(d.unmarketLeaseDate) : undefined,
        },
      };
    });

    const formatedValues = _value.reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      acc[key] = obj[key];
      return acc;
    }, {});

    setReasonsOptions(formatedValues);
    const mergedValue = Object.assign({}, ..._value);
    form.setFieldsValue(mergedValue);
  }, []);

  const onReasonChange = (value, key, option) => {
    setReasonsOptions({
      ...reasonOptions,
      [key]: {
        value,
        moreInformationRequired: option.moreinformationrequired,
        unmarketAdditionalInformation: option.unmarketAdditionalInformation,
        dateFieldLabel: option.datefieldlabel,
        dateFieldRequired: option.datefieldrequired,
      },
    });
  };

  const onSameReasonChange = (value, option) => {
    setSameReasonOption(option.moreinformationrequired);
    setSameReasonDateOption({ dateFieldLabel: option.datefieldlabel, dateFieldRequired: option.datefieldrequired });
  };

  const toggleSameReason = checked => {
    setIsSameReason(checked);
  };

  /**
   * function use to rendered unmarket reasons form
   */
  const renderReasonForm = () => {
    if (isSameReason) {
      return (
        <Col span={8} xs={24} sm={24} lg={24}>
          <Form.Item
            label={`${unmarketData.length} ${t("UnitUnmarket.SameReasonLable", {
              propertyType:
                propertyType === FIELD_TYPE.building ? PROPERTY_TYPE_UI_MAPPING.unit : PROPERTY_TYPE_UI_MAPPING.land,
            })}`}
            name={["sameReason", "value"]}
            rules={[{ required: true, message: t("ErrorMessages.SelectUnmarketReason") }]}
          >
            <Select
              onChange={onSameReasonChange}
              placeholder={t("UnitUnmarket.UnmarketPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
            >
              {_map(filteredList, reason => (
                <Option
                  className="unmarket-option"
                  key={reason.id}
                  moreinformationrequired={reason?.moreInformationRequired.toString()}
                  datefieldrequired={reason?.dateFieldRequired ? reason?.dateFieldRequired.toString() : "false"}
                  datefieldlabel={reason?.dateFieldLabel ? reason?.dateFieldLabel : ""}
                  value={reason.id}
                >
                  {reason.unmarketingReason}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {sameReasonDateOption?.dateFieldRequired === "true" && (
            <Form.Item
              label={sameReasonDateOption?.dateFieldLabel}
              name={["sameReason", "unmarketLeaseDate"]}
              rules={[
                {
                  required: true,
                  message: t("ErrorMessages.UnmarketLeaseDateMessage", {
                    dateFieldName: sameReasonDateOption?.dateFieldLabel,
                  }),
                },
              ]}
              preserve={false}
            >
              <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
            </Form.Item>
          )}
          {sameReasonOption === "true" && (
            <Form.Item
              label={t("UnitUnmarket.UnmarketTextAreaLable")}
              name={["sameReason", "unmarketAdditionalInformation"]}
              rules={[{ required: true, message: t("ErrorMessages.AdditionalInformationMessage") }]}
              preserve={false}
            >
              <TextArea autoSize={{ minRows: 6 }} rules={[{ required: true }]} />
            </Form.Item>
          )}
        </Col>
      );
    }

    const getLabel = param => (propertyType === FIELD_TYPE.building ? param.unitCode : param.id);
    const getName = param => (propertyType === FIELD_TYPE.building ? param.unitId : param.id);

    return _map(unmarketData, _reason => (
      <Col key={getName(_reason)} span={8} xs={24} sm={24} lg={24}>
        <Form.Item
          label={getLabel(_reason)}
          name={[getName(_reason), "value"]}
          rules={[{ required: true, message: t("ErrorMessages.SelectUnmarketReason") }]}
        >
          <Select
            onChange={(value, option) => onReasonChange(value, getName(_reason), option)}
            placeholder={t("UnitUnmarket.UnmarketPlaceholder")}
            defaultValue={_reason.unmarketReasonId}
            optionFilterProp="value"
            value={_reason.unmarketReasonId}
            autoComplete="off"
          >
            {_map(filteredList, reason => (
              <Option
                className="unmarket-option"
                moreinformationrequired={reason?.moreInformationRequired.toString()}
                datefieldrequired={reason?.dateFieldRequired ? reason?.dateFieldRequired.toString() : "false"}
                datefieldlabel={reason?.dateFieldLabel ? reason?.dateFieldLabel : ""}
                key={reason.id}
                value={reason.id}
              >
                {reason.unmarketingReason}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {reasonOptions[getName(_reason)]?.dateFieldRequired === "true" && (
          <Form.Item
            label={reasonOptions[getName(_reason)]?.dateFieldLabel}
            name={[getName(_reason), "unmarketLeaseDate"]}
            rules={[
              {
                required: true,
                message: t("ErrorMessages.UnmarketLeaseDateMessage", {
                  dateFieldName: reasonOptions[getName(_reason)]?.dateFieldLabel,
                }),
              },
            ]}
            preserve={false}
          >
            <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
          </Form.Item>
        )}
        {reasonOptions[getName(_reason)]?.moreInformationRequired === "true" && (
          <Form.Item
            label={t("UnitUnmarket.UnmarketTextAreaLable")}
            name={[getName(_reason), "unmarketAdditionalInformation"]}
            rules={[{ required: true, message: t("ErrorMessages.AdditionalInformationMessage") }]}
            preserve={false}
          >
            <TextArea autoSize={{ minRows: 6 }} rules={[{ required: true }]} />
          </Form.Item>
        )}
      </Col>
    ));
  };

  return (
    <div className="unmarket-form">
      <Form form={form} layout="vertical" name="units-unmarket">
        <Row align="middle">
          <Col span={16}>
            <p>
              {t("UnitUnmarket.Description", {
                propertyType: propertyType === FIELD_TYPE.building ? FIELD_TYPE.unit : FIELD_TYPE.land,
              })}
            </p>
          </Col>
          <Col span={8} className="text-right">
            <Space>
              <Form.Item
                className="reason-switch"
                valuePropName="checked"
                label={t("UnitUnmarket.SameReasonText")}
                name="isSameReason"
              >
                <Switch onChange={toggleSameReason} checked={isSameReason} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>{renderReasonForm()}</Row>
      </Form>
      <br />
    </div>
  );
};

UnmarketForm.propTypes = {
  unmarketData: PropTypes.array,
  form: PropTypes.object,
  propertyType: PropTypes.string,
};

export default UnmarketForm;
