import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import MainLayoutContainer from "../containers/MainLayout";
import { checkAuth, logout, userSuccess } from "../store/actions/user.actions";

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkAuth,
      logout,
      updateUserInStore: userSuccess,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  loading: state.user.loading,
  user: state.user.value,
  error: state.user.error,
});

const MainLayoutProvider = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayoutContainer));

export default MainLayoutProvider;
