import { get, put, post } from "./http.service";

const UnmarketReasonsService = {
  get: () => get("unmarketingReason"),
  update: (id, body) => put(`unmarketingReason/${id}`, body),
  create: body => post(`unmarketingReason`, body),
  remove: (id, body) => put(`unmarketingReason/delete/${id}`, body),
  updateOrder: body => put(`unmarketingReason/updateOrder`, body),
  getAllUnmarketProperties: id => get(`unmarketingReason/propertiesByReasonId/${id}`),
  export: id => get(`unmarketingReason/export?id=${id}`, { responseType: "base64" }),
};

export default UnmarketReasonsService;
