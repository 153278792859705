/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import ReactPlayer from "react-player";

import BuildingInfo from "./BuildingInfo";
import BuildingDetails from "./BuildingDetails";
import LandDetails from "./LandDetails";
import LandInfo from "./LandInfo";
import MultipleUnits from "./MultipleUnits";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { extractMediaUrl } from "../../utils/property.util";
import Slider from "../../components/Slider/Slider";
import { useLanguage } from "../../hooks";

import InvalidUrlImage from "../../images/invalid-url.png";

import "./PropertySearchPreview.scss";
import { getLocalTranslatedValue } from "../../i18n";
import ContactDetails from "./ContactDetails";

const MediaButton = ({ onClick, title, icon, active }) => {
  let buttonClassName = "media-button";
  let iconClassName = "media-button-icon";

  if (active) {
    buttonClassName += " media-button-active";
    iconClassName += " media-button-icon-active";
  }

  return (
    <>
      <span className={buttonClassName} onClick={onClick}>
        {title}
      </span>
      <CustomIcon name={icon} className={iconClassName} />
    </>
  );
};

const PropertySearchPreview = ({ form, selected, media, translatedMedia, propertyType, employeeList, brokerList }) => {
  const [activeMedia, setActiveMedia] = useState("gallery");
  const [isVideoError, setIsVideoError] = useState(false);
  const { language } = useLanguage();
  const radioOptions = useMemo(() => [
    { label: getLocalTranslatedValue(language.current, "UsCustomary"), value: "uscustomary" },
    { label: getLocalTranslatedValue(language.current, "Metric"), value: "metric" },
  ]);
  const [activeMeasurement, setActiveMeasurement] = useState(radioOptions[0].value);
  const values = form.getFieldsValue(true);
  const translatedValues = values.translations[language.current];
  const { updatedAt } = selected;

  const headerStyles = {
    position: "relative",
    backgroundImage: `url(${extractMediaUrl(media.hero[0])})`,
    backgroundPosition: "center",
  };

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onChangeMeasurement = e => {
    setActiveMeasurement(e.target.value);
  };

  const PropertyHeader = () => {
    const _values = language.current === "en" ? values : translatedValues;
    return (
      <div className="property-header" style={headerStyles}>
        <div className="property-header-content">
          <Row className="w-container">
            <Col xs={24}>
              <h1 className="property-heading">
                {propertyType === "land"
                  ? _get(_values, "landInfo.marketingPropertyName", "")
                  : _get(_values, "buildingInfo.marketingPropertyName", "")}
              </h1>
            </Col>
            <Col xs={24}>
              <h4 className="property-sub-heading">{_get(selected, "market.marketName", "")}</h4>
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  // const Breadcrumb = () => (
  //   <ol className="breadcrumb w-container">
  //     <li className="breadcrumb__item">locations</li>
  //     <li className="breadcrumb__item">united states</li>
  //     <li className="breadcrumb__item">californias central valley</li>
  //   </ol>
  // );

  const PropertyMedia = () => {
    const buildingVideoUrl =
      language.current === "en" ? values?.buildingInfo?.videoUrl : translatedValues?.buildingInfo?.videoUrl;
    const landVideoUrl = language.current === "en" ? values?.landInfo?.videoUrl : translatedValues?.landInfo?.videoUrl;
    const buildingDigitalTourUrl =
      language.current === "en" ? values?.buildingInfo?.digitalTourUrl : translatedValues?.buildingInfo?.digitalTourUrl;
    const landDigitalTourUrl =
      language.current === "en" ? values?.landInfo?.digitalTourUrl : translatedValues?.landInfo?.digitalTourUrl;
    const buildingMicrositeUrl =
      language.current === "en" ? values?.buildingInfo?.micrositeUrl : translatedValues?.buildingInfo?.micrositeUrl;
    const landMicrositeUrl =
      language.current === "en" ? values?.landInfo?.micrositeUrl : translatedValues?.landInfo?.micrositeUrl;
    const _media = language.current === "en" ? media : _get(translatedMedia, `${language.current}.media`, {});

    return (
      <div className="property-media w-container">
        {activeMedia === "gallery" && media.gallery.length === 0 && (
          <Row>
            <Col xs={24} className="property-media-image">
              <img className="" src={extractMediaUrl(media.hero[0])} alt=" " />
            </Col>
          </Row>
        )}
        {activeMedia === "gallery" && media.gallery.length === 1 && (
          <Row>
            <Col xs={24} className="property-media-image-2">
              <img className="" src={extractMediaUrl(media.hero[0])} alt="" />
              <img className="" src={extractMediaUrl(media.gallery[0])} alt="" />
            </Col>
          </Row>
        )}
        {activeMedia === "gallery" && media.gallery.length > 1 && (
          <Row>
            <Col xs={24} className="property-media-image">
              <Slider images={[media.hero[0], ...media.gallery]} />
            </Col>
          </Row>
        )}
        {activeMedia === "aerial" && (
          <Row>
            <Col xs={24} className="property-media-image">
              <img className="" src={extractMediaUrl(media.aerial[0])} alt=" " />
            </Col>
          </Row>
        )}
        {activeMedia === "video" && (
          <Row>
            {!isVideoError && (
              <Col xs={24} className="property-media-video">
                <ReactPlayer
                  url={propertyType === "building" ? buildingVideoUrl : landVideoUrl}
                  width="100%"
                  height="100%"
                  className="video-player"
                  onError={error => {
                    if (error) {
                      setIsVideoError(true);
                    }
                  }}
                />
              </Col>
            )}
            {isVideoError && (
              <Col xs={24} className="property-media-image">
                <img src={InvalidUrlImage} alt="" />
              </Col>
            )}
          </Row>
        )}
        <Row>
          <Col xs={24} sm={4} md={3}>
            <MediaButton
              onClick={() => {
                setActiveMedia("gallery");
              }}
              // title={t("PropertyPreview.Gallery")}
              title={getLocalTranslatedValue(language.current, "Gallery")}
              icon="camera"
              active={activeMedia === "gallery"}
            />
          </Col>
          {/* <Col xs={24} sm={3}>
            <MediaButton
              onClick={() => {
                setActiveMedia("map");
              }}
              title={t("PropertyPreview.Map")}
              icon="map"
              active={activeMedia === "map"}
            />
          </Col> */}
          {!_isEmpty(media.aerial) && (
            <Col xs={24} sm={3}>
              <MediaButton
                onClick={() => {
                  setActiveMedia("aerial");
                }}
                // title={t("PropertyPreview.Aerial")}
                title={getLocalTranslatedValue(language.current, "Aerial")}
                icon="aerial"
                active={activeMedia === "aerial"}
              />
            </Col>
          )}
          {(buildingVideoUrl || landVideoUrl) && (
            <Col xs={24} sm={3}>
              <MediaButton
                onClick={() => {
                  setActiveMedia("video");
                }}
                // title={t("PropertyPreview.Video")}
                title={getLocalTranslatedValue(language.current, "Video")}
                icon="videoCam"
                active={activeMedia === "video"}
              />
            </Col>
          )}
          {(buildingDigitalTourUrl || landDigitalTourUrl) && (
            <Col xs={24} sm={4} md={3}>
              <MediaButton
                // title={t("PropertyPreview.360Tour")}
                title={getLocalTranslatedValue(language.current, "360Tour")}
                icon="newTab"
                onClick={() => openInNewTab(buildingDigitalTourUrl || landDigitalTourUrl)}
              />
            </Col>
          )}
          {!_isEmpty(_media.propertyMarketingCollateral) && (
            <Col xs={24} sm={8} md={6} lg={4}>
              <MediaButton
                // title={t("PropertyPreview.PropertyBrochure")}
                title={getLocalTranslatedValue(language.current, "PropertyBrochure")}
                icon="newTab"
                onClick={() => openInNewTab(extractMediaUrl(_media.propertyMarketingCollateral[0]))}
              />
            </Col>
          )}
          {!_isEmpty(_media.sitePlan) && (
            <Col xs={24} sm={4} md={3}>
              <MediaButton
                // title={t("PropertyPreview.SitePlan")}
                title={getLocalTranslatedValue(language.current, "SitePlan")}
                icon="newTab"
                onClick={() => openInNewTab(extractMediaUrl(_media.sitePlan[0]))}
              />
            </Col>
          )}
          {(buildingMicrositeUrl || landMicrositeUrl) && (
            <Col xs={24} sm={4} md={3}>
              <MediaButton
                // title={t("PropertyPreview.ViewMore")}
                title={getLocalTranslatedValue(language.current, "ViewMore")}
                icon="newTab"
                onClick={() => openInNewTab(buildingMicrositeUrl || landMicrositeUrl)}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  };

  return (
    <div className="property-search-preview">
      <PropertyHeader />
      {/* <Breadcrumb /> */}
      {propertyType === "building" ? <BuildingInfo values={values} /> : <LandInfo values={values} />}

      <PropertyMedia />
      {propertyType === "building" ? (
        <BuildingDetails
          values={values}
          updatedAt={updatedAt}
          activeMeasurement={activeMeasurement}
          radioOptions={radioOptions}
          onChangeMeasurement={onChangeMeasurement}
        />
      ) : (
        <LandDetails
          values={values}
          updatedAt={updatedAt}
          activeMeasurement={activeMeasurement}
          radioOptions={radioOptions}
          onChangeMeasurement={onChangeMeasurement}
        />
      )}
      {propertyType === "building" && values.unitInfo.length > 1 && (
        <MultipleUnits values={values} activeMeasurement={activeMeasurement} />
      )}
      <ContactDetails
        values={values}
        propertyType={propertyType}
        employeeList={employeeList}
        brokerList={brokerList}
        selectedContacts={selected?.contacts}
      />
    </div>
  );
};

MediaButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
};

PropertySearchPreview.propTypes = {
  form: PropTypes.object,
  selected: PropTypes.object,
  media: PropTypes.object,
  translatedMedia: PropTypes.object,
  propertyType: PropTypes.string,
  employeeList: PropTypes.array,
  brokerList: PropTypes.array,
};

export default PropertySearchPreview;
