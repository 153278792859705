import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const property = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.PROPERTY_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.PROPERTY_SUGGESTION_REQUEST:
      draft.suggestionLoading = true;
      return draft;
    case ACTIONS.PROPERTY_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.value = action.response;
      return draft;
    case ACTIONS.PROPERTY_SUGGESTION_SUCCESS:
      draft.suggestionLoading = false;
      draft.error = null;
      return draft;
    case ACTIONS.PROPERTY_TAGS_SUCCESS:
      draft.tags = action.response;
      return draft;
    case ACTIONS.CURRENT_VACANT_PROPERTIES_TAGS_SUCCESS:
      draft.currentVacantPropertyTags = action.response;
      return draft;
    case ACTIONS.PROPERTY_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    case ACTIONS.CURRENT_VACANT_PROPERTY_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.currentVacantPropertyPagination = action.response.pagination;
      return draft;
    case ACTIONS.SELECTED_PROPERTY_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.selected = action.response;
      return draft;
    case ACTIONS.PROPERTY_CODES_SUCCESS:
      draft.loadingCodes = false;
      draft.error = null;
      draft.propertyCodes = action.response;
      return draft;
    case ACTIONS.PROPERTY_MEDIA_SUCCESS:
      draft.loadingMedia = false;
      draft.error = null;
      draft.selectedPropertyMedia = action.response;
      return draft;
    case ACTIONS.PROPERTY_CODE_DETAILS_SUCCESS:
      draft.loadingCodes = false;
      draft.error = null;
      return draft;
    case ACTIONS.PROPERTY_ADDRESS_FIELDS_LIST_SUCCESS:
      draft.loading = false;
      draft.allCountryAddressFields = action.response;
      return draft;
    case ACTIONS.PROPERTY_ERROR:
      draft[action.payload.key] = false;
      draft.error = action.payload.error;
      return draft;
    case ACTIONS.PROPERTY_SUGGESTION_ERROR:
      draft.suggestionLoading = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.GET_ALL_PROPERTY_BROKERS_SUCCESS:
      draft.loadingBrokers = false;
      draft.error = null;
      draft.brokerList = action.response.list;
      draft.paginationBroker = action.response.pagination;
      return draft;
    case ACTIONS.GET_ALL_PROPERTY_EMPLOYEES_SUCCESS:
      draft.loadingEmployees = false;
      draft.error = null;
      draft.employeeList = action.response.list;
      draft.paginationEmployee = action.response.pagination;
      return draft;
    case ACTIONS.GET_ALL_PROPERTY_ESM_SUCCESS:
      draft.loadingESM = false;
      draft.error = null;
      draft.esmList = action.response.list;
      draft.paginationESM = action.response.pagination;
      return draft;
    case ACTIONS.GET_ALL_PROPERTY_MARKETS_SUCCESS:
      draft.loadingMarkets = false;
      draft.error = null;
      draft.marketList = action.response.list;
      draft.paginationMarket = action.response.pagination;
      return draft;
    case ACTIONS.PROPERTY_ACTIVE_PROPERTYROLLUP_SUCCESS:
      draft.loadingPropertyRollUp = false;
      draft.error = null;
      draft.propertyRollUpList = action.response.list;
      return draft;
    case ACTIONS.ACTIVE_PROPERTY_MARKETS_SUCCESS:
      draft.loadingMarkets = false;
      draft.error = null;
      draft.marketActiveList = action.response;
      return draft;
    case ACTIONS.ACTIVE_PROPERTY_SUB_MARKET_SUCCESS:
      draft.loadingSubMarkets = false;
      draft.error = null;
      draft.subMarketActiveList = action.response;
      return draft;
    case ACTIONS.COMBINED_UNIT_NUMBER:
      draft.maxCombinedUnitNumber = action.maxCombinedUnitNumber;
      return draft;
    case ACTIONS.AUDIT_SUCCESS:
      draft.auditLoading = false;
      draft.error = null;
      draft.auditList = action.response;
      return draft;
    case ACTIONS.AUDIT_ISSUE_ID_NUMBER:
      draft.maxAuditIssueIdNumber = action.maxAuditIssueIdNumber;
      return draft;
    case ACTIONS.PROPERTY_CONVERT_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.loadingConvertProperty = false;
      return draft;
    default:
      return draft;
  }
});

export default property;
