import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const home = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.HOME_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.UNITS_TO_REVIEW_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.unitsToReview = action.response.list;
      return draft;
    case ACTIONS.HOME_TAGS_SUCCESS:
      draft.homeTags = action.response;
      return draft;
    case ACTIONS.HOME_PAGINATION_SUCCESS:
      draft.homePagination = action.response;
      return draft;
    case ACTIONS.VACANT_UNITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.vacantUnits = action.response.list;
      return draft;
    case ACTIONS.UPCOMING_VACANT_UNITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.upcomingVacantUnits = action.response.list;
      return draft;
    case ACTIONS.ACTIVE_AUDITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.activeAudits = action.response.list;
      return draft;
    case ACTIONS.NOT_MARKETING_UNITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.notMarketingUnits = action.response.list;
      return draft;
    case ACTIONS.MARKETABLE_UNITS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.marketableUnits = action.response.marketableUnits;
      draft.marketableUnitsCount = action.response.marketableUnitsCount;
      return draft;
    case ACTIONS.HOME_ERROR:
      draft[action.key] = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default home;
