import { Button, Collapse } from "antd";
import _isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import Editor from "../../components/Editor/Editor";
import { getAnnouncement } from "../../store/actions/announcement.action";
import { ANNOUNCEMENT_DEFAULT_MESSAGE, ANNOUNCEMENT_ID } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import "./Announcements.scss";

const { Panel } = Collapse;

const Announcements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canCreateAnnouncement = permissionsUtil.checkAuth("announcement_create");

  const { value: announcement } = useSelector(state => state.announcement);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await dispatch(getAnnouncement(ANNOUNCEMENT_ID));
  };

  const toggleModal = async modalState => {
    setIsModalOpen(modalState);
  };

  return (
    <div className="announcements">
      {!_isEmpty(announcement) ? (
        <Collapse
          className="announcement-collapse"
          expandIconPosition="right"
          expandIcon={({ isActive }) => <CustomIcon name="DownOutlined" rotate={isActive ? 180 : 0} />}
        >
          <Panel
            header={
              <div className="collapse-header">
                <CustomIcon className="header-icon" name="BellFilled" />
                <p>{_isEmpty(announcement.title) ? ANNOUNCEMENT_DEFAULT_MESSAGE.title : announcement.title}</p>
              </div>
            }
            key={announcement.id}
            extra={
              canCreateAnnouncement && (
                <Button
                  type="primary"
                  onClick={eve => {
                    eve.stopPropagation();
                    toggleModal(true);
                  }}
                >
                  <CustomIcon name="EditOutlined" />
                  {t("Buttons.Edit")}
                </Button>
              )
            }
          >
            <h3>{_isEmpty(announcement.subtitle) ? ANNOUNCEMENT_DEFAULT_MESSAGE.subtitle : announcement.subtitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: _isEmpty(announcement.message) ? ANNOUNCEMENT_DEFAULT_MESSAGE.message : announcement.message,
              }}
            ></div>
          </Panel>
        </Collapse>
      ) : null}
      <AppModal
        title={t("Editor.EditAnnouncement")}
        width={800}
        visible={isModalOpen}
        onCancel={() => {
          toggleModal(false);
        }}
        footer={null}
        destroyOnClose
      >
        <Editor announcement={announcement} onCloseModal={toggleModal} />
      </AppModal>
    </div>
  );
};

export default Announcements;
