import React, { useState } from "react";
import { Tag, Col, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./EditableTagGroup.scss";
import { PROPERTY_STATUS_UI_MAPPING, PROPERTY_TYPE_UI_MAPPING } from "../../utils/constants";

const EditableTagGroup = ({ tags, updateTags }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    updateTags(newTags);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const Tags = collapsed ? tags.slice(0, 7) : tags;
  return (
    <div className="editable-tag-group">
      <Col className="tags" xs={18} sm={18} md={18} lg={18} xl={20} xxl={20}>
        {Tags.map(tag => {
          let _tag;
          if (PROPERTY_STATUS_UI_MAPPING[tag]) {
            _tag = t(`PropertyStatusUiMapping.${tag}`);
          } else if (PROPERTY_TYPE_UI_MAPPING[tag]) {
            _tag = t(`PropertyTypeUiMapping.${tag}`);
          } else {
            _tag = tag;
          }
          return (
            <span key={tag} style={{ display: "inline-block" }}>
              <Tag
                className="text-capitalize"
                closable
                onClose={e => {
                  e.preventDefault();
                  handleClose(tag);
                }}
              >
                {_tag}
              </Tag>
            </span>
          );
        })}
      </Col>

      <Col className="expand-btn" xs={8} sm={8} md={8} lg={4} xl={4} xxl={4}>
        {tags.length > 7 && (
          <Button onClick={toggleCollapse} className="ant-btn" class="ant-btn ant-btn-block  add-property-btn">
            {collapsed ? (
              <>
                {t("Expand")}
                <DownOutlined className="icon" />
              </>
            ) : (
              <>
                {t("Collapse")}
                {' '}
&nbsp;
                <UpOutlined className="icon" />
              </>
            )}
          </Button>
        )}
      </Col>
    </div>
  );
};

EditableTagGroup.defaultProps = {};

EditableTagGroup.propTypes = {
  tags: PropTypes.array,
  updateTags: PropTypes.func,
};

export default EditableTagGroup;
