import React from "react";
import { Row, Col, Radio } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useLanguage } from "../../hooks";
import { getLocalTranslatedValue } from "../../i18n";
import { dateLanguageLookup, MEASUREMENT_TYPE, MEASURMENT_UNIT_CODES } from "../../utils/constants";
import { buildAddressForPreview, getLandMeasurements } from "../../utils/property.util";

const LandDetails = ({ values, updatedAt, radioOptions, activeMeasurement, onChangeMeasurement }) => {
  const { language } = useLanguage();
  const translatedValues = values.translations[language.current];
  const _updateAt = new Date(updatedAt).toLocaleString(dateLanguageLookup[language.current], { dateStyle: "long" });
  const address = buildAddressForPreview(values.landInfo.address, translatedValues?.address, language.current);

  const measurements = getLandMeasurements(
    _get(values, "landInfo.landSize", 0),
    _get(values, "landInfo.maxFutureBuildingSize", 0),
    _get(values, "landInfo.landSizeUOM", ""),
    _get(values, "landInfo.maxFutureBuildingSizeUOM", ""),
  );
  const getTotalArea = () =>
    activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY
      ? `${measurements[0].data.land_area.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.AC}`
      : `${measurements[1].data.land_area.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.HA}`;

  const getBuildableArea = () =>
    activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY
      ? `${measurements[0].data.buildable_area.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.SF}`
      : `${measurements[1].data.buildable_area.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.SQM}`;

  return (
    <div className="property-details w-container">
      <Row gutter={16} className="row-vertical-padding-16">
        <Col xs={24} md={8} lg={6}>
          <Row>
            <Col xs={24} className="property-item-title">
              {getLocalTranslatedValue(language.current, "TotalArea")}
            </Col>
            <Col xs={24} className="property-item-value">
              {getTotalArea()}
            </Col>
          </Row>
          {values.landInfo.maxFutureBuildingSize && (
            <Row>
              <Col xs={24} className="property-item-title">
                {getLocalTranslatedValue(language.current, "BuildableArea")}
              </Col>
              <Col xs={24} className="property-item-value">
                {getBuildableArea()}
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Row>
            <Col xs={24} className="property-item-title">
              {getLocalTranslatedValue(language.current, "Address")}
            </Col>
            <Col xs={24} className="property-item-value">
              {address}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8} lg={12} className="text-end">
          <Radio.Group
            options={radioOptions}
            optionType="button"
            buttonStyle="solid"
            value={activeMeasurement}
            onChange={onChangeMeasurement}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="property-heading">
          {getLocalTranslatedValue(language.current, "PropertyDetails")}
        </Col>
        <Col xs={24} className="property-update-date">
          {`${getLocalTranslatedValue(language.current, "Updated")}: ${_updateAt}`}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={16}>
          <p className="property-item-value">
            {language.current === "en"
              ? _get(values, "landInfo.marketingDescription", "")
              : _get(translatedValues, "landInfo.marketingDescription", "")}
          </p>
        </Col>
        <Col xs={24} lg={8} className="property-item-value">
          <Row>
            <Col xs={24} className="property-item-title">
              {getLocalTranslatedValue(language.current, "Features")}
            </Col>
            <Col xs={24} className="property-item-value">
              {language.current === "en" ? (
                <ul className="property-features-list">
                  {!_isEmpty(values.keyFeatures.feature1) && <li>{values.keyFeatures.feature1}</li>}
                  {!_isEmpty(values.keyFeatures.feature2) && <li>{values.keyFeatures.feature2}</li>}
                  {!_isEmpty(values.keyFeatures.feature3) && <li>{values.keyFeatures.feature3}</li>}
                  {!_isEmpty(values.keyFeatures.feature4) && <li>{values.keyFeatures.feature4}</li>}
                  {!_isEmpty(values.keyFeatures.feature5) && <li>{values.keyFeatures.feature5}</li>}
                  {!_isEmpty(values.keyFeatures.feature6) && <li>{values.keyFeatures.feature6}</li>}
                </ul>
              ) : (
                <ul className="property-features-list">
                  {!_isEmpty(translatedValues.keyFeatures.feature1) && <li>{translatedValues.keyFeatures.feature1}</li>}
                  {!_isEmpty(translatedValues.keyFeatures.feature2) && <li>{translatedValues.keyFeatures.feature2}</li>}
                  {!_isEmpty(translatedValues.keyFeatures.feature3) && <li>{translatedValues.keyFeatures.feature3}</li>}
                  {!_isEmpty(translatedValues.keyFeatures.feature4) && <li>{translatedValues.keyFeatures.feature4}</li>}
                  {!_isEmpty(translatedValues.keyFeatures.feature5) && <li>{translatedValues.keyFeatures.feature5}</li>}
                  {!_isEmpty(translatedValues.keyFeatures.feature6) && <li>{translatedValues.keyFeatures.feature6}</li>}
                </ul>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

LandDetails.propTypes = {
  values: PropTypes.object,
  updatedAt: PropTypes.object,
  radioOptions: PropTypes.array,
  activeMeasurement: PropTypes.object,
  onChangeMeasurement: PropTypes.func,
};

export default LandDetails;
