import { Button, Col, Form, Input, Row, Skeleton, Switch } from "antd";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import CustomSelect from "../../components/CustomSelect";
import {
  brokerAssociatedPropertiesSuccess,
  createBroker,
  getBrokerAssociatedProperties,
  getBrokerById,
  getBrokerByIdSuccess,
  updateBroker,
} from "../../store/actions/broker.actions";
import { getActiveMarkets } from "../../store/actions/market.actions";
import { SEARCH_QUERY, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { isValidPhoneNumber, populateDisplayName } from "../../utils/utils";
import "./AddEditBroker.scss";

const queryParam = { filterCriteria: { isActive: true }, sort: "id,asc" };

const AddEditBroker = ({ brokerId, onSuccess, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { value: broker, loading, error, associatedProperties } = useSelector(store => store.broker);
  const { activeList: markets, loading: fetchingMarkets } = useSelector(store => store.market);

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchingBroker, setFetchingBroker] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const canEditUser = permissionsUtil.checkAuth("user_edit");

  const onFormFinish = async fieldValues => {
    const _fieldValues = { ...fieldValues, markets: _map(fieldValues.markets, "value") };

    if (brokerId) {
      delete _fieldValues.id;
      await dispatch(updateBroker(brokerId, _fieldValues));
    } else {
      _fieldValues.isActive = _fieldValues.isActive || false;
      await dispatch(createBroker(_fieldValues));
    }
    onCloseModal(false);
    if (onSuccess) {
      onSuccess(brokerId);
    }
  };

  useEffect(() => {
    const didMount = async () => {
      fetchMarkets();
      if (brokerId) {
        setIsEditMode(true);
        setFetchingBroker(true);
        dispatch(getBrokerAssociatedProperties(brokerId));
        await dispatch(getBrokerById(brokerId));
        setFetchingBroker(false);
      } else {
        form.setFieldsValue({ isActive: true });
      }
    };
    didMount();
  }, []);

  useEffect(
    () => () => {
      dispatch(brokerAssociatedPropertiesSuccess([]));
      form.resetFields();
    },
    [],
  );

  useEffect(() => {
    if (broker) {
      form.setFieldsValue({
        markets: _map(broker?.markets, m => ({
          ...m,
          label: m.id,
          value: m.id,
        })),
      });
    }
  }, [broker]);

  const fetchMarkets = async query => {
    let _query = query;
    if (!_query) {
      _query = { ...SEARCH_QUERY, ...queryParam };
    }
    dispatch(getActiveMarkets(_query));
  };

  const updateDisplayName = e => {
    populateDisplayName(form);
  };

  const _onChangeStatus = status => {
    if (!status && !_isEmpty(associatedProperties)) {
      setIsModalOpen(true);
    }
  };

  const _onCloseModal = () => {
    setIsModalOpen(false);
    form.setFieldsValue({ isActive: true });
  };

  const _onConfirm = () => {
    setIsModalOpen(false);
    form.setFieldsValue({ isActive: false });
  };

  return (
    <div className="edit-broker">
      <Skeleton loading={fetchingBroker} active paragraph={{ rows: 6 }}>
        <Form
          form={form}
          className="edit-broker"
          layout="vertical"
          name="nest-messages"
          initialValues={broker}
          onFinish={onFormFinish}
          validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
        >
          <Row className="fields-row" gutter={20} type="flex">
            <Col className={!isEditMode ? "hidden" : ""} xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("AddEditBroker.BrokerId")} name="id">
                <Input placeholder={t("AddEditBroker.BrokerId")} disabled />
              </Form.Item>
            </Col>
            <Col className={!isEditMode ? "hidden" : ""} xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.DisplayName")} name="displayName">
                <Input placeholder={t("User.DisplayName")} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.FirstName")} name="firstName" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.FirstName")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.LastName")} name="lastName" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.LastName")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={t("AddEditBroker.Phone")}
                name="phone"
                validateFirst
                rules={[
                  {
                    whitespace: true,
                  },
                  { validator: isValidPhoneNumber },
                ]}
              >
                <Input placeholder={t("AddEditBroker.Phone")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.Email")} name="email" rules={[{ required: true, type: "email" }]}>
                <Input placeholder={t("User.Email")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.JobTitle")} name="jobTitle" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.JobTitle")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.Company")} name="company" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.Company")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.LicenseNumber")} name="licenseNumber">
                <Input placeholder={t("User.LicenseNumber")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.IsActive")} hidden={!canEditUser} name="isActive" valuePropName="checked">
                <Switch onChange={_onChangeStatus} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate label={t("User.MarketLabel")} name="markets" rules={[{ required: true }]}>
                <CustomSelect
                  isMulti
                  placeholder={t("User.MarketPlaceholder")}
                  loading={fetchingMarkets}
                  fetchOptions={fetchMarkets}
                  optionsList={markets}
                  // renderOptions={value => value.id}
                  queryParam={queryParam}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row className="fields-row" gutter={20} type="flex" justify="center">
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Button
                    block
                    onClick={() => {
                      onCloseModal(false);
                      dispatch(getBrokerByIdSuccess({}));
                    }}
                  >
                    {t("User.DiscardChanges")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Button block type="primary" htmlType="submit" loading={loading}>
                    {brokerId ? t("User.SaveChanges") : t("Buttons.Create")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Skeleton>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title="Warning"
        width={600}
        minHeight="20vh"
        maxHeight="60vh"
        overFlowY="auto"
        visible={isModalOpen}
        onCancel={_onCloseModal}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block disabled={loading} onClick={_onCloseModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={_onConfirm} loading={loading}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <p>{t("AddEditBroker.Description")}</p>
        <Row gutter={[8, 8]} justify="center">
          {_map(associatedProperties, (val, key) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} className="associated-property">
              {val.id}
            </Col>
          ))}
        </Row>
      </AppModal>
    </div>
  );
};

AddEditBroker.propTypes = {
  brokerId: PropTypes.string,
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default AddEditBroker;
