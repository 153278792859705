import { get, put, post } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const SubMarketService = {
  suggestions: keyword => get(`submarket/suggestion?keyword=${keyword}`),
  search: query => get(`submarket/search${getSearchListQuery(query)}`),
  get: () => get("submarket"),
  getById: id => get(`submarket/${id}`),
  update: (id, body) => put(`submarket/${id}`, body),
  create: body => post(`submarket`, body),
};

export default SubMarketService;
