import { Form, Select } from "antd";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _some from "lodash/some";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "../../hooks";
import propertyService from "../../services/property.service";
import { getPropertyCodes } from "../../store/actions/property.actions";
import { PROPERTY_TYPE_KEYS, PROPERTY_TYPE_UI_MAPPING, SEARCH_QUERY } from "../../utils/constants";
import { getTooltip } from "../../utils/utils";
import CustomSelect from "../CustomSelect";
import "./PropertyCodeSelect.scss";

const { Option } = Select;

export default function PropertyCodeSelect({
  isEditView,
  setSelectedProperty,
  setIsTemporaryProperty,
  viewOnlyMode,
  propertyCodeError,
  setPropertyCodeError,
  queryParam,
  formKey,
  label,
  placeholder,
  tooltip,
  newCodeLabel,
  isConvertProperty,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState([]);
  const { loadingCodes, propertyCodes } = useSelector(state => ({
    ...state.property,
  }));
  const { language } = useLanguage();

  useEffect(() => {
    if (!viewOnlyMode && !isEditView) dispatch(getPropertyCodes({ ...SEARCH_QUERY, ...queryParam }));
  }, []);

  const _fetchOptions = query => {
    const _query = { ...query, keyword: query?.keyword?.toLocaleUpperCase() };
    dispatch(getPropertyCodes(_query));
    setKeyword(_query.keyword || []);
  };

  const _onChange = async params => {
    try {
      const isNewPropertyCode = params.label.includes(` (${newCodeLabel})`);
      if (!isEditView && !_isEmpty(keyword) && isNewPropertyCode && !isConvertProperty) {
        setPropertyCodeError({ validateStatus: "validating", help: t("PropertyCodeSelect.Verifying") });
        await propertyService.verifyPropertyCode(params.value);
        setPropertyCodeError({});
        setIsTemporaryProperty(true);
      } else if (!isEditView && !_isEmpty(propertyCodes)) {
        setPropertyCodeError({});
        setIsTemporaryProperty(false);
      }
      setSelectedProperty(params);
    } catch (err) {
      setPropertyCodeError({ validateStatus: "error", help: err });
    }
  };

  let _propertyCodes = [];
  const isNewCode = !_some(propertyCodes, pCode => !_isEmpty(keyword) && pCode.value === keyword);
  if (!_isEmpty(keyword) && !loadingCodes && isNewCode && !isConvertProperty) {
    _propertyCodes = [{ label: `${keyword} (${newCodeLabel})`, value: keyword, isNew: true }];
  }
  if (!_isEmpty(propertyCodes)) {
    const options = _map(propertyCodes, code => {
      let resObj = {};
      if (isConvertProperty) {
        const _propertyType =
          code.propertyType === PROPERTY_TYPE_KEYS.building
            ? PROPERTY_TYPE_UI_MAPPING.building
            : PROPERTY_TYPE_UI_MAPPING.land;
        resObj = {
          label: `${code.propertyCode} (${_propertyType})`,
          value: code.propertyCode,
        };
      } else {
        resObj = {
          label: code.propertyCode,
          value: code.propertyCode,
        };
      }

      return resObj;
    });
    _propertyCodes = [..._propertyCodes, ...options];
  }

  let errorPayload = {};
  if (propertyCodeError.help && propertyCodeError.validateStatus) {
    errorPayload = {
      hasFeedback: propertyCodeError.validateStatus === "validating",
      validateStatus: propertyCodeError.validateStatus,
      help: propertyCodeError.help,
    };
  }
  return (
    <Form.Item
      tooltip={getTooltip(tooltip, language)}
      label={label}
      name={formKey}
      validateFirst
      rules={[{ required: true }]}
      shouldUpdate
      {...errorPayload}
      className="property-code-select"
    >
      <CustomSelect
        placeholder={placeholder}
        loading={loadingCodes}
        fetchOptions={_fetchOptions}
        optionsList={_propertyCodes}
        showArrow={false}
        disabled={viewOnlyMode || isEditView}
        onChange={_onChange}
        queryParam={queryParam}
        renderOptions={d => (
          <Option key={d.value} value={d.value}>
            {d.label}
          </Option>
        )}
      />
    </Form.Item>
  );
}

PropertyCodeSelect.propTypes = {
  isEditView: PropTypes.bool,
  setSelectedProperty: PropTypes.func,
  setIsTemporaryProperty: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  queryParam: PropTypes.object,
  formKey: PropTypes.array,
  propertyCodeError: PropTypes.shape({
    validateStatus: PropTypes.string,
    help: PropTypes.string,
  }),
  setPropertyCodeError: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.object,
  newCodeLabel: PropTypes.string,
  isConvertProperty: PropTypes.bool,
};
