import PropertyContactService from "../../services/propertyContactHistory.service";
import { ERROR_MESSAGE, PAGINATOIN } from "../../utils/constants";
import { throwError, to, toastMessage, downloadFile, setSessionStorage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function propertyContactRequest() {
  return { type: ACTIONS.PROPERTY_CONTACT_REQUEST };
}

export function propertyContactSuggestionRequest() {
  return { type: ACTIONS.PROPERTY_CONTACT_SUGGESTION_REQUEST };
}

export function propertyContactSuccess(response) {
  return { type: ACTIONS.PROPERTY_CONTACT_SUCCESS, response };
}

export function propertyContactSuggestionSuccess() {
  return { type: ACTIONS.PROPERTY_CONTACT_SUGGESTION_SUCCESS };
}

export function propertyContactSuggestionError(error) {
  return { type: ACTIONS.PROPERTY_CONTACT_SUGGESTION_ERROR, error };
}

export function propertyContactError(error) {
  return { type: ACTIONS.PROPERTY_CONTACT_ERROR, error };
}

export function propertyContactListSuccess(response) {
  return { type: ACTIONS.PROPERTY_CONTACT_LIST_SUCCESS, response };
}

export function getPropertyContactsList(query) {
  return async dispatch => {
    dispatch(propertyContactRequest());
    try {
      const [err, response] = await to(PropertyContactService.searchPropertyContacts(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(propertyContactListSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.contactHistory, pagination);
    } catch (error) {
      dispatch(propertyContactError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertyContactsSuggestion(payload) {
  return async dispatch => {
    dispatch(propertyContactSuggestionRequest());
    try {
      const [err, response] = await to(PropertyContactService.suggestions(payload));
      if (err) throwError(err);
      dispatch(propertyContactSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(propertyContactSuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function exportPropertyContacts() {
  return async dispatch => {
    dispatch(propertyContactRequest());
    try {
      const [err, response] = await to(PropertyContactService.exportPropertyContacts());
      if (err) throwError(err);
      downloadFile(response, "Property-Contacts-History.xlsx");
      dispatch(propertyContactSuccess({}));
    } catch (error) {
      dispatch(propertyContactError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}
