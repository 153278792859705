import { Button, Col, Form, Radio, Row, Select, Switch } from "antd";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useKeyword } from "../../hooks";
import { getAuditsSuggestion, openAuditsListSuccess, openAuditsTagSuccess } from "../../store/actions/audit.action";
import {
  getUnitVacancySuggestion,
  upcomingVacantListSuccess,
  upcomingVacantPropertiesTagsSuccess,
} from "../../store/actions/unitVacancy.actions";
import { homePaginationSuccess, homeTagsSuccess } from "../../store/actions/home.actions";
import { getLandVacancySuggestion } from "../../store/actions/landVacancy.actions";
import { getAllMarkets } from "../../store/actions/market.actions";
import {
  getPropertiesSuggestion,
  propertyListSuccess,
  currentVacantPropertyListSuccess,
  currentVacantPropertiesTagsSuccess,
  propertyTagsSuccess,
} from "../../store/actions/property.actions";
import { subMarketListSuccess } from "../../store/actions/subMarket.actions";
import {
  TAG,
  HOME_PROPERTY_TYPE,
  PROPERTY_KIND,
  PROPERTY_KIND_LOOKUP,
  PROPERTY_STAGE,
  PROPERTY_TYPE,
  SEARCH_QUERY,
  PAGINATOIN,
  SUGGESTION_SEARCH_STATE,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
  AVAILABILITY_PAGE_PROPERTY_SORTER,
  DEFAULT_PROPERTY_SORTER,
} from "../../utils/constants";
import {
  checkEmptyObj,
  getFeatureFlags,
  getMatchedValues,
  getSorterObj,
  removeEmptyElements,
  searchQueryGenerator,
  sortAlphabetically,
  submitSearchForm,
  setSessionStorage,
} from "../../utils/utils";

const { Option } = Select;

const SearchPropertyHorizontal = ({
  pagination,
  tags,
  homeTags,
  shouldFetchData,
  getList,
  defaultSort,
  propertyType,
  searchState,
  isFromHome,
  homePagination,
}) => {
  const { t } = useTranslation();
  const [applySort, setApplySort] = useState(false);
  const [resetFieldFlag, setResetFieldFlag] = useState(false);
  const { keyword, setKeyword } = useKeyword({ pagination });
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    isTemp: undefined,
  });
  const [sorter, setSorter] = useState(defaultSort);
  const [form] = Form.useForm();
  const { suggestionLoading } = useSelector(state => state[searchState]);
  const { list: markets, loading } = useSelector(state => state.market);
  const { list: subMarkets, loading: subMarketLoading } = useSelector(state => state.subMarket);
  const FEATURE_FLAG = getFeatureFlags();
  const { SUBMARKET } = FEATURE_FLAG;

  /**
   * Sync form filters and table sorts
   * with pagination object
   */
  useEffect(() => {
    if (!_isEmpty(pagination) && !_isEmpty(pagination.sort)) {
      setSorter(getSorterObj(pagination.sort));
    }
    if (!_isEmpty(pagination) && !_isEmpty(pagination.filterCriteria)) {
      form.setFieldsValue({
        isTemp: pagination?.filterCriteria?.isTemp,
        isDraft: pagination?.filterCriteria?.isDraft,
      });
      syncTagFields(pagination.filterCriteria);
    }
  }, [pagination]);

  /**
   * Initial search call
   */
  useEffect(() => {
    (async () => {
      await dispatch(getAllMarkets());
      _onSubmit();
    })();
  }, []);

  /**
   * Setting submarket individually because
   * of sync issues with other filters
   */
  useEffect(() => {
    if (!_isEmpty(subMarkets)) {
      const _tags =
        (!_isEmpty(tags) && tags) || (!_isEmpty(pagination?.filterCriteria?.subMarket) && pagination?.filterCriteria);
      if (!_isEmpty(_tags)) {
        form.setFieldsValue({
          subMarket: getMatchedValues(_tags, subMarkets),
        });
      }
    }
  }, [subMarkets]);

  /**
   * Sync markets and submarkets with tags
   */
  useEffect(() => {
    let _filterValues;
    if (!_isEmpty(markets)) {
      if (!_isEmpty(tags)) {
        _filterValues = tags;
      } else if (!_isEmpty(pagination) && !_isEmpty(pagination.filterCriteria)) {
        _filterValues = pagination.filterCriteria;
      }
      syncTagFields(_filterValues, true);
    }
  }, [tags, markets, pagination]);

  /**
   * Sync form fields with tags when tags are updated
   * should reflect the same on form
   */
  const syncTagFields = async (_tags, updateSubmarkets = false) => {
    form.setFieldsValue({
      propertyType: isFromHome
        ? getMatchedValues(HOME_PROPERTY_TYPE, PROPERTY_TYPE)
        : getMatchedValues(_tags, PROPERTY_TYPE),

      propertyStage: getMatchedValues(_tags, PROPERTY_STAGE),
      market: getMatchedValues(_tags, markets),
    });
    if (updateSubmarkets) await _setSubMarkets(getMatchedValues(_tags, markets));
    if (shouldFetchData) _onSubmit();
  };

  /**
   * get filter criteria from form fields
   */
  const getFilterCriteria = fieldValues => ({
    market: removeEmptyElements(fieldValues.market || []),
    subMarket: removeEmptyElements(fieldValues.subMarket || []),
    status: removeEmptyElements(fieldValues.propertyStage || []),
    propertyType:
      searchState !== SUGGESTION_SEARCH_STATE.audit && searchState !== SUGGESTION_SEARCH_STATE.property
        ? [propertyType]
        : removeEmptyElements(fieldValues.propertyType || []),
    isTemp: fieldValues.isTemp,
    ...(searchState === SUGGESTION_SEARCH_STATE.unitVacancy && { availableUnits: { gt: 0 } }),
    isDraft: fieldValues.isDraft ? true : undefined,
    ...((searchState === SUGGESTION_SEARCH_STATE.unitVacancy || searchState === SUGGESTION_SEARCH_STATE.property) &&
      fieldValues.notMarketableNeedsReview && { availabileFilterCount: { gt: 0 } }),
  });

  /**
   * Create request body to search listing as per
   * filter criteria
   */
  const onFormFinish = async (fieldValues, option) => {
    const filterCriteria = getFilterCriteria(fieldValues);
    let _sorter;
    if (_isEmpty(keyword) || applySort) {
      /**
       * To reset sort on listing
       */
      if (!_isEmpty(pagination) && (resetFieldFlag || _isEmpty(pagination.sort)) && !applySort) {
        _sorter = defaultSort;
        setResetFieldFlag(false);
      } else {
        _sorter = sorter;
      }
    } else {
      /**
       * Remove sort if user has typed in the search bar
       */
      _sorter = null;
    }

    // In Properties page if the ‘Not Marketing – Needs Review’ filter is enabled, then we need to sort the list based on most marketable units on top
    if (searchState === SUGGESTION_SEARCH_STATE.property) {
      if (filterCriteria.availabileFilterCount) {
        _sorter = AVAILABILITY_PAGE_PROPERTY_SORTER;
      } else {
        _sorter = DEFAULT_PROPERTY_SORTER;
      }
    }

    if (searchState === SUGGESTION_SEARCH_STATE.audit) {
      /**
       * If listing is Audit, remove isTemp filter, so it will include
       * both, temp as well as regular properties in Audit listing
       */
      delete filterCriteria?.isTemp;
    }

    let currentPage = pagination?.page + 1 || 1;
    checkEmptyObj(filterCriteria);
    if (
      !_isEmpty(keyword) ||
      !_isEmpty(pagination?.keyword) ||
      !_isEmpty(filterCriteria) ||
      !_isEmpty(pagination?.filterCriteria)
    ) {
      if (!_isEqual(keyword, pagination?.keyword) || !_isEqual(filterCriteria, pagination?.filterCriteria)) {
        /**
         * If any filter is applied then active page
         * should be set to 1
         */
        currentPage = 1;
      }
    }

    if (option?.key) filterCriteria.id = option.key;

    const query = {
      ...pagination,
      currentPage,
      keyword: option?.key ? null : keyword,
      filterCriteria,
      sorter: _sorter,
      pageSize: pagination?.size,
      /**
       * Sending labelInValue attribute so it could later be
       * restored on page reload
       */
      labelInValue: !_isEmpty(option) ? option : undefined,
    };

    const _query = searchQueryGenerator(query);
    getList(_query);
  };

  /**
   * Get search options as user types into search bar
   */
  const getOptions = val => {
    const fieldValues = form.getFieldsValue();
    const filterCriteria = getFilterCriteria(fieldValues);
    const query = { ...SEARCH_QUERY, filterCriteria, keyword: val };
    let suggestionOptions;

    switch (searchState) {
      case SUGGESTION_SEARCH_STATE.audit:
        /**
         * If listing is Audit, remove isTemp filter, so it will include
         * both, temp as well as regular properties in Audit listing
         */
        delete filterCriteria?.isTemp;
        suggestionOptions = dispatch(getAuditsSuggestion(query));
        break;
      case SUGGESTION_SEARCH_STATE.unitVacancy:
        suggestionOptions = dispatch(getUnitVacancySuggestion(query));
        break;
      case SUGGESTION_SEARCH_STATE.landVacancy:
        suggestionOptions = dispatch(getLandVacancySuggestion(query));
        break;

      default:
        suggestionOptions = dispatch(getPropertiesSuggestion(query));
        break;
    }

    return suggestionOptions;
  };

  /**
   * Wrapper method to form submit which will be called
   * on each onChange event of form fields
   */
  const _onSubmit = (flag = true, option) => {
    submitSearchForm(flag, option, pagination, form, onFormFinish, setApplySort);
  };

  /**
   * Wrapper method to be called on search bar when
   * user presses Enter
   */
  const _onEnter = flag => {
    if (keyword.length) _onSubmit(flag);
  };

  /**
   * Update submarkets when user selects / deselects markets
   */
  const _onChangeMarket = key => {
    _onSubmit();
    _setSubMarkets(key);
  };

  /**
   * Set submarkets in form field as per selected markets
   */
  const _setSubMarkets = async key => {
    /**
     * Retrieve submarket list from market
     */
    let subMarketsList = markets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);

    if (!_isEmpty(key)) {
      /**
       * If markets are selected, retrieve submarkets from
       * selected markets
       */
      const matchedMarkets = _map(key, _market => markets.find(market => market.id === _market));
      subMarketsList = matchedMarkets.reduce((prev, next) => [...prev, ..._get(next, "subMarkets", [])], []);
    }

    const selectedSubMarkets = form.getFieldValue("subMarket");

    if (!_isEmpty(selectedSubMarkets)) {
      /**
       * If submarkets are selected previously
       * then reflect changes as per selected markets
       */
      const matchedSubMarkets = selectedSubMarkets.filter(_selectedSubMarket =>
        subMarketsList.some(_subMarket => _subMarket.id === _selectedSubMarket),
      );

      form.setFieldsValue({ subMarket: matchedSubMarkets });
    }

    await dispatch(subMarketListSuccess({ list: subMarketsList }));
  };

  const _resetHomePage = async () => {
    setSessionStorage(PAGINATOIN.home, { filterCriteria: {} });
    setSessionStorage(TAG.home, []);
    await dispatch(homeTagsSuccess([]));
    await dispatch(homePaginationSuccess({ list: [], pagination: { filterCriteria: {} } }));
  };

  const _resetCurrentVacantPropertiesPage = async () => {
    setSessionStorage(PAGINATOIN.currentVacantPagination, { filterCriteria: {} });
    setSessionStorage(TAG.currentVacantProperties, []);
    await dispatch(currentVacantPropertiesTagsSuccess([]));
    await dispatch(currentVacantPropertyListSuccess({ list: [], pagination: { filterCriteria: {} } }));
  };

  const _resetAuditPage = async () => {
    setSessionStorage(PAGINATOIN.openAuditPagination, { filterCriteria: {} });
    setSessionStorage(TAG.openAudits, []);
    await dispatch(openAuditsTagSuccess([]));
    await dispatch(openAuditsListSuccess({ list: [], pagination: { filterCriteria: {} } }));
  };

  const _resetUpcomingVacantPropertiesPage = async () => {
    setSessionStorage(PAGINATOIN.upcomingPropertyPagination, { filterCriteria: {} });
    setSessionStorage(TAG.upcomingVacantProperties, []);
    await dispatch(upcomingVacantPropertiesTagsSuccess([]));
    await dispatch(upcomingVacantListSuccess({ list: [], pagination: { filterCriteria: {} } }));
  };

  /**
   * Reset filters to initial state
   */
  const _onClickReset = async () => {
    form.resetFields();
    setKeyword([]);
    setResetFieldFlag(true);
    if (isFromHome) {
      await _resetHomePage();
      await _resetAuditPage();
      await _resetCurrentVacantPropertiesPage();
      await _resetUpcomingVacantPropertiesPage();
    }
    _onSubmit(false);
  };

  return (
    <div className="search-property-horizontal">
      <Form
        hideRequiredMark
        form={form}
        className="search-form-properties"
        onFinish={onFormFinish}
        initialValues={initialValues}
        validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Row gutter={10}>
              <Col xs={24} lg={8} xl={4} xxl={5}>
                <SearchInput
                  value={keyword}
                  setValue={setKeyword}
                  onSelectValue={_onSubmit}
                  loading={suggestionLoading}
                  getOptions={getOptions}
                  onEnter={_onEnter}
                  placeholder={t("Properties.Placeholder")}
                  renderOptions={value => (
                    <Option key={value.id} value={value?.id}>
                      {`${value?.id} ${value?.marketingPropertyName ? `/ ${value?.marketingPropertyName}` : ""}`}
                    </Option>
                  )}
                />
              </Col>
              {searchState !== SUGGESTION_SEARCH_STATE.audit && (
                <Col xs={24} lg={8} xl={4} xxl={3}>
                  <Form.Item name="propertyStage">
                    <Select
                      mode="multiple"
                      listHeight={300}
                      showArrow
                      showSearch={false}
                      maxTagCount={1}
                      maxTagTextLength={3}
                      placeholder={t("PropertiesTable.Status")}
                      onChange={() => _onSubmit()}
                    >
                      {_map(PROPERTY_STAGE, propertyStage => (
                        <Select.Option
                          key={propertyStage.value}
                          value={propertyStage.value}
                          className="filter-option-item"
                        >
                          {propertyStage.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {searchState === SUGGESTION_SEARCH_STATE.property && (
                <Col xs={24} lg={8} xl={4} xxl={3}>
                  <Form.Item name="propertyType">
                    <Select
                      mode="multiple"
                      listHeight={300}
                      showArrow
                      showSearch={false}
                      maxTagCount={1}
                      maxTagTextLength={4}
                      placeholder={t("PropertiesTable.Type")}
                      onChange={() => _onSubmit()}
                      defaultValue={isFromHome && HOME_PROPERTY_TYPE.value}
                      disabled={isFromHome}
                    >
                      {_map(PROPERTY_TYPE, _propertyType => (
                        <Select.Option
                          key={_propertyType.value}
                          value={_propertyType.value}
                          className="filter-option-item"
                        >
                          {_propertyType.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} lg={8} xl={4} xxl={3}>
                <Form.Item name="market">
                  <Select
                    autoClearSearchValue={false}
                    loading={loading}
                    mode="multiple"
                    onChange={_onChangeMarket}
                    showSearch
                    showArrow
                    placeholder={t("PropertiesTable.Market")}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    autoComplete="off"
                    maxTagCount={1}
                    maxTagTextLength={3}
                  >
                    {_map(sortAlphabetically(markets), market => (
                      <Option key={market.id} value={market.id}>
                        {market.id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {searchState === SUGGESTION_SEARCH_STATE.property && (
                <>
                  {SUBMARKET?.status && (
                    <Col xs={24} lg={8} xl={4} xxl={3}>
                      <Form.Item name="subMarket">
                        <Select
                          autoClearSearchValue={false}
                          loading={subMarketLoading}
                          mode="multiple"
                          onChange={() => _onSubmit()}
                          showSearch
                          showArrow
                          placeholder={t("PropertiesTable.Submarket")}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          autoComplete="off"
                          maxTagCount={1}
                          maxTagTextLength={3}
                        >
                          {_map(sortAlphabetically(subMarkets), subMarket => (
                            <Option key={subMarket.id} value={subMarket.id}>
                              {subMarket.id}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
              <Col xs={24} lg={8} xl={4} xxl={2}>
                <Button block className="reset-filters-btn" onClick={_onClickReset}>
                  Reset
                </Button>
              </Col>
              {searchState === SUGGESTION_SEARCH_STATE.unitVacancy && (
                <Col xs={24} lg={9} xl={4}>
                  <Form.Item
                    name="notMarketableNeedsReview"
                    label={t("PropertiesTable.NotMarketingNeedsReview")}
                    valuePropName="checked"
                  >
                    <Switch onChange={() => _onSubmit()} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {searchState === SUGGESTION_SEARCH_STATE.property && (
          <Row gutter={24}>
            <Col xs={24} lg={7} xxl={5}>
              <Form.Item name="isTemp">
                <Radio.Group buttonStyle="solid" size="middle" onChange={() => _onSubmit()}>
                  {_map(PROPERTY_KIND, _propertyKind => (
                    <Radio.Button key={_propertyKind.value} value={_propertyKind.value} className="filter-option-item">
                      {_propertyKind.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} lg={9} xl={5}>
              <Form.Item
                name="notMarketableNeedsReview"
                label={t("PropertiesTable.NotMarketingNeedsReview")}
                valuePropName="checked"
              >
                <Switch onChange={() => _onSubmit()} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={9} xl={4}>
              <Form.Item name="isDraft" label={t("PropertiesTable.ShowOnlyDraft")} valuePropName="checked">
                <Switch onChange={() => _onSubmit()} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

SearchPropertyHorizontal.propTypes = {
  pagination: PropTypes.object,
  tags: PropTypes.array,
  homeTags: PropTypes.array,
  shouldFetchData: PropTypes.bool,
  getList: PropTypes.func,
  defaultSort: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  propertyType: PropTypes.string,
  searchState: PropTypes.string,
  isFromHome: PropTypes.bool,
  homePagination: PropTypes.object,
};

SearchPropertyHorizontal.defaultProps = {
  isFromHome: false,
  searchState: SUGGESTION_SEARCH_STATE.property,
};

export default SearchPropertyHorizontal;
