import ACTIONS from "./types.actions";
import { throwError, to, toastMessage } from "../../utils/utils";
import { SUCCESS_MESSAGE, ERROR_MESSAGE, ANNOUNCEMENT_DEFAULT_MESSAGE } from "../../utils/constants";
import AnnouncementService from "../../services/announcement.service";

export function announcementRequest() {
  return { type: ACTIONS.ANNOUNCEMENT_REQUEST };
}

export function announcementSuccess(response) {
  return { type: ACTIONS.ANNOUNCEMENT_SUCCESS, response };
}

export function announcementError(error) {
  return { type: ACTIONS.ANNOUNCEMENT_ERROR, error };
}

export function getAnnouncement(id) {
  return async dispatch => {
    dispatch(announcementRequest());
    try {
      const [err, response] = await to(AnnouncementService.getAnnouncement(id));
      if (err) throwError(err);
      else if (!response) {
        dispatch(announcementSuccess(ANNOUNCEMENT_DEFAULT_MESSAGE));
      } else {
        dispatch(announcementSuccess(response));
      }
    } catch (error) {
      dispatch(announcementError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}
export function updateAnnouncement(id, announcement) {
  return async dispatch => {
    dispatch(announcementRequest());
    try {
      const [err, response] = await to(AnnouncementService.updateAnnouncement(id, announcement));
      if (err) throwError(err);
      dispatch(announcementSuccess(response));
    } catch (error) {
      dispatch(announcementError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}
