import { Badge, Radio } from "antd";
import _isEmpty from "lodash/isEmpty";
import upperCase from "lodash/upperCase";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../../hooks";
// import i18n from "../../i18n";
import { setLanguage } from "../../store/actions/language.actions";
import { LANGUAGE_CODES, SECONDARY_LANGUAGE_CODES } from "../../utils/constants";
import "./LanguageSelect.scss";

const LanguageSelect = ({ form, propertyType, onLanguageChange, isHidden, isTranslated, errorTabs }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedCountry = form.getFieldValue([propertyType, "address", "country"]);

  const handleLanguageChange = async lang => {
    // i18n.changeLanguage(lang); // for static data
    const { current } = language;
    let currLang = lang;
    if (history?.location?.state?.translateLang) {
      currLang = history?.location?.state?.translateLang;
    }
    const payload = {
      current: currLang,
      secondary: secondaryLanguageCode,
    };
    await dispatch(setLanguage(payload));
    onLanguageChange(lang, current);
  };

  const _setLanguage = async lang => {
    let currLang = lang;
    if (history?.location?.state?.translateLang) {
      currLang = history?.location?.state?.translateLang;
    }
    const payload = {
      current: currLang,
      secondary: secondaryLanguageCode,
    };
    await dispatch(setLanguage(payload));
  };

  useEffect(() => {
    if (selectedCountry) {
      _setLanguage(LANGUAGE_CODES.English.value);
    }
  }, [selectedCountry]);

  const secondaryLanguageCode = SECONDARY_LANGUAGE_CODES[selectedCountry];

  if (_isEmpty(secondaryLanguageCode)) {
    return null;
  }

  const isInvalid = (langStatus, langCode) => {
    if (langStatus === "primary") {
      return (
        errorTabs[langStatus][propertyType] ||
        errorTabs[langStatus].keyFeatures ||
        errorTabs[langStatus].contacts ||
        errorTabs[langStatus].media ||
        errorTabs[langStatus].unitInfo
      );
    }
    if (langStatus === "secondary" && !_isEmpty(language.secondary) && errorTabs[langStatus][langCode]) {
      return (
        errorTabs[langStatus][langCode][propertyType] ||
        errorTabs[langStatus][langCode].keyFeatures ||
        errorTabs[langStatus][langCode].contacts ||
        errorTabs[langStatus][langCode].media ||
        errorTabs[langStatus][langCode].unitInfo
      );
    }
    return false;
  };

  return (
    <div className="language-options-container" style={{ visibility: isHidden ? "hidden" : "visible" }}>
      <Radio.Group
        size="middle"
        name="language-option"
        value={language.current}
        onChange={e => {
          handleLanguageChange(e.target.value);
        }}
        optionType="button"
      >
        <Radio.Button
          value={LANGUAGE_CODES.English.value}
          key={LANGUAGE_CODES.English.value}
          className={isInvalid("primary") ? "has-error primary-language" : "primary-language"}
        >
          {LANGUAGE_CODES.English.label}
        </Radio.Button>
        {!_isEmpty(secondaryLanguageCode) &&
          secondaryLanguageCode.map(langCode => (
            <Badge key={langCode} count={!isTranslated[langCode] ? "new" : null} className="badge-color">
              <Radio.Button
                value={langCode}
                key={langCode}
                className={isInvalid("secondary", langCode) ? "has-error secondary-language" : "secondary-language"}
              >
                {upperCase(langCode)}
              </Radio.Button>
            </Badge>
          ))}
      </Radio.Group>
    </div>
  );
};

LanguageSelect.propTypes = {
  form: PropTypes.object,
  errorTabs: PropTypes.object,
  isTranslated: PropTypes.object,
  isHidden: PropTypes.bool,
  propertyType: PropTypes.string,
  onLanguageChange: PropTypes.func,
};

export default LanguageSelect;
