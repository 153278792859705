import { green, red } from "@ant-design/colors";
import { Col, Progress, Row, Space, Tag, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import EditableTagGroup from "../../components/EditableTagGroup/EditableTagGroup";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchListView from "../../components/SearchListView/SearchListView";
import { usePropertyFilterTags, usePropertyPagination, useAuditPagination, useOpenAuditFilterTags } from "../../hooks";
import { homePaginationSuccess, homeTagsSuccess } from "../../store/actions/home.actions";
import { getAudits, auditTagsSuccess, openAuditsTagSuccess } from "../../store/actions/audit.action";

import {
  DATE_FORMAT_TIME,
  DEFAULT_SORTER,
  HOME_PROPERTIES_TITLE,
  HOME_PROPERTIES_TYPE,
  PAGINATOIN,
  PROPERTY_TYPE_KEYS,
  ROUTES,
  SORT_DIRECTION,
  SUGGESTION_SEARCH_STATE,
  TAG,
} from "../../utils/constants";
import { setSessionStorage } from "../../utils/utils";
import SearchPropertyHorizontal from "../SearchPropertyHorizontal/SearchPropertyHorizontal";
import "./Audit.scss";

const Audit = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { t } = useTranslation();

  const { loading, list, pagination, auditPagination, tags, openAudits } = useSelector(state => state.audit);
  const { list: markets, loading: loadingMarket } = useSelector(state => state.market);
  const { homePagination, homeTags } = useSelector(state => state.home);

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isFromHome, setIsFromHome] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);

  const { propertyTag } = usePropertyFilterTags({ tags, tagType: TAG.audits });
  const { openAuditFilterTag } = useOpenAuditFilterTags({ tags: openAudits, tagType: TAG.openAudits });

  const { propertyPagination } = usePropertyPagination({ pagination, paginationType: PAGINATOIN.audits });
  const { openAuditPagination } = useAuditPagination({
    pagination: auditPagination,
    paginationType: PAGINATOIN.openAuditPagination,
  });

  const homePaginationResponse = usePropertyPagination({ pagination: homePagination, paginationType: PAGINATOIN.home });

  useEffect(() => {
    if (!isEmpty(HOME_PROPERTIES_TYPE[type])) {
      setIsFromHome(true);
      let _newPropertyTags = [];
      const homePaginationValues = homePaginationResponse.propertyPagination;
      if (!isEmpty(homePaginationValues) && !isEmpty(homePaginationValues?.filterCriteria)) {
        if (isEmpty(homePaginationValues?.filterCriteria?.market)) {
          let availableMarkets = [];
          if (
            !isEmpty(homePaginationValues?.filterCriteria?.division) ||
            !isEmpty(homePaginationValues?.filterCriteria?.region)
          ) {
            if (!isEmpty(homePaginationValues?.filterCriteria?.region)) {
              availableMarkets = [
                ...new Set(
                  markets.filter(market =>
                    (homePaginationValues?.filterCriteria?.region).includes(market.region?.code),
                  ),
                ),
              ];
            } else {
              availableMarkets = [
                ...new Set(
                  markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
                ),
              ];
            }
            availableMarkets = [...new Set(availableMarkets.map(element => element.id))];
            _newPropertyTags = [...availableMarkets];
          } else {
            _newPropertyTags = [];
          }
        } else {
          _newPropertyTags = [...(homePaginationValues?.filterCriteria?.market || [])];
        }
        (async () => {
          await dispatch(openAuditsTagSuccess(_newPropertyTags));
        })();
      }
    }
  }, []);

  useEffect(() => {
    // Add tags from filtered views to home.
    if (!isEmpty(HOME_PROPERTIES_TYPE[type])) {
      const homePaginationValues = homePaginationResponse.propertyPagination;
      const oldFilterCriteria = homePaginationValues?.filterCriteria;
      let oldMarkets = [],
        oldSubMarkets = [];
      // If market and subMarket are not available, use division and region to filter markets.
      if (
        isEmpty(homePaginationValues?.filterCriteria?.market) &&
        isEmpty(homePaginationValues?.filterCriteria?.subMarket)
      ) {
        // If division and region are also not available, no markets and submarket are selected
        if (
          !isEmpty(homePaginationValues?.filterCriteria?.division) ||
          !isEmpty(homePaginationValues?.filterCriteria?.region)
        ) {
          // If region is available, filter markets by region, else use division.
          if (!isEmpty(homePaginationValues?.filterCriteria?.region)) {
            oldMarkets = [
              ...new Set(
                markets.filter(market => (homePaginationValues?.filterCriteria?.region).includes(market.region?.code)),
              ),
            ];
          } else {
            oldMarkets = [
              ...new Set(
                markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
              ),
            ];
          }
          oldMarkets = [...new Set(oldMarkets.map(element => element.id))];
        } else {
          oldMarkets = oldFilterCriteria?.market || [];
          oldSubMarkets = oldFilterCriteria?.subMarket || [];
        }
      } else {
        oldMarkets = oldFilterCriteria?.market || [];
        oldSubMarkets = oldFilterCriteria?.subMarket || [];
      }
      const newMarkets = openAuditPagination?.filterCriteria?.market || [];
      const filterCriteria = {
        ...oldFilterCriteria,
        market: newMarkets,
      };
      const newHomeTags = [
        ...(oldFilterCriteria?.division || []),
        ...(oldFilterCriteria?.region || []),
        ...newMarkets,
        ...(oldFilterCriteria?.subMarket || []),
      ];
      setSessionStorage(PAGINATOIN.home, { filterCriteria });
      setSessionStorage(TAG.home, newHomeTags);
      dispatch(homePaginationSuccess({ filterCriteria }));
      dispatch(homeTagsSuccess(newHomeTags));
    }
  }, [openAudits]);

  const getAuditIssuePercentage = record =>
    record.auditIssuesCompleted === 0 && record.auditIssues === 0
      ? 100
      : (record.auditIssuesCompleted / record.auditIssues) * 100;

  const columns = [
    {
      title: t("AuditTable.PropertyCode"),
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 150,
      render: (text, record) => {
        const link =
          record.propertyType === PROPERTY_TYPE_KEYS.land ? ROUTES.VIEW_LAND.path : ROUTES.VIEW_BUILDING.path;
        return (
          <>
            <Link to={`${link}/${record?.id}#auditIssues`}>{text}</Link>
          </>
        );
      },
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "id" && sortInfo?.order,
    },
    {
      title: t("AuditTable.Name"),
      dataIndex: "marketingPropertyName",
      key: "marketingPropertyName",
      width: 150,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketingPropertyName" && sortInfo?.order,
    },
    {
      title: t("AuditTable.StreetAddress"),
      dataIndex: "streetAddress1",
      key: "streetAddress1",
      width: 150,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "streetAddress1" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Market"),
      dataIndex: "market",
      key: "market",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "market" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          <Tag className="ellipsis" color="geekblue">
            {text}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: t("AuditTable.AuditIssuesCompletionPercentage"),
      dataIndex: "auditIssuesCompleted",
      key: "auditIssuesCompleted",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "auditIssuesCompleted" && sortInfo?.order,
      width: 150,
      render: (text, record) => {
        const _title = (
          <Space direction="vertical" className="audit-issues-tooltip-space">
            <Typography.Text type="success">
              {`${record.auditIssuesCompleted}  ${t("AuditTable.IssuesClosed")}`}
            </Typography.Text>
            <Typography.Text type="danger">
              {`${record.auditIssues - record.auditIssuesCompleted}  ${t("AuditTable.IssuesOpen")}`}
            </Typography.Text>
          </Space>
        );
        return (
          <Tooltip color="white" title={_title}>
            <Progress
              format={percent => `${percent.toFixed(2)}%`}
              percent={getAuditIssuePercentage(record)}
              trailColor={red[7]}
              success={{
                percent: getAuditIssuePercentage(record),
                strokeColor: green[7],
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: t("AuditTable.AuditStatusLastUpdatedOn"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: SORT_DIRECTION.threeDirection,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
    {
      title: t("PropertiesTable.LastPublishDate"),
      dataIndex: "lastPublishDate",
      key: "lastPublishDate",
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "lastPublishDate" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
  ];

  /**
   * To get the list of properties with audits
   */
  const fetchAudits = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = isFromHome ? auditPagination : pagination;
      }
      if (!isEmpty(HOME_PROPERTIES_TYPE[type])) {
        await dispatch(getAudits(_query, true));
      } else {
        setIsFromHome(false);
        await dispatch(getAudits(_query, false));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const auditsPageTitle = !isEmpty(HOME_PROPERTIES_TYPE[type]) ? HOME_PROPERTIES_TITLE[type] : ROUTES.AUDIT.title;

  /**
   * Wrapper method to trigger when updates are made
   * to filter tags
   */
  const onUpdateTags = async _tags => {
    if (isFromHome) {
      setSessionStorage(TAG.openAudits, []);
      setShouldFetchData(true);
      await dispatch(openAuditsTagSuccess(_tags));
    } else {
      setSessionStorage(TAG.audits, []);
      setShouldFetchData(true);
      await dispatch(auditTagsSuccess(_tags));
    }
    setShouldFetchData(false);
  };

  const loadingTable = loading || loadingMarket;

  return (
    <div className="audit">
      <PageTitle title={auditsPageTitle} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <SearchPropertyHorizontal
            defaultSort={DEFAULT_SORTER}
            getList={fetchAudits}
            shouldFetchData={shouldFetchData}
            pagination={isFromHome ? openAuditPagination : propertyPagination}
            tags={isFromHome ? openAuditFilterTag : propertyTag}
            isFromHome={isFromHome}
            searchState={SUGGESTION_SEARCH_STATE.audit}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]} className="row-vertical-padding-8">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <EditableTagGroup tags={isFromHome ? openAuditFilterTag : propertyTag} updateTags={onUpdateTags} />
            </Col>
          </Row>
          <SearchListView
            paginationKey={isFromHome ? PAGINATOIN.openAuditPagination : PAGINATOIN.audits}
            defaultSort={DEFAULT_SORTER}
            preventInitialFetchList
            setFilterInfo={setFilterInfo}
            setSortInfo={setSortInfo}
            dataSource={list}
            columns={columns}
            loading={loadingTable}
            rowKey="id"
            getList={fetchAudits}
            pagination={isFromHome ? auditPagination : pagination}
            scroll={{ x: 850, y: "calc(100vh - 460px)" }}
          />
        </Col>
      </Row>
    </div>
  );
};

Audit.defaultProps = {};

Audit.propTypes = {};

export default Audit;
