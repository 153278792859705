import { Card, Col, Row, Button } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import TransferTable from "../../components/TransferTable/TransferTable";
import { PROPERTY_CHARACTERISTICS, PROPERTY_STATUS } from "../../utils/constants";
import { getFeatureFlags } from "../../utils/utils";
import "./Characteristics.scss";

const PropertyCharacteristics = ({
  data,
  codeDetail,
  selected,
  form,
  notAvailable,
  isTemporary,
  viewOnlyMode,
  updateNotAvailableFields,
}) => {
  const { t } = useTranslation();
  const [isDifferenceModalOpen, setIsDifferenceModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});

  const FEATURE_FLAG = getFeatureFlags();
  const _onCancel = () => setIsDifferenceModalOpen(false);

  const _onSuccess = values => {
    setIsDifferenceModalOpen(false);
    const obj = {};
    if (!_isEmpty(selectedValues)) {
      Object.entries(selectedValues).forEach(([key, value]) => {
        const _value = values[key] || _current[key];
        if (key === "railServed") {
          obj[key] = _value === "Yes";
        } else {
          obj[key] = _value;
          if (notAvailable[key]) {
            updateNotAvailableFields(key);
          }
        }
      });
    }
    Object.entries(values).forEach(([key, value]) => {
      const _value = value;
      if (key === "railServed") {
        obj[key] = _value === "Yes";
      } else {
        obj[key] = _value;
        if (notAvailable[key]) {
          updateNotAvailableFields(key);
        }
      }
    });
    form.setFieldsValue({
      buildingInfo: {
        ...obj,
      },
    });

    // setSelectedValues({ ...values });
    setSelectedValues({});
  };

  const _source = useMemo(() => {
    const obj = {};
    if (!_isEmpty(codeDetail)) {
      obj.railServed = {
        label: t("BuildingInfo.RailServed"),
        value: codeDetail?.characteristics?.railServed === "1" ? "Yes" : "No",
      };
      obj.truckCourtDepth = {
        label: t("BuildingInfo.TruckCourtDepth"),
        value: codeDetail?.characteristics?.truckCourtDepth,
      };
      if (FEATURE_FLAG.SUBMARKET?.status) {
        obj.subMarketId = {
          label: t("Property.Submarket"),
          value: codeDetail?.subMarket,
        };
      }
      obj.latitude = {
        label: t("Property.Latitude"),
        value: codeDetail?.latitude,
      };
      obj.longitude = {
        label: t("Property.Longitude"),
        value: codeDetail?.longitude,
      };
      obj.marketId = {
        label: t("Property.Market"),
        value: codeDetail?.market?.id,
      };
      obj.totalBuildingSize = {
        label: t("BuildingInfo.TotalBuildingSize"),
        value: codeDetail?.propertyInfo?.totalBuildingSize,
      };
      obj.totalBuildingSizeUOM = {
        label: t("BuildingInfo.BuildingUOM"),
        value: codeDetail?.propertyInfo?.totalBuildingSizeUOM,
      };
      obj.marketingPropertyName = {
        label: t("Property.MarketingPropertyName"),
        value: codeDetail?.propertyInfo?.marketingPropertyName,
      };
    }
    return obj;
  }, [codeDetail]);

  const _current = useMemo(() => {
    const obj = {};
    if (!_isEmpty(selected)) {
      if (FEATURE_FLAG.SUBMARKET?.status) {
        obj.subMarketId = typeof selected?.subMarket === "object" ? selected?.subMarket?.id : selected?.subMarket;
      }
      obj.railServed = selected?.buildingInfo?.railServed ? "Yes" : "No";
      obj.truckCourtDepth =
        selected?.buildingInfo?.truckCourtDepth === "0" || selected?.buildingInfo?.truckCourtDepth === "NaN"
          ? undefined
          : selected?.buildingInfo?.truckCourtDepth;
      obj.latitude = selected?.latitude;
      obj.longitude = selected?.longitude;
      obj.marketId = selected?.market?.id;
      obj.totalBuildingSize = selected?.buildingInfo?.totalBuildingSize;
      obj.totalBuildingSizeUOM = selected?.buildingInfo?.totalBuildingSizeUOM;
      obj.marketingPropertyName = selected?.buildingInfo?.marketingPropertyName;
    }
    return obj;
  }, [selected]);

  const _disabled =
    viewOnlyMode ||
    _isEmpty(codeDetail) ||
    _isEmpty(selected) ||
    (selected?.status === PROPERTY_STATUS.never_published && !selected.isDraft) ||
    isTemporary;

  return (
    <>
      <Card
        title={t("PropertyCharacteristics.Title")}
        className="overflow-auto characteristics"
        extra={
          <Button
            disabled={_disabled}
            size="large"
            shape="circle"
            type="primary"
            icon={<CustomIcon name="SyncOutlined" />}
            onClick={() => setIsDifferenceModalOpen(true)}
          />
        }
      >
        {PROPERTY_CHARACTERISTICS.map(characteristics => (
          <Row key={characteristics.value}>
            <Col md={12}>{characteristics.label}</Col>
            <Col md={12}>{`: ${_get(data, characteristics.value, "-")}`}</Col>
          </Row>
        ))}
      </Card>
      <AppModal
        title={t("PropertyCharacteristics.AppModalTitle")}
        width={900}
        visible={isDifferenceModalOpen}
        onCancel={_onCancel}
        footer={null}
        destroyOnClose
      >
        <TransferTable
          selectedValues={selectedValues}
          source={_source}
          current={_current}
          onSuccess={_onSuccess}
          onCancel={_onCancel}
          notAvailable={notAvailable}
        />
      </AppModal>
    </>
  );
};

PropertyCharacteristics.propTypes = {
  data: PropTypes.object,
  codeDetail: PropTypes.object,
  selected: PropTypes.object,
  form: PropTypes.object,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  isTemporary: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
};

export default PropertyCharacteristics;
