import { ConfigProvider } from "antd";
import en from "antd/lib/locale/en_US";
import es from "antd/lib/locale/es_ES";
import React from "react";
import Routes from "./routes";
import "./styles/App.scss";

const languages = {
  en,
  es,
};

function App() {
  // const [languageCode, setLanguageCode] = useState(languages[getLanguageCode()]);
  // useEffect(() => {
  //   i18n.on("languageChanged", e => {
  //     setLanguageCode(languages[e]);
  //   });
  // }, []);

  return (
    // <ConfigProvider locale={languageCode}>
    <ConfigProvider>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
