import { Button, Col, Form, Input, Row, Skeleton, Checkbox } from "antd";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomSelect from "../../components/CustomSelect";
import { getEmployeeById, getEmployeeByIdSuccess, updateEmployee } from "../../store/actions/employee.actions";
import { getActiveMarkets } from "../../store/actions/market.actions";
import { SEARCH_QUERY, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import { isValidPhoneNumber, populateDisplayName } from "../../utils/utils";
import "./EditEmployee.scss";

const queryParam = { filterCriteria: { isActive: true }, sort: "id,asc" };

const EditEmployee = ({ employeeId, onSuccess, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { value: employee, loading, error } = useSelector(store => store.employee);
  const { activeList: markets, loading: fetchingMarkets } = useSelector(store => store.market);

  const [form] = Form.useForm();

  const [disableUseMobile, setDisableUseMobile] = useState(true);
  const [fetchingEmployee, setFetchingEmployee] = useState(false);
  const [isDPMUser, setIsDPMUser] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);

  const onFormFinish = async fieldValues => {
    const _fieldValues = { ...fieldValues, markets: _map(fieldValues.markets, "value") };
    delete _fieldValues.id;
    await dispatch(updateEmployee(employeeId, _fieldValues));
    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(() => {
    const didMount = async () => {
      fetchMarkets();
      if (employeeId) {
        setFetchingEmployee(true);
        await dispatch(getEmployeeById(employeeId));
        setFetchingEmployee(false);
      }
    };
    didMount();
  }, []);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [],
  );

  useEffect(() => {
    if (employee) {
      const _markets = employee.isDPMUser
        ? _map(employee?.markets, m => ({
          ...m,
          label: m.id,
          value: m.id,
        }))
        : undefined;
      form.setFieldsValue({
        ...employee,
        markets: _markets,
      });
      if (employee.isDPMUser) setIsDPMUser(true);
      if (!_isEmpty(employee.mobilePhone)) setDisableUseMobile(false);
      if (employee.isAuditor) setIsAuditor(true);
    }
  }, [employee]);

  const _onChangeMobilePhone = ({ target: { value } }) => {
    let _disable = false;
    if (_isEmpty(value)) {
      _disable = true;
      form.setFieldsValue({ displayMobile: false });
    }
    setDisableUseMobile(_disable);
  };

  const _onChangeDPMUser = ({ target: { checked } }) => {
    setIsDPMUser(checked);
  };

  const _onChangeIsAuditor = ({ target: { checked } }) => {
    setIsAuditor(checked);
  };

  const fetchMarkets = async query => {
    let _query = query;
    if (!_query) {
      _query = { ...SEARCH_QUERY, ...queryParam };
    }
    dispatch(getActiveMarkets(_query));
  };

  const updateDisplayName = e => {
    populateDisplayName(form);
  };

  return (
    <div className="edit-employee">
      <Skeleton loading={fetchingEmployee} active paragraph={{ rows: 6 }}>
        <Form
          form={form}
          className="edit-employee"
          layout="vertical"
          name="nest-messages"
          onFinish={onFormFinish}
          validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
        >
          <Row className="fields-row" gutter={20} type="flex">
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("EditEmployee.EmployeeId")} name="id">
                <Input placeholder={t("EditEmployee.EmployeeId")} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.DisplayName")} name="displayName">
                <Input placeholder={t("User.DisplayName")} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.FirstName")} name="firstName" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.FirstName")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.LastName")} name="lastName" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.LastName")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={t("EditEmployee.OfficePhone")}
                name="phone"
                validateFirst
                rules={[
                  {
                    whitespace: true,
                  },
                  { validator: isValidPhoneNumber },
                ]}
              >
                <Input placeholder={t("EditEmployee.OfficePhone")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={t("EditEmployee.MobilePhone")}
                name="mobilePhone"
                validateFirst
                rules={[
                  {
                    whitespace: true,
                  },
                  {
                    validator: (rule, value) => (_isEmpty(value) ? Promise.resolve() : isValidPhoneNumber(rule, value)),
                  },
                ]}
              >
                <Input onChange={_onChangeMobilePhone} placeholder={t("EditEmployee.MobilePhone")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.Email")} name="email" rules={[{ required: true, type: "email" }]}>
                <Input placeholder={t("User.Email")} onChange={updateDisplayName} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.JobTitle")} name="jobTitle" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.JobTitle")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.Company")} name="company" rules={[{ required: true, whitespace: true }]}>
                <Input placeholder={t("User.Company")} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label={t("User.LicenseNumber")} name="licenseNumber">
                <Input placeholder={t("User.LicenseNumber")} />
              </Form.Item>
            </Col>
            <Col span={24} className="mg-top-10">
              <Form.Item noStyle label={t("User.IsActive")} name="isActive" valuePropName="checked">
                <Checkbox>{t("User.IsActive")}</Checkbox>
              </Form.Item>

              <Form.Item noStyle label={t("EditEmployee.IsDPMUser")} name="isDPMUser" valuePropName="checked">
                <Checkbox onChange={_onChangeDPMUser}>{t("EditEmployee.IsDPMUser")}</Checkbox>
              </Form.Item>
              <Form.Item noStyle label={t("EditEmployee.UseMobile")} name="displayMobile" valuePropName="checked">
                <Checkbox disabled={disableUseMobile}>{t("EditEmployee.UseMobile")}</Checkbox>
              </Form.Item>
              <Form.Item noStyle label={t("EditEmployee.IsAuditor")} name="isAuditor" valuePropName="checked">
                <Checkbox onChange={_onChangeIsAuditor}>{t("EditEmployee.IsAuditor")}</Checkbox>
              </Form.Item>
            </Col>
            {isDPMUser && (
              <Col span={24} className="mg-top-10">
                <Form.Item label={t("User.MarketLabel")} name="markets" rules={[{ required: true }]}>
                  <CustomSelect
                    isMulti
                    placeholder={t("User.MarketPlaceholder")}
                    loading={fetchingMarkets}
                    fetchOptions={fetchMarkets}
                    optionsList={markets}
                    // renderOptions={value => value.id}
                    queryParam={queryParam}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Row className="fields-row mg-top-20" gutter={20} type="flex" justify="center">
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Button
                    block
                    onClick={() => {
                      onCloseModal(false);
                      dispatch(getEmployeeByIdSuccess({}));
                    }}
                  >
                    {t("User.DiscardChanges")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Button block type="primary" htmlType="submit" loading={loading}>
                    {employeeId ? t("User.SaveChanges") : t("Buttons.Create")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </div>
  );
};

EditEmployee.propTypes = {
  employeeId: PropTypes.string,
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default EditEmployee;
