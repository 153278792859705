import _isEmpty from "lodash/isEmpty";
import { getLanguageCode } from "../../i18n";
import landService from "../../services/land.service";
import propertyService from "../../services/property.service";
import { ERROR_MESSAGE, SEARCH_QUERY, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  LAND_LOADING: "loading",
};

export function landRequest(key = LOADING.LAND_LOADING) {
  return { type: ACTIONS.LAND_REQUEST, key };
}

export function landSuccess(response) {
  return { type: ACTIONS.LAND_SUCCESS, response };
}

export function landCodeSuccess(response) {
  return { type: ACTIONS.LAND_CODE_SUCCESS, response };
}

export function landError(error) {
  return { type: ACTIONS.LAND_ERROR, error };
}

export function updateLand(id, landInfo, successMsg) {
  return async dispatch => {
    const currentLanguage = getLanguageCode();
    dispatch(landRequest(LOADING.LAND_LOADING));
    try {
      const [err, response] = await to(landService.updateLandById(id, landInfo, currentLanguage));
      if (err) throwError(err);

      toastMessage("success", successMsg || SUCCESS_MESSAGE.EDITED);
      dispatch(landSuccess({}));
    } catch (error) {
      dispatch(landError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function addLand(landInfo) {
  return async dispatch => {
    const currentLanguage = getLanguageCode();
    dispatch(landRequest(LOADING.LAND_LOADING));
    try {
      const [err, response] = await to(landService.addLand(landInfo, currentLanguage));
      if (err) throwError(err);

      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(landSuccess({}));
    } catch (error) {
      dispatch(landError(error));
      toastMessage("error", ERROR_MESSAGE.ADDED);
      throwError(error);
    }
  };
}

export function getLandById(id) {
  return async dispatch => {
    const currentLanguage = getLanguageCode();
    dispatch(landRequest(LOADING.LAND_LOADING));
    try {
      let land;
      const [err, pCode] = await to(propertyService.getPropertyCodes({ ...SEARCH_QUERY, id }));

      const [err1, _land] = await to(landService.getLandById(id, currentLanguage));

      if (err || err1) throwError(err || err1);

      if (_isEmpty(pCode) && _isEmpty(_land)) {
        toastMessage("error", "No record found!");
        return dispatch(landSuccess({}));
      }

      // characteristics
      if (!_land) {
        land = {
          status: pCode.status,
          market: { ...pCode?.market },
          subMarket: pCode?.subMarket,
          landInfo: {
            propertyCode: pCode?.id,
            marketingPropertyName: pCode?.propertyInfo?.marketingPropertyName || 0,
            address: {
              streetAddress1: pCode?.address?.streetAddress1,
              streetAddress2: pCode?.address?.streetAddress2,
              postalCode: pCode?.address?.zipCode,
              city: pCode?.address?.city,
            },
          },
          latitude: pCode?.latitude,
          longitude: pCode?.longitude,
        };
      } else {
        land = {
          ..._land,
        };
      }

      dispatch(landCodeSuccess(pCode));

      if (!_isEmpty(land)) {
        dispatch(landSuccess(land));
        return land;
      }
      return {};
    } catch (error) {
      dispatch(landError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      return throwError(error);
    }
  };
}

export function updateLandAuditStatus(id, body) {
  return async dispatch => {
    try {
      const [err, response] = await to(landService.updateAuditStatus(id, body));
      await dispatch(getLandById(id));
      if (err) throwError(err);
    } catch (error) {
      dispatch(landError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

/**
 * To update land record which doesn't have
 * never published status and
 * update audit status of a land
 * which only Admins and Auditors can perform
 */
export function landPublishAndPassAudit(id, landInfo, auditStatus, successMsg) {
  const { propertyCode } = landInfo.landInfo;
  return async dispatch => {
    const currentLanguage = getLanguageCode();
    dispatch(landRequest(LOADING.LAND_LOADING));
    try {
      const [err, response] = await to(landService.updateLandById(id, landInfo, currentLanguage));
      if (err) throwError(err);

      const [auditErr, auditResponse] = await to(landService.updateAuditStatus(propertyCode, auditStatus));
      if (err) throwError(err);

      toastMessage("success", successMsg || SUCCESS_MESSAGE.EDITED);
      dispatch(landSuccess({}));
    } catch (error) {
      dispatch(landError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
