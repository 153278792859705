import { Col, Form, Row } from "antd";
import _isEmpty from "lodash/isEmpty";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useKeyword } from "../../hooks";
import { getPropertyRollUps, getPropertyRollUpsSuggestion } from "../../store/actions/propertyRollUp.action";
import { DEFAULT_SORTER } from "../../utils/constants";
import { searchQueryGenerator, submitSearchForm } from "../../utils/utils";

const SearchPropertyRollUp = props => {
  const { t } = useTranslation();
  const { pagination, suggestionLoading } = useSelector(state => state.propertyRollUp);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { keyword, setKeyword } = useKeyword({ pagination });

  const onFormFinish = async (value, option) => {
    let sorter = DEFAULT_SORTER;
    if (!_isEmpty(pagination.sort)) {
      const sortArr = pagination.sort.split(",");
      sorter = { field: sortArr[0], order: sortArr[1] };
    }

    const filterCriteria = {};

    if (option?.key) filterCriteria.id = option.key;

    const query = {
      ...pagination,
      currentPage: 1,
      keyword: option?.key ? null : keyword,
      sorter: _isEmpty(keyword) ? sorter : null,
      pageSize: pagination.size,
      filterCriteria,
      labelInValue: !_isEmpty(option) ? option : undefined,
    };

    const _query = searchQueryGenerator(query);
    await dispatch(getPropertyRollUps(_query));
  };

  const getOptions = val => dispatch(getPropertyRollUpsSuggestion(val));

  const _onSubmit = (flag = true, option) => {
    submitSearchForm(flag, option, pagination, form, onFormFinish);
  };

  const _onEnter = () => {
    if (keyword.length) _onSubmit();
  };

  return (
    <div className="property-roll-up-search">
      <Form form={form} onFinish={onFormFinish}>
        <Row gutter={[16, 16]} className="row-vertical-padding-4">
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={4}>
            <SearchInput
              value={keyword}
              setValue={setKeyword}
              onSelectValue={_onSubmit}
              loading={suggestionLoading}
              getOptions={getOptions}
              onEnter={_onEnter}
              placeholder={t("AdminSearch.PropertyRollUpCodePlaceholder")}
              valuePropName="id"
              labelSeparator=" / "
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchPropertyRollUp;
