import { Button, Col, Drawer, Row, Spin, Switch, Typography } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateBuildingAuditStatus } from "../../store/actions/building.actions";
import { updateLandAuditStatus } from "../../store/actions/land.actions";
import {
  removeAuditIssue,
  resolveAllIssues,
  sendEmailNotification,
  updateAuditIssue,
} from "../../store/actions/property.actions";
import { fileUpload, removeAttachment } from "../../store/actions/upload.action";
import {
  AUDIT_STATUS,
  AUDIT_STATUS_UI_MAPPING,
  BUILDING_TABS_LOOKUP as _tabs,
  LAND_TABS_LOOKUP as _landTabs,
  PROPERTY_AUDIT_STATUS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { sortByDate } from "../../utils/utils";
import AppModal from "../AppModal/AppModal";
import AuditEditor from "../AuditEditor/AuditEditor";
import AuditIssueItem from "../AuditIssueItem/AuditIssueItem";
import CustomIcon from "../CustomIcon/CustomIcon";
import PopUp from "../PopUp/PopUp";
import "./AuditDrawer.scss";

const { Text } = Typography;

const AuditDrawer = ({
  viewOnlyMode,
  propertyCode,
  auditList,
  auditPanelDrawerVisible,
  setAuditPanelDrawerVisible,
  navigateToIssueTab,
  propertyType,
  openModalByAuditPass,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { auditLoading } = useSelector(state => ({
    ...state.property,
  }));

  const { selected: building } = useSelector(state => ({
    ...state.building,
  }));

  const { selected: land } = useSelector(state => ({
    ...state.land,
  }));

  const selectedProperty = propertyType === PROPERTY_TYPE_KEYS.land ? land : building;

  const [hideAuditPassed, setHideAuditPassed] = useState(false);
  const [isResolveAllConfirmation, setIsResolveAllConfirmation] = useState(false);
  const [auditPassedConfirmation, setAuditPassedConfirmation] = useState(false);
  const [filteredAuditList, setFilteredAuditList] = useState(auditList);
  const [loading, setLoading] = useState(false);
  const [selectedAuditStatus, setSelectedAuditStatus] = useState();
  const [selectedAuditId, setSelectedAuditId] = useState();
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] = useState(false);
  const [auditToEdit, setAuditToEdit] = useState({});
  const [auditToDelete, setAuditToDelete] = useState("");
  const [isMinimize, setIsMinimize] = useState(false);
  const [isMaximize, setIsMaximize] = useState(false);

  const canAuditProperty = permissionsUtil.checkAuth("audit_add");

  useEffect(() => {
    const _filteredAuditList = hideAuditPassed
      ? auditList.filter(({ status }) => status !== AUDIT_STATUS.audit_passed)
      : auditList;
    setFilteredAuditList(sortByDate(_filteredAuditList, "lastModifiedDate"));
  }, [hideAuditPassed, auditList]);

  const disableResolveAll = _isEmpty(filteredAuditList.find(audit => audit.status === AUDIT_STATUS.ready_for_review));
  const isAuditPassed =
    (_isEmpty(filteredAuditList) || filteredAuditList.every(audit => audit.status === AUDIT_STATUS.audit_passed)) &&
    selectedProperty.auditStatus !== PROPERTY_AUDIT_STATUS.passed &&
    selectedProperty.status === PROPERTY_STATUS.published &&
    !selectedProperty.isDraft;

  const disableSendNotification =
    (canAuditProperty && !filteredAuditList.some(audit => !audit.emailSentToEditor)) ||
    (!canAuditProperty && !filteredAuditList.some(audit => !audit.emailSentToAuditor));

  const disableButton = _isEmpty(auditList);
  const deleteModalVisibility = !_isEmpty(auditToDelete);
  const editModalVisibility = !_isEmpty(auditToEdit);

  const onClose = () => {
    setAuditPanelDrawerVisible(false);
  };

  const resolveAllConfirmationModal = () => {
    setIsResolveAllConfirmation(!isResolveAllConfirmation);
  };

  const auditPassedConfirmationPopup = () => {
    setAuditPassedConfirmation(!auditPassedConfirmation);
  };

  const onClickHideAuditPassed = checked => {
    setHideAuditPassed(checked);
  };

  /**
   * Function for invoking the API for resolve single audit issue
   */
  const resolveAuditIssue = async (id, marketingPropertyName) => {
    const payload = {
      status: AUDIT_STATUS.audit_passed,
      marketingPropertyName,
    };
    try {
      setLoading(true);
      await dispatch(updateAuditIssue(id, payload));
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function for invoking the API for resolve all audit issue
   */
  const resolveAllAuditIssues = async () => {
    try {
      setLoading(true);
      await dispatch(resolveAllIssues(propertyCode));
      resolveAllConfirmationModal();
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function for invoking the API for send notification
   * and notify editor and auditor
   */
  const sendNotification = async () => {
    try {
      setLoading(true);
      await dispatch(sendEmailNotification({ propertyCode }));
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function for invoking the API for audit passed
   */
  const auditPassed = async () => {
    try {
      const payload = {
        auditStatus: PROPERTY_AUDIT_STATUS.passed,
      };
      setLoading(true);
      const updatePropertyAuditStatus =
        propertyType === PROPERTY_TYPE_KEYS.land ? updateLandAuditStatus : updateBuildingAuditStatus;
      await dispatch(updatePropertyAuditStatus(propertyCode, payload));
      setAuditPanelDrawerVisible(false);
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function for invoking the API for update audit status
   */
  const updateAuditStatus = async () => {
    const audit = {
      status: selectedAuditStatus,
    };
    await dispatch(updateAuditIssue(selectedAuditId, audit));
    setShowStatusChangeConfirmation(false);
  };

  /**
   * Function for open publish property modal
   * and invoking the API for Audit pass
   */
  const onConfrimAuditPassAndPublish = () => {
    if (!_isEmpty(auditList)) {
      openModalByAuditPass();
    } else {
      auditPassed();
    }
  };

  const popupTitle = !_isEmpty(auditList)
    ? t("AddEditProperty.Audit.AuditPassedConfirmationAndPulishText")
    : t("AddEditProperty.Audit.AuditPassedConfirmationText");

  const auditStatusChanged = (_status, _id) => {
    setSelectedAuditStatus(_status);
    setSelectedAuditId(_id);
    setShowStatusChangeConfirmation(true);
  };

  const closeStatusConfirmationModel = () => {
    setShowStatusChangeConfirmation(false);
  };

  /**
   * Function for invoking the API for delete audit status
   */
  const deleteAuditIssue = async () => {
    try {
      setLoading(true);
      await dispatch(removeAuditIssue(propertyCode, auditToDelete));
      resetAuditToDelete();
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function for invoking the API for update audit issue
   */
  const onAuditIssueUpdate = async (attachments = [], deletedAttachments = []) => {
    try {
      const newFiles = attachments.filter(attachment => !attachment.url);
      let attachmentsToUpload = attachments.filter(attachment => attachment.url);

      if (newFiles.length) {
        const attachmentResponse = await dispatch(fileUpload(newFiles));
        attachmentsToUpload = [...attachmentsToUpload, ...attachmentResponse];
      }

      if (deletedAttachments.length) {
        await dispatch(removeAttachment(deletedAttachments));
      }

      const payload = {
        issue: auditToEdit.issue,
        attachments: attachmentsToUpload,
      };

      await dispatch(updateAuditIssue(auditToEdit.id, payload));
      resetAuditToEdit();
    } catch (ex) {
      console.log("ex: ", ex);
    }
  };

  const resetAuditToEdit = () => {
    setAuditToEdit({});
  };

  const resetAuditToDelete = () => {
    setAuditToDelete("");
  };

  const handleIssueChange = e => {
    setAuditToEdit({
      ...auditToEdit,
      issue: e.target.value,
    });
  };

  const onMinimizeDrawer = () => {
    setIsMinimize(true);
    setIsMaximize(false);
  };

  const onMaximizeDrawer = () => {
    setIsMaximize(true);
    setIsMinimize(false);
  };

  const addAttachmentFileName =
    auditToEdit?.attachments &&
    auditToEdit?.attachments.map(attachment => ({ ...attachment, name: attachment.originalFileName }));

  return (
    <>
      {loading ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <Drawer
        className={`app-drawer ${isMinimize && "minimize"} ${isMaximize && "maximize"}`}
        title={
          <>
            {t("AddEditProperty.Audit.Title")}
            {isMinimize ? (
              <CustomIcon name="BorderOutlined" className="min-max-btn" onClick={onMaximizeDrawer} />
            ) : (
              <CustomIcon name="MinusOutlined" className="min-max-btn" onClick={onMinimizeDrawer} />
            )}
          </>
        }
        mask={false}
        placement="right"
        onClose={onClose}
        visible={auditPanelDrawerVisible}
        width="390px"
      >
        <Text type="success">{t("AddEditProperty.Audit.AddAuditIssue")}</Text>
        <p>{t("AddEditProperty.Audit.AddAuditDescription")}</p>
        <div className={`wrapper ${canAuditProperty && !viewOnlyMode ? "" : "editor-wrapper"}`}>
          <Text type="success">
            {t("AddEditProperty.Audit.AuditIssues")}
            :&nbsp;
            {auditList.length}
          </Text>
          <div className="panel-body">
            {_isEmpty(auditList) ? (
              <Row justify="center" align="middle" style={{ height: "inherit" }}>
                <Col span={20} className="text-center">
                  <CustomIcon name="noAuditIssuePosted" />
                  <h2>{t("AddEditProperty.Audit.NoIssuePosted")}</h2>
                  <p>{t("AddEditProperty.Audit.NoIssueDescription")}</p>
                </Col>
              </Row>
            ) : (
              <Row>
                {filteredAuditList.map(item => {
                  const _propertyType = item.propertyType === PROPERTY_TYPE_KEYS.land ? _landTabs : _tabs;
                  const _onClickIssueLink = () =>
                    navigateToIssueTab(_propertyType[item.field.tab].key, item.language, item.unitCode);
                  const _onClickResolveLink = () => resolveAuditIssue(item.id, item.marketingPropertyName);
                  return (
                    <AuditIssueItem
                      viewOnlyMode={viewOnlyMode}
                      item={item}
                      resolveAuditIssue={_onClickResolveLink}
                      navigateToIssueTab={_onClickIssueLink}
                      setAuditToDelete={setAuditToDelete}
                      setAuditToEdit={setAuditToEdit}
                      auditStatusChanged={auditStatusChanged}
                      key={item.id}
                    />
                  );
                })}
              </Row>
            )}
          </div>
          <div className="panel-buttons">
            {canAuditProperty && !viewOnlyMode && (
              <Row className="row-vertical-padding-4" gutter={[12, 0]}>
                {!isAuditPassed && (
                  <Col span={24}>
                    <PopUp
                      title={t("AddEditProperty.Audit.ResolveIssueConfirmationText")}
                      placement="topRight"
                      onConfirm={resolveAllAuditIssues}
                      okText={t("Buttons.Yes")}
                      cancelText={t("Buttons.No")}
                      popupVisible={isResolveAllConfirmation}
                      onVisibleChange={resolveAllConfirmationModal}
                    >
                      <Button type="primary" onClick={resolveAllConfirmationModal} disabled={disableResolveAll} block>
                        {t("Buttons.ResolveAllIssues")}
                      </Button>
                    </PopUp>
                  </Col>
                )}
                {isAuditPassed && (
                  <Col span={24}>
                    <PopUp
                      title={popupTitle}
                      placement="topRight"
                      onConfirm={onConfrimAuditPassAndPublish}
                      okText={t("Buttons.Yes")}
                      cancelText={t("Buttons.No")}
                      popupVisible={auditPassedConfirmation}
                      onVisibleChange={auditPassedConfirmationPopup}
                    >
                      <Button type="primary" onClick={auditPassedConfirmationPopup} block>
                        {t("Buttons.AuditPass")}
                      </Button>
                    </PopUp>
                  </Col>
                )}
              </Row>
            )}
            <Row gutter={[12, 0]} align="middle">
              <Col span={8}>
                <p>{t("AddEditProperty.Audit.HideAuditPassed")}</p>
              </Col>
              <Col span={4}>
                <Switch checked={hideAuditPassed} onChange={onClickHideAuditPassed} disabled={disableButton} />
              </Col>
              <Col span={12}>
                {!viewOnlyMode && (
                  <Button type="primary" disabled={disableSendNotification} onClick={sendNotification} block>
                    {t("Buttons.SendNotification")}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>

      <AppModal
        width={600}
        minHeight="10vh"
        maxHeight="20vh"
        title={t("Constants.ConfirmMessages.Warning")}
        visible={deleteModalVisibility}
        onCancel={resetAuditToDelete}
        overFlowY="auto"
        destroyOnClose
        centered
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={resetAuditToDelete}>
                {t("Buttons.No")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={deleteAuditIssue} loading={loading}>
                {t("Buttons.Yes")}
              </Button>
            </Col>
          </Row>
        }
      >
        <p>{t("Constants.ConfirmMessages.DeleteAudit", { issueId: auditToDelete })}</p>
      </AppModal>

      <AppModal
        title={t("AddEditProperty.Audit.EditAuditIssue")}
        width={700}
        minHeight="15vh"
        visible={editModalVisibility}
        onCancel={resetAuditToEdit}
        overFlowY="auto"
        destroyOnClose
        centered
        footer={false}
      >
        <AuditEditor
          onCancel={resetAuditToEdit}
          onIssueChange={handleIssueChange}
          buttonTitle={t("Buttons.Update")}
          onSubmit={onAuditIssueUpdate}
          auditLoading={auditLoading}
          issue={auditToEdit?.issue}
          attachments={addAttachmentFileName}
        />
      </AppModal>
      <AppModal
        title={t("AddEditProperty.Audit.UpdateStatusConfirmation")}
        width={700}
        minHeight="15vh"
        visible={showStatusChangeConfirmation}
        onCancel={closeStatusConfirmationModel}
        overFlowY="auto"
        destroyOnClose
        centered
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={closeStatusConfirmationModel}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={updateAuditStatus} loading={auditLoading}>
                {t("Buttons.Update")}
              </Button>
            </Col>
          </Row>
        }
      >
        <p>
          {t("AddEditProperty.Audit.UpdateStatusConfirmationText", {
            status: AUDIT_STATUS_UI_MAPPING[selectedAuditStatus] || "",
          })}
        </p>
      </AppModal>
    </>
  );
};

AuditDrawer.propTypes = {
  propertyCode: PropTypes.string,
  auditList: PropTypes.array,
  auditPanelDrawerVisible: PropTypes.bool,
  setAuditPanelDrawerVisible: PropTypes.func,
  navigateToIssueTab: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  propertyType: PropTypes.string,
  openModalByAuditPass: PropTypes.func,
};

export default AuditDrawer;
