/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import "./Slider.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { extractMediaUrl } from "../../utils/property.util";

const Slider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const _next = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const _prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <div className="slider w-container">
      <Carousel
        showArrows={false}
        infiniteLoop
        emulateTouch
        showIndicators={false}
        showThumbs={false}
        centerMode
        centerSlidePercentage={70}
        swipeable
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {images.map(_image => (
          <div key={_image.asset_id}>
            <img src={extractMediaUrl(_image)} alt="" />
          </div>
        ))}
      </Carousel>
      <div className="slider-buttons">
        <div className="slider-button-prev" onClick={_prev} onKeyDown={_prev}></div>
        <div className="slider-button-next" onClick={_next} onKeyDown={_prev}></div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  images: PropTypes.array,
};

export default Slider;
