import { Form, Row, Col, Select } from "antd";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchInput from "../../components/SearchInput/SearchInput";
import { getSubMarkets, getSubMarketSuggestion } from "../../store/actions/subMarket.actions";
import { DEFAULT_SORTER } from "../../utils/constants";
import { searchQueryGenerator } from "../../utils/utils";
import "./SearchSubMarket.scss";

const { Option } = Select;

const SearchSubMarket = props => {
  const { pagination, suggestionLoading } = useSelector(state => state.subMarket);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [keyword, setKeyword] = useState([]);

  const onFormFinish = async (_, option) => {
    let sorter = DEFAULT_SORTER;
    if (!_isEmpty(pagination.sort)) {
      const sortArr = pagination.sort.split(",");
      sorter = { field: sortArr[0], order: sortArr[1] };
    }

    const filterCriteria = {};

    if (option?.key) filterCriteria.id = option.key;

    const query = {
      ...pagination,
      currentPage: 1,
      keyword: option?.key ? null : keyword,
      sorter: _isEmpty(keyword) ? sorter : null,
      pageSize: pagination.size,
      filterCriteria,
    };

    const _query = searchQueryGenerator(query);
    await dispatch(getSubMarkets(_query));
  };

  const getOptions = val => dispatch(getSubMarketSuggestion(val));

  const _onSubmit = (_, option) => {
    if (_isEmpty(option)) {
      form.submit();
    } else {
      onFormFinish(_, option);
    }
  };

  const _onEnter = () => {
    if (keyword.length) _onSubmit();
  };

  return (
    <div className="sub-market-search">
      <Form form={form} onFinish={onFormFinish}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={4}>
            <SearchInput
              value={keyword}
              setValue={setKeyword}
              onSelectValue={_onSubmit}
              loading={suggestionLoading}
              getOptions={getOptions}
              onEnter={_onEnter}
              placeholder={t("SearchSubMarket.SubmarketPlaceholder")}
              renderOptions={value => (
                <Option key={value.id} value={`${value?.id} ${value?.market}`}>
                  {`${value?.id} / ${value?.market}`}
                </Option>
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchSubMarket;
