import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import "../../index.scss";
import { createUnmarketReasons, updateUnmarketReasons } from "../../store/actions/unmarketReasons.action";
import { PROPERTY_TYPE, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";

const UnmarketReasonsEditor = ({ selectedUnmarketReason, onCloseModal, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isDateFieldLabel, setIsDateFieldLabel] = useState(selectedUnmarketReason?.dateFieldRequired);

  const onFormFinish = async fieldValues => {
    if (!fieldValues.dateFieldRequired) {
      fieldValues.dateFieldLabel = undefined;
    }
    if (selectedUnmarketReason?.id) {
      await dispatch(updateUnmarketReasons(selectedUnmarketReason.id, fieldValues));
    } else {
      await dispatch(createUnmarketReasons(fieldValues));
    }
    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(
    () => () => {
      form.resetFields();
    },
    [],
  );

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const resetValue = () => {
    onCloseModal(false);
  };

  const onDateFieldChange = checked => {
    setIsDateFieldLabel(checked);
  };

  return (
    <Form
      className="unmarket-reasons-editor"
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={selectedUnmarketReason}
      validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      onFinishFailed={onFinishFailed}
    >
      <Row className="action-btns" type="flex" justify="space-between">
        <Col xs={24}>
          <Form.Item
            label={t("Table.UnmarketReasons.Reason")}
            name="unmarketingReason"
            rules={[{ required: true, whitespace: true }]}
          >
            <Input placeholder={t("Table.UnmarketReasons.Reason")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={24}>
          <Form.Item label={t("Table.Template.Type")} name="type" rules={[{ required: true }]}>
            <Select
              mode="multiple"
              listHeight={300}
              showArrow
              showSearch={false}
              placeholder={t("PropertiesTable.Type")}
            >
              {_map(PROPERTY_TYPE, propertyType => (
                <Select.Option key={propertyType.value} value={propertyType.value} className="filter-option-item">
                  {propertyType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={14}>
          <Form.Item
            valuePropName="checked"
            label={t("Table.UnmarketReasons.IncludeAdditionalInformation")}
            name="moreInformationRequired"
            className="property-available-switch"
          >
            <Switch disabled={false} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} lg={10}>
          <Form.Item
            valuePropName="checked"
            label={t("Table.UnmarketReasons.DateField")}
            name="dateFieldRequired"
            className="property-available-switch"
          >
            <Switch checked={selectedUnmarketReason?.dateFieldRequired} disabled={false} onChange={onDateFieldChange} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={24}>
          <Form.Item
            label={t("Table.UnmarketReasons.DateFieldName")}
            name="dateFieldLabel"
            rules={[{ required: isDateFieldLabel, whitespace: true }]}
          >
            <Input placeholder={t("Table.UnmarketReasons.DateFieldName")} disabled={!isDateFieldLabel} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="fields-row mg-top-10" gutter={20} type="flex" justify="center">
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block onClick={resetValue} className="cancelButton">
            {t("Buttons.Cancel")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block type="primary" htmlType="submit" className="saveButton" loading={loading} disabled={error}>
            {t("Buttons.Save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
UnmarketReasonsEditor.propTypes = {
  selectedUnmarketReason: PropTypes.object,
  onCloseModal: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default UnmarketReasonsEditor;
