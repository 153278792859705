import { Button, Col, Input, Row } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppModal from "../AppModal/AppModal";
import CustomFormItem from "../CustomFormItem/CustomFormItem";
import LabelWithDropDown from "../LabelWithDropDown/LabelWithDropDown";
import { useLanguage } from "../../hooks";
import { LANGUAGE_CODES } from "../../utils/constants";

const KeyFeature = ({
  viewOnlyMode,
  tooltip,
  label,
  templateName,
  form,
  templateList,
  parentId,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  ...props
}) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState(null);
  const [modalVisible, setModalVisibility] = useState(false);
  const { language, languageStatus } = useLanguage();

  const _onCancel = () => {
    setModalVisibility(false);
    form.setFieldsValue({ keyFeatures: { [templateName[1]]: templateId } });
  };

  const _onConfirm = () => {
    populateDescription(form.getFieldValue(["keyFeatures", templateName[1]]));
    setModalVisibility(false);
  };

  const setTemplateDescription = data => {
    if (!_isEmpty(data)) {
      if (_isEmpty(form.getFieldValue("keyFeatures")?.[props.name[1]])) {
        populateDescription(data);
      } else {
        setModalVisibility(true);
      }
    }
  };

  const populateDescription = data => {
    if (data) {
      const country = form.getFieldValue([parentId, "address", "country"]);
      const selectedTemplate = templateList.find(item => item.id === data);
      const templateData = _get(
        selectedTemplate,
        `translations.${
          country === "Mexico" && language.current !== LANGUAGE_CODES.English.value
            ? LANGUAGE_CODES.Mexico.value
            : language.current
        }.template`,
        selectedTemplate?.template,
      );
      const obj = {};
      if (languageStatus !== "primary") {
        obj.translations = {
          [language.current]: { keyFeatures: { [props.name[1]]: templateData } },
        };
      } else {
        obj.keyFeatures = { [props.name[1]]: templateData };
      }
      form.setFieldsValue({ ...obj });
      setTemplateId(data);
    }
  };

  return (
    <>
      <Col span={8} xs={24} sm={8} lg={8}>
        <CustomFormItem
          {...props}
          className="form-item-label-with-drop-down"
          label={
            <LabelWithDropDown
              label={t(label)}
              tooltip={tooltip}
              templateId={templateId}
              changeHandler={setTemplateDescription}
              options={templateList}
              viewOnlyMode={viewOnlyMode}
              name={templateName}
              form={form}
              tab={tab}
              fieldName={fieldName}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
        >
          <Input.TextArea placeholder={t(label)} disabled={viewOnlyMode} rows={2} />
        </CustomFormItem>
      </Col>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title={t("Template.Confirmation")}
        width={600}
        minHeight="10vh"
        maxHeight="60vh"
        overFlowY="auto"
        visible={modalVisible}
        onCancel={_onCancel}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCancel}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={_onConfirm}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <p>{t("Template.ConfirmationMessageKeyFeatures")}</p>
      </AppModal>
    </>
  );
};
KeyFeature.propTypes = {
  viewOnlyMode: PropTypes.bool,
  tooltip: PropTypes.object,
  label: PropTypes.string,
  parentId: PropTypes.string,
  templateName: PropTypes.array,
  name: PropTypes.array,
  templateList: PropTypes.array,
  form: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
};
export default KeyFeature;
