import { get } from "./http.service";

const homeService = {
  unitsToReview: () => get(`dashboard/unitsToReview`),
  unitsToReviewWithFilters: filterCriteria =>
    get(`dashboard/unitsToReview?filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`),
  activeAudits: () => get(`dashboard/activeAudits`),
  activeAuditsWithFilters: filterCriteria =>
    get(`dashboard/activeAudits?filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`),
  marketableUnits: () => get(`dashboard/marketableUnits`),
  marketableUnitsWithFilters: filterCriteria =>
    get(`dashboard/marketableUnits?filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`),
};
export default homeService;
