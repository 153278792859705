import React from "react";
import AddEditBuilding from "../containers/AddEditBuilding/AddEditBuilding";
import AddEditLand from "../containers/AddEditLand/AddEditLand";
import Properties from "../containers/Properties/Properties";
import Home from "../containers/Home/Home";
import DashboardProvider from "../providers/dashboard.provider";
import { ROUTES } from "../utils/constants";
import UnitVacancy from "../containers/UnitVacancy/UnitVacancy";
import AddEditUnitVacancy from "../containers/AddEditUnitVacancy/AddEditUnitVacancy";
import LandVacancy from "../containers/LandVacancy/LandVacancy";
import Audit from "../containers/Audit/Audit";
import { getFeatureFlags } from "../utils/utils";
import DowntimeReport from "../containers/DowntimeReport/DowntimeReport";

const FEATURE_FLAG = getFeatureFlags();
const { LANDMARKET } = FEATURE_FLAG;

const routes = [
  {
    path: ROUTES.ADMIN.path,
    exact: true,
    name: ROUTES.ADMIN.name,
    displayName: ROUTES.ADMIN.title,
    component: DashboardProvider,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
    // permissionStr: ["user_view", "market_view"]
  },
  {
    path: ROUTES.HOME.path,
    exact: true,
    name: ROUTES.HOME.name,
    displayName: ROUTES.HOME.title,
    component: Home,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "home",
    action: "view",
  },
  {
    path: ROUTES.PROPERTIES.path,
    exact: true,
    name: ROUTES.PROPERTIES.name,
    displayName: ROUTES.PROPERTIES.title,
    component: Properties,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "view",
  },
  {
    path: `${ROUTES.PROPERTIES_TO_REVIEW.path}/:type`,
    exact: true,
    name: ROUTES.PROPERTIES_TO_REVIEW.name,
    displayName: ROUTES.PROPERTIES_TO_REVIEW.title,
    component: Properties,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "view",
  },
  {
    path: ROUTES.DOWNTIME_REPORT.path,
    exact: true,
    name: ROUTES.DOWNTIME_REPORT.name,
    displayName: ROUTES.DOWNTIME_REPORT.title,
    component: DowntimeReport,
    isPrivate: true,
    category: "downtime_report",
    action: "view",
  },
  {
    path: ROUTES.ADD_BUILDING.path,
    exact: true,
    name: ROUTES.ADD_BUILDING.name,
    displayName: ROUTES.ADD_BUILDING.title,
    component: AddEditBuilding,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "add",
  },
  {
    path: `${ROUTES.VIEW_BUILDING.path}/:id`,
    exact: true,
    name: ROUTES.VIEW_BUILDING.name,
    displayName: ROUTES.VIEW_BUILDING.title,
    component: AddEditBuilding,
    isPrivate: true,
    componentProps: { viewOnlyMode: true },
    icon: "ProfileOutlined",
    category: "property",
    action: "view",
  },
  {
    path: `${ROUTES.EDIT_BUILDING.path}/:id`,
    exact: true,
    name: ROUTES.EDIT_BUILDING.name,
    displayName: ROUTES.EDIT_BUILDING.title,
    component: AddEditBuilding,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "edit",
  },
  {
    path: ROUTES.ADD_LAND.path,
    exact: true,
    name: ROUTES.ADD_LAND.name,
    displayName: ROUTES.ADD_LAND.title,
    component: AddEditLand,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "add",
  },
  {
    path: `${ROUTES.VIEW_LAND.path}/:id`,
    exact: true,
    name: ROUTES.VIEW_LAND.name,
    displayName: ROUTES.VIEW_LAND.title,
    component: AddEditLand,
    isPrivate: true,
    componentProps: { viewOnlyMode: true },
    icon: "ProfileOutlined",
    category: "property",
    action: "view",
  },
  {
    path: `${ROUTES.EDIT_LAND.path}/:id`,
    exact: true,
    name: ROUTES.EDIT_LAND.name,
    displayName: ROUTES.EDIT_LAND.title,
    component: AddEditLand,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "property",
    action: "edit",
  },
  {
    path: ROUTES.UNIT_VACANCY.path,
    exact: true,
    name: ROUTES.UNIT_VACANCY.name,
    displayName: ROUTES.UNIT_VACANCY.title,
    component: UnitVacancy,
    isPrivate: true,
    category: "unit_vacancy",
    action: "view",
  },
  {
    path: `${ROUTES.UNITS_TO_REVIEW.path}/:type`,
    exact: true,
    name: ROUTES.UNITS_TO_REVIEW.name,
    displayName: ROUTES.UNITS_TO_REVIEW.title,
    component: UnitVacancy,
    isPrivate: true,
    category: "unit_vacancy",
    action: "view",
  },
  {
    path: `${ROUTES.UNIT_VACANCY.path}/:id`,
    exact: true,
    name: ROUTES.UNIT_VACANCY.name,
    displayName: ROUTES.UNIT_VACANCY.title,
    component: AddEditUnitVacancy,
    isPrivate: true,
    category: "unit_vacancy",
    action: "view",
  },
  {
    path: ROUTES.AUDIT.path,
    exact: true,
    name: ROUTES.AUDIT.name,
    displayName: ROUTES.AUDIT.title,
    component: Audit,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "audit",
    action: "view",
  },
  {
    path: `${ROUTES.OPEN_AUDIT_ISSUES.path}/:type`,
    exact: true,
    name: ROUTES.OPEN_AUDIT_ISSUES.name,
    displayName: ROUTES.OPEN_AUDIT_ISSUES.title,
    component: Audit,
    isPrivate: true,
    icon: "ProfileOutlined",
    category: "audit",
    action: "view",
  },
];

if (LANDMARKET?.status) {
  routes.push({
    path: ROUTES.LAND_VACANCY.path,
    exact: true,
    name: ROUTES.LAND_VACANCY.name,
    displayName: ROUTES.LAND_VACANCY.title,
    component: LandVacancy,
    isPrivate: true,
    category: "land_vacancy",
    action: "view",
  });
}

export default routes;
