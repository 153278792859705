import React from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { dateLanguageLookup } from "../../utils/constants";
import { isAsianCountry } from "../../utils/property.util";
import { useLanguage } from "../../hooks";
import { getLocalTranslatedValue } from "../../i18n";

const BuildingInfo = ({ values }) => {
  const { language } = useLanguage();
  const translatedValues = values.translations[language.current];

  const { availableDate } = values.buildingInfo;
  const date = new Date(availableDate);

  const currentPlusThreeMonths = new Date();
  currentPlusThreeMonths.setMonth(currentPlusThreeMonths.getMonth() + 3);
  const options = { year: "numeric", month: "long" };
  const availableDateFormatted = date.toLocaleDateString(dateLanguageLookup[language.current], options);

  const renderAvailability = () => (
    <div className="available-date">
      {availableDate &&
        (date > currentPlusThreeMonths
          ? `${getLocalTranslatedValue(language.current, "Available")} ${availableDateFormatted}`
          : getLocalTranslatedValue(language.current, "AvailableNow"))}
    </div>
  );

  const onContactScroll = () => {
    document.getElementById("contact").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="property-info w-container">
      {!isAsianCountry(null, "buildingInfo", values) && renderAvailability()}
      <Row justify="space-between row-vertical-padding-16">
        <Col className="property-info-heading">
          {language.current === "en"
            ? _get(values, "buildingInfo.marketingPropertyName", "")
            : _get(translatedValues, "buildingInfo.marketingPropertyName", "")}
        </Col>
        <Col className="btn-wrapper">
          <Button className="contact-btn" type="primary" size="large" onClick={onContactScroll}>
            {getLocalTranslatedValue(language.current, "ContactUs")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

BuildingInfo.propTypes = {
  values: PropTypes.object,
};

export default BuildingInfo;
