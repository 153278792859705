import { Tag, Tooltip, Row, Col, Button } from "antd";
import _map from "lodash/map";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import SearchListView from "../../components/SearchListView/SearchListView";
import { exportEmployees, getEmployeeByIdSuccess, getEmployeeContacts } from "../../store/actions/employee.actions";
import { PAGINATOIN, SORT_DIRECTION } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { replaceIfNull } from "../../utils/utils";
import EditEmployee from "../EditEmployee/EditEmployee";
import "./EmployeeContacts.scss";

const EmployeeContacts = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canEditUser = permissionsUtil.checkAuth("user_edit");
  const canViewUser = permissionsUtil.checkAuth("user_view");

  const { list, loading, pagination } = useSelector(store => store.employee);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [exportingEmployees, setExportingEmployees] = useState(false);

  const fetchEmployees = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getEmployeeContacts(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const toggleModal = async (modalState, id) => {
    setEmployeeId(id);
    setIsModalOpen(modalState);
  };

  const exportAction = async () => {
    try {
      setExportingEmployees(true);
      await dispatch(exportEmployees());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingEmployees(false);
    }
  };

  const renderMarketTags = markets => {
    if (markets && markets.length) {
      return _map(markets, market =>
        market ? (
          <Tag key={market} className="text-capitalize" color="processing">
            {market}
          </Tag>
        ) : null,
      );
    }
    return "N/A";
  };

  const columns = [
    {
      title: t("Table.DisplayName"),
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "displayName" && sortInfo?.order,
      fixed: "left",
      width: 300,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.DPMUser"),
      dataIndex: "isDPMUser",
      key: "isDPMUser",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "isDPMUser" && sortInfo?.order,
      render: text => (text ? "True" : "False"),
      width: 100,
    },
    {
      title: t("Table.MarketCodes"),
      dataIndex: "markets",
      key: "markets",
      render: (markets, record) => (record?.isDPMUser ? renderMarketTags(markets) : "N/A"),
      width: 300,
    },
    {
      title: t("Table.Company"),
      dataIndex: "company",
      key: "company",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "company" && sortInfo?.order,
      width: 100,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.License"),
      dataIndex: "licenseNumber",
      key: "licenseNumber",
      width: 100,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.Active"),
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "isActive" && sortInfo?.order,
      // filters: ACTIVE_STATUS,
      // onFilter: (value, record) => record.isActive === value,
      // filteredValue: filterInfo?.isActive || null,
      render: text => (text ? "True" : "False"),
      width: 75,
    },
    {
      title: t("Table.Auditor"),
      dataIndex: "isAuditor",
      key: "isAuditor",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "isAuditor" && sortInfo?.order,
      render: text => (text ? "True" : "False"),
      width: 75,
    },
  ];

  if (canEditUser) {
    columns.push({
      title: t("Table.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 75,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Table.Edit")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => {
                if (ev.keyCode === 13) toggleModal(true, record.id);
              }}
              onClick={() => {
                toggleModal(true, record.id);
              }}
            >
              <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
              <p style={{ color: "#5C5E5D" }}>{t("Table.Edit")}</p>
            </div>
          </Tooltip>
        </div>
      ),
    });
  }

  return (
    <div className="employee-contacts">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canViewUser && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block loading={exportingEmployees} onClick={exportAction}>
              Export
            </Button>
          </Col>
        )}
      </Row>
      <SearchListView
        paginationKey={PAGINATOIN.employees}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        loading={loading}
        rowKey="id"
        dataSource={list}
        columns={columns}
        getList={fetchEmployees}
        pagination={pagination}
        scroll={{ x: 1150, y: "calc(100vh - 560px)" }}
        filterCriteria={{ type: "employee" }}
      />
      <AppModal
        title={`${employeeId ? "Edit" : "Create"} Employee Contact`}
        width={800}
        visible={isModalOpen}
        onCancel={() => {
          toggleModal(false);
          dispatch(getEmployeeByIdSuccess({}));
        }}
        footer={null}
        destroyOnClose
      >
        <EditEmployee employeeId={employeeId} onSuccess={fetchEmployees} onCloseModal={toggleModal} />
      </AppModal>
    </div>
  );
};

export default EmployeeContacts;
