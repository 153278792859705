import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FIELD_TYPE } from "../../utils/constants";

const DraftComments = ({ form, type, fieldName }) => {
  const { t } = useTranslation();

  return (
    <>
      {type === FIELD_TYPE.unit ? (
        <Form form={form} layout="vertical" name="draft-comments">
          <Form.Item
            label={t("Property.DraftComment")}
            name={["unitInfo", fieldName, "draftComments"]}
            rules={[{ required: true, message: t("ErrorMessages.DraftCommentMessage") }]}
          >
            <Input placeholder={t("Property.DraftComment")} />
          </Form.Item>
        </Form>
      ) : (
        <Form form={form} layout="vertical" name="draft-comments">
          <Form.Item
            label={t("Property.DraftComment")}
            name="draftComments"
            rules={[{ required: true, message: t("ErrorMessages.DraftCommentMessage") }]}
          >
            <Input placeholder={t("Property.DraftComment")} />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

DraftComments.propTypes = {
  form: PropTypes.object,
  type: PropTypes.string,
  fieldName: PropTypes.number,
};

export default DraftComments;
