import TemplateService from "../../services/template.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { downloadFile, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  TEMPLATE_LOADING: "loading",
};

export function templateRequest(key = LOADING.TEMPLATE_LOADING) {
  return { type: ACTIONS.TEMPLATE_REQUEST, key };
}

export function templateSuccess(response) {
  return { type: ACTIONS.TEMPLATE_SUCCESS, response };
}

export function templateListSuccess(response) {
  return { type: ACTIONS.TEMPLATE_LIST_SUCCESS, response };
}

export function templateError(error, key = LOADING.TEMPLATE_LOADING) {
  return { type: ACTIONS.TEMPLATE_ERROR, error, key };
}

export function getAllTemplates() {
  return async dispatch => {
    dispatch(templateRequest());
    try {
      const [err, response] = await to(TemplateService.get());
      if (err) throwError(err);
      dispatch(templateListSuccess(response));
    } catch (error) {
      dispatch(templateError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function updateTemplate(id, payload) {
  return async dispatch => {
    dispatch(templateRequest());
    try {
      const [err, response] = await to(TemplateService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(templateSuccess({}));
    } catch (error) {
      dispatch(templateError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createTemplate(payload) {
  return async dispatch => {
    dispatch(templateRequest());
    try {
      const [err, response] = await to(TemplateService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(templateSuccess({}));
    } catch (error) {
      dispatch(templateError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function deleteTemplate(id) {
  return async dispatch => {
    dispatch(templateRequest());
    try {
      const [err, response] = await to(TemplateService.remove(id));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
      dispatch(templateSuccess({}));
    } catch (error) {
      dispatch(templateError(error));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      throwError(error);
    }
  };
}

export function exportTemplates() {
  return async dispatch => {
    dispatch(templateRequest());
    try {
      const [err, response] = await to(TemplateService.export());
      if (err) throwError(err);
      downloadFile(response, "Templates.xlsx");
      dispatch(templateSuccess({}));
    } catch (error) {
      dispatch(templateError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}
