import AuditorService from "../../services/auditor.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE, PAGINATOIN } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  AUDITOR_LOADING: "loading",
};

export function auditorRequest(key = LOADING.AUDITOR_LOADING) {
  return { type: ACTIONS.AUDITOR_REQUEST, key };
}

export function auditorSuccess(response) {
  return { type: ACTIONS.AUDITOR_SUCCESS, response };
}

export function auditorError(error, key = LOADING.AUDITOR_LOADING) {
  return { type: ACTIONS.AUDITOR_ERROR, error, key };
}

export function auditorSuggestionRequest() {
  return { type: ACTIONS.AUDITOR_SUGGESTION_REQUEST };
}

export function auditorSuggestionSuccess(response) {
  return { type: ACTIONS.AUDITOR_SUGGESTION_SUCCESS, response };
}

export function auditorSuggestionError(error) {
  return { type: ACTIONS.AUDITOR_SUGGESTION_ERROR, error };
}

export function getAuditorByIdSuccess(response) {
  return { type: ACTIONS.GET_AUDITOR_BY_ID_SUCCESS, response };
}

export function getAuditors(query) {
  return async dispatch => {
    dispatch(auditorRequest());
    try {
      const [err, response] = await to(AuditorService.searchAuditors(query));
      if (err || !response) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(auditorSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.auditors, pagination);
    } catch (error) {
      dispatch(auditorError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getAuditorsSuggestion(payload) {
  return async dispatch => {
    dispatch(auditorSuggestionRequest());
    try {
      const [err, response] = await to(AuditorService.suggestions(payload));
      if (err) throwError(err);
      dispatch(auditorSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(auditorSuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getAuditorById(id) {
  return async dispatch => {
    dispatch(auditorRequest());
    try {
      const [err, response] = await to(AuditorService.getAuditorById(id));
      if (err) throwError(err);
      dispatch(getAuditorByIdSuccess(response));
    } catch (error) {
      dispatch(auditorError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateAuditor(id, payload) {
  return async dispatch => {
    dispatch(auditorRequest());
    try {
      const [err, response] = await to(AuditorService.updateAuditor(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(getAuditorByIdSuccess({}));
    } catch (error) {
      dispatch(auditorError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
