import React from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import AppModal from "../AppModal/AppModal";

const LangModal = ({ isVisible, title, onClose, onTranslate }) => {
  const { t } = useTranslation();

  return (
    <AppModal
      visible={isVisible}
      minHeight="14vh"
      maxHeight="100vh"
      overFlowY="auto"
      centered
      title={title}
      onCancel={onClose}
      footer={
        <Row justify="center" gutter={24}>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Button block onClick={onClose}>
              {t("Buttons.Cancel")}
            </Button>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Button block type="primary" onClick={onTranslate}>
              {t("Buttons.Translate")}
            </Button>
          </Col>
        </Row>
      }
    >
      <div>
        This property has not been translated yet. You will need to translate it before you can publish that property.
        Would you like to go into edit mode now and create the translation?
      </div>
    </AppModal>
  );
};

LangModal.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onTranslate: PropTypes.func,
};

export default LangModal;
