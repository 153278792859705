import { getQueryParams, getSearchListQuery } from "../utils/utils";
import { get, put } from "./http.service";

const unitVacancyService = {
  getBuildingFromSnowflake: ({ id, ...query }) => get(`property/codes/${id || ""}${getSearchListQuery(query)}`),
  getBuildingById: id => get(`property/${id}`),
  searchUnitVacancy: query => get(`property/search`, { params: getQueryParams(query) }),
  searchDashboardUnitVacancy: (query, getPropertyType) =>
    get(`property/${getPropertyType}`, { params: getQueryParams(query) }),
  suggestions: query => get(`property/suggestion${getSearchListQuery(query)}`),
  unmarketUnit: body => put(`property/unmarketUnit`, body),
  marketUnit: body => put(`property/marketUnit`, body),
};
export default unitVacancyService;
