import { Col, Row, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import _isNumber from "lodash/isNumber";
import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import EditableTagGroup from "../../components/EditableTagGroup/EditableTagGroup";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchListView from "../../components/SearchListView/SearchListView";
import {
  usePropertyFilterTags,
  usePropertyPagination,
  useUpcomingPropertyPagination,
  useUpcomingVacantPropertyFilterTags,
} from "../../hooks";
import {
  getUnitVacancyList,
  upcomingVacantPropertiesTagsSuccess,
  unitVacancyTagsSuccess,
} from "../../store/actions/unitVacancy.actions";
import { homePaginationSuccess, homeTagsSuccess } from "../../store/actions/home.actions";
import {
  DATE_FORMAT_TIME,
  AVAILABILITY_PAGE_PROPERTY_SORTER,
  FIELD_TYPE,
  PAGINATOIN,
  PROPERTY_TYPE_UI_MAPPING,
  ROUTES,
  SORT_DIRECTION,
  SUGGESTION_SEARCH_STATE,
  TAG,
  HOME_UNITS_TYPE,
  HOME_PROPERTIES_TITLE,
} from "../../utils/constants";
import { renderPropertyStatus } from "../../utils/property.util";
import { setSessionStorage } from "../../utils/utils";
import SearchPropertyHorizontal from "../SearchPropertyHorizontal/SearchPropertyHorizontal";
import "./UnitVacancy.scss";

const UnitVacancy = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { type } = useParams();
  const { loading, list, pagination, upcomingVacantPagination, tags, upcomingVacantPropertyTags } = useSelector(
    state => state.unitVacancy,
  );
  const { homePagination, homeTags } = useSelector(state => state.home);
  const { list: markets, loading: loadingMarket } = useSelector(state => state.market);

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isFromHome, setIsFromHome] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);

  const { propertyTag } = usePropertyFilterTags({ tags, tagType: TAG.unitVacancy });
  const { upcomingVacantPropertyTag } = useUpcomingVacantPropertyFilterTags({
    tags: upcomingVacantPropertyTags,
    tagType: TAG.upcomingVacantProperties,
  });
  const { propertyPagination } = usePropertyPagination({ pagination, paginationType: PAGINATOIN.unitVacancy });
  const { upcomingPropertyPagination } = useUpcomingPropertyPagination({
    pagination: upcomingVacantPagination,
    paginationType: PAGINATOIN.upcomingPropertyPagination,
  });
  const homePaginationResponse = usePropertyPagination({ pagination: homePagination, paginationType: PAGINATOIN.home });

  useEffect(() => {
    if (!isEmpty(HOME_UNITS_TYPE[type])) {
      setIsFromHome(true);
      let _newPropertyTags = [];
      const homePaginationValues = homePaginationResponse.propertyPagination;
      if (!isEmpty(homePaginationValues) && !isEmpty(homePaginationValues?.filterCriteria)) {
        if (isEmpty(homePaginationValues?.filterCriteria?.market)) {
          let availableMarkets = [];
          if (
            !isEmpty(homePaginationValues?.filterCriteria?.division) ||
            !isEmpty(homePaginationValues?.filterCriteria?.region)
          ) {
            if (!isEmpty(homePaginationValues?.filterCriteria?.region)) {
              availableMarkets = [
                ...new Set(
                  markets.filter(market =>
                    (homePaginationValues?.filterCriteria?.region).includes(market.region?.code),
                  ),
                ),
              ];
            } else {
              availableMarkets = [
                ...new Set(
                  markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
                ),
              ];
            }
            availableMarkets = [...new Set(availableMarkets.map(element => element.id))];
            _newPropertyTags = [...availableMarkets];
          } else {
            _newPropertyTags = [];
          }
        } else {
          _newPropertyTags = [...(homePaginationValues?.filterCriteria?.market || [])];
        }
        (async () => {
          await dispatch(upcomingVacantPropertiesTagsSuccess(_newPropertyTags));
          console.log(`property tags :: `);
        })();
      }
    }
  }, []);

  useEffect(() => {
    // Add tags from filtered views to home.
    if (!isEmpty(HOME_UNITS_TYPE[type])) {
      const homePaginationValues = homePaginationResponse.propertyPagination;
      const oldFilterCriteria = homePaginationValues?.filterCriteria;
      let oldMarkets = [],
        oldSubMarkets = [];
      // If market and subMarket are not available, use division and region to filter markets.
      if (
        isEmpty(homePaginationValues?.filterCriteria?.market) &&
        isEmpty(homePaginationValues?.filterCriteria?.subMarket)
      ) {
        // If division and region are also not available, no markets and submarket are selected
        if (
          !isEmpty(homePaginationValues?.filterCriteria?.division) ||
          !isEmpty(homePaginationValues?.filterCriteria?.region)
        ) {
          // If region is available, filter markets by region, else use division.
          if (!isEmpty(homePaginationValues?.filterCriteria?.region)) {
            oldMarkets = [
              ...new Set(
                markets.filter(market => (homePaginationValues?.filterCriteria?.region).includes(market.region?.code)),
              ),
            ];
          } else {
            oldMarkets = [
              ...new Set(
                markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
              ),
            ];
          }
          oldMarkets = [...new Set(oldMarkets.map(element => element.id))];
        } else {
          oldMarkets = oldFilterCriteria?.market || [];
          oldSubMarkets = oldFilterCriteria?.subMarket || [];
        }
      } else {
        oldMarkets = oldFilterCriteria?.market || [];
        oldSubMarkets = oldFilterCriteria?.subMarket || [];
      }
      const newMarkets = upcomingPropertyPagination?.filterCriteria?.market || [];
      const filterCriteria = {
        ...oldFilterCriteria,
        market: newMarkets,
      };
      const newHomeTags = [
        ...(oldFilterCriteria?.division || []),
        ...(oldFilterCriteria?.region || []),
        ...newMarkets,
        ...(oldFilterCriteria?.subMarket || []),
      ];
      setSessionStorage(PAGINATOIN.home, { filterCriteria });
      setSessionStorage(TAG.home, newHomeTags);
      dispatch(homePaginationSuccess({ filterCriteria }));
      dispatch(homeTagsSuccess(newHomeTags));
    }
  }, [upcomingVacantPropertyTags]);

  const columns = [
    {
      title: t("PropertiesTable.PropertyCode"),
      dataIndex: "propertyCode",
      key: "propertyCode",
      fixed: "left",
      width: 150,
      render: (text, record) => {
        const link = ROUTES.UNIT_VACANCY.path;
        return (
          <>
            <Link to={`${link}/${record?.id}`}>{text}</Link>
            {record?.isDraft && (
              <>
                <Tag className="text-capitalize float-right" color="warning">
                  {t("PropertyStatusUiMapping.draft")}
                </Tag>
              </>
            )}
          </>
        );
      },
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyCode" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Name"),
      dataIndex: "marketingPropertyName",
      key: "marketingPropertyName",
      ellipsis: true,
      width: 150,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketingPropertyName" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.StreetAddress"),
      dataIndex: "streetAddress1",
      key: "streetAddress1",
      ellipsis: true,
      width: 150,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "streetAddress1" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      render: text => <>{renderPropertyStatus(text)}</>,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "status" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Type"),
      dataIndex: "propertyType",
      key: "propertyType",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyType" && sortInfo?.order,
      width: 100,
      render: (text, record) => PROPERTY_TYPE_UI_MAPPING[record?.propertyType],
    },
    {
      title: t("PropertiesTable.Market"),
      dataIndex: "market",
      key: "market",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "market" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          <Tag className="ellipsis" color="geekblue">
            {text}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: t("PropertiesTable.Units"),
      dataIndex: "totalUnits",
      key: "totalUnits",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "totalUnits" && sortInfo?.order,
      width: 100,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.totalUnits) ? record.totalUnits : "N/A",
    },
    {
      title: t("PropertiesTable.AvailableUnits"),
      dataIndex: "availableUnits",
      key: "availableUnits",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "availableUnits" && sortInfo?.order,
      width: 150,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.availableUnits)
          ? record.availableUnits
          : "N/A",
    },
    {
      title: t("PropertiesTable.PublishedUnits"),
      dataIndex: "publishedUnits",
      key: "publishedUnits",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "publishedUnits" && sortInfo?.order,
      width: 150,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.publishedUnits)
          ? record.publishedUnits
          : "N/A",
    },
    {
      title: t("PropertiesTable.NotMarketingUnits"),
      dataIndex: "notMarketingUnits",
      key: "notMarketingUnits",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "notMarketingUnits" && sortInfo?.order,
      width: 150,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.notMarketingUnits)
          ? record.notMarketingUnits
          : "N/A",
    },
    {
      title: t("PropertiesTable.LastModifiedDate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      fixed: "right",
      width: 150,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
      sorter: true,
      sortDirections: SORT_DIRECTION.threeDirection,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
    },
  ];

  /**
   * To get the list of buildings
   */
  const getList = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = isFromHome ? upcomingPropertyPagination : pagination;
      }
      _query.filterCriteria = { ..._query.filterCriteria };
      if (!isEmpty(HOME_UNITS_TYPE[type])) {
        await dispatch(getUnitVacancyList(_query, true, HOME_UNITS_TYPE[type]));
      } else {
        setIsFromHome(false);
        await dispatch(getUnitVacancyList(_query, false, ""));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const propertiesPageTitle = !isEmpty(HOME_UNITS_TYPE[type]) ? HOME_PROPERTIES_TITLE[type] : ROUTES.UNIT_VACANCY.title;

  /**
   * Wrapper method to trigger when updates are made
   * to filter tags
   */
  const onUpdateTags = async _tags => {
    if (isFromHome) {
      setSessionStorage(TAG.upcomingVacantProperties, []);
      setShouldFetchData(true);
      await dispatch(upcomingVacantPropertiesTagsSuccess(_tags));
    } else {
      setSessionStorage(TAG.unitVacancy, []);
      setShouldFetchData(true);
      await dispatch(unitVacancyTagsSuccess(_tags));
    }
    setShouldFetchData(false);
  };

  const loadingTable = loading || loadingMarket;

  return (
    <div className="unit-vacancy">
      <PageTitle title={propertiesPageTitle} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <SearchPropertyHorizontal
            defaultSort={AVAILABILITY_PAGE_PROPERTY_SORTER}
            getList={getList}
            shouldFetchData={shouldFetchData}
            isFromHome={isFromHome}
            pagination={isFromHome ? upcomingPropertyPagination : propertyPagination}
            tags={isFromHome ? upcomingVacantPropertyTag : propertyTag}
            propertyType={FIELD_TYPE.building}
            searchState={SUGGESTION_SEARCH_STATE.unitVacancy}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]} className="row-vertical-padding-8">
            <Col xs={16} sm={22} md={24} lg={24} xl={24} xxl={24}>
              <EditableTagGroup tags={isFromHome ? upcomingVacantPropertyTag : propertyTag} updateTags={onUpdateTags} />
            </Col>
          </Row>
          <SearchListView
            paginationKey={isFromHome ? PAGINATOIN.upcomingPropertyPagination : PAGINATOIN.unitVacancy}
            defaultSort={AVAILABILITY_PAGE_PROPERTY_SORTER}
            preventInitialFetchList
            setFilterInfo={setFilterInfo}
            setSortInfo={setSortInfo}
            dataSource={list}
            columns={columns}
            loading={loadingTable}
            rowKey="id"
            getList={getList}
            pagination={isFromHome ? upcomingPropertyPagination : pagination}
            scroll={{ x: 1450, y: "calc(100vh - 460px)" }}
          />
        </Col>
      </Row>
    </div>
  );
};

UnitVacancy.defaultProps = {};

UnitVacancy.propTypes = {};

export default UnitVacancy;
