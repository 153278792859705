import ACTIONS from "./types.actions";
import { downloadFile, setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import { SUCCESS_MESSAGE, ERROR_MESSAGE, PAGINATOIN } from "../../utils/constants";
import EmployeeService from "../../services/employee.service";

export const LOADING = {
  EMPLOYEE_LOADING: "loading",
};

export function employeeRequest() {
  return { type: ACTIONS.EMPLOYEE_REQUEST };
}

export function employeeSuggestionRequest() {
  return { type: ACTIONS.EMPLOYEE_SUGGESTION_REQUEST };
}

export function employeeSuccess(response) {
  return { type: ACTIONS.EMPLOYEE_SUCCESS, response };
}

export function employeeSuggestionSuccess() {
  return { type: ACTIONS.EMPLOYEE_SUGGESTION_SUCCESS };
}

export function getAllEmployeesSuccess(response) {
  return { type: ACTIONS.GET_ALL_EMPLOYEES_SUCCESS, response };
}

export function getEmployeeByIdSuccess(response) {
  return { type: ACTIONS.GET_EMPLOYEE_BY_ID_SUCCESS, response };
}

export function employeeError(error, key = LOADING.EMPLOYEE_LOADING) {
  return { type: ACTIONS.EMPLOYEE_ERROR, error, key };
}

export function employeeSuggestionError(error) {
  return { type: ACTIONS.EMPLOYEE_SUGGESTION_ERROR, error };
}

export function getEmployeeContacts(query) {
  return async dispatch => {
    dispatch(employeeRequest());
    try {
      const [err, response] = await to(EmployeeService.searchEmployees(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllEmployeesSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.employees, pagination);
    } catch (error) {
      dispatch(employeeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getEmployeesSuggestion(payload) {
  return async dispatch => {
    dispatch(employeeSuggestionRequest());
    try {
      const [err, response] = await to(EmployeeService.suggestions(payload));
      if (err) throwError(err);
      dispatch(employeeSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(employeeSuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getEmployeeById(id) {
  return async dispatch => {
    dispatch(employeeRequest());
    try {
      const [err, response] = await to(EmployeeService.getEmployeeById(id));
      if (err) throwError(err);
      dispatch(getEmployeeByIdSuccess(response));
    } catch (error) {
      dispatch(employeeError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateEmployee(id, payload) {
  return async dispatch => {
    dispatch(employeeRequest());
    try {
      const [err, response] = await to(EmployeeService.updateEmployee(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(getEmployeeByIdSuccess({}));
    } catch (error) {
      dispatch(employeeError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function exportEmployees() {
  return async dispatch => {
    dispatch(employeeRequest());
    try {
      const [err, response] = await to(EmployeeService.exportEmployees());
      if (err) throwError(err);
      downloadFile(response, "Employees.xlsx");
      dispatch(getEmployeeByIdSuccess({}));
    } catch (error) {
      dispatch(employeeError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}
