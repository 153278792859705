import { getQueryParams, getSearchListQuery } from "../utils/utils";
import { get, post, put, remove } from "./http.service";

const auditService = {
  get: pCode => get(`audit/${pCode}`),
  add: body => post(`audit`, body),
  searchAudits: query => get(`audit/search`, { params: getQueryParams(query) }),
  openAudits: query => get(`audit/openAuditProperties`, { params: getQueryParams(query) }),
  update: (id, body) => put(`audit/${id}`, body),
  resolveAll: id => put(`audit/resolveAll/${id}`),
  remove: (propertyCode, auditId) => remove(`audit/?propertyCode=${propertyCode}&auditId=${auditId}`),
  comment: (id, body) => put(`audit/comment/${id}`, body),
  suggestions: query => get(`audit/suggestion${getSearchListQuery(query)}`),
  sendNotification: body => post(`audit/sendEmail`, body),
};
export default auditService;
