import { Button, Col, Form, Row, Select, Input, Radio } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import _get from "lodash/get";
import CustomIcon from "../CustomIcon/CustomIcon";
import "../../index.scss";
import { extractContent, sortAlphabetically } from "../../utils/utils";
import { createHelperText, updateHelperText } from "../../store/actions/helperText.actions";
import {
  FIELD_TYPE_UI_MAPPING,
  HELPER_TEXT_DEFAULT_MESSAGE,
  HELPER_TEXT_DROPDOWN_MAPPING,
  LANGUAGE_CODES,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
  FIELD_TYPE,
} from "../../utils/constants";
import "./HelperTextEditor.scss";

const { Option } = Select;

const _initialValues = {
  language: LANGUAGE_CODES.English.value,
  helperText: "",
  type: FIELD_TYPE.building,
};

const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_CODES).map(_lang => ({
  label: LANGUAGE_CODES[_lang].label,
  value: LANGUAGE_CODES[_lang].value,
}));

const getTranslatedHelperText = (lang, helperTextObj, formValue, helperTextTranslations) => {
  let value;
  if (lang !== LANGUAGE_CODES.English.value) {
    value =
      _get(helperTextObj, `translations.${lang}.helperText`, helperTextObj?.helperText) ||
      _get(helperTextTranslations, `[${lang}].helperText`) ||
      _get(helperTextTranslations, `[${LANGUAGE_CODES.English.value}].helperText`) ||
      formValue;
  } else {
    value =
      _get(helperTextTranslations, `[${LANGUAGE_CODES.English.value}].helperText`, helperTextObj?.helperText) ||
      formValue;
  }

  return value || "";
};

const HelperTextEditor = ({ helperTextItem, onCloseModal, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const reactQuilRef = useRef();
  const [loading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(_initialValues.language);
  const [selectedType, setSelectedType] = useState(helperTextItem ? helperTextItem.type : _initialValues.type);
  const [selectedFieldLabel, setSelectedFieldLabel] = useState(
    helperTextItem ? { id: helperTextItem.id, fieldLabel: helperTextItem.fieldLabel } : null,
  );
  const [error, setError] = useState(false);
  const [helperTextTranslations, setHelperTextTranslations] = useState({
    ...helperTextItem?.translations,
  });
  const [initialValues, setInitialValues] = useState({
    ..._initialValues,
    ...helperTextItem,
    helperText: getTranslatedHelperText(_initialValues.language, helperTextItem),
  });

  const isEditing = !!helperTextItem;

  useEffect(() => {
    if (reactQuilRef.current) {
      reactQuilRef.current.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map(op => {
          const tag = {
            insert: op.insert,
          };

          if (op?.attributes?.link) {
            tag.attributes = {
              link: op.attributes.link,
            };
          }

          return tag;
        });
        return delta;
      });
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      helperText:
        helperTextTranslations[currentLanguage]?.helperText ||
        getTranslatedHelperText(
          currentLanguage,
          helperTextItem,
          form.getFieldValue("helperText"),
          helperTextTranslations,
        ),
    });
  }, [currentLanguage]);

  const getReqBody = values => {
    const _values = { ...values };
    _values.translations = { ...helperTextTranslations };
    if (_values.language !== LANGUAGE_CODES.English.value && _isEmpty(helperTextTranslations[_values.language])) {
      _values.translations = { ..._values.translations, [_values.language]: { helperText: _values.helperText } };
    } else {
      _values.helperText = _values.helperText;
    }
    if (!_isEmpty(helperTextTranslations[LANGUAGE_CODES.English.value]?.helperText)) {
      _values.helperText = helperTextTranslations[LANGUAGE_CODES.English.value].helperText;
      delete _values.translations[LANGUAGE_CODES.English.value];
    } else if (!_isEmpty(helperTextItem)) {
      _values.helperText = helperTextItem.helperText;
    }

    _values.id = selectedFieldLabel.id;
    _values.fieldLabel = selectedFieldLabel.fieldLabel;

    if (_values.type === FIELD_TYPE.generic) {
      delete _values.type;
    }

    return _values;
  };

  const saveValue = async fieldValues => {
    try {
      const _fieldValues = { ...fieldValues };
      if (!_isEmpty(extractContent(_fieldValues.helperText))) {
        setLoading(true);
        const reqBody = getReqBody(_fieldValues, helperTextItem);

        if (_isEmpty(helperTextItem)) {
          await dispatch(createHelperText(reqBody));
        } else {
          await dispatch(updateHelperText(helperTextItem.id, reqBody));
        }

        if (onSuccess) onSuccess();
        onCloseModal(false);
      }
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      setLoading(false);
    }
  };

  const resetValue = () => {
    onCloseModal(false);
  };
  const _onChange = (val, delta, source, editor) => {
    const _helperTextValue = editor.getText();
    if (editor.getText().length > 1 && _helperTextValue.replace("/\t|\r/", "") >= 0) {
      setError(true);
    } else if (editor.getText().length > 1) {
      setError(false);
      setHelperTextTranslations({
        ...helperTextTranslations,
        [currentLanguage]: {
          helperText: val,
        },
      });
    } else {
      setError(true);
    }
  };
  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const onPressMenuItem = key => {
    if (_isEmpty(helperTextTranslations[currentLanguage])) {
      setHelperTextTranslations({
        ...helperTextTranslations,
        [currentLanguage]: {
          helperText: getTranslatedHelperText(
            currentLanguage,
            helperTextItem,
            form.getFieldValue("helperText"),
            helperTextTranslations,
          ),
        },
      });
    }

    setCurrentLanguage(key);
  };

  const _onChangeType = e => {
    setSelectedType(e.target.value);
    form.setFieldsValue({ fieldLabel: null });
  };

  const _onSelectFieldLabel = (key, option) => {
    setSelectedFieldLabel({ id: option.key, fieldLabel: option.value });
  };

  return (
    <Form
      className="helper-text-editor"
      form={form}
      name="basic"
      layout="vertical"
      initialValues={initialValues}
      onFinish={saveValue}
      validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      onFinishFailed={onFinishFailed}
    >
      <p className="template-desc">
        This helper text form is used to create or update all versions of text in all languages. The Field Label, Field
        Type, and Description are all required fields. When you select a different Language from the
        &quot;Language&quot; selector, the entries you made in the previous language will be stored as you move on to
        the next language selection. Once you are done with your entries, click on the &quot;Save&quot; button and it
        will save all entries to all languages that you have made. You will see a separate line entry for each language
        on the &quot;Helper Text&quot; screen.
      </p>
      <Row className="action-btns" type="flex" justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label={t("Table.HelperText.FieldLabel")}
            name="fieldLabel"
            rules={[{ required: true, whitespace: true }]}
          >
            <Select placeholder={t("Table.HelperText.FieldLabel")} onSelect={_onSelectFieldLabel} disabled={isEditing}>
              {sortAlphabetically(HELPER_TEXT_DROPDOWN_MAPPING[selectedType]).map(_helperTextFieldLabel => (
                <Option key={_helperTextFieldLabel.value} value={_helperTextFieldLabel.label}>
                  {_helperTextFieldLabel.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11}>
          <Form.Item label={t("Table.HelperText.Type")} name="type" rules={[{ required: true }]}>
            <Radio.Group
              size="middle"
              buttonStyle="solid"
              optionType="button"
              onChange={_onChangeType}
              disabled={isEditing}
            >
              {Object.keys(FIELD_TYPE).map(field => (
                <Radio.Button value={field} key={field}>
                  {FIELD_TYPE_UI_MAPPING[field]}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={t("Table.HelperText.Language")} name="language" rules={[{ required: true }]}>
            <Select onChange={onPressMenuItem}>
              {sortAlphabetically(LANGUAGE_OPTIONS).map(_lang => (
                <Option key={_lang.value} value={_lang.value}>
                  {_lang.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="helperText"
        rules={[{ required: true, message: t("ErrorMessages.HelperTextError"), whitespace: true }]}
      >
        <ReactQuill
          theme="snow"
          onChange={_onChange}
          preserveWhitespace
          className={`quil-component ${error ? "quilclass" : "quilgreen"}`}
          ref={reactQuilRef}
        />
      </Form.Item>
      {error ? <p className="quilclassmsg">{t("ErrorMessages.HelperTextError")}</p> : ""}
      <Row className="fields-row" gutter={20} type="flex" justify="center">
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block onClick={resetValue} className="cancelButton">
            {t("Buttons.Cancel")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block type="primary" htmlType="submit" className="saveButton" loading={loading} disabled={error}>
            {t("Buttons.Save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
HelperTextEditor.propTypes = {
  helperTextItem: PropTypes.object,
  onCloseModal: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default HelperTextEditor;
