import { Layout } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "./AppFooter.scss";

const { Footer } = Layout;
const AppFooter = ({ isFixedLayout }) => {
  const { t } = useTranslation();

  return (
    <Footer className={`app-footer ${isFixedLayout ? " app-footer__fixed" : ""}`}>
      {t("AppFooter.Copyright")}
      &nbsp;©
      {new Date().getFullYear()}
      &nbsp;-
      {t("AppFooter.AllRightsReserved")}
    </Footer>
  );
};
AppFooter.propTypes = {
  isFixedLayout: PropTypes.bool,
};

export default AppFooter;
