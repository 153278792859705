import React from "react";
import { Row, Col, Radio } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import {
  buildAddressForPreview,
  getAvailableSpaceForBuilding,
  getClearHeight,
  getMeasurements,
  getTotalAvailableForPreview,
  isAsianCountry,
} from "../../utils/property.util";
import { dateLanguageLookup, MEASUREMENT_TYPE, MEASURMENT_UNIT_CODES, OUTBOUND_API_TYPES } from "../../utils/constants";
import { useLanguage } from "../../hooks";
import { getLocalTranslatedValue } from "../../i18n";

const BuildingDetails = ({ values, updatedAt, activeMeasurement, radioOptions, onChangeMeasurement }) => {
  const { language } = useLanguage();
  const _unitValues =
    language.current === "en" ? values.unitInfo[0] : values.unitInfo[0].translations[language.current];
  const isUnitKeyFeaturesAvailable =
    !_isEmpty(_unitValues.keyFeatures) &&
    (!_isEmpty(_unitValues.keyFeatures.feature1) ||
      !_isEmpty(_unitValues.keyFeatures.feature2) ||
      !_isEmpty(_unitValues.keyFeatures.feature3) ||
      !_isEmpty(_unitValues.keyFeatures.feature4));
  const translatedValues = values.translations[language.current];
  const measurements = getMeasurements(
    _get(values, "buildingInfo.minSpaceAvailable", 0),
    _get(values, "buildingInfo.maxSpaceAvailable", 0),
    _get(values, "buildingInfo.totalBuildingSize", 0),
    _get(values, "buildingInfo.minSpaceAvailableUOM", ""),
    OUTBOUND_API_TYPES.BUILDING,
    getAvailableSpaceForBuilding(
      values,
      _get(values, "buildingInfo.minSpaceAvailableUOM", ""),
      _get(values, "buildingInfo.maxSpaceAvailable", 0),
    ),
  );
  const unitMeasurement = getMeasurements(
    values.unitInfo[0].minSpaceAvailable,
    values.unitInfo[0].maxSpaceAvailable,
    null,
    values.unitInfo[0].minSpaceAvailableUOM,
    OUTBOUND_API_TYPES.UNIT,
    values.unitInfo[0].maxSpaceAvailable,
    _get(values.unitInfo[0], "officeSize", null),
  );
  const clearHeightUOM = activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? "FT" : "M";
  const _updateAt = new Date(updatedAt).toLocaleString(dateLanguageLookup[language.current], { dateStyle: "long" });

  const getTotalAvailable = () =>
    `${getTotalAvailableForPreview(
      activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? measurements[0].data : measurements[1].data,
    )} ${activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? MEASURMENT_UNIT_CODES.SF : MEASURMENT_UNIT_CODES.SQM}`;

  const getOfficeSize = () => {
    if (values.unitInfo[0].officeSize) {
      if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
        return `${_get(unitMeasurement[0], "data.office_size.value")} ${MEASURMENT_UNIT_CODES.SF}`;
      }
      return `${_get(unitMeasurement[1], "data.office_size.value")} ${MEASURMENT_UNIT_CODES.SQM}`;
    }
    return "N/A";
  };

  const getUnitMinSize = () => {
    if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
      return `${_get(unitMeasurement[0], "data.min_divisible.value").toLocaleString("en-US")} ${
        MEASURMENT_UNIT_CODES.SF
      }`;
    }
    return `${_get(unitMeasurement[1], "data.min_divisible.value").toLocaleString("en-US")} ${
      MEASURMENT_UNIT_CODES.SQM
    }`;
  };

  const getUnitMaxSize = () => {
    if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
      return `${_get(unitMeasurement[0], "data.max_contiguous.value").toLocaleString("en-US")} ${
        MEASURMENT_UNIT_CODES.SF
      }`;
    }
    return `${_get(unitMeasurement[1], "data.max_contiguous.value").toLocaleString("en-US")} ${
      MEASURMENT_UNIT_CODES.SQM
    }`;
  };

  const KeyFeatures = () => {
    if (language.current === "en") {
      return (
        <ul className="property-features-list">
          {!_isEmpty(values.keyFeatures.feature1) && <li>{values.keyFeatures.feature1}</li>}
          {!_isEmpty(values.keyFeatures.feature2) && <li>{values.keyFeatures.feature2}</li>}
          {!_isEmpty(values.keyFeatures.feature3) && <li>{values.keyFeatures.feature3}</li>}
          {!_isEmpty(values.keyFeatures.feature4) && <li>{values.keyFeatures.feature4}</li>}
          {!_isEmpty(values.keyFeatures.feature5) && <li>{values.keyFeatures.feature5}</li>}
          {!_isEmpty(values.keyFeatures.feature6) && <li>{values.keyFeatures.feature6}</li>}
        </ul>
      );
    }
    return (
      <ul className="property-features-list">
        {!_isEmpty(translatedValues.keyFeatures.feature1) && <li>{translatedValues.keyFeatures.feature1}</li>}
        {!_isEmpty(translatedValues.keyFeatures.feature2) && <li>{translatedValues.keyFeatures.feature2}</li>}
        {!_isEmpty(translatedValues.keyFeatures.feature3) && <li>{translatedValues.keyFeatures.feature3}</li>}
        {!_isEmpty(translatedValues.keyFeatures.feature4) && <li>{translatedValues.keyFeatures.feature4}</li>}
        {!_isEmpty(translatedValues.keyFeatures.feature5) && <li>{translatedValues.keyFeatures.feature5}</li>}
        {!_isEmpty(translatedValues.keyFeatures.feature6) && <li>{translatedValues.keyFeatures.feature6}</li>}
      </ul>
    );
  };

  const address = buildAddressForPreview(values.buildingInfo.address, translatedValues?.address, language.current);

  const getTotalBuildingSize = () => {
    if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
      return `${measurements[0].data.building_size.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.SF}`;
    }
    return `${measurements[1].data.building_size.value.toLocaleString("en-US")} ${MEASURMENT_UNIT_CODES.SQM}`;
  };

  return (
    <div className="property-details w-container">
      {values.unitInfo.length === 1 && (
        <>
          <Row gutter={16} className="row-vertical-padding-16">
            <Col xs={24} md={8} lg={6}>
              {!isAsianCountry(null, "buildingInfo", values) && (
                <Row>
                  <Col xs={24} className="property-item-title">
                    {getLocalTranslatedValue(language.current, "TotalAvailable")}
                  </Col>
                  <Col xs={24} className="property-item-value">
                    {getTotalAvailable()}
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} className="property-item-title">
                  {getLocalTranslatedValue(language.current, "TotalBuildingSize")}
                </Col>
                <Col xs={24} className="property-item-value">
                  {getTotalBuildingSize()}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Row>
                <Col xs={24} className="property-item-title">
                  {getLocalTranslatedValue(language.current, "Address")}
                </Col>
                <Col xs={24} className="property-item-value">
                  {address}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} lg={12} className="text-end">
              <Radio.Group
                options={radioOptions}
                optionType="button"
                buttonStyle="solid"
                value={activeMeasurement}
                onChange={onChangeMeasurement}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="property-heading">
              {getLocalTranslatedValue(language.current, "PropertyDetails")}
            </Col>
            <Col xs={24} className="property-update-date">
              {updatedAt && `${getLocalTranslatedValue(language.current, "Updated")}: ${_updateAt}`}
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <p className="property-item-value">
                {language.current === "en"
                  ? _get(values, "buildingInfo.marketingDescription", "")
                  : _get(translatedValues, "buildingInfo.marketingDescription", "")}
              </p>
            </Col>
          </Row>
          <Row gutter={20}>
            {!isUnitKeyFeaturesAvailable ? (
              <>
                <Col xs={24} md={8} lg={6}>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "Features")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      <KeyFeatures />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "MinSpaceAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getUnitMinSize()}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "OfficeSize")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {getOfficeSize()}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "ClearHeight")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {`${getClearHeight(
                        _get(values, "unitInfo[0].clearHeight"),
                        _get(values, "unitInfo[0].clearHeightUOM"),
                        activeMeasurement,
                      )} ${getLocalTranslatedValue(language.current, clearHeightUOM)}`}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "MaxSpaceAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getUnitMaxSize()}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "GradeLevelDoors")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].gradeLevelDoors", "N/A")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "FireSuppresionSystem")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].fireSuppressionSystem")}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "TotalAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getTotalAvailable()}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "WarehouseLightingType")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].warehouseLightingType")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "DockHighDoors")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].dockHighDoors", "N/A")}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} md={8} lg={6}>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "Features")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      <KeyFeatures />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "MinSpaceAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getUnitMinSize()}
                      </Col>
                    </Row>
                  )}
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "TotalAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getTotalAvailable()}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "GradeLevelDoors")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].gradeLevelDoors", "N/A")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "ClearHeight")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {`${getClearHeight(
                        _get(values, "unitInfo[0].clearHeight"),
                        _get(values, "unitInfo[0].clearHeightUOM"),
                        activeMeasurement,
                      )} ${getLocalTranslatedValue(language.current, clearHeightUOM)}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "DockHighDoors")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].dockHighDoors", "N/A")}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  {!isAsianCountry(null, "buildingInfo", values) && (
                    <Row>
                      <Col xs={24} className="property-item-title">
                        {getLocalTranslatedValue(language.current, "MaxSpaceAvailable")}
                      </Col>
                      <Col xs={24} className="property-item-value">
                        {getUnitMaxSize()}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "OfficeSize")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {getOfficeSize()}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "WarehouseLightingType")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].warehouseLightingType")}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className="property-item-title">
                      {getLocalTranslatedValue(language.current, "FireSuppresionSystem")}
                    </Col>
                    <Col xs={24} className="property-item-value">
                      {_get(values, "unitInfo[0].fireSuppressionSystem")}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <div className="property-item-title">{getLocalTranslatedValue(language.current, "UnitFeatures")}</div>
                  <ul className="property-features-list">
                    {!_isEmpty(_unitValues?.keyFeatures?.feature1) && (
                      <li className="property-item-value">{_unitValues.keyFeatures.feature1}</li>
                    )}
                    {!_isEmpty(_unitValues?.keyFeatures?.feature2) && (
                      <li className="property-item-value">{_unitValues.keyFeatures.feature2}</li>
                    )}
                    {!_isEmpty(_unitValues?.keyFeatures?.feature3) && (
                      <li className="property-item-value">{_unitValues.keyFeatures.feature3}</li>
                    )}
                    {!_isEmpty(_unitValues?.keyFeatures?.feature4) && (
                      <li className="property-item-value">{_unitValues.keyFeatures.feature4}</li>
                    )}
                  </ul>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
      {values.unitInfo.length > 1 && (
        <>
          <Row>
            <Col xs={24} className="property-heading">
              {getLocalTranslatedValue(language.current, "PropertyDetails")}
            </Col>
            <Col xs={24} className="property-update-date">
              {updatedAt && `${getLocalTranslatedValue(language.current, "Updated")}: ${_updateAt}`}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} lg={16}>
              <p className="property-item-value">
                {language.current === "en"
                  ? _get(values, "buildingInfo.marketingDescription", "")
                  : _get(translatedValues, "buildingInfo.marketingDescription", "")}
              </p>
            </Col>
            <Col xs={24} lg={8} className="property-item-value">
              <Row>
                <Col xs={24} className="property-item-title">
                  {getLocalTranslatedValue(language.current, "Features")}
                </Col>
                <Col xs={24} className="property-item-value">
                  <KeyFeatures />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

BuildingDetails.propTypes = {
  values: PropTypes.object,
  updatedAt: PropTypes.string,
  activeMeasurement: PropTypes.object,
  radioOptions: PropTypes.array,
  onChangeMeasurement: PropTypes.func,
};

export default BuildingDetails;
