import MarketService from "../../services/market.service";
import { ERROR_MESSAGE, PAGINATOIN, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage, downloadFile, setSessionStorage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  MARKET_LOADING: "loading",
  ACTIVE_MARKET_LOADING: "activeLoading",
};

export function marketRequest(key = LOADING.MARKET_LOADING) {
  return { type: ACTIONS.MARKET_REQUEST, key };
}

export function marketSuggestionRequest() {
  return { type: ACTIONS.MARKET_SUGGESTION_REQUEST };
}

export function marketSuccess(response) {
  return { type: ACTIONS.MARKET_SUCCESS, response };
}

export function marketSuggestionSuccess() {
  return { type: ACTIONS.MARKET_SUGGESTION_SUCCESS };
}

export function getAllMarketsSuccess(response) {
  return { type: ACTIONS.GET_ALL_MARKETS_SUCCESS, response };
}

export function getAllMarketsSelectedSuccess(response) {
  return { type: ACTIONS.GET_ALL_MARKETS_SELECTED_SUCCESS, response };
}

export function activeMarketsSuccess(response) {
  return { type: ACTIONS.ACTIVE_MARKETS_SUCCESS, response };
}

export function getMarketByIdSuccess(response) {
  return { type: ACTIONS.GET_MARKET_BY_ID_SUCCESS, response };
}

export function marketError(error, key = LOADING.MARKET_LOADING) {
  return { type: ACTIONS.MARKET_ERROR, error, key };
}

export function marketSuggestionError(error) {
  return { type: ACTIONS.MARKET_SUGGESTION_ERROR, error };
}

export function getMarkets(query) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.searchMarkets(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllMarketsSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.markets, pagination);
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getActiveMarkets(query) {
  return async dispatch => {
    dispatch(marketRequest(LOADING.ACTIVE_MARKET_LOADING));
    try {
      const [err, response] = await to(MarketService.searchMarkets(query));
      if (err) throwError(err);
      dispatch(activeMarketsSuccess(response.content));
    } catch (error) {
      dispatch(marketError(error, LOADING.ACTIVE_MARKET_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getAllMarkets(query) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.getAllMarkets());
      if (err) throwError(err);
      dispatch(
        getAllMarketsSuccess({ list: response /* , pagination: { ...query, totalElements: response.total } */ }),
      );
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getMarketsSuggestion(payload) {
  return async dispatch => {
    dispatch(marketSuggestionRequest());
    try {
      const [err, response] = await to(MarketService.suggestions(payload));
      if (err) throwError(err);
      dispatch(marketSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(marketSuggestionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function setSelectedMarkets(payload) {
  return async dispatch => {
    try {
      dispatch(
        getAllMarketsSelectedSuccess({
          selectedList: payload /* , pagination: { ...query, totalElements: response.total } */,
        }),
      );
      return payload;
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getMarketById(id) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.getMarketById(id));
      if (err) throwError(err);
      dispatch(getMarketByIdSuccess(response));
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateMarket(id, payload) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.updateMarket(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(getMarketByIdSuccess({}));
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createMarket(payload) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.createMarket(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(getMarketByIdSuccess({}));
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function exportMarkets() {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.exportMarkets());
      if (err) throwError(err);
      downloadFile(response, "Markets.xlsx");
      dispatch(getMarketByIdSuccess({}));
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}

export function createSubMarket(payload) {
  return async dispatch => {
    dispatch(marketRequest());
    try {
      const [err, response] = await to(MarketService.createSubMarket(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(getMarketByIdSuccess({}));
    } catch (error) {
      dispatch(marketError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
