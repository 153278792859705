import { Button, Col, Form, Input, Row, Select, Skeleton, Switch } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { createMarket, getMarketById, getMarketByIdSuccess, updateMarket } from "../../store/actions/market.actions";
import { COUNTRY_LIST, VALIDATE_FORM_MESSAGES_TEMPLATE, mapLabelToT } from "../../utils/constants";
import "./AddEditMarket.scss";
import { sortAlphabetically, validateCode } from "../../utils/utils";

const AddEditMarket = ({ marketId, onSuccess, onCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { value: market, loading } = useSelector(store => store.market);
  const [form] = Form.useForm();
  const [fetchingMarket, setFetchingMarket] = useState(false);

  const onFormFinish = async fieldValues => {
    if (marketId) {
      await dispatch(updateMarket(marketId, fieldValues));
    } else {
      const isActive = fieldValues.isActive || false;
      const values = { ...fieldValues, isActive };
      await dispatch(createMarket(values));
    }
    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(() => {
    const didMount = async () => {
      if (marketId) {
        setFetchingMarket(true);
        await dispatch(getMarketById(marketId));
        setFetchingMarket(false);
      }
    };
    didMount();
  }, []);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [],
  );

  const isValidCode = (rule, value) => {
    if (value && !validateCode(value)) {
      return Promise.reject(new Error(t("AddEditMarket.MarketCodeFormat")));
    }
    return Promise.resolve();
  };

  return (
    <div className="edit-market">
      <Skeleton loading={fetchingMarket} active paragraph={{ rows: 6 }}>
        <Form
          form={form}
          className="edit-market"
          layout="vertical"
          name="nest-messages"
          initialValues={market}
          onFinish={onFormFinish}
          validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
        >
          <Row className="fields-row" gutter={20} type="flex">
            <Col span={12}>
              <Form.Item
                label={t("AddEditMarket.MarketCode")}
                name="marketCode"
                validateFirst
                rules={[{ required: true, whitespace: true }, { validator: isValidCode }]}
              >
                <Input placeholder={t("AddEditMarket.MarketCodePlaceholder")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("AddEditMarket.MarketName")}
                name="marketName"
                validateFirst
                rules={[{ required: true, whitespace: true }]}
              >
                <Input placeholder={t("AddEditMarket.MarketName")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("Property.Country")} name="country" rules={[{ required: true }]}>
                <Select
                  placeholder={t("AddEditMarket.CountryPlaceholder")}
                  options={sortAlphabetically(mapLabelToT(COUNTRY_LIST, "COUNTRY_LIST"))}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("AddEditMarket.IsActive")} name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row className="fields-row" gutter={20} type="flex" justify="center">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button
                    block
                    onClick={() => {
                      onCloseModal(false);
                      dispatch(getMarketByIdSuccess({}));
                    }}
                  >
                    {t("Buttons.DiscardChanges")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button block type="primary" htmlType="submit" loading={loading}>
                    {marketId ? t("Buttons.SaveChanges") : t("Buttons.Create")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </div>
  );
};

AddEditMarket.propTypes = {
  marketId: PropTypes.string,
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default AddEditMarket;
