import { Table } from "antd";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { DEFAULT_SORTER } from "../../utils/constants";
import { queryGenerator } from "../../utils/utils";
import CustomIcon from "../CustomIcon/CustomIcon";
import TableSearchFilter from "../TableSearchFilter/TableSearchFilter";
import "./ListView.scss";

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const ListView = props => {
  const {
    columns,
    dataSource,
    deleteAllData,
    getList,
    loading,
    pagination,
    rowKey,
    scroll,
    size,
    rowSelection,
    isDraggable,
    dragTableData,
    setDragTableData,
  } = props;

  const [rowsSelected, setRowsSelected] = useState([]);

  // For api query
  const [sorterFilterPage, setSorterFilterPage] = useState(() => {
    let cPage = _get(pagination, "number");
    cPage = _isNumber(cPage) ? cPage + 1 : 1;
    return {
      currentPage: cPage,
      pageSize: _get(pagination, "size", 20),
      sorter: DEFAULT_SORTER,
      filters: {},
    };
  });
  // For antd pagination
  const [_pagination, setPagination] = useState({
    current: sorterFilterPage.currentPage,
    showSizeChanger: true,
    position: "bottom",
    pageSize: sorterFilterPage.pageSize,
    showQuickJumper: true,
    ...pagination,
  });
  useEffect(() => {
    const _query = queryGenerator(sorterFilterPage);
    if (getList) getList(_query);
  }, []);

  const onSelectChange = selectedRowKeys => {
    const selectedCount = selectedRowKeys.length || 0;
    setRowsSelected(selectedRowKeys);
  };

  const resetStates = () => {
    setRowsSelected([]);
  };

  const _rowSelection = !_isEmpty(rowSelection) ? rowSelection : false;
  const hasCount = rowsSelected.length;

  const handleTableChange = async (pageInfo, filters, sorter) => {
    const payload = {
      currentPage: pageInfo.current,
      pageSize: pageInfo.pageSize,
      sorter: _isEmpty(sorter) ? DEFAULT_SORTER : sorter,
      filters,
    };
    if (_pagination.current !== pageInfo.current) setRowsSelected([]);
    setSorterFilterPage(payload);
    const query = queryGenerator(payload);
    if (getList) await getList(query);
    setPagination(pageInfo);
  };

  const paginationControl = () => ({
    // current: sorterFilterPage.currentPage,
    // total: _get(pagination, "totalElements", 0),
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: _pagination.showSizeChanger,
    position: _pagination.position,
    defaultPageSize: 20,
    // pageSize: _pagination.pageSize,
    showQuickJumper: _pagination.showQuickJumper,
    // onShowSizeChange: (current, _size) => {
    //   const paginationWithUpdatedPageSize = _pagination;
    //   paginationWithUpdatedPageSize.pageSize = _size;
    //   setPagination(paginationWithUpdatedPageSize);
    // },
  });

  const _dataSource = _isArray(dataSource) ? dataSource : [];

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: options => <TableSearchFilter {...options} dataIndex={dataIndex} placeholder={placeholder} />,
    filterIcon: filtered => <CustomIcon name="SearchOutlined" className={`${filtered ? "active" : ""}`} />,
    onFilter: (value, record) => {
      if (dataIndex.constructor.name === "Array") {
        const recordKey = dataIndex.join(".");
        const label = _get(record, recordKey);
        return label ? label.toString().toLowerCase().includes(value.toLowerCase()) : "";
      }
      return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "";
    },
  });

  // const selectRow = key => {
  //   let selectedRowKeys = rowsSelected;
  //   if (selectedRowKeys.includes(key)) {
  //     selectedRowKeys = _filter(selectedRowKeys, i => i !== key);
  //   } else {
  //     selectedRowKeys.push(key);
  //   }
  //   setRowsSelected([...selectedRowKeys]);
  // };

  const getColumns = () =>
    columns.map(item =>
      item.columnFilter ? { ...item, ...getColumnSearchProps(item.dataIndex, item.title) } : { ...item },
    );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const _newData = arrayMoveImmutable(dragTableData.slice(), oldIndex, newIndex).filter(el => !!el);
      setDragTableData(_newData);
    }
  };
  const DraggableContainer = _props => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {..._props} />
  );
  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dragTableData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Fragment>
      <div className="listView">
        {isDraggable ? (
          <Table
            {...props}
            rowSelection={_rowSelection}
            showSorterTooltip={false}
            dataSource={dragTableData}
            columns={getColumns()}
            size={size}
            pagination={paginationControl()}
            loading={loading}
            rowKey="index"
            scroll={scroll}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          ></Table>
        ) : (
          <Table
            {...props}
            rowSelection={_rowSelection}
            showSorterTooltip={false}
            dataSource={_dataSource}
            columns={getColumns()}
            size={size}
            // onChange={handleTableChange}
            pagination={paginationControl()}
            loading={loading}
            rowKey={rowKey || columns[0].key}
            scroll={scroll}
          ></Table>
        )}
      </div>
    </Fragment>
  );
};

ListView.defaultProps = {
  dataSource: [],
  rowSelection: {},
  size: "middle",
  pagination: {},
  scroll: null,
};

ListView.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  deleteAllData: PropTypes.func,
  getList: PropTypes.func,
  rowSelection: PropTypes.shape({
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
  }),
  size: PropTypes.string,
  rowKey: PropTypes.string,
  pagination: PropTypes.object,
  columns: PropTypes.array.isRequired,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.string,
  }),
  isDraggable: PropTypes.bool,
  dragTableData: PropTypes.array,
  setDragTableData: PropTypes.func,
};

export default ListView;
