import DivisionService from "../../services/division.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  DIVISION_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function divisionRequest(key = LOADING.DIVISION_LOADING) {
  return { type: ACTIONS.DIVISION_REQUEST, key };
}

export function divisionSuccess(response) {
  return { type: ACTIONS.DIVISION_SUCCESS, response };
}

export function divisionSuggestionSuccess() {
  return { type: ACTIONS.DIVISION_SUGGESTION_SUCCESS };
}

export function divisionListSuccess(response) {
  return { type: ACTIONS.DIVISION_LIST_SUCCESS, response };
}

export function divisionSelectedListSuccess(response) {
  return { type: ACTIONS.DIVISION_SELECTED_LIST_SUCCESS, response };
}

export function divisionError(error, key = LOADING.DIVISION_LOADING) {
  return { type: ACTIONS.DIVISION_ERROR, error, key };
}

export function getDivisions(query) {
  return async dispatch => {
    dispatch(divisionRequest());
    try {
      const [err, response] = await to(DivisionService.search(query));
      if (err) throwError(err);
      dispatch(
        divisionListSuccess({ list: response.content, pagination: { ...query, totalElements: response.total } }),
      );
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getAllDivisions(query) {
  return async dispatch => {
    dispatch(divisionRequest());
    try {
      const [err, response] = await to(DivisionService.get());
      if (err) throwError(err);
      dispatch(divisionListSuccess({ list: response /* , pagination: { ...query, totalElements: response.total } */ }));
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function setDivisions(payload) {
  return async dispatch => {
    try {
      dispatch(divisionListSuccess({ list: payload /* , pagination: { ...query, totalElements: response.total } */ }));
      return payload;
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getDivisionSuggestion(payload) {
  return async dispatch => {
    dispatch(divisionRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(DivisionService.suggestions(payload));
      if (err) throwError(err);
      dispatch(divisionSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(divisionError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getDivisionById(id) {
  return async dispatch => {
    dispatch(divisionRequest());
    try {
      const [err, response] = await to(DivisionService.getById(id));
      if (err) throwError(err);
      dispatch(divisionSuccess(response));
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateDivision(id, payload) {
  return async dispatch => {
    dispatch(divisionRequest());
    try {
      const [err, response] = await to(DivisionService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(divisionSuccess({}));
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createDivision(payload) {
  return async dispatch => {
    dispatch(divisionRequest());
    try {
      const [err, response] = await to(DivisionService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(divisionSuccess({}));
    } catch (error) {
      dispatch(divisionError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
