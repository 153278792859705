import { Col, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import HomeTotalAndPercentageBox from "../../components/HomeTotalAndPercentageBox/HomeTotalAndPercentageBox";
import FilterPropertyHorizontal from "../FilterPropertyHorizontal/FilterPropertyHorizontal";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useHomePagination, useLanguage, useHomeFilterTags } from "../../hooks";
import { getAnnouncement } from "../../store/actions/announcement.action";
import {
  ANNOUNCEMENT_ID,
  HELPER_TEXT_KEYS,
  HOME_PROPERTIES_TYPE,
  ROUTES,
  PAGINATOIN,
  TAG,
  DEFAULT_PROPERTY_SORTER,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { getDangerouslySetHTML, getTranslatedHelperText, getFeatureFlags } from "../../utils/utils";
import Announcements from "../Announcements/Announcements";

import "./Home.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { language } = useLanguage();

  const { unitsToReview, activeAudits, marketableUnits, marketableUnitsCount } = useSelector(state => state.home);
  const { value: announcement } = useSelector(state => state.announcement);
  const { lookupList } = useSelector(state => state.helperText);

  const { homePagination, homeTags } = useSelector(state => state.home);
  const { homeFilterPagination } = useHomePagination({
    pagination: homePagination,
    paginationType: PAGINATOIN.home,
  });
  const { homeFilterTag } = useHomeFilterTags({ tags: homeTags, tagType: TAG.home });

  const canAccessAdmin = permissionsUtil.checkAuth("user_view");

  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    getAllDashboardData();
  }, []);

  const getAllDashboardData = async () => {
    try {
      setIsLoading(true);
      await dispatch(getAnnouncement(ANNOUNCEMENT_ID));
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const redirectToPublish = type => {
    if (type === HOME_PROPERTIES_TYPE.openAuditIssues) {
      history.push(`${ROUTES.OPEN_AUDIT_ISSUES.path}/${type}`);
    } else {
      history.push(`${ROUTES.PROPERTIES_TO_REVIEW.path}/${type}`);
    }
  };

  const redirectToNotMarket = type => {
    history.push(`${ROUTES.UNITS_TO_REVIEW.path}/${type}`);
  };

  return (
    <>
      {isLoading ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <div className="home">
        {(canAccessAdmin && <Announcements />) || (announcement?.isEnable && <Announcements />)}
        <PageTitle title={ROUTES.HOME.title} />
        <Row justify="center">
          <Col xs={24} className="home-dropdowns">
            <FilterPropertyHorizontal
              defaultSort={DEFAULT_PROPERTY_SORTER}
              shouldFetchData={shouldFetchData}
              homePagination={homeFilterPagination}
              homeTags={homeFilterTag}
              setIsLoading={setIsLoading}
            />
          </Col>
          <Col xs={24} md={24} xl={24} xxl={18} className="total-units">
            <Row justify="space-between" className="units-details">
              <Col xs={24} md={12} xl={12} xxl={12} align="center">
                <p className="font-w-500 font-20">
                  {t("Home.UnitsToReview")}
                  {!_isEmpty(lookupList[HELPER_TEXT_KEYS.home.unitstoReview]) && (
                    <span className="font-14 font-w-400 mg-left-6">
                      <Tooltip
                        title={getDangerouslySetHTML(
                          getTranslatedHelperText(lookupList[HELPER_TEXT_KEYS.home.unitstoReview], language),
                        )}
                      >
                        <CustomIcon name="InfoCircleOutlined" />
                      </Tooltip>
                    </span>
                  )}
                </p>
                <span className="font-bold font-40">{unitsToReview?.totalUnitsToReview || "0"}</span>
              </Col>
              <Col xs={24} md={12} xl={12} xxl={12} align="center">
                <p className="font-w-500 font-20 pad-left-120 pad-right-120 line-h-24 mg-bottom-4">
                  {t("Home.MarketableUnits")}
                  {!_isEmpty(lookupList[HELPER_TEXT_KEYS.home.marketableUnitsNotOnPropertySearch]) && (
                    <span className="font-14 font-w-400 mg-left-6">
                      <Tooltip
                        title={getDangerouslySetHTML(
                          getTranslatedHelperText(
                            lookupList[HELPER_TEXT_KEYS.home.marketableUnitsNotOnPropertySearch],
                            language,
                          ),
                        )}
                      >
                        <CustomIcon name="InfoCircleOutlined" />
                      </Tooltip>
                    </span>
                  )}
                  <br />
                  {t("Home.PropertySearch")}
                </p>
                <div className="ant-col ant-col-xs-24 ant-col-md-12 ant-col-xl-12 ant-col-xxl-12">
                  <div className="container">
                    <div className="mg-bottom-1">
                      <h4>Total</h4>
                      <span className="font-bold font-40 color-black">
                        {(marketableUnitsCount && `${parseFloat(marketableUnitsCount).toFixed(0)}`) || "0"}
                      </span>
                    </div>
                    <div className="mg-bottom-1">
                      <h4>Percent</h4>
                      <span className="font-bold font-40 color-black">
                        {(marketableUnits && `${parseFloat(marketableUnits).toFixed(0)}%`) || "0"}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={20} xl={24} xxl={18} className="mg-top-12">
            <Row>
              <Col xs={24} md={24} xl={12} xxl={12} className="vertical-divider">
                <HomeTotalAndPercentageBox
                  title={t("Home.CurrentVacantUnits")}
                  total={unitsToReview?.totalVacantUnits}
                  percent={unitsToReview?.vacantPercentage}
                  actionText={t("Nav.Publish")}
                  secActionText={t("Buttons.Unmarket")}
                  type={HOME_PROPERTIES_TYPE.vacantProperties}
                  redirectToPublish={redirectToPublish}
                  redirectToNotMarket={redirectToNotMarket}
                  tooltip={lookupList[HELPER_TEXT_KEYS.home.currentVacantUnits]}
                />
                <HomeTotalAndPercentageBox
                  title={t("Home.UpcomingVacantUnits")}
                  total={unitsToReview?.availableInYear}
                  percent={unitsToReview?.availableInYearPercentage}
                  actionText={t("Nav.Publish")}
                  type={HOME_PROPERTIES_TYPE.upcomingProperties}
                  secActionText={t("Buttons.Unmarket")}
                  redirectToPublish={redirectToPublish}
                  redirectToNotMarket={redirectToNotMarket}
                  tooltip={lookupList[HELPER_TEXT_KEYS.home.upcomingVacantUnits]}
                />
              </Col>
              <Col xs={24} md={24} xl={12} xxl={12}>
                <HomeTotalAndPercentageBox
                  title={t("Home.NotMarketingOngoingReview")}
                  total={unitsToReview?.totalUnmarketUnits}
                  percent={unitsToReview?.unMarketedPercentage}
                  actionText={t("Nav.Publish")}
                  secActionText={t("Buttons.Unmarket")}
                  type={HOME_PROPERTIES_TYPE.unmnarketedProperties}
                  redirectToPublish={redirectToPublish}
                  redirectToNotMarket={redirectToNotMarket}
                  tooltip={lookupList[HELPER_TEXT_KEYS.home.notMarketingOngoingReview]}
                />
                <HomeTotalAndPercentageBox
                  title={t("Home.UnitsLandActiveAudits")}
                  total={activeAudits?.totalopenAudits}
                  actionText={t("Home.ViewAll")}
                  type={HOME_PROPERTIES_TYPE.openAuditIssues}
                  redirectToPublish={redirectToPublish}
                  redirectToNotMarket={redirectToNotMarket}
                  tooltip={lookupList[HELPER_TEXT_KEYS.home.propertiesActiveAudits]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

Home.propTypes = {};

export default Home;
