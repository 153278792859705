import { Checkbox, Tooltip } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { useLanguage } from "../../hooks";
import { getDangerouslySetHTML, getTranslatedHelperText } from "../../utils/utils";
import CustomIcon from "../CustomIcon/CustomIcon";
import "./LabelWithCheckbox.scss";

const LabelWithCheckbox = ({ viewOnlyMode, label, tooltip, ...props }) => {
  const { language } = useLanguage();

  return (
    <div className="label-with-checkbox">
      <Checkbox disabled={viewOnlyMode} {...props}>
        {label}
      </Checkbox>
      {!_isEmpty(tooltip) && !_isEmpty(tooltip.helperText) && (
        <Tooltip title={getDangerouslySetHTML(getTranslatedHelperText(tooltip, language))}>
          <CustomIcon name="InfoCircleOutlined" />
        </Tooltip>
      )}
    </div>
  );
};

LabelWithCheckbox.propTypes = {
  viewOnlyMode: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LabelWithCheckbox;
