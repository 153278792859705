import { Empty, Select, Spin } from "antd";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React from "react";
import { SEARCH_QUERY } from "../../utils/constants";
import { getContactLabel } from "../../utils/property.util";
import { validateAlphaNumeric, validateAlphaNumericWithoutSpace } from "../../utils/utils";

const { Option } = Select;

class CustomSelect extends React.Component {
  state = {
    data: this.props.optionsList || [],
    value: this.props.value || undefined,
    searchValue: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!this.state.value && !_isEqual(nextProps.value, this.state.value)) {
      this.setState({ value: nextProps.value });
    }

    // For Custom Contact Options
    if (!_isEqual(nextProps.optionsList, this.props.optionsList)) {
      this.setState({ data: nextProps.optionsList });
    }

    // For Updating Broker
    if (this.props.updateValue && !_isEqual(nextProps.value, this.state.value)) {
      this.setState({ value: nextProps.value }, () => this.props.setIsUpdated(false));
    }
  }

  validateInput = val => {
    if (this.props.allowSpace) {
      return validateAlphaNumeric(val);
    }
    return validateAlphaNumericWithoutSpace(val);
  };

  onSearch = val => {
    if (!this.validateInput(val)) {
      this.setState(prev => ({ searchValue: prev.searchValue || "" }));
      return;
    }
    this.setState({ searchValue: val });
    this.onChangeValue(val);
  };

  onChangeValue = _debounce(async value => {
    let query = {
      ...SEARCH_QUERY,
      keyword: value,
    };
    if (this.props.queryParam) {
      query = { ...query, ...this.props.queryParam };
    }

    this.props.fetchOptions(query);
  }, 500);

  handleChange = value => {
    if (this.props.onChange) this.props.onChange(value);
    this.setState({ value });
  };

  handleBlur = () => {
    this.setState({ searchValue: undefined });
    if (this.props.isMulti && this.state.searchValue) this.onChangeValue();
  };

  _renderOptions = item => {
    if (this.props.valuePropName) {
      return this.props.isContact ? getContactLabel(item) : item[this.props.valuePropName];
    }
    return `${item.id}`;
  };

  _notFoundContent = () => {
    if (this.props.loading) {
      return (
        <div className="text-center">
          <Spin size="default" />
        </div>
      );
    }
    if (!this.props.loading && this.state.searchValue && this.state.searchValue.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return null;
  };

  render() {
    const { data } = this.state;
    const options = _map(data, d => {
      if (this.props.renderOptions) return this.props.renderOptions(d);
      return (
        <Option key={d.id} type={d.type}>
          {this._renderOptions(d)}
        </Option>
      );
    });

    return (
      <Select
        showSearch
        value={this.state.value}
        searchValue={this.state.searchValue}
        labelInValue
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={this.props.showArrow}
        filterOption={false}
        onSearch={this.onSearch}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        notFoundContent={this._notFoundContent()}
        mode={this.props.isMulti ? "multiple" : ""}
        disabled={this.props.disabled}
        loading={this.props.loading}
        onSelect={this.props.onSelect}
        onClear={this.props.onClear}
        allowClear={this.props.allowClear}
      >
        {options}
      </Select>
    );
  }
}

CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  optionsList: PropTypes.array,
  loading: PropTypes.bool,
  fetchOptions: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  renderOptions: PropTypes.func,
  valuePropName: PropTypes.string,
  queryParam: PropTypes.object,
  allowSpace: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  showArrow: PropTypes.bool,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  isContact: PropTypes.bool,
  updateValue: PropTypes.bool,
  setIsUpdated: PropTypes.func,
};

export default CustomSelect;
