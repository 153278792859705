import SubMarketService from "../../services/subMarket.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  SUB_MARKET_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function subMarketRequest(key = LOADING.SUB_MARKET_LOADING) {
  return { type: ACTIONS.SUB_MARKET_REQUEST, key };
}

export function subMarketSuccess(response) {
  return { type: ACTIONS.SUB_MARKET_SUCCESS, response };
}

export function subMarketSuggestionSuccess() {
  return { type: ACTIONS.SUB_MARKET_SUGGESTION_SUCCESS };
}

export function subMarketListSuccess(response) {
  return { type: ACTIONS.SUB_MARKET_LIST_SUCCESS, response };
}

export function subMarketSelectedListSuccess(response) {
  return { type: ACTIONS.SUB_MARKET_SELECTED_LIST_SUCCESS, response };
}

export function activeSubMarketsSuccess(response) {
  return { type: ACTIONS.ACTIVE_SUB_MARKET_SUCCESS, response };
}

export function subMarketError(error, key = LOADING.SUB_MARKET_LOADING) {
  return { type: ACTIONS.SUB_MARKET_ERROR, error, key };
}

export function getSubMarkets(query) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.search(query));
      if (err) throwError(err);
      dispatch(
        subMarketListSuccess({ list: response.content, pagination: { ...query, totalElements: response.total } }),
      );
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function setSubMarkets(payload) {
  return async dispatch => {
    try {
      dispatch(subMarketListSuccess({ list: payload /* , pagination: { ...query, totalElements: response.total } */ }));
      return payload;
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function setSelectedSubMarkets(payload) {
  return async dispatch => {
    try {
      dispatch(
        subMarketSelectedListSuccess({
          selectedList: payload /* , pagination: { ...query, totalElements: response.total } */,
        }),
      );
      return payload;
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getActiveSubMarkets(query) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.search(query));
      if (err) throwError(err);
      dispatch(activeSubMarketsSuccess(response.content));
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getAllSubMarkets(query) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.get());
      if (err) throwError(err);
      dispatch(
        subMarketListSuccess({ list: response /* , pagination: { ...query, totalElements: response.total } */ }),
      );
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getSubMarketSuggestion(payload) {
  return async dispatch => {
    dispatch(subMarketRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(SubMarketService.suggestions(payload));
      if (err) throwError(err);
      dispatch(subMarketSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(subMarketError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getSubMarketById(id) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.getById(id));
      if (err) throwError(err);
      dispatch(subMarketSuccess(response));
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateSubMarket(id, payload) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(subMarketSuccess({}));
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createSubMarket(payload) {
  return async dispatch => {
    dispatch(subMarketRequest());
    try {
      const [err, response] = await to(SubMarketService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(subMarketSuccess({}));
    } catch (error) {
      dispatch(subMarketError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
