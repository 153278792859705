import { Button, Col, Row, Tag, Tooltip, Form, Checkbox, Dropdown, Menu } from "antd";
import dayjs from "dayjs";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _find from "lodash/find";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import EditableTagGroup from "../../components/EditableTagGroup/EditableTagGroup";
import PageTitle from "../../components/PageTitle/PageTitle";
import SearchListView from "../../components/SearchListView/SearchListView";
import AppModal from "../../components/AppModal/AppModal";
import UnmarketForm from "../UnmarketForm/UnmarketForm";
import { usePropertyFilterTags, usePropertyPagination } from "../../hooks";
import {
  getLandVacancyList,
  landVacancyMarketLand,
  landVacancyTagsSuccess,
  landVacancyUnmarketLand,
} from "../../store/actions/landVacancy.actions";
import {
  DATE_FORMAT_TIME,
  DEFAULT_PROPERTY_SORTER,
  FIELD_TYPE,
  MARKET_TYPE_KEYS,
  PAGINATOIN,
  PROPERTY_STATUS,
  PROPERTY_STATUS_UI_MAPPING,
  PROPERTY_TYPE_UI_MAPPING,
  ROUTES,
  SORT_DIRECTION,
  SUGGESTION_SEARCH_STATE,
  TAG,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { renderPropertyStatus, renderUnmarketDateAndLabel, wrapUnmarketInTag } from "../../utils/property.util";
import { setSessionStorage, toastMessage } from "../../utils/utils";
import SearchPropertyHorizontal from "../SearchPropertyHorizontal/SearchPropertyHorizontal";
import { getAllUnmarketReasons } from "../../store/actions/unmarketReasons.action";
import "./LandVacancy.scss";

const LandVacancy = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { loading, list, pagination, tags } = useSelector(state => state.landVacancy);
  console.log("list: ", list);
  const { loading: loadingMarket } = useSelector(state => state.market);

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [landsToBeUnmarket, setLandsToBeUnmarket] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [nonMarketLands, setNonMarketLands] = useState([]);
  const [marketModalOpen, setMarketModalOpen] = useState(false);
  const [landsToBeMarket, setLandsToBeMarket] = useState([]);

  const [reasonToBeEdit, setReasonToBeEdit] = useState([]);
  const [isEditUnmarketReason, setIsEditUnmarketReason] = useState(false);
  const { propertyTag } = usePropertyFilterTags({ tags, tagType: TAG.landVacancy });
  const { propertyPagination } = usePropertyPagination({ pagination, paginationType: PAGINATOIN.landVacancy });

  const canUpdateLandVacancy = permissionsUtil.checkAuth("land_vacancy_update");
  const unmarketReasons = useSelector(state => ({
    ...state.unmarketReason,
  }));

  useEffect(() => {
    getUnmarketReasons();
  }, []);

  /**
   * To filter the list of non market lands
   */
  useEffect(() => {
    setNonMarketLands(list?.filter(land => land.isUnmarket));
  }, [list]);

  /**
   * To get the list of unmarket reasons
   */
  const getUnmarketReasons = async () => {
    setLoadingData(true);
    setLandsToBeUnmarket([]);
    try {
      await dispatch(getAllUnmarketReasons());
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * To get the list of lands
   */
  const getList = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getLandVacancyList(_query));
      /**
       * When a filter is changed or an API is called, this line will remove the checked lands
       */
      getRowSelection().onChange(null, null, true);
    } catch (ex) {
      console.log(ex);
    }
  };

  /**
   * Wrapper method to trigger when updates are made
   * to filter tags
   */
  const onUpdateTags = async _tags => {
    setSessionStorage(TAG.landVacancy, []);
    setShouldFetchData(true);
    await dispatch(landVacancyTagsSuccess(_tags));
    setShouldFetchData(false);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows, isFilterApply) => {
    if (isFilterApply) {
      setLandsToBeUnmarket([]);
    } else {
      setLandsToBeUnmarket(selectedRows);
    }
  };

  const getRowSelection = () =>
    canUpdateLandVacancy
      ? {
          onChange: onSelectChange,
          getCheckboxProps: record => ({
            disabled: record.status === PROPERTY_STATUS.published || record.isUnmarket,
          }),
          selectedRowKeys: landsToBeUnmarket.map(land => land.id),
        }
      : null;

  const _onSelectLandsToMarket = selectedLands => {
    setLandsToBeMarket([...selectedLands]);
  };

  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);
  const toggleMarketModalVisibility = () => setMarketModalOpen(!marketModalOpen);
  const toggleEditReasonModalVisibility = () => setIsEditUnmarketReason(!isEditUnmarketReason);

  const onEditReasonModalClose = () => {
    toggleEditReasonModalVisibility();
    setReasonToBeEdit([]);
  };

  const onEditReason = () => {
    toggleEditReasonModalVisibility();
  };

  const onNextToEditReason = async () => {
    toggleEditReasonModalVisibility();
    setIsModalOpen(true);
  };

  const onSelectUnitsToEditReason = selectedLands => {
    setReasonToBeEdit([...selectedLands]);
  };

  const _onClose = () => {
    form.resetFields();
    setReasonToBeEdit([]);
    toggleModalVisibility();
  };

  const onMarketModalClose = () => {
    toggleMarketModalVisibility();
    setLandsToBeMarket([]);
  };

  /**
   * Display the unmarket status of a land
   */
  const _renderUnmarketLandStatus = _land => _land?.isUnmarket && wrapUnmarketInTag(_land.lastUnmarketDate);

  /**
   * Function for generating a payload
   * and invoking the API for unmarket lands
   */
  const onLandsUnmarketSubmit = async () => {
    const _landInfo = form.getFieldValue();
    setLoadingData(true);

    return form
      .validateFields()
      .then(async () => {
        const _lands = !_isEmpty(reasonToBeEdit) ? reasonToBeEdit : landsToBeUnmarket;
        const properties = _lands.map(land => {
          const _land = _landInfo[land.id];

          if (_landInfo?.isSameReason) {
            const _sameUnmarketReason = _find(
              unmarketReasons.list,
              resason => resason.id === _landInfo.sameReason.value,
            );
            const _data = {
              id: land.id,
              propertyCode: land.propertyCode,
              unmarketReason: _landInfo.sameReason.value,
              unmarketReasonLabel: _sameUnmarketReason.unmarketingReason,
              status: land.status,
              ...(_landInfo.sameReason?.unmarketAdditionalInformation && {
                unmarketAdditionalInformation: _landInfo.sameReason.unmarketAdditionalInformation,
              }),
              ...(_landInfo.sameReason?.unmarketLeaseDate && {
                unmarketLeaseDate: _landInfo.sameReason.unmarketLeaseDate
                  ? dayjs(_landInfo.sameReason.unmarketLeaseDate).valueOf()
                  : undefined,
              }),
              dateFieldLabel: _sameUnmarketReason.dateFieldLabel,
            };

            return { ..._data };
          }

          const _unmarketReason = _find(unmarketReasons.list, resason => resason.id === _land.value);

          const _data = {
            id: land.id,
            propertyCode: land.propertyCode,
            unmarketReason: _land.value,
            unmarketReasonLabel: _unmarketReason.unmarketingReason,
            status: land.status,
            ...(_land?.unmarketAdditionalInformation && {
              unmarketAdditionalInformation: _land.unmarketAdditionalInformation,
            }),
            ...(_land?.unmarketLeaseDate && {
              unmarketLeaseDate: _land.unmarketLeaseDate ? dayjs(_land.unmarketLeaseDate).valueOf() : undefined,
            }),
            dateFieldLabel: _unmarketReason.dateFieldLabel,
          };
          return { ..._data };
        });

        const values = {
          properties,
        };
        await dispatch(landVacancyUnmarketLand(values));
        setReasonToBeEdit([]);
        getList(pagination);
        setIsModalOpen(false);
        form.resetFields();
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        toastMessage("error", errorFields?.[0]?.errors?.[0]);
      })
      .finally(() => setLoadingData(false));
  };

  /**
   * Function for generating a payload
   * and invoking the API for market lands
   */
  const onLandsMarketSubmit = async () => {
    const payload = {
      properties: landsToBeMarket,
    };
    try {
      setLoadingData(true);
      await dispatch(landVacancyMarketLand(payload));
      getList(pagination);
      setLandsToBeMarket([]);
      setMarketModalOpen(false);
    } catch (ex) {
      console.log("ex", ex);
      const { errorFields } = ex;
      toastMessage("error", errorFields?.[0]?.errors?.[0]);
    } finally {
      setLoadingData(false);
    }
  };

  const columns = [
    {
      title: t("PropertiesTable.PropertyCode"),
      dataIndex: "propertyCode",
      key: "propertyCode",
      fixed: "left",
      width: 150,
      render: (text, record) => (
        <>
          <a>{text}</a>
          {record?.isDraft && (
            <>
              <Tag className="text-capitalize float-right" color="warning">
                {t("PropertyStatusUiMapping.draft")}
              </Tag>
            </>
          )}
        </>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyCode" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Name"),
      dataIndex: "marketingPropertyName",
      key: "marketingPropertyName",
      ellipsis: true,
      width: 170,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketingPropertyName" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.StreetAddress"),
      dataIndex: "streetAddress1",
      key: "streetAddress1",
      ellipsis: true,
      width: 170,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "streetAddress1" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      render: text => renderPropertyStatus(text),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "status" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Market"),
      dataIndex: "market",
      key: "market",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "market" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          <Tag className="ellipsis" color="geekblue">
            {text}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: t("UnitVacancy.NotMarketingStateWithTimeStamp"),
      key: "lastUnmarketDate",
      dataIndex: "lastUnmarketDate",
      width: 300,
      render: (text, record) => _renderUnmarketLandStatus(record),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "lastUnmarketDate" && sortInfo?.order,
    },
    {
      title: t("UnitVacancy.NotMarketingReason"),
      key: "unmarketReason",
      dataIndex: "unmarketReason",
      width: 240,
      render: (text, record) => record?.unmarketReason?.unmarketingReason || record?.unmarketReason || "",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "unmarketReason" && sortInfo?.order,
    },
    {
      title: t("UnitVacancy.AdditionalInformation"),
      key: "unmarketAdditionalInformation",
      dataIndex: "unmarketAdditionalInformation",
      width: 300,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "unmarketAdditionalInformation" && sortInfo?.order,
      render: (text, record) => record.unmarketAdditionalInformation && text,
    },
    {
      title: t("UnitVacancy.UnavailableReasonDate"),
      key: "unmarketLeaseDate",
      dataIndex: "unmarketLeaseDate",
      width: 250,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "unmarketLeaseDate" && sortInfo?.order,
      render: (text, record) => renderUnmarketDateAndLabel(record),
    },
    {
      title: t("PropertiesTable.LastModifiedDate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      fixed: "right",
      width: 150,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
      sorter: true,
      sortDirections: SORT_DIRECTION.threeDirection,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
    },
  ];

  /**
   * Menu items for market unmarket land Dropdown
   */
  const menu = (
    <Menu>
      <Menu.Item
        key={MARKET_TYPE_KEYS.market}
        onClick={toggleMarketModalVisibility}
        disabled={_isEmpty(nonMarketLands)}
      >
        {t("Buttons.Marketing")}
      </Menu.Item>
      <Menu.Item key={MARKET_TYPE_KEYS.market} onClick={onEditReason} disabled={_isEmpty(nonMarketLands)}>
        {t("Buttons.EditReason")}
      </Menu.Item>
      <Menu.Item key={MARKET_TYPE_KEYS.unmarket} onClick={toggleModalVisibility} disabled={_isEmpty(landsToBeUnmarket)}>
        {t("Buttons.Unmarket")}
      </Menu.Item>
    </Menu>
  );

  const loadingTable = loading || loadingMarket;

  return (
    <div className="land-vacancy">
      <PageTitle title={ROUTES.LAND_VACANCY.title} />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={21}>
          <SearchPropertyHorizontal
            defaultSort={DEFAULT_PROPERTY_SORTER}
            getList={getList}
            shouldFetchData={shouldFetchData}
            pagination={propertyPagination}
            tags={propertyTag}
            propertyType={FIELD_TYPE.land}
            searchState={SUGGESTION_SEARCH_STATE.landVacancy}
          />
        </Col>
        <Col xs={24} lg={3}>
          {canUpdateLandVacancy && (
            <Dropdown overlay={menu}>
              <Button block className="land-market-unmarket-btn">
                {t("Properties.Menu.Actions")}
                &nbsp;
                <CustomIcon name="DownOutlined" />
              </Button>
            </Dropdown>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]} className="row-vertical-padding-8">
            <Col xs={16} sm={22} md={24} lg={24} xl={24} xxl={24}>
              <EditableTagGroup tags={propertyTag} updateTags={onUpdateTags} />
            </Col>
          </Row>
          <SearchListView
            paginationKey={PAGINATOIN.landVacancy}
            defaultSort={DEFAULT_PROPERTY_SORTER}
            preventInitialFetchList
            setFilterInfo={setFilterInfo}
            setSortInfo={setSortInfo}
            dataSource={list}
            columns={columns}
            loading={loadingTable}
            rowKey="id"
            getList={getList}
            pagination={pagination}
            scroll={{ x: 1450, y: "calc(100vh - 460px)" }}
            rowSelection={getRowSelection()}
          />
        </Col>
      </Row>
      <AppModal
        title={t("UnitUnmarket.Title", { propertyType: FIELD_TYPE.land })}
        width={700}
        minHeight="20vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={isModalOpen}
        onCancel={_onClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block loading={loadingData} onClick={onLandsUnmarketSubmit} type="primary">
                {t("Buttons.Save")}
              </Button>
            </Col>
          </Row>
        }
      >
        <UnmarketForm
          form={form}
          unmarketData={!_isEmpty(reasonToBeEdit) ? reasonToBeEdit : landsToBeUnmarket}
          propertyType={FIELD_TYPE.land}
        />
      </AppModal>

      <AppModal
        title={t("UnitMarket.MarketTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.land })}
        width={800}
        minHeight="40vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={marketModalOpen}
        onCancel={onMarketModalClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={onMarketModalClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                disabled={_isEmpty(landsToBeMarket)}
                loading={loadingData}
                type="primary"
                onClick={onLandsMarketSubmit}
              >
                {t("Buttons.Market", { propertyType: PROPERTY_TYPE_UI_MAPPING.land })}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Checkbox.Group
              className="block"
              name="landsToBeMarket"
              value={landsToBeMarket}
              onChange={_onSelectLandsToMarket}
            >
              <Row gutter={[18, 18]}>
                {_map(nonMarketLands, (val, key) => (
                  <Col xs={24} key={key}>
                    <Row gutter={[8, 8]}>
                      <Col xs={6}>
                        <Checkbox key={key} value={val?.id}>
                          {val?.id}
                        </Checkbox>
                      </Col>
                      <Col xs={6}>{renderPropertyStatus(val?.status)}</Col>
                      <Col xs={10}>{_renderUnmarketLandStatus(val)}</Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </AppModal>
      <AppModal
        title={t("UnitUnmarket.EditReasonTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.land })}
        width={800}
        minHeight="40vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={isEditUnmarketReason}
        onCancel={onEditReasonModalClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={onEditReasonModalClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                disabled={_isEmpty(reasonToBeEdit)}
                loading={loadingData}
                type="primary"
                onClick={onNextToEditReason}
              >
                {t("Buttons.Next")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Checkbox.Group
              className="block"
              name="reasonToBeEdit"
              value={reasonToBeEdit}
              onChange={onSelectUnitsToEditReason}
            >
              <Row gutter={[18, 18]}>
                {_map(nonMarketLands, (val, key) => (
                  <Col xs={24} key={key}>
                    <Row gutter={[8, 8]}>
                      <Col xs={6}>
                        <Checkbox key={key} value={val}>
                          {val?.propertyCode}
                        </Checkbox>
                      </Col>
                      <Col xs={6}>{renderPropertyStatus(val?.status)}</Col>
                      <Col xs={10}>{_renderUnmarketLandStatus(val)}</Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </AppModal>
    </div>
  );
};

LandVacancy.defaultProps = {};

LandVacancy.propTypes = {};

export default LandVacancy;
