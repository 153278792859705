import { Form, Spin } from "antd";
import _forEach from "lodash/forEach";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppModal from "../../components/AppModal/AppModal";
import FilePreview from "../../components/FilePreview";
import { useLanguage } from "../../hooks";
import { PROPERTY_STATUS } from "../../utils/constants";
import UnitInfoList from "./UnitInfoList";
import "./UnitInformation.scss";

const UnitInformation = ({
  form,
  isEditView,
  propertyStatus,
  selectedProperty,
  save,
  updateAvailableUnits,
  loading,
  viewOnlyMode,
  notAvailable,
  updateNotAvailableFields,
  syncNotAvailableFields,
  isTranslated,
  setErrorTabs,
  errorTabs,
  activeCollapseKeys,
  setActiveCollapseKeys,
  auditPanelDrawerVisible,
}) => {
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState();

  const [previewModal, setPreviewModal] = useState(false);
  const { language, languageStatus } = useLanguage();

  const previewFile = url => {
    setPreviewModal(true);
    setFileUrl(url);
  };

  const selectedPropertyUnits = useMemo(
    () => _map(selectedProperty.units, u => ({ ...u, value: u.unitId, label: u.id })),
    [selectedProperty.units],
  );

  const getUnitOptions = () => {
    const unitInfo = form.getFieldValue("unitInfo");
    const selectedUnits = unitInfo.reduce((prev, next) => {
      const _unitCodes = next?.unitCode?.map(_unitCode => _unitCode.value) || [];

      return [...prev, ..._unitCodes];
    }, []);

    const updatedUnitOptions = selectedPropertyUnits.map(_unit => {
      const foundUnitOption = unitInfo[0]?.unitOptions?.find(u => u.unitId === _unit.unitId);

      if (foundUnitOption) {
        return {
          ..._unit,
          isAvailable: !selectedUnits.includes(foundUnitOption.unitId),
          value: foundUnitOption.unitId,
          label: foundUnitOption.unitCode,
          unitCode: foundUnitOption.unitCode,
        };
      }
      return {
        ..._unit,
        isAvailable: !selectedUnits.includes(_unit.unitId),
        value: _unit.unitId,
        label: _unit.id,
      };
    });

    return updatedUnitOptions;
  };

  return (
    <div className="unit-information">
      {loading ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <Form.List name="unitInfo">
        {(fields, { add, remove }) => (
          <UnitInfoList
            addUnit={() => {
              const unitOptions = getUnitOptions();
              add({ unitOptions, status: PROPERTY_STATUS.never_published });
              if (!_isEmpty(language.secondary) && isTranslated) {
                let errObj = { ...errorTabs };
                if (languageStatus === "primary") {
                  const _secondaryLang = { ...errObj.secondary };
                  _forEach(language.secondary, _lang => {
                    _secondaryLang[_lang] = {
                      ..._secondaryLang[_lang],
                      unitInfo: true,
                    };
                  });

                  errObj = {
                    ...errObj,
                    secondary: { ..._secondaryLang },
                  };
                } else {
                  errObj = {
                    ...errObj,
                    primary: { ...errObj.primary, unitInfo: true },
                  };
                }
                setErrorTabs({
                  ...errObj,
                });
              }
            }}
            removeUnit={remove}
            form={form}
            isEditView={isEditView}
            viewOnlyMode={viewOnlyMode}
            propertyStatus={propertyStatus}
            previewFile={previewFile}
            selectedProperty={selectedProperty}
            selectedPropertyUnits={selectedPropertyUnits}
            updateAvailableUnits={updateAvailableUnits}
            save={save}
            fields={fields}
            notAvailable={notAvailable}
            updateNotAvailableFields={updateNotAvailableFields}
            syncNotAvailableFields={syncNotAvailableFields}
            activeCollapseKeys={activeCollapseKeys}
            setActiveCollapseKeys={setActiveCollapseKeys}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
          />
        )}
      </Form.List>
      <AppModal
        className="media-preview"
        title={t("UnitInformation.FilePreview")}
        width="80%"
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        maxHeight="100vh"
        overFlowY="auto"
        footer={null}
        destroyOnClose
      >
        <FilePreview file={fileUrl} />
      </AppModal>
    </div>
  );
};

UnitInformation.propTypes = {
  form: PropTypes.object,
  isEditView: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
  loading: PropTypes.bool,
  propertyStatus: PropTypes.oneOf([
    PROPERTY_STATUS.published,
    PROPERTY_STATUS.unpublished,
    PROPERTY_STATUS.never_published,
  ]),
  selectedProperty: PropTypes.object,
  updateAvailableUnits: PropTypes.func,
  save: PropTypes.func,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  syncNotAvailableFields: PropTypes.func,
  isTranslated: PropTypes.bool,
  setErrorTabs: PropTypes.func,
  errorTabs: PropTypes.object,
  activeCollapseKeys: PropTypes.array,
  setActiveCollapseKeys: PropTypes.func,
  auditPanelDrawerVisible: PropTypes.bool,
};

export default UnitInformation;
