import mapValues from "lodash/mapValues";

const ACTIONS = {
  APP_RESET: null,
  USER_REQUEST: null,
  USER_SUCCESS: null,
  USER_ERROR: null,
  GET_USER_CAPABILITIES_SUCCESS: null,
  GET_USER_NOTIFICATIONS_SUCCESS: null,
  GET_ALL_USERS_SUCCESS: null,

  SET_LANGUAGE: null,

  EMPLOYEE_REQUEST: null,
  EMPLOYEE_SUCCESS: null,
  EMPLOYEE_SUGGESTION_REQUEST: null,
  EMPLOYEE_SUGGESTION_SUCCESS: null,
  GET_ALL_EMPLOYEES_SUCCESS: null,
  GET_EMPLOYEE_BY_ID_SUCCESS: null,
  EMPLOYEE_ERROR: null,
  EMPLOYEE_SUGGESTION_ERROR: null,

  ANNOUNCEMENT_REQUEST: null,
  ANNOUNCEMENT_SUCCESS: null,
  ANNOUNCEMENT_ERROR: null,

  PROPERTYROLLUP_REQUEST: null,
  PROPERTYROLLUP_SUCCESS: null,
  PROPERTYROLLUP_ERROR: null,
  GET_ALL_PROPERTYROLLUP_SUCCESS: null,

  PROPERTY_REQUEST: null,
  PROPERTY_SUCCESS: null,
  PROPERTY_ERROR: null,
  PROPERTY_SUGGESTION_REQUEST: null,
  PROPERTY_SUGGESTION_SUCCESS: null,
  PROPERTY_SUGGESTION_ERROR: null,
  PROPERTY_LIST_SUCCESS: null,
  CURRENT_VACANT_PROPERTY_LIST_SUCCESS: null,
  SELECTED_PROPERTY_SUCCESS: null,
  PROPERTY_TAGS_SUCCESS: null,
  CURRENT_VACANT_PROPERTIES_TAGS_SUCCESS: null,
  PROPERTY_ACTIVE_PROPERTYROLLUP_SUCCESS: null,

  GET_PROPERTY_BROKER_SUCCESS: null,
  GET_ALL_PROPERTY_BROKERS_SUCCESS: null,
  GET_ALL_PROPERTY_EMPLOYEES_SUCCESS: null,
  GET_ALL_PROPERTY_ESM_SUCCESS: null,
  GET_ALL_PROPERTY_MARKETS_SUCCESS: null,
  ACTIVE_PROPERTY_MARKETS_SUCCESS: null,
  ACTIVE_PROPERTY_SUB_MARKET_SUCCESS: null,

  UNIT_VACANCY_REQUEST: null,
  UNIT_VACANCY_SUCCESS: null,
  UPCOMING_VACANT_PROPERTIES_TAG_SUCCESS: null,
  UPCOMING_VACANT_PROPERTY_LIST_SUCCESS: null,
  UNIT_VACANCY_ERROR: null,
  UNIT_VACANCY_SUGGESTION_SUCCESS: null,
  UNIT_VACANCY_LIST_SUCCESS: null,
  UNIT_VACANCY_TAGS_SUCCESS: null,

  LAND_VACANCY_REQUEST: null,
  LAND_VACANCY_SUCCESS: null,
  LAND_VACANCY_ERROR: null,
  LAND_VACANCY_LIST_SUCCESS: null,
  LAND_VACANCY_TAGS_SUCCESS: null,

  PROPERTY_CODES_SUCCESS: null,
  PROPERTY_CODE_DETAILS_SUCCESS: null,
  PROPERTY_MEDIA_SUCCESS: null,

  PROPERTY_CONTACT_REQUEST: null,
  PROPERTY_CONTACT_SUCCESS: null,
  PROPERTY_CONTACT_ERROR: null,
  PROPERTY_CONTACT_SUGGESTION_REQUEST: null,
  PROPERTY_CONTACT_SUGGESTION_SUCCESS: null,
  PROPERTY_CONTACT_SUGGESTION_ERROR: null,
  PROPERTY_CONTACT_LIST_SUCCESS: null,

  PROPERTY_ADDRESS_FIELDS_LIST_SUCCESS: null,
  PROPERTY_CONVERT_SUCCESS: null,
  PROPERTY_CONVERT_REQUEST: null,
  PROPERTY_CONVERT_ERROR: null,
  PROPERTY_CONVERT_LIST_SUCCESS: null,

  BROKER_REQUEST: null,
  BROKER_SUCCESS: null,
  BROKER_SUGGESTION_REQUEST: null,
  BROKER_SUGGESTION_SUCCESS: null,
  GET_ALL_BROKERS_SUCCESS: null,
  GET_BROKER_BY_ID_SUCCESS: null,
  BROKER_ERROR: null,
  BROKER_SUGGESTION_ERROR: null,
  BROKER_ASSOCIATED_PROPERTIES_SUCCESS: null,

  AUDITOR_REQUEST: null,
  AUDITOR_SUCCESS: null,
  AUDITOR_ERROR: null,
  AUDITOR_SUGGESTION_REQUEST: null,
  AUDITOR_SUGGESTION_SUCCESS: null,
  GET_AUDITOR_BY_ID_SUCCESS: null,
  AUDITOR_SUGGESTION_ERROR: null,

  MARKET_REQUEST: null,
  MARKET_SUCCESS: null,
  GET_ALL_MARKETS_SUCCESS: null,
  GET_ALL_MARKETS_SELECTED_SUCCESS: null,
  ACTIVE_MARKETS_SUCCESS: null,
  GET_MARKET_BY_ID_SUCCESS: null,
  MARKET_ERROR: null,
  MARKET_SUGGESTION_REQUEST: null,
  MARKET_SUGGESTION_SUCCESS: null,
  MARKET_SUGGESTION_ERROR: null,

  ADMIN_LIST_SUCCESS: null,
  SELECTED_ADMIN_SUCCESS: null,
  ADMIN_REQUEST: null,
  ADMIN_SUCCESS: null,
  ADMIN_ERROR: null,

  BUILDING_ERROR: null,
  BUILDING_REQUEST: null,
  BUILDING_SUCCESS: null,
  BUILDING_CODE_SUCCESS: null,
  BUILDING_PUBLISHED_UNITS_SUCCESS: null,
  BUILDING_UOM_SUCCESS: null,
  BUILDING_TOTAL_BUILDING_SIZE_SUCCESS: null,
  BUILDING_OCCUPIED_SPACE_SUCCESS: null,
  BUILDING_COMBINED_UNIT_NUMBER: null,
  BUILDING_UNIT_DETAIL_SUCCESS: null,

  DIVISION_REQUEST: null,
  DIVISION_SUGGESTION_REQUEST: null,
  DIVISION_SUGGESTION_SUCCESS: null,
  DIVISION_SUCCESS: null,
  DIVISION_LIST_SUCCESS: null,
  DIVISION_SELECTED_LIST_SUCCESS: null,
  GET_MARKET_USERS_SUCCESS: null,

  REGION_REQUEST: null,
  REGION_SUGGESTION_REQUEST: null,
  REGION_SELECTED_LIST_SUCCESS: null,
  REGION_SUGGESTION_SUCCESS: null,
  REGION_SUCCESS: null,
  REGION_LIST_SUCCESS: null,

  LAND_ERROR: null,
  LAND_REQUEST: null,
  LAND_SUCCESS: null,
  LAND_CODE_SUCCESS: null,

  SUB_MARKET_REQUEST: null,
  SUB_MARKET_SUCCESS: null,
  SUB_MARKET_ERROR: null,
  SUB_MARKET_LIST_SUCCESS: null,
  ACTIVE_SUB_MARKET_SUCCESS: null,
  SUB_MARKET_SUGGESTION_SUCCESS: null,
  SUB_MARKET_SELECTED_LIST_SUCCESS: null,

  HELPER_TEXT_REQUEST: null,
  HELPER_TEXT_SUCCESS: null,
  HELPER_TEXT_ERROR: null,
  HELPER_TEXT_LIST_SUCCESS: null,

  TEMPLATE_REQUEST: null,
  TEMPLATE_SUCCESS: null,
  TEMPLATE_ERROR: null,
  TEMPLATE_LIST_SUCCESS: null,

  PROPERTIES_MAP_REQUEST: null,
  PROPERTIES_MAP_ERROR: null,
  PROPERTIES_MAP_SUCCESS: null,
  PROPERTIES_MAP_LIST_SUCCESS: null,

  FEATURE_FLAG_REQUEST: null,
  FEATURE_FLAG_ERROR: null,
  FEATURE_FLAG_SUCCESS: null,
  FEATURE_FLAG_LIST_SUCCESS: null,

  AUDIT_REQUEST: null,
  AUDIT_ERROR: null,
  GET_ALL_AUDITS_SUCCESS: null,
  AUDIT_TAGS_SUCCESS: null,
  OPEN_AUDIT_TAG_SUCCESS: null,
  OPEN_AUDIT_LIST_SUCCESS: null,
  AUDIT_SUCCESS: null,
  AUDIT_SUGGESTION_SUCCESS: null,
  AUDIT_ISSUE_ID_NUMBER: null,

  UPLOAD_REQUEST: null,
  UPLOAD_ERROR: null,
  UPLOAD_SUCCESS: null,

  UNMARKET_REASONS_REQUEST: null,
  UNMARKET_REASONS_SUCCESS: null,
  UNMARKET_REASONS_ERROR: null,
  UNMARKET_REASONS_LIST_SUCCESS: null,
  UNMARKET_PROPERTIES_LIST_SUCCESS: null,

  DOWNTIME_REPORT_REQUEST: null,
  DOWNTIME_REPORT_SUCCESS: null,
  DOWNTIME_REPORT_ERROR: null,

  HOME_REQUEST: null,
  HOME_ERROR: null,
  HOME_TAGS_SUCCESS: null,
  HOME_PAGINATION_SUCCESS: null,
  UNITS_TO_REVIEW_SUCCESS: null,
  VACANT_UNITS_SUCCESS: null,
  UPCOMING_VACANT_UNITS_SUCCESS: null,
  ACTIVE_AUDITS_SUCCESS: null,
  NOT_MARKETING_UNITS_SUCCESS: null,
  MARKETABLE_UNITS_SUCCESS: null,
};

export default mapValues(ACTIONS, (v, k) => k);
