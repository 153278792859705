import { Button, Col, Form, Input, Row, Skeleton, Switch } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import { getActiveMarkets } from "../../store/actions/market.actions";
import { createPropertyRollUp } from "../../store/actions/propertyRollUp.action";
import { SEARCH_QUERY, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import { validateCode } from "../../utils/utils";

const AddEditPropertyRollUp = ({ onSuccess, onCloseModal }) => {
  const queryParam = { filterCriteria: { isActive: true }, sort: "id,asc" };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { value: propertyRollUp, loading } = useSelector(store => store.propertyRollUp);
  const [form] = Form.useForm();
  const { activeList: markets, loading: fetchingMarkets } = useSelector(store => store.market);

  const onFormFinish = async fieldValues => {
    const isActive = fieldValues.isActive || false;

    const values = { ...fieldValues, isActive, market: fieldValues.market.value };
    await dispatch(createPropertyRollUp(values));

    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(() => {
    const didMount = async () => {
      fetchMarkets();
    };
    didMount();
  }, []);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [],
  );

  const isValidCode = (rule, value) => {
    if (value && !validateCode(value)) {
      return Promise.reject(new Error(t("AddEditMarket.MarketCodeFormat")));
    }
    return Promise.resolve();
  };

  const fetchMarkets = async query => {
    let _query = query;
    if (!_query) {
      _query = { ...SEARCH_QUERY, ...queryParam };
    }
    dispatch(getActiveMarkets(_query));
  };

  return (
    <div className="edit-propertyRollUp">
      <Skeleton loading={false} active paragraph={{ rows: 6 }}>
        <Form
          form={form}
          className="edit-propertyRollUp"
          layout="vertical"
          name="nest-messages"
          initialValues={propertyRollUp}
          onFinish={onFormFinish}
          validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
        >
          <Row className="fields-row" gutter={20} type="flex">
            <Col span={12}>
              <Form.Item
                label={t("AddEditPropertyRollUp.PropertyRollUpCode")}
                name="id"
                validateFirst
                rules={[{ required: true, whitespace: true }, { validator: isValidCode }]}
              >
                <Input placeholder={t("AddEditPropertyRollUp.PropertyRollUpCodePlaceholder")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("AddEditPropertyRollUp.PropertyRollUpName")}
                name="propertyRollUpName"
                validateFirst
                rules={[{ required: true, whitespace: true }]}
              >
                <Input placeholder={t("AddEditPropertyRollUp.PropertyRollUpName")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("AddEditPropertyRollUp.MarketPlaceholder")}
                name="market"
                rules={[{ required: true, message: t("AddEditPropertyRollUp.MarketRequiredMessage") }]}
              >
                <CustomSelect
                  placeholder={t("AddEditPropertyRollUp.MarketPlaceholder")}
                  loading={fetchingMarkets}
                  fetchOptions={fetchMarkets}
                  optionsList={markets}
                  queryParam={queryParam}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("AddEditPropertyRollUp.IsActive")} name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row className="fields-row" gutter={20} type="flex" justify="center">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button
                    block
                    onClick={() => {
                      onCloseModal(false);
                    }}
                  >
                    {t("Buttons.DiscardChanges")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button block type="primary" htmlType="submit" loading={loading}>
                    {t("Buttons.Create")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </div>
  );
};

AddEditPropertyRollUp.propTypes = {
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default AddEditPropertyRollUp;
