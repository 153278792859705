import { Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

const Forbidden = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <>
        <Link to={ROUTES.HOME.path} className="action-buttons-item">
          <p style={{ color: "#5C5E5D" }}>
            Go to
            {' '}
            <span className="font-bold">Home</span>
          </p>
        </Link>
      </>
    }
  />
);

export default Forbidden;
