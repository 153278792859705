/* eslint-disable prefer-destructuring */
import { Layout, Spin } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import AppFooter from "../../components/AppFooter/AppFooter";
import PrivateRoute from "../../components/PrivateRoute";
import routes from "../../routes/list.route";
import { AuthService } from "../../services/auth.service";
import { getAllFeatureFlags } from "../../store/actions/featureFlag.actions";
import { getAllHelperTexts } from "../../store/actions/helperText.actions";
import { getAllTemplates } from "../../store/actions/template.actions";
import AppHeaderContainer from "../Header";
import "./MainLayout.scss";

const MainLayout = ({ history, logout, loading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const helperTexts = useSelector(state => state.helperText.lookupList);
  const featureFlags = useSelector(state => state.featureFlag.list);
  const { list: templateList } = useSelector(state => state.template);

  useEffect(() => {
    if (_isEmpty(helperTexts)) {
      dispatch(getAllHelperTexts());
    }
    if (_isEmpty(templateList)) {
      dispatch(getAllTemplates());
    }
    if (_isEmpty(featureFlags)) {
      dispatch(getAllFeatureFlags());
    }
  }, []);

  return loading ? (
    <div>
      <div className="edit-form__spin">
        <Spin size="large" tip={t("ErrorMessages.Loading")} />
      </div>
    </div>
  ) : (
    <Layout className="main-layout">
      <Layout>
        <React.Fragment>
          {<AppHeaderContainer history={history} logout={logout} />}
          <Layout.Content className="layout-content">
            <div className="main-layout__content-wrap">
              <Switch>
                {routes &&
                  routes.map(({ component, componentProps, path, exact, category, action }) => (
                    <PrivateRoute
                      exact={exact}
                      key={`${path}-private-route`}
                      path={`${path}`}
                      component={component}
                      componentProps={componentProps}
                      category={category}
                      action={action}
                    />
                  ))}

                <Route
                  path=""
                  component={() =>
                    !_isEmpty(AuthService.getAllAccounts()) ? <Redirect to="/home" /> : <Redirect to="/404" />
                  }
                />
              </Switch>
            </div>
          </Layout.Content>
          <AppFooter />
        </React.Fragment>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  logout: PropTypes.func,
};

export default MainLayout;
