import { Col, Form, Row, Select } from "antd";
import _isEmpty from "lodash/isEmpty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SearchInput from "../../components/SearchInput/SearchInput";
import { useKeyword } from "../../hooks";
import {
  getPropertyContactsList,
  getPropertyContactsSuggestion,
} from "../../store/actions/propertyContactHistory.action";
import { DEFAULT_SORTER } from "../../utils/constants";
import { searchQueryGenerator, submitSearchForm } from "../../utils/utils";
import "./SearchPropertyContactHistory.scss";
const { Option } = Select;

const SearchPropertyContactHistory = props => {
  const { t } = useTranslation();
  const { pagination, suggestionLoading } = useSelector(state => state.propertyContactHistory);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { keyword, setKeyword } = useKeyword({ pagination });

  const onFormFinish = async (_, option) => {
    let sorter = DEFAULT_SORTER;
    if (!_isEmpty(pagination.sort)) {
      const sortArr = pagination.sort.split(",");
      sorter = { field: sortArr[0], order: sortArr[1] };
    }

    const query = {
      ...pagination,
      currentPage: 1,
      keyword: option?.key ? option.key : keyword,
      sorter: _isEmpty(keyword) ? sorter : null,
      pageSize: pagination.size,
      labelInValue: !_isEmpty(option) ? option : undefined,
    };

    const _query = searchQueryGenerator(query);
    await dispatch(getPropertyContactsList(_query));
  };

  const getOptions = val => dispatch(getPropertyContactsSuggestion(val));

  const _onSubmit = (flag = true, option) => {
    submitSearchForm(flag, option, pagination, form, onFormFinish);
  };

  const _onEnter = () => {
    if (keyword.length) _onSubmit();
  };

  return (
    <div className="property-contact-history-search">
      <Form form={form} onFinish={onFormFinish}>
        <Row gutter={[16, 16]} className="row-vertical-padding-4">
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={4}>
            <SearchInput
              value={keyword}
              setValue={setKeyword}
              onSelectValue={_onSubmit}
              loading={suggestionLoading}
              getOptions={getOptions}
              onEnter={_onEnter}
              placeholder={t("AdminSearch.ContactHistoryPlaceholder")}
              renderOptions={value => (
                <Option key={value?.propertyCode} value={value?.propertyCode}>
                  {`${value?.propertyCode} ${value?.propertyType ? `/ ${value?.propertyType}` : ""}`}
                </Option>
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchPropertyContactHistory;
