import React from "react";
import PropTypes from "prop-types";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

const NoMatch = ({ history }) => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("NoMatch.Description")}
      extra={
        <Button type="primary" onClick={() => history.push("/properties")}>
          {t("NoMatch.ButtonText")}
        </Button>
      }
    />
  );
};

NoMatch.propTypes = {
  history: PropTypes.object,
};

export default NoMatch;
