import { Switch, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomTooltipHelper from "../CustomTooltipHelper/CustomTooltipHelper";
import "./LabelWithSwitch.scss";

const LabelWithSwitch = ({
  viewOnlyMode,
  _onChangeToggle,
  label,
  fieldName,
  checked,
  index,
  tooltip,
  form,
  tab,
  auditPanelDrawerVisible,
}) => {
  const { t } = useTranslation();
  const _onChange = () => {
    _onChangeToggle(fieldName, index);
  };

  return (
    <div className="label-with-switch">
      <span>
        <CustomTooltipHelper
          viewOnlyMode={viewOnlyMode}
          label={label}
          tooltip={tooltip}
          form={form}
          tab={tab}
          fieldName={fieldName}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          unitIndex={index}
        />
      </span>
      <Tooltip title={t("Property.NotApplicable")}>
        {t("Property.NA")}
        <Switch size="small" className="mg-left-10" disabled={viewOnlyMode} onChange={_onChange} checked={checked} />
      </Tooltip>
    </div>
  );
};

LabelWithSwitch.propTypes = {
  viewOnlyMode: PropTypes.bool,
  _onChangeToggle: PropTypes.func,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  checked: PropTypes.bool,
  index: PropTypes.number,
  tooltip: PropTypes.object,
  form: PropTypes.object,
  tab: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
};

export default LabelWithSwitch;
