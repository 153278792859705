import React from "react";
import { Popconfirm } from "antd";
import PropTypes from "prop-types";
import "./PopUp.scss";

const PopUp = ({
  title,
  okText,
  cancelText,
  placement,
  className,
  disabled,
  onConfirm,
  popupVisible,
  onVisibleChange,
  children,
  onCancel,
  ...props
}) => {
  const keyAction = e => {
    if (popupVisible && e.key === "Enter") {
      onConfirm(e);
    }
  };

  return (
    <div onKeyDown={keyAction} role="button" tabIndex={0} className="pop-up">
      <Popconfirm
        title={title}
        okText={okText}
        cancelText={cancelText}
        placement={placement}
        className={className}
        disabled={disabled}
        onConfirm={onConfirm}
        onCancel={onCancel}
        popupVisible={popupVisible}
        onVisibleChange={onVisibleChange}
        {...props}
      >
        <a href="#" className="pop-up-anchor">
          {children}
        </a>
      </Popconfirm>
    </div>
  );
};

PopUp.propTypes = {
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  popupVisible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  children: PropTypes.any,
  onCancel: PropTypes.func,
};

export default PopUp;
