import { Button, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SEARCH_QUERY, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import "./AddEditRegion.scss";
import { validateCode } from "../../utils/utils";
import CustomSelect from "../../components/CustomSelect";
import { getActiveMarkets, marketSuccess, updateMarket } from "../../store/actions/market.actions";

const queryParam = { filterCriteria: { isActive: true }, sort: "id,asc" };

const AddEditRegion = ({ onSuccess, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeList: markets, loading } = useSelector(store => store.market);

  const [form] = Form.useForm();

  const onFormFinish = async fieldValues => {
    const _fieldValues = { ...fieldValues, market: fieldValues.market.value };

    delete _fieldValues.market;
    await dispatch(updateMarket(fieldValues.market.value, { region: _fieldValues }));
    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(() => {
    (async () => {
      fetchMarkets();
      form.resetFields();
    })();
  }, []);

  const fetchMarkets = async query => {
    let _query = query;
    if (!_query) {
      _query = { ...SEARCH_QUERY, ...queryParam };
    }
    dispatch(getActiveMarkets(_query));
  };

  const isValidCode = (rule, value) => {
    if (value && !validateCode(value)) {
      return Promise.reject(new Error(t("AddEditRegion.RegionCodeFormat")));
    }
    return Promise.resolve();
  };

  return (
    <div className="edit-region">
      <Form
        form={form}
        layout="vertical"
        name="nest-messages"
        onFinish={onFormFinish}
        validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      >
        <Row className="fields-row" gutter={20} type="flex">
          <Col span={12}>
            <Form.Item
              label={t("AddEditRegion.RegionCode")}
              name="code"
              validateFirst
              rules={[{ required: true, whitespace: true }, { validator: isValidCode }]}
            >
              <Input placeholder={t("AddEditRegion.RegionCodePlaceholder")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("AddEditRegion.RegionName")}
              name="name"
              validateFirst
              rules={[{ required: true, whitespace: true }]}
            >
              <Input placeholder={t("AddEditRegion.RegionName")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item shouldUpdate label={t("AddEditMarket.MarketCode")} name="market" rules={[{ required: true }]}>
              <CustomSelect
                placeholder={t("AddEditRegion.SelectMarketCode")}
                loading={loading}
                fetchOptions={fetchMarkets}
                optionsList={markets}
                queryParam={queryParam}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row className="fields-row" gutter={20} type="flex" justify="center">
              <Col xs={12} sm={12} md={8} lg={8}>
                <Button
                  block
                  onClick={() => {
                    onCloseModal(false);
                    dispatch(marketSuccess({}));
                  }}
                >
                  {t("Buttons.DiscardChanges")}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Button block type="primary" htmlType="submit" loading={loading}>
                  {t("Buttons.Create")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddEditRegion.propTypes = {
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default AddEditRegion;
