import { getLanguageCode } from "../../i18n";

const appInitialState = {
  language: {
    current: getLanguageCode(),
    secondary: [],
  },
  user: {
    loading: false,
    error: null,
    value: {},
  },
  employee: {
    loading: false,
    error: null,
    suggestionLoading: false,
    value: {},
    list: [],
    pagination: {},
  },
  announcement: {
    loading: false,
    error: null,
    value: {},
    list: [],
  },
  building: {
    loading: false,
    error: null,
    selected: {},
    codeDetail: {},
    publishedUnits: [],
    uom: [],
    maxSpaceAvailable: undefined,
    spaceOccupiedByUnits: undefined,
    maxCombinedUnitNumber: 0,
    unitDetail: {},
  },
  property: {
    loading: false,
    loadingCodes: false,
    loadingMedia: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    // selected: {},
    selectedPropertyMedia: [],
    propertyCodes: [],
    pagination: {},
    currentVacantPropertyPagination: {},
    tags: [],
    currentVacantPropertyTags: [],
    allCountryAddressFields: [],
    // employees
    employeeList: [],
    loadingEmployees: false,
    paginationEmplyee: {},
    // brokers
    brokerList: [],
    loadingBrokers: false,
    paginationBroker: {},
    // ESM contact
    loadingESM: false,
    loadingConvertProperty: false,
    esmList: [],
    paginationESM: {},
    // auditor
    auditorList: [],
    loadingAuditor: false,
    paginationAuditor: {},
    // markets
    marketList: [],
    marketActiveList: [],
    loadingMarkets: false,
    loadingActiveSubMarkets: false,
    // sub markets
    subMarketList: [],
    subMarketActiveList: [],
    loadingSubMarkets: false,
    // audit
    auditLoading: false,
    auditList: [],
    usersLoading: false,
    maxAuditIssueIdNumber: 0,
    // property RollUp
    propertyRollUpList: [],
    loadingPropertyRollUp: false,
  },
  unitVacancy: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    pagination: {},
    upcomingVacantPagination: {},
    tags: [],
    upcomingVacantPropertyTags: [],
  },
  landVacancy: {
    loading: false,
    suggestionLoading: false,
    error: null,
    list: [],
    pagination: {},
    tags: [],
  },
  land: {
    loading: false,
    error: null,
    selected: {},
    codeDetail: {},
  },
  propertyContactHistory: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    pagination: {},
  },
  broker: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    pagination: {},
    associatedProperties: [],
    loadingAssociatedProperties: [],
  },
  auditor: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    pagination: {},
  },
  market: {
    loading: false,
    suggestionLoading: false,
    activeLoading: false,
    error: null,
    value: {},
    list: [],
    selectedList: [],
    pagination: {},
    activeList: [],
  },
  division: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    selectedList: [],
    pagination: {},
    activeList: [],
  },
  region: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    selectedList: [],
    pagination: {},
    activeList: [],
  },
  subMarket: {
    loading: false,
    suggestionLoading: false,
    error: null,
    value: {},
    list: [],
    selectedList: [],
    pagination: {},
    activeList: [],
  },
  helperText: {
    loading: false,
    error: null,
    value: {},
    list: [],
    lookupList: {},
  },
  template: {
    loading: false,
    error: null,
    value: {},
    list: [],
  },
  featureFlag: {
    loading: false,
    error: null,
    value: {},
    list: [],
  },
  audit: {
    loading: false,
    error: null,
    value: {},
    list: [],
    pagination: {},
    auditPagination: {},
    tags: [],
    openAudits: [],
  },
  upload: {
    loading: false,
  },
  propertyRollUp: {
    loading: false,
    suggestionLoading: false,
    error: null,
    list: [],
    value: {},
    pagination: {},
  },
  unmarketReason: {
    loading: false,
    error: null,
    list: [],
    unmarketPropertiesList: [],
  },
  downtimeReport: {
    loading: false,
    error: null,
    list: [],
    pagination: {},
  },
  home: {
    unitsToReview: {},
    vacantUnits: {},
    upcomingVacantUnits: {},
    activeAudits: {},
    notMarketingUnits: {},
    marketableUnits: null,
    marketableUnitsCount: null,
    homePagination: {},
    homeTags: [],
  },
};

export default appInitialState;
