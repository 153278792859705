import { Button, Collapse, Typography } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { buildingOccupiedSpaceSuccess } from "../../store/actions/building.actions";
import { PROPERTY_STATUS } from "../../utils/constants";
import { combineUnitsMaxSpaces, validateMaxAvailableUnitSpaces } from "../../utils/utils";
import UnitInfoForm from "./UnitInfoForm";
import "./UnitInfoList.scss";
import UnitListActions from "./UnitListActions";

const { Text } = Typography;

const UnitInfoList = ({
  form,
  isEditView,
  viewOnlyMode,
  propertyStatus,
  previewFile,
  selectedProperty,
  selectedPropertyUnits,
  updateAvailableUnits,
  save,
  fields,
  notAvailable,
  updateNotAvailableFields,
  syncNotAvailableFields,
  removeUnit,
  addUnit,
  activeCollapseKeys,
  setActiveCollapseKeys,
  auditPanelDrawerVisible,
}) => {
  const dispatch = useDispatch();
  const [clearHeightUOM, setClearHeightUOM] = useState([]);
  const { t } = useTranslation();
  const [filterPropertyUnitType, setFilterPropertyUnitType] = useState("all");
  const [filterPropertyUnitStatus, setFilterPropertyUnitStatus] = useState("all");

  const filteredFields = fields.filter(field => {
    const fieldName = field.name;
    const unitCode = _get(form.getFieldValue("unitInfo"), `[${fieldName}].unitCode`, []);
    const unitStatus = _get(form.getFieldValue("unitInfo"), `[${fieldName}].status`, "");
    return (
      (filterPropertyUnitStatus === "all" || filterPropertyUnitStatus === unitStatus) &&
      (filterPropertyUnitType === "all" ||
        (filterPropertyUnitType === "single" && unitCode.length === 1) ||
        (filterPropertyUnitType === "merged" && unitCode.length > 1))
    );
  });

  const _renderEmptyFieldsContent = () => {
    if (_isEmpty(selectedProperty)) {
      return (
        <Text type="secondary" className="helper-text">
          {t("UnitInfoList.Message1")}
        </Text>
      );
    }

    return (
      <div>
        <Text type="secondary" className="helper-text">
          {t("UnitInfoList.AddUnitMessage")}
        </Text>
      </div>
    );
  };

  return (
    <div className="UnitInfoList">
      {fields.length === 0 && _renderEmptyFieldsContent()}

      {!_isEmpty(selectedProperty) && (
        <UnitListActions
          form={form}
          viewOnlyMode={viewOnlyMode}
          showFilters={fields.length > 0}
          onUnitAdd={addUnitsValue => {
            for (let i = 0; i < addUnitsValue; i += 1) {
              addUnit();
            }
            syncNotAvailableFields("unitInfo", undefined, ["gradeLevelDoors", "dockHighDoors"], addUnitsValue);
            setActiveCollapseKeys([0]);
            form.validateFields([["buildingInfo", "propertyCode"]]);
          }}
          filterPropertyUnitStatus={filterPropertyUnitStatus}
          onFilterPropertyUnitStatus={value => setFilterPropertyUnitStatus(value)}
          filterPropertyUnitType={filterPropertyUnitType}
          onFilterPropertyUnitType={value => setFilterPropertyUnitType(value)}
        />
      )}

      {fields.length > 0 && (
        <>
          <div className="UnitInfoList__collapse-header">
            <Text>
              {t("UnitInfoList.showing")}
              &nbsp;
              {filteredFields.length}
              &nbsp;
              {t("UnitInfoList.unit(s)")}
            </Text>
            {filteredFields.length > 0 && (
              <Button
                type="link"
                onClick={() => {
                  if (activeCollapseKeys.length > 0) {
                    setActiveCollapseKeys([]);
                  } else {
                    setActiveCollapseKeys([...Array(filteredFields.length).keys()]);
                  }
                }}
              >
                {activeCollapseKeys.length > 0 ? t("UnitInfoList.collapseAll") : t("UnitInfoList.expandAll")}
              </Button>
            )}
          </div>
          {filteredFields.length > 0 ? (
            <Collapse
              expandIconPosition="right"
              defaultActiveKey={[0]}
              activeKey={activeCollapseKeys}
              expandIcon={({ isActive }) => <CustomIcon name="UpOutlined" rotate={isActive ? 180 : 0} />}
              onChange={keys => {
                setActiveCollapseKeys(keys);
              }}
              className="unit-info-collapse"
            >
              {_map(filteredFields.reverse(), (field, index) => {
                const _removeUnit = async () => {
                  const _unitCode = form.getFieldValue(["unitInfo", field.name, "unitCode"]);
                  syncNotAvailableFields("unitInfo", field.name);
                  updateAvailableUnits(
                    _unitCode.map(_code => _code.value),
                    field.name,
                    true,
                  );
                  removeUnit(field.name);
                  const _unitInfo = form.getFieldValue("unitInfo");
                  const combined = combineUnitsMaxSpaces(_unitInfo);
                  await dispatch(buildingOccupiedSpaceSuccess(combined));
                  validateMaxAvailableUnitSpaces(form, _unitInfo);
                };
                return (
                  <UnitInfoForm
                    key={index}
                    fields={filteredFields}
                    fieldName={field.name}
                    updateAvailableUnits={updateAvailableUnits}
                    removeUnit={_removeUnit}
                    form={form}
                    save={save}
                    selectedProperty={selectedProperty}
                    selectedPropertyUnits={selectedPropertyUnits}
                    viewOnlyMode={viewOnlyMode}
                    isEditView={isEditView}
                    previewFile={previewFile}
                    propertyStatus={propertyStatus}
                    notAvailable={notAvailable}
                    updateNotAvailableFields={updateNotAvailableFields}
                    clearHeightUOM={clearHeightUOM}
                    setClearHeightUOM={setClearHeightUOM}
                    auditPanelDrawerVisible={auditPanelDrawerVisible}
                  />
                );
              })}
            </Collapse>
          ) : (
            <Text type="secondary" className="helper-text">
              {t("UnitInfoList.NoUnitsMessage")}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

UnitInfoList.propTypes = {
  form: PropTypes.object,
  isEditView: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
  propertyStatus: PropTypes.oneOf([
    PROPERTY_STATUS.published,
    PROPERTY_STATUS.unpublished,
    PROPERTY_STATUS.never_published,
    "",
  ]),
  previewFile: PropTypes.func,
  selectedProperty: PropTypes.object,
  selectedPropertyUnits: PropTypes.array,
  updateAvailableUnits: PropTypes.func,
  save: PropTypes.func,
  fields: PropTypes.array,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  syncNotAvailableFields: PropTypes.func,
  removeUnit: PropTypes.func,
  addUnit: PropTypes.func,
  activeCollapseKeys: PropTypes.array,
  setActiveCollapseKeys: PropTypes.func,
  auditPanelDrawerVisible: PropTypes.bool,
};

export default UnitInfoList;
