const AnalyticService = {
  sendCustomEvent: (eventName, opts) => {
    if (window.google_tag_manager && window.dataLayer) {
      const eventObject = {
        event: eventName,
        ...opts,
      };
      window.dataLayer.push(eventObject);
      console.log("Successfully Sent Custom GTAG Event: ", eventObject);
      return true;
    }
    console.log("Failed to Send Custom GTAG Event, GTM container not found");
    return false;
  },
};
export default AnalyticService;
