import { get, put } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const AuditorService = {
  suggestions: query => get(`user/suggestion${getSearchListQuery(query)}`),
  searchAuditors: query => get(`user/search${getSearchListQuery(query)}`),
  getAuditorById: id => get(`user/${id}`),
  updateAuditor: (id, body) => put(`user/${id}`, body),
};
export default AuditorService;
