/* eslint-disable prettier/prettier */

export const permissions = {
  // "Action/Right": ["Admin", "Editor", "View Only", "Auditor"],
  user_create: ["YES", "YES", "NO", "YES"],
  user_view: ["YES", "NO", "YES", "NO"],
  user_edit: ["YES", "NO", "NO", "NO"],

  market_create: ["YES", "NO", "NO", "NO"],
  market_view: ["YES", "NO", "NO", "NO"],

  property_rollup_create: ["YES", "NO", "NO", "NO"],

  property_roll_up_view: ["YES", "NO", "NO", "NO"],

  property_add: ["YES", "YES", "NO", "YES"],
  property_edit: ["YES", "YES", "NO", "YES"],
  property_view: ["YES", "YES", "YES", "YES"],
  property_publish: ["YES", "YES", "NO", "YES"],
  property_unpublish: ["YES", "YES", "NO", "YES"],

  unit_add: ["YES", "YES", "NO", "YES"],
  unit_edit: ["YES", "YES", "NO", "YES"],
  unit_publish: ["YES", "YES", "NO", "YES"],
  unit_unpublish: ["YES", "YES", "NO", "YES"],
  property_contact_history_export: ["YES", "NO", "YES", "NO"],

  announcement_create: ["YES", "NO", "NO", "NO"],

  sub_market_create: ["YES", "NO", "NO", "NO"],

  helper_text_create: ["YES", "NO", "NO", "NO"],
  helper_text_view: ["YES", "NO", "YES", "NO"],
  helper_text_edit: ["YES", "NO", "NO", "NO"],
  helper_text_export: ["YES", "NO", "YES", "NO"],

  template_create: ["YES", "NO", "NO", "NO"],
  template_view: ["YES", "NO", "YES", "NO"],
  template_edit: ["YES", "NO", "NO", "NO"],
  template_export: ["YES", "NO", "YES", "NO"],

  feature_flag_view: ["YES", "NO", "YES", "NO"],
  feature_flag_edit: ["YES", "NO", "NO", "NO"],

  unit_vacancy_update: ["YES", "NO", "NO", "YES"],
  unit_vacancy_view: ["YES", "NO", "YES", "YES"],

  audit_add: ["YES", "NO", "NO", "YES"],
  audit_edit: ["YES", "NO", "NO", "YES"],
  audit_delete: ["YES", "NO", "NO", "YES"],
  audit_view: ["YES", "YES", "YES", "YES"],
  audit_comment: ["YES", "YES", "NO", "YES"],
  audit_export: ["YES", "NO", "YES", "YES"],
  audit_status_edit: ["YES", "YES", "NO", "NO"],

  unmarket_reason_create: ["YES", "NO", "NO", "NO"],
  unmarket_reason_view: ["YES", "NO", "YES", "NO"],
  unmarket_reason_edit: ["YES", "NO", "NO", "NO"],
  unmarket_reason_delete: ["YES", "NO", "NO", "NO"],

  land_vacancy_update: ["YES", "NO", "NO", "YES"],
  land_vacancy_view: ["YES", "NO", "YES", "YES"],

  downtime_report_view: ["YES", "NO", "NO", "NO"],

  convert_property_view: ["YES", "YES", "NO", "NO"],
  home_view: ["YES", "YES", "YES", "YES"],
};

export const adminPermissions = {
  user_create: "YES",
  user_view: "YES",
  user_edit: "YES",

  market_create: "YES",
  market_view: "YES",

  property_rollup_create: "YES",

  property_roll_up_view: "YES",

  property_add: "YES",
  property_edit: "YES",
  property_view: "YES",
  property_publish: "YES",
  property_unpublish: "YES",

  unit_add: "YES",
  unit_edit: "YES",
  unit_publish: "YES",
  unit_unpublish: "YES",
  property_contact_history_export: "YES",

  announcement_create: "YES",

  sub_market_create: "YES",

  helper_text_view: "YES",
  helper_text_edit: "YES",
  helper_text_create: "YES",
  helper_text_export: "YES",

  template_create: "YES",
  template_view: "YES",
  template_edit: "YES",
  template_export: "YES",

  feature_flag_view: "YES",
  feature_flag_edit: "YES",

  unit_vacancy_update: "YES",
  unit_vacancy_view: "YES",

  audit_add: "YES",
  audit_edit: "YES",
  audit_delete: "YES",
  audit_view: "YES",
  audit_comment: "YES",
  audit_export: "YES",
  audit_status_edit: "YES",

  unmarket_reason_create: "YES",
  unmarket_reason_view: "YES",
  unmarket_reason_edit: "YES",
  unmarket_reason_delete: "YES",

  land_vacancy_view: "YES",
  land_vacancy_update: "YES",

  downtime_report_view: "YES",

  convert_property_view: "YES",
  home_view: "YES",
};

export const editorPermissions = {
  user_create: "YES",
  user_view: "NO",
  user_edit: "NO",

  market_create: "NO",
  market_view: "NO", // added by adnan

  property_rollup_create: "NO",

  property_roll_up_view: "NO",

  property_add: "YES",
  property_edit: "YES",
  property_view: "YES",
  property_publish: "YES",
  property_unpublish: "YES",

  unit_add: "YES",
  unit_edit: "YES",
  unit_publish: "YES",
  unit_unpublish: "YES",
  property_contact_history_export: "NO",

  announcement_create: "NO",

  sub_market_create: "NO",

  helper_text_view: "NO",
  helper_text_edit: "NO",
  helper_text_create: "NO",
  helper_text_export: "NO",

  template_create: "NO",
  template_view: "NO",
  template_edit: "NO",
  template_export: "NO",

  feature_flag_view: "NO",
  feature_flag_edit: "NO",

  unit_vacancy_update: "YES",
  unit_vacancy_view: "YES",

  audit_add: "NO",
  audit_edit: "NO",
  audit_delete: "NO",
  audit_view: "YES",
  audit_comment: "YES",
  audit_export: "NO",
  audit_status_edit: "YES",

  unmarket_reason_create: "NO",
  unmarket_reason_view: "NO",
  unmarket_reason_edit: "NO",
  unmarket_reason_delete: "NO",

  land_vacancy_view: "YES",
  land_vacancy_update: "YES",

  downtime_report_view: "NO",

  convert_property_view: "YES",
  home_view: "YES",
};

export const auditorPermissions = {
  user_create: "YES",
  user_view: "NO",
  user_edit: "NO",

  market_create: "NO",
  market_view: "NO",

  property_rollup_create: "NO",

  property_roll_up_view: "NO",

  property_add: "YES",
  property_edit: "YES",
  property_view: "YES",
  property_publish: "YES",
  property_unpublish: "YES",

  unit_add: "YES",
  unit_edit: "YES",
  unit_publish: "YES",
  unit_unpublish: "YES",
  property_contact_history_export: "NO",

  announcement_create: "NO",

  sub_market_create: "NO",

  helper_text_view: "NO",
  helper_text_edit: "NO",
  helper_text_create: "NO",
  helper_text_export: "NO",

  template_create: "NO",
  template_view: "NO",
  template_edit: "NO",
  template_export: "NO",

  feature_flag_view: "NO",
  feature_flag_edit: "NO",

  unit_vacancy_update: "YES",
  unit_vacancy_view: "YES",

  audit_add: "YES",
  audit_edit: "YES",
  audit_delete: "YES",
  audit_view: "YES",
  audit_comment: "YES",
  audit_export: "YES",
  audit_status_edit: "NO",

  unmarket_reason_create: "NO",
  unmarket_reason_view: "NO",
  unmarket_reason_edit: "NO",
  unmarket_reason_delete: "NO",

  land_vacancy_view: "YES",
  land_vacancy_update: "YES",

  downtime_report_view: "NO",

  convert_property_view: "NO",
  home_view: "YES",
};

export const viewerPermissions = {
  user_create: "NO",
  user_view: "YES",
  user_edit: "NO",

  market_create: "NO",
  market_view: "NO",

  property_rollup_create: "NO",

  property_roll_up_view: "NO",

  property_add: "NO",
  property_edit: "NO",
  property_view: "YES",
  property_publish: "NO",
  property_unpublish: "NO",

  unit_add: "NO",
  unit_edit: "NO",
  unit_publish: "NO",
  unit_unpublish: "NO",
  property_contact_history_export: "YES",

  announcement_create: "NO",

  sub_market_create: "NO",

  helper_text_create: "NO",
  helper_text_view: "YES",
  helper_text_edit: "NO",
  helper_text_export: "YES",

  template_create: "NO",
  template_view: "YES",
  template_edit: "NO",
  template_export: "YES",

  feature_flag_view: "YES",
  feature_flag_edit: "NO",

  unit_vacancy_update: "NO",
  unit_vacancy_view: "YES",

  audit_add: "NO",
  audit_edit: "NO",
  audit_delete: "NO",
  audit_view: "YES",
  audit_comment: "NO",
  audit_export: "YES",
  audit_status_edit: "NO",

  unmarket_reason_create: "NO",
  unmarket_reason_view: "YES",
  unmarket_reason_edit: "NO",
  unmarket_reason_delete: "NO",

  land_vacancy_view: "YES",
  land_vacancy_update: "NO",

  downtime_report_view: "NO",

  convert_property_view: "NO",
  home_view: "YES",
};
