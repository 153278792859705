import { Card, Col, Form, Row, Typography } from "antd";
import _get from "lodash/get";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React from "react";
import "./Characteristics.scss";

const { Text } = Typography;

const PreFilledDataCard = ({ preFilledCardProps, form }) => (
  <>
    <Card title={preFilledCardProps.title} className="pre-filled-characteristics">
      {_map(preFilledCardProps.data, (val, key) => (
        <Row key={key}>
          <Col md={12}>{key}</Col>
          <Col md={12}>
            <Form.Item shouldUpdate noStyle>
              {() => {
                const _val = _get(val?.isExternal ? val?.parent : form.getFieldValue(val?.parent), val?.child, "-");
                return (
                  <Text ellipsis title={_val}>
                    {`: ${_val}`}
                  </Text>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      ))}
    </Card>
  </>
);

PreFilledDataCard.propTypes = {
  preFilledCardProps: PropTypes.object,
  form: PropTypes.object,
};

export default PreFilledDataCard;
