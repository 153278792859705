import { Layout, Spin } from "antd";
import _isEmpty from "lodash/isEmpty";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import Forbidden from "../components/Forbidden";
import NoMatch from "../components/NoMatch";
import PrivateRoute from "../components/PrivateRoute";
import PropertySearchPreview from "../containers/PropertySearchPreview/PropertySearchPreview";
import MainLayoutProvider from "../providers/mainLayout.provider";
import { AuthService, signOut } from "../services/auth.service";
import { checkAuth, userRequest } from "../store/actions/user.actions";
import history from "../utils/history.utils";
import { toastMessage } from "../utils/utils";

function Routes() {
  const { t } = useTranslation();
  const loading = useSelector(state => state.user.loading);
  const user = useSelector(state => state.user.value);
  const dispatch = useDispatch();

  useState(() => {
    dispatch(userRequest());
    dispatch(checkAuth());
  });

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        // console.log("Browser tab is hidden");
      } else {
        const [currentAccount = null] = AuthService.getAllAccounts();

        if (!currentAccount && !window.location.href.includes("/loginResponse")) {
          toastMessage("info", "Your session is expired, we are redirecting to login page");
          setTimeout(() => {
            signOut();
          }, 3000);
        }
      }
    });
  }, []);

  return (
    <Router history={history}>
      <Suspense
        fallback={
          <div className="text-center mg-top-pc-20">
            <Spin size="large" />
          </div>
        }
      >
        <Layout className="app-layout-wrap">
          {loading || _isEmpty(user) ? (
            <div>
              <div className="edit-form__spin">
                <Spin size="large" tip={t("ErrorMessages.Loading")} />
              </div>
            </div>
          ) : (
            <Switch>
              <Route exact path="/404" component={NoMatch} />
              <Route exact path="/forbidden" component={Forbidden} />
              <PrivateRoute
                exact
                key="/preview-private-route"
                path="/preview"
                component={PropertySearchPreview}
                category="property"
                action="view"
              />
              <Route path="/" component={MainLayoutProvider} />
              <Route path="" component={NoMatch} />
            </Switch>
          )}
        </Layout>
      </Suspense>
    </Router>
  );
}

export default Routes;
