import { Button, Col, Form, Input, Row, Skeleton } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createSubMarket,
  getMarketByIdSuccess,
  marketSuccess,
  updateMarket,
  getActiveMarkets,
} from "../../store/actions/market.actions";
import { SEARCH_QUERY, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import "./AddEditSubMarket.scss";
import { validateCode } from "../../utils/utils";
import CustomSelect from "../../components/CustomSelect";

const queryParam = { filterCriteria: { isActive: true }, sort: "id,asc" };

const AddEditSubMarket = ({ subMarketId, onSuccess, onCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { value: subMarket, loading } = useSelector(store => store.subMarket);
  const { activeList: markets, loading: fetchingMarkets } = useSelector(store => store.market);

  const [form] = Form.useForm();

  const [fetchingSubMarket, setFetchingSubMarket] = useState(false);

  const onFormFinish = async fieldValues => {
    const _fieldValues = { ...fieldValues, market: fieldValues.market.value };
    if (!_isEmpty(subMarketId)) {
      delete _fieldValues.id;
      await dispatch(updateMarket(subMarketId, _fieldValues));
    } else {
      await dispatch(createSubMarket(_fieldValues));
    }
    onCloseModal(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  useEffect(() => {
    (async () => {
      fetchMarkets();
      if (!_isEmpty(subMarketId)) {
        setFetchingSubMarket(true);
        await dispatch(getMarketByIdSuccess(subMarketId));
        setFetchingSubMarket(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!_isEmpty(subMarket)) {
      form.setFieldsValue({
        market: {
          ...subMarket.market,
          label: subMarket.market.id,
          value: subMarket.market.id,
        },
      });
    }
  }, [subMarket]);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [],
  );

  const fetchMarkets = async query => {
    let _query = query;
    if (!_query) {
      _query = { ...SEARCH_QUERY, ...queryParam };
    }
    dispatch(getActiveMarkets(_query));
  };

  const isValidCode = (rule, value) => {
    if (value && !validateCode(value)) {
      return Promise.reject(new Error(t("AddEditSubmarket.SubmarketCodeFormat")));
    }
    return Promise.resolve();
  };

  return (
    <div className="edit-sub-market">
      <Skeleton loading={fetchingSubMarket} active paragraph={{ rows: 6 }}>
        <Form
          form={form}
          layout="vertical"
          name="nest-messages"
          initialValues={subMarket}
          onFinish={onFormFinish}
          validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
        >
          <Row className="fields-row" gutter={20} type="flex">
            <Col span={12}>
              <Form.Item
                label={t("Table.SubmarketCode")}
                name="id"
                validateFirst
                rules={[{ required: true, whitespace: true }, { validator: isValidCode }]}
              >
                <Input placeholder={t("AddEditSubmarket.SubmarketCode")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("Table.SubmarketName")}
                name="subMarketName"
                validateFirst
                rules={[{ required: true, whitespace: true }]}
              >
                <Input placeholder={t("Table.SubmarketName")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate label={t("Table.MarketCode")} name="market" rules={[{ required: true }]}>
                <CustomSelect
                  placeholder={t("AddEditSubmarket.SelectMarketCode")}
                  loading={fetchingMarkets}
                  fetchOptions={fetchMarkets}
                  optionsList={markets}
                  queryParam={queryParam}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row className="fields-row" gutter={20} type="flex" justify="center">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button
                    block
                    onClick={() => {
                      onCloseModal(false);
                      dispatch(marketSuccess({}));
                    }}
                  >
                    {t("Buttons.DiscardChanges")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Button block type="primary" htmlType="submit" loading={loading}>
                    {subMarketId ? t("Buttons.SaveChanges") : t("Buttons.Create")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </div>
  );
};

AddEditSubMarket.propTypes = {
  subMarketId: PropTypes.string,
  onSuccess: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default AddEditSubMarket;
