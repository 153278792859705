import { get, put, post, remove, patch } from "./http.service";
import { delay, getSearchListQuery } from "../utils/utils";
import { employeeContactList } from "../__mocks__/employees";

const BrokerService = {
  // getAllBrokers: () => {
  //   // await delay(2000);
  //   // return new Promise(resolve => resolve(employeeContactList));
  //   return get(`/broker`);
  // },
  // getBrokerById: async id => {
  //   await delay(2000);
  //   const response = employeeContactList.find(value => value.id === id) || {};
  //   return new Promise(resolve => resolve(response));
  // },
  // updateBroker: async payload => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve());
  // },
  // createBroker: async payload => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve());
  // },
  suggestions: query => get(`user/suggestion${getSearchListQuery(query)}`),
  searchBrokers: query => get(`user/search${getSearchListQuery(query)}`),
  getAllBrokers: () => get("user"),
  exportBrokers: () => get("user/export?type=broker", { responseType: "base64" }),
  importBrokers: (body, getProgress) => post("user/import", body, { onUploadProgress: getProgress }),
  getBrokerById: id => get(`user/${id}`),
  getBrokerAssociatedProperties: id => get(`user/${id}/properties?type=broker`),
  updateBroker: (id, body) => put(`user/${id}`, body),
  createBroker: body => post(`user`, body),
  removeBroker: id => remove(`user/${id}`),
};
export default BrokerService;
