import { Button, Col, Form, Input, Row, Space, Switch } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import "../../index.scss";
import { updateAnnouncement } from "../../store/actions/announcement.action";
import {
  ANNOUNCEMENT_DEFAULT_MESSAGE,
  ANNOUNCEMENT_TITLE_LENGTH,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
} from "../../utils/constants";
import "./Editor.scss";

const Editor = ({ announcement, onCloseModal, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [value, setValue] = useState(announcement ? announcement.message : "");
  const checkBody = value;
  const saveValue = async feildvalues => {
    const textNode = {
      message: value,
      title: feildvalues.title,
      subtitle: feildvalues.subtitle,
      isEnable: !!feildvalues.isEnable,
    };

    if (checkBody) {
      await dispatch(updateAnnouncement(announcement.id, textNode));
      onCloseModal(false);
      if (onSuccess) onSuccess();
    }
    // else {
    // await dispatch(updateAnnouncement(announcement_id, textNode));
    // onCloseModal(false);
    // }
  };

  const resetValue = () => {
    onCloseModal(false);
  };
  const _onChange = (val, delta, source, editor) => {
    const messageValue = editor.getText();
    if (editor.getText().length > 1 && messageValue.replace("/\t|\r/", "") >= 0) {
      setValue("");
    } else if (editor.getText().length > 1) {
      setValue(val);
    } else {
      setValue("");
    }
  };
  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className="editor"
      form={form}
      name="basic"
      layout="vertical"
      initialValues={_isEmpty(announcement) ? ANNOUNCEMENT_DEFAULT_MESSAGE : announcement}
      onFinish={saveValue}
      validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      onFinishFailed={onFinishFailed}
    >
      <Col span={24} className="text-right">
        <Space className="text-right">
          <Form.Item className="enable-switch" valuePropName="checked" label={t("Editor.Enable")} name="isEnable">
            <Switch />
          </Form.Item>
        </Space>
      </Col>
      <Form.Item
        label={t("Editor.Title")}
        name="title"
        rules={[{ required: true, whitespace: true, max: ANNOUNCEMENT_TITLE_LENGTH }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Editor.Subtitle")}
        name="subtitle"
        rules={[{ required: true, whitespace: true, max: ANNOUNCEMENT_TITLE_LENGTH }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Editor.Message")}
        name="message"
        rules={[{ required: true, /* message: "Please input your announment!" */ whitespace: true }]}
      >
        <ReactQuill
          theme="snow"
          value={value}
          onChange={_onChange}
          preserveWhitespace
          className={`quil-component ${!value ? "quilclass" : "quilgreen"}`}
        />
      </Form.Item>
      {!value ? <p className="quilclassmsg">{t("Editor.EnterAnnouncement")}</p> : ""}

      <Row justify="end">
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            /* onClick={saveValue} */ className="saveButton"
            // disabled={!checkBody}
          >
            {t("Buttons.Save")}
          </Button>
        </Col>
        <Col>
          <Button onClick={resetValue} className="cancelButton">
            {t("Buttons.Cancel")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
Editor.propTypes = {
  announcement: PropTypes.object,
  onCloseModal: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default Editor;
