import { Col, Form, Row, Select } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useKeyword } from "../../hooks";
import { DEFAULT_SORTER, SEARCH_QUERY } from "../../utils/constants";
import { searchQueryGenerator, submitSearchForm } from "../../utils/utils";
import SearchInput from "../SearchInput/SearchInput";
import "./SearchUserForm.scss";
const { Option } = Select;

const SearchUserForm = ({ pagination, loading, getList, getSuggestions, filterCriteria }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { keyword, setKeyword } = useKeyword({ pagination });

  const onFormFinish = async (value, option) => {
    let sorter = DEFAULT_SORTER;
    if (!_isEmpty(pagination.sort)) {
      const sortArr = pagination.sort.split(",");
      sorter = { field: sortArr[0], order: sortArr[1] };
    }

    const filterCriteriaNew = {
      ...filterCriteria,
    };

    if (option?.key) filterCriteriaNew.id = option.key;

    const query = {
      ...pagination,
      currentPage: 1,
      keyword: option?.key ? null : keyword,
      sorter: _isEmpty(keyword) ? sorter : null,
      pageSize: pagination.size,
      filterCriteria: filterCriteriaNew,
      labelInValue: !_isEmpty(option) ? option : undefined,
    };

    const _query = searchQueryGenerator(query);

    await dispatch(getList(_query));
  };

  const getOptions = val => dispatch(getSuggestions({ ...SEARCH_QUERY, filterCriteria, keyword: val }));

  const _onSubmit = (flag = true, option) => {
    submitSearchForm(flag, option, pagination, form, onFormFinish);
  };

  const _onEnter = () => {
    if (keyword.length) _onSubmit();
  };

  return (
    <Form form={form} onFinish={onFormFinish} className="search-user-form">
      <Row gutter={[16, 16]} className="row-vertical-padding-4">
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={4}>
          <SearchInput
            value={keyword}
            setValue={setKeyword}
            onSelectValue={_onSubmit}
            loading={loading}
            getOptions={getOptions}
            onEnter={_onEnter}
            placeholder={t("AdminSearch.EmployeesPlaceholder")}
            renderOptions={value => (
              <Option key={value.id} value={`${value?.firstName} ${value?.lastName}`}>
                {`${value?.firstName} ${value?.lastName}`}
              </Option>
            )}
          />
        </Col>
      </Row>
    </Form>
  );
};

SearchUserForm.propTypes = {
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  getList: PropTypes.func,
  getSuggestions: PropTypes.func,
  filterCriteria: PropTypes.object,
};

export default SearchUserForm;
