/* eslint-disable no-restricted-globals */
import _isEmpty from "lodash/isEmpty";
import { AuthService, signIn, signOut } from "../../services/auth.service";
import permissionsUtil from "../../utils/permissions.util";
import { toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";
import history from "../../utils/history.utils";

export function userRequest() {
  return { type: ACTIONS.USER_REQUEST };
}

export function userSuccess(response) {
  return { type: ACTIONS.USER_SUCCESS, response };
}

export function getAllUsersSuccess(response) {
  return { type: ACTIONS.GET_ALL_USERS_SUCCESS, response };
}

export function userError(error) {
  return { type: ACTIONS.USER_ERROR, error };
}

async function handleEmptyResponse() {
  const currentAccounts = AuthService.getAllAccounts();
  if (!currentAccounts || currentAccounts.length < 1) {
    return signIn({ state: location.pathname });
  }
  return AuthService.acquireTokenSilent({
    account: currentAccounts[0],
    scopes: ["openid", "profile"],
  });
}

export function checkAuth() {
  return async dispatch => {
    dispatch(userRequest(true));
    let response;
    try {
      response = await AuthService.handleRedirectPromise();
      if (_isEmpty(response)) {
        response = await handleEmptyResponse(response);
        if (!response) throw new Error("No Session Found");
      }
      const roles = response.idTokenClaims && response.idTokenClaims.roles;
      permissionsUtil.setPermissions(roles);
      dispatch(userSuccess(response));
      if (response.state) history.push(response.state);
    } catch (error) {
      console.log("checkAuthError", error);
      const [currentAccount = null] = AuthService.getAllAccounts();
      const state = location.pathname;
      if (currentAccount?.username) {
        const loginHint = currentAccount.username;
        await AuthService.acquireTokenRedirect({
          loginHint,
          state,
        });
      } else {
        setTimeout(() => {
          signIn({ state });
        }, 500);
      }
      dispatch(userError(error));
    }
  };
}

// export function handleRedirectPromise() {
//   return async dispatch => {
//     dispatch(userRequest());
//     try {
//       const response = await AuthService.handleRedirectPromise();
//       if (!_isEmpty(response)) {
//         const roles = response.idTokenClaims && response.idTokenClaims.roles;
//         permissionsUtil.setPermissions(roles);
//         dispatch(userSuccess(response));
//         if (response.state) history.push(response.state);
//       }
//       await handleEmptyResponse(response);
//     } catch (error) {
//       console.log("handleRedirectPromise Catach: ", error);
//     }
//   };
// }

export function logout() {
  return async dispatch => {
    dispatch(userRequest());
    const currentAccount = AuthService.getAllAccounts();
    if (currentAccount && currentAccount.length > 0) {
      try {
        const accountId = currentAccount[0].username;
        await signOut(accountId);
      } catch (error) {
        toastMessage("error", "Unable to Logout, please try again");
      }
    } else {
      toastMessage("info", "Your session is expired, we are reloading page");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    return currentAccount;
  };
}
