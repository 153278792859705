import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const broker = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.BROKER_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.BROKER_SUGGESTION_REQUEST:
      draft.suggestionLoading = true;
      return draft;
    case ACTIONS.BROKER_SUGGESTION_SUCCESS:
      draft.suggestionLoading = false;
      draft.error = null;
      return draft;
    case ACTIONS.BROKER_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.value = action.response;
      return draft;
    case ACTIONS.BROKER_ASSOCIATED_PROPERTIES_SUCCESS:
      draft.loadingAssociatedProperties = false;
      draft.associatedProperties = action.response;
      return draft;
    case ACTIONS.GET_ALL_BROKERS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    case ACTIONS.GET_BROKER_BY_ID_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.value = action.response;
      return draft;
    case ACTIONS.BROKER_ERROR:
      draft[action.key] = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.BROKER_SUGGESTION_ERROR:
      draft.suggestionLoading = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default broker;
