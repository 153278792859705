import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationCS from "./locales/cs/translation.json";
import translationDE from "./locales/de/translation.json";
import translationESM from "./locales/es-m/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationHU from "./locales/hu/translation.json";
import translationIT from "./locales/it/translation.json";
import translationJP from "./locales/jp/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationPL from "./locales/pl/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationSE from "./locales/se/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationSK from "./locales/sk/translation.json";

export const getLanguageCode = () => i18n.language;

const languageLookup = {
  en: translationEN,
  es: translationES,
  cs: translationCS,
  de: translationDE,
  "es-m": translationESM,
  fr: translationFR,
  hu: translationHU,
  it: translationIT,
  jp: translationJP,
  nl: translationNL,
  pl: translationPL,
  pt: translationPT,
  se: translationSE,
  zh: translationZH,
  sk: translationSK,
};

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export const getLocalTranslatedValue = (languageCode, value) => languageLookup[languageCode].PropertyPreview[value]
  ? languageLookup[languageCode].PropertyPreview[value]
  : languageLookup.en.PropertyPreview[value];

export default i18n;
