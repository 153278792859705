import { Button, Col, Form, Input, Popconfirm, Row, Space, Spin, Upload } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ERROR_MESSAGE, VALIDATE_FORM_MESSAGES_TEMPLATE } from "../../utils/constants";
import { toastMessage } from "../../utils/utils";
import CustomIcon from "../CustomIcon/CustomIcon";

const antIcon = <CustomIcon name="LoadingOutlined" style={{ fontSize: 20 }} spin />;

const AuditEditor = ({
  issue,
  onCancel,
  onSubmit,
  auditLoading,
  onIssueChange,
  buttonTitle,
  attachments = [],
  attachmentType,
}) => {
  const { t } = useTranslation();

  const [fileList, setFileList] = useState(attachments);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { loading } = useSelector(state => ({
    ...state.upload,
  }));

  const handleChange = info => {
    const { file } = info;
    const isValidFileType = [...info.fileList]
      .filter(_file => !_file.url)
      .every(_file => _file.type === "image/png" || _file.type === "image/jpeg" || _file.type === "image/jpg");

    if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
      toastMessage("error", ERROR_MESSAGE.IMAGE_TYPE);
    }

    if (isValidFileType) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map(_file => {
        if (_file.response) {
          _file.url = _file.response.url;
        }
        return _file;
      });
      setFileList(newFileList);
    }
  };

  const onRemoveAttachment = async file => {
    const fileListClone = [...fileList];
    const fileIndex = fileList.indexOf(file);
    fileListClone.splice(fileIndex, 1);
    if (file.url) {
      setDeletedFiles([...deletedFiles, file.fileName]);
    }
    setFileList(fileListClone);
  };

  const disabledFormItems = loading || isLoading;
  const disabledAddButton = _isEmpty(issue) || isLoading || auditLoading;

  const props = {
    fileList,
    disabled: disabledFormItems,
    accept: "image/*",
    multiple: true,
    onChange: handleChange,
    beforeUpload: () => false,
    onRemove: () => false,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: file => (
        <Popconfirm
          title={t("Upload.ConfirmationMessage")}
          onConfirm={() => onRemoveAttachment(file)}
          okText="Yes"
          cancelText="No"
        >
          <CustomIcon name="deleteIcon" size={16} className="vertical-baseline" />
        </Popconfirm>
      ),
    },
  };

  const _onSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmit(fileList, deletedFiles);
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form layout="vertical" validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}>
      <Form.Item label={t("AddEditProperty.Audit.IssueDescription")} name="issue" rules={[{ required: true }]}>
        <Input.TextArea
          placeholder={t("AddEditProperty.Audit.IssueDescription")}
          row={5}
          onChange={onIssueChange}
          defaultValue={issue}
          disabled={disabledFormItems}
        />
      </Form.Item>
      <div className="attach-document">
        {t("AddEditProperty.Audit.AttachDocument")}
        <Upload {...props}>
          <Button type="link">
            <CustomIcon name="PaperClipOutlined" />
          </Button>
        </Upload>
        {loading && (
          <p className="font-12">
            {attachmentType ? `${t("Upload.UploadingAttachment")}` : `${t("Upload.UpdatingAttachment")}`}
            &nbsp;
            <Spin indicator={antIcon} />
          </p>
        )}
      </div>
      <Row justify="end">
        <Col>
          <Space>
            <Button type="text" onClick={onCancel}>
              {t("Buttons.Cancel")}
            </Button>
            <Button type="primary" loading={auditLoading} onClick={_onSubmit} disabled={disabledAddButton}>
              {buttonTitle}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

AuditEditor.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  issue: PropTypes.string,
  auditLoading: PropTypes.bool,
  onIssueChange: PropTypes.func,
  buttonTitle: PropTypes.string,
  attachments: PropTypes.array,
  attachmentType: PropTypes.bool,
};
export default AuditEditor;
