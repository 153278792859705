import { Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchAuditors from "../../containers/SearchAuditors/SearchAuditors";
import SearchBroker from "../../containers/SearchBroker/SearchBroker";
import SearchEmployeeContact from "../../containers/SearchEmployeeContact/SearchEmployeeContact";
import SearchMarket from "../../containers/SearchMarket/SearchMarket";
import SearchPropertyRollUp from "../../containers/SearchPropertyRollUp/SearchPropertyRollUp";
import SearchPropertyContactHistory from "../../containers/SearchPropertyContactHistory/SearchPropertyContactHistory";
import SearchSubMarket from "../../containers/SearchSubMarket/SearchSubMarket";
import "./AdminSearch.scss";

const { Title } = Typography;
const AdminSearch = ({ type, tabsKey }) => {
  const { t } = useTranslation();

  const SearchTabsLookup = {
    employeeContacts: <SearchEmployeeContact />,
    brokers: <SearchBroker />,
    auditors: <SearchAuditors />,
    markets: <SearchMarket />,
    subMarkets: <SearchSubMarket />,
    contactsHistory: <SearchPropertyContactHistory />,
    propertyRollUp: <SearchPropertyRollUp />,
  };

  return (
    <div className="admin-search">
      {SearchTabsLookup[type] && <Title level={4}>{t("AdminSearch.Title")}</Title>}
      {SearchTabsLookup[type]}
    </div>
  );
};

AdminSearch.propTypes = {
  type: PropTypes.string,
  tabsKey: PropTypes.object,
};

export default AdminSearch;
