import { Button, Col, Dropdown, Form, Menu, Row, Spin, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import EditableTagGroup from "../../components/EditableTagGroup/EditableTagGroup";
import PageTitle from "../../components/PageTitle/PageTitle";
import PopUp from "../../components/PopUp/PopUp";
import PropertyCodeSelect from "../../components/PropertyCodeSelect/PropertyCodeSelect";
import SearchListView from "../../components/SearchListView/SearchListView";
import {
  usePropertyFilterTags,
  usePropertyPagination,
  useCurrentVacantPagination,
  useCurrentVacantPropertyFilterTags,
} from "../../hooks";
import { homePaginationSuccess, homeTagsSuccess } from "../../store/actions/home.actions";
import { sendAnalyticsEvent } from "../../store/actions/analytics.actions";
import { updateBuilding } from "../../store/actions/building.actions";
import { updateLand } from "../../store/actions/land.actions";
import {
  convertProperty,
  getPropertiesList,
  getDashboardPropertiesList,
  propertyTagsSuccess,
  currentVacantPropertiesTagsSuccess,
} from "../../store/actions/property.actions";
import {
  DATE_FORMAT_TIME,
  DEFAULT_PROPERTY_SORTER,
  FIELD_TYPE,
  GTAG_EVENTS,
  HOME_PROPERTIES_TITLE,
  HOME_UNITS_TYPE,
  PAGINATOIN,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
  PROPERTY_TYPE_UI_MAPPING,
  ROUTES,
  SORT_DIRECTION,
  TAG,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { renderPropertyStatus } from "../../utils/property.util";
import { getFeatureFlags, setSessionStorage } from "../../utils/utils";
import AddPropertyForm from "../AddPropertyForm/AddPropertyForm";
import SearchPropertyHorizontal from "../SearchPropertyHorizontal/SearchPropertyHorizontal";
import "./Properties.scss";

const Properties = () => {
  const dispatch = useDispatch();
  const { type: _type } = useParams();
  const { t } = useTranslation();

  const {
    loading,
    list,
    pagination,
    currentVacantPropertyPagination,
    tags,
    currentVacantPropertyTags,
    loadingCodes,
    propertyCodes,
    loadingConvertProperty,
  } = useSelector(state => state.property);
  const { homePagination, homeTags } = useSelector(state => state.home);
  const { loading: loadingBuilding } = useSelector(state => state.building);
  const { loading: loadingLand } = useSelector(state => state.land);
  const { list: markets, loading: loadingMarket } = useSelector(state => state.market);

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [propertyType, setPropertyType] = useState(undefined);
  const [convertToRegularProperty, setConvertToRegularProperty] = useState(false);
  const [permanentProperty, setPermanentProperty] = useState("");
  const [propertyCodeError, setPropertyCodeError] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isFromHome, setIsFromHome] = useState(false);
  const { propertyTag } = usePropertyFilterTags({ tags, tagType: TAG.properties });
  const { currentVacantPropertyTag } = useCurrentVacantPropertyFilterTags({
    tags: currentVacantPropertyTags,
    tagType: TAG.currentVacantProperties,
  });
  const { propertyPagination } = usePropertyPagination({ pagination, paginationType: PAGINATOIN.properties });
  const { currentVacantPagination } = useCurrentVacantPagination({
    pagination: currentVacantPropertyPagination,
    paginationType: PAGINATOIN.currentVacantPagination,
  });
  const homePaginationResponse = usePropertyPagination({ pagination: homePagination, paginationType: PAGINATOIN.home });

  const canAddProperty = permissionsUtil.checkAuth("property_add");
  const canEditProperty = permissionsUtil.checkAuth("property_edit");
  const canUnPublishProperty = permissionsUtil.checkAuth("property_unpublish");
  const canConvertProperty = permissionsUtil.checkAuth("convert_property_view");

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    if (!_isEmpty(HOME_UNITS_TYPE[_type])) {
      setIsFromHome(true);
      let _newPropertyTags = [];
      const homePaginationValues = homePaginationResponse.propertyPagination;
      if (!_isEmpty(homePaginationValues) && !_isEmpty(homePaginationValues?.filterCriteria)) {
        // If market and subMarket are not available, use division and region to filter markets.
        if (
          _isEmpty(homePaginationValues?.filterCriteria?.market) &&
          _isEmpty(homePaginationValues?.filterCriteria?.subMarket)
        ) {
          let availableMarkets = [];
          // If division and region are also not available, no markets and submarket are selected
          if (
            !_isEmpty(homePaginationValues?.filterCriteria?.division) ||
            !_isEmpty(homePaginationValues?.filterCriteria?.region)
          ) {
            // If region is available, filter markets by region, else use division.
            if (!_isEmpty(homePaginationValues?.filterCriteria?.region)) {
              availableMarkets = [
                ...new Set(
                  markets.filter(market =>
                    (homePaginationValues?.filterCriteria?.region).includes(market.region?.code),
                  ),
                ),
              ];
            } else {
              availableMarkets = [
                ...new Set(
                  markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
                ),
              ];
            }
            availableMarkets = [...new Set(availableMarkets.map(element => element.id))];
            _newPropertyTags = ["building", ...availableMarkets];
          } else {
            _newPropertyTags = ["building"];
          }
        } else {
          _newPropertyTags = [
            "building",
            ...(homePaginationValues?.filterCriteria?.market || []),
            ...(homePaginationValues?.filterCriteria?.subMarket || []),
          ];
        }
        (async () => {
          await dispatch(currentVacantPropertiesTagsSuccess(_newPropertyTags));
        })();
      }
    }
  }, []);

  useEffect(() => {
    // Add tags from filtered views to home.
    if (!_isEmpty(HOME_UNITS_TYPE[_type])) {
      const homePaginationValues = homePaginationResponse.propertyPagination;
      const oldFilterCriteria = homePaginationValues?.filterCriteria;
      let oldMarkets = [],
        oldSubMarkets = [];
      // If market and subMarket are not available, use division and region to filter markets.
      if (
        _isEmpty(homePaginationValues?.filterCriteria?.market) &&
        _isEmpty(homePaginationValues?.filterCriteria?.subMarket)
      ) {
        // If division and region are also not available, no markets and submarket are selected
        if (
          !_isEmpty(homePaginationValues?.filterCriteria?.division) ||
          !_isEmpty(homePaginationValues?.filterCriteria?.region)
        ) {
          // If region is available, filter markets by region, else use division.
          if (!_isEmpty(homePaginationValues?.filterCriteria?.region)) {
            oldMarkets = [
              ...new Set(
                markets.filter(market => (homePaginationValues?.filterCriteria?.region).includes(market.region?.code)),
              ),
            ];
          } else {
            oldMarkets = [
              ...new Set(
                markets.filter(({ division }) => (homePaginationValues?.filterCriteria?.division).includes(division)),
              ),
            ];
          }
          oldMarkets = [...new Set(oldMarkets.map(element => element.id))];
        } else {
          oldMarkets = oldFilterCriteria?.market || [];
          oldSubMarkets = oldFilterCriteria?.subMarket || [];
        }
      } else {
        oldMarkets = oldFilterCriteria?.market || [];
        oldSubMarkets = oldFilterCriteria?.subMarket || [];
      }
      const newMarkets = currentVacantPagination?.filterCriteria?.market || [];
      const newSubMarkets = currentVacantPagination?.filterCriteria?.subMarket || [];
      const filterCriteria = {
        ...oldFilterCriteria,
        market: newMarkets,
        subMarket: newSubMarkets,
      };
      const newHomeTags = [
        ...(oldFilterCriteria?.division || []),
        ...(oldFilterCriteria?.region || []),
        ...newMarkets,
        ...newSubMarkets,
      ];
      setSessionStorage(PAGINATOIN.home, { filterCriteria });
      setSessionStorage(TAG.home, newHomeTags);
      dispatch(homePaginationSuccess({ filterCriteria }));
      dispatch(homeTagsSuccess(newHomeTags));
    }
  }, [currentVacantPropertyTags]);

  useEffect(() => {
    if (!loadingCodes) {
      setIsLoading(false);
    }
  }, [propertyCodes, loadingCodes]);

  /**
   * Navigate to property edit form based on property type
   */
  const getNavigationLink = (type, route) => {
    switch (type) {
      case PROPERTY_TYPE_KEYS.building:
        return ROUTES[`${route}_BUILDING`].path;
      case PROPERTY_TYPE_KEYS.land:
        return ROUTES[`${route}_LAND`].path;
      default:
        return ROUTES[`${route}_BUILDING`].path;
    }
  };

  /**
   * To update property based on property type
   */
  const update = (record, propertyInfo) => {
    switch (record.propertyType) {
      case PROPERTY_TYPE_KEYS.building:
        return updateBuilding(record.id, propertyInfo, `Building ${propertyInfo.status} successfully`);
      case PROPERTY_TYPE_KEYS.land:
        return updateLand(record.id, propertyInfo, `Land ${propertyInfo.status} successfully`);
      default:
        return updateBuilding(record.id, propertyInfo, `Building ${propertyInfo.status} successfully`);
    }
  };

  const convertTempPropertyKeyAction = (event, data) => {
    if (event.keyCode === 13) onOpenConvertTempPropertyModal(data);
  };

  const onOpenConvertTempPropertyModal = record => {
    setIsLoading(true);
    setSelectedProperty(record);
    setConvertToRegularProperty(true);
  };

  const _onCloseConvertTempPropertyModal = () => {
    setConvertToRegularProperty(false);
    setPermanentProperty("");
    setSelectedProperty({});
  };

  const _setSelectedProperty = async params => {
    setPermanentProperty(params.value);
  };

  const propertyDetails =
    selectedProperty.propertyType === PROPERTY_TYPE_KEYS.building
      ? {
        propertyCodeLabel: t("BuildingInfo.BuildingCode"),
        newPropertyLabel: t("BuildingInfo.NewBuildingLabel"),
        propertyCodePlaceholder: t("BuildingInfo.BuildingCodePlaceholder"),
      }
      : {
        propertyCodeLabel: t("Table.PropertyCode"),
        newPropertyLabel: t("Property.NewPropertyLabel"),
        propertyCodePlaceholder: t("Property.PropertyCodePlaceholder"),
      };

  const columns = [
    {
      title: t("PropertiesTable.PropertyCode"),
      dataIndex: "propertyCode",
      key: "propertyCode",
      fixed: "left",
      width: 150,
      render: (text, record) => {
        const link = getNavigationLink(record.propertyType, "VIEW");
        return (
          <>
            <Link to={`${link}/${record?.id}`}>{text}</Link>
            {record.isTemp && (
              <Tooltip title={t("Property.TemporaryProperty")}>
                <CustomIcon className="mg-left-10" name="FileProtectOutlined" style={{ color: "green" }} />
              </Tooltip>
            )}
            {record?.isDraft && (
              <>
                <Tag className="text-capitalize float-right" color="warning">
                  {t("PropertyStatusUiMapping.draft")}
                </Tag>
              </>
            )}
          </>
        );
      },
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyCode" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Name"),
      dataIndex: "marketingPropertyName",
      key: "marketingPropertyName",
      ellipsis: true,
      width: 150,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketingPropertyName" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.StreetAddress"),
      dataIndex: "streetAddress1",
      key: "streetAddress1",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "streetAddress1" && sortInfo?.order,
      width: 200,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t("PropertiesTable.Status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      render: text => <>{renderPropertyStatus(text)}</>,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "status" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.Type"),
      dataIndex: "propertyType",
      key: "propertyType",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyType" && sortInfo?.order,
      width: 100,
      render: text => PROPERTY_TYPE_UI_MAPPING[text],
    },
    {
      title: t("PropertiesTable.Market"),
      dataIndex: "market",
      key: "market",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "market" && sortInfo?.order,
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={text}>
          <Tag className="ellipsis" color="geekblue">
            {text}
          </Tag>
        </Tooltip>
      ),
    },
  ];

  /**
   * Conditional column, will be displayed if the
   * feature is enabled
   */
  if (FEATURE_FLAG.SUBMARKET?.status) {
    columns.push({
      title: t("PropertiesTable.Submarket"),
      dataIndex: "subMarket",
      key: "subMarket",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "subMarket" && sortInfo?.order,
      render: text =>
        text ? (
          <Tooltip className="text-capitalize" placement="top" title={text}>
            <Tag className="ellipsis" color="geekblue">
              {text}
            </Tag>
          </Tooltip>
        ) : (
          "N/A"
        ),
    });
  }

  columns.push(
    {
      title: t("PropertiesTable.Units"),
      dataIndex: "units",
      key: "units",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "units" && sortInfo?.order,
      width: 75,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.units) ? record.units : "N/A",
    },
    {
      title: t("PropertiesTable.TotalUnits"),
      dataIndex: "totalUnits",
      key: "totalUnits",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "totalUnits" && sortInfo?.order,
      width: 100,
      render: (text, record) =>
        record?.propertyType === FIELD_TYPE.building && _isNumber(record?.totalUnits) ? record.totalUnits : "N/A",
    },
    {
      title: t("PropertiesTable.LastModifiedDate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
      sorter: true,
      sortDirections: SORT_DIRECTION.threeDirection,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.InitialPublishDate"),
      dataIndex: "initialPublishDate",
      key: "initialPublishDate",
      width: 150,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "initialPublishDate" && sortInfo?.order,
    },
    {
      title: t("PropertiesTable.LastPublishDate"),
      dataIndex: "lastPublishDate",
      key: "lastPublishDate",
      width: 150,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "lastPublishDate" && sortInfo?.order,
    },
  );

  /**
   * Actions column, to display only if the user has
   * publish or edit rights
   */
  if (canEditProperty || canUnPublishProperty || canConvertProperty) {
    columns.push({
      title: t("PropertiesTable.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 170,
      render: record => {
        const nextState = getNextState(record.status);
        const _statusUpdate = event => {
          event.stopPropagation();
          updateStatus(record, nextState);
        };
        const _link = getNavigationLink(record.propertyType, "EDIT");
        const isConvertIconVisible =
          record.isTemp ||
          (record.propertyType === FIELD_TYPE.land &&
            !(record.status === PROPERTY_STATUS.never_published && !record.isDraft));
        return (
          <div className="action-buttons">
            {/**
             * Display this block only if property is upgradeable
             * to next state
             */}
            {FEATURE_FLAG.CONVERTPROPERTY?.status && canConvertProperty && isConvertIconVisible ? (
              <Tooltip placement="bottom" title={t("DashboardContainerTabs.Convert")}>
                <div
                  className="action-buttons-item"
                  role="button"
                  tabIndex={0}
                  onKeyDown={ev => convertTempPropertyKeyAction(ev, record.id)}
                  onClick={() => {
                    onOpenConvertTempPropertyModal(record);
                  }}
                >
                  <div className="action-buttons-item">
                    <CustomIcon name="convertIcon" style={{ width: 17, color: "#5C5E5D" }} />
                    <p style={{ color: "#5C5E5D" }}>{t("DashboardContainerTabs.Convert")}</p>
                  </div>
                </div>
              </Tooltip>
            ) : null}
            {nextState && canUnPublishProperty ? (
              <Tooltip placement="bottom" title={<p className="text-capitalize">{nextState}</p>}>
                <div>
                  <PopUp
                    title={`${t("PropertiesTable.Sure")}${nextState} ${t("PropertiesTable.ThisProperty")}`}
                    onConfirm={_statusUpdate}
                    okText="Yes"
                    cancelText="No"
                    popupVisible={popupVisible}
                    onVisibleChange={() => togglePopupVisibility(!popupVisible)}
                  >
                    <div className="action-buttons-item">
                      <CustomIcon name={nextState} size={20} style={{ color: "#1b9a6c" }} />
                      <p className="text-capitalize">{nextState}</p>
                    </div>
                  </PopUp>
                </div>
              </Tooltip>
            ) : null}
            {canEditProperty ? (
              <Tooltip placement="bottom" title={t("PropertiesTable.Edit")}>
                <Link to={`${_link}/${record.id}`} className="action-buttons-item">
                  <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
                  <p style={{ color: "#5C5E5D" }}>{t("PropertiesTable.Edit")}</p>
                </Link>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    });
  }

  /**
   * To get the list of properties
   */
  const getList = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = isFromHome ? currentVacantPagination : pagination;
      }
      if (!_isEmpty(HOME_UNITS_TYPE[_type])) {
        await dispatch(getDashboardPropertiesList(_query, true, HOME_UNITS_TYPE[_type]));
      } else {
        setIsFromHome(false);
        await dispatch(getPropertiesList(_query));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const propertiesPageTitle = !_isEmpty(HOME_UNITS_TYPE[_type])
    ? HOME_PROPERTIES_TITLE[_type]
    : ROUTES.PROPERTIES.title;

  /**
   * Wrapper method to update status of the property
   * from listing
   */
  const updateStatus = async (record, status) => {
    try {
      if (status === "publish") {
        status = PROPERTY_STATUS.published;
      } else if (status === "unpublish") {
        status = PROPERTY_STATUS.unpublished;
      }
      await dispatch(update(record, { status }));
      dispatch(sendAnalyticsEvent(GTAG_EVENTS.UNPUBLISH_PROPERTY, { propertyCode: record.propertyCode }));
      getList(isFromHome ? currentVacantPagination : pagination);
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      togglePopupVisibility(false);
    }
  };

  /**
   * Wrapper method to trigger when updates are made
   * to filter tags
   */
  const onUpdateTags = async _tags => {
    if (isFromHome) {
      setSessionStorage(TAG.currentVacantProperties, []);
      setShouldFetchData(true);
      await dispatch(currentVacantPropertiesTagsSuccess(_tags));
    } else {
      setSessionStorage(TAG.properties, []);
      setShouldFetchData(true);
      await dispatch(propertyTagsSuccess(_tags));
    }
    setShouldFetchData(false);
  };

  /**
   * Get property's next state based on current status
   */
  const getNextState = currState => {
    switch (currState) {
      case "published":
        return t("PropertiesTable.Unpublish");
      default:
        return "";
    }
  };

  /**
   * Wrapper method for opening Create Property modal
   */
  const _onClickCreateProperty = ({ key }) => {
    setPropertyType(key);
  };

  /**
   * Wrapper method for close Create Property modal
   */
  const _closeCreatePropertyModal = () => {
    setPropertyType(undefined);
  };

  /**
   * Property form renderer in create property modal
   */
  const _renderPropertyForm = () => {
    let propertyFormProps = {
      onClose: _closeCreatePropertyModal,
      propertyType,
    };

    if (propertyType === PROPERTY_TYPE_KEYS.building) {
      propertyFormProps = {
        ...propertyFormProps,
        description: t("AddEditProperty.CreateBuilding.Description"),
        propertyCodeLabel: t("BuildingInfo.BuildingCode"),
        newPropertyLabel: t("BuildingInfo.NewBuildingLabel"),
        propertyCodePlaceholder: t("BuildingInfo.BuildingCodePlaceholder"),
      };
    } else if (propertyType === PROPERTY_TYPE_KEYS.land) {
      propertyFormProps = {
        ...propertyFormProps,
        description: t("AddEditLand.CreateLand.Description"),
        propertyCodeLabel: t("LandInfo.LandCode"),
        newPropertyLabel: t("LandInfo.NewLandLabel"),
        propertyCodePlaceholder: t("LandInfo.LandCodePlaceholder"),
      };
    }

    return <AddPropertyForm {...propertyFormProps} />;
  };

  /**
   * Menu items for Create Property Dropdown
   */
  const menu = (
    <Menu onClick={_onClickCreateProperty}>
      <Menu.Item key={PROPERTY_TYPE_KEYS.building}>{t("Properties.Menu.Building")}</Menu.Item>
      <Menu.Item key={PROPERTY_TYPE_KEYS.land}>{t("Properties.Menu.Land")}</Menu.Item>
    </Menu>
  );

  /**
   * Get modal title as per property type
   */
  const getModalTitle = () => {
    let _title;
    if (propertyType === PROPERTY_TYPE_KEYS.building) {
      _title = t("AddEditProperty.CreateBuilding.Title");
    } else if (propertyType === PROPERTY_TYPE_KEYS.land) {
      _title = t("AddEditLand.CreateLand.Title");
    }
    return _title;
  };

  const onConverToRegularProperty = async () => {
    const payload = {
      propertyCode: permanentProperty,
      priorPropertyCode: selectedProperty.propertyCode,
    };

    try {
      await dispatch(convertProperty(payload));
      getList();
      _onCloseConvertTempPropertyModal();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      getList();
      setConvertToRegularProperty(false);
    }
  };

  const loadingTable = loading || loadingBuilding || loadingLand || loadingMarket;

  return (
    <div className="properties">
      <PageTitle title={propertiesPageTitle} />
      <AppModal
        title={getModalTitle()}
        width={700}
        minHeight="20vh"
        maxHeight="100vh"
        overFlowY="auto"
        visible={!_isEmpty(propertyType)}
        onCancel={_closeCreatePropertyModal}
        destroyOnClose
        footer={null}
      >
        {_renderPropertyForm()}
      </AppModal>
      <AppModal
        title={t("ConvertProperty.ModalTitle")}
        width={600}
        minHeight="20vh"
        maxHeight="100vh"
        visible={convertToRegularProperty}
        onCancel={_onCloseConvertTempPropertyModal}
        overFlowY="auto"
        footer={null}
        destroyOnClose
        isNotDraggable
      >
        <Spin spinning={isLoading}>
          <Form layout="vertical">
            <Row gutter={20} className="row-vertical-padding-8">
              <Col span={24}>
                <PropertyCodeSelect
                  newCodeLabel={propertyDetails.newPropertyLabel}
                  label={propertyDetails.propertyCodeLabel}
                  placeholder={propertyDetails.propertyCodePlaceholder}
                  queryParam={{
                    filterCriteria: {
                      // status: [PROPERTY_STATUS.never_published],
                      isDraft: false,
                      isTemp: false,
                      market: [selectedProperty?.market],
                      ...((selectedProperty.propertyType === FIELD_TYPE.building ||
                        (selectedProperty.propertyType === FIELD_TYPE.land && !selectedProperty.isTemp)) && {
                        propertyType: [FIELD_TYPE.building],
                      }),
                    },
                  }}
                  isEditView={false}
                  setSelectedProperty={_setSelectedProperty}
                  viewOnlyMode={false}
                  setIsTemporaryProperty={() => {}}
                  propertyCodeError={propertyCodeError}
                  setPropertyCodeError={() => {}}
                  isConvertProperty
                />
              </Col>
            </Row>
          </Form>
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCloseConvertTempPropertyModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                disabled={_isEmpty(permanentProperty)}
                loading={loadingConvertProperty}
                onClick={onConverToRegularProperty}
                type="primary"
              >
                {t("Buttons.Update")}
              </Button>
            </Col>
          </Row>
        </Spin>
      </AppModal>
      <Row gutter={[16, 16]}>
        <Col xs={21}>
          <SearchPropertyHorizontal
            defaultSort={DEFAULT_PROPERTY_SORTER}
            getList={getList}
            shouldFetchData={shouldFetchData}
            pagination={isFromHome ? currentVacantPagination : propertyPagination}
            tags={isFromHome ? currentVacantPropertyTag : propertyTag}
            isFromHome={isFromHome}
            homePagination={homePaginationResponse.propertyPagination}
            homeTags={homeTags}
          />
        </Col>
        <Col xs={24} lg={3}>
          {canAddProperty && (
            <Dropdown overlay={menu}>
              <Button block className="add-property-btn">
                {t("Properties.Menu.AddProperty")}
                &nbsp;
                <CustomIcon name="DownOutlined" />
              </Button>
            </Dropdown>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[16, 16]} className="row-vertical-padding-8">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <EditableTagGroup tags={isFromHome ? currentVacantPropertyTag : propertyTag} updateTags={onUpdateTags} />
            </Col>
          </Row>
          <SearchListView
            paginationKey={isFromHome ? PAGINATOIN.currentVacantPagination : PAGINATOIN.properties}
            defaultSort={DEFAULT_PROPERTY_SORTER}
            preventInitialFetchList
            setFilterInfo={setFilterInfo}
            setSortInfo={setSortInfo}
            dataSource={list}
            columns={columns}
            loading={loadingTable}
            rowKey="id"
            getList={getList}
            pagination={isFromHome ? currentVacantPagination : pagination}
            scroll={{ x: 1575, y: "calc(100vh - 570px)" }}
          />
        </Col>
      </Row>
    </div>
  );
};

Properties.defaultProps = {};

Properties.propTypes = {};

export default Properties;
