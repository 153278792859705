import { Button, Col, Form, Radio, Row, Table, Typography } from "antd";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddEditBroker from "../../containers/AddEditBroker/AddEditBroker";
import { getBrokerByIdSuccess } from "../../store/actions/broker.actions";
import {
  getAllPropertyBrokersSuccess,
  getAllPropertyESMContactSuccess,
  getAllPropertyEmployeesSuccess,
  getPropertyBrokerById,
  getPropertyBrokers,
  getPropertyESMContacts,
  getPropertyEmployeeContacts,
} from "../../store/actions/property.actions";
import {
  BUILDING_REQUIRED_FIELDS_LOOKUP,
  BUILDING_TABS_KEYS,
  CONTACT_TYPE_UI_MAPPING,
  COUNTRY_NAME,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  LAND_TABS_KEYS,
  PROPERTY_TYPE_KEYS,
  SEARCH_QUERY,
  USER_ESM_TYPE,
  USER_TYPE,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { getContactLabel } from "../../utils/property.util";
import { replaceIfNull, getFeatureFlags } from "../../utils/utils";
import AppModal from "../AppModal/AppModal";
import CustomSelect from "../CustomSelect";
import CustomTooltipHelper from "../CustomTooltipHelper/CustomTooltipHelper";

const { Paragraph } = Typography;

const ContactsTab = ({ form, viewOnlyMode, helperText, auditPanelDrawerVisible, propertyType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    brokerList,
    loadingBrokers,
    employeeList,
    loadingEmployees,
    paginationBroker,
    paginationEmployee,
    loadingESM,
    esmList,
    paginationESM,
  } = useSelector(store => store.property);
  const { selected: selectedBuilding } = useSelector(state => state.building);
  const { selected: selectedLand } = useSelector(state => state.land);

  const [brokerId, setBrokerId] = useState(null);
  const [disableEditBroker, setDisableEditBroker] = useState(true);
  const [isBrokerFormModalOpen, setIsBrokerFormModal] = useState(false);
  const [isBrokersListModalOpen, setIsBrokersListModalOpen] = useState(false);
  const [propertyCountry, setPropertyCountry] = useState("");
  const [selectedBrokerContacts, setSelectedBrokerContacts] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const fieldsType = FORM_FIELDS_TYPE.regular;

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(
    () => () => {
      dispatch(getAllPropertyBrokersSuccess({ list: [], pagination: {} }));
      dispatch(getAllPropertyEmployeesSuccess({ list: [], pagination: {} }));
      dispatch(getAllPropertyESMContactSuccess({ list: [], pagination: {} }));
    },
    [],
  );

  useEffect(() => {
    if (propertyType === PROPERTY_TYPE_KEYS.building) setPropertyCountry(selectedBuilding?.market?.country);
    else if (propertyType === PROPERTY_TYPE_KEYS.land) setPropertyCountry(selectedLand?.market?.country);
  }, [selectedBuilding, selectedLand]);

  useEffect(() => {
    updateBrokerList();
  }, [form.getFieldValue(["contacts", "secondaryContacts"])]);

  const fetchAllPropertyEmployeesSuccess = () => {
    dispatch(
      getAllPropertyEmployeesSuccess({
        list: [...employeeList],
        pagination: { ...paginationEmployee },
      }),
    );
  };

  const fetchAllPropertyBrokersSuccess = () => {
    dispatch(
      getAllPropertyBrokersSuccess({
        list: [...brokerList],
        pagination: { ...paginationBroker },
      }),
    );
  };

  const fetchAllPropertyESMContactSuccess = () => {
    dispatch(
      getAllPropertyESMContactSuccess({
        list: [...esmList],
        pagination: { ...paginationESM },
      }),
    );
  };

  const onSelectPrologisContact = () => {
    fetchAllPropertyBrokersSuccess();
    fetchAllPropertyESMContactSuccess();
  };

  const onSelectSecondaryContact = () => {
    fetchAllPropertyEmployeesSuccess();
    fetchAllPropertyESMContactSuccess();
  };

  const onSelectESMContact = () => {
    fetchAllPropertyEmployeesSuccess();
    fetchAllPropertyBrokersSuccess();
  };

  const onSecondaryContactChange = val => {
    if (!val) {
      fetchAllPropertyEmployeesSuccess();
      fetchAllPropertyESMContactSuccess();
    } else {
      updateBrokerList();
    }
  };

  const getContactNameAndEmail = record => {
    const splitedRecord = record.label?.split("-");
    const name = splitedRecord[0].trim();
    const email = splitedRecord[1].split("(")[0].trim();
    return { name, email };
  };

  const getUpdatedBroker = async broker => {
    const secondaryContacts = form.getFieldValue(["contacts", "secondaryContacts"]);
    const values = secondaryContacts.map(_contact => {
      if (broker.id === _contact.value) {
        return {
          value: broker.id,
          label: getContactLabel(broker),
        };
      }
      return {
        ..._contact,
      };
    });
    form.setFieldsValue({
      contacts: {
        secondaryContacts: values,
      },
    });
    await fetchBrokers({
      ...SEARCH_QUERY,
      ...secondaryContactQueryParam,
    });
    form.validateFields([["contacts", "secondaryContacts"]]);
    setIsUpdated(true);
  };

  const getContactType = _broker => {
    const result = _broker?.label?.split(/[()]/)?.filter(v => !_isEmpty(v.trim()));
    return result?.length ? result[result.length - 2] : "";
  };

  const updateBrokerList = () => {
    const _contacts = form.getFieldValue(["contacts", "secondaryContacts"]);
    let _selectedBrokers = [];

    if (!_isEmpty(_contacts)) {
      _selectedBrokers = [..._contacts.filter(_broker => getContactType(_broker) === CONTACT_TYPE_UI_MAPPING.broker)];
    }

    setDisableEditBroker(_isEmpty(_selectedBrokers));
    setSelectedBrokerContacts(_selectedBrokers);
  };

  const fetchBrokers = async query => {
    dispatch(getPropertyBrokers(query));
  };

  const fetchBroker = async query => {
    const broker = await dispatch(getPropertyBrokerById(query));
    getUpdatedBroker(broker);
  };

  const fetchEmployees = async query => {
    dispatch(getPropertyEmployeeContacts(query));
  };

  const onBrokerAddSuccess = async _id => {
    if (_id) {
      fetchBroker(_id);
    }
  };

  const fetchESMContacts = async query => {
    dispatch(getPropertyESMContacts(query));
  };

  const _onClickOpenBrokerForm = () => {
    setIsBrokerFormModal(true);
  };

  const _onCancelBrokerFormModal = () => {
    setIsBrokerFormModal(false);
    dispatch(getBrokerByIdSuccess({}));
  };

  const _onClickEditBroker = () => {
    setIsBrokersListModalOpen(true);
  };

  const _onCancelBrokerListModal = () => {
    setBrokerId(null);
    setIsBrokersListModalOpen(false);
  };

  const filterOptionsList = (optionsList, selectedContact, selectedContactTwo) => {
    let _contacts = [];
    if (_isArray(selectedContact)) {
      _contacts = [...selectedContact];
    } else {
      _contacts = [selectedContact];
    }
    if (_isArray(selectedContactTwo)) {
      _contacts = [..._contacts, ...selectedContactTwo];
    } else {
      _contacts = [..._contacts, selectedContactTwo];
    }
    return optionsList && optionsList.length && _contacts
      ? optionsList.filter(item => !_contacts.find(sc => (sc?.id ? sc.id === item.id : sc?.value === item.id)))
      : optionsList;
  };

  const _tabsKey = propertyType === PROPERTY_TYPE_KEYS.land ? LAND_TABS_KEYS : BUILDING_TABS_KEYS;
  const _fieldName =
    propertyType === PROPERTY_TYPE_KEYS.land ? FORM_FIELDS_LOOKUP.land.contacts : FORM_FIELDS_LOOKUP.building.contacts;

  const getSelectedCompanyCount = (value = []) => {
    const selectedCompanyCount = {};
    value.forEach(selectedUser => {
      const result = selectedUser.label?.split(/[()]/)?.filter(v => !_isEmpty(v.trim()));
      if (!_isEmpty(result) && result[result.length - 2] === CONTACT_TYPE_UI_MAPPING.broker) {
        const company = result[result.length - 1];
        selectedCompanyCount[company] = selectedCompanyCount[company] ? selectedCompanyCount[company] + 1 : 1;
      }
    });

    return selectedCompanyCount;
  };

  const getContactsCounts = value => {
    const contactTypes = value?.map(v => getContactType(v));
    const brokersCount = contactTypes?.filter(contact => contact === CONTACT_TYPE_UI_MAPPING.broker).length;
    const employeeCount = contactTypes?.filter(contact => contact === CONTACT_TYPE_UI_MAPPING.employee).length;
    return { brokersCount, employeeCount };
  };

  const validateBrokersLengthPerCountry = (rule, value) => {
    if (!_isEmpty(value)) {
      const { brokersCount } = getContactsCounts(value);
      if (propertyCountry?.toLocaleLowerCase() === COUNTRY_NAME.UNITED_KINGDOM && brokersCount > 6) {
        return Promise.reject(new Error(t("ContactsTab.MoreThanSixBrokersErrorMessage")));
      }

      if (propertyCountry?.toLocaleLowerCase() !== COUNTRY_NAME.UNITED_KINGDOM && value?.length > 1) {
        return Promise.reject(new Error(t("ContactsTab.MoreThanOneSecondaryContactsErrorMessage")));
      }
    }

    return Promise.resolve();
  };

  const validateEmployeeLengthOnUK = (rule, value) => {
    if (!_isEmpty(value)) {
      const { employeeCount } = getContactsCounts(value);

      if (propertyCountry?.toLocaleLowerCase() === COUNTRY_NAME.UNITED_KINGDOM && employeeCount > 1) {
        return Promise.reject(new Error(t("ContactsTab.MoreThanOneEmployeeErrorMessage")));
      }
    }
    return Promise.resolve();
  };
  const validateBrokersCountPerCompany = (rule, value) => {
    if (propertyCountry?.toLocaleLowerCase() === COUNTRY_NAME.UNITED_KINGDOM && value?.length > 0) {
      const selectedCompanyCount = getSelectedCompanyCount(value);

      let isMaxCountExceeded = false;
      if (selectedCompanyCount) {
        Object.keys(selectedCompanyCount).forEach(key => {
          if (selectedCompanyCount[key] > 2) isMaxCountExceeded = true;
        });
      }

      if (isMaxCountExceeded) {
        return Promise.reject(new Error(t("ContactsTab.MoreThanTwoSecondaryContactsErrorMessage")));
      }
    }
    return Promise.resolve();
  };

  const validateCompanyCount = (rule, value) => {
    if (propertyCountry?.toLocaleLowerCase() === COUNTRY_NAME.UNITED_KINGDOM && value?.length > 0) {
      const selectedCompanyCount = getSelectedCompanyCount(value);

      if (Object.keys(selectedCompanyCount)?.length > 3) {
        return Promise.reject(new Error(t("ContactsTab.MoreThanThreeSecondaryContactsErrorMessage")));
      }
    }
    return Promise.resolve();
  };

  const columns = [
    {
      align: "center",
      width: 100,
      render: (text, record) => (
        <Radio
          checked={brokerId === record.value}
          onChange={e => setBrokerId(record.value)}
          value={record.value}
          key={record.value}
        ></Radio>
      ),
    },
    {
      title: t("ContactsTab.Name"),
      dataIndex: "displayName",
      key: "displayName",
      fixed: "left",
      width: 200,
      render: (text, record) => (
        <Typography.Text>{`${replaceIfNull(getContactNameAndEmail(record)?.name, "N/A")} `}</Typography.Text>
      ),
    },
    {
      title: t("ContactsTab.Email"),
      dataIndex: "email",
      key: "email",
      width: 150,
      render: (text, record) => (
        <Typography.Text>{`${replaceIfNull(getContactNameAndEmail(record)?.email, "N/A")} `}</Typography.Text>
      ),
    },
  ];

  const primaryContactQueryParam = {
    filterCriteria: {
      isActive: true,
      type: USER_TYPE.EMPLOYEE,
    },
    sort: "lastName,asc",
  };

  const secondaryContactQueryParam = {
    filterCriteria: {
      isActive: true,
    },
    sort: "lastName,asc",
  };

  const esmContactQueryParam = {
    filterCriteria: {
      isActive: true,
      type: USER_TYPE.ESM,
      jobTitle: USER_ESM_TYPE.JOB_TITLE,
    },
    sort: "lastName,asc",
  };

  return (
    <>
      <Paragraph ellipsis>{t("ContactsTab.Description")}</Paragraph>

      <Form.Item
        label={
          <CustomTooltipHelper
            label={t("ContactsTab.PrimaryContactLabel")}
            form={form}
            tab={_tabsKey[3]}
            fieldName={_fieldName.prologisContact.key}
            tooltip={helperText.prologisContact}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
          />
        }
        name={["contacts", "prologisContact"]}
        rules={[
          {
            required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].contacts.prologisContact,
            message: t("BuildingInfo.PrologisContactRulesMessage"),
          },
        ]}
        shouldUpdate
      >
        <CustomSelect
          placeholder={t("ContactsTab.PrimaryContactPlaceholder")}
          loading={loadingEmployees}
          fetchOptions={fetchEmployees}
          optionsList={filterOptionsList(
            employeeList,
            form.getFieldValue(["contacts", "secondaryContacts"]),
            FEATURE_FLAG.ESMCONTACT?.status ? form.getFieldValue(["contacts", "esmContact"]) : [],
          )}
          valuePropName="displayName"
          queryParam={primaryContactQueryParam}
          onSelect={onSelectPrologisContact}
          disabled={viewOnlyMode}
          allowSpace
        />
      </Form.Item>
      <Form.Item
        label={
          <>
            <span>
              <CustomTooltipHelper
                label={t("ContactsTab.SecondaryContactsLabel")}
                tooltip={helperText.secondaryContacts}
                form={form}
                tab={_tabsKey[3]}
                fieldName={_fieldName.secondaryContacts.key}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            </span>
            {permissionsUtil.checkAuth("user_create") && !viewOnlyMode && (
              <Row gutter={8} className="pad-left-8">
                <Col>
                  <Button block type="primary" onClick={_onClickOpenBrokerForm}>
                    {t("ContactsTab.CreateButton")}
                  </Button>
                </Col>
                <Col>
                  <Button block type="primary" onClick={_onClickEditBroker} disabled={disableEditBroker}>
                    {t("ContactsTab.EditButton")}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        }
        className="secondary-contacts-item"
        name={["contacts", "secondaryContacts"]}
        shouldUpdate
        rules={[
          {
            validator: validateBrokersLengthPerCountry,
          },
          {
            validator: validateBrokersCountPerCompany,
          },
          {
            validator: validateCompanyCount,
          },
          {
            validator: validateEmployeeLengthOnUK,
          },
        ]}
      >
        <CustomSelect
          isMulti
          placeholder={t("ContactsTab.SecondaryContactPlaceholder")}
          loading={loadingBrokers}
          fetchOptions={fetchBrokers}
          optionsList={filterOptionsList(
            brokerList,
            form.getFieldValue(["contacts", "prologisContact"]),
            FEATURE_FLAG.ESMCONTACT?.status ? form.getFieldValue(["contacts", "esmContact"]) : [],
          )}
          valuePropName="displayName"
          queryParam={secondaryContactQueryParam}
          allowClear
          isContact
          onChange={onSecondaryContactChange}
          onSelect={onSelectSecondaryContact}
          disabled={viewOnlyMode}
          allowSpace
          updateValue={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      </Form.Item>
      {FEATURE_FLAG.ESMCONTACT?.status && (
        <Form.Item
          label={
            <CustomTooltipHelper
              label={t("ContactsTab.EssentialSolutionsManager")}
              form={form}
              tab={_tabsKey[3]}
              fieldName={_fieldName.esmContact.key}
              tooltip={helperText.esmContact}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["contacts", "esmContact"]}
          shouldUpdate
        >
          <CustomSelect
            placeholder={t("ContactsTab.EssentialSolutionsManagerPlaceholder")}
            loading={loadingESM}
            fetchOptions={fetchESMContacts}
            optionsList={filterOptionsList(
              esmList,
              form.getFieldValue(["contacts", "secondaryContacts"]),
              form.getFieldValue(["contacts", "prologisContact"]),
            )}
            valuePropName="displayName"
            queryParam={esmContactQueryParam}
            onSelect={onSelectESMContact}
            disabled={viewOnlyMode}
            allowSpace
            allowClear
          />
        </Form.Item>
      )}
      <AppModal
        title={`${brokerId ? t("ContactsTab.EditButton") : t("ContactsTab.CreateButton")}`}
        width={800}
        visible={isBrokerFormModalOpen}
        onCancel={_onCancelBrokerFormModal}
        footer={null}
        destroyOnClose
      >
        <AddEditBroker brokerId={brokerId} onSuccess={onBrokerAddSuccess} onCloseModal={_onCancelBrokerFormModal} />
      </AppModal>

      <AppModal
        title={`${t("ContactsTab.EditButton")}`}
        width={800}
        visible={isBrokersListModalOpen}
        onCancel={_onCancelBrokerListModal}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCancelBrokerListModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                type="primary"
                disabled={_isEmpty(brokerId)}
                onClick={_onClickOpenBrokerForm}
                loading={false}
              >
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <Table dataSource={selectedBrokerContacts} columns={columns} rowKey="value" pagination={false} />
      </AppModal>
    </>
  );
};

ContactsTab.propTypes = {
  form: PropTypes.object,
  viewOnlyMode: PropTypes.bool,
  helperText: PropTypes.object,
  auditPanelDrawerVisible: PropTypes.bool,
  propertyType: PropTypes.string,
};
export default ContactsTab;
