import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import CustomFormItem from "../../components/CustomFormItem/CustomFormItem";
import CustomSelect from "../../components/CustomSelect";
import CustomTooltipHelper from "../../components/CustomTooltipHelper/CustomTooltipHelper";
import LabelWithCheckbox from "../../components/LabelWithCheckbox/LabelWithCheckbox";
import LabelWithDropDown from "../../components/LabelWithDropDown/LabelWithDropDown";
import { useLanguage } from "../../hooks";
import {
  getAllPropertyMarketsSuccess,
  getPropertyAllMarkets,
  getPropertyRollUps,
} from "../../store/actions/property.actions";
import {
  COUNTRY_NAME,
  DATE_FORMAT,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  HELPER_TEXT_KEYS,
  LAND_MEASUREMENT_UNITS,
  LAND_REQUIRED_FIELDS_LOOKUP,
  LAND_TABS_KEYS,
  LAND_TYPE,
  LANGUAGE_CODES,
  MEASUREMENT_UNITS,
  QUERY_PARAMS,
  SEARCH_QUERY,
} from "../../utils/constants";
import { isUSProperty } from "../../utils/property.util";
import {
  getFeatureFlags,
  getTooltip,
  sortAlphabetically,
  validateDependant,
  validateLatitude,
  validateLongitude,
  validateMarketingDescription,
  validateMaxValue,
  validateMinMax,
  validateMinValue,
  validateNumberValue,
} from "../../utils/utils";
import PreFilledDataCard from "../AddEditBuilding/PreFilledDataCard";

const { Option } = Select;

const MinFutureBuildingSizeUOM = ({ viewOnlyMode, updateUOM, fieldName, disableMinFutureBuildingSizeUOM, t }) => {
  const _disableMinFutureBuildingSizeUOM = viewOnlyMode || disableMinFutureBuildingSizeUOM;
  return (
    <Form.Item
      label={t("LandInfo.MinFutureBuildingSizeUOM")}
      name={["landInfo", "minFutureBuildingSizeUOM"]}
      dependencies={[["landInfo", "minFutureBuildingSize"]]}
      noStyle
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("LandInfo.SelectUOM")}
        onChange={_uom => updateUOM(_uom, fieldName)}
        disabled={_disableMinFutureBuildingSizeUOM}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MaxFutureBuildingSizeUOM = ({ viewOnlyMode, updateUOM, fieldName, disableMaxFutureBuildingSizeUOM, t }) => {
  const _disableMaxFutureBuildingSizeUOM = viewOnlyMode || disableMaxFutureBuildingSizeUOM;
  return (
    <Form.Item
      label={t("LandInfo.MaxFutureBuildingSizeUOM")}
      name={["landInfo", "maxFutureBuildingSizeUOM"]}
      dependencies={[["landInfo", "maxFutureBuildingSize"]]}
      noStyle
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("LandInfo.SelectUOM")}
        onChange={_uom => updateUOM(_uom, fieldName)}
        disabled={_disableMaxFutureBuildingSizeUOM}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const LandSizeUOM = ({ form, viewOnlyMode, t, disableLandSizeUOM }) => {
  const _disableLandSizeUOM = viewOnlyMode || disableLandSizeUOM;
  return (
    <Form.Item
      label={t("LandInfo.LandSizeUOM")}
      name={["landInfo", "landSizeUOM"]}
      dependencies={[["landInfo", "landSize"]]}
      noStyle
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("LandInfo.SelectUOM")}
        onChange={() => validateDependant(form, "landInfo", ["landSize"])}
        disabled={_disableLandSizeUOM}
      >
        {_map(sortAlphabetically(LAND_MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const LandInfo = ({
  setSelectedProperty,
  form,
  isEditView,
  viewOnlyMode,
  isTemporaryProperty,
  setIsTemporaryProperty,
  propertyCodeError,
  setPropertyCodeError,
  disableLatLng,
  countryAddressFields,
  fetchCountryAddressFields,
  templateList,
  auditPanelDrawerVisible,
}) => {
  const { t } = useTranslation();
  const { language, languageStatus } = useLanguage();
  const {
    marketList: markets,
    subMarketActiveList: subMarkets,
    loadingSubMarkets: loading,
    propertyRollUpList,
    loadingPropertyRollUp,
  } = useSelector(state => state.property);
  const [disableMinFutureBuildingSizeUOM, setDisableMinFutureBuildingSizeUOM] = useState(true);
  const [disableMaxFutureBuildingSizeUOM, setDisableMaxFutureBuildingSizeUOM] = useState(true);
  const [disableLandSizeUOM, setDisableLandSizeUOM] = useState(true);
  const [templateId, setTemplateId] = useState(null);
  const [modalVisible, setModalVisibility] = useState(false);
  const { codeDetail, selected } = useSelector(state => ({
    ...state.land,
  }));
  const { lookupList } = useSelector(state => state.helperText);
  const dispatch = useDispatch();
  const FEATURE_FLAG = getFeatureFlags();

  const _setIsTemporaryProperty = isTemp => {
    setIsTemporaryProperty(isTemp);
    if (isTemp) dispatch(getPropertyAllMarkets(isTemp));
    else dispatch(getAllPropertyMarketsSuccess({ list: [] }));
  };

  useEffect(() => {
    const _landInfo = { ...form.getFieldValue("landInfo") };
    if (!_landInfo?.minFutureBuildingSize) {
      setDisableMinFutureBuildingSizeUOM(true);
    } else {
      setDisableMinFutureBuildingSizeUOM(false);
    }
  }, [form.getFieldValue(["landInfo", "minFutureBuildingSize"])]);

  useEffect(() => {
    const _landInfo = { ...form.getFieldValue("landInfo") };
    if (!_landInfo?.maxFutureBuildingSize) {
      setDisableMaxFutureBuildingSizeUOM(true);
    } else {
      setDisableMaxFutureBuildingSizeUOM(false);
    }
  }, [form.getFieldValue(["landInfo", "maxFutureBuildingSize"])]);

  useEffect(() => {
    const _landInfo = { ...form.getFieldValue("landInfo") };
    if (!_landInfo?.landSize) {
      setDisableLandSizeUOM(true);
    } else {
      setDisableLandSizeUOM(false);
    }
  }, [form.getFieldValue(["landInfo", "landSize"])]);

  const setTemplateDescription = data => {
    if (!_isEmpty(data)) {
      if (_isEmpty(form.getFieldValue("landInfo")?.marketingDescription)) {
        populateDescription(data);
      } else {
        setModalVisibility(true);
      }
    }
  };

  useEffect(() => {
    if (selected?.market?.id) {
      getPropertyRollUpList({
        ...SEARCH_QUERY,
        ...QUERY_PARAMS,
      });
    }
  }, [selected]);

  const getPropertyRollUpList = async query => {
    let _query = query;
    if (!_query) {
      _query = {
        ...SEARCH_QUERY,
        ...QUERY_PARAMS,
      };
    }
    _query.filterCriteria.market = selected?.market?.id;
    dispatch(getPropertyRollUps(_query));
  };

  const populateDescription = data => {
    if (data) {
      const selectedTemplate = templateList.find(item => item.id === data);
      const country = form.getFieldValue(["landInfo", "address", "country"]);
      const marketingDescription = _get(
        selectedTemplate,
        `translations.${
          country === "Mexico" && language.current !== LANGUAGE_CODES.English.value
            ? LANGUAGE_CODES.Mexico.value
            : language.current
        }.template`,
        selectedTemplate?.template,
      );
      const obj = {};
      if (languageStatus !== "primary") {
        obj.translations = {
          [language.current]: { landInfo: { marketingDescription } },
        };
      } else {
        obj.landInfo = { marketingDescription };
      }
      form.setFieldsValue({ ...obj });
      setTemplateId(data);
    }
  };

  const _onCancel = () => {
    setModalVisibility(false);
    form.setFieldsValue({ landInfo: { templateId } });
  };

  const _onConfirm = () => {
    populateDescription(form.getFieldValue(["landInfo", "templateId"]));
    setModalVisibility(false);
  };

  const _onChangeMarket = key => {
    const market = _find(markets, m => m.id === key);
    fetchCountryAddressFields(market);
    const _landInfo = form.getFieldValue("landInfo");
    form.setFieldsValue({ landInfo: { ..._landInfo, subMarketId: undefined, region: market?.region?.code } });
  };

  const updateMinFutureBuildingSizeUOM = value => {
    let _landInfo = _cloneDeep(form.getFieldValue("landInfo"));

    if (_isEmpty(value)) {
      _landInfo = {
        ..._landInfo,
        minFutureBuildingSizeUOM: undefined,
      };

      form.setFieldsValue({ landInfo: _landInfo });
      setDisableMinFutureBuildingSizeUOM(true);
    } else {
      setDisableMinFutureBuildingSizeUOM(false);
    }
  };

  const updateMaxFutureBuildingSizeUOM = value => {
    let _landInfo = _cloneDeep(form.getFieldValue("landInfo"));

    if (_isEmpty(value)) {
      _landInfo = {
        ..._landInfo,
        maxFutureBuildingSizeUOM: undefined,
      };

      form.setFieldsValue({ landInfo: _landInfo });
      setDisableMaxFutureBuildingSizeUOM(true);
    } else {
      setDisableMaxFutureBuildingSizeUOM(false);
    }
  };

  const updateUOM = (uom, fieldName) => {
    validateDependant(form, "landInfo", ["minFutureBuildingSize", "maxFutureBuildingSize"]);
    form.setFieldsValue({
      landInfo: {
        [fieldName]: uom,
      },
    });
  };

  const updateLandSizeUOM = value => {
    let _landInfo = _cloneDeep(form.getFieldValue("landInfo"));

    if (_isEmpty(value)) {
      _landInfo = {
        ..._landInfo,
        landSizeUOM: undefined,
      };

      form.setFieldsValue({ landInfo: _landInfo });
      setDisableLandSizeUOM(true);
    } else {
      setDisableLandSizeUOM(false);
    }
  };

  const disableCoordinates = viewOnlyMode || (disableLatLng && !isTemporaryProperty);

  const disableSubMarket =
    viewOnlyMode || (!isTemporaryProperty && (!_isEmpty(selected?.subMarket) || !_isEmpty(codeDetail?.subMarket)));

  const renderUrbanIndicator = () => {
    const country = selected?.market?.country?.toLocaleLowerCase();
    if (
      (country === COUNTRY_NAME.CANADA && !FEATURE_FLAG?.Canada_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.UNITED_STATES && !FEATURE_FLAG?.USA_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.BRAZIL && !FEATURE_FLAG?.Brazil_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.MEXICO && !FEATURE_FLAG?.Mexico_Urban_Indicator?.status)
    ) {
      return <></>;
    }
    return (
      <Form.Item name={["landInfo", "isUrban"]} valuePropName="checked">
        <LabelWithCheckbox
          viewOnlyMode={viewOnlyMode}
          label={t("Property.IsUrban")}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.isUrban]}
        />
      </Form.Item>
    );
  };

  const fieldsType = FORM_FIELDS_TYPE.regular;

  const _renderForm = () => (
    <Row className="fields-row" gutter={20} type="flex">
      <Col span={8} xs={24} sm={12} lg={12}>
        {isTemporaryProperty && (
          <Form.Item
            tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.land.market], language)}
            label={t("Property.Market")}
            name={["landInfo", "marketId"]}
            rules={[{ required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.marketId }]}
            shouldUpdate
          >
            <Select
              onChange={_onChangeMarket}
              showSearch
              placeholder={t("Property.MarketPlaceholder")}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode || !isTemporaryProperty}
            >
              {_map(markets, market => (
                <Option key={market.id} value={market.id}>
                  {market.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {isTemporaryProperty && FEATURE_FLAG.SUBMARKET?.status && (
          <Form.Item
            tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.land.submarket], language)}
            label={t("Property.Submarket")}
            name={["landInfo", "subMarketId"]}
            shouldUpdate
            rules={[{ required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.subMarketId }]}
          >
            <Select
              loading={loading}
              showSearch
              placeholder={t("Property.SubmarketPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={disableSubMarket}
            >
              {_map(subMarkets, subMarket => (
                <Option key={subMarket.id} value={subMarket.id}>
                  {subMarket.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.StreetAddress1")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.streetAddress1.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.streetAddress1]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "address", "streetAddress1"]}
          rules={[
            {
              required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.streetAddress1,
              message: t("LandInfo.StreetAddress1RulesMessage"),
            },
            { whitespace: true, message: t("LandInfo.StreetAddress1WhitespaceMessage") },
          ]}
        >
          <Input placeholder={t("Property.StreetAddress1")} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.StreetAddress2")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.streetAddress2.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.streetAddress2]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "address", "streetAddress2"]}
        >
          <Input placeholder={t("Property.StreetAddress2")} disabled={viewOnlyMode} />
        </CustomFormItem>
        {countryAddressFields?.city && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.City")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.city.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.city]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "city"]}
            rules={
              isUSProperty(selected.market) && [
                {
                  required: ["landInfo", "address", "city"],
                  message: t("LandInfo.CityRuleMessage"),
                },
              ]
            }
          >
            <Input placeholder={t("Property.City")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.district && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.District")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.district.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.district]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "district"]}
          >
            <Input placeholder={t("Property.District")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.neighborhood && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Neighborhood")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.neighborhood.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.neighbourhood]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "neighborhood"]}
          >
            <Input placeholder={t("Property.Neighborhood")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.postTown && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.PostTown")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.postTown.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.postTown]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "postTown"]}
          >
            <Input placeholder={t("Property.PostTown")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.province && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Province")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.province.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.province]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "province"]}
          >
            <Select
              showSearch
              placeholder={t("Property.ProvincePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.province) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.prefecture && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Prefecture")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.prefecture.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.prefecture]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "prefecture"]}
          >
            <Select
              showSearch
              placeholder={t("Property.PrefecturePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.prefecture) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.state && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.State")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.state.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.state]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "state"]}
            rules={
              isUSProperty(selected.market) && [
                {
                  required: ["landInfo", "address", "state"],
                  message: t("LandInfo.StateRuleMessage"),
                },
              ]
            }
          >
            <Select
              showSearch
              placeholder={t("Property.StatePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.state) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.dosi && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Dosi")}
                form={form}
                tab={LAND_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.land.landInfo.dosi.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.land.dosi]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["landInfo", "address", "dosi"]}
          >
            <Select
              showSearch
              placeholder={t("Property.DosiPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.dosi) || []}
            />
          </Form.Item>
        )}
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={countryAddressFields?.cedex ? 12 : 24} lg={countryAddressFields?.cedex ? 12 : 24}>
            <Form.Item
              label={
                <CustomTooltipHelper
                  viewOnlyMode={viewOnlyMode}
                  label={t("Property.PostalCode")}
                  form={form}
                  tab={LAND_TABS_KEYS[1]}
                  fieldName={FORM_FIELDS_LOOKUP.land.landInfo.postalCode.key}
                  tooltip={lookupList[HELPER_TEXT_KEYS.land.postalCode]}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                />
              }
              name={["landInfo", "address", "postalCode"]}
              rules={[
                {
                  required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.postalCode,
                  message: t("LandInfo.PostalCodeRulesMessage"),
                },
                { whitespace: true, message: t("LandInfo.PostalCodeWhitespaceMessage") },
              ]}
            >
              <Input placeholder={t("Property.PostalCode")} disabled={viewOnlyMode} />
            </Form.Item>
          </Col>
          {countryAddressFields?.cedex && (
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <CustomTooltipHelper
                    viewOnlyMode={viewOnlyMode}
                    label={t("Property.Cedex")}
                    form={form}
                    tab={LAND_TABS_KEYS[1]}
                    fieldName={FORM_FIELDS_LOOKUP.land.landInfo.cedex.key}
                    tooltip={lookupList[HELPER_TEXT_KEYS.land.cedex]}
                    auditPanelDrawerVisible={auditPanelDrawerVisible}
                  />
                }
                name={["landInfo", "address", "cedex"]}
              >
                <Input placeholder={t("Property.Cedex")} disabled={viewOnlyMode} />
              </Form.Item>
            </Col>
          )}
        </Row>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.DigitalTourURL")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.digitalTourUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.digitalTourUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "digitalTourUrl"]}
          rules={[{ type: "url", message: t("LandInfo.DigitalTourUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.DigitalTourURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.VideoURL")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.videoUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.videoUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "videoUrl"]}
          rules={[{ type: "url", message: t("LandInfo.VideoUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.VideoURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.MicrositeURL")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.micrositeUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.micrositeUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "micrositeUrl"]}
          rules={[{ type: "url", message: t("LandInfo.MicrositeUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.MicrositeURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>
        {renderUrbanIndicator()}
      </Col>
      <Col span={8} xs={24} sm={12} lg={12}>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("LandInfo.LandType")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.propertySubtype.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.propertyType]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "propertySubtype"]}
          rules={[
            {
              required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.propertySubtype,
              message: t("LandInfo.PropertyTypeRulesMessage"),
            },
          ]}
        >
          <Select
            showSearch
            placeholder={t("LandInfo.LandTypePlaceholder")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            autoComplete="off"
            disabled={viewOnlyMode}
          >
            {_map(sortAlphabetically(LAND_TYPE), landType => (
              <Option key={landType.value} value={landType.value}>
                {landType.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Table.PropertyRollUpCode")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.propertyRollUp.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.propertyRollUp]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "propertyRollUp"]}
        >
          <CustomSelect
            allowClear
            placeholder={t("LandInfo.PropertyRollUpPlaceholder")}
            loading={loadingPropertyRollUp}
            fetchOptions={getPropertyRollUpList}
            optionsList={propertyRollUpList}
            queryParam={QUERY_PARAMS}
            disabled={viewOnlyMode}
          />
        </Form.Item>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.MarketingPropertyName")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.marketingPropertyName.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.marketingPropertyNames]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "marketingPropertyName"]}
          rules={[
            {
              required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.marketingPropertyName,
              message: t("LandInfo.MarketingPropertyNameRulesMessage"),
            },
            { whitespace: true, message: t("LandInfo.MarketingPropertyNameWhitespaceMessage") },
          ]}
        >
          <Input placeholder={t("Property.MarketingPropertyName")} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          className="form-item-label-with-drop-down"
          label={
            <LabelWithDropDown
              label={t("Property.MarketingDescription")}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.marketingDescription]}
              templateId={templateId}
              changeHandler={setTemplateDescription}
              options={templateList}
              viewOnlyMode={viewOnlyMode}
              name={["landInfo", "templateId"]}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.marketingDescription.key}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "marketingDescription"]}
          rules={[
            {
              required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.marketingDescription,
              message: t("LandInfo.MarketingDescriptionRulesMessage"),
            },
            { whitespace: true, message: t("LandInfo.MarketingDescriptionWhitespaceMessage") },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("Property.MarketingDescription")),
            },
          ]}
        >
          <Input.TextArea placeholder={t("Property.MarketingDescription")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>

        <Form.Item
          tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.land.availableDate], language)}
          label={t("Property.AvailableDate")}
          name={["landInfo", "availableDate"]}
        >
          <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} disabled={viewOnlyMode} />
        </Form.Item>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("LandInfo.LandSize")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.landSize.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.landSize]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "landSize"]}
          validateFirst
          dependencies={[["landInfo", "landSizeUOM"]]}
          onChange={event => {
            updateLandSizeUOM(event.target.value);
          }}
          rules={[
            {
              required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.landSize,
              message: t("LandInfo.LandSizeRulesMessage"),
            },
            {
              validator: (rule, value) => validateMinValue(value, t("LandInfo.LandSize"), 0.1),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const isUOMEmpty = _isEmpty(getFieldValue(["landInfo", "landSizeUOM"]));
                return isUOMEmpty && value
                  ? Promise.reject(new Error(`Land Size UOM is required!`))
                  : Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            min="1"
            step="any"
            placeholder={t("LandInfo.LandSize")}
            disabled={viewOnlyMode}
            onChange={() => validateDependant(form, "landInfo", ["landSizeUOM"])}
            addonAfter={
              <LandSizeUOM form={form} viewOnlyMode={viewOnlyMode} t={t} disableLandSizeUOM={disableLandSizeUOM} />
            }
          />
        </Form.Item>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("LandInfo.MinFutureBuildingSize")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.minFutureBuildingSize.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.minFutureBuildingSize]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "minFutureBuildingSize"]}
          dependencies={[
            ["landInfo", "minFutureBuildingSizeUOM"],
            ["landInfo", "maxFutureBuildingSize"],
          ]}
          onChange={event => {
            updateMinFutureBuildingSizeUOM(event.target.value);
          }}
          validateFirst
          rules={[
            {
              validator: (rule, value) => validateMinValue(value, t("LandInfo.MinFutureBuildingSize"), 1),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return validateMinMax({
                  currValue: value,
                  depValue: getFieldValue(["landInfo", "maxFutureBuildingSize"]),
                  type: "max",
                  fieldName: t("LandInfo.MinFutureBuildingSize"),
                  depLabel: t("LandInfo.MaxFutureBuildingSize"),
                });
              },
            }),
            ({ getFieldValue }) => ({
              validator() {
                const isMinEmpty = validateNumberValue(+getFieldValue(["landInfo", "minFutureBuildingSize"]));
                const isMaxEmpty = validateNumberValue(+getFieldValue(["landInfo", "maxFutureBuildingSize"]));
                return isMaxEmpty && !isMinEmpty
                  ? Promise.reject(new Error(t("LandInfo.MinFutureBuildingSizeRulesMessage")))
                  : Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const isUOMEmpty = _isEmpty(getFieldValue(["landInfo", "minFutureBuildingSizeUOM"]));
                return isUOMEmpty && value
                  ? Promise.reject(new Error(t("LandInfo.MinFutureBuildingSizeMessage")))
                  : Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            min="1"
            step="any"
            placeholder={t("LandInfo.MinFutureBuildingSize")}
            disabled={viewOnlyMode}
            onChange={() => validateDependant(form, "landInfo", ["minFutureBuildingSizeUOM"])}
            addonAfter={
              <MinFutureBuildingSizeUOM
                viewOnlyMode={viewOnlyMode}
                updateUOM={updateUOM}
                fieldName="maxFutureBuildingSizeUOM"
                disableMinFutureBuildingSizeUOM={disableMinFutureBuildingSizeUOM}
                t={t}
              />
            }
          />
        </Form.Item>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("LandInfo.MaxFutureBuildingSize")}
              form={form}
              tab={LAND_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.land.landInfo.maxFutureBuildingSize.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.land.maxFutureBuildingSize]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["landInfo", "maxFutureBuildingSize"]}
          dependencies={[
            ["landInfo", "maxFutureBuildingSizeUOM"],
            ["landInfo", "minFutureBuildingSize"],
          ]}
          onChange={event => {
            updateMaxFutureBuildingSizeUOM(event.target.value);
          }}
          validateFirst
          rules={[
            {
              validator: (rule, value) => validateMinValue(value, t("LandInfo.MaxFutureBuildingSize"), 1),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return validateMinMax({
                  currValue: value,
                  depValue: getFieldValue(["landInfo", "minFutureBuildingSize"]),
                  type: "min",
                  fieldName: t("LandInfo.MaxFutureBuildingSize"),
                  depLabel: t("LandInfo.MinFutureBuildingSize"),
                });
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const isUOMEmpty = _isEmpty(getFieldValue(["landInfo", "maxFutureBuildingSizeUOM"]));
                return isUOMEmpty && value
                  ? Promise.reject(new Error(t("LandInfo.MaxFutureBuildingSizeMessage")))
                  : Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator() {
                const isMinEmpty = validateNumberValue(+getFieldValue(["landInfo", "minFutureBuildingSize"]));
                const isMaxEmpty = validateNumberValue(+getFieldValue(["landInfo", "maxFutureBuildingSize"]));
                return isMinEmpty && !isMaxEmpty
                  ? Promise.reject(new Error(t("LandInfo.MaxFutureBuildingSizeRulesMessage")))
                  : Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            min="1"
            step="any"
            placeholder={t("LandInfo.MaxFutureBuildingSize")}
            disabled={viewOnlyMode}
            onChange={() => validateDependant(form, "landInfo", ["maxsFutureBuildingSizeUOM"])}
            addonAfter={
              <MaxFutureBuildingSizeUOM
                viewOnlyMode={viewOnlyMode}
                updateUOM={updateUOM}
                fieldName="minFutureBuildingSizeUOM"
                disableMaxFutureBuildingSizeUOM={disableMaxFutureBuildingSizeUOM}
                t={t}
              />
            }
          />
        </Form.Item>
        {!disableCoordinates && (
          <>
            <Form.Item
              tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.land.latitude], language)}
              label={t("Property.Latitude")}
              name={["landInfo", "latitude"]}
              rules={[
                { required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.latitude },
                {
                  validator: (rule, value) => validateLatitude(value),
                },
                {
                  validator: (rule, value) => validateMinValue(value, t("Property.Latitude"), -90),
                },
                {
                  validator: (rule, value) => validateMaxValue(value, t("Property.Latitude"), 90),
                },
              ]}
            >
              <Input placeholder={t("Property.Latitude")} disabled={disableCoordinates} />
            </Form.Item>
            <Form.Item
              tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.land.longitude], language)}
              label={t("Property.Longitude")}
              name={["landInfo", "longitude"]}
              rules={[
                { required: LAND_REQUIRED_FIELDS_LOOKUP[fieldsType].land.longitude },
                {
                  validator: (rule, value) => validateLongitude(value),
                },
                {
                  validator: (rule, value) => validateMinValue(value, t("Property.Longitude"), -180),
                },
                {
                  validator: (rule, value) => validateMaxValue(value, t("Property.Longitude"), 180),
                },
              ]}
            >
              <Input placeholder={t("Property.Longitude")} disabled={disableCoordinates} />
            </Form.Item>
          </>
        )}
      </Col>
    </Row>
  );

  const preFilledCardProps = {
    title: [t("PreFilledDataCard.Land.Title")],
    data: {
      [t("LandInfo.LandCode")]: { parent: "landInfo", child: "propertyCode.label" },
      [t("Table.PropertyRollUpCode")]: { parent: "landInfo", child: "propertyRollUp.label" },
      [t("Property.Market")]: { parent: "landInfo", child: "marketId" },
      [t("Property.Submarket")]: { parent: "landInfo", child: "subMarketId" },
      [t("PropertiesTable.ExpirationDate")]: { parent: "buildingInfo", child: "expirationDate" },
      [t("Property.Region")]: { parent: "landInfo", child: "region" },
      [t("Property.Country")]: { parent: countryAddressFields, child: "country", isExternal: true },
      [t("Property.Latitude")]: { parent: "landInfo", child: "latitude" },
      [t("Property.Longitude")]: { parent: "landInfo", child: "longitude" },
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={18} lg={18}>
          {_renderForm()}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <PreFilledDataCard preFilledCardProps={preFilledCardProps} form={form} />
        </Col>
      </Row>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title={t("Template.Confirmation")}
        width={600}
        minHeight="10vh"
        maxHeight="60vh"
        overFlowY="auto"
        visible={modalVisible}
        onCancel={_onCancel}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCancel}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={_onConfirm}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <p>{t("Template.ConfirmationMessage")}</p>
      </AppModal>
    </>
  );
};
LandInfo.propTypes = {
  setSelectedProperty: PropTypes.func,
  form: PropTypes.object,
  isEditView: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
  isTemporaryProperty: PropTypes.bool,
  setIsTemporaryProperty: PropTypes.func,
  propertyCodeError: PropTypes.shape({
    validateStatus: PropTypes.string,
    help: PropTypes.string,
  }),
  setPropertyCodeError: PropTypes.func,
  disableLatLng: PropTypes.bool,
  fetchCountryAddressFields: PropTypes.func,
  countryAddressFields: PropTypes.object,
  templateList: PropTypes.array,
  auditPanelDrawerVisible: PropTypes.bool,
};

MinFutureBuildingSizeUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  updateUOM: PropTypes.func,
  fieldName: PropTypes.string,
  disableMinFutureBuildingSizeUOM: PropTypes.bool,
  t: PropTypes.func,
};

MaxFutureBuildingSizeUOM.propTypes = {
  viewOnlyMode: PropTypes.bool,
  updateUOM: PropTypes.func,
  fieldName: PropTypes.string,
  disableMaxFutureBuildingSizeUOM: PropTypes.bool,
  t: PropTypes.func,
};

LandSizeUOM.propTypes = {
  form: PropTypes.object,
  viewOnlyMode: PropTypes.bool,
  t: PropTypes.func,
  disableLandSizeUOM: PropTypes.bool,
};

export default LandInfo;
