import { Button, Col, Row, Table } from "antd";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { replaceIfNull } from "../../utils/utils";
import CustomIcon from "../CustomIcon/CustomIcon";
import "./TransferTable.scss";

const TransferTable = ({ scroll, size, source, current, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _dataSource = [];
    Object.entries(source).forEach(([key, value]) => {
      const obj = {};
      obj.id = key;
      obj.fieldName = value.label;
      obj.snowflakeValue = value.value;
      obj.currentValue = current[key];
      _dataSource.push(obj);
    });
    setDataSource(_dataSource);
    setLoading(false);
  }, []);

  const _selectAllValues = () => {
    const _dataSource = _map(dataSource, d => ({ ...d, updatedValue: d.snowflakeValue }));

    setDataSource([..._dataSource]);
  };

  const _updateSelectedValues = () => {
    const _selectedValues = {};
    _forEach(dataSource, d => {
      if (d.updatedValue) {
        _selectedValues[d.id] = d.updatedValue;
      }
    });

    onSuccess(_selectedValues);
  };

  const _selectValue = record => {
    const _dataSource = dataSource;
    const _index = _findIndex(_dataSource, { id: record.id });
    _dataSource[_index] = {
      ..._dataSource[_index],
      updatedValue: _dataSource[_index].snowflakeValue,
    };

    setDataSource([..._dataSource]);
  };

  const _deselectValue = record => {
    const _dataSource = dataSource;
    const _index = _findIndex(_dataSource, { id: record.id });
    _dataSource[_index] = {
      ..._dataSource[_index],
      updatedValue: undefined,
    };

    setDataSource([..._dataSource]);
  };

  const columns = [
    {
      title: t("TransferTable.FieldName"),
      dataIndex: "fieldName",
      key: "fieldName",
      fixed: "left",
      width: 200,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("TransferTable.BudValue"),
      dataIndex: "snowflakeValue",
      key: "snowflakeValue",
      width: 150,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("TransferTable.DpmValue"),
      dataIndex: "currentValue",
      key: "currentValue",
      width: 150,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("TransferTable.Action"),
      align: "center",
      width: 100,
      render: (text, record) => {
        const _disabled =
          !_isNumber(record.updatedValue) && !_isBoolean(record.updatedValue) && _isEmpty(record.updatedValue);
        const _emptySnowflakeValue =
          !_isNumber(record.snowflakeValue) && !_isBoolean(record.snowflakeValue) && _isEmpty(record.snowflakeValue);
        return (
          <>
            <Button
              icon={<CustomIcon name="LeftOutlined" />}
              disabled={_disabled}
              onClick={() => _deselectValue(record)}
            />
            <Button
              icon={<CustomIcon name="RightOutlined" />}
              disabled={_emptySnowflakeValue || !_disabled}
              onClick={() => _selectValue(record)}
            />
          </>
        );
      },
    },
    {
      title: t("TransferTable.UpdatedValue"),
      dataIndex: "updatedValue",
      key: "updatedValue",
      fixed: "right",
      width: 150,
      render: text => text || "",
    },
  ];

  return (
    <div className="transfer-table">
      <Row justify="end" gutter={[8, 16]} className="row-vertical-padding-8">
        <Col xs={24} sm={24} md={6} lg={5} xl={5}>
          <Button block type="primary" onClick={_selectAllValues}>
            {t("TransferTable.PullAll")}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        size={size}
        loading={loading}
        rowKey="id"
        scroll={scroll}
        pagination={false}
      />
      <Row justify="space-around" className="mg-top-20">
        {onCancel && (
          <Col xs={24} sm={24} md={6} lg={5} xl={5}>
            <Button block onClick={onCancel}>
              {t("Buttons.Cancel")}
            </Button>
          </Col>
        )}
        <Col xs={24} sm={24} md={6} lg={5} xl={5}>
          <Button block type="primary" onClick={_updateSelectedValues}>
            {t("Buttons.Update")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

TransferTable.defaultProps = {
  rowSelection: {},
  size: "middle",
  scroll: { x: 750, y: 400 },
};

TransferTable.propTypes = {
  rowSelection: PropTypes.shape({
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
  }),
  size: PropTypes.string,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  source: PropTypes.object,
  current: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default TransferTable;
