import { get, put, post, remove } from "./http.service";

const TemplateService = {
  get: () => get("template"),
  update: (id, body) => put(`template/${id}`, body),
  create: body => post(`template`, body),
  remove: id => remove(`template/${id}`),
  export: () => get(`template/export`, { responseType: "base64" }),
};

export default TemplateService;
