import { Col, DatePicker, Divider, Form, Input, Row, Select, Space, Switch } from "antd";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "../../utils/constants";

import "./UnmarketPropertiesForm.scss";

const { Option } = Select;
const { TextArea } = Input;

const UnmarketPropertiesForm = ({ unmarketData, form, selectedReasonId }) => {
  const { t } = useTranslation();
  const [isSameReason, setIsSameReason] = useState(false);
  const [reasonOptions, setReasonsOptions] = useState({});
  const [sameReasonOption, setSameReasonOption] = useState("");
  const [filteredList, setFilterdList] = useState([]);
  const [sameReasonDateOption, setSameReasonDateOption] = useState({});

  const { list } = useSelector(state => ({
    ...state.unmarketReason,
  }));

  const onReasonChange = (value, key, option) => {
    setReasonsOptions({
      ...reasonOptions,
      [key]: {
        value,
        moreInformationRequired: option.moreinformationrequired,
        unmarketAdditionalInformation: option.unmarketAdditionalInformation,
        dateFieldLabel: option.datefieldlabel,
        dateFieldRequired: option.datefieldrequired,
      },
    });
  };
  const filteredSameReason = () => {
    const propType = [];
    _map(unmarketData, d => {
      if (!propType.includes(d.propertyType)) {
        propType.push(d.propertyType);
      }
    });
    if (propType.length === 2) {
      const _unmarketReasons = list.filter(_list => _list?.type?.length === 2 && _list.id !== selectedReasonId);
      setFilterdList(_unmarketReasons);
    } else {
      const _unmarketReasons = list.filter(
        _list => _list?.type?.includes(propType[0]) && _list.id !== selectedReasonId,
      );
      setFilterdList(_unmarketReasons);
    }
  };

  const onSameReasonChange = (value, option) => {
    setSameReasonOption(option.moreinformationrequired);
    setSameReasonDateOption({ dateFieldLabel: option.datefieldlabel, dateFieldRequired: option.datefieldrequired });
  };

  const toggleSameReason = checked => {
    setIsSameReason(checked);
    if (reasonOptions) {
      const _reasonOptions = Object.values(reasonOptions).map(option => ({
        ...option,
        moreInformationRequired: "false",
        dateFieldRequired: "false",
      }));
      setReasonsOptions({
        ..._reasonOptions,
      });
      setSameReasonOption("false");
      setSameReasonDateOption({});
    }
  };

  const onFilterReasonByType = type => {
    const unmarketReasons = list.filter(_list => _list?.type?.includes(type) && _list.id !== selectedReasonId);
    setFilterdList(unmarketReasons);
  };

  /**
   * function use to rendered unmarket reasons form
   */
  const renderReasonForm = () => {
    if (isSameReason) {
      return (
        <Col span={8} xs={24} sm={24} lg={24}>
          <Form.Item
            label={`${unmarketData.length} ${t("UnitUnmarket.PropertiesSameReasonLable")}`}
            name={["sameReason", "value"]}
            rules={[{ required: true, message: t("ErrorMessages.SelectUnmarketReason") }]}
            preserve={false}
          >
            <Select
              onChange={onSameReasonChange}
              placeholder={t("UnitUnmarket.UnmarketPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              onFocus={filteredSameReason}
            >
              {_map(filteredList, reason => (
                <Option
                  className="unmarket-option"
                  key={reason.id}
                  moreinformationrequired={reason?.moreInformationRequired.toString()}
                  datefieldrequired={reason?.dateFieldRequired ? reason?.dateFieldRequired.toString() : "false"}
                  datefieldlabel={reason?.dateFieldLabel ? reason?.dateFieldLabel : ""}
                  value={reason.id}
                >
                  {reason.unmarketingReason}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {sameReasonDateOption?.dateFieldRequired === "true" && (
            <Form.Item
              label={sameReasonDateOption?.dateFieldLabel}
              name={["sameReason", "unmarketLeaseDate"]}
              rules={[
                {
                  required: true,
                  message: t("ErrorMessages.UnmarketLeaseDateMessage", {
                    dateFieldName: sameReasonDateOption?.dateFieldLabel,
                  }),
                },
              ]}
              preserve={false}
            >
              <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
            </Form.Item>
          )}
          {sameReasonOption === "true" && (
            <Form.Item
              label={t("UnitUnmarket.UnmarketTextAreaLable")}
              name={["sameReason", "unmarketAdditionalInformation"]}
              rules={[{ required: true, message: t("ErrorMessages.AdditionalInformationMessage") }]}
              preserve={false}
            >
              <TextArea autoSize={{ minRows: 6 }} rules={[{ required: true }]} />
            </Form.Item>
          )}
        </Col>
      );
    }

    return _map(unmarketData, _reason => (
      <Col key={_reason.id} span={8} xs={24} sm={24} lg={24}>
        <Form.Item
          label={_reason.propertyCode}
          name={[_reason.id, "value"]}
          rules={[{ required: true, message: t("ErrorMessages.SelectUnmarketReason") }]}
          preserve={false}
        >
          <Select
            onChange={(value, option) => onReasonChange(value, _reason.id, option)}
            placeholder={t("UnitUnmarket.UnmarketPlaceholder")}
            optionFilterProp="value"
            defaultValue={_reason.unmarketReasonId}
            value={_reason.unmarketReasonId}
            autoComplete="off"
            onFocus={() => onFilterReasonByType(_reason.propertyType)}
          >
            {_map(filteredList, reason => (
              <Option
                className="unmarket-option"
                moreinformationrequired={reason?.moreInformationRequired.toString()}
                datefieldrequired={reason?.dateFieldRequired ? reason?.dateFieldRequired.toString() : "false"}
                datefieldlabel={reason?.dateFieldLabel ? reason?.dateFieldLabel : ""}
                key={reason.id}
                value={reason.id}
              >
                {reason.unmarketingReason}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {reasonOptions[_reason.id]?.dateFieldRequired === "true" && (
          <Form.Item
            label={reasonOptions[[_reason.id]]?.dateFieldLabel}
            name={[[_reason.id], "unmarketLeaseDate"]}
            rules={[
              {
                required: true,
                message: t("ErrorMessages.UnmarketLeaseDateMessage", {
                  dateFieldName: reasonOptions[[_reason.id]]?.dateFieldLabel,
                }),
              },
            ]}
            preserve={false}
          >
            <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
          </Form.Item>
        )}
        {reasonOptions[_reason.id]?.moreInformationRequired === "true" && (
          <Form.Item
            label={t("UnitUnmarket.UnmarketTextAreaLable")}
            name={[_reason.id, "unmarketAdditionalInformation"]}
            rules={[{ required: true, message: t("ErrorMessages.AdditionalInformationMessage") }]}
            preserve={false}
          >
            <TextArea autoSize={{ minRows: 6 }} rules={[{ required: true }]} />
          </Form.Item>
        )}
      </Col>
    ));
  };

  return (
    <div className="properties-unmarket-form">
      <Form form={form} layout="vertical" name="properties-unmarket">
        <Row align="middle">
          <Col span={16}>
            <p>
              {t("UnitUnmarket.PropertiesDescription", {
                reason: unmarketData[0]?.unmarketReason,
              })}
            </p>
          </Col>
          <Col span={8} className="text-right">
            <Space>
              <Form.Item
                className="reason-switch"
                valuePropName="checked"
                label={t("UnitUnmarket.SameReasonText")}
                name="isSameReason"
                preserve={false}
              >
                <Switch onChange={toggleSameReason} checked={isSameReason} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>{renderReasonForm()}</Row>
      </Form>
      <br />
    </div>
  );
};

UnmarketPropertiesForm.propTypes = {
  unmarketData: PropTypes.array,
  form: PropTypes.object,
  selectedReasonId: PropTypes.string,
};

export default UnmarketPropertiesForm;
