import { Col, Layout, Menu, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BrandLogo from "../../images/brand-logo.svg";
import defaultLogo from "../../images/invalidLogo.svg";
import PartnerLogo from "../../images/listing-card.svg";
import { getUser } from "../../utils/auth.utils";
import { ROUTES } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { getFeatureFlags } from "../../utils/utils";
import "./Header.scss";

const { Header } = Layout;
class AppHeaderContainer extends React.Component {
  state = {
    errorImage: false,
  };

  _handleLogout = () => {
    const user = getUser;
    this.props.logout(user);
  };

  render() {
    const { t } = this.props;
    const { history } = this.props;
    const { errorImage } = this.state;
    const currentPath = history.location.pathname;
    const pathName = currentPath || "";
    const canAccessAdmin = permissionsUtil.checkAuth("user_view");
    const canAccessProperty = permissionsUtil.checkAuth("property_view");
    const canAccessUnitVacancy = permissionsUtil.checkAuth("unit_vacancy_view");
    const canExportAuditProperty = permissionsUtil.checkAuth("audit_view");
    const canAccessDowntimeReportAdmin = permissionsUtil.checkAuth("downtime_report_view");
    const canAccessHome = permissionsUtil.checkAuth("home_view");
    const FEATURE_FLAG = getFeatureFlags();
    const { LANDMARKET } = FEATURE_FLAG;

    return (
      <Header className="app-header">
        <Row type="flex" className="fields-row app-header__row" gutter={24}>
          <Col span={8} xs={18} sm={18} md={14} lg={14}>
            <div className="app-header__title text-uppercase">
              <Link to={ROUTES.HOME.path}>
                <img
                  className="brand-logo"
                  src={errorImage ? defaultLogo : BrandLogo || PartnerLogo}
                  alt="brand"
                  width="170"
                  onError={() => this.setState({ errorImage: true })}
                />
              </Link>
            </div>
          </Col>
          <Col
            span={8}
            xs={6}
            sm={6}
            md={10}
            lg={10}
            className="left-vertical-split text-right app-header__secondary-menu-wrap"
          >
            <Menu className="header-menu" mode="horizontal" selectedKeys={[pathName]}>
              {canAccessHome && (
                <Menu.Item key={ROUTES.HOME.path}>
                  <Link to={ROUTES.HOME.path}>{ROUTES.HOME.name}</Link>
                </Menu.Item>
              )}
              {canAccessProperty && (
                <Menu.Item key={ROUTES.PROPERTIES.path}>
                  <Link to={ROUTES.PROPERTIES.path}>{ROUTES.PROPERTIES.name}</Link>
                </Menu.Item>
              )}
              {canAccessUnitVacancy && (
                <Menu.SubMenu key="VacancyMenu" title={t("Nav.PropertyVacancy.Name")}>
                  <Menu.Item key={ROUTES.UNIT_VACANCY.path}>
                    <Link to={ROUTES.UNIT_VACANCY.path}>{ROUTES.UNIT_VACANCY.name}</Link>
                  </Menu.Item>
                  {LANDMARKET?.status && (
                    <Menu.Item key={ROUTES.LAND_VACANCY.path}>
                      <Link to={ROUTES.LAND_VACANCY.path}>{ROUTES.LAND_VACANCY.name}</Link>
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}
              {canExportAuditProperty && (
                <Menu.Item key={ROUTES.AUDIT.path}>
                  <Link to={ROUTES.AUDIT.path}>{ROUTES.AUDIT.name}</Link>
                </Menu.Item>
              )}
              {canAccessAdmin && (
                <Menu.Item key={ROUTES.ADMIN.path}>
                  <Link to={ROUTES.ADMIN.path}>{ROUTES.ADMIN.name}</Link>
                </Menu.Item>
              )}
              <Menu.SubMenu key="SubMenu" title={t("Nav.Reports.Name")}>
                {window.VACANCY_STATUS_URL ? (
                  <Menu.Item key="vacancy-status">
                    <a href={window.VACANCY_STATUS_URL} target="_blank" rel="noopener noreferrer">
                      {t("Nav.Reports.VacancyStatus")}
                    </a>
                  </Menu.Item>
                ) : (
                  <Menu.Item key="vacancy-status">
                    <Link to="/404" target="_blank" rel="noopener noreferrer">
                      {t("Nav.Reports.VacancyStatus")}
                    </Link>
                  </Menu.Item>
                )}
                {canAccessDowntimeReportAdmin && (
                  <Menu.Item key={ROUTES.DOWNTIME_REPORT.path}>
                    <Link to={ROUTES.DOWNTIME_REPORT.path}>{ROUTES.DOWNTIME_REPORT.name}</Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
              {window.HELP_URL ? (
                <Menu.Item key="help">
                  <a href={window.HELP_URL} target="_blank">
                    {t("Nav.Help")}
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item key="help">
                  <Link to="/404" target="_blank">
                    {t("Nav.Help")}
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="logout" onClick={this._handleLogout}>
                {t("Nav.Logout")}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Header>
    );
  }
}

AppHeaderContainer.propTypes = {
  history: PropTypes.object,
  logout: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(AppHeaderContainer);
