import { Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _reduce from "lodash/reduce";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import TransferTable from "../../components/TransferTable/TransferTable";
import { FIRE_SUPPRESSION_SYSTEMS, WAREHOUSE_LIGHTING_TYPE } from "../../utils/constants";
import { getUnitLabel } from "../../utils/property.util";
import { filterOption, getFeatureFlags } from "../../utils/utils";
import "./Characteristics.scss";

const { Title } = Typography;

const UnitCharacteristics = ({
  _unitCodes,
  selectedPropertyUnits,
  selectedProperty,
  form,
  notAvailable,
  index,
  viewOnlyMode,
  updateNotAvailableFields,
}) => {
  const [isDifferenceModalOpen, setIsDifferenceModalOpen] = useState(false);
  const [codeDetail, setCodeDetail] = useState({});
  const [selected, setSelected] = useState({});
  const [selectedUnitCode, setSelectedUnitCode] = useState(undefined);
  const { t } = useTranslation();
  const FEATURE_FLAG = getFeatureFlags();

  const _onCancel = () => {
    setCodeDetail({});
    setSelected({});
    setIsDifferenceModalOpen(false);
  };

  const _onClickSyncModal = (unitDetail, selectedUnit) => {
    setCodeDetail(unitDetail);
    setSelected(selectedUnit);
    setSelectedUnitCode(unitDetail?.unitId);
    setIsDifferenceModalOpen(true);
  };

  const _onSuccess = values => {
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));
    setIsDifferenceModalOpen(false);
    setCodeDetail({});
    setSelected({});
    const obj = {};
    let uInfoArray = [];
    Object.entries(values).forEach(([key, value]) => {
      const _value = value;
      if (key === "leaseStatus") {
        obj[key] = _value === "Occupied";
      } else if (key === "leaseEndDate") {
        obj[key] = _value ? dayjs(_value) : null;
      } else if (key === "unitCode") {
        if (_unitInfo?.length > 0) {
          uInfoArray = [..._unitInfo];

          uInfoArray.forEach((ui, _index) => {
            const _uCode = ui.unitCode;
            if (_uCode?.length === 1) {
              if (_uCode[0].value === selected?.unitId) {
                uInfoArray[_index].unitCode = [
                  { key: _uCode[0].key, label: getUnitLabel(_value), value: _uCode[0].value },
                ];
              }
            } else {
              const ind = _uCode.findIndex(x => x.value === selected?.unitId);
              if (ind !== -1) {
                const newUCodesArray = [..._uCode];
                newUCodesArray[ind] = { key: _uCode[ind].key, label: getUnitLabel(_value), value: _uCode[ind].value };
                uInfoArray[_index].unitCode = newUCodesArray;
              }
            }
            const newUnitOptionsArray = [...ui.unitOptions];
            if (newUnitOptionsArray?.length > 0) {
              newUnitOptionsArray
                .filter(u => u.value === selected?.unitId)
                .forEach(x => {
                  x.label = getUnitLabel(_value);
                  x.unitCode = getUnitLabel(_value);
                });
              uInfoArray[_index].unitOptions = [...newUnitOptionsArray];
            }
          });
        }
      } else {
        obj[key] = _value;
        if (notAvailable.unitInfo[index][key]) {
          updateNotAvailableFields(key, index);
        }
      }
    });

    if (!_isEmpty(uInfoArray)) {
      _unitInfo.forEach((u, i) => {
        u.unitOptions = [...uInfoArray[i].unitOptions];
      });
    }

    _unitInfo[index] = {
      ..._unitInfo[index],
      ...obj,
    };

    form.setFieldsValue({
      unitInfo: _unitInfo,
    });
  };

  const _source = useMemo(() => {
    const obj = {};
    if (!_isEmpty(codeDetail)) {
      obj.unitCode = {
        label: t("UnitInfoForm.UnitCode"),
        value: codeDetail?.unitCode,
      };
      obj.fireSuppressionSystem = {
        label: t("UnitCharacteristics.FireSuppressionSystem"),
        value: filterOption(FIRE_SUPPRESSION_SYSTEMS, codeDetail?.characteristics?.fireSuppressionSystem),
      };
      obj.warehouseLightingType = {
        label: t("UnitCharacteristics.WarehouseLightingType"),
        value: filterOption(WAREHOUSE_LIGHTING_TYPE, codeDetail?.characteristics?.warehouseLightingType),
      };
      obj.clearHeight = {
        label: t("UnitCharacteristics.ClearHeight"),
        value: codeDetail?.characteristics?.clearHeight,
      };
      obj.gradeLevelDoors = {
        label: t("UnitCharacteristics.GradeLevelDoors"),
        value: codeDetail?.characteristics?.gradeLevelDoors,
      };
      obj.dockHighDoors = {
        label: t("UnitCharacteristics.DockHighDoors"),
        value: codeDetail?.characteristics?.dockHighDoors,
      };
      obj.mainBreakerSize = {
        label: t("UnitCharacteristics.MainBreakerSize"),
        value: codeDetail?.characteristics?.mainBreakerSize,
      };
    }
    return obj;
  }, [codeDetail]);

  const _current = useMemo(() => {
    const obj = {};
    if (!_isEmpty(selected)) {
      obj.unitCode = selected.unitCode;
      obj.fireSuppressionSystem = filterOption(FIRE_SUPPRESSION_SYSTEMS, selected?.fireSuppressionSystem);
      obj.warehouseLightingType = filterOption(WAREHOUSE_LIGHTING_TYPE, selected?.warehouseLightingType);
      obj.clearHeight = selected?.clearHeight;
      obj.gradeLevelDoors = selected?.gradeLevelDoors;
      obj.dockHighDoors = selected?.dockHighDoors;
      obj.mainBreakerSize = selected?.mainBreakerSize;
    }
    return obj;
  }, [selected]);

  const shouldBeDisabled = (unitDetail, selectedUnit) => viewOnlyMode || _isEmpty(unitDetail) || _isEmpty(selectedUnit);

  const dataSource = _reduce(
    _unitCodes,
    (result, _unitCode) => {
      const unitDetail = _find(selectedPropertyUnits, { unitId: _unitCode.value });
      const unitCharacteristics = _get(unitDetail, "characteristics", {});
      const selectedUnit = _find(selectedProperty.unitInfo, { unitId: _unitCode.value });

      result.records.forEach((record, recordIndex) => {
        result.records[recordIndex][_unitCode.value] = _get(unitCharacteristics, record.key, "-");
      });

      const _disabled = shouldBeDisabled(unitCharacteristics, selectedUnit);

      result.columns.push({
        title: (
          <div className="single-unit-characteristic-heading">
            <Title level={5} ellipsis title={getUnitLabel(_unitCode.label)}>
              {getUnitLabel(_unitCode.label)}
            </Title>
            <Button
              disabled={_disabled}
              size="small"
              shape="circle"
              type="primary"
              icon={<CustomIcon name="SyncOutlined" />}
              onClick={() => _onClickSyncModal(unitDetail, selectedUnit)}
            />
          </div>
        ),
        dataIndex: _unitCode.value,
        key: _unitCode.value,
        width: 150,
      });
      return result;
    },
    {
      records: [
        { key: "mainBreakerSize", characteristic: t("UnitCharacteristics.MainBreakerSizeInAmps") },
        { key: "hvacWarehouse", characteristic: t("UnitCharacteristics.HVACEvaporativeCooling") },
        { key: "crossDock", characteristic: t("UnitCharacteristics.UnitCrossDock") },
        { key: "officeLightingType", characteristic: t("UnitCharacteristics.OfficeLightingType") },
        { key: "warehouseLightingType", characteristic: t("UnitCharacteristics.WarehouseLightingType") },
        { key: "dockHighDoors", characteristic: t("UnitCharacteristics.DockHighDoor") },
        { key: "clearHeight", characteristic: t("UnitCharacteristics.ClearHeight") },
        { key: "gradeLevelDoors", characteristic: t("UnitCharacteristics.GradeLevelDoors") },
        { key: "fireSuppressionSystem", characteristic: t("UnitCharacteristics.FireSuppressionSystem") },
        { key: "edgeOfDockLevelersCount", characteristic: t("PropertyCharacteristics.EdgeOfDockLevelersCount") },
        { key: "pitLevelersCount", characteristic: t("UnitCharacteristics.PitLevelersCount") },
        { key: "vehicleRestraintsCount", characteristic: t("UnitCharacteristics.VehicleRestraintsCount") },
      ],
      columns: [
        {
          title: t("UnitCharacteristics.Title"),
          width: 200,
          dataIndex: "characteristic",
          key: "characteristic",
          fixed: "left",
        },
      ],
    },
  );

  return (
    <div className="unit-characteristics-container">
      <Table
        dataSource={dataSource.records}
        columns={dataSource.columns}
        pagination={false}
        scroll={{ x: `${dataSource.columns.length * 120 + 80}px` }}
      />
      <AppModal
        title={t("UnitCharacteristics.SyncLatestChanges")}
        width={900}
        visible={isDifferenceModalOpen}
        onCancel={_onCancel}
        footer={null}
        destroyOnClose
      >
        <TransferTable source={_source} current={_current} onSuccess={_onSuccess} onCancel={_onCancel} />
      </AppModal>
    </div>
  );
};

UnitCharacteristics.propTypes = {
  _unitCodes: PropTypes.array,
  selectedPropertyUnits: PropTypes.array,
  selectedProperty: PropTypes.object,
  form: PropTypes.object,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  index: PropTypes.number,
  viewOnlyMode: PropTypes.bool,
};

export default UnitCharacteristics;
