import * as I from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import * as SvgCollection from "./svg";

const CustomIcon = ({ name, size, ...props }) => {
  const AntIcon = I[name];
  const svg = SvgCollection[name];

  if (size) {
    props.width = size;
    props.height = size;
  }

  if (AntIcon) return <AntIcon {...props} />;

  if (svg) return <img src={svg} alt="" style={{ verticalAlign: "top" }} {...props} />;

  return <I.QuestionCircleOutlined />;
};

CustomIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
};

export default CustomIcon;
