import { get, put, post, remove, patch } from "./http.service";
import { delay, getSearchListQuery } from "../utils/utils";
import { employeeContactList } from "../__mocks__/employees";

const EmployeeService = {
  // getAllEmployees: async () => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve(employeeContactList));
  // },
  // getEmployeeById: async id => {
  //   await delay(2000);
  //   const response = employeeContactList.find(value => value.id === id) || {};
  //   return new Promise(resolve => resolve(response));
  // },
  // updateEmployee: async payload => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve());
  // },
  suggestions: query => get(`user/suggestion${getSearchListQuery(query)}`),
  searchEmployees: query => get(`user/search${getSearchListQuery(query)}`),
  getAllEmployees: () => get("user"),
  getEmployeeById: id => get(`user/${id}`),
  updateEmployee: (id, body) => put(`user/${id}`, body),
  exportEmployees: () => get("user/export?type=employee", { responseType: "base64" }),
  searchESMContact: query => get(`user/search${getSearchListQuery(query)}`),
};
export default EmployeeService;
