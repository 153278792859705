import React from "react";
import { useSelector } from "react-redux";
import SearchUserForm from "../../components/SearchUserForm/SearchUserForm";
import { getAuditors, getAuditorsSuggestion } from "../../store/actions/auditor.actions";

const SearchAuditors = () => {
  const { pagination, suggestionLoading } = useSelector(state => state.auditor);

  return (
    <div className="employee-search">
      <SearchUserForm
        pagination={pagination}
        loading={suggestionLoading}
        getList={getAuditors}
        getSuggestions={getAuditorsSuggestion}
        filterCriteria={{ type: "auditor" }}
      />
    </div>
  );
};

export default SearchAuditors;
