import { Button, Col, Form, InputNumber, Radio, Row, Tooltip } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { useLanguage } from "../../hooks";
import { FILTERS_PROPERTY_UNIT_STATUS, FILTERS_PROPERTY_UNIT_TYPE, HELPER_TEXT_KEYS } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { getDangerouslySetHTML, getTranslatedHelperText } from "../../utils/utils";

const UnitListActions = ({
  viewOnlyMode,
  onUnitAdd,
  showFilters,
  form,
  filterPropertyUnitType,
  onFilterPropertyUnitType,
  filterPropertyUnitStatus,
  onFilterPropertyUnitStatus,
}) => {
  const [disableAddUnits, setDisableAddUnits] = useState(true);
  const { lookupList } = useSelector(state => state.helperText);
  const { t } = useTranslation();
  const { language } = useLanguage();
  const canAddUnit = permissionsUtil.checkAuth("unit_add");
  const canEditUnit = permissionsUtil.checkAuth("unit_edit");

  return (
    <Row className="UnitInfoList__actions">
      {showFilters && (
        <Row className="UnitInfoList__filters-row">
          <Col className="UnitInfoList__filter-col">
            <label htmlFor="UnitInfoList__filter-unit-type">
              {t("UnitInfoList.UnitType")}
              &nbsp;
              {!_isEmpty(lookupList[HELPER_TEXT_KEYS.unit.unitTypeFilter]) && (
                <Tooltip
                  title={getDangerouslySetHTML(
                    getTranslatedHelperText(lookupList[HELPER_TEXT_KEYS.unit.unitTypeFilter], language),
                  )}
                >
                  <CustomIcon name="InfoCircleOutlined" />
                </Tooltip>
              )}
            </label>
            <Radio.Group
              size="middle"
              name="UnitInfoList__filter-unit-type"
              className="UnitInfoList__filter"
              value={filterPropertyUnitType}
              onChange={e => onFilterPropertyUnitType(e.target.value)}
              optionType="button"
            >
              {FILTERS_PROPERTY_UNIT_TYPE.map(unitType => (
                <Radio.Button value={unitType.value} key={unitType.value}>
                  {unitType.iconName && <CustomIcon name={unitType.iconName} size={14} className="merged-unit-icon" />}
                  {unitType.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>

          <Col className="UnitInfoList__filter-col">
            <label htmlFor="UnitInfoList__filter-unit-status">
              {t("UnitInfoList.UnitStatus")}
              &nbsp;
              {!_isEmpty(lookupList[HELPER_TEXT_KEYS.unit.unitStatusFilter]) && (
                <Tooltip
                  title={getDangerouslySetHTML(
                    getTranslatedHelperText(lookupList[HELPER_TEXT_KEYS.unit.unitStatusFilter], language),
                  )}
                >
                  <CustomIcon name="InfoCircleOutlined" />
                </Tooltip>
              )}
            </label>
            <Radio.Group
              size="middle"
              name="UnitInfoList__filter-unit-status"
              className="UnitInfoList__filter"
              value={filterPropertyUnitStatus}
              onChange={e => onFilterPropertyUnitStatus(e.target.value)}
              optionType="button"
            >
              {FILTERS_PROPERTY_UNIT_STATUS.map(unitStatus => (
                <Radio.Button value={unitStatus.value} key={unitStatus.value}>
                  {unitStatus.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      )}
      {!viewOnlyMode && (canAddUnit || canEditUnit) && (
        <div className="add-units-info-form">
          <Form.Item>
            <label className="add-unit-info-tooltip">
              {showFilters ? t("UnitInfoList.MultipleUnitAddMessage") : t("UnitInfoList.SingleUnitAddMessage")}
              &nbsp;
              {!_isEmpty(lookupList[HELPER_TEXT_KEYS.unit.addUnits]) && (
                <Tooltip
                  title={getDangerouslySetHTML(
                    getTranslatedHelperText(lookupList[HELPER_TEXT_KEYS.unit.addUnits], language),
                  )}
                >
                  <CustomIcon name="InfoCircleOutlined" />
                </Tooltip>
              )}
            </label>
            <Row className="addUnits__actions">
              <Col>
                <Form.Item
                  noStyle
                  name={["addUnits"]}
                  fieldKey="addUnits"
                  rules={[{ min: 1, max: 10, type: "number", message: "Add no more than 10 units at a time." }]}
                >
                  <InputNumber
                    type="number"
                    step="1"
                    placeholder="1"
                    min="1"
                    max="10"
                    className="add-input-field"
                    onChange={() => {
                      setDisableAddUnits(
                        !form.getFieldValue(["unitInfo", "addUnits"]) ||
                          form.getFieldValue(["unitInfo", "addUnits"]) <= 0 ||
                          form.getFieldValue(["unitInfo", "addUnits"]) > 10,
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    if (form.getFieldError(["unitInfo", "addUnits"])) {
                      onUnitAdd(form.getFieldValue(["unitInfo", "addUnits"]));
                    }
                  }}
                  className="btn-icon"
                  icon={<CustomIcon name="PlusOutlined" />}
                  disabled={disableAddUnits}
                >
                  {t("UnitInfoList.AddUnitButtonLabel")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </div>
      )}
    </Row>
  );
};

UnitListActions.propTypes = {
  viewOnlyMode: PropTypes.bool,
  form: PropTypes.object,
  onUnitAdd: PropTypes.func,
  showFilters: PropTypes.bool,
  filterPropertyUnitStatus: PropTypes.string,
  filterPropertyUnitType: PropTypes.string,
  onFilterPropertyUnitStatus: PropTypes.func,
  onFilterPropertyUnitType: PropTypes.func,
};

export default UnitListActions;
