/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Row, Col, Collapse } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import CustomIcon from "../../components/CustomIcon/CustomIcon";

import {
  getAvailableSpaceForBuilding,
  getClearHeight,
  getMeasurements,
  getTotalAvailableForPreview,
  isAsianCountry,
} from "../../utils/property.util";
import { MEASUREMENT_TYPE, MEASURMENT_UNIT_CODES, OUTBOUND_API_TYPES } from "../../utils/constants";
import { useLanguage } from "../../hooks";
import { getLocalTranslatedValue } from "../../i18n";

const { Panel } = Collapse;

const UnitItem = ({ values, activeMeasurement, unit, isGridView, ...collapsePanelProps }) => {
  const { language } = useLanguage();
  const translatedUnitValues = unit.translations ? unit.translations[language.current] : {};
  const translatedMarketingUnitName =
    language.current === "en"
      ? _get(unit, "marketingUnitName", "")
      : _get(translatedUnitValues, "marketingUnitName", "");
  const isKeyFeaturesAvailable =
    !_isEmpty(unit.keyFeatures) &&
    (!_isEmpty(unit.keyFeatures.feature1) ||
      !_isEmpty(unit.keyFeatures.feature2) ||
      !_isEmpty(unit.keyFeatures.feature3) ||
      !_isEmpty(unit.keyFeatures.feature4));
  const isTranslatedKeyFeaturesAvailable =
    !_isEmpty(translatedUnitValues?.keyFeatures) &&
    (!_isEmpty(translatedUnitValues?.keyFeatures?.feature1) ||
      !_isEmpty(translatedUnitValues?.keyFeatures?.feature2) ||
      !_isEmpty(translatedUnitValues?.keyFeatures?.feature3) ||
      !_isEmpty(translatedUnitValues?.keyFeatures?.feature4));

  const measurements = getMeasurements(
    _get(values, "buildingInfo.minSpaceAvailable", 0),
    _get(values, "buildingInfo.maxSpaceAvailable", 0),
    _get(values, "buildingInfo.totalBuildingSize", 0),
    _get(values, "buildingInfo.minSpaceAvailableUOM", ""),
    OUTBOUND_API_TYPES.BUILDING,
    getAvailableSpaceForBuilding(
      values,
      _get(values, "buildingInfo.minSpaceAvailableUOM", ""),
      _get(values, "buildingInfo.maxSpaceAvailable", 0),
    ),
  );
  const unitMeasurement = getMeasurements(
    unit.minSpaceAvailable,
    unit.maxSpaceAvailable,
    null,
    unit.minSpaceAvailableUOM,
    OUTBOUND_API_TYPES.UNIT,
    unit.maxSpaceAvailable,
    _get(unit, "officeSize", null),
  );
  const clearHeightUOM = activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? "FT" : "M";

  const getTotalAvailable = () =>
    `${getTotalAvailableForPreview(
      activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? measurements[0].data : measurements[1].data,
    )} ${activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY ? MEASURMENT_UNIT_CODES.SF : MEASURMENT_UNIT_CODES.SQM}`;

  const getOfficeSize = () => {
    if (unit.officeSize) {
      if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
        return `${_get(unitMeasurement[0], "data.office_size.value")} ${MEASURMENT_UNIT_CODES.SF}`;
      }
      return `${_get(unitMeasurement[1], "data.office_size.value")} ${MEASURMENT_UNIT_CODES.SQM}`;
    }
    return "N/A";
  };

  const getUnitMinSize = () => {
    if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
      return `${_get(unitMeasurement[0], "data.min_divisible.value").toLocaleString("en-US")} ${
        MEASURMENT_UNIT_CODES.SF
      }`;
    }
    return `${_get(unitMeasurement[1], "data.min_divisible.value").toLocaleString("en-US")} ${
      MEASURMENT_UNIT_CODES.SQM
    }`;
  };

  const getUnitMaxSize = () => {
    if (activeMeasurement === MEASUREMENT_TYPE.US_CUSTOMARY) {
      return `${_get(unitMeasurement[0], "data.max_contiguous.value").toLocaleString("en-US")} ${
        MEASURMENT_UNIT_CODES.SF
      }`;
    }
    return `${_get(unitMeasurement[1], "data.max_contiguous.value").toLocaleString("en-US")} ${
      MEASURMENT_UNIT_CODES.SQM
    }`;
  };

  if (!isGridView) {
    return (
      <Panel {...collapsePanelProps} header={translatedMarketingUnitName}>
        <Row gutter={8}>
          {language.current === "en" && isKeyFeaturesAvailable && (
            <Col xs={24} md={6}>
              <div className="property-item-title">
                {/* {t("PropertyPreview.UnitFeatures")} */}
                {getLocalTranslatedValue(language.current, "UnitFeatures")}
              </div>
              <ul className="property-features-list">
                {!_isEmpty(unit?.keyFeatures?.feature1) && (
                  <li className="property-item-value">{unit.keyFeatures.feature1}</li>
                )}
                {!_isEmpty(unit?.keyFeatures?.feature2) && (
                  <li className="property-item-value">{unit.keyFeatures.feature2}</li>
                )}
                {!_isEmpty(unit?.keyFeatures?.feature3) && (
                  <li className="property-item-value">{unit.keyFeatures.feature3}</li>
                )}
                {!_isEmpty(unit?.keyFeatures?.feature4) && (
                  <li className="property-item-value">{unit.keyFeatures.feature4}</li>
                )}
              </ul>
            </Col>
          )}
          {language.current !== "en" && isTranslatedKeyFeaturesAvailable && (
            <Col xs={24} md={6}>
              <div className="property-item-title">
                {/* {t("PropertyPreview.UnitFeatures")} */}
                {getLocalTranslatedValue(language.current, "UnitFeatures")}
              </div>
              <ul className="property-features-list">
                {!_isEmpty(translatedUnitValues?.keyFeatures?.feature1) && (
                  <li className="property-item-value">{translatedUnitValues.keyFeatures.feature1}</li>
                )}
                {!_isEmpty(translatedUnitValues?.keyFeatures?.feature2) && (
                  <li className="property-item-value">{translatedUnitValues.keyFeatures.feature2}</li>
                )}
                {!_isEmpty(translatedUnitValues?.keyFeatures?.feature3) && (
                  <li className="property-item-value">{translatedUnitValues.keyFeatures.feature3}</li>
                )}
                {!_isEmpty(translatedUnitValues?.keyFeatures?.feature4) && (
                  <li className="property-item-value">{translatedUnitValues.keyFeatures.feature4}</li>
                )}
              </ul>
            </Col>
          )}
          <Col xs={24} md={6}>
            {!isAsianCountry(null, "buildingInfo", values) && (
              <Row>
                <Col xs={24} className="property-item-title">
                  {/* {t("PropertyPreview.MinSpaceAvailable")} */}
                  {getLocalTranslatedValue(language.current, "MinSpaceAvailable")}
                </Col>
                <Col xs={24} className="property-item-value">
                  {getUnitMinSize()}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.OfficeSize")} */}
                {getLocalTranslatedValue(language.current, "OfficeSize")}
              </Col>
              <Col xs={24} className="property-item-value">
                {getOfficeSize()}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.ClearHeight")} */}
                {getLocalTranslatedValue(language.current, "ClearHeight")}
              </Col>
              <Col xs={24} className="property-item-value">
                {`${getClearHeight(
                  _get(unit, "clearHeight"),
                  _get(unit, "clearHeightUOM"),
                  activeMeasurement,
                  language.current,
                )} ${getLocalTranslatedValue(language.current, clearHeightUOM)}`}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={6}>
            {!isAsianCountry(null, "buildingInfo", values) && (
              <Row>
                <Col xs={24} className="property-item-title">
                  {/* {t("PropertyPreview.MaxSpaceAvailable")} */}
                  {getLocalTranslatedValue(language.current, "MaxSpaceAvailable")}
                </Col>
                <Col xs={24} className="property-item-value">
                  {getUnitMaxSize()}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.GradeLevelDoors")} */}
                {getLocalTranslatedValue(language.current, "GradeLevelDoors")}
              </Col>
              <Col xs={24} className="property-item-value">
                {_get(unit, ".gradeLevelDoors", "N/A")}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.FireSuppresionSystem")} */}
                {getLocalTranslatedValue(language.current, "FireSuppresionSystem")}
              </Col>
              <Col xs={24} className="property-item-value">
                {_get(unit, "fireSuppressionSystem")}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={6}>
            {!isAsianCountry(null, "buildingInfo", values) && (
              <Row>
                <Col xs={24} className="property-item-title">
                  {/* {t("PropertyPreview.TotalAvailable")} */}
                  {getLocalTranslatedValue(language.current, "TotalAvailable")}
                </Col>
                <Col xs={24} className="property-item-value">
                  {getTotalAvailable()}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.WarehouseLightingType")} */}
                {getLocalTranslatedValue(language.current, "WarehouseLightingType")}
              </Col>
              <Col xs={24} className="property-item-value">
                {_get(unit, "warehouseLightingType")}
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.DockHighDoors")} */}
                {getLocalTranslatedValue(language.current, "DockHighDoors")}
              </Col>
              <Col xs={24} className="property-item-value">
                {_get(unit, ".dockHighDoors", "N/A")}
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>
    );
  }

  return (
    <Panel {...collapsePanelProps} header={translatedMarketingUnitName} className="site-collapse-custom-panel">
      <Row gutter={8}>
        <Col xs={24} md={6}>
          {!isAsianCountry(null, "buildingInfo", values) && (
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.MinSpaceAvailable")} */}
                {getLocalTranslatedValue(language.current, "MinSpaceAvailable")}
              </Col>
              <Col xs={24} className="property-item-value">
                {getUnitMinSize()}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} className="property-item-title">
              {/* {t("PropertyPreview.GradeLevelDoors")} */}
              {getLocalTranslatedValue(language.current, "GradeLevelDoors")}
            </Col>
            <Col xs={24} className="property-item-value">
              {_get(unit, ".gradeLevelDoors", "N/A")}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6}>
          {!isAsianCountry(null, "buildingInfo", values) && (
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.MaxSpaceAvailable")} */}
                {getLocalTranslatedValue(language.current, "MaxSpaceAvailable")}
              </Col>
              <Col xs={24} className="property-item-value">
                {getUnitMaxSize()}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} className="property-item-title">
              {/* {t("PropertyPreview.ClearHeight")} */}
              {getLocalTranslatedValue(language.current, "ClearHeight")}
            </Col>
            <Col xs={24} className="property-item-value">
              {`${getClearHeight(
                _get(unit, "clearHeight"),
                _get(unit, "clearHeightUOM"),
                activeMeasurement,
              )} ${getLocalTranslatedValue(language.current, clearHeightUOM)}`}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6}>
          {!isAsianCountry(null, "buildingInfo", values) && (
            <Row>
              <Col xs={24} className="property-item-title">
                {/* {t("PropertyPreview.TotalAvailable")} */}
                {getLocalTranslatedValue(language.current, "TotalAvailable")}
              </Col>
              <Col xs={24} className="property-item-value">
                {getTotalAvailable()}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} className="property-item-title">
              {/* {t("PropertyPreview.FireSuppresionSystem")} */}
              {getLocalTranslatedValue(language.current, "FireSuppresionSystem")}
            </Col>
            <Col xs={24} className="property-item-value">
              {_get(unit, "fireSuppressionSystem")}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6}>
          <Row>
            <Col xs={24} className="property-item-title">
              {/* {t("PropertyPreview.OfficeSize")} */}
              {getLocalTranslatedValue(language.current, "OfficeSize")}
            </Col>
            <Col xs={24} className="property-item-value">
              {getOfficeSize()}
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="property-item-title">
              {/* {t("PropertyPreview.DockHighDoors")} */}
              {getLocalTranslatedValue(language.current, "DockHighDoors")}
            </Col>
            <Col xs={24} className="property-item-value">
              {_get(unit, ".dockHighDoors", "N/A")}
            </Col>
          </Row>
        </Col>
      </Row>
    </Panel>
  );
};

const MultipleUnits = ({ values, activeMeasurement }) => {
  const { language } = useLanguage();
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([]);
  const [gridActiveCollapseKeysOne, setGridActiveCollapseKeysOne] = useState([]);
  const [gridActiveCollapseKeysTwo, setGridActiveCollapseKeysTwo] = useState([]);
  const isGridView = values.unitInfo.length > 5;

  const ExpandAll = () => (
    <div
      className="expand-button"
      onClick={() => {
        if (!isGridView) {
          setActiveCollapseKeys([...Array(values.unitInfo.length).keys()]);
        } else {
          setGridActiveCollapseKeysOne([
            ...Array(values.unitInfo.slice(0, Math.ceil(values.unitInfo.length / 2)).length).keys(),
          ]);
          setGridActiveCollapseKeysTwo([
            ...Array(
              values.unitInfo.slice(Math.ceil(values.unitInfo.length / 2), values.unitInfo.length).length,
            ).keys(),
          ]);
        }
      }}
    >
      <span className="button-label">
        {/* {t("PropertyPreview.ExpandAll")} */}
        {getLocalTranslatedValue(language.current, "ExpandAll")}
      </span>
      <CustomIcon name="expandAll" />
    </div>
  );

  const CollapseAll = () => (
    <div
      className="collapse-button"
      onClick={() => {
        setActiveCollapseKeys([]);
        if (!isGridView) {
          setActiveCollapseKeys([]);
        } else {
          setGridActiveCollapseKeysOne([]);
          setGridActiveCollapseKeysTwo([]);
        }
      }}
    >
      <span className="button-label">
        {/* {t("PropertyPreview.CollapseAll")} */}
        {getLocalTranslatedValue(language.current, "CollapseAll")}
      </span>
      <CustomIcon name="collapseAll" />
    </div>
  );

  return (
    <div className="multiple-units w-container">
      <div className="header">
        <h2 className="property-heading">
          {/* {t("PropertyPreview.UnitDetails")} */}
          {getLocalTranslatedValue(language.current, "UnitDetails")}
        </h2>
        <div className="buttons-container">
          <ExpandAll />
          <CollapseAll />
        </div>
      </div>
      {isGridView ? (
        <div className="site-collapse-custom-collapse">
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CustomIcon name={isActive ? "toggleClose" : "toggleOpen"} />}
                expandIconPosition="right"
                activeKey={gridActiveCollapseKeysOne}
                onChange={keys => {
                  setGridActiveCollapseKeysOne(keys);
                }}
              >
                {values.unitInfo.slice(0, Math.ceil(values.unitInfo.length / 2)).map(_unit => (
                  <UnitItem
                    values={values}
                    activeMeasurement={activeMeasurement}
                    unit={_unit}
                    isGridView={isGridView}
                  />
                ))}
              </Collapse>
            </Col>
            <Col xs={24} md={12}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CustomIcon name={isActive ? "toggleClose" : "toggleOpen"} />}
                expandIconPosition="right"
                activeKey={gridActiveCollapseKeysTwo}
                onChange={keys => {
                  setGridActiveCollapseKeysTwo(keys);
                }}
              >
                {values.unitInfo.slice(Math.ceil(values.unitInfo.length / 2), values.unitInfo.length).map(_unit => (
                  <UnitItem
                    values={values}
                    activeMeasurement={activeMeasurement}
                    unit={_unit}
                    isGridView={isGridView}
                  />
                ))}
              </Collapse>
            </Col>
          </Row>
        </div>
      ) : (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CustomIcon name={isActive ? "toggleClose" : "toggleOpen"} />}
          expandIconPosition="right"
          activeKey={activeCollapseKeys}
          onChange={keys => {
            setActiveCollapseKeys(keys);
          }}
        >
          {values.unitInfo.map(_unit => (
            <UnitItem values={values} activeMeasurement={activeMeasurement} unit={_unit} />
          ))}
        </Collapse>
      )}
    </div>
  );
};

UnitItem.propTypes = {
  values: PropTypes.object,
  activeMeasurement: PropTypes.object,
  unit: PropTypes.object,
  isGridView: PropTypes.bool,
};

MultipleUnits.propTypes = {
  values: PropTypes.object,
  activeMeasurement: PropTypes.object,
};

export default MultipleUnits;
