import { Button, Checkbox, Col, Row, Typography, Modal } from "antd";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { LANGUAGE_CODES, MEDIA_TYPES } from "../../utils/constants";
import { isPDFFileType } from "../../utils/utils";
import PDFIcon from "../PropertyMedia/PDFIcon";
import UnitCollateralDroppable from "./UnitCollateralDroppable";
import { useLanguage } from "../../hooks";

const { Text } = Typography;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination, draggedItemId) => {
  const sourceClone = [...source];
  const destClone = [...destination];
  let itemToMoveIndex;
  let itemToMove;

  if (droppableSource.droppableId !== droppableDestination.droppableId) {
    if (droppableDestination.droppableId === MEDIA_TYPES.UNIT_MARKETING_COLLATERAL) {
      itemToMoveIndex = sourceClone.findIndex(d => d.asset_id === draggedItemId);
      itemToMove = sourceClone[itemToMoveIndex];
      const prevRemoved = destClone.splice(0, 1, itemToMove);
      sourceClone.splice(itemToMoveIndex, 1);
      if (prevRemoved[0]) sourceClone.push(prevRemoved[0]);
    }

    if (droppableDestination.droppableId === "allUnitCollateral") {
      itemToMoveIndex = sourceClone.findIndex(d => d.asset_id === draggedItemId);
      itemToMove = sourceClone[itemToMoveIndex];
      // const prevRemoved =
      destClone.push(itemToMove);
      sourceClone.splice(itemToMoveIndex, 1);
      // if (prevRemoved[0]) sourceClone.push(prevRemoved[0]);
    }
  }
  // if (droppableDestination.droppableId === "allUnitCollateral") {
  //   const itemToMove = sourceClone.find(d => d.asset_id === draggedItemId);
  //   const prevRemoved = destClone.splice(0, 1, itemToMove);
  //   sourceClone = sourceClone.concat(prevRemoved);
  // }
  // const removed = sourceClone.find(d => d.asset_id === draggedItemId);
  // // destClone.splice(droppableDestination.index, 0, removed);
  // destClone.push(removed);
  // const newSrcArr = sourceClone.filter((d, i) => d.asset_id !== removed.asset_id);

  const result = {};
  result[droppableSource.droppableId] = sourceClone; // newSrcArr;
  result[droppableDestination.droppableId] = destClone;
  result.draggedItem = itemToMove; // removed;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "initial",
  // styles we need to apply on draggables
  userSelect: "text",
  ...draggableStyle,
});

const getMainListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "initial",
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  height: "100%",
  width: "100%",
});

function UnitMediaModal({
  allFiles,
  closeModal,
  previewFile,
  selectedFile,
  updateFiles,
  translatedFile,
  useSingleMedia,
}) {
  const { t } = useTranslation();
  const [textSelectable, setTextSelectable] = useState(false);
  const [copyLocalized, setCopyLocalized] = useState(useSingleMedia);
  const { language, languageStatus } = useLanguage();
  const [files, setFiles] = useState({
    allUnitCollateral: [],
    unitMarketingCollateral: [],
    translatedMedia: {},
  });

  useEffect(() => {
    if (_isEmpty(files.unitMarketingCollateral)) {
      setCopyLocalized(false);
    }
  }, [files.unitMarketingCollateral]);

  useEffect(() => {
    const _files = { ...files, allUnitCollateral: allFiles };
    _files.unitMarketingCollateral = !_isEmpty(selectedFile) ? [selectedFile] : [];
    // _files.translatedMedia = !_isEmpty(translatedFile)
    //   ? { [language.secondary]: { [MEDIA_TYPES.UNIT_MARKETING_COLLATERAL]: [translatedFile] } }
    //   : {};
    _files.translatedMedia = {};

    if (!_isEmpty(translatedFile)) {
      _forEach(language.secondary, _lang => {
        if (translatedFile[_lang]) {
          _files.translatedMedia = {
            ..._files.translatedMedia,
            [_lang]: {
              [MEDIA_TYPES.UNIT_MARKETING_COLLATERAL]: [translatedFile[_lang].file],
            },
          };
        }
      });
    }
    // _files.allUnitCollateral = _filter(
    //   allFiles,
    //   f => isPDFFileType(f) && f.asset_id !== selectedFile?.asset_id && f.asset_id !== translatedFile?.asset_id,
    // );
    _files.allUnitCollateral = _filter(
      allFiles,
      f =>
        isPDFFileType(f) &&
        f.asset_id !== selectedFile?.asset_id &&
        !Object.keys(translatedFile || {}).some(_key => translatedFile[_key].file.asset_id === f.asset_id),
    );
    setFiles(_files);
  }, [allFiles, selectedFile, translatedFile]);

  useEffect(() => {
    setCopyLocalized(useSingleMedia);
  }, [useSingleMedia]);

  const onDragEnd = result => {
    const { source, destination, draggableId } = result;
    // dropped outside the list or source & destination is same
    if (!destination || destination.droppableId === source.droppableId) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getMedia(source.droppableId), source.index, destination.index);

      let state = { allUnitCollateral: items };

      if (source.droppableId === MEDIA_TYPES.UNIT_MARKETING_COLLATERAL) {
        // state = { unitMarketingCollateral: items };
        state = updateTranslatedMediaFields(items, MEDIA_TYPES.UNIT_MARKETING_COLLATERAL);
      }
      setFiles(state);
    } else {
      const resultState = move(
        getMedia(source.droppableId),
        getMedia(destination.droppableId),
        source,
        destination,
        draggableId,
      );
      //   if (destination.droppableId === "allUnitCollateral") {
      //     this.setState({ category: resultState.draggedItem.type });
      //   }
      let state = { ...files };
      if (resultState.unitMarketingCollateral) {
        state = {
          ...state,
          ...updateTranslatedMediaFields(resultState.unitMarketingCollateral, MEDIA_TYPES.UNIT_MARKETING_COLLATERAL),
        };
      }
      if (resultState.allUnitCollateral) {
        state = { ...state, allUnitCollateral: resultState.allUnitCollateral };
      }

      setFiles(state);
      //   this.setState(state, () => {
      //     this.props.updateFiles(this.state);
      //   });
    }
  };

  const saveUnitCollateral = () => {
    updateFiles(files.unitMarketingCollateral?.[0] || {}, files.translatedMedia, copyLocalized);
    closeModal();
  };

  const discardUnitCollateral = () => {
    const _files = { ...files };
    // if (languageStatus !== "primary") {
    //   _files.translatedMedia = {};
    // } else {
    //   _files.unitMarketingCollateral = {};
    // }
    if (language.current === LANGUAGE_CODES.English.value) {
      _files.unitMarketingCollateral = {};

      if (useSingleMedia) {
        _files.translatedMedia = {};
      }
    } else if (useSingleMedia) {
      _files.unitMarketingCollateral = {};
      _files.translatedMedia = {};
    } else {
      delete _files.translatedMedia[language.current];
    }
    updateFiles(_files.unitMarketingCollateral?.[0] || {}, _files.translatedMedia, false);
    closeModal();
  };

  const getMedia = key => {
    const isEnglish = language.current === LANGUAGE_CODES.English.value;
    const _translatedFile = !_isEmpty(language.secondary)
      ? _get(files, `translatedMedia.${language.current}.${key}`, [])
      : [];

    return isEnglish || key !== MEDIA_TYPES.UNIT_MARKETING_COLLATERAL ? files[key] : _translatedFile;
  };

  const updateTranslatedMediaFields = (items, key) => {
    const isEnglish = language.current === LANGUAGE_CODES.English.value;

    return isEnglish ? { [key]: items } : { translatedMedia: { [language.current]: { [key]: items } } };
  };

  const _onChangeCheckbox = (event, key) => {
    const { checked } = event.target;
    const { secondary } = language;
    let _state = [];

    if (checked) {
      _state = files[key];
    }

    let _files = { ...files };
    _forEach(language.secondary, _lang => {
      _files = {
        ..._files,
        translatedMedia: {
          ..._files.translatedMedia,
          [_lang]: {
            [key]: _state,
          },
        },
      };
    });
    // setFiles({
    //   ...files,
    //   translatedMedia: {
    //     [secondary]: {
    //       [key]: _state,
    //     },
    //   },
    // });
    setFiles(_files);
    setCopyLocalized(checked);
  };

  const showMessage = _isEmpty(files.allUnitCollateral) && _isEmpty(files.unitMarketingCollateral);

  const _disableDiscardMedia = language.current !== LANGUAGE_CODES.English.value && copyLocalized;

  const _disableCheckbox = language.current !== LANGUAGE_CODES.English.value || _isEmpty(files.unitMarketingCollateral);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row className="fields-row" gutter={24} type="flex">
        <Col span={8} xs={24} sm={12} lg={12}>
          <h3>{t("UnitMediaModal.UnitMarketingCollateral")}</h3>
          {!_isEmpty(language.secondary) && (
            <Checkbox
              checked={copyLocalized}
              onChange={event => _onChangeCheckbox(event, MEDIA_TYPES.UNIT_MARKETING_COLLATERAL)}
              className="media-langauge-checkbox"
              disabled={_disableCheckbox}
            >
              Apply for all languages
            </Checkbox>
          )}
          <UnitCollateralDroppable
            // list={files.unitMarketingCollateral}
            list={getMedia(MEDIA_TYPES.UNIT_MARKETING_COLLATERAL)}
            isDropDisabled={_disableDiscardMedia}
            type={MEDIA_TYPES.UNIT_MARKETING_COLLATERAL}
            previewFile={previewFile}
            className="mg-left-8 mg-right-8 mg-bottom-8 droppable"
            placeholderText={t("PropertyMedia.Placeholder3")}
          />
        </Col>
        <Col span={8} xs={24} sm={12} lg={12}>
          <h3>{t("UnitMediaModal.AvailableFiles")}</h3>
          <div className="property-unit-media__all-container droppable">
            <Droppable droppableId="allUnitCollateral" direction="horizontal" isDropDisabled={_disableDiscardMedia}>
              {(provided1, snapshot1) => (
                <div ref={provided1.innerRef} style={getMainListStyle(snapshot1.isDraggingOver)}>
                  {files.allUnitCollateral.length
                    ? _map(files.allUnitCollateral, (item, index) => {
                      const _url = item.thumbnails_webimage_url;
                      const _alt = item.asset_name;
                      return (
                        <Draggable
                          key={item.asset_id}
                          draggableId={item.asset_id}
                          index={index}
                          isDragDisabled={textSelectable}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="property-unit-media-all__item"
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div
                                className="property-unit-media__item-hover"
                                onDoubleClick={() => previewFile(item)}
                              >
                                <img className="item-image pdf" src={_url} alt={_alt} />
                                <PDFIcon item={item} isPDF size={35} className="pdf" />
                                <div
                                  className="id-container text-center"
                                  onMouseEnter={() => setTextSelectable(true)}
                                  onMouseLeave={() => setTextSelectable(false)}
                                >
                                  {item.asset_id}
                                  {/* Removed <span> */}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                    : showMessage && (
                    <Text className="helper-text" type="secondary">
                      {t("UnitMediaModal.NoAsset")}
                    </Text>
                    )}
                  {provided1.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </Col>
      </Row>

      <Row className="fields-row mg-top-15" gutter={20} type="flex">
        <Col xs={12} sm={12} md={{ offset: 6, span: 6 }} lg={{ offset: 6, span: 6 }}>
          <Button block type="primary" onClick={saveUnitCollateral} disabled={_disableDiscardMedia}>
            {t("UnitMediaModal.SaveUnitCollateral")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Button block onClick={discardUnitCollateral} disabled={_disableDiscardMedia}>
            {t("Buttons.DiscardChanges")}
          </Button>
        </Col>
      </Row>
    </DragDropContext>
  );
}
UnitMediaModal.propTypes = {
  allFiles: PropTypes.array,
  closeModal: PropTypes.func,
  previewFile: PropTypes.func,
  selectedFile: PropTypes.object,
  translatedFile: PropTypes.object,
  useSingleMedia: PropTypes.bool,
  updateFiles: PropTypes.func,
};
export default UnitMediaModal;
