import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const landVacancy = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.LAND_VACANCY_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.LAND_VACANCY_SUCCESS:
      draft[action.key] = false;
      draft.error = null;
      return draft;
    case ACTIONS.LAND_VACANCY_ERROR:
      draft[action.key] = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.LAND_VACANCY_TAGS_SUCCESS:
      draft.tags = action.response;
      return draft;
    case ACTIONS.LAND_VACANCY_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    default:
      return draft;
  }
});

export default landVacancy;
