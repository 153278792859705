import ACTIONS from "../actions/types.actions";
import appInitialState from "../app-state/app-initial.state";
import announcement from "./announcement.reducer";
import audit from "./audit.reducer";
import auditor from "./auditor.reducer";
import broker from "./broker.reducer";
import building from "./building.reducer";
import downtimeReport from "./downtimeReport.reducer";
import employee from "./employee.reducer";
import featureFlag from "./featureFlag.reducer";
import helperText from "./helperText.reducer";
import land from "./land.reducer";
import landVacancy from "./landVacancy.reducer";
import language from "./language.reducer";
import market from "./market.reducer";
import property from "./property.reducer";
import propertyContactHistory from "./propertyContactHistory.reducer";
import propertyRollUp from "./propertyRollUp.reducer";
import division from "./division.reducer";
import region from "./region.reducer";
import subMarket from "./subMarket.reducer";
import template from "./template.reducer";
import unitVacancy from "./unitVacancy.reducer";
import unmarketReason from "./unmarketReasons.reducer";
import upload from "./upload.reducer";
import user from "./user.reducer";
import home from "./home.reducer";

export default function reducer(state = appInitialState, action) {
  if (action.type === ACTIONS.APP_RESET) {
    return appInitialState;
  }
  return {
    announcement: announcement(state.announcement, action),
    audit: audit(state.audit, action),
    auditor: auditor(state.auditor, action),
    broker: broker(state.broker, action),
    building: building(state.building, action),
    downtimeReport: downtimeReport(state.downtimeReport, action),
    employee: employee(state.employee, action),
    featureFlag: featureFlag(state.featureFlag, action),
    helperText: helperText(state.helperText, action),
    land: land(state.land, action),
    landVacancy: landVacancy(state.landVacancy, action),
    language: language(state.language, action),
    market: market(state.market, action),
    property: property(state.property, action),
    division: division(state.division, action),
    region: region(state.region, action),
    propertyContactHistory: propertyContactHistory(state.propertyContactHistory, action),
    propertyRollUp: propertyRollUp(state.propertyRollUp, action),
    subMarket: subMarket(state.subMarket, action),
    template: template(state.template, action),
    unitVacancy: unitVacancy(state.unitVacancy, action),
    unmarketReason: unmarketReason(state.unmarketReason, action),
    upload: upload(state.upload, action),
    user: user(state.user, action),
    home: home(state.home, action),
  };
}
