/* eslint-disable no-restricted-globals */
import axios from "axios";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { BASE_URL } from "../utils/constants";
import { toastMessage } from "../utils/utils";
import { AuthService, signIn } from "./auth.service";

export function SetApiRequestHeader(customHeader = {}) {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...customHeader,
  };
  return defaultHeaders;
}

const instance = axios.create({
  baseURL: BASE_URL || "/api/",
  timeout: 29000,
  headers: SetApiRequestHeader(),
});

instance.interceptors.request.use(
  config => {
    const [currentAccount = null] = AuthService.getAllAccounts();
    if (!_isEmpty(currentAccount)) {
      const request = {
        scopes: ["openid"],
        account: currentAccount,
        forceRefresh: false,
      };
      return AuthService.acquireTokenSilent(request)
        .then(tokenResponse => {
          config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
          return Promise.resolve(config);
        })
        .catch(error => {
          console.log("sign in error: ==>", error);

          toastMessage("info", "Your session is expired we are trying to refresh your session.");
          return AuthService.acquireTokenRedirect({ loginHint: currentAccount.username, state: location.pathname });
          // setTimeout(() => {
          //   signIn();
          // }, 1000);
          // return Promise.reject(error);
        });
    }

    toastMessage("info", "Your session is expired we are redirecting you to login screen.");
    setTimeout(() => {
      signIn({ state: location.pathname });
    }, 1000);
    return config;
  },
  err => Promise.reject(err),
);

// Add a request interceptor
// instance.interceptors.request.use(
//   config => {
//     const token = getToken();
//     const user = getUser();

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     if (user) {
//       config.headers["user-email"] = user.userName;
//     }
//     if (config.headers["for-file"]) {
//       config.responseType = "blob";
//     }
//     if (config.headers["for-upload"]) {
//       config.timeout = 0;
//     }
//     return config;
//   },
//   error => Promise.reject(error),
// );

instance.interceptors.response.use(undefined, err => {
  const { config, response } = err;
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  const backoff = new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(() => axios(config));
});

// Add a response interceptor
instance.interceptors.response.use(
  response =>
    // if (_get(response, "headers.access-token", "")) {
    //   const token = _get(response, "headers.access-token", "");
    //   setToken(TOKEN, token);
    // }
    response.data,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        return signIn();
      }
      if (!_isEmpty(error, "response") && error.response.status >= 400) {
        const errorObj =
          typeof error === "string"
            ? error
            : _get(error, "response.data.message") || _get(error, "response.data.error") || "Something Went Wrong";
        const errMessage = decodeURI(errorObj.message || errorObj);
        // const errMessage = errorObj.message ? (decodeURI(errorObj.message)) : decodeURI(errorObj);
        toastMessage("error", errMessage);
        return Promise.reject(errMessage);
      }
    }

    const err = error.message ? error.message : JSON.stringify(error);
    console.log("error===> ", error);
    // toastMessage("error", err);
    return Promise.reject(err);
  },
);

const _getApiVersion = (params = {}) => params.apiVersion || "";
const _retryParams = (params = {}) => ({
  ...params,
  validateStatus: status => status < 400,
  retry: 4,
  retryDelay: 2000,
});

export function get(url, params) {
  return instance.get(`${_getApiVersion(params)}/${url}`, _retryParams(params));
}

export function post(url, body, params) {
  return instance.post(`${_getApiVersion(params)}/${url}`, body, _retryParams(params));
}

export function put(url, body, params) {
  return instance.put(`${_getApiVersion(params)}/${url}`, body, _retryParams(params));
}

export function patch(url, params, body) {
  return instance.patch(`${_getApiVersion(params)}/${url}`, body || {}, _retryParams(params));
}

export function remove(url, params, body) {
  return instance.delete(`${_getApiVersion(params)}/${url}`, body || {}, _retryParams(params));
}
