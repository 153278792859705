export const convertSfToSqm = value => value * 0.092903;

export const convertSfToTsubo = value => value * 0.0281031696;

export const convertSqmToSf = value => value * 10.7639;

export const convertSqmToTsubo = value => value * 0.3025;

export const convertTsuboToSf = value => value * 35.5832;

export const convertTsuboToSqm = value => value * 3.30579;

export const convertHaToAc = value => value * 2.471;

export const convertActoHa = value => value / 2.471;

export const convertMuToAc = value => value * 0.16473692;

export const convertMuToHa = value => value / 15;
