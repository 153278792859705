import { Popover, Tooltip } from "antd";
import _isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "../../hooks";
import { addAuditIssue } from "../../store/actions/property.actions";
import { fileUpload } from "../../store/actions/upload.action";
import {
  AUDIT_STATUS,
  AUDIT_STATUS_UI_MAPPING,
  BUILDING_TABS_KEYS,
  OUTBOUND_API_TYPES,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
} from "../../utils/constants";
import AuditEditor from "../AuditEditor/AuditEditor";
import CustomIcon from "../CustomIcon/CustomIcon";
import "./AuditIssuePopover.scss";

const AuditIssuePopover = ({
  form,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  unitIndex,
  canAuditProperty,
  viewOnlyMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [issue, setIssue] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const { language } = useLanguage();
  const { maxAuditIssueIdNumber, auditLoading, auditList } = useSelector(state => ({
    ...state.property,
  }));

  const { selected: buildingSelected } = useSelector(state => ({
    ...state.building,
  }));

  const { selected: landSelected } = useSelector(state => ({
    ...state.land,
  }));

  const isPropertyPublished =
    (buildingSelected?.status === PROPERTY_STATUS.published && !buildingSelected?.isDraft) ||
    (landSelected?.status === PROPERTY_STATUS.published && !landSelected.isDraft);

  const onCancel = () => {
    setPopupVisible(false);
  };

  const onVisibleChange = newOpen => {
    setPopupVisible(newOpen);
    setIssue("");
  };

  const onIssueChange = e => {
    setIssue(e.target.value);
  };

  const onAuditIssueAdd = async (fileList = []) => {
    try {
      let attachments = [];
      if (fileList.length) {
        attachments = await dispatch(fileUpload(fileList));
      }

      const { buildingInfo, unitInfo = [], landInfo } = form.getFieldsValue(true);
      const unit = unitInfo[unitIndex];
      const formFields = landInfo?.propertyType === PROPERTY_TYPE_KEYS.land ? landInfo : buildingInfo;

      const payload = {
        id: `${formFields.propertyCode.value}~${maxAuditIssueIdNumber + 1}`,
        propertyCode: formFields.propertyCode.value,
        marketingPropertyName: formFields.marketingPropertyName,
        market: formFields.marketId,
        status: AUDIT_STATUS.audit_issue,
        field: {
          tab,
          name: fieldName,
        },
        ...(tab === BUILDING_TABS_KEYS[5] && {
          unitCode: unitIndex && unit.ghostUnitCode ? unit.ghostUnitCode : unit.unitCode[0].label,
        }),
        propertyType: tab === BUILDING_TABS_KEYS[5] ? OUTBOUND_API_TYPES.UNIT : formFields.propertyType,
        language: language.current,
        issue,
        attachments,
      };
      await dispatch(addAuditIssue(payload));
      setPopupVisible(false);
    } catch (ex) {
      console.log("ex: ", ex);
    }
  };

  const getAuditIssueFlagStatus = () => {
    const unitInfo = form.getFieldValue("unitInfo");
    let color = null;
    let title = null;

    let filteredAuditList = auditList.filter(
      _audit => _audit.field.tab === tab && _audit.field.name === fieldName && _audit.language === language.current,
    );

    if (_isNumber(unitIndex)) {
      filteredAuditList = filteredAuditList.filter(
        _audit =>
          _audit.unitCode === unitInfo[unitIndex].unitCode[0].label ||
          _audit.unitCode === unitInfo[unitIndex].ghostUnitCode,
      );
    }

    if (filteredAuditList.some(_audit => _audit.status === AUDIT_STATUS.audit_issue)) {
      color = "audit-issue-flag";
      title = AUDIT_STATUS_UI_MAPPING[AUDIT_STATUS.audit_issue];
    } else if (filteredAuditList.some(_audit => _audit.status === AUDIT_STATUS.edition_in_progress)) {
      color = "edition-in-progress-flag";
      title = AUDIT_STATUS_UI_MAPPING[AUDIT_STATUS.edition_in_progress];
    } else if (filteredAuditList.some(_audit => _audit.status === AUDIT_STATUS.ready_for_review)) {
      color = "ready-for-review-flag";
      title = AUDIT_STATUS_UI_MAPPING[AUDIT_STATUS.ready_for_review];
    }

    return (
      ((isPropertyPublished && canAuditProperty && !viewOnlyMode) || color) && (
        <Tooltip title={title}>
          <CustomIcon name="FlagFilled" className={color} />
        </Tooltip>
      )
    );
  };

  const content = (
    <AuditEditor
      onIssueChange={onIssueChange}
      onCancel={onCancel}
      onSubmit={onAuditIssueAdd}
      buttonTitle={t("Buttons.Add")}
      auditLoading={auditLoading}
      issue={issue}
      attachmentType
    />
  );

  const renderWithPopover = () => {
    if (isPropertyPublished && auditPanelDrawerVisible && canAuditProperty && !viewOnlyMode) {
      return (
        <Popover
          destroyPopupOnHide
          overlayClassName="audit-popover"
          content={content}
          trigger="click"
          visible={popupVisible}
          onVisibleChange={onVisibleChange}
        >
          {getAuditIssueFlagStatus()}
        </Popover>
      );
    }
    return getAuditIssueFlagStatus();
  };

  return renderWithPopover();
};

AuditIssuePopover.propTypes = {
  form: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
  unitIndex: PropTypes.number,
  canAuditProperty: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
};

export default AuditIssuePopover;
