import { Button, Col, Form, Input, Row, Select } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _intersection from "lodash/intersection";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "../../index.scss";
import { createTemplate, updateTemplate } from "../../store/actions/template.actions";
import {
  LANGUAGE_CODES,
  TEMPLATE_FIELDS,
  TEMPLATE_FIELD_UI_MAPPING,
  TEMPLATE_TYPE_UI_MAPPING,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
} from "../../utils/constants";
import "./TemplateEditor.scss";
import { findInObject, throwError, toastMessage, sortAlphabetically, checkEmptyObj } from "../../utils/utils";

const { Option } = Select;
const { TextArea } = Input;
const _initialValues = {
  language: LANGUAGE_CODES.English.value,
};

const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_CODES).map(_lang => ({
  label: LANGUAGE_CODES[_lang].label,
  value: LANGUAGE_CODES[_lang].value,
}));

const getTranslatedTemplate = (lang, templateObj, formValue, templateTranslations) => {
  let value;
  if (lang !== LANGUAGE_CODES.English.value) {
    value =
      _get(templateObj, `translations.${lang}.template`, templateObj?.template) ||
      _get(templateTranslations, `[${lang}].template`) ||
      _get(templateTranslations, `[${LANGUAGE_CODES.English.value}].template`) ||
      formValue;
  } else {
    value =
      _get(templateTranslations, `[${LANGUAGE_CODES.English.value}].template`, templateObj?.template) || formValue;
  }

  return value || "";
};

const TemplateEditor = ({ templateItem, onCloseModal, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(_initialValues.language);
  const [templateTranslations, setTemplateTranslations] = useState({
    ...templateItem?.translations,
  });
  const [initialValues, setInitialValues] = useState({
    ..._initialValues,
    ...templateItem,
    template: getTranslatedTemplate(_initialValues.language, templateItem),
  });
  const [fieldList, setFieldList] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      template:
        templateTranslations[currentLanguage]?.template ||
        getTranslatedTemplate(currentLanguage, templateItem, form.getFieldValue("template"), templateTranslations),
    });
  }, [currentLanguage]);

  useEffect(() => {
    if (!_isEmpty(templateItem)) {
      updateFieldsList(templateItem.type);
    }
  }, [templateItem]);

  const getReqBody = values => {
    const _values = { ...values };
    _values.translations = { ...templateTranslations };
    if (_values.language !== LANGUAGE_CODES.English.value && _isEmpty(templateTranslations[_values.language])) {
      _values.translations = { ..._values.translations, [_values.language]: { template: _values.template } };
    }
    if (!_isEmpty(templateTranslations[LANGUAGE_CODES.English.value]?.template?.trim())) {
      _values.template = templateTranslations[LANGUAGE_CODES.English.value].template;
      delete _values.translations[LANGUAGE_CODES.English.value];
    } else if (!_isEmpty(templateItem)) {
      _values.template = templateItem.template;
    }
    checkEmptyObj(_values?.translations);
    return _values;
  };

  const checkTemplateLength = _template => {
    let err = false;
    let _langCode = LANGUAGE_CODES.English.label;
    const _translationsKeys = Object.keys(templateTranslations);
    for (let i = 0; i < _translationsKeys.length; i += 1) {
      if (
        !_isEmpty(templateTranslations[_translationsKeys[i]]?.template) &&
        templateTranslations[_translationsKeys[i]]?.template.length > 255
      ) {
        err = true;
        _langCode = findInObject(LANGUAGE_CODES, _translationsKeys[i]).label;
        break;
      }
    }
    if (_template.length > 255) {
      err = true;
    }
    if (err) {
      throwError(`Character count cannot exceed 255 in ${_langCode} version!`);
    }
  };

  const onSubmit = async fieldValues => {
    try {
      const _fieldValues = { ...fieldValues };
      setLoading(true);
      checkTemplateLength(_fieldValues.template);
      const reqBody = getReqBody(_fieldValues, templateItem);
      if (_isEmpty(templateItem)) {
        await dispatch(createTemplate(reqBody));
      } else {
        await dispatch(updateTemplate(templateItem.id, reqBody));
      }
      if (onSuccess) onSuccess();
      onCloseModal(false);
    } catch (ex) {
      toastMessage("error", ex);
      console.log(`ex`, ex);
    } finally {
      setLoading(false);
    }
  };

  const onPressMenuItem = key => {
    if (_isEmpty(templateTranslations[currentLanguage])) {
      setTemplateTranslations({
        ...templateTranslations,
        [currentLanguage]: {
          template: getTranslatedTemplate(
            currentLanguage,
            templateItem,
            form.getFieldValue("template"),
            templateTranslations,
          ),
        },
      });
    }

    setCurrentLanguage(key);
  };

  const updateFieldsList = val => {
    const _fields = [];
    _map(val, value => {
      _fields.push(Object.keys(TEMPLATE_FIELD_UI_MAPPING[value]));
    });
    setFieldList(
      _intersection(..._fields)
        .map(v => ({ label: TEMPLATE_FIELDS[v], value: v }))
        .sort((a, b) => a.label?.localeCompare(b.label)),
    );
  };

  const _onChangeTemplateType = val => {
    updateFieldsList(val);
    form.setFieldsValue({ field: undefined });
  };

  const resetValue = () => {
    onCloseModal(false);
  };
  const _onChange = eve => {
    setTemplateTranslations({
      ...templateTranslations,
      [currentLanguage]: {
        template: eve?.target?.value,
      },
    });
  };
  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className="template-editor"
      form={form}
      name="basic"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
      validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      onFinishFailed={onFinishFailed}
    >
      <p className="template-desc">{t("Template.Description")}</p>
      <Row className="fields-row" gutter={20}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={t("Table.Template.Name")} name="name" rules={[{ required: true, whitespace: true }]}>
            <Input placeholder={t("Table.Template.Name")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={t("Table.Template.Type")} name="type" rules={[{ required: true }]}>
            <Select onChange={_onChangeTemplateType} mode="multiple" placeholder={t("Table.Template.Type")}>
              {Object.keys(TEMPLATE_TYPE_UI_MAPPING).map(field => (
                <Option value={field} key={field}>
                  {TEMPLATE_TYPE_UI_MAPPING[field]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={t("Table.Template.Language")} name="language" rules={[{ required: true }]}>
            <Select onChange={onPressMenuItem} placeholder={t("Table.Template.Language")}>
              {sortAlphabetically(LANGUAGE_OPTIONS).map(_lang => (
                <Option key={_lang.value} value={_lang.value}>
                  {_lang.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={t("Table.Template.FieldLable")} name="field" rules={[{ required: true }]}>
            <Select placeholder={t("Table.Template.FieldLable")} options={sortAlphabetically(fieldList)} labelInValue />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="template"
        rules={[
          { required: true, message: t("Table.Template.Error") },
          {
            whitespace: true,
            message: t("Table.Template.WhitespaceMessage"),
          },
          {
            max: 255,
          },
        ]}
      >
        <TextArea
          onChange={_onChange}
          placeholder={t("Table.Template.Template")}
          autoSize={{ minRows: 5, maxRows: 5 }}
        />
      </Form.Item>
      <Row className="fields-row" gutter={20} type="flex" justify="center">
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block onClick={resetValue} className="cancelButton">
            {t("Buttons.Cancel")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block type="primary" htmlType="submit" className="saveButton" loading={loading}>
            {t("Buttons.Save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
TemplateEditor.propTypes = {
  templateItem: PropTypes.object,
  onCloseModal: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default TemplateEditor;
