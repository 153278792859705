import { Button, Col, Row, Tag, Dropdown, Menu } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import _map from "lodash/map";
import AppModal from "../../components/AppModal/AppModal";
import SearchListView from "../../components/SearchListView/SearchListView";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { getMarketByIdSuccess, getMarkets, exportMarkets } from "../../store/actions/market.actions";
import { DATE_FORMAT_TIME, PAGINATOIN, SORT_DIRECTION } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { removeUnderscore, replaceIfNull } from "../../utils/utils";
import AddEditMarket from "../AddEditMarket/AddEditMarket";
import AddEditSubMarket from "../AddEditSubMarket/AddEditSubMarket";
import AddEditRegion from "../AddEditRegion/AddEditRegion";
import "./Markets.scss";

const Markets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canCreateMarket = permissionsUtil.checkAuth("market_create");

  const { list, loading, pagination } = useSelector(store => store.market);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subMarketModal, setIsSubMarketModalOpen] = useState(false);
  const [regionModal, setIsRegionModalOpen] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [marketId, setMarketId] = useState(null);
  const [subMarketId, setSubMarketId] = useState(null);
  const [exportingMarkets, setExportingMarkets] = useState(false);

  const fetchMarkets = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getMarkets(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const toggleModal = async (modalState, id) => {
    setMarketId(id);
    setIsModalOpen(modalState);
  };

  const toggleSubMarketModal = async modalState => {
    setIsSubMarketModalOpen(modalState);
  };

  const toggleRegionModal = async modalState => {
    setIsRegionModalOpen(modalState);
  };

  const renderRegion = region => region?.name || "N/A";

  const renderSubMarketCodes = subMarkets => {
    if (subMarkets && subMarkets.length) {
      return _map(subMarkets, subMarket =>
        subMarket ? (
          <Tag key={subMarket.id} className="text-capitalize" color="processing">
            {subMarket.id}
          </Tag>
        ) : null,
      );
    }
    return t("Table.NA");
  };

  const renderSubMarketNames = subMarkets => {
    if (subMarkets && subMarkets.length) {
      return _map(subMarkets, subMarket =>
        subMarket ? (
          <Tag key={subMarket.id} className="text-capitalize" color="processing">
            {subMarket.subMarketName}
          </Tag>
        ) : null,
      );
    }
    return t("Table.NA");
  };

  const onPressMenuItem = ({ key }) => {
    if (key === "market") {
      toggleModal(true);
    } else if (key === "subMarket") {
      setIsSubMarketModalOpen(true);
    } else if (key === "region") {
      setIsRegionModalOpen(true);
    }
  };

  const exportAction = async () => {
    try {
      setExportingMarkets(true);
      await dispatch(exportMarkets());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingMarkets(false);
    }
  };

  const menu = (
    <Menu onClick={onPressMenuItem}>
      <Menu.Item key="region">{t("Property.Region")}</Menu.Item>
      <Menu.Item key="market">{t("Property.Market")}</Menu.Item>
      <Menu.Item key="subMarket">{t("Property.Submarket")}</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: t("Property.Region"),
      dataIndex: "region.name",
      key: "region",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "region.name" && sortInfo?.order,
      render: (text, record) => renderRegion(record.region),
    },
    {
      title: t("Property.Country"),
      dataIndex: "country",
      key: "country",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "country" && sortInfo?.order,
      render: country => replaceIfNull(removeUnderscore(country), t("Table.NA")),
      className: "text-capitalize",
    },
    {
      title: t("Table.MarketCode"),
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "id" && sortInfo?.order,
      render: text => replaceIfNull(text, t("Table.NA")),
    },
    {
      title: t("Table.MarketName"),
      dataIndex: "marketName",
      key: "marketName",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketName" && sortInfo?.order,
      render: text => replaceIfNull(text, t("Table.NA")),
    },
    {
      title: t("Table.SubmarketCode"),
      dataIndex: "subMarkets",
      key: "subMarkets",
      render: (markets, record) => renderSubMarketCodes(record.subMarkets),
      width: 300,
    },
    {
      title: t("Table.SubmarketName"),
      dataIndex: "subMarkets",
      key: "subMarkets",
      render: (markets, record) => renderSubMarketNames(record.subMarkets),
      width: 300,
    },
    {
      title: t("Table.Active"),
      dataIndex: "isActive",
      key: "isActive",
      // filters: ACTIVE_STATUS,
      // onFilter: (value, record) => record.isActive === value,
      // filteredValue: filterInfo?.isActive || null,
      render: text => (text ? t("Table.True") : t("Table.False")),
    },
    {
      title: t("Table.ModifiedOn"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
    {
      title: t("Table.ModifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: text => replaceIfNull(text, t("Table.NA")),
    },
  ];

  return (
    <div className="markets">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
          <Button block loading={exportingMarkets} onClick={exportAction}>
            {t("Table.Export")}
          </Button>
        </Col>
        {canCreateMarket && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Dropdown overlay={menu} className="add-button">
              <Button block type="primary">
                {t("Table.Add")}
                <CustomIcon name="DownOutlined" />
              </Button>
            </Dropdown>
          </Col>
        )}
      </Row>
      <SearchListView
        paginationKey={PAGINATOIN.markets}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        loading={loading}
        dataSource={list}
        columns={columns}
        getList={fetchMarkets}
        pagination={pagination}
        scroll={{ x: 1575, y: "calc(100vh - 560px)" }}
      />
      <AppModal
        title={`${marketId ? t("Markets.EditMarket") : t("Markets.CreateMarket")}`}
        width={600}
        visible={isModalOpen}
        onCancel={() => {
          toggleModal(false);
          dispatch(getMarketByIdSuccess({}));
        }}
        footer={null}
        destroyOnClose
      >
        <AddEditMarket marketId={marketId} onSuccess={fetchMarkets} onCloseModal={toggleModal} />
      </AppModal>
      <AppModal
        title={`${subMarketId ? t("Markets.EditSubmarket") : t("Markets.CreateSubmarket")}`}
        width={600}
        visible={subMarketModal}
        onCancel={() => {
          toggleSubMarketModal(false);
          // dispatch(subMarketSuccess({}));
          dispatch(getMarketByIdSuccess({}));
        }}
        footer={null}
        destroyOnClose
      >
        <AddEditSubMarket subMarketId={subMarketId} onSuccess={fetchMarkets} onCloseModal={toggleSubMarketModal} />
      </AppModal>
      <AppModal
        title={t("Table.CreateRegion")}
        width={600}
        visible={regionModal}
        onCancel={() => {
          toggleRegionModal(false);
          // dispatch(subMarketSuccess({}));
          dispatch(getMarketByIdSuccess({}));
        }}
        footer={null}
        destroyOnClose
      >
        <AddEditRegion onSuccess={fetchMarkets} onCloseModal={toggleRegionModal} />
      </AppModal>
    </div>
  );
};

export default Markets;
