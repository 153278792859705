import propertyRollUpService from "../../services/propertyRollUp.service";
import { ERROR_MESSAGE, PAGINATOIN, SUCCESS_MESSAGE } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  PROPERTYROLLUP_LOADING: "loading",
  PROPERTYROLLUP_SUGGESTION_LOADING: "suggestionLoading",
};
export function propertyRollUpRequest(key = LOADING.PROPERTYROLLUP_LOADING) {
  return { type: ACTIONS.PROPERTYROLLUP_REQUEST, key };
}

export function getAllPropertyRollUpSuccess(response) {
  return { type: ACTIONS.GET_ALL_PROPERTYROLLUP_SUCCESS, response };
}

export function propertyRollUpSuccess() {
  return { type: ACTIONS.PROPERTYROLLUP_SUCCESS };
}

export function propertyRollUpError(error, key = LOADING.PROPERTYROLLUP_LOADING) {
  return { type: ACTIONS.PROPERTYROLLUP_ERROR, error, key };
}

export function getPropertyRollUps(query) {
  return async dispatch => {
    dispatch(propertyRollUpRequest());
    try {
      const [err, response] = await to(propertyRollUpService.searchPropertyRollUp(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(getAllPropertyRollUpSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.propertyRollUp, pagination);
    } catch (error) {
      dispatch(propertyRollUpError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getPropertyRollUpsSuggestion(payload) {
  return async dispatch => {
    dispatch(propertyRollUpRequest(LOADING.PROPERTYROLLUP_SUGGESTION_LOADING));
    try {
      const [err, response] = await to(propertyRollUpService.suggestions(payload));
      if (err) throwError(err);
      dispatch(propertyRollUpSuccess());
      return response;
    } catch (error) {
      dispatch(propertyRollUpError(error, LOADING.PROPERTYROLLUP_SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function createPropertyRollUp(payload) {
  return async dispatch => {
    dispatch(propertyRollUpRequest());
    try {
      const [err, response] = await to(propertyRollUpService.createPropertyRollUp(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
    } catch (error) {
      dispatch(propertyRollUpError(error));
      toastMessage("error", ERROR_MESSAGE.ADDED);
      throwError(error);
    }
  };
}
