import PropTypes from "prop-types";
import React from "react";
import AuditIssuePopover from "../AuditIssuePopover/AuditIssuePopover";

const AuditIssueTag = ({
  form,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  unitIndex,
  canAuditProperty,
  viewOnlyMode,
}) => (
  <AuditIssuePopover
    viewOnlyMode={viewOnlyMode}
    form={form}
    tab={tab}
    fieldName={fieldName}
    auditPanelDrawerVisible={auditPanelDrawerVisible}
    unitIndex={unitIndex}
    canAuditProperty={canAuditProperty}
  />
);

AuditIssueTag.propTypes = {
  form: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
  unitIndex: PropTypes.number,
  canAuditProperty: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
};

export default AuditIssueTag;
