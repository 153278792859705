import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PropTypes, { string } from "prop-types";
import "./FilePreview.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const FilePreview = ({ file, imageUrl }) => {
  const [pages, setPages] = useState(1);
  const url = (file.dpm_url && file.dpm_url.trim()) || (file.original_url && file.original_url.trim()) || imageUrl;

  const onDocumentLoadSuccess = info => {
    const { numPages } = info;
    setPages(numPages);
  };

  let markup = null;

  if (file.asset_category_type === "document" && url) {
    markup = (
      <div className="file-preview__container">
        <Document
          className="file-preview__container__document"
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {Array.from(new Array(pages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    );
  } else if (url) {
    markup = <img src={url} alt="" className="file-preview" />;
  }
  return markup;
};

export default FilePreview;

FilePreview.propTypes = {
  file: PropTypes.object,
};

FilePreview.defaultProps = {
  file: {},
  imageUrl: string,
};
