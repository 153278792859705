import PropTypes from "prop-types";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { isPDFFileType } from "../../utils/utils";
import PDFIcon from "../PropertyMedia/PDFIcon";

const getListStyle = (isDraggingOver, isPlaceholder) =>
  // let background = isDraggingOver ? "lightblue" : "rgb(239 239 239)";
  // let border = 0;
  // if (!isDraggingOver && !isPlaceholder) {
  //   border = "2px solid #e4e4e4";
  //   background = "#fff";
  // }
  ({
    background: isDraggingOver ? "lightblue" : "initial",
    // overflow: "auto",
    // display: "flex",
    // flexWrap: "wrap",
    // border: "2px solid #e4e4e4",
    // height: "160px",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  });
const getItemStyle = (isDragging, draggableStyle, isPlaceholder) => {
  let background = isDragging ? "lightgreen" : "initial" || "rgb(239 239 239)";
  let border = "2px solid #e4e4e4";
  if (!isDragging && !isPlaceholder) {
    background = "initial";
    border = 0;
  }

  // if (!isDragging && !hasItems)

  return {
    background,
    border,
    // height: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "text",
    // background: "white",
    height: "100%",
    width: "100%",
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const UnitCollateralDroppable = ({ className, list, type, isDropDisabled, previewFile, placeholderText }) => {
  const [textSelectable, setTextSelectable] = useState(false);

  return (
    <div className={`property-unit-media__container ${className}`}>
      <Droppable droppableId={type} direction="vertical" isDropDisabled={isDropDisabled}>
        {(provided1, snapshot1) => {
          if (!list.length) {
            return (
              <div
                ref={provided1.innerRef}
                className="property-unit-media__item"
                style={{
                  ...getItemStyle(snapshot1.isDraggingOver, {}, true),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <div className="text-center">{placeholderText}</div>
              </div>
            );
          }
          return (
            <div ref={provided1.innerRef} style={getListStyle(snapshot1.isDraggingOver, false)}>
              {list.length ? (
                list.map((item, index) => {
                  const _url = item.thumbnails_webimage_url;
                  const _alt = item.asset_name;

                  // if (isPDFFileType(item)) {
                  //   _url = getThumbnailByType(_url);
                  //   _alt = "PDF";
                  // }
                  const isPDF = isPDFFileType(item);
                  if (snapshot1.isDraggingOver && snapshot1.draggingFromThisWith !== snapshot1.draggingOverWith) {
                    return null;
                  }

                  return (
                    <Draggable
                      key={item.asset_id}
                      draggableId={item.asset_id}
                      index={index}
                      isDragDisabled={textSelectable}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="property-unit-media__item"
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, false)}
                        >
                          <div className="property-unit-media__wrapper" onDoubleClick={() => previewFile(item, isPDF)}>
                            {/* <div style={{ position: "relative", opacity: 0.2 }}> */}
                            <img
                              className={`item-image ${isPDF ? "pdf" : ""}`}
                              // className="item-image"
                              src={_url}
                              alt={_alt}
                              // style={{ width: isPDF ? 60 : "100%", height: isPDF ? 80 : "100%", objectFit: "contain" }}
                            />
                            {/* </div> */}
                            <PDFIcon item={item} isPDF size={35} className="pdf" />
                            <div
                              className="id-container text-center"
                              onMouseEnter={() => setTextSelectable(true)}
                              onMouseLeave={() => setTextSelectable(false)}
                              // style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                            >
                              {item.asset_id}
                              {' '}
                              {/* Removed <span> */}
                            </div>
                            {/* <div className="text-center">{item.name}</div> */}
                          </div>
                          {/* <PDFIcon item={item} isPDF={isPDF} className="external-link" /> */}
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <div style={getItemStyle(false, {}, true)}>
                  <div>
                    <div className="text-center">{placeholderText}</div>
                  </div>
                </div>
              )}
              {provided1.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

UnitCollateralDroppable.propTypes = {
  className: PropTypes.string,
  previewFile: PropTypes.func,
  placeholderText: PropTypes.string,
  list: PropTypes.array,
  isDropDisabled: PropTypes.bool,
  type: PropTypes.oneOf([
    "hero",
    "aerial",
    "gallery",
    "sitePlan",
    "propertyMarketingCollateral",
    "unitMarketingCollateral",
  ]),
};

export default UnitCollateralDroppable;
