import get from "lodash/get";
import isString from "lodash/isString";
import { adminPermissions, editorPermissions, viewerPermissions, auditorPermissions } from "../data/permissions";
import { USER_ROLES } from "./constants";

const permissions = {
  [USER_ROLES.ADMIN]: adminPermissions,
  [USER_ROLES.EDITOR]: editorPermissions,
  [USER_ROLES.VIEWER]: viewerPermissions,
  [USER_ROLES.AUDITOR]: auditorPermissions,
};

/**
 * Usage Example:
 *
 * import permissionsUtil from "../../utils/permissions.util";
 *
 * const canAddUser = permissionsUtil.checkAuth({
 *   category: "property",
 *   action: "add",
 * });
 */

class PermissionsUtil {
  static instance;

  constructor() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = this;
  }

  setPermissions(roles) {
    const highPriorityRole = this.getHighPriorityRole(roles);
    this.permissions = permissions[highPriorityRole];
  }

  clearPermissions() {
    this.permissions = {};
  }

  getHighPriorityRole(roles = []) {
    const rolePriority = [USER_ROLES.ADMIN, USER_ROLES.AUDITOR, USER_ROLES.EDITOR, USER_ROLES.VIEWER];

    const prioritizedRoles = [...roles].sort((a, b) => {
      const aKey = rolePriority.indexOf(a);
      const bKey = rolePriority.indexOf(b);
      return aKey - bKey;
    });

    return prioritizedRoles[0];
  }

  getPermissionString(permission) {
    if (isString(permission)) return permission;

    let str = "";
    const { action, category } = permission;

    if (category) str += `${category}_`;
    if (action) str += `${action}`;
    return str;
  }

  // hasAccess = ({ action, category, permissionObj = {} }) => {
  //   if (_isArray(action)) {
  //     for (let i = 0; i < action.length; i += 1) {
  //       if (get(permissionObj, `${category}.${action[i]}`, false)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   }
  //   return get(permissionObj, `${category}.${action}`, false);
  // };

  checkAuth(actionStr) {
    const str = this.getPermissionString(actionStr);
    const _permissions = get(this.permissions, str);
    // console.log("checkAuth for : ", str, _permissions);

    return _permissions === "YES";
    // return get(_permissions, this.roleIndex) === "YES";
  }
}

const permissionsUtil = new PermissionsUtil();
export default permissionsUtil;
