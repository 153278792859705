import { get, put, post, remove } from "./http.service";

const HelperTextService = {
  get: () => get("helperText"),
  update: (id, body) => put(`helperText/${id}`, body),
  create: body => post(`helperText`, body),
  remove: id => remove(`helperText/${id}`),
  export: () => get(`helperText/export`, { responseType: "base64" }),
};

export default HelperTextService;
