import auditService from "../../services/audit.service";
import { ERROR_MESSAGE, PAGINATOIN, TAG } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  AUDIT_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function auditRequest(key = LOADING.AUDIT_LOADING) {
  return { type: ACTIONS.AUDIT_REQUEST, key };
}
export function getAllAuditsSuccess(response) {
  return { type: ACTIONS.GET_ALL_AUDITS_SUCCESS, response };
}

export function auditError(error, key = LOADING.AUDIT_LOADING) {
  return { type: ACTIONS.AUDIT_ERROR, error, key };
}

export function auditTagsSuccess(response) {
  return { type: ACTIONS.AUDIT_TAGS_SUCCESS, response };
}

export function openAuditsTagSuccess(response) {
  return { type: ACTIONS.OPEN_AUDIT_TAG_SUCCESS, response };
}

export function openAuditsListSuccess(response) {
  return { type: ACTIONS.OPEN_AUDIT_LIST_SUCCESS, response };
}

export function auditSuggestionSuccess(response) {
  return { type: ACTIONS.AUDIT_SUGGESTION_SUCCESS, response };
}

export function getAudits(query, isFromDashboard = false) {
  return async dispatch => {
    dispatch(auditRequest(LOADING.AUDIT_LOADING));
    try {
      const _tags = [...(query?.filterCriteria?.market || [])];
      let err, response;
      if (isFromDashboard) {
        [err, response] = await to(auditService.openAudits(query));
      } else {
        [err, response] = await to(auditService.searchAudits(query));
      }
      if (err || !response) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      if (!isFromDashboard) {
        setSessionStorage(TAG.audits, []);
        dispatch(getAllAuditsSuccess({ list: response.content, pagination }));
        setSessionStorage(PAGINATOIN.audits, pagination);
        dispatch(auditTagsSuccess(_tags));
        setSessionStorage(TAG.audits, _tags);
      } else {
        setSessionStorage(TAG.openAudits, []);
        dispatch(openAuditsListSuccess({ list: response.content, pagination }));
        setSessionStorage(PAGINATOIN.openAuditPagination, pagination);
        dispatch(openAuditsTagSuccess(_tags));
        setSessionStorage(TAG.openAudits, _tags);
      }
    } catch (error) {
      dispatch(auditError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getAuditsSuggestion(payload) {
  return async dispatch => {
    dispatch(auditRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(auditService.suggestions(payload));
      if (err) throwError(err);
      dispatch(auditSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(auditError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}
