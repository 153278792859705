import { Button, Col, Row, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import ListView from "../../components/ListView/ListView";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useKeyword } from "../../hooks";
import { getMarketUsers } from "../../store/actions/building.actions";
import {
  AUDIT_STATUS,
  BUILDING_TABS_LOOKUP as _tabs,
  DATE_FORMAT_TIME,
  FORM_FIELDS_LOOKUP,
  BUILDING_TABS_KEYS as _tabsKey,
  AUDIT_STATUS_UI_MAPPING,
  LAND_TABS_LOOKUP as _landTabs,
  PROPERTY_TYPE_KEYS,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { renderAuditStatus } from "../../utils/property.util";
import { getFieldLabel } from "../../utils/utils";
import "./AuditIssues.scss";

const AuditIssues = ({ auditCountByStatus, navigateToIssueTab, mainForm, propertyType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, pagination } = useSelector(state => state.building);
  const { auditList, usersLoading } = useSelector(state => state.property);

  const { keyword, setKeyword } = useKeyword({ pagination });
  const [selectedUserName, setSelectedUserName] = useState();
  const [selectedAuditStatuses, setSelectedAuditStatuses] = useState([]);

  const canViewAudit = permissionsUtil.checkAuth("audit_view");
  const canAuditProperty = permissionsUtil.checkAuth("audit_add");

  useEffect(() => {
    auditCountByStatus(
      _filter(
        auditList,
        audit =>
          (canViewAudit && !canAuditProperty && audit.status === AUDIT_STATUS.audit_issue) ||
          (canAuditProperty && audit.status === AUDIT_STATUS.ready_for_review),
      ).length,
    );
  }, [auditList]);

  const auditStatusChanged = _statuses => {
    setSelectedAuditStatuses([..._statuses]);
  };

  const columns = [
    {
      title: t("AuditIssuesTable.AuditId"),
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 150,
      render: (text, record) => {
        const _propertyType =
          record.propertyType === PROPERTY_TYPE_KEYS.land
            ? _landTabs[record?.field?.tab].key
            : _tabs[record?.field?.tab].key;
        const onItemClick = () => navigateToIssueTab(_propertyType, record?.language, record?.unitCode, true);
        return (
          <Button type="link" onClick={onItemClick}>
            {text}
          </Button>
        );
      },
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: t("AuditIssuesTable.IssueAreaAndField"),
      dataIndex: "field",
      key: "field",
      ellipsis: true,
      width: 180,
      sorter: (a, b) => a.issue.localeCompare(b.issue),
      render: (field = {}, record) => {
        const fieldLabel = getFieldLabel(record?.propertyType, field, FORM_FIELDS_LOOKUP, _tabsKey);
        const tabLabel =
          record.propertyType === PROPERTY_TYPE_KEYS.land ? _landTabs[field.tab]?.tabTitle : _tabs[field.tab]?.tabTitle;
        const propertyTabTitle = `${tabLabel}: ${fieldLabel}`;

        return fieldLabel ? (
          <Tooltip className="text-capitalize" placement="top" title={propertyTabTitle}>
            {propertyTabTitle}
          </Tooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: t("AuditIssuesTable.Language"),
      dataIndex: "language",
      key: "language",
      width: 150,
      sorter: (a, b) => a.language.localeCompare(b.language),
      render: text => (
        <Tooltip className="text-uppercase" placement="top" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t("AuditIssuesTable.Status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: text => <>{renderAuditStatus(text, true)}</>,
    },
    {
      title: t("AuditIssuesTable.LastEditedBy"),
      dataIndex: "lastModifiedBy",
      key: "lastModifiedBy",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => a.lastModifiedBy?.firstName?.localeCompare(b?.lastModifiedBy?.firstName),
      render: text =>
        text ? (
          <Tooltip className="text-capitalize" placement="top" title={`${text?.firstName} ${text?.lastName}`}>
            {`${text.firstName} ${text.lastName}`}
          </Tooltip>
        ) : (
          t("Table.NA")
        ),
    },
    {
      title: t("AuditIssuesTable.AssigneeAuditor"),
      dataIndex: "auditor",
      key: "auditor",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => a.auditor.firstName.localeCompare(b.auditor.firstName),
      render: text => (
        <Tooltip className="text-capitalize" placement="top" title={`${text?.firstName} ${text?.lastName}`}>
          {`${text.firstName} ${text.lastName}`}
        </Tooltip>
      ),
    },
    {
      title: t("AuditIssuesTable.CreatedOn"),
      dataIndex: "createdOn",
      key: "createdOn",
      width: 190,
      sorter: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
    },
    {
      title: t("AuditIssuesTable.AuditStatusLastUpdatedOn"),
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      width: 210,
      sorter: (a, b) => new Date(a.lastModifiedDate) - new Date(b.lastModifiedDate),
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
    },
  ];

  const getOptions = val => {
    const formType = propertyType === PROPERTY_TYPE_KEYS.building ? "buildingInfo" : "landInfo";
    const propertyInfo = mainForm.getFieldValue(formType);
    const filterCriteria = { markets: [propertyInfo?.marketId] };
    const query = { filterCriteria, keyword: val };
    return dispatch(getMarketUsers(query));
  };

  const _onSubmit = (flag = true, option) => {
    setSelectedUserName(option?.value);
  };

  const _onEnter = () => {
    if (keyword.length) setSelectedUserName(keyword);
  };

  const _onClickReset = () => {
    setKeyword([]);
    setSelectedUserName(undefined);
    setSelectedAuditStatuses([]);
  };

  const filteredFields = _filter(auditList, audit => {
    const modifierName = `${audit?.lastModifiedBy?.firstName} ${audit?.lastModifiedBy?.lastName}`.toLowerCase();
    return (
      (_isEmpty(selectedUserName) || modifierName.includes(selectedUserName.toLowerCase())) &&
      (_isEmpty(selectedAuditStatuses) || selectedAuditStatuses.includes(audit.status))
    );
  });

  const locale = {
    emptyText: (
      <div className="pad-80">
        <CustomIcon name="fileIcon" />
        <h2>{t("AuditIssues.NoAuditIssuesPosted")}</h2>
        <p>{t("AuditIssues.AllAuditIssuesAndUpdatesCanBeAccessedHere")}</p>
      </div>
    ),
  };

  return (
    <div className="audit-issues">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row gutter={10}>
            <Col xs={24} lg={8} xl={4} xxl={5}>
              <SearchInput
                value={keyword}
                setValue={setKeyword}
                onSelectValue={_onSubmit}
                loading={usersLoading}
                getOptions={getOptions}
                onEnter={_onEnter}
                placeholder={t("AuditIssues.EditedBy")}
                renderOptions={value => (
                  <Select.Option key={value?.email} value={`${value?.firstName} ${value?.lastName}`}>
                    {`${value?.firstName} ${value?.lastName}`}
                  </Select.Option>
                )}
              />
            </Col>
            <Col xs={24} lg={8} xl={4} xxl={3}>
              <Select
                mode="multiple"
                listHeight={300}
                showArrow
                showSearch={false}
                maxTagCount={1}
                maxTagTextLength={3}
                placeholder={t("AuditReportTable.Status")}
                onChange={auditStatusChanged}
                value={selectedAuditStatuses}
                className="audit-statuses-select"
              >
                {_map(Object.keys(AUDIT_STATUS_UI_MAPPING), auditStatus => (
                  <Select.Option key={auditStatus} value={auditStatus} className="filter-option-item">
                    {AUDIT_STATUS_UI_MAPPING[auditStatus]}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} lg={8} xl={4} xxl={2}>
              <Button block className="reset-filters-btn" onClick={_onClickReset}>
                {t("AddEditProperty.Audit.Reset")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListView
            locale={locale}
            rowKey="id"
            dataSource={filteredFields}
            columns={columns}
            scroll={{ x: 1430, y: 350 }}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

AuditIssues.propTypes = {
  auditCountByStatus: PropTypes.func,
  navigateToIssueTab: PropTypes.func,
  mainForm: PropTypes.object,
  propertyType: PropTypes.string,
};

export default AuditIssues;
