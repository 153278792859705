import { Form, Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { sortAlphabetically } from "../../utils/utils";
import CustomTooltipHelper from "../CustomTooltipHelper/CustomTooltipHelper";
import "./LabelWithDropDown.scss";

const LabelWithDropDown = ({
  label,
  tooltip,
  options,
  templateId,
  changeHandler,
  name,
  viewOnlyMode,
  form,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  unitIndex,
}) => {
  const { t } = useTranslation();

  const _onClick = e => {
    e.preventDefault();
  };

  return (
    <div className="label-with-drop-down">
      <span>
        <CustomTooltipHelper
          viewOnlyMode={viewOnlyMode}
          label={label}
          tooltip={tooltip}
          form={form}
          tab={tab}
          fieldName={fieldName}
          auditPanelDrawerVisible={auditPanelDrawerVisible}
          unitIndex={unitIndex}
        />
      </span>
      <Form.Item name={name}>
        <Select
          allowClear
          disabled={viewOnlyMode}
          placeholder={t("Template.Template")}
          onChange={changeHandler}
          value={templateId}
          options={sortAlphabetically(options).map(o => ({ value: o.value, label: o.label }))}
          onClick={_onClick}
        />
      </Form.Item>
    </div>
  );
};

LabelWithDropDown.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.object,
  name: PropTypes.array,
  options: PropTypes.array,
  templateId: PropTypes.string,
  changeHandler: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  form: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
  unitIndex: PropTypes.number,
};

export default LabelWithDropDown;
