import { Button, Col, Row, Tag, Tooltip } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import CustomTableFilterList from "../../components/CustomTableFilterList/CustomTableFilterList";
import ListView from "../../components/ListView/ListView";
import PopUp from "../../components/PopUp/PopUp";
import TemplateEditor from "../../components/TemplateEditor/TemplateEditor";
import { deleteTemplate, exportTemplates, getAllTemplates } from "../../store/actions/template.actions";
import { LANGUAGE_CODES, TEMPLATE_TYPE_UI_MAPPING } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import "./Template.scss";

const LANGUAGE_OPTIONS = _sortBy(
  Object.keys(LANGUAGE_CODES).map(_lang => ({
    label: LANGUAGE_CODES[_lang].label,
    value: LANGUAGE_CODES[_lang].value,
  })),
  l => l.label,
);

const Template = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const canEditTemplate = permissionsUtil.checkAuth("template_edit");
  const canExportTemplate = permissionsUtil.checkAuth("template_export");
  const canCreateTemplate = permissionsUtil.checkAuth("template_create");

  const { list, loading } = useSelector(state => state.template);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [exportingTemplates, setExportingTemplates] = useState(false);
  const [selectedLang, setSelectedLang] = useState(LANGUAGE_CODES.English);
  const [popupVisible, togglePopupVisibility] = useState(false);

  const fetchTemplates = async () => {
    try {
      await dispatch(getAllTemplates());
    } catch (ex) {
      console.log(ex);
    }
  };

  const _onClose = () => {
    toggleModal(false);
    setSelectedField({});
  };

  const _onSuccess = () => {
    setSelectedField({});
    fetchTemplates();
  };

  const toggleModal = modalState => {
    setIsModalOpened(modalState);
  };

  const deleteRecord = async id => {
    try {
      await dispatch(deleteTemplate(id));
    } catch (ex) {
      console.log(`ex`, ex);
    } finally {
      fetchTemplates();
      togglePopupVisibility(false);
    }
  };

  const exportAction = async () => {
    try {
      setExportingTemplates(true);
      await dispatch(exportTemplates());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingTemplates(false);
    }
  };

  const getModalTitle = () => {
    let title = "";
    if (!_isEmpty(selectedField)) {
      const { type, name } = selectedField;
      title = name;

      if (type) {
        const _types = _map(type, _type => TEMPLATE_TYPE_UI_MAPPING[_type]).join(", ");
        title = `${_types}: ${title}`;
      }
    } else {
      title = "Create New Template";
    }

    return title;
  };

  const renderTypes = types => {
    if (types && types.length) {
      return _map(types, _type =>
        _type ? (
          <Tag key={_type} className="text-capitalize" color="processing">
            {TEMPLATE_TYPE_UI_MAPPING[_type]}
          </Tag>
        ) : null,
      );
    }
    return t("Table.NA");
  };

  const columns = [
    {
      title: t("Table.Template.Name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      columnFilter: true,
    },
    {
      title: t("Table.Template.Type"),
      dataIndex: "type",
      key: "type",
      render: text => renderTypes(text),
      columnFilter: true,
    },
    {
      title: "Field Label",
      dataIndex: ["field", "label"],
      key: "field.label",
      sorter: (a, b) => a.field?.label?.localeCompare(b.field?.label),
      columnFilter: true,
    },
    {
      title: t("Table.Template.EnglishDescription"),
      dataIndex: "templateDiv",
      key: "templateDiv",
    },
    {
      title: t("Table.Template.Translation"),
      dataIndex: "translatedDiv",
      key: "translatedDiv",
      filterDropdown: options => (
        <CustomTableFilterList
          {...options}
          dataSource={LANGUAGE_OPTIONS}
          language={selectedLang}
          setValue={setSelectedLang}
        />
      ),
      filterIcon: () => (
        <>
          <span className={`language-filter-placeholder ${_isEmpty(selectedLang) ? "placeholder" : ""}`}>
            {selectedLang?.label || "Code"}
            &nbsp;
          </span>
          <CustomIcon name="DownOutlined" className="custom-table-filter-icon" />
        </>
      ),
      onFilter: true,
    },
  ];

  if (canEditTemplate) {
    columns.push({
      title: t("Table.Action"),
      key: "action",
      align: "center",
      fixed: "right",
      width: 150,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Buttons.Edit")}>
            <div
              className="action-buttons-item"
              role="button"
              tabIndex={0}
              onKeyDown={ev => {
                // if (ev.keyCode === 13) toggleModal(true, record.id);
              }}
              onClick={() => {
                setSelectedField(record);
                toggleModal(true);
              }}
            >
              <CustomIcon name="EditOutlined" style={{ fontSize: "20px", color: "#5C5E5D" }} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Edit")}</p>
            </div>
          </Tooltip>
          <Tooltip placement="bottom" title={t("Buttons.Remove")}>
            <PopUp
              title={t("Constants.ConfirmMessages.DeleteTemplate")}
              onConfirm={() => deleteRecord(record.id)}
              okText={t("Buttons.Yes")}
              cancelText={t("Buttons.No")}
              className="action-buttons-item"
              placement="topRight"
              popupVisible={popupVisible}
              onVisibleChange={() => togglePopupVisibility(!popupVisible)}
            >
              <CustomIcon name="deleteIcon" size={22} />
              <p style={{ color: "#5C5E5D" }}>{t("Buttons.Remove")}</p>
            </PopUp>
          </Tooltip>
        </div>
      ),
    });
  }

  const getTemplates = () =>
    list.map(item => ({
      ...item,
      templateDiv: item.template,
      translatedDiv: _get(item, `translations.${selectedLang?.value}.template`, ""),
    }));

  return (
    <div className="template">
      <Row className="table-action-buttons row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canExportTemplate && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block onClick={exportAction} loading={exportingTemplates}>
              {t("Table.Export")}
            </Button>
          </Col>
        )}
        {canCreateTemplate && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block type="primary" onClick={() => toggleModal(true)}>
              {t("Table.Template.Create")}
            </Button>
          </Col>
        )}
      </Row>
      <ListView
        rowKey="id"
        dataSource={getTemplates()}
        getList={fetchTemplates}
        columns={columns}
        scroll={{ y: "calc(100vh - 500px)" }}
        loading={loading}
      />
      <AppModal
        title={getModalTitle()}
        width={600}
        visible={isModalOpened}
        onCancel={_onClose}
        footer={null}
        destroyOnClose
      >
        <TemplateEditor templateItem={selectedField} onSuccess={_onSuccess} onCloseModal={toggleModal} />
      </AppModal>
    </div>
  );
};

export default Template;
