import DowntimeReport from "../../services/downtimeReport.service";
import ACTIONS from "./types.actions";
import { throwError, to, toastMessage, setSessionStorage } from "../../utils/utils";
import { ERROR_MESSAGE, PAGINATOIN } from "../../utils/constants";

export const LOADING = {
  DOWNTIME_REPORT_LOADING: "loading",
};

export function getDowntimeReportSuccess(response) {
  return { type: ACTIONS.DOWNTIME_REPORT_SUCCESS, response };
}

export function downntimeReportRequest(key = LOADING.DOWNTIME_REPORT_LOADING) {
  return { type: ACTIONS.DOWNTIME_REPORT_REQUEST, key };
}

export function downtimeReportError(error, key = LOADING.DOWNTIME_REPORT_LOADING) {
  return { type: ACTIONS.DOWNTIME_REPORT_ERROR, error, key };
}

export function getDowntimeReport(query) {
  return async dispatch => {
    dispatch(downntimeReportRequest());
    try {
      const [err, response] = await to(DowntimeReport.searchDowntimeReport(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(getDowntimeReportSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.downtimeReport, pagination);
    } catch (error) {
      dispatch(downtimeReportError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}
