import { Button, Divider, Tooltip } from "antd";
import React from "react";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomIcon from "../CustomIcon/CustomIcon";
import { getDangerouslySetHTML, getTranslatedHelperText } from "../../utils/utils";
import { useLanguage } from "../../hooks";

import "./HomeTotalAndPercentageBox.scss";

const HomeTotalAndPercentageBox = ({
  title,
  total,
  percent,
  actionText,
  secActionText,
  type,
  redirectToPublish,
  redirectToNotMarket,
  tooltip,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <div className="details-box">
      <h3 className="text-center font-18">
        {title}
        {!_isEmpty(tooltip) && !_isEmpty(tooltip.helperText) && (
          <span className="font-14 font-w-400 mg-left-6">
            <Tooltip title={getDangerouslySetHTML(getTranslatedHelperText(tooltip, language))}>
              <CustomIcon name="InfoCircleOutlined" />
            </Tooltip>
          </span>
        )}
      </h3>
      <div className="wrapper">
        <div className="w-pc-58 text-center">
          <div className="count-box">
            <h4 className="mg-bottom-0">{t("Home.Total")}</h4>
            <span className="font-bold font-26">{total || 0}</span>
          </div>
          {percent && (
            <div className="count-box">
              <h4 className="mg-bottom-0">{t("Home.Percent")}</h4>
              <span className="font-bold font-26">
                {parseFloat(percent).toFixed(0)}
%
              </span>
            </div>
          )}
        </div>
        <div>
          <Divider type="vertical" />
        </div>
        <div className="buttons">
          <Button type="link" onClick={() => redirectToPublish(type)}>
            {actionText}
            <CustomIcon name="arrowRightLong" />
          </Button>
          {secActionText && (
            <Button type="link" onClick={() => redirectToNotMarket(type)}>
              {secActionText}
              <CustomIcon name="arrowRightLong" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

HomeTotalAndPercentageBox.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  percent: PropTypes.string,
  actionText: PropTypes.string,
  secActionText: PropTypes.string,
  type: PropTypes.string,
  redirectToPublish: PropTypes.func,
  redirectToNotMarket: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default HomeTotalAndPercentageBox;
