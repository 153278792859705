import { Switch, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../components/ListView/ListView";
import { getAllFeatureFlags, toggleFeatureFlags } from "../../store/actions/featureFlag.actions";
import permissionsUtil from "../../utils/permissions.util";
import "./FeatureFlag.scss";

const FeatureFlag = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list, loading } = useSelector(store => store.featureFlag);
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);

  const canEditFeatureFlag = permissionsUtil.checkAuth("feature_flag_edit");

  const fetchFeatureFlag = async () => {
    try {
      await dispatch(getAllFeatureFlags());
    } catch (ex) {
      console.log("feature flag getAll error", ex);
    }
  };

  const toggleFeatureFlag = async record => {
    try {
      record = { ...record, status: !record.status };
      await dispatch(toggleFeatureFlags(record));
      await fetchFeatureFlag();
    } catch (ex) {
      console.log("feature flag update error:", ex);
    }
  };

  const columns = [
    {
      title: t("Table.FeatureFlag.FieldLabel"),
      dataIndex: "id",
      key: "id",
      width: 300,
      sorter: (a, b) => b.id.localeCompare(a.id),
      columnFilter: true,
      defaultSortOrder: "asc",
    },
    {
      title: t("Table.FeatureFlag.Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Table.Action"),
      key: "action",
      width: 300,
      render: (text, record) => (
        <div className="action-buttons">
          <Tooltip placement="bottom" title={t("Buttons.Toggle")}>
            <Switch disabled={!canEditFeatureFlag} onChange={() => toggleFeatureFlag(record)} checked={record.status} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="feature-flag-container">
      <ListView
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        dataSource={list}
        getList={fetchFeatureFlag}
        columns={columns}
        loading={loading}
        scroll={{ y: "calc(100vh - 500px)" }}
      />
    </div>
  );
};

export default FeatureFlag;
