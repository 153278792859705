import { Modal } from "antd";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import Draggable from "react-draggable";
import "./AppModal.scss";

const AppModal = props => {
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [disabled, setDisabled] = useState(true);
  const draggableRef = useRef(null);

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const bodyStyle = {
    maxHeight: props.maxHeight || "auto",
    minHeight: props.minHeight || "auto",
    overflowY: props.overFlowY || "none",
  };

  const renderModal = modal => (
    <Draggable bounds={bounds} disabled={disabled} onStart={onStart}>
      <div ref={draggableRef}>{modal}</div>
    </Draggable>
  );

  return (
    <Modal
      {...props}
      title={
        <div
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {props.title}
        </div>
      }
      bodyStyle={bodyStyle}
      modalRender={props.isNotDraggable ? null : renderModal}
    >
      {props.children}
    </Modal>
  );
};

AppModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.any,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  centered: PropTypes.bool,
  footer: PropTypes.node,
  afterClose: PropTypes.func,
  children: PropTypes.any,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  overFlowY: PropTypes.string,
  maskClosable: PropTypes.bool,
  isNotDraggable: PropTypes.bool,
};

AppModal.defaultProps = {
  maskClosable: false,
};

export default AppModal;
