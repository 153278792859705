import { Avatar, Button, Col, Divider, Typography } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getTimeAgo, renderAvatarName } from "../../utils/utils";

const { Title, Text, Paragraph, Link } = Typography;

const AvatarWithComment = ({ item, onVisibleTextArea, hideButton, hideDivider, visibleTextArea, viewOnlyMode }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [uniqueKey, setUniqueKey] = useState(0);

  const username = `${item.user.firstName} ${item.user.lastName}`;

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
    setUniqueKey(isExpanded ? uniqueKey + 1 : uniqueKey + 0);
  };

  const ellipsis = {
    rows: 2,
    expandable: true,
    symbol: t("Buttons.SeeMore"),
    onExpand: () => toggleIsExpanded(),
  };

  return (
    <>
      <Col sm={4}>
        <Avatar>{renderAvatarName(username)}</Avatar>
      </Col>
      <Col sm={13}>
        <Title ellipsis level={5} title={username}>
          {username}
        </Title>
        <Text className="block font-12 font-grey mg-bottom-5">{item.user.role}</Text>
      </Col>
      <Col sm={7} className="text-end">
        <Text className="font-w-500 font-grey relative-time">{getTimeAgo(item.commentedAt)}</Text>
      </Col>
      <Col sm={24} key={uniqueKey}>
        <Paragraph ellipsis={ellipsis} className="audit-comment">
          {item.comment}
          &nbsp;
          {isExpanded && <Link onClick={toggleIsExpanded}>{t("Buttons.SeeLess")}</Link>}
        </Paragraph>
      </Col>
      {hideDivider && <Divider />}
      {!hideButton && !visibleTextArea && (
        <Col sm={24} className="text-end">
          {!viewOnlyMode && (
            <Button type="link" onClick={onVisibleTextArea}>
              {t("AddEditProperty.Audit.ReplyLink")}
            </Button>
          )}
        </Col>
      )}
    </>
  );
};

AvatarWithComment.propTypes = {
  item: PropTypes.object,
  onVisibleTextArea: PropTypes.func,
  hideButton: PropTypes.bool,
  hideDivider: PropTypes.bool,
  visibleTextArea: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
};

export default AvatarWithComment;
