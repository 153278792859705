import UnmarketReasonsService from "../../services/unmarketReasons.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { downloadFile, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  UNMARKET_REASONS_LOADING: "loading",
};

export function unmarketReasonsRequest(key = LOADING.UNMARKET_REASONS_LOADING) {
  return { type: ACTIONS.UNMARKET_REASONS_REQUEST, key };
}

export function unmarketReasonsSuccess(response) {
  return { type: ACTIONS.UNMARKET_REASONS_SUCCESS, response };
}

export function unmarketReasonsListSuccess(response) {
  return { type: ACTIONS.UNMARKET_REASONS_LIST_SUCCESS, response };
}

export function unmarketPropertiesListSuccess(response) {
  return { type: ACTIONS.UNMARKET_PROPERTIES_LIST_SUCCESS, response };
}

export function unmarketReasonsError(error, key = LOADING.UNMARKET_REASONS_ERROR) {
  return { type: ACTIONS.UNMARKET_REASONS_ERROR, error, key };
}

export function getAllUnmarketReasons() {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.get());
      if (err) throwError(err);
      dispatch(
        unmarketReasonsListSuccess({
          list: response,
        }),
      );
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function createUnmarketReasons(payload) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(unmarketReasonsSuccess({}));
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function updateUnmarketReasons(id, payload) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(unmarketReasonsSuccess({}));
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function deleteAndUpdateUnmarketReasons(id, payload) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.remove(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
      dispatch(unmarketReasonsSuccess({}));
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      throwError(error);
    }
  };
}

export function updateUnmarketReasonsOrder(payload) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.updateOrder(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(unmarketReasonsSuccess({}));
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function getAllUnmarketPropertiesList(id) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.getAllUnmarketProperties(id));
      if (err) throwError(err);
      dispatch(
        unmarketPropertiesListSuccess({
          unmarketPropertiesList: response,
        }),
      );
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function exportUnmarketProperties(id) {
  return async dispatch => {
    dispatch(unmarketReasonsRequest());
    try {
      const [err, response] = await to(UnmarketReasonsService.export(id));
      if (err) throwError(err);
      downloadFile(response, "unmarketProperties.xlsx");
      dispatch(unmarketReasonsSuccess({}));
    } catch (error) {
      dispatch(unmarketReasonsError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}
