import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import _find from "lodash/find";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import CustomFormItem from "../../components/CustomFormItem/CustomFormItem";
import CustomSelect from "../../components/CustomSelect";
import CustomTooltipHelper from "../../components/CustomTooltipHelper/CustomTooltipHelper";
import LabelWithCheckbox from "../../components/LabelWithCheckbox/LabelWithCheckbox";
import LabelWithDropDown from "../../components/LabelWithDropDown/LabelWithDropDown";
import LabelWithSwitch from "../../components/LabelWithSwitch/LabelWithSwitch";
import { useLanguage } from "../../hooks";
import { buildingUOMSuccess } from "../../store/actions/building.actions";
import { getPropertyRollUps } from "../../store/actions/property.actions";
import {
  BUILDING_TABS_KEYS,
  BUILDING_TYPE,
  COUNTRY_NAME,
  DATE_FORMAT,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  HELPER_TEXT_KEYS,
  LANGUAGE_CODES,
  MEASUREMENT_UNITS,
  QUERY_PARAMS,
  SEARCH_QUERY,
  BUILDING_REQUIRED_FIELDS_LOOKUP,
} from "../../utils/constants";
import { isUSProperty } from "../../utils/property.util";
import {
  getFeatureFlags,
  getTooltip,
  sortAlphabetically,
  validateDependant,
  validateLatitude,
  validateLongitude,
  validateMarketingDescription,
  validateMaxValue,
  validateMinMax,
  validateMinValue,
} from "../../utils/utils";
import PreFilledDataCard from "./PreFilledDataCard";
import PropertyCharacteristics from "./PropertyCharacteristics";

const { Option } = Select;

const TotalBuildingSizeUOM = ({ viewOnlyMode, updateUOM, fieldsType }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("BuildingInfo.BuildingUOM")}
      name={["buildingInfo", "totalBuildingSizeUOM"]}
      dependencies={[["buildingInfo", "totalBuildingSize"]]}
      noStyle
      rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.totalBuildingSizeUOM }]}
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("BuildingInfo.BuildingUOMPlaceholder")}
        onChange={updateUOM}
        disabled={viewOnlyMode}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MinSpaceAvailableUOM = ({ viewOnlyMode, updateUOM, fieldsType }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t("BuildingInfo.MinUOM")}
      name={["buildingInfo", "minSpaceAvailableUOM"]}
      dependencies={[["buildingInfo", "minSpaceAvailable"]]}
      noStyle
      rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.minSpaceAvailableUOM }]}
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("BuildingInfo.BuildingUOMPlaceholder")}
        onChange={updateUOM}
        disabled={viewOnlyMode}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MaxSpaceAvailableUOM = ({ viewOnlyMode, updateUOM, fieldsType }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t("BuildingInfo.MaxUOM")}
      name={["buildingInfo", "maxSpaceAvailableUOM"]}
      dependencies={[["buildingInfo", "maxSpaceAvailable"]]}
      noStyle
      rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.maxSpaceAvailableUOM }]}
    >
      <Select
        style={{ width: 140 }}
        placeholder={t("BuildingInfo.BuildingUOMPlaceholder")}
        onChange={updateUOM}
        disabled={viewOnlyMode}
      >
        {_map(sortAlphabetically(MEASUREMENT_UNITS), _unit => (
          <Option key={_unit.value} value={_unit.value}>
            {_unit.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const BuildingInfo = ({
  form,
  viewOnlyMode,
  isTemporaryProperty,
  notAvailable,
  updateNotAvailableFields,
  disableLatLng,
  countryAddressFields,
  fetchCountryAddressFields,
  templateList,
  auditPanelDrawerVisible,
}) => {
  const { language, languageStatus } = useLanguage();
  const [templateId, setTemplateId] = useState(null);
  const [modalVisible, setModalVisibility] = useState(false);

  const { t } = useTranslation();
  const {
    marketList: markets,
    subMarketActiveList: subMarkets,
    loadingSubMarkets: loading,
    propertyRollUpList,
    loadingPropertyRollUp,
  } = useSelector(state => state.property);
  const { selected, codeDetail } = useSelector(state => ({
    ...state.building,
  }));
  const { lookupList } = useSelector(state => state.helperText);
  const dispatch = useDispatch();

  const FEATURE_FLAG = getFeatureFlags();

  const setTemplateDescription = data => {
    if (!_isEmpty(data)) {
      if (_isEmpty(form.getFieldValue("buildingInfo")?.marketingDescription)) {
        populateDescription(data);
      } else {
        setModalVisibility(true);
      }
    }
  };

  useEffect(() => {
    if (selected?.market?.id) {
      getPropertyRollUpList({
        ...SEARCH_QUERY,
        ...QUERY_PARAMS,
      });
    }
  }, [selected]);

  const getPropertyRollUpList = async query => {
    let _query = query;
    if (!_query) {
      _query = {
        ...SEARCH_QUERY,
        ...QUERY_PARAMS,
      };
    }
    _query.filterCriteria.market = selected?.market?.id;
    dispatch(getPropertyRollUps(_query));
  };

  const populateDescription = data => {
    if (data) {
      const country = form.getFieldValue(["buildingInfo", "address", "country"]);
      const selectedTemplate = templateList.find(item => item.id === data);
      const marketingDescription = _get(
        selectedTemplate,
        `translations.${
          country === "Mexico" && language.current !== LANGUAGE_CODES.English.value
            ? LANGUAGE_CODES.Mexico.value
            : language.current
        }.template`,
        selectedTemplate?.template,
      );
      const obj = {};
      if (languageStatus !== "primary") {
        obj.translations = {
          [language.current]: { buildingInfo: { marketingDescription } },
        };
      } else {
        obj.buildingInfo = { marketingDescription };
      }
      form.setFieldsValue({ ...obj });
      setTemplateId(data);
    }
  };

  const updateUOM = uom => {
    dispatch(buildingUOMSuccess(uom));
    validateDependant(form, "buildingInfo", ["minSpaceAvailable", "maxSpaceAvailable"]);
  };

  const updateTotalBuildinSizeUOM = uom => {
    dispatch(buildingUOMSuccess(uom));
    validateDependant(form, "buildingInfo", ["totalBuildingSize"]);
  };

  const _onChangeToggle = key => {
    updateNotAvailableFields(key, "buildingInfo");
  };

  const _onChangeMarket = key => {
    const market = _find(markets, m => m.id === key);
    fetchCountryAddressFields(market);
    const _buildingInfo = form.getFieldValue("buildingInfo");
    form.setFieldsValue({
      buildingInfo: { ..._buildingInfo, subMarketId: undefined, region: market?.region?.code },
    });
  };

  const _onChangeTotalBuildingSize = async e => {
    validateDependant(form, "buildingInfo", ["totalBuildingSizeUOM"]);
  };

  const _onCancel = () => {
    setModalVisibility(false);
    form.setFieldsValue({ buildingInfo: { templateId } });
  };

  const _onConfirm = () => {
    populateDescription(form.getFieldValue(["buildingInfo", "templateId"]));
    setModalVisibility(false);
  };

  let notAvailableParams = {};
  if (notAvailable.truckCourtDepth) {
    notAvailableParams = {
      validateStatus: "",
      help: "",
      placeholder: t("Property.NotApplicable"),
      rules: [],
    };
  }

  const disableCoordinates = viewOnlyMode || (disableLatLng && !isTemporaryProperty);

  const disableSubMarket =
    viewOnlyMode || (!isTemporaryProperty && (!_isEmpty(selected?.subMarket) || !_isEmpty(codeDetail?.subMarket)));

  const renderUrbanIndicator = () => {
    const country = selected?.market?.country?.toLocaleLowerCase();
    if (
      (country === COUNTRY_NAME.CANADA && !FEATURE_FLAG?.Canada_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.UNITED_STATES && !FEATURE_FLAG?.USA_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.BRAZIL && !FEATURE_FLAG?.Brazil_Urban_Indicator?.status) ||
      (country === COUNTRY_NAME.MEXICO && !FEATURE_FLAG?.Mexico_Urban_Indicator?.status)
    ) {
      return <></>;
    }
    return (
      <Form.Item name={["buildingInfo", "isUrban"]} valuePropName="checked">
        <LabelWithCheckbox
          viewOnlyMode={viewOnlyMode}
          label={t("Property.IsUrban")}
          tooltip={lookupList[HELPER_TEXT_KEYS.building.isUrban]}
        />
      </Form.Item>
    );
  };

  const fieldsType = FORM_FIELDS_TYPE.regular;

  const _renderForm = () => (
    <Row className="fields-row" gutter={20} type="flex">
      <Col span={8} xs={24} sm={12} lg={12}>
        {isTemporaryProperty && (
          <Form.Item
            tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.market], language)}
            label={t("Property.Market")}
            name={["buildingInfo", "marketId"]}
            rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.marketId }]}
            shouldUpdate
          >
            <Select
              onChange={_onChangeMarket}
              showSearch
              placeholder={t("Property.MarketPlaceholder")}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode || !isTemporaryProperty}
            >
              {_map(markets, market => (
                <Option key={market.id} value={market.id}>
                  {market.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {isTemporaryProperty && FEATURE_FLAG.SUBMARKET?.status && (
          <Form.Item
            tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.submarket], language)}
            label={t("Property.Submarket")}
            name={["buildingInfo", "subMarketId"]}
            shouldUpdate
            rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.subMarketId }]}
          >
            <Select
              loading={loading}
              showSearch
              placeholder={t("Property.SubmarketPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={disableSubMarket}
            >
              {_map(subMarkets, subMarket => (
                <Option key={subMarket.id} value={subMarket.id}>
                  {subMarket.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.StreetAddress1")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.streetAddress1.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.streetAddress1]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "address", "streetAddress1"]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.streetAddress1,
              message: t("BuildingInfo.StreetAddress1RulesMessage"),
            },
            { whitespace: true, message: t("BuildingInfo.StreetAddress1WhitespaceMessage") },
          ]}
        >
          <Input placeholder={t("Property.StreetAddress1")} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.StreetAddress2")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.streetAddress2.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.streetAddress2]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "address", "streetAddress2"]}
        >
          <Input placeholder={t("Property.StreetAddress2")} disabled={viewOnlyMode} />
        </CustomFormItem>
        {countryAddressFields?.city && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.City")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.city.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.city]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "city"]}
            rules={
              isUSProperty(selected.market) && [
                {
                  required: ["buildingInfo", "address", "city"],
                  message: t("BuildingInfo.CityRuleMessage"),
                },
              ]
            }
          >
            <Input placeholder={t("Property.City")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.district && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.District")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.district.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.district]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "district"]}
          >
            <Input placeholder={t("Property.District")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.neighborhood && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Neighborhood")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.neighborhood.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.neighbourhood]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "neighborhood"]}
          >
            <Input placeholder={t("Property.Neighborhood")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.postTown && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.PostTown")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.postTown.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.postTown]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "postTown"]}
          >
            <Input placeholder={t("Property.PostTown")} disabled={viewOnlyMode} />
          </Form.Item>
        )}
        {countryAddressFields?.province && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Province")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.province.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.province]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "province"]}
          >
            <Select
              showSearch
              placeholder={t("Property.ProvincePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.province) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.prefecture && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Prefecture")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.prefecture.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.prefecture]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "prefecture"]}
          >
            <Select
              showSearch
              placeholder={t("Property.PrefecturePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.prefecture) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.state && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.State")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.state.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.state]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "state"]}
            rules={
              isUSProperty(selected.market) && [
                {
                  required: ["buildingInfo", "address", "state"],
                  message: t("BuildingInfo.StateRuleMessage"),
                },
              ]
            }
          >
            <Select
              showSearch
              placeholder={t("Property.StatePlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.state) || []}
            />
          </Form.Item>
        )}
        {countryAddressFields?.dosi && (
          <Form.Item
            label={
              <CustomTooltipHelper
                viewOnlyMode={viewOnlyMode}
                label={t("Property.Dosi")}
                form={form}
                tab={BUILDING_TABS_KEYS[1]}
                fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.dosi.key}
                tooltip={lookupList[HELPER_TEXT_KEYS.building.dosi]}
                auditPanelDrawerVisible={auditPanelDrawerVisible}
              />
            }
            name={["buildingInfo", "address", "dosi"]}
          >
            <Select
              showSearch
              placeholder={t("Property.DosiPlaceholder")}
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoComplete="off"
              disabled={viewOnlyMode}
              options={sortAlphabetically(countryAddressFields?.dosi) || []}
            />
          </Form.Item>
        )}
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={countryAddressFields?.cedex ? 12 : 24} lg={countryAddressFields?.cedex ? 12 : 24}>
            <Form.Item
              label={
                <CustomTooltipHelper
                  viewOnlyMode={viewOnlyMode}
                  label={t("Property.PostalCode")}
                  form={form}
                  tab={BUILDING_TABS_KEYS[1]}
                  fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.postalCode.key}
                  tooltip={lookupList[HELPER_TEXT_KEYS.building.postalCode]}
                  auditPanelDrawerVisible={auditPanelDrawerVisible}
                />
              }
              name={["buildingInfo", "address", "postalCode"]}
              rules={[
                {
                  required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.postalCode,
                  message: t("BuildingInfo.PostalCodeRulesMessage"),
                },
                { whitespace: true, message: t("BuildingInfo.PostalCodeWhitespaceMessage") },
              ]}
            >
              <Input placeholder={t("Property.PostalCode")} disabled={viewOnlyMode} />
            </Form.Item>
          </Col>
          {countryAddressFields?.cedex && (
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <CustomTooltipHelper
                    viewOnlyMode={viewOnlyMode}
                    label={t("Property.Cedex")}
                    form={form}
                    tab={BUILDING_TABS_KEYS[1]}
                    fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.cedex.key}
                    tooltip={lookupList[HELPER_TEXT_KEYS.building.cedex]}
                    auditPanelDrawerVisible={auditPanelDrawerVisible}
                  />
                }
                name={["buildingInfo", "address", "cedex"]}
              >
                <Input placeholder={t("Property.Cedex")} disabled={viewOnlyMode} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("BuildingInfo.TotalBuildingSize")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.totalBuildingSize.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.totalBuildingSize]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "totalBuildingSize"]}
          validateFirst
          dependencies={[["buildingInfo", "totalBuildingSizeUOM"]]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.totalBuildingSize,
              message: t("BuildingInfo.TotalBuildingSizeRulesMessage"),
            },
            {
              validator: (rule, value) => validateMinValue(value, t("BuildingInfo.TotalBuildingSize"), 100),
            },
          ]}
        >
          <Input
            type="number"
            min="100"
            step="any"
            placeholder={t("BuildingInfo.TotalBuildingSize")}
            disabled={viewOnlyMode}
            onChange={_onChangeTotalBuildingSize}
            addonAfter={
              <TotalBuildingSizeUOM
                viewOnlyMode={viewOnlyMode}
                updateUOM={updateTotalBuildinSizeUOM}
                fieldsType={fieldsType}
              />
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("BuildingInfo.MinSpaceAvailable")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.minSpaceAvailable.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.minSpaceAvailable]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "minSpaceAvailable"]}
          dependencies={[
            ["buildingInfo", "maxSpaceAvailable"],
            ["buildingInfo", "minSpaceAvailableUOM"],
          ]}
          validateFirst
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.minSpaceAvailable,
              message: t("BuildingInfo.MinSpaceAvailableRulesMessage"),
            },
            {
              validator: (rule, value) => validateMinValue(value, t("BuildingInfo.MinSpaceAvailable"), 100),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return validateMinMax({
                  currValue: value,
                  depValue: getFieldValue(["buildingInfo", "maxSpaceAvailable"]),
                  type: "max",
                  fieldName: t("BuildingInfo.MinSpaceAvailable"),
                  depLabel: t("BuildingInfo.MaxSpaceAvailable"),
                });
              },
            }),
          ]}
        >
          <Input
            type="number"
            min="100"
            step="any"
            placeholder={t("BuildingInfo.MinSpaceAvailable")}
            disabled={viewOnlyMode}
            onChange={() => validateDependant(form, "buildingInfo", ["minSpaceAvailableUOM"])}
            addonAfter={
              <MinSpaceAvailableUOM viewOnlyMode={viewOnlyMode} updateUOM={updateUOM} fieldsType={fieldsType} />
            }
          />
        </Form.Item>

        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("BuildingInfo.MaxSpaceAvailable")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.maxSpaceAvailable.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.maxSpaceAvailable]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "maxSpaceAvailable"]}
          dependencies={[
            ["buildingInfo", "minSpaceAvailable"],
            ["buildingInfo", "maxSpaceAvailableUOM"],
            ["buildingInfo", "totalBuildingSize"],
          ]}
          validateFirst
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.maxSpaceAvailable,
              message: t("BuildingInfo.MaxSpaceAvailableRulesMessage"),
            },
            {
              validator: (rule, value) => validateMinValue(value, t("BuildingInfo.MaxSpaceAvailable"), 100),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return validateMinMax({
                  currValue: value,
                  depValue: getFieldValue(["buildingInfo", "minSpaceAvailable"]),
                  type: "min",
                  fieldName: t("BuildingInfo.MaxSpaceAvailable"),
                  depLabel: t("BuildingInfo.MinSpaceAvailable"),
                });
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return validateMinMax({
                  currValue: value,
                  depValue: getFieldValue(["buildingInfo", "totalBuildingSize"]),
                  type: "max",
                  fieldName: t("BuildingInfo.MaxSpaceAvailable"),
                  depLabel: t("BuildingInfo.TotalBuildingSize"),
                });
              },
            }),
          ]}
        >
          <Input
            type="number"
            min="100"
            step="any"
            placeholder={t("BuildingInfo.MaxSpaceAvailable")}
            disabled={viewOnlyMode}
            onChange={() => validateDependant(form, "buildingInfo", ["maxSpaceAvailableUOM"])}
            addonAfter={
              <MaxSpaceAvailableUOM viewOnlyMode={viewOnlyMode} updateUOM={updateUOM} fieldsType={fieldsType} />
            }
          />
        </Form.Item>
        {renderUrbanIndicator()}
      </Col>
      <Col span={8} xs={24} sm={12} lg={12}>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("BuildingInfo.PropertyType")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.propertySubtype.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.propertyType]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "propertySubtype"]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.propertySubtype,
              message: t("BuildingInfo.PropertyTypeRulesMessage"),
            },
          ]}
        >
          <Select
            showSearch
            placeholder={t("BuildingInfo.PropertyTypePlaceholder")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            autoComplete="off"
            disabled={viewOnlyMode}
          >
            {_map(sortAlphabetically(BUILDING_TYPE), propertyType => (
              <Option key={propertyType.value} value={propertyType.value}>
                {propertyType.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Table.PropertyRollUpCode")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.propertyRollUp.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.propertyRollUp]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "propertyRollUp"]}
        >
          <CustomSelect
            allowClear
            placeholder={t("BuildingInfo.PropertyRollUpPlaceholder")}
            loading={loadingPropertyRollUp}
            fetchOptions={getPropertyRollUpList}
            optionsList={propertyRollUpList}
            queryParam={QUERY_PARAMS}
            disabled={viewOnlyMode}
          />
        </Form.Item>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.MarketingPropertyName")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.marketingPropertyName.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.marketingPropertyName]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "marketingPropertyName"]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.marketingPropertyName,
              message: t("BuildingInfo.MarketingPropertyNameRulesMessage"),
            },
            { whitespace: true, message: t("BuildingInfo.MarketingPropertyNameWhitespaceMessage") },
          ]}
        >
          <Input placeholder={t("Property.MarketingPropertyName")} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          className="form-item-label-with-drop-down"
          label={
            <LabelWithDropDown
              label={t("Property.MarketingDescription")}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.marketingDescription]}
              templateId={templateId}
              changeHandler={setTemplateDescription}
              options={templateList}
              viewOnlyMode={viewOnlyMode}
              name={["buildingInfo", "templateId"]}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.marketingDescription.key}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "marketingDescription"]}
          rules={[
            {
              required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.marketingDescription,
              message: t("BuildingInfo.MarketingDescriptionRulesMessage"),
            },
            { whitespace: true, message: t("BuildingInfo.MarketingDescriptionWhitespaceMessage") },
            {
              validator: (rule, value) => validateMarketingDescription(value, t("Property.MarketingDescription")),
            },
          ]}
        >
          <Input.TextArea placeholder={t("Property.MarketingDescription")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>
        <Form.Item
          tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.availableDate], language)}
          label="Available Date"
          name={["buildingInfo", "availableDate"]}
          fieldKey={["buildingInfo", "availableDate"]}
        >
          <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} disabled={viewOnlyMode} />
        </Form.Item>

        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.DigitalTourURL")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.digitalTourUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.digitalTourUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "digitalTourUrl"]}
          rules={[{ type: "url", message: t("BuildingInfo.DigitalTourUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.DigitalTourURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>

        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.VideoURL")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.videoUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.videoUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "videoUrl"]}
          rules={[{ type: "url", message: t("BuildingInfo.VideoUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.VideoURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>
        <CustomFormItem
          label={
            <CustomTooltipHelper
              viewOnlyMode={viewOnlyMode}
              label={t("Property.MicrositeURL")}
              form={form}
              tab={BUILDING_TABS_KEYS[1]}
              fieldName={FORM_FIELDS_LOOKUP.building.buildingInfo.micrositeUrl.key}
              tooltip={lookupList[HELPER_TEXT_KEYS.building.micrositeUrl]}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          }
          name={["buildingInfo", "micrositeUrl"]}
          rules={[{ type: "url", message: t("BuildingInfo.MicrositeUrlRulesMessage") }]}
        >
          <Input placeholder={t("Property.MicrositeURL")} row={5} disabled={viewOnlyMode} />
        </CustomFormItem>

        {!disableCoordinates && (
          <>
            <Form.Item
              tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.latitude], language)}
              label={t("Property.Latitude")}
              name={["buildingInfo", "latitude"]}
              rules={[
                { required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.latitude },
                {
                  validator: (rule, value) => validateLatitude(value),
                },
                {
                  validator: (rule, value) => validateMinValue(value, t("Property.Latitude"), -90),
                },
                {
                  validator: (rule, value) => validateMaxValue(value, t("Property.Latitude"), 90),
                },
              ]}
            >
              <Input placeholder={t("Property.Latitude")} disabled={disableCoordinates} />
            </Form.Item>
            <Form.Item
              tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.longitude], language)}
              label={t("Property.Longitude")}
              name={["buildingInfo", "longitude"]}
              rules={[
                { required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.longitude },
                {
                  validator: (rule, value) => validateLongitude(value),
                },
                {
                  validator: (rule, value) => validateMinValue(value, t("Property.Longitude"), -180),
                },
                {
                  validator: (rule, value) => validateMaxValue(value, t("Property.Longitude"), 180),
                },
              ]}
            >
              <Input placeholder={t("Property.Longitude")} disabled={disableCoordinates} />
            </Form.Item>
          </>
        )}
        <Form.Item
          className="form-item-label-with-switch"
          label={
            <LabelWithSwitch
              tooltip={lookupList[HELPER_TEXT_KEYS.building.truckCourtDepth]}
              checked={notAvailable.truckCourtDepth}
              label={t("BuildingInfo.TruckCourtDepth")}
              viewOnlyMode={viewOnlyMode}
              _onChangeToggle={_onChangeToggle}
              fieldName="truckCourtDepth"
            />
          }
          name={["buildingInfo", "truckCourtDepth"]}
          validateFirst
          rules={[
            {
              required: true,
              message: t("BuildingInfo.TruckCourtDepthRulesMessage"),
            },
            {
              validator: (rule, value) => validateMinValue(value, t("BuildingInfo.TruckCourtDepth"), 1),
            },
          ]}
          {...notAvailableParams}
        >
          <Input
            type="number"
            step="any"
            placeholder={t("BuildingInfo.TruckCourtDepth")}
            min="1"
            disabled={viewOnlyMode || notAvailable.truckCourtDepth}
            {...notAvailableParams}
          />
        </Form.Item>
        <Form.Item
          tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS.building.railServed], language)}
          label={t("BuildingInfo.RailServed")}
          name={["buildingInfo", "railServed"]}
          rules={[{ required: BUILDING_REQUIRED_FIELDS_LOOKUP[fieldsType].building.railServed }]}
        >
          <Radio.Group disabled={viewOnlyMode}>
            <Radio value>{t("Buttons.Yes")}</Radio>
            <Radio value={false}>{t("Buttons.No")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );

  const preFilledCardProps = {
    title: [t("PreFilledDataCard.Building.Title")],
    data: {
      [t("BuildingInfo.BuildingCode")]: { parent: "buildingInfo", child: "propertyCode.label" },
      [t("Table.PropertyRollUpCode")]: { parent: "buildingInfo", child: "propertyRollUp.label" },
      [t("Property.Market")]: { parent: "buildingInfo", child: "marketId" },
      [t("Property.Submarket")]: { parent: "buildingInfo", child: "subMarketId" },
      [t("Property.Region")]: { parent: "buildingInfo", child: "region" },
      [t("Property.Country")]: { parent: countryAddressFields, child: "country", isExternal: true },
      [t("Property.Latitude")]: { parent: "buildingInfo", child: "latitude" },
      [t("Property.Longitude")]: { parent: "buildingInfo", child: "longitude" },
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={18} lg={18}>
          {_renderForm()}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <PreFilledDataCard preFilledCardProps={preFilledCardProps} form={form} />
          <PropertyCharacteristics
            data={codeDetail.characteristics}
            form={form}
            codeDetail={codeDetail}
            selected={selected}
            notAvailable={notAvailable}
            isTemporary={isTemporaryProperty}
            viewOnlyMode={viewOnlyMode}
            updateNotAvailableFields={_onChangeToggle}
          />
        </Col>
      </Row>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title={t("Template.Confirmation")}
        width={600}
        minHeight="10vh"
        maxHeight="60vh"
        overFlowY="auto"
        visible={modalVisible}
        onCancel={_onCancel}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCancel}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={_onConfirm}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <p>{t("Template.ConfirmationMessage")}</p>
      </AppModal>
    </>
  );
};
BuildingInfo.propTypes = {
  form: PropTypes.object,
  viewOnlyMode: PropTypes.bool,
  isTemporaryProperty: PropTypes.bool,
  notAvailable: PropTypes.object,
  updateNotAvailableFields: PropTypes.func,
  disableLatLng: PropTypes.bool,
  fetchCountryAddressFields: PropTypes.func,
  countryAddressFields: PropTypes.object,
  templateList: PropTypes.array,
  auditPanelDrawerVisible: PropTypes.bool,
};

TotalBuildingSizeUOM.propTypes = {
  updateUOM: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  fieldsType: PropTypes.string,
};

MinSpaceAvailableUOM.propTypes = {
  updateUOM: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  fieldsType: PropTypes.string,
};

MaxSpaceAvailableUOM.propTypes = {
  updateUOM: PropTypes.func,
  viewOnlyMode: PropTypes.bool,
  fieldsType: PropTypes.string,
};

export default BuildingInfo;
