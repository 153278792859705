// import "./wdyr";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";

const storeConfig = configureStore();

ReactDOM.render(
  <storeConfig.Provider store={storeConfig.store}>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </storeConfig.Provider>,
  document.getElementById("root"),
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
