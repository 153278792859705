import { getQueryParams, getSearchListQuery } from "../utils/utils";
import { get, remove, put } from "./http.service";

const propertyService = {
  // getPropertyCodes: ({ id, type }) => get(`property/codes/${id || ""}?${type ? `propertyType=${type}` : ""}`),
  getPropertyCodes: ({ id, ...query }) => get(`property/codes/${id || ""}${getSearchListQuery(query)}`),
  verifyPropertyCode: id => get(`property/codes/verify/${id}`),
  getPropertyMedia: ({ id, propertyType }) => get(`bynder/${id}?type=${propertyType}`),
  searchDashboardProperties: (query, getPropertyType) =>
    get(`property/${getPropertyType}`, { params: getQueryParams(query) }),
  searchProperties: query => get(`property/search`, { params: getQueryParams(query) }),
  suggestions: query => get(`property/suggestion${getSearchListQuery(query)}`),
  removeProperty: id => remove(`property/${id}`),
  convertProperty: body => put(`property/convertProperty`, body),
};
export default propertyService;
