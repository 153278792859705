import RegionService from "../../services/region.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  REGION_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function regionRequest(key = LOADING.REGION_LOADING) {
  return { type: ACTIONS.REGION_REQUEST, key };
}

export function regionSuccess(response) {
  return { type: ACTIONS.REGION_SUCCESS, response };
}

export function regionSuggestionSuccess() {
  return { type: ACTIONS.REGION_SUGGESTION_SUCCESS };
}

export function regionListSuccess(response) {
  return { type: ACTIONS.REGION_LIST_SUCCESS, response };
}

export function regionSelectedListSuccess(response) {
  return { type: ACTIONS.REGION_SELECTED_LIST_SUCCESS, response };
}

export function regionError(error, key = LOADING.REGION_LOADING) {
  return { type: ACTIONS.REGION_ERROR, error, key };
}

export function getRegions(query) {
  return async dispatch => {
    dispatch(regionRequest());
    try {
      const [err, response] = await to(RegionService.search(query));
      if (err) throwError(err);
      dispatch(regionListSuccess({ list: response.content, pagination: { ...query, totalElements: response.total } }));
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function setSelectedRegions(payload) {
  return async dispatch => {
    try {
      dispatch(
        regionSelectedListSuccess({
          selectedList: payload /* , pagination: { ...query, totalElements: response.total } */,
        }),
      );
      return payload;
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function setRegions(payload) {
  return async dispatch => {
    try {
      dispatch(regionListSuccess({ list: payload /* , pagination: { ...query, totalElements: response.total } */ }));
      return payload;
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getAllRegions(query) {
  return async dispatch => {
    dispatch(regionRequest());
    try {
      const [err, response] = await to(RegionService.get());
      if (err) throwError(err);
      dispatch(regionListSuccess({ list: response /* , pagination: { ...query, totalElements: response.total } */ }));
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getRegionSuggestion(payload) {
  return async dispatch => {
    dispatch(regionRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(RegionService.suggestions(payload));
      if (err) throwError(err);
      dispatch(regionSuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(regionError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getRegionById(id) {
  return async dispatch => {
    dispatch(regionRequest());
    try {
      const [err, response] = await to(RegionService.getById(id));
      if (err) throwError(err);
      dispatch(regionSuccess(response));
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      throwError(error);
    }
  };
}

export function updateRegion(id, payload) {
  return async dispatch => {
    dispatch(regionRequest());
    try {
      const [err, response] = await to(RegionService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(regionSuccess({}));
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function createRegion(payload) {
  return async dispatch => {
    dispatch(regionRequest());
    try {
      const [err, response] = await to(RegionService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(regionSuccess({}));
    } catch (error) {
      dispatch(regionError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
