import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getSessionStorage, setActiveTab } from "../utils/utils";

/**
 * Get search keyword from pagination
 */
export const useKeyword = dependencies => {
  const { pagination } = dependencies;
  const [keyword, setKeyword] = useState([]);

  useEffect(() => {
    if (!_isEmpty(pagination) && !_isEmpty(pagination.labelInValue)) {
      setKeyword(pagination?.labelInValue?.children);
    } else if (!_isEmpty(pagination) && !_isEmpty(pagination.keyword) && pagination.keyword !== keyword) {
      setKeyword(pagination?.keyword);
    }
  }, [pagination]);

  return { keyword, setKeyword };
};

/**
 * Get active tab from location hash
 */
export const useLocationHash = dependencies => {
  const history = useHistory();
  const { _tabs, defaultTabKey, _tabsKey } = dependencies;
  const [activeTabKey, setActiveTabKey] = useState(history?.location?.state?.activeTabKey || defaultTabKey);

  useEffect(() => {
    setActiveTab(_tabs, _tabsKey, activeTabKey, key => {
      setActiveTabKey(key);
    });
    window.onpopstate = e => {
      setActiveTab(_tabs, _tabsKey, activeTabKey, key => {
        setActiveTabKey(key);
      });
    };
  }, []);

  return { activeTabKey, setActiveTabKey };
};

/**
 * Get selected language and language status from store
 */
export const useLanguage = () => {
  const language = useSelector(state => state.language);

  const languageStatus = useMemo(
    () => (_isArray(language.secondary) && language.secondary?.includes(language.current) ? "secondary" : "primary"),
    [language],
  );

  return { language, languageStatus };
};

/**
 * Get pagination for property from store or session
 */
export const usePropertyPagination = dependencies => {
  const { pagination, paginationType } = dependencies;
  const propertyPagination = useMemo(
    () => (!_isEmpty(pagination) ? pagination : getSessionStorage(paginationType) || {}),
    [pagination],
  );

  return { propertyPagination };
};

/**
 * Get pagination for property from store or session
 */
export const useHomePagination = dependencies => {
  const { pagination, paginationType } = dependencies;
  const homeFilterPagination = useMemo(
    () => (!_isEmpty(pagination) ? pagination : getSessionStorage(paginationType) || {}),
    [pagination],
  );

  return { homeFilterPagination };
};

export const useCurrentVacantPagination = dependencies => {
  const { pagination, paginationType } = dependencies;
  const currentVacantPagination = useMemo(
    () => (!_isEmpty(pagination) ? pagination : getSessionStorage(paginationType) || {}),
    [pagination],
  );
  return { currentVacantPagination };
};

export const useUpcomingPropertyPagination = dependencies => {
  const { pagination, paginationType } = dependencies;
  const upcomingPropertyPagination = useMemo(
    () => (!_isEmpty(pagination) ? pagination : getSessionStorage(paginationType) || {}),
    [pagination],
  );
  return { upcomingPropertyPagination };
};

export const useAuditPagination = dependencies => {
  const { pagination, paginationType } = dependencies;
  const openAuditPagination = useMemo(
    () => (!_isEmpty(pagination) ? pagination : getSessionStorage(paginationType) || {}),
    [pagination],
  );
  return { openAuditPagination };
};
/**
 * Get filter tags for property from store or session
 */
export const usePropertyFilterTags = dependencies => {
  const { tags, tagType } = dependencies;
  const propertyTag = useMemo(() => (!_isEmpty(tags) ? tags : getSessionStorage(tagType) || []), [tags]);

  return { propertyTag };
};

/**
 * Get filter tags for property from store or session
 */
export const useUpcomingVacantPropertyFilterTags = dependencies => {
  const { tags, tagType } = dependencies;
  const upcomingVacantPropertyTag = useMemo(() => (!_isEmpty(tags) ? tags : getSessionStorage(tagType) || []), [tags]);
  return { upcomingVacantPropertyTag };
};

/**
 * Get filter tags for property from store or session
 */
export const useCurrentVacantPropertyFilterTags = dependencies => {
  const { tags, tagType } = dependencies;
  const currentVacantPropertyTag = useMemo(() => (!_isEmpty(tags) ? tags : getSessionStorage(tagType) || []), [tags]);
  return { currentVacantPropertyTag };
};

/**
 * Get filter tags for property from store or session
 */
export const useOpenAuditFilterTags = dependencies => {
  const { tags, tagType } = dependencies;
  const openAuditFilterTag = useMemo(() => (!_isEmpty(tags) ? tags : getSessionStorage(tagType) || []), [tags]);
  return { openAuditFilterTag };
};

/**
 * Get filter tags for property from store or session
 */
export const useHomeFilterTags = dependencies => {
  const { tags, tagType } = dependencies;
  const homeFilterTag = useMemo(() => (!_isEmpty(tags) ? tags : getSessionStorage(tagType) || []), [tags]);
  return { homeFilterTag };
};
