import { get, put, post, remove, patch } from "./http.service";
import { delay, getSearchListQuery } from "../utils/utils";
import { marketList } from "../__mocks__/markets";

const MarketService = {
  // getAllMarkets: async () => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve(marketList));
  // },
  // getMarketById: async id => {
  //   await delay(2000);
  //   const response = marketList.find(value => value.id === id) || {};
  //   return new Promise(resolve => resolve(response));
  // },
  // updateMarket: async payload => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve());
  // },
  // createMarket: async payload => {
  //   await delay(2000);
  //   return new Promise(resolve => resolve());
  // },
  suggestions: keyword => get(`market/suggestion?keyword=${keyword}`),
  searchMarkets: query => get(`market/search${getSearchListQuery(query)}`),
  getAllMarkets: () => get("market"),
  getMarketById: id => get(`market/${id}`),
  updateMarket: (id, body) => put(`market/${id}`, body),
  createMarket: body => post(`market`, body),
  exportMarkets: () => get(`market/export`, { responseType: "base64" }),
  createSubMarket: body => post(`market/createSubMarket`, body),
};

export default MarketService;
