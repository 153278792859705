import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const building = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.BUILDING_REQUEST:
      draft[action.key] = !draft[action.key];
      return draft;
    case ACTIONS.BUILDING_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.selected = action.response;
      return draft;
    case ACTIONS.BUILDING_CODE_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.codeDetail = action.response;
      return draft;
    case ACTIONS.BUILDING_PUBLISHED_UNITS_SUCCESS:
      draft.publishedUnits = action.response;
      return draft;
    case ACTIONS.BUILDING_UOM_SUCCESS:
      draft.uom = action.response;
      return draft;
    case ACTIONS.BUILDING_TOTAL_BUILDING_SIZE_SUCCESS:
      draft.totalBuildingSize = action.response;
      return draft;
    case ACTIONS.BUILDING_OCCUPIED_SPACE_SUCCESS:
      draft.spaceOccupiedByUnits = action.response;
      return draft;
    case ACTIONS.BUILDING_ERROR:
      draft.loading = false;
      draft.auditLoading = false;
      draft.usersLoading = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.BUILDING_COMBINED_UNIT_NUMBER:
      draft.maxCombinedUnitNumber = action.maxCombinedUnitNumber;
      return draft;
    case ACTIONS.BUILDING_UNIT_DETAIL_SUCCESS:
      draft.unitDetail = action.response;
      draft.loading = false;
      return draft;
    case ACTIONS.GET_MARKET_USERS_SUCCESS:
      draft.usersLoading = false;
      draft.error = null;
      return draft;
    default:
      return draft;
  }
});

export default building;
