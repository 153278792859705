import { Tooltip } from "antd";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { useLanguage } from "../../hooks";
import permissionsUtil from "../../utils/permissions.util";
import { getDangerouslySetHTML, getTranslatedHelperText } from "../../utils/utils";
import AuditIssueTag from "../AuditIssueTag/AuditIssueTag";
import CustomIcon from "../CustomIcon/CustomIcon";

const CustomTooltipHelper = ({
  label,
  tooltip,
  form,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  unitIndex,
  viewOnlyMode,
}) => {
  const { language } = useLanguage();

  const canAuditProperty = permissionsUtil.checkAuth("audit_add");

  const getTooltipAndTag = () => {
    const auditTag = auditPanelDrawerVisible && !_isEmpty(fieldName) && (
      <AuditIssueTag
        viewOnlyMode={viewOnlyMode}
        form={form}
        tab={tab}
        fieldName={fieldName}
        auditPanelDrawerVisible={auditPanelDrawerVisible}
        unitIndex={unitIndex}
        canAuditProperty={canAuditProperty}
      />
    );

    if (!_isEmpty(tooltip) && !_isEmpty(tooltip.helperText)) {
      return (
        <span>
          <Tooltip title={getDangerouslySetHTML(getTranslatedHelperText(tooltip, language))}>
            <CustomIcon name="InfoCircleOutlined" />
          </Tooltip>
          &nbsp;
          {auditTag}
        </span>
      );
    }
    return auditTag;
  };

  return (
    <span>
      {label}
      &nbsp;
      {getTooltipAndTag()}
    </span>
  );
};

CustomTooltipHelper.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  form: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
  unitIndex: PropTypes.number,
  label: PropTypes.string,
  viewOnlyMode: PropTypes.bool,
};

export default CustomTooltipHelper;
