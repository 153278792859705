import { Alert, Button, Col, Collapse, Form, Row, Spin, Switch, Tabs } from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _reduce from "lodash/reduce";
import _some from "lodash/some";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Sticky, StickyContainer } from "react-sticky";
import AppModal from "../../components/AppModal/AppModal";
import AuditDrawer from "../../components/AuditDrawer/AuditDrawer";
import Contacts from "../../components/ContactsTab/ContactsTab";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import DraftComments from "../../components/DraftComments/DraftComments";
import LangModal from "../../components/LangModal/LangModal";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import PageTitle from "../../components/PageTitle/PageTitle";
import PopUp from "../../components/PopUp/PopUp";
import TransferTable from "../../components/TransferTable/TransferTable";
import { useLanguage, useLocationHash } from "../../hooks";
import {
  addLand,
  getLandById,
  landCodeSuccess,
  landPublishAndPassAudit,
  landSuccess,
  updateLand,
  updateLandAuditStatus,
} from "../../store/actions/land.actions";
import { setLanguage } from "../../store/actions/language.actions";
import {
  activePropertySubMarketsSuccess,
  auditSuccess,
  getAllCountryAddressFields,
  getAllPropertyRollUpSuccess,
  getAuditList,
  getPropertyAllMarkets,
  getPropertyBrokers,
  getPropertyCodes,
  getPropertyEmployeeContacts,
  getPropertyESMContacts,
  getPropertyMedia,
  propertyAddressFieldsListSuccess,
  propertyCodesSuccess,
  propertyMediaSuccess,
} from "../../store/actions/property.actions";
import {
  HELPER_TEXT_KEYS,
  LAND_MEASUREMENT_UNITS,
  LAND_REQUIRED_FIELDS as requiredFields,
  LAND_TABS_KEYS as _tabsKey,
  LAND_TABS_LOOKUP as _tabs,
  LAND_TRANSLATION_REQUIRED_FIELDS as requiredTranslationFields,
  LANGUAGE_CODES,
  MEASUREMENT_UNITS,
  PROPERTY_AUDIT_STATUS,
  PROPERTY_KEY_FEATURE_TEMPLATE_KEYS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
  PROPERTY_TYPE_UI_MAPPING,
  ROUTES,
  SEARCH_QUERY,
  USER_ESM_TYPE,
  USER_TYPE,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import {
  getContactLabel,
  isAsianCountry,
  isUSProperty,
  mapMediaContentWithAlt,
  removeMediaContent,
  renderPropertyStatusWithModifiedDate,
  wrapUnmarketInTag,
} from "../../utils/property.util";
import {
  checkEmptyObj,
  filterOption,
  getFeatureFlags,
  getTemplateList,
  getTooltip,
  removedEmptyObject,
  setCountryAddressFormFields,
  to,
  toastMessage,
  updateCountryAddressFields,
} from "../../utils/utils";
import AuditIssues from "../AddEditBuilding/AuditIssues";
import PropertyMedia from "../PropertyMedia";
import PropertySearchPreview from "../PropertySearchPreview/PropertySearchPreview";
import "./AddEditLand.scss";
import KeyFeatures from "./KeyFeatures";
import LandInfo from "./LandInfo";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const errorTabsInitialObj = {
  landInfo: false,
  keyFeatures: false,
  contacts: false,
  media: false,
  auditIssues: false,
};

const AddEditLand = ({ componentProps: { viewOnlyMode = false } }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [_files, _setFiles] = useState({});
  const [_translatedFiles, _setTranslatedFiles] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const { activeTabKey, setActiveTabKey } = useLocationHash({ defaultTabKey: _tabs.landInfo.key, _tabs, _tabsKey });
  const [status, setStatus] = useState("");
  const [mediaErrors, setMediaErrors] = useState({});
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [errorTabs, setErrorTabs] = useState({
    primary: { ...errorTabsInitialObj },
    secondary: {},
  });
  const [editTimestamp, setEditTimestamp] = useState();
  const [countryAddressFields, setCountryAddressFields] = useState({});
  const [propertyCodeError, setPropertyCodeError] = useState({});
  const [isTranslated, setIsTranslated] = useState({});
  const [isTemporaryProperty, setIsTemporaryProperty] = useState(false);
  const [isDifferenceModalOpen, setIsDifferenceModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [isLangModalOpen, setIsLangModalOpen] = useState(false);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [auditCountByStatus, setAuditCountByStatus] = useState();
  const [auditPanelDrawerVisible, setAuditPanelDrawerVisible] = useState(false);
  const [landPublishedModal, setLandPublishedModal] = useState(false);
  const [isCallByAudit, setIsCallByAudit] = useState(false);
  const [draftCommentsModal, setDraftCommentsModal] = useState(false);
  const canAuditProperty = permissionsUtil.checkAuth("audit_add");
  const canViewAuditIssues = permissionsUtil.checkAuth("audit_view");
  const FEATURE_FLAG = getFeatureFlags();
  const { LANDMARKET } = FEATURE_FLAG;

  const [form] = Form.useForm();
  const {
    loadingMedia,
    selectedPropertyMedia,
    propertyCodes,
    allCountryAddressFields,
    employeeList,
    brokerList,
    auditList,
  } = useSelector(state => ({
    ...state.property,
  }));

  const {
    codeDetail: selectedProperty,
    loading,
    selected,
  } = useSelector(state => ({
    ...state.land,
  }));

  const { marketingDescriptionTemplateList, keyFeatureTemplateList } = useSelector(state => ({
    marketingDescriptionTemplateList: getTemplateList(state.template.list, "marketingDescription", "land"),
    keyFeatureTemplateList: getTemplateList(state.template.list, "keyFeatures", "land"),
  }));
  const { language, languageStatus } = useLanguage();
  const { lookupList } = useSelector(state => state.helperText);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [_employeeList, _setEmployeeList] = useState(employeeList);
  const [_brokerList, _setBrokerList] = useState(brokerList);

  useEffect(() => {
    _setEmployeeList([..._employeeList, ...employeeList]);
  }, [employeeList]);

  useEffect(() => {
    _setBrokerList([..._brokerList, ...brokerList]);
  }, [brokerList]);

  useEffect(() => {
    const langKey = {};

    if (!_isEmpty(language.secondary) && !errorTabs.secondary[language.secondary[0]]) {
      _forEach(language.secondary, _language => {
        langKey.secondary = {
          ...langKey.secondary,
          [_language]: {
            ...errorTabsInitialObj,
          },
        };
      });
    }
    setErrorTabs({ ...errorTabs, ...langKey });
  }, [language]);

  useEffect(() => {
    if (history?.location?.state?._tabKey) {
      const { _tabKey, _language, unitCode, shouldOpenDrawer } = history?.location?.state;
      navigateToIssueTab(_tabKey, _language, unitCode, shouldOpenDrawer);
    }
  }, []);

  const isEditView = !!id;

  const initialValues = {
    landInfo: {},
    status,
    translations: {},
  };

  const canEditProperty = permissionsUtil.checkAuth("property_edit");

  const mapTranslatedMedia = (_translations = {}) => {
    const translatedMedia = {};
    Object.keys(_translations).forEach(item => {
      translatedMedia[item] = {
        media: _get(_translations, `${item}.media`, {}),
      };
    });

    return translatedMedia;
  };

  useEffect(() => {
    if (!viewOnlyMode) {
      setEditTimestamp(dayjs().valueOf());
    }
    i18n.on("languageChanged", () => {
      // form.resetFields();
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        await dispatch(getAllCountryAddressFields());
        if (id) {
          await dispatch(getLandById(id));
          await dispatch(getAuditList(id));
          dispatch(getPropertyMedia({ id, propertyType: PROPERTY_TYPE_KEYS.land }));
        }
        const filterCriteriaBroker = { isActive: true };
        const filterCriteriaEmployee = { isActive: true, type: USER_TYPE.EMPLOYEE };
        const query = {
          ...SEARCH_QUERY,
          sort: "lastName,asc",
        };
        dispatch(getPropertyBrokers({ ...query, filterCriteria: filterCriteriaBroker }));
        dispatch(getPropertyEmployeeContacts({ ...query, filterCriteria: filterCriteriaEmployee }));
        if (FEATURE_FLAG.ESMCONTACT?.status) {
          const filterCriteriaESM = {
            isActive: true,
            type: USER_TYPE.ESM,
            jobTitle: USER_ESM_TYPE.JOB_TITLE,
          };

          dispatch(getPropertyESMContacts({ ...query, filterCriteria: filterCriteriaESM }));
        }
      } catch (ex) {
        console.log("ex", ex);
      } finally {
        setLoadingData(false);
      }
    })();
    return clearStoreState;
  }, [id, t]);

  useEffect(() => {
    const _property = selectedProperty || selected;
    if (isEditView && !_isEmpty(_property) && disabledStatus) {
      form.validateFields([["landInfo", "propertyCode"]]);
    }
  }, [form, isEditView, selectedProperty, selected]);

  useEffect(() => {
    (async () => {
      if (!_isEmpty(selected) && !loading && (!isPopulated || selected.isDraft)) {
        await setFormValues();
        if (history?.location?.state?.translateLang) {
          _onLanguageChange(history?.location?.state?.translateLang);
        } else {
          const payload = {};
          _forEach(language?.secondary, _lang => {
            if (_isEmpty(_get(selected, `translations.${_lang}`, {}))) {
              payload[_lang] = false;
            } else {
              payload[_lang] = true;
            }
          });
          setIsTranslated(payload);
        }
        setIsPopulated(true);
      }
    })();
  }, [selected, history, language.secondary]);

  useEffect(() => {
    if (!_isEmpty(countryAddressFields)) {
      setCountryAddressFormFields(form, countryAddressFields, "landInfo");
    }
  }, [countryAddressFields, form]);

  const auditButtonTitle =
    selected.status === PROPERTY_STATUS.published && !selected.isDraft && canAuditProperty
      ? t("Buttons.NewAuditIssue")
      : t("Buttons.ViewAuditIssue");
  const auditAlertMessage =
    (canAuditProperty && t("AddEditProperty.Audit.AuditorAlertMessage")) ||
    (canViewAuditIssues && t("AddEditProperty.Audit.EditorAlertMessage"));

  const showAuditPanelDrawer = () => {
    setAuditPanelDrawerVisible(true);
    if (activeTabKey === _tabs.auditIssues.key) setActiveTabKey(_tabs.landInfo.key);
  };

  const startAudit = async () => {
    try {
      const payload = {
        auditStatus: PROPERTY_AUDIT_STATUS.pending,
      };
      setLoadingData(true);
      await dispatch(updateLandAuditStatus(selected.landInfo.propertyCode, payload));
      setAuditPanelDrawerVisible(true);
      if (activeTabKey === _tabs.auditIssues.key) setActiveTabKey(_tabs.landInfo.key);
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setLoadingData(false);
    }
  };

  const clearStoreState = () => {
    dispatch(propertyCodesSuccess([]));
    dispatch(landSuccess({}));
    dispatch(landCodeSuccess({}));
    dispatch(propertyMediaSuccess([])); // to clear current state
    dispatch(propertyAddressFieldsListSuccess([]));
    dispatch(auditSuccess([]));
    dispatch(setLanguage());
    dispatch(getAllPropertyRollUpSuccess([]));
    if (FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess([]));
  };

  const fetchCountryAddressFields = args => {
    const country = _get(args, "country", "");
    const subMarkets = _get(args, "subMarkets", []);
    updateCountryAddressFields(country, allCountryAddressFields, fields => {
      setCountryAddressFields({ ...fields });
      if (!viewOnlyMode && FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess(subMarkets));
    });
  };

  /**
   * Function for open land publish modal on audit pass button
   */
  const openModalByAuditPass = () => {
    setIsCallByAudit(true);
    setLandPublishedModal(true);
  };

  const onCloseLandPublishedModal = () => {
    setLandPublishedModal(false);
    setIsCallByAudit(false);
  };

  const _updateFiles = (payload, _translations) => {
    const { hero = [], aerial = [], gallery = [], sitePlan = [], propertyMarketingCollateral = [] } = payload;

    if (!_isEmpty(hero)) setMediaErrors({});
    const translatedMedia = mapTranslatedMedia(_translations);
    _setFiles({ hero, aerial, gallery, sitePlan, propertyMarketingCollateral });
    _setTranslatedFiles(translatedMedia);
  };

  /**
   * Function for generating a payload
   * and invoking the API for creating and updating lands
   */
  const onFormFinish = async newStatus => {
    let values = { ...form.getFieldsValue(true) };
    const { contacts } = values;
    const { marketId, subMarketId, latitude, longitude } = values.landInfo;

    if (contacts?.prologisContact?.value) {
      contacts.prologisContact = contacts?.prologisContact?.value;
    }

    if (contacts.secondaryContacts?.length > 0) {
      contacts.secondaryContacts = [...contacts.secondaryContacts.map(_secondaryContact => _secondaryContact.value)];
    }

    contacts.esmContact = contacts?.esmContact?.value;

    values = {
      ...values,
      id: values.landInfo.propertyCode.value,
      status: newStatus,
      market: marketId,
      subMarket: subMarketId,
      latitude: Number(latitude),
      longitude: Number(longitude),
      isTemp: isTemporaryProperty,
      isDraft: false,
      landInfo: {
        ...values.landInfo,
        propertyCode: values?.landInfo?.propertyCode?.value,
        propertyType: PROPERTY_TYPE_KEYS.land,
        availableDate: values?.landInfo?.availableDate ? dayjs(values.landInfo.availableDate).valueOf() : undefined,
        landSize: values?.landInfo?.landSize ? +values?.landInfo?.landSize : undefined,
        minFutureBuildingSize: values?.landInfo?.minFutureBuildingSize
          ? +values?.landInfo?.minFutureBuildingSize
          : undefined,
        maxFutureBuildingSize: values?.landInfo?.maxFutureBuildingSize
          ? +values?.landInfo?.maxFutureBuildingSize
          : undefined,
        propertyRollUp: values?.landInfo?.propertyRollUp?.value,
      },
      contacts,
      media: mapMediaContentWithAlt(_files, false, form),
      editTimestamp,
    };

    if (!values?.landInfo?.availableDate) {
      delete values.landInfo.availableDate;
    }
    delete values.landInfo.marketId;
    delete values.landInfo.subMarketId;
    delete values.landInfo.latitude;
    delete values.landInfo.longitude;

    try {
      setLoadingData(true);
      if (isEditView && status === PROPERTY_STATUS.never_published && !selected.isDraft) {
        Object.keys(values.translations || {}).forEach(item => {
          values.translations[item].media = mapMediaContentWithAlt(_files, _translatedFiles[item]?.media, form, item);
          values.translations[item].media = removeMediaContent(values.translations[item].media);
        });

        await dispatch(addLand(values));
      } else {
        Object.keys(values.translations || {}).forEach(item => {
          values.translations[item].media = mapMediaContentWithAlt(_files, _translatedFiles[item]?.media, form, item);
          values.translations[item].media = removeMediaContent(values.translations[item].media);
        });

        const _payload = { ...values };
        delete _payload.createdAt;
        delete _payload.updatedAt;
        delete _payload.lastPublishDate;
        delete _payload.initialPublishDate;
        delete _payload.lastUnpublishDate;
        delete _payload.id;

        /**
         * If the user is an auditor and is performing the publish land,
         * then published and passed the audit otherwise simply published
         */
        if (isCallByAudit) {
          const auditPayload = {
            auditStatus: PROPERTY_AUDIT_STATUS.passed,
          };
          await dispatch(
            landPublishAndPassAudit(
              id,
              _payload,
              auditPayload,
              `${t("AddEditLand.Land")} ${newStatus} and ${t(
                "AddEditProperty.Audit.AuditStatusUiMapping.AuditPassed",
              )} ${t("AddEditLand.Successfully")}`,
            ),
          );
        } else {
          await dispatch(
            updateLand(id, _payload, `${t("AddEditLand.Land")} ${newStatus} ${t("AddEditLand.Successfully")}`),
          );
        }
      }
      history.push(ROUTES.PROPERTIES.path);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
      onCloseLandPublishedModal();
    }
  };

  /**
   * Function to pre-fill form fields with data from the form
   */
  const setFormValues = async () => {
    const _selected = _cloneDeep(selected);
    const landInfo = _get(_selected, "landInfo", {});
    const keyFeatures = _get(_selected, "keyFeatures", {});
    const contacts = { ..._selected?.contacts };
    const media = _get(_selected, "media", {});
    const date = _get(landInfo, "availableDate", undefined);
    const availableDate = date ? dayjs(date) : undefined;
    const _isTemp = _get(_selected, "isTemp", false);

    setIsTemporaryProperty(_isTemp);
    if (_isTemp && !viewOnlyMode) dispatch(getPropertyAllMarkets(_isTemp));

    if (!_isEmpty(contacts?.prologisContact)) {
      contacts.prologisContact = {
        ...contacts.prologisContact,
        value: contacts.prologisContact.id,
        label: contacts.prologisContact.displayName,
      };
    }

    if (contacts?.secondaryContacts?.length > 0) {
      contacts.secondaryContacts = [
        ...contacts.secondaryContacts.map(_secondaryContact => ({
          ..._secondaryContact,
          value: _secondaryContact.id,
          label: getContactLabel(_secondaryContact),
        })),
      ];
    }

    if (!_isEmpty(contacts?.esmContact)) {
      contacts.esmContact = {
        ...contacts.esmContact,
        value: contacts.esmContact.id,
        label: contacts.esmContact.displayName,
      };
    }

    setStatus(selected.status);

    _setFiles(media);
    _setTranslatedFiles(_selected.translations);

    form.setFieldsValue({
      propertyAvailable: selected?.propertyAvailable,
      landInfo: {
        ...landInfo,
        marketId: selected?.market?.id,
        region: selected?.market?.region?.code,
        subMarketId: typeof selected?.subMarket === "object" ? selected?.subMarket?.id : selected?.subMarket,
        propertyRollUp: selected?.landInfo?.propertyRollUp?.id
          ? {
            value: selected?.landInfo?.propertyRollUp?.id,
            label: selected?.landInfo?.propertyRollUp?.id,
          }
          : undefined,
        latitude: selected?.latitude === 0 ? undefined : selected?.latitude,
        longitude: selected?.longitude === 0 ? undefined : selected?.longitude,
        propertyCode: { label: landInfo.propertyCode, value: landInfo.propertyCode },
        availableDate,
        landSizeUOM: filterOption(LAND_MEASUREMENT_UNITS, landInfo?.landSizeUOM),
        minFutureBuildingSizeUOM: filterOption(MEASUREMENT_UNITS, landInfo?.minFutureBuildingSizeUOM),
        maxFutureBuildingSizeUOM: filterOption(MEASUREMENT_UNITS, landInfo?.maxFutureBuildingSizeUOM),
        templateId: filterOption(marketingDescriptionTemplateList, landInfo?.templateId),
      },
      keyFeatures: {
        ...keyFeatures,
        templateFeature1: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature1),
        templateFeature2: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature2),
        templateFeature3: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature3),
        templateFeature4: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature4),
        templateFeature5: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature5),
        templateFeature6: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature6),
      },
      contacts: {
        prologisContact: contacts?.prologisContact?.isActive ? contacts.prologisContact : undefined,
        secondaryContacts: contacts.secondaryContacts?.filter(_secondaryContact => _secondaryContact.isActive),
        esmContact: contacts?.esmContact?.isActive ? contacts.esmContact : undefined,
      },
      translations: { ..._selected.translations },
      media: { ...media },
    });
    fetchCountryAddressFields(_selected?.market);
  };

  const langModalTitle = t("AddEditProperty.LangModalTitle");

  const getTemplate = (templateList, country, templateId) => {
    const selectedTemplate = templateList.find(item => item.id === templateId);
    return _get(
      selectedTemplate,
      `translations.${country === "Mexico" ? LANGUAGE_CODES.Mexico.value : language.current}.template`,
      selectedTemplate?.template,
    );
  };

  const copyLocalizeFields = lang => {
    const formValues = form.getFieldsValue(true);
    let marketingDescription;
    const templateFeatures = {};
    const country = form.getFieldValue(["landInfo", "address", "country"]);
    if (formValues?.landInfo?.templateId) {
      marketingDescription = getTemplate(marketingDescriptionTemplateList, country, formValues?.landInfo?.templateId);
    }

    _forEach(PROPERTY_KEY_FEATURE_TEMPLATE_KEYS, _value => {
      templateFeatures[_value.fieldKey] =
        getTemplate(keyFeatureTemplateList, country, formValues?.keyFeatures?.[_value.templateKey]) ||
        formValues?.keyFeatures?.[_value.fieldKey];
    });

    form.setFieldsValue({
      translations: {
        [lang]: {
          landInfo: {
            address: {
              streetAddress1: _get(formValues, "landInfo.address.streetAddress1", ""),
              streetAddress2: _get(formValues, "landInfo.address.streetAddress2", ""),
            },
            marketingDescription: marketingDescription || formValues?.landInfo?.marketingDescription,
            marketingPropertyName: formValues.landInfo.marketingPropertyName,
            digitalTourUrl: formValues.landInfo.digitalTourUrl,
            videoUrl: formValues.landInfo.videoUrl,
            micrositeUrl: formValues.landInfo.micrositeUrl,
          },
          keyFeatures: {
            ...formValues.keyFeatures,
            ...templateFeatures,
          },
        },
      },
    });
    setIsTranslated({ ...isTranslated, [lang]: true });
  };

  const updateErrorState = prevLang => {
    form.validateFields(["landInfo", "propertyCode"]);
    const hasErrors = getFieldsError(_tabsKey[activeTabKey], prevLang);
    let _state = {};

    if (prevLang === LANGUAGE_CODES.English.value) {
      _state = {
        ...errorTabs,
        primary: { ...errorTabs.primary, [_tabsKey[activeTabKey]]: !!hasErrors },
      };
    } else {
      _state = {
        ...errorTabs,
        secondary: {
          ...errorTabs.secondary,
          [prevLang]: {
            ...errorTabs.secondary[prevLang],
            [_tabsKey[activeTabKey]]: !!hasErrors,
          },
        },
      };
    }
    setErrorTabs(_state);
    return _state;
  };

  const checkTabError = (lang, prevLang) => {
    const _state = updateErrorState(prevLang);
    onTabChange(activeTabKey, lang, _state);
  };

  const _onLanguageChange = (lang, prevLang) => {
    if (!isTranslated[lang] && lang !== LANGUAGE_CODES.English.value) {
      if (viewOnlyMode) {
        setIsLangModalOpen(true);
      } else {
        copyLocalizeFields(lang);
      }
    }
    checkTabError(lang, prevLang);
  };

  const onSelectedPropertyChange = async params => {
    try {
      setLoadingData(true);
      dispatch(landCodeSuccess({}));
      setCountryAddressFields({});
      const _property = await dispatch(getPropertyCodes({ ...SEARCH_QUERY, id: params.value }));
      dispatch(getPropertyMedia({ id: params.value, propertyType: PROPERTY_TYPE_KEYS.land }));

      const { ...rest } = _property;

      if (!viewOnlyMode && !isEditView) setStatus(_property.status || PROPERTY_STATUS.never_published);

      form.setFieldsValue({
        landInfo: {
          propertyCode: params,
          marketId: _property?.market?.id,
          region: _property?.market?.region?.code,
          subMarketId: typeof _property?.subMarket === "object" ? _property?.subMarket?.id : _property?.subMarket,
          latitude: _property?.latitude,
          longitude: _property?.longitude,
          marketingPropertyName: _property?.propertyInfo?.marketingPropertyName,
          address: {
            streetAddress1: _property?.address?.streetAddress1,
            streetAddress2: _property?.address?.streetAddress2,
            postalCode: _property?.address?.zipCode,
            city: _property?.address?.city,
            country: undefined,
          },
        },
      });
      dispatch(landCodeSuccess({ ...rest }));
      _setFiles({});
      _setTranslatedFiles({});
      if (_property?.market) fetchCountryAddressFields(_property?.market);
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      dispatch(setLanguage());
      setIsTranslated(false);
      setIsPopulated(false);
      setLoadingData(false);
    }
  };

  /**
   * Function for producing a payload
   * and changing the statuses of the lands to Draft and Unpublished
   */
  const saveForLater = (_status, isDraft) => {
    const keysToValidate = [
      ["landInfo", "propertyCode"],
      ["landInfo", "marketId"],
    ];

    if (FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status) {
      keysToValidate.push(["draftComments"]);
    }

    if (!_isEmpty(propertyCodeError)) {
      setActiveTabKey(_tabs.landInfo.key);
      return toastMessage("error", propertyCodeError.help || t("ErrorMessages.SelectPropertyCode"));
    }
    setLoadingData(true);
    return form
      .validateFields(keysToValidate)
      .then(async () => {
        let values = { ...form.getFieldsValue(true) };
        const { contacts } = values;
        const { marketId, subMarketId, latitude, longitude } = values.landInfo;

        if (contacts?.prologisContact?.value) {
          contacts.prologisContact = contacts?.prologisContact?.value;
        }

        if (contacts.secondaryContacts?.length > 0) {
          contacts.secondaryContacts = [
            ...contacts.secondaryContacts.map(_secondaryContact => _secondaryContact.value),
          ];
        }

        if (contacts?.esmContact?.value) {
          contacts.esmContact = contacts?.esmContact?.value;
        }

        values = {
          ...values,
          translations: checkEmptyObj(values.translations),
          id: values?.landInfo?.propertyCode?.value,
          status: _status,
          market: marketId,
          subMarket: subMarketId,
          contacts,
          latitude: latitude ? Number(latitude) : 0,
          longitude: longitude ? Number(longitude) : 0,
          isTemp: isTemporaryProperty,
          isDraft: !!isDraft,
          ...(FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status && isDraft && { draftComments: values?.draftComments }),
          landInfo: {
            ...values.landInfo,
            propertyCode: values?.landInfo?.propertyCode?.value,
            propertyType: PROPERTY_TYPE_KEYS.land,
            availableDate: values?.landInfo?.availableDate ? dayjs(values.landInfo.availableDate).valueOf() : undefined,
            landSize: values?.landInfo?.landSize ? +values?.landInfo?.landSize : undefined,
            minFutureBuildingSize: values?.landInfo?.minFutureBuildingSize
              ? +values?.landInfo?.minFutureBuildingSize
              : undefined,
            maxFutureBuildingSize: values?.landInfo?.maxFutureBuildingSize
              ? +values?.landInfo?.maxFutureBuildingSize
              : undefined,
            propertyRollUp: values?.landInfo?.propertyRollUp?.value,
          },
          media: mapMediaContentWithAlt(_files, false, form),
          editTimestamp,
        };

        if (!values?.landInfo?.availableDate) {
          delete values.landInfo.availableDate;
        }
        delete values.landInfo.marketId;
        delete values.landInfo.subMarketId;
        delete values.landInfo.latitude;
        delete values.landInfo.longitude;

        try {
          if (isEditView && status === PROPERTY_STATUS.never_published && !selected.isDraft) {
            Object.keys(values.translations || {}).forEach(item => {
              values.translations[item].media = mapMediaContentWithAlt(
                _files,
                _translatedFiles[item]?.media,
                form,
                item,
              );
              values.translations[item].media = removeMediaContent(values.translations[item].media);
            });
            await dispatch(addLand(values));
          } else {
            Object.keys(values.translations || {}).forEach(item => {
              values.translations[item].media = mapMediaContentWithAlt(
                _files,
                _translatedFiles[item]?.media,
                form,
                item,
              );
              values.translations[item].media = removeMediaContent(values.translations[item].media);
            });
            const _payload = { ...values };
            delete _payload.createdAt;
            delete _payload.updatedAt;
            delete _payload.lastPublishDate;
            delete _payload.initialPublishDate;
            delete _payload.lastUnpublishDate;
            delete _payload.id;

            await dispatch(updateLand(id, _payload));
            setDraftCommentsModal(false);
          }
          if (!values.isDraft) {
            history.push(ROUTES.PROPERTIES.path);
          } else {
            await dispatch(getLandById(id));
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        const currentTabHasError = errorFields?.some(({ name }) => name.indexOf(_tabsKey[activeTabKey]) > -1);
        const _tabKey = _tabs?.[errorFields?.[0]?.name[0]]?.key;
        if (!currentTabHasError) setActiveTabKey(_tabKey);

        toastMessage("error", errorFields?.[0]?.errors?.[0] || t("ErrorMessages.SelectPropertyCode"));
      })
      .finally(() => setLoadingData(false));
  };

  const saveAndPublish = newStatus => {
    form
      .validateFields()
      .then(async () => {
        const isSitePlanError =
          (!_isEmpty(_files.sitePlan) &&
            _some(language.secondary, _lang => _isEmpty(_get(_translatedFiles, `${_lang}.media.sitePlan`, {})))) ||
          (_isEmpty(_files.sitePlan) &&
            _some(language.secondary, _lang => !_isEmpty(_get(_translatedFiles, `${_lang}.media.sitePlan`, {}))));
        const isPropertyMarketingCollateralError =
          (!_isEmpty(_files.propertyMarketingCollateral) &&
            _some(
              language.secondary,
              _lang =>
                _files.propertyMarketingCollateral.length !==
                removedEmptyObject(_get(_translatedFiles, `${_lang}.media.propertyMarketingCollateral`, {})).length,
            )) ||
          (_isEmpty(_files.propertyMarketingCollateral) &&
            _some(
              language.secondary,
              _lang =>
                _files.propertyMarketingCollateral.length !==
                removedEmptyObject(_get(_translatedFiles, `${_lang}.media.propertyMarketingCollateral`, {})).length,
            ));
        if (!_isEmpty(propertyCodeError)) {
          setActiveTabKey(_tabs.landInfo.key);
          toastMessage("error", propertyCodeError.help || t("ErrorMessages.SelectPropertyCode"));
        } else if (_isEmpty(_files.hero)) {
          setActiveTabKey(_tabs.media.key);
          setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });
        } else if (language.secondary && (isSitePlanError || isPropertyMarketingCollateralError)) {
          const _mediaErrors = {};
          if (isSitePlanError) {
            _mediaErrors.sitePlan = "error";
          }
          if (isPropertyMarketingCollateralError) {
            _mediaErrors.propertyMarketingCollateral = "error";
          }
          setMediaErrors(_mediaErrors);
          setActiveTabKey(_tabs.media.key);
          toastMessage("error", t("ErrorMessages.SelectBothTranslatedAndLocal"));
        } else {
          await onFormFinish(newStatus);
          setMediaErrors({});
        }
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        const currentTabHasError = errorFields?.some(({ name }) => name.indexOf(_tabsKey[activeTabKey]) > -1);
        const _tabKey = _tabs?.[errorFields?.[0]?.name[0]]?.key;
        if (!currentTabHasError) setActiveTabKey(_tabKey);

        if (_isEmpty(_files.hero)) setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });

        toastMessage("error", t("ErrorMessages.RequiredFields"));
      })
      .finally(() => {
        togglePopupVisibility(false);
        onCloseLandPublishedModal();
      });
  };

  const _onCancel = () => setIsDifferenceModalOpen(false);

  const _onSuccess = values => {
    setIsDifferenceModalOpen(false);
    const obj = {};
    if (!_isEmpty(selectedValues)) {
      Object.entries(selectedValues).forEach(([key, value]) => {
        const _value = values[key] || _current[key];
        obj[key] = _value;
      });
    }
    Object.entries(values).forEach(([key, value]) => {
      const _value = value;
      obj[key] = _value;
    });
    form.setFieldsValue({
      landInfo: {
        ...obj,
      },
    });

    setSelectedValues({});
  };

  const _source = useMemo(() => {
    const obj = {};
    if (!_isEmpty(selectedProperty)) {
      if (FEATURE_FLAG.SUBMARKET?.status) {
        obj.subMarketId = {
          label: t("Property.Submarket"),
          value: selectedProperty?.subMarket,
        };
      }
      obj.latitude = {
        label: t("Property.Latitude"),
        value: selectedProperty?.latitude,
      };
      obj.longitude = {
        label: t("Property.Longitude"),
        value: selectedProperty?.longitude,
      };
      obj.marketId = {
        label: t("Property.Market"),
        value: selectedProperty?.market?.id,
      };
      obj.marketingPropertyName = {
        label: t("Property.MarketingPropertyName"),
        value: selectedProperty?.propertyInfo?.marketingPropertyName,
      };
    }
    return obj;
  }, [selectedProperty]);

  const _current = useMemo(() => {
    const obj = {};
    if (!_isEmpty(selected)) {
      if (FEATURE_FLAG.SUBMARKET?.status) {
        obj.subMarketId = typeof selected?.subMarket === "object" ? selected?.subMarket?.id : selected?.subMarket;
      }
      obj.latitude = selected?.latitude;
      obj.longitude = selected?.longitude;
      obj.marketId = selected?.market?.id;
      obj.marketingPropertyName = selected?.landInfo?.marketingPropertyName;
    }
    return obj;
  }, [selected]);

  const _disabled =
    viewOnlyMode ||
    _isEmpty(selectedProperty) ||
    _isEmpty(selected) ||
    (selected?.status === PROPERTY_STATUS.never_published && !selected.isDraft) ||
    isTemporaryProperty;

  const isStartAuditVisible =
    canAuditProperty &&
    selected.auditStatus !== PROPERTY_AUDIT_STATUS.pending &&
    selected.status === PROPERTY_STATUS.published &&
    !selected.isDraft;

  const getPopupTitle = () => {
    let _titles;
    if (!_isEmpty(language.secondary) && selectedProperty?.isUnmarket) {
      _titles = `${t("AddEditProperty.ConfirmPropertyLocalized")}
        ${t("AddEditProperty.LandUnMarketedPropertyLocalized")}`;
    } else if (!_isEmpty(language.secondary)) {
      _titles = t("AddEditProperty.ConfirmPropertyLocalized");
    } else if (selectedProperty?.isUnmarket) {
      _titles = t("AddEditProperty.LandUnMarketedPropertyLocalized");
    } else {
      _titles = t("AddEditLand.ConfirmLand");
    }
    return _titles;
  };

  const _onDraftCommentsModalClose = () => {
    setDraftCommentsModal(false);
  };

  const _onDraftCommentsModalOpen = () => {
    if (selected?.isDraft) {
      form.setFieldsValue({ draftComments: selected?.draftComments });
    }
    setDraftCommentsModal(true);
  };

  const _renderStatus = () => {
    const isPublishDisabled = disabledStatus || _isEmpty(_files.hero) || loadingMedia;

    return (
      <Row className="fields-row" gutter={24} justify="center">
        <Col span={8} xs={24} sm={24} lg={24}>
          <Row className="fields-row tab-btn-actions row-vertical-padding-4" gutter={24} type="flex">
            {activeTabKey === _tabs.landInfo.key && !viewOnlyMode && (
              <>
                <Button
                  disabled={_disabled}
                  shape="circle"
                  type="primary"
                  icon={<CustomIcon name="SyncOutlined" />}
                  onClick={() => setIsDifferenceModalOpen(true)}
                />
              </>
            )}
            {!viewOnlyMode && (
              <Button
                type="info"
                disabled={isPublishDisabled}
                className="btn-small"
                onClick={() => setIsPreviewActive(true)}
              >
                {t("Buttons.Preview")}
              </Button>
            )}
            {!viewOnlyMode && (
              <Button type="info" onClick={() => history.replace(ROUTES.PROPERTIES.path)} className="btn-small">
                {t("Buttons.Discard")}
              </Button>
            )}

            {viewOnlyMode && canEditProperty && (
              <Link to={{ pathname: `${ROUTES.EDIT_LAND.path}/${id}`, state: { activeTabKey } }}>
                <Button type="primary" className="btn-icon" icon={<CustomIcon name="EditFilled" />}>
                  {t("Buttons.Edit")}
                </Button>
              </Link>
            )}
            {!viewOnlyMode && (
              <>
                {FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status ? (
                  <Button type="info" onClick={_onDraftCommentsModalOpen} className="btn-small">
                    {t("Buttons.SaveForLater")}
                  </Button>
                ) : (
                  <Button type="info" onClick={() => saveForLater(selected?.status, true)} className="btn-small">
                    {t("Buttons.SaveForLater")}
                  </Button>
                )}
              </>
            )}
            {!viewOnlyMode && (
              <Button onClick={() => saveForLater(PROPERTY_STATUS.unpublished)} type="primary">
                {t("Buttons.Unpublish")}
              </Button>
            )}
            {!viewOnlyMode && (
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <PopUp
                    overlayClassName="add-edit-land__publish-pop-up"
                    title={getPopupTitle()}
                    onConfirm={() => saveAndPublish(PROPERTY_STATUS.published)}
                    okText={t("Buttons.Yes")}
                    cancelText={t("Buttons.No")}
                    disabled={isPublishDisabled}
                    popupVisible={popupVisible}
                    onVisibleChange={() => togglePopupVisibility(!popupVisible)}
                  >
                    <Button disabled={disabledStatus || _isEmpty(_files.hero) || loadingMedia} type="primary">
                      {t("Buttons.Publish")}
                    </Button>
                  </PopUp>
                )}
              </Form.Item>
            )}
            {!viewOnlyMode && (
              <>
                {isStartAuditVisible ? (
                  <Button type="primary" onClick={startAudit}>
                    {t("Buttons.StartAudit")}
                  </Button>
                ) : (
                  <Button type="primary" onClick={showAuditPanelDrawer}>
                    {auditButtonTitle}
                  </Button>
                )}
              </>
            )}
          </Row>
          {!viewOnlyMode && (
            <Row justify="end" className="tab-btn-actions">
              <Col>
                {isAsianCountry(form, "landInfo") && (
                  <Form.Item
                    tooltip={getTooltip(helperText.propertyAvailable, language)}
                    valuePropName="checked"
                    label={t("Property.PropertyAvailable")}
                    name="propertyAvailable"
                    className="property-available-switch"
                  >
                    <Switch disabled={viewOnlyMode || isPublishDisabled} />
                  </Form.Item>
                )}
              </Col>
              {LANDMARKET?.status && selectedProperty?.isUnmarket && (
                <Col className="mg-right-8 mg-left-8">{wrapUnmarketInTag()}</Col>
              )}
              <Col className="text-right">
                {t("AddEditProperty.Audit.AuditIssues")}
                :&nbsp;
                {auditList.length}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const _onCloseLangModal = () => {
    setIsLangModalOpen(false);
    dispatch(setLanguage({ current: LANGUAGE_CODES.English.value, secondary: language.secondary }));
  };

  const onLandPublishAndAuditPass = () => saveAndPublish(PROPERTY_STATUS.published);

  const _onClickTranslate = () => {
    history.push({ pathname: `${ROUTES.EDIT_LAND.path}/${id}`, state: { translateLang: language.current } });
  };

  let _title = isEditView ? `${t("AddEditLand.EditLand")} ${id}` : t("AddEditLand.AddLand");
  if (viewOnlyMode) _title = `${t("AddEditLand.ViewLand")} ${id}`;

  const _validateFields = (field, allFields) => {
    const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length).length;
    let _disabledStatus = false;
    const untouchFields = allFields
      .filter(({ value }) => value === "" || value === undefined || value === null || value === "NaN")
      .map(({ name }) => name);
    const mediaFieldsError = allFields.some(
      ({ value, name }) =>
        (name.includes("media") && !_isNumber(value) && !_isBoolean(value) && _isEmpty(value)) || value === "NaN",
    );

    if (mediaFieldsError) {
      _disabledStatus = true;
    }

    if (hasErrors) {
      _disabledStatus = true;
    } else {
      const _requiredFields = [...requiredFields];
      _requiredFields.push(...requiredTranslationFields);

      for (let index = 0; index < untouchFields.length; index += 1) {
        const _err = _requiredFields.some(val =>
          val[0] !== "translations"
            ? val[0] === untouchFields[index][0] && val[1] === untouchFields[index][untouchFields[index].length - 1]
            : val[1] === untouchFields[index][2] &&
              val[val.length - 1] === untouchFields[index][untouchFields[index].length - 1],
        );
        if (_err) {
          _disabledStatus = true;
          break;
        }
      }
    }

    setDisabledStatus(_disabledStatus);
  };

  const getFieldsError = (key, _lang) =>
    form
      .getFieldsError()
      .filter(
        ({ name, errors }) =>
          (_lang === LANGUAGE_CODES.English.value ? name[0] === key : name[1] === _lang && name[2] === key) &&
          errors.length,
      ).length;

  const getKeysToValidate = (_lang, key, fields) => {
    const _requiredFields = [...fields];

    if (_lang === LANGUAGE_CODES.English.value) {
      return [..._requiredFields.filter(val => val[0] === key)];
    }
    _requiredFields.push(...requiredTranslationFields);

    return _reduce(
      _requiredFields,
      (prev, curr) => {
        if (curr[0] === key && _lang === LANGUAGE_CODES.English.value) {
          prev.push(curr);
        } else if (curr[1] === key) {
          prev.push([...curr.slice(0, 1), _lang, ...curr.slice(1)]);
        } else if (curr[2] === key) {
          prev.push(curr);
        }
        return prev;
      },
      [],
    );
  };

  const onTabChange = async (acKey, lang, _state) => {
    if (!viewOnlyMode) {
      const _lang = lang || language.current;
      const _errorTabs = _state || errorTabs;
      let err = true;
      const key = _tabsKey[activeTabKey];
      // return (;
      const hasErrors = getFieldsError(key, _lang);
      const _requiredFields = [...requiredFields];

      if (isUSProperty(selected.market)) {
        _requiredFields.push(["landInfo", "address", "state"], ["landInfo", "address", "city"]);
      }

      let keysToValidate = [];
      if (key === _tabsKey[4]) {
        const mediaFields = form.getFieldsValue().media || {};
        const mediaFieldsToValidate = [];
        Object.keys(mediaFields).forEach(mediaFieldType => {
          _forEach(mediaFields[mediaFieldType], (field, i) => {
            const mediaField = [];
            if (_lang !== LANGUAGE_CODES.English.value) {
              mediaField.push(["translations", _lang, "media", mediaFieldType, i, "alt_text"]);
            } else {
              mediaField.push(["media", mediaFieldType, i, "alt_text"]);
            }
            mediaFieldsToValidate.push(...mediaField);
          });
        });

        if (_isEmpty(_files.hero)) {
          setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });
        } else if (mediaFieldsToValidate.length) {
          keysToValidate = getKeysToValidate(_lang, key, [..._requiredFields, ...mediaFieldsToValidate]);
        } else {
          err = false;
        }
      } else {
        keysToValidate = getKeysToValidate(_lang, key, _requiredFields);
      }

      if ((key !== _tabsKey[4] && !hasErrors) || (key === _tabsKey[4] && !_isEmpty(_files.hero) && !hasErrors)) {
        const [error] = await to(form.validateFields(keysToValidate));
        if (!error) err = false;
      }

      let errorPayload = { ..._errorTabs };

      if (languageStatus === "primary") {
        errorPayload = {
          ...errorPayload,
          [languageStatus]: { ..._errorTabs[languageStatus], [key]: err },
          [key]: err,
        };
      } else {
        errorPayload = {
          ...errorPayload,
          secondary: {
            ...errorPayload.secondary,
            [_lang]: { ..._errorTabs.secondary[_lang], [key]: err },
          },
          [key]: err,
        };
      }

      setErrorTabs(errorPayload);
    }
    setActiveTabKey(acKey);
    window.location.hash = _tabsKey[acKey];
  };

  const _renderTabTitle = _id => {
    const hasError =
      languageStatus === "primary"
        ? errorTabs[languageStatus]?.[_id]
        : errorTabs[languageStatus]?.[language.current]?.[_id];
    return <span className={hasError ? "tab-has-error" : ""}>{_tabs[_id].tabTitle}</span>;
  };

  const disableLatLng = !!(
    selected.latitude ||
    selected.longitude ||
    selectedProperty.latitude ||
    selectedProperty.longitude
  );

  const helperText = {
    hero: lookupList[HELPER_TEXT_KEYS.land.hero],
    aerial: lookupList[HELPER_TEXT_KEYS.land.aerial],
    sitePlan: lookupList[HELPER_TEXT_KEYS.land.sitePlan],
    marketingCollateral: lookupList[HELPER_TEXT_KEYS.land.propertyMarketingCollateral],
    imageGallery: lookupList[HELPER_TEXT_KEYS.land.imageGallery],
    propertyAvailable: lookupList[HELPER_TEXT_KEYS.propertyAvailable],
  };

  const contactsHelperText = {
    prologisContact: lookupList[HELPER_TEXT_KEYS.land.prologisContact],
    secondaryContacts: lookupList[HELPER_TEXT_KEYS.land.secondaryContacts],
    esmContact: lookupList[HELPER_TEXT_KEYS.land.esmContact],
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky topOffset={-60}>
      {({ style, isSticky }) => (
        <div
          className={`custom-sticky-tab-bar ${selected?.isDraft ? "isDraft" : ""}`}
          style={{
            ...style,
            marginTop: isSticky ? 56 : 0,
          }}
        >
          {auditPanelDrawerVisible && (
            <Row className="row-vertical-padding-4">
              <Col span={19}>
                {!viewOnlyMode && <Alert message={auditAlertMessage} type="info" showIcon closable />}
              </Col>
            </Row>
          )}

          {FEATURE_FLAG.PROPERTY_DRAFT_COMMENT.status && selected?.isDraft && selected?.draftComments && (
            <Collapse defaultActiveKey={["1"]} className="ant-collapse-warning">
              <Panel header={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.land })} key="1">
                <p>{selected?.draftComments}</p>
              </Panel>
            </Collapse>
          )}

          <LanguageSelect
            form={form}
            propertyType="landInfo"
            errorTabs={errorTabs}
            isTranslated={isTranslated}
            onLanguageChange={_onLanguageChange}
          />
          <PageTitle
            title={_title}
            items={[ROUTES.PROPERTIES, isEditView ? { title: id } : ROUTES.ADD_LAND]}
            propertyStatus={renderPropertyStatusWithModifiedDate(selected, isTemporaryProperty)}
          />

          <DefaultTabBar {...props} />
        </div>
      )}
    </Sticky>
  );

  const _renderAuditTabTitle = (_id, isDisabled) => (
    <span className={isDisabled ? "disable-tab" : ""}>
      {_tabs[_id].tabTitle}
      &nbsp;
      {auditCountByStatus > 0 ? (
        <Button type="primary" shape="circle">
          {auditCountByStatus}
        </Button>
      ) : (
        <></>
      )}
    </span>
  );

  const auditCountByStatusDisplay = count => {
    setAuditCountByStatus(count);
  };

  const navigateToIssueTab = (_tabKey, _language, unitCode, shouldOpenDrawer) => {
    const _state = updateErrorState(language.current);
    onTabChange(_tabKey, _language, _state);
    const payload = {
      ...language,
      current: _language,
    };

    if (shouldOpenDrawer) {
      setAuditPanelDrawerVisible(shouldOpenDrawer);
    }
    dispatch(setLanguage(payload));
  };

  const handleNavigateToIssueTab = async (_tabKey, _language, unitCode, shouldOpenDrawer) => {
    if (viewOnlyMode && canEditProperty) {
      history.push({
        pathname: `${ROUTES.EDIT_LAND.path}/${id}`,
        state: { _tabKey, _language, unitCode, shouldOpenDrawer },
      });
    } else {
      navigateToIssueTab(_tabKey, _language, unitCode, shouldOpenDrawer);
    }
  };

  return (
    <StickyContainer>
      {loadingData ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <AuditDrawer
        viewOnlyMode={viewOnlyMode}
        propertyCode={selected.landInfo?.propertyCode}
        auditList={auditList}
        auditPanelDrawerVisible={auditPanelDrawerVisible}
        setAuditPanelDrawerVisible={setAuditPanelDrawerVisible}
        navigateToIssueTab={handleNavigateToIssueTab}
        propertyType={selected.landInfo?.propertyType}
        openModalByAuditPass={openModalByAuditPass}
      />
      <Form
        form={form}
        className="add-edit-land"
        layout="vertical"
        name="nest-messages"
        onFieldsChange={_validateFields}
        initialValues={initialValues}
        validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      >
        <Tabs
          activeKey={activeTabKey}
          className="tabs__container"
          onChange={onTabChange}
          tabBarExtraContent={_renderStatus()}
          renderTabBar={renderTabBar}
        >
          <TabPane tab={_renderTabTitle(_tabsKey[1])} key={_tabs.landInfo.key} forceRender>
            <LandInfo
              templateList={marketingDescriptionTemplateList}
              disableLatLng={disableLatLng}
              selectedProperty={selectedProperty}
              propertyCodes={propertyCodes}
              setSelectedProperty={onSelectedPropertyChange}
              form={form}
              isEditView={isEditView}
              viewOnlyMode={viewOnlyMode}
              isTemporaryProperty={isTemporaryProperty}
              setIsTemporaryProperty={setIsTemporaryProperty}
              propertyCodeError={propertyCodeError}
              setPropertyCodeError={setPropertyCodeError}
              fetchCountryAddressFields={fetchCountryAddressFields}
              countryAddressFields={countryAddressFields}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          </TabPane>
          <TabPane tab={_renderTabTitle(_tabsKey[2])} key={_tabs.keyFeatures.key} forceRender>
            <KeyFeatures
              viewOnlyMode={viewOnlyMode}
              form={form}
              templateList={keyFeatureTemplateList}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
            />
          </TabPane>
          <TabPane tab={_renderTabTitle(_tabsKey[3])} key={_tabs.contacts.key} forceRender>
            <Contacts
              form={form}
              viewOnlyMode={viewOnlyMode}
              helperText={contactsHelperText}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
              propertyType={PROPERTY_TYPE_KEYS.land}
            />
          </TabPane>
          <TabPane tab={_renderTabTitle(_tabsKey[4])} key={_tabs.media.key} forceRender>
            <PropertyMedia
              helperText={helperText}
              marketingCollateralLabel={t("Property.LandMarketingCollateral")}
              mediaErrors={mediaErrors}
              updateFiles={_updateFiles}
              isEditView={isEditView}
              selectedPropertyCode={selectedProperty.id}
              propertyMedia={selectedPropertyMedia}
              translatedSelectedMedia={_translatedFiles}
              selectedMedia={_files}
              viewOnlyMode={viewOnlyMode}
              loading={loadingMedia}
              language={language}
              languageStatus={languageStatus}
              form={form}
              auditPanelDrawerVisible={auditPanelDrawerVisible}
              propertyType={PROPERTY_TYPE_KEYS.land}
            />
          </TabPane>
          <TabPane
            tab={_renderAuditTabTitle(_tabsKey[5], auditPanelDrawerVisible)}
            key={_tabs.auditIssues.key}
            forceRender
            disabled={auditPanelDrawerVisible}
          >
            <AuditIssues
              auditCountByStatus={auditCountByStatusDisplay}
              navigateToIssueTab={handleNavigateToIssueTab}
              mainForm={form}
              propertyType={PROPERTY_TYPE_KEYS.land}
            />
          </TabPane>
        </Tabs>
      </Form>
      <AppModal
        title={t("AddEditLand.SyncLatestChanges")}
        width={900}
        visible={isDifferenceModalOpen}
        onCancel={_onCancel}
        footer={null}
        destroyOnClose
      >
        <TransferTable
          selectedValues={selectedValues}
          source={_source}
          current={_current}
          onSuccess={_onSuccess}
          onCancel={_onCancel}
        />
      </AppModal>
      <AppModal
        className="property-preview-modal"
        title={t("PropertyPreview.PropertyPreview")}
        width="100%"
        visible={isPreviewActive}
        onCancel={() => setIsPreviewActive(false)}
        overFlowY="auto"
        footer={null}
        destroyOnClose
        isNotDraggable
      >
        <PropertySearchPreview
          form={form}
          selected={selected}
          media={_files}
          translatedMedia={_translatedFiles}
          propertyType="land"
          employeeList={_employeeList}
          brokerList={_brokerList}
        />
      </AppModal>
      <AppModal
        title={t("AddEditProperty.Audit.UpdateStatusConfirmation")}
        width={700}
        minHeight="15vh"
        visible={landPublishedModal}
        onCancel={onCloseLandPublishedModal}
        overFlowY="auto"
        destroyOnClose
        centered
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={onCloseLandPublishedModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={onLandPublishAndAuditPass} loading={loading}>
                {t("Buttons.Publish")}
              </Button>
            </Col>
          </Row>
        }
      >
        <p>{getPopupTitle()}</p>
      </AppModal>
      <LangModal
        isVisible={isLangModalOpen}
        title={langModalTitle}
        onClose={_onCloseLangModal}
        onTranslate={_onClickTranslate}
      />
      <AppModal
        title={t("DraftComments.ModalTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.land })}
        width="700px"
        visible={draftCommentsModal}
        onCancel={_onDraftCommentsModalClose}
        destroyOnClose
        className="draft-comment"
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={10} lg={10}>
              <Button block type="primary" loading={loading} onClick={() => saveForLater(selected?.status, true)}>
                {t("Buttons.Save")}
              </Button>
            </Col>
          </Row>
        }
      >
        <DraftComments form={form} />
      </AppModal>
    </StickyContainer>
  );
};

AddEditLand.propTypes = {
  componentProps: PropTypes.shape({
    viewOnlyMode: PropTypes.bool,
  }),
};

AddEditLand.defaultProps = {
  componentProps: {},
};

export default AddEditLand;
