import { Button, Input, Space } from "antd";
import PropTypes from "prop-types";
import React, { useRef } from "react";

const TableSearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, dataIndex, placeholder }) => {
  const searchInput = useRef();

  const handleSearch = () => {
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    clearFilters();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={handleSearch}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button type="primary" onClick={handleSearch} size="small">
          Search
        </Button>
        <Button onClick={handleReset} size="small">
          Reset
        </Button>
      </Space>
    </div>
  );
};

TableSearchFilter.propTypes = {
  setSelectedKeys: PropTypes.func,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  selectedKeys: PropTypes.array,
  dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
};

export default TableSearchFilter;
