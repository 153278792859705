import landVacancyService from "../../services/landVacancy.service";

import { ERROR_MESSAGE, PAGINATOIN, SUCCESS_MESSAGE, TAG } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  LAND_VACANCY_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function landVacancyRequest(key = LOADING.LAND_VACANCY_LOADING) {
  return { type: ACTIONS.LAND_VACANCY_REQUEST, key };
}

export function landVacancySuccess(key = LOADING.LAND_VACANCY_LOADING) {
  return { type: ACTIONS.LAND_VACANCY_SUCCESS, key };
}

export function landVacancyError(error, key = LOADING.LAND_VACANCY_LOADING) {
  return { type: ACTIONS.LAND_VACANCY_ERROR, error, key };
}

export function landVacancyListSuccess(response) {
  return { type: ACTIONS.LAND_VACANCY_LIST_SUCCESS, response };
}

export function landVacancyTagsSuccess(response) {
  return { type: ACTIONS.LAND_VACANCY_TAGS_SUCCESS, response };
}

export function getLandVacancyList(query) {
  return async dispatch => {
    dispatch(landVacancyRequest());
    try {
      setSessionStorage(TAG.landVacancy, []);
      const _tags = [...(query?.filterCriteria?.status || []), ...(query?.filterCriteria?.market || [])];
      const [err, response] = await to(landVacancyService.searchLandVacancy(query));
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      dispatch(landVacancyListSuccess({ list: response.content, pagination }));
      setSessionStorage(PAGINATOIN.landVacancy, pagination);
      dispatch(landVacancyTagsSuccess(_tags));
      setSessionStorage(TAG.landVacancy, _tags);
    } catch (error) {
      dispatch(landVacancyError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getLandVacancySuggestion(payload) {
  return async dispatch => {
    dispatch(landVacancyRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(landVacancyService.suggestions(payload));
      if (err) throwError(err);
      dispatch(landVacancySuccess(LOADING.SUGGESTION_LOADING));
      return response;
    } catch (error) {
      dispatch(landVacancyError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function landVacancyUnmarketLand(values) {
  return async dispatch => {
    dispatch(landVacancyRequest());
    try {
      const [err, response] = await to(landVacancyService.unmarketLand(values));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(landVacancySuccess());
    } catch (error) {
      dispatch(landVacancyError(error));
      throwError(error);
    }
  };
}

export function landVacancyMarketLand(values) {
  return async dispatch => {
    dispatch(landVacancyRequest());
    try {
      const [err, response] = await to(landVacancyService.marketLand(values));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(landVacancySuccess());
    } catch (error) {
      dispatch(landVacancyError(error));
      throwError(error);
    }
  };
}
