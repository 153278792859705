/* eslint-disable no-template-curly-in-string */
import React from "react";
import i18n from "../i18n";
import {
  convertActoHa,
  convertHaToAc,
  convertMuToAc,
  convertMuToHa,
  convertSfToSqm,
  convertSfToTsubo,
  convertSqmToSf,
  convertSqmToTsubo,
  convertTsuboToSf,
  convertTsuboToSqm,
} from "./conversion.util";
import jll from "../images/jll.png";
import cbre from "../images/cbre.png";
import dtre from "../images/dtre.jpg";
import savills from "../images/savills.png";
import lewisCo from "../images/lewis_co.jpg";
import colliers from "../images/colliers.jpg";
import m1Agency from "../images/m1_agency.png";
import geraldEve from "../images/gerald_eve.jpg";
import avisonYoung from "../images/avison_young.png";
import ilpPartners from "../images/ilp_partners.png";
import montaguEvans from "../images/montagu_evans.jpg";
import logixProperty from "../images/logix_property.png";
import burbageRealty from "../images/burbage_realty.png";
import cushmanWakefield from "../images/cushman_wakefield.png";
import budworthHardcastle from "../images/budworth_hardcastle.png";
import lambertSmithHampton from "../images/lambert_smith_hampton.png";
import colliersInternational from "../images/colliers_international.jpg";
import dowleyTurnerRealEstate from "../images/dowley_turner_real_estate.png";
export const BASE_URL = window.BASE_URL || "http://localhost:8080/api";
export const UPLOAD_URL = window.UPLOAD_URL || "http://localhost:9109";
export const TOKEN = "CT_TOKEN";
export const USER = "CT_USER";
export const PAGINATOIN = {
  properties: "properties-pagination",
  currentVacantPagination: "current-vacant-pagination",
  upcomingPropertyPagination: "upcoming-property-pagination",
  openAuditPagination: "open-audit-pagination",
  employees: "employees-pagination",
  brokers: "brokers-pagination",
  auditors: "auditors-pagination",
  markets: "markets-pagination",
  home: "home-pagination",
  propertyRollUp: "property-roll-up-pagination",
  contactHistory: "contact-pagination",
  auditReport: "audit-pagination",
  unitVacancy: "unit-vacancy-pagination",
  audits: "audit-pagination",
  landVacancy: "land-vacancy-pagination",
  downtimeReport: "downtime-report-pagination",
};
export const TAG = {
  currentVacantProperties: "current-vacant-tags",
  upcomingVacantProperties: "upcoming-vacant-tags",
  openAudits: "open-audits-tags",
  properties: "properties-tags",
  unitVacancy: "unit-vacancy-tags",
  audits: "audit-tags",
  landVacancy: "land-vacancy-tags",
  home: "home-tags",
};

export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_AUDIT = "DD MMM";
export const DATE_FORMAT_TIME = "MM/DD/YYYY HH:mm z";
export const TIME_FORMAT = "HH:mm";
export const DEFAULT_SORTER = { field: "updatedAt", order: "descend" };
export const DEFAULT_PROPERTY_SORTER = [
  { field: "isDraft", order: "descend" },
  { field: "status", order: "ascend" },
  { ...DEFAULT_SORTER },
];

export const AVAILABILITY_PAGE_PROPERTY_SORTER = [{ field: "availabileSortCount", order: "descend" }];
export const DEFAULT_DOWNTIME_REPORT_SORTER = { field: "createdAt", order: "descend" };
export const DEFAULT_QUERY_PARAM = { filterCriteria: {}, sort: "id,asc" };

export const USER_ROLES = {
  ADMIN: "Admin",
  EDITOR: "Editor",
  VIEWER: "Viewer",
  AUDITOR: "Auditor",
};

export const QUERY_PARAMS = { filterCriteria: { isActive: true }, sort: "id,asc" };
export const SORT_DIRECTION = {
  twoDirection: ["ascend", "descend"],
  threeDirection: ["descend", "ascend", "descend"],
  reverse: ["descend", "ascend"],
};
export const MEDIA_TYPES = {
  AERIAL: "aerial",
  HERO: "hero",
  GALLERY: "gallery",
  SITE_PLAN: "sitePlan",
  PROPERTY_MARKETING_COLLATERAL: "propertyMarketingCollateral",
  UNIT_MARKETING_COLLATERAL: "unitMarketingCollateral",
};
export const SEARCH_QUERY = { page: 0, size: 20 };

export const MERCHANT_USER_TYPE = {
  MANAGER: "MANAGER",
  ASSOCIATE: "ASSOCIATE",
};
export const SUCCESS_MESSAGE = {
  DELETED: i18n.t("Constants.SuccessMessages.Deleted"),
  ADDED: i18n.t("Constants.SuccessMessages.Added"),
  EDITED: i18n.t("Constants.SuccessMessages.Edited"),
  ENABLED: i18n.t("Constants.SuccessMessages.Enabled"),
  PUBLISHED: i18n.t("Constants.SuccessMessages.Published"),
  LIST: i18n.t("Constants.SuccessMessages.List"),
  INFO: i18n.t("Constants.SuccessMessages.Info"),
  AUTHORIZED: i18n.t("Constants.SuccessMessages.Authorized"),
  EMAIL: i18n.t("Constants.SuccessMessages.Email"),
  CLONE: i18n.t("Constants.SuccessMessages.Clone"),
  RETIRED: i18n.t("Constants.SuccessMessages.Retired"),
  SUSPEND: i18n.t("Constants.SuccessMessages.Suspend"),
  RESUME: i18n.t("Constants.SuccessMessages.Resume"),
  UPLOAD: i18n.t("Constants.SuccessMessages.Upload"),
};
export const ERROR_MESSAGE = {
  DELETED: i18n.t("Constants.ErrorMessages.Deleted"),
  ADDED: i18n.t("Constants.ErrorMessages.Added"),
  EDITED: i18n.t("Constants.ErrorMessages.Edited"),
  ENABLED: i18n.t("Constants.ErrorMessages.Enabled"),
  PUBLISHED: i18n.t("Constants.ErrorMessages.Published"),
  LIST: i18n.t("Constants.ErrorMessages.List"),
  INFO: i18n.t("Constants.ErrorMessages.Info"),
  PARTNER_SELECT: i18n.t("Constants.ErrorMessages.PartnerSelect"),
  AUTHORIZED: i18n.t("Constants.ErrorMessages.Authorized"),
  EMAIL: i18n.t("Constants.ErrorMessages.Email"),
  FILE: i18n.t("Constants.ErrorMessages.File"),
  RETIRE: i18n.t("Constants.ErrorMessages.Retire"),
  SUSPEND: i18n.t("Constants.ErrorMessages.Suspend"),
  RESUME: i18n.t("Constants.ErrorMessages.Resume"),
  CLONE: i18n.t("Constants.ErrorMessages.Clone"),
  UPLOAD: i18n.t("Constants.ErrorMessages.Upload"),
  IMAGE_TYPE: i18n.t("Constants.ErrorMessages.ImageType"),
};

export const CONFIRM_MESSAGE = {
  DELETE: "Are you sure you want to delete?",
  ADD: "Are you sure you want to add?",
  EDIT: "Are you sure you want to save?",
  ENABLE: "Are you sure you want to enable?",
  DISABLE: "Are you sure you want to disable?",
  PUBLISH: "Are you sure you want to publish / un publish?",
  CANCEL: "Are you sure you want to cancel?",
};

export const EMPTY_MESSAGE = "Please select a row";

export const VALIDATE_FORM_MESSAGES_TEMPLATE = {
  required: `\${label} ${i18n.t("FormMessages.IsRequired")}`,
  types: {
    email: i18n.t("FormMessages.Email"),
    number: `\${label} ${i18n.t("FormMessages.ValidateNumber")}`,
    url: `\${label} ${i18n.t("FormMessages.ValidUrl")}`,
  },
  number: {
    range: `\${label} ${i18n.t("FormMessages.MustBeBetween")} \${min} ${i18n.t("FormMessages.And")} \${max}`,
  },
  string: {
    max: `${i18n.t("FormMessages.Max")} \${max}`,
  },
  whitespace: `\${label} ${i18n.t("FormMessages.CannotEmpty")}`,
};
export const PROPERTY_STATUS = {
  published: "published",
  unpublished: "unpublished",
  never_published: "never_published",
};

export const LEASE_STATUS = {
  current: "Current",
  na: "N/A",
};

export const PROPERTY_AUDIT_STATUS = {
  not_started: "not_started",
  pending: "pending",
  passed: "passed",
};

export const FILTERS_PROPERTY_UNIT_STATUS = [
  { label: i18n.t("UnitInfoList.UnitStatusOptions.all"), value: "all" },
  { label: i18n.t("UnitInfoList.UnitStatusOptions.never_published"), value: "never_published" },
  { label: i18n.t("UnitInfoList.UnitStatusOptions.published"), value: "published" },
  { label: i18n.t("UnitInfoList.UnitStatusOptions.unpublished"), value: "unpublished" },
];

export const FILTERS_UNIT_VACANCY_STATUS = [...FILTERS_PROPERTY_UNIT_STATUS];
export const FILTERS_PROPERTY_MARKETING_STATUS = [
  { label: i18n.t("AddEditUnitVacancy.MarketingStatusOptions.all"), value: "all" },
  { label: i18n.t("AddEditUnitVacancy.MarketingStatusOptions.notMarketing"), value: true },
  { label: i18n.t("AddEditUnitVacancy.MarketingStatusOptions.availableForMarketing"), value: undefined },
];

export const FILTERS_PROPERTY_UNIT_TYPE = [
  { label: i18n.t("UnitInfoList.UnitTypeOptions.all"), value: "all" },
  { label: i18n.t("UnitInfoList.UnitTypeOptions.single"), value: "single" },
  { label: i18n.t("UnitInfoList.UnitTypeOptions.merged"), value: "merged", iconName: "mergedUnitGray" },
];

export const PROPERTY_STATUS_UI_MAPPING = {
  published: i18n.t("PropertyStatusUiMapping.published"),
  unpublished: i18n.t("PropertyStatusUiMapping.unpublished"),
  never_published: i18n.t("PropertyStatusUiMapping.never_published"),
};

export const LEASE_STATUS_UI_MAPPING = {
  [LEASE_STATUS.current]: "Vacant",
  [LEASE_STATUS.na]: "Future Vacant",
};

export const PROPERTY_TYPE_UI_MAPPING = {
  building: "Building",
  land: "Land",
  unit: "Unit",
};

export const BUILDING_TYPE = [
  { value: "building", text: "Building", label: "Building" },
  { value: "spec_development", text: "Spec Development", label: "Spec Development" },
];

export const SORT_BY_PROPERTY_SEARCH = [
  { value: "updatedAt", text: "Last Modified Date", label: "Last Modified Date" },
  { value: "marketingPropertyName", text: "Property Name", label: "Property Name" },
  { value: "status", text: "Property Status", label: "Property Status" },
  { value: "propertyType", text: "Property Type", label: "Property Type" },
];

export const LAND_TYPE = [
  { value: "build_to_suit", text: "Build-To-Suit", label: "Build-To-Suit" },
  { value: "land_for_sale", text: "Land For Sale", label: "Land For Sale" },
  { value: "land_for_lease", text: "Land For Lease", label: "Land For Lease" },
];

export const PROPERTY_TYPE = [
  { value: "building", text: "Building", label: "Building" },
  { value: "land", text: "Land", label: "Land" },
];

export const HOME_PROPERTY_TYPE = { value: "building", text: "Building", label: "Building" };

export const CONTACT_TYPE = [
  { value: "primary", text: "Primary", label: "Primary" },
  { value: "secondary", text: "Secondary", label: "Secondary" },
];

export const PROPERTY_STAGE = [
  { value: "published", label: "Published", text: "Published" },
  { value: "unpublished", label: "Unpublished", text: "Unpublished" },
  { value: "never_published", label: "Never Published", text: "Never Published" },
];

export const MEASUREMENT_UNITS = [
  { value: "SF", text: "SF" },
  { value: "SQM", text: "SQM" },
  { value: "TSUBO", text: "TSUBO" },
];

export const CLEAR_HEIGHT_MEASUREMENT_UNITS = [
  { value: "FT", text: "Feet" },
  { value: "M", text: "Meters" },
];

export const LAND_MEASUREMENT_UNITS = [
  { value: "AC", text: "ACRES" },
  { value: "HA", text: "HECTARES" },
  { value: "MU", text: "MU" },
];

export const FIRE_SUPPRESSION_SYSTEMS = [
  { value: "Calculated System", text: "Calculated System" },
  { value: "Dry System", text: "Dry System" },
  { value: "ESFR", text: "ESFR" },
  { value: "Hose Cabinet", text: "Hose Cabinet" },
  { value: "Other", text: "Other" },
  { value: "None", text: "None" },
];

export const WAREHOUSE_LIGHTING_TYPE = [
  { value: "LED", text: "LED" },
  { value: "Metal Halide", text: "Metal Halide" },
  { value: "T5/T8 Fluorescent", text: "T5/T8 Fluorescent" },
  { value: "T10 Fluorescent", text: "T10 Fluorescent" },
  { value: "T12 Fluorescent", text: "T12 Fluorescent" },
  { value: "Other", text: "Other" },
  { value: "No Warehouse Space", text: "N/A" },
];

export const LEASE_RATE = {
  "United States": "USD",
  Mexico: "MXN",
  Brazil: "BRL",
  Canada: "CAD",
  "United Kingdom": "GBP",
  France: "EUR",
  Spain: "EUR",
  Italy: "EUR",
  Germany: "EUR",
  Netherlands: "EUR",
  Belgium: "EUR",
  Sweden: "SEK",
  Poland: "PLN",
  Hungary: "HUF",
  Slovakia: "EUR",
  "Czech Republic": "CZK",
  China: "CNY",
  Japan: "JPY",
  Singapore: "SGD",
  Brunei: "BND",
};

export const LEASE_RATE_CURRENCIES = {
  USD: {
    currency: "United States Dollar",
    symbol: "$",
    value: "USD",
  },
  MXN: {
    currency: "Mexican Peso",
    symbol: "$",
    value: "MXN",
  },
  BRL: {
    currency: "Brazilian Real",
    symbol: "R$",
    value: "BRL",
  },
  CAD: {
    currency: "Canadian Dollar",
    symbol: "$",
    value: "CAD",
  },
  GBP: {
    currency: "British Pound",
    symbol: "£",
    value: "GBP",
  },
  EUR: {
    currency: "Euro",
    symbol: "£",
    value: "EUR",
  },
  SEK: {
    currency: "Swedish krona",
    symbol: "Kr",
    value: "SEK",
  },
  PLN: {
    currency: "Polish złot",
    symbol: "zł",
    value: "PLN",
  },
  HUF: {
    currency: "Hungarian forint",
    symbol: "Ft",
    value: "HUF",
  },
  CZK: {
    currency: "Czech koruna",
    symbol: "Kč",
    value: "CZK",
  },
  CNY: {
    currency: "Chinese yuan",
    symbol: "¥ or 元",
    value: "CNY",
  },
  JPY: {
    currency: "Japanese yen",
    symbol: "¥ or 円",
    value: "JPY",
  },
  SGD: {
    currency: "Singapore dollar",
    symbol: "$",
    value: "SGD",
  },
  BND: {
    currency: "Brunei dollar",
    symbol: "$",
    value: "BND",
  },
};

export const ACTIVE_STATUS = [
  { value: true, text: "True" },
  { value: false, text: "False" },
];

export const USER_ROLES_LIST = [
  { value: "admin", label: "Admin", text: "Admin" },
  { value: "editor", label: "Editor", text: "Editor" },
  { value: "viewOnly", label: "View Only", text: "View Only" },
  { value: "auditor", label: "Auditor", text: "Auditor" },
];

export const COUNTRY_LIST = [
  { value: "Austria", label: "Austria", text: "Austria" },
  { value: "Belgium", label: "Belgium", text: "Belgium" },
  { value: "Brazil", label: "Brazil", text: "Brazil" },
  { value: "Canada", label: "Canada", text: "Canada" },
  { value: "China", label: "China", text: "China" },
  { value: "Czech Republic", label: "Czech Republic", text: "Czech Republic" },
  { value: "France", label: "France", text: "France" },
  { value: "Germany", label: "Germany", text: "Germany" },
  { value: "Hungary", label: "Hungary", text: "Hungary" },
  { value: "Italy", label: "Italy", text: "Italy" },
  { value: "Japan", label: "Japan", text: "Japan" },
  // { value: "korea", label: "Korea", text: "Korea" },
  { value: "Mexico", label: "Mexico", text: "Mexico" },
  { value: "Netherlands", label: "Netherlands", text: "Netherlands" },
  { value: "Poland", label: "Poland", text: "Poland" },
  { value: "Romania", label: "Romania", text: "Romania" },
  { value: "Singapore", label: "Singapore", text: "Singapore" },
  { value: "Slovakia", label: "Slovakia", text: "Slovakia" },
  { value: "South Korea", label: "South Korea", text: "South Korea" },
  { value: "Spain", label: "Spain", text: "Spain" },
  { value: "Sweden", label: "Sweden", text: "Sweden" },
  { value: "United Kingdom", label: "United Kingdom", text: "United Kingdom" },
  { value: "United States", label: "United States", text: "United States" },
];

export const SECONDARY_LANGUAGE_CODES = {
  China: ["zh"],
  Japan: ["jp"],
  Brazil: ["pt"],
  Spain: ["es"],
  France: ["fr"],
  Italy: ["it"],
  Mexico: ["es"],
  Belgium: ["nl", "fr"],
  Netherlands: ["nl"],
  Sweden: ["se"],
  "Czech Republic": ["cs"],
  Hungary: ["hu"],
  Poland: ["pl"],
  Slovakia: ["sk"],
  Germany: ["de"],
};

export const GTAG_EVENTS = {
  ADD_PROPERTY: "Add Property",
  EDIT_PROPERTY: "Edit Property",
  VIEW_PROPERTY: "View Property",
  CANCEL_PROPERTY_VIEW: "Cancel Property View",
  PUBLISH_PROPERTY: "Publish Property",
  UNPUBLISH_PROPERTY: "UnPublish Property",
  DRAFT_PROPERTY: "Draft Property",
};

export const ANNOUNCEMENT_ID = 219;
export const ANNOUNCEMENT_DEFAULT_MESSAGE = {
  message: "No Announcement",
  title: "Announcement Title",
  subtitle: "Announcement Subtitle",
  id: 219,
};
export const ANNOUNCEMENT_TITLE_LENGTH = 100;

export const HELPER_TEXT_DEFAULT_MESSAGE = {
  helperText: "No helper text",
};

export const HELPER_TEXT_KEYS = {
  latitude: "latitude",
  longitude: "longitude",
  sitePlan: "sitePlan",
  prologisContact: "prologisContact",
  secondaryContact: "secondaryContact",
  propertyAvailable: "propertyAvailable",
  land: {
    isUrban: "landIsUrban",
    propertyCode: "landPropertyCode",
    propertyType: "landPropertyType",
    market: "landMarket",
    marketingPropertyName: "landMarketingPropertyName",
    marketingDescription: "landMarketingDescription",
    availableDate: "landAvailableDate",
    landSize: "landSize",
    minFutureBuildingSize: "minFutureBuildingSize",
    maxFutureBuildingSize: "maxFutureBuildingSize",
    digitalTourUrl: "landDigitalTourUrl",
    videoUrl: "landVideoUrl",
    micrositeUrl: "landMicrositeUrl",
    keyFeature1: "landKeyFeature1",
    keyFeature2: "landKeyFeature2",
    keyFeature3: "landKeyFeature3",
    keyFeature4: "landKeyFeature4",
    keyFeature5: "landKeyFeature5",
    keyFeature6: "landKeyFeature6",
    propertyMarketingCollateral: "landPropertyMarketingCollateral",
    hero: "landHero",
    aerial: "landAerial",
    gero: "landHero",
    imageGallery: "landImageGallery",
    latitude: "landLatitude",
    longitude: "landLongitude",
    sitePlan: "landSitePlan",
    prologisContact: "landPrologisContact",
    secondaryContact: "landSecondaryContact",
    secondaryContacts: "landSecondaryContacts",
    esmContact: "landESMContact",
    region: "landRegion",
    country: "landCountry",
    submarket: "landSubMarket",
    streetAddress1: "landStreetAddress1",
    streetAddress2: "landStreetAddress2",
    city: "landCity",
    district: "landDistrict",
    neighbourhood: "landNeighbourhood",
    postTown: "landPostTown",
    province: "landProvince",
    prefecture: "landPrefecture",
    state: "landState",
    dosi: "landDosi",
    postalCode: "landPostalCode",
    cedex: "landCedex",
    propertyRollUp: "landPropertyRollUp",
  },
  building: {
    isUrban: "buildingIsUrban",
    propertyCode: "buildingPropertyCode",
    propertyType: "buildingPropertyType",
    market: "buildingMarket",
    marketingPropertyName: "buildingMarketingPropertyName",
    marketingDescription: "buildingMarketingDescription",
    minSpaceAvailable: "buildingMinSpaceAvailable",
    maxSpaceAvailable: "buildingMaxSpaceAvailable",
    totalBuildingSize: "buildingTotalBuildingSize",
    availableDate: "buildingAvailableDate",
    unitAvailableDate: "unitAvailableDate",
    digitalTourUrl: "buildingDigitalTourUrl",
    videoUrl: "buildingVideoUrl",
    micrositeUrl: "buildingMicrositeUrl",
    railServed: "railServed",
    truckCourtDepth: "truckCourtDepth",
    keyFeature1: "buildingKeyFeature1",
    keyFeature2: "buildingKeyFeature2",
    keyFeature3: "buildingKeyFeature3",
    keyFeature4: "buildingKeyFeature4",
    keyFeature5: "buildingKeyFeature5",
    keyFeature6: "buildingKeyFeature6",
    propertyMarketingCollateral: "buildingPropertyMarketingCollateral",
    aerial: "buildingAerial",
    hero: "buildingHero",
    imageGallery: "buildingImageGallery",
    latitude: "buildingLatitude",
    longitude: "buildingLongitude",
    sitePlan: "buildingSitePlan",
    prologisContact: "buildingPrologisContact",
    secondaryContact: "buildingSecondaryContact",
    secondaryContacts: "buildingSecondaryContacts",
    esmContact: "buildingESMContact",
    region: "buildingRegion",
    country: "buildingCountry",
    submarket: "buildingSubMarket",
    streetAddress1: "buildingStreetAddress1",
    streetAddress2: "buildingStreetAddress2",
    city: "buildingCity",
    district: "buildingDistrict",
    neighbourhood: "buildingNeighbourhood",
    postTown: "buildingPostTown",
    province: "buildingProvince",
    prefecture: "buildingPrefecture",
    state: "buildingState",
    dosi: "buildingDosi",
    postalCode: "buildingPostalCode",
    cedex: "buildingCedex",
    propertyRollUp: "buildingPropertyRollUp",
  },
  unit: {
    unitCode: "unitCode",
    marketingUnitName: "marketingUnitName",
    minSpaceAvailable: "unitMinSpaceAvailable",
    maxSpaceAvailable: "unitMaxSpaceAvailable",
    officeSize: "officeSize",
    mezzanineSpace: "mezzanineSpace",
    leaseRate: "leaseRate",
    leaseStatus: "leaseStatus",
    warehouseLightingType: "warehouseLightingType",
    fireSuppressionSystem: "fireSuppressionSystem",
    clearHeight: "clearHeight",
    dockHighDoors: "dockHighDoors",
    gradeLevelDoors: "gradeLevelDoors",
    unitMarketingCollateral: "unitMarketingCollateral",
    mainBreakerSize: "unitMainBreakerSize",
    unitStatusFilter: "unitStatusFilter",
    unitTypeFilter: "unitTypeFilter",
    addUnits: "addUnits",
    keyFeature1: "unitKeyFeature1",
    keyFeature2: "unitKeyFeature2",
    keyFeature3: "unitKeyFeature3",
    keyFeature4: "unitKeyFeature4",
  },
  home: {
    unitstoReview: "unitstoReview",
    currentVacantUnits: "currentVacantUnits",
    upcomingVacantUnits: "upcomingVacantUnits",
    notMarketingOngoingReview: "notMarketingOngoingReview",
    propertiesActiveAudits: "propertiesActiveAudits",
    marketableUnitsNotOnPropertySearch: "marketableUnitsNotOnPropertySearch",
  },
};

export const HELPER_TEXT_DROPDOWN_MAPPING = {
  generic: [
    {
      value: "latitude",
      label: "Latitude",
    },
    {
      value: "longitude",
      label: "Longitude",
    },
    {
      value: "sitePlan",
      label: "Site Plan",
    },
    {
      value: "prologisContact",
      label: "Primary Contact",
    },
    {
      value: "secondaryContact",
      label: "Secondary Contact(Employee OR Broker)",
    },
    {
      value: "propertyAvailable",
      label: "Property is Available",
    },
  ],
  land: [
    {
      value: "landPropertyCode",
      label: "Land Code",
    },
    {
      value: "landPropertyType",
      label: "Land Type",
    },
    {
      value: "landMarket",
      label: "Market",
    },
    {
      value: "landMarketingPropertyName",
      label: "Marketing Property Name",
    },
    {
      value: "landMarketingDescription",
      label: "Marketing Description",
    },
    {
      value: "landAvailableDate",
      label: "Available Date",
    },
    {
      value: "landSize",
      label: "Land Size",
    },
    {
      value: "minFutureBuildingSize",
      label: "Min Future Building Size",
    },
    {
      value: "maxFutureBuildingSize",
      label: "Max Future Building Size",
    },
    {
      value: "landDigitalTourUrl",
      label: "Digital Tour URL",
    },
    {
      value: "landVideoUrl",
      label: "Video URL",
    },
    {
      value: "landPropertyMarketingCollateral",
      label: "Property Marketing Collateral",
    },
    {
      value: "landAerial",
      label: "Aerial",
    },
    {
      value: "landHero",
      label: "Hero",
    },
    {
      value: "landImageGallery",
      label: "Image Gallery",
    },
    {
      value: "landKeyFeature1",
      label: "Key Feature 1",
    },
    {
      value: "landKeyFeature2",
      label: "Key Feature 2",
    },
    {
      value: "landKeyFeature3",
      label: "Key Feature 3",
    },
    {
      value: "landKeyFeature4",
      label: "Key Feature 4",
    },
    {
      value: "landKeyFeature5",
      label: "Key Feature 5",
    },
    {
      value: "landKeyFeature6",
      label: "Key Feature 6",
    },
    {
      value: "landMicrositeUrl",
      label: "Microsite URL",
    },
    {
      value: "landLatitude",
      label: "Latitude",
    },
    {
      value: "landLongitude",
      label: "Longitude",
    },
    {
      value: "landSitePlan",
      label: "Site Plan",
    },
    {
      value: "landPrologisContact",
      label: "Primary Contact",
    },
    {
      value: "landSecondaryContacts",
      label: "Additional Contacts (Employee OR Broker)",
    },
    {
      value: "landESMContact",
      label: "Essential Solutions Manager (ESM)",
    },
    {
      value: "landRegion",
      label: "Region",
    },
    {
      value: "landCountry",
      label: "Country",
    },
    {
      value: "landSubMarket",
      label: "Submarket",
    },
    {
      value: "landStreetAddress1",
      label: "Street Address 1",
    },
    {
      value: "landStreetAddress2",
      label: "Street Address 2",
    },
    {
      value: "landCity",
      label: "City",
    },
    {
      value: "landDistrict",
      label: "District",
    },
    {
      value: "landNeighbourhood",
      label: "Neighborhood",
    },
    {
      value: "landPostTown",
      label: "PostTown",
    },
    {
      value: "landProvince",
      label: "Province",
    },
    {
      value: "landPrefecture",
      label: "Prefecture",
    },
    {
      value: "landState",
      label: "State",
    },
    {
      value: "landDosi",
      label: "Do si",
    },
    {
      value: "landPostalCode",
      label: "Postal Code",
    },
    {
      value: "landCedex",
      label: "Cedex",
    },
    {
      value: "landIsUrban",
      label: "Urban Property",
    },
    {
      value: "landPropertyRollUp",
      label: "Park",
    },
  ],
  building: [
    {
      value: "buildingPropertyCode",
      label: "Building Code",
    },
    {
      value: "buildingPropertyType",
      label: "Property Type",
    },
    {
      value: "buildingMarket",
      label: "Market",
    },
    {
      value: "buildingMarketingPropertyName",
      label: "Marketing Property Name",
    },
    {
      value: "buildingMarketingDescription",
      label: "Marketing Description",
    },
    {
      value: "buildingMinSpaceAvailable",
      label: "Min Space Available",
    },
    {
      value: "buildingMaxSpaceAvailable",
      label: "Max Space Available",
    },
    {
      value: "buildingTotalBuildingSize",
      label: "Total Building Size",
    },
    {
      value: "buildingAvailableDate",
      label: "Available Date",
    },
    {
      value: "buildingDigitalTourUrl",
      label: "Digital Tour URL",
    },
    {
      value: "buildingVideoUrl",
      label: "Video URL",
    },
    {
      value: "railServed",
      label: "Rail Served",
    },
    {
      value: "truckCourtDepth",
      label: "Truck Court Depth",
    },
    {
      value: "buildingPropertyMarketingCollateral",
      label: "Property Marketing Collateral",
    },
    {
      value: "buildingAerial",
      label: "Aerial",
    },
    {
      value: "buildingHero",
      label: "Hero",
    },
    {
      value: "buildingImageGallery",
      label: "Image Gallery",
    },
    {
      value: "buildingKeyFeature1",
      label: "Key Feature 1",
    },
    {
      value: "buildingKeyFeature2",
      label: "Key Feature 2",
    },
    {
      value: "buildingKeyFeature3",
      label: "Key Feature 3",
    },
    {
      value: "buildingKeyFeature4",
      label: "Key Feature 4",
    },
    {
      value: "buildingKeyFeature5",
      label: "Key Feature 5",
    },
    {
      value: "buildingKeyFeature6",
      label: "Key Feature 6",
    },
    {
      value: "buildingMicrositeUrl",
      label: "Microsite URL",
    },
    {
      value: "buildingLatitude",
      label: "Latitude",
    },
    {
      value: "buildingLongitude",
      label: "Longitude",
    },
    {
      value: "buildingSitePlan",
      label: "Site Plan",
    },
    {
      value: "buildingPrologisContact",
      label: "Primary Contact",
    },
    {
      value: "buildingSecondaryContacts",
      label: "Additional Contacts (Employee OR Broker)",
    },
    {
      value: "buildingESMContact",
      label: "Essential Solutions Manager (ESM)",
    },
    {
      value: "buildingRegion",
      label: "Region",
    },
    {
      value: "buildingCountry",
      label: "Country",
    },
    {
      value: "buildingSubMarket",
      label: "Submarket",
    },
    {
      value: "buildingStreetAddress1",
      label: "Street Address 1",
    },
    {
      value: "buildingStreetAddress2",
      label: "Street Address 2",
    },
    {
      value: "buildingCity",
      label: "City",
    },
    {
      value: "buildingDistrict",
      label: "District",
    },
    {
      value: "buildingNeighbourhood",
      label: "Neighborhood",
    },
    {
      value: "buildingPostTown",
      label: "PostTown",
    },
    {
      value: "buildingProvince",
      label: "Province",
    },
    {
      value: "buildingPrefecture",
      label: "Prefecture",
    },
    {
      value: "buildingState",
      label: "State",
    },
    {
      value: "buildingDosi",
      label: "Do si",
    },
    {
      value: "buildingPostalCode",
      label: "Postal Code",
    },
    {
      value: "buildingCedex",
      label: "Cedex",
    },
    {
      value: "buildingIsUrban",
      label: "Urban Property",
    },
    {
      value: "buildingPropertyRollUp",
      label: "Park",
    },
  ],
  unit: [
    {
      value: "unitAvailableDate",
      label: "Available Date",
    },
    {
      value: "unitCode",
      label: "Unit Code",
    },
    {
      value: "marketingUnitName",
      label: "Marketing Unit Name",
    },
    {
      value: "unitMinSpaceAvailable",
      label: "Min Space Available",
    },
    {
      value: "unitMaxSpaceAvailable",
      label: "Max Space Available",
    },
    {
      value: "leaseRate",
      label: "Lease Rate",
    },
    {
      value: "leaseStatus",
      label: "Lease Status",
    },
    {
      value: "warehouseLightingType",
      label: "Warehouse Lighting Type",
    },
    {
      value: "fireSuppressionSystem",
      label: "Fire Suppression System",
    },
    {
      value: "clearHeight",
      label: "Clear Height",
    },
    {
      value: "dockHighDoors",
      label: "# of Dock High Doors",
    },
    {
      value: "gradeLevelDoors",
      label: "# of Grade Level Doors",
    },
    {
      value: "unitMarketingCollateral",
      label: "Unit Marketing Collateral",
    },
    {
      value: "unitMainBreakerSize",
      label: "Main Breaker Size (AMPS)",
    },
    {
      value: "unitStatusFilter",
      label: "Unit Status Filter",
    },
    {
      value: "unitTypeFilter",
      label: "Unit Type Filter",
    },
    {
      value: "addUnits",
      label: "Add Units",
    },
    {
      value: "officeSize",
      label: "Office Size",
    },
    {
      value: "mezzanineSpace",
      label: "Mezzanine Space",
    },
    {
      value: "unitKeyFeature1",
      label: "Key Feature 1",
    },
    {
      value: "unitKeyFeature2",
      label: "Key Feature 2",
    },
    {
      value: "unitKeyFeature3",
      label: "Key Feature 3",
    },
    {
      value: "unitKeyFeature4",
      label: "Key Feature 4",
    },
  ],
  home: [
    {
      value: "unitstoReview",
      label: "Units to Review",
    },
    {
      value: "currentVacantUnits",
      label: "Current Vacant Units",
    },
    {
      value: "upcomingVacantUnits",
      label: "Upcoming Vacant Units",
    },
    {
      value: "notMarketingOngoingReview",
      label: "Not Marketing Ongoing Review",
    },
    {
      value: "propertiesActiveAudits",
      label: "Properties/Land w/ Active Audits",
    },
    {
      value: "marketableUnitsNotOnPropertySearch",
      label: "Marketable Units not on Property Search",
    },
  ],
};

export const FIELD_TYPE = {
  building: "building",
  land: "land",
  unit: "unit",
  home: "home",
};

export const FIELD_TYPE_UI_MAPPING = {
  building: "Building",
  land: "Land",
  unit: "Unit",
  home: "Home",
  generic: "Generic",
};

export const PROPERTY_TYPE_KEYS = PROPERTY_TYPE.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.value }), {});

export const mapLabelToT = (array, arrayName) =>
  array.map(obj => {
    obj.label = i18n.t(`${arrayName}.${obj.value}`);
    obj.text = i18n.t(`${arrayName}.${obj.value}`);
    return obj;
  });

export const ROUTES = {
  BASE: {
    path: "/",
    title: "",
    name: "",
  },
  ADMIN: {
    path: "/admin",
    title: "Admin",
    name: "Admin",
  },
  HOME: {
    path: "/home",
    title: i18n.t("Nav.MyDPM"),
    name: i18n.t("Nav.Home"),
  },
  PROPERTIES: {
    path: "/properties",
    title: "All Properties",
    name: "Publish",
  },
  PROPERTIES_TO_REVIEW: {
    path: "/properties/properties-to-review",
    title: "All Properties",
    name: "Publish",
  },
  ADD_BUILDING: {
    path: "/building/add",
    title: "Add Building",
    name: "Add Building",
  },
  VIEW_BUILDING: {
    path: "/building/view",
    title: "View Building",
    name: "View Building",
  },
  EDIT_BUILDING: {
    path: "/building/edit",
    title: "Edit Building",
    name: "Edit Building",
  },
  ADD_LAND: {
    path: "/land/add",
    title: "Add Land",
    name: "Add Land",
  },
  VIEW_LAND: {
    path: "/land/view",
    title: "View Land",
    name: "View Land",
  },
  EDIT_LAND: {
    path: "/land/edit",
    title: "Edit Land",
    name: "Edit Land",
  },
  UNIT_VACANCY: {
    path: "/unit-vacancy",
    title: i18n.t("UnitVacancy.Title"),
    name: i18n.t("Properties.Menu.Building"),
  },
  UNITS_TO_REVIEW: {
    path: "/unit-vacancy/units-to-review",
    title: i18n.t("UnitVacancy.Title"),
    name: i18n.t("Properties.Menu.Building"),
  },
  AUDIT: {
    path: "/audit",
    title: i18n.t("AuditTable.Title"),
    name: i18n.t("AuditTable.NavTitle"),
  },
  OPEN_AUDIT_ISSUES: {
    path: "/audit/audit-to-review",
    title: i18n.t("AuditTable.OpenAudits"),
    name: i18n.t("AuditTable.NavTitle"),
  },
  LAND_VACANCY: {
    path: "/land-vacancy",
    title: "Land Vacancy",
    name: i18n.t("Properties.Menu.Land"),
  },
  DOWNTIME_REPORT: {
    path: "/downtime-report",
    title: i18n.t("DowntimeReport.Title"),
    name: i18n.t("DowntimeReport.Menu.Name"),
  },
};

export const DOWNTIME_REPORT_COLUMN_NAME = {
  MONTH_YEAR: {
    title: "DowntimeReport.MonthYear",
    key: "createdAt",
  },
  TIMEOUT_DURATION: {
    title: "DowntimeReport.TimeoutDuration",
    key: "timeout",
  },
  THROTTLE_DURATION: {
    title: "DowntimeReport.ThrottleDuration",
    key: "throttle",
  },
};

export const BUILDING_COMMON_REQUIRED_FIELDS = [
  ["buildingInfo", "propertyCode"],
  ["buildingInfo", "marketId"],
  ["buildingInfo", "latitude"],
  ["buildingInfo", "longitude"],
  ["buildingInfo", "minSpaceAvailable"],
  ["buildingInfo", "maxSpaceAvailable"],
  ["buildingInfo", "totalBuildingSizeUOM"],
  ["buildingInfo", "totalBuildingSize"],
  ["buildingInfo", "minSpaceAvailableUOM"],
  ["buildingInfo", "maxSpaceAvailableUOM"],
  ["buildingInfo", "propertySubtype"], // subType
  ["buildingInfo", "railServed"],
  ["buildingInfo", "address", "country"],
  ["buildingInfo", "subMarketId"],
  ["contacts", "prologisContact"],
  ["unitInfo", "maxSpaceAvailable"],
  ["unitInfo", "minSpaceAvailable"],
  ["unitInfo", "unitCode"],
  ["unitInfo", "warehouseLightingType"],
  ["unitInfo", "clearHeight"],
  ["unitInfo", "fireSuppressionSystem"],
];

export const BUILDING_REQUIRED_FIELDS = [
  ...BUILDING_COMMON_REQUIRED_FIELDS,
  ["buildingInfo", "marketingPropertyName"],
  ["buildingInfo", "marketingDescription"],
  ["keyFeatures", "feature1"],
  ["keyFeatures", "feature2"],
  ["keyFeatures", "feature3"],
  ["unitInfo", "marketingUnitName"],
  ["buildingInfo", "address", "streetAddress1"],
  ["buildingInfo", "address", "postalCode"],
];

export const BUILDING_TRANSLATION_REQUIRED_FIELDS = [
  ["translations", "buildingInfo", "marketingPropertyName"],
  ["translations", "buildingInfo", "marketingDescription"],
  ["translations", "keyFeatures", "feature1"],
  ["translations", "keyFeatures", "feature2"],
  ["translations", "keyFeatures", "feature3"],
  ["translations", "unitInfo", "marketingUnitName"],
  ["translations", "buildingInfo", "address", "streetAddress1"],
];

export const LAND_COMMON_REQUIRED_FIELDS = [
  ["landInfo", "propertyCode"],
  ["landInfo", "marketId"],
  ["landInfo", "latitude"],
  ["landInfo", "longitude"],
  ["landInfo", "landSize"],
  ["landInfo", "propertySubtype"],
  ["landInfo", "address", "country"],
  ["landInfo", "subMarketId"],
  ["contacts", "prologisContact"],
];

export const LAND_REQUIRED_FIELDS = [
  ...LAND_COMMON_REQUIRED_FIELDS,
  ["landInfo", "marketingDescription"],
  ["landInfo", "marketingPropertyName"],
  ["keyFeatures", "feature1"],
  ["keyFeatures", "feature2"],
  ["landInfo", "address", "streetAddress1"],
  ["landInfo", "address", "postalCode"],
];

export const LAND_TRANSLATION_REQUIRED_FIELDS = [
  ["translations", "landInfo", "marketingDescription"],
  ["translations", "landInfo", "marketingPropertyName"],
  ["translations", "keyFeatures", "feature1"],
  ["translations", "keyFeatures", "feature2"],
  ["translations", "landInfo", "address", "streetAddress1"],
];

export const BUILDING_TABS_KEYS = {
  1: "buildingInfo",
  2: "keyFeatures",
  3: "contacts",
  4: "media",
  5: "unitInfo",
  6: "auditIssues",
};

export const LAND_TABS_KEYS = {
  1: "landInfo",
  2: "keyFeatures",
  3: "contacts",
  4: "media",
  5: "auditIssues",
};

export const UNIT_INFO_KEYS = [
  "marketingUnitName",
  "maxSpaceAvailable",
  "maxSpaceAvailableUOM",
  "minSpaceAvailable",
  "minSpaceAvailableUOM",
  "unitCode",
  "warehouseLightingType",
  "clearHeight",
  "clearHeightUOM",
  "fireSuppressionSystem",
  // "status",
];

const fill = () => {
  ROUTES.ADMIN.name = i18n.t("Nav.Admin");
  ROUTES.PROPERTIES.name = i18n.t("Nav.Publish");
  ROUTES.PROPERTIES.title = i18n.t("Properties.PageTitle");
  ROUTES.ADD_BUILDING.title = i18n.t("Nav.AddBuilding");
  ROUTES.VIEW_BUILDING.title = i18n.t("Nav.ViewBuilding");
  ROUTES.EDIT_BUILDING.title = i18n.t("Nav.EditBuilding");
  ROUTES.ADD_LAND.title = i18n.t("Nav.AddLand");
  ROUTES.VIEW_LAND.title = i18n.t("Nav.ViewLand");
  ROUTES.EDIT_LAND.title = i18n.t("Nav.EditLand");
  ROUTES.HOME.name = i18n.t("Nav.Home");
  ROUTES.HOME.title = i18n.t("Nav.MyDPM");

  FILTERS_PROPERTY_UNIT_TYPE.forEach(unitType => {
    unitType.label = i18n.t(`UnitInfoList.UnitTypeOptions.${unitType.value}`);
  });

  FILTERS_PROPERTY_UNIT_STATUS.forEach(unitStatus => {
    unitStatus.label = i18n.t(`UnitInfoList.UnitStatusOptions.${unitStatus.value}`);
  });

  PROPERTY_STATUS_UI_MAPPING.published = i18n.t("PropertyStatusUiMapping.published");
  PROPERTY_STATUS_UI_MAPPING.unpublished = i18n.t("PropertyStatusUiMapping.unpublished");
  PROPERTY_STATUS_UI_MAPPING.never_published = i18n.t("PropertyStatusUiMapping.never_published");

  VALIDATE_FORM_MESSAGES_TEMPLATE.required = `\${label} ${i18n.t("FormMessages.IsRequired")}`;
  VALIDATE_FORM_MESSAGES_TEMPLATE.types.email = i18n.t("FormMessages.Email");
  VALIDATE_FORM_MESSAGES_TEMPLATE.types.number = `\${label} ${i18n.t("FormMessages.ValidateNumber")}`;
  VALIDATE_FORM_MESSAGES_TEMPLATE.types.url = `\${label} ${i18n.t("FormMessages.ValidUrl")}`;
  VALIDATE_FORM_MESSAGES_TEMPLATE.number.range = `\${label} ${i18n.t("FormMessages.MustBeBetween")} \${min} ${i18n.t(
    "FormMessages.And",
  )} \${max}`;
  VALIDATE_FORM_MESSAGES_TEMPLATE.string.max = `${i18n.t("FormMessages.Max")} \${max}`;
  VALIDATE_FORM_MESSAGES_TEMPLATE.whitespace = `\${label} ${i18n.t("FormMessages.CannotEmpty")}`;

  ERROR_MESSAGE.DELETED = i18n.t("Constants.ErrorMessages.Deleted");
  ERROR_MESSAGE.ADDED = i18n.t("Constants.ErrorMessages.Added");
  ERROR_MESSAGE.EDITED = i18n.t("Constants.ErrorMessages.Edited");
  ERROR_MESSAGE.ENABLED = i18n.t("Constants.ErrorMessages.Enabled");
  ERROR_MESSAGE.PUBLISHED = i18n.t("Constants.ErrorMessages.Published");
  ERROR_MESSAGE.LIST = i18n.t("Constants.ErrorMessages.List");
  ERROR_MESSAGE.INFO = i18n.t("Constants.ErrorMessages.Info");
  ERROR_MESSAGE.PARTNER_SELECT = i18n.t("Constants.ErrorMessages.PartnerSelect");
  ERROR_MESSAGE.AUTHORIZED = i18n.t("Constants.ErrorMessages.Authorized");
  ERROR_MESSAGE.EMAIL = i18n.t("Constants.ErrorMessages.Email");
  ERROR_MESSAGE.FILE = i18n.t("Constants.ErrorMessages.File");
  ERROR_MESSAGE.RETIRE = i18n.t("Constants.ErrorMessages.Retire");
  ERROR_MESSAGE.SUSPEND = i18n.t("Constants.ErrorMessages.Suspend");
  ERROR_MESSAGE.RESUME = i18n.t("Constants.ErrorMessages.Resume");
  ERROR_MESSAGE.CLONE = i18n.t("Constants.ErrorMessages.Clone");
  ERROR_MESSAGE.UPLOAD = i18n.t("Constants.ErrorMessages.Upload");

  SUCCESS_MESSAGE.DELETED = i18n.t("Constants.SuccessMessages.Deleted");
  SUCCESS_MESSAGE.ADDED = i18n.t("Constants.SuccessMessages.Added");
  SUCCESS_MESSAGE.EDITED = i18n.t("Constants.SuccessMessages.Edited");
  SUCCESS_MESSAGE.ENABLED = i18n.t("Constants.SuccessMessages.Enabled");
  SUCCESS_MESSAGE.PUBLISHED = i18n.t("Constants.SuccessMessages.Published");
  SUCCESS_MESSAGE.LIST = i18n.t("Constants.SuccessMessages.List");
  SUCCESS_MESSAGE.INFO = i18n.t("Constants.SuccessMessages.Info");
  SUCCESS_MESSAGE.AUTHORIZED = i18n.t("Constants.SuccessMessages.Authorized");
  SUCCESS_MESSAGE.EMAIL = i18n.t("Constants.SuccessMessages.Email");
  SUCCESS_MESSAGE.CLONE = i18n.t("Constants.SuccessMessages.Clone");
  SUCCESS_MESSAGE.RETIRED = i18n.t("Constants.SuccessMessages.Retired");
  SUCCESS_MESSAGE.SUSPEND = i18n.t("Constants.SuccessMessages.Suspend");
  SUCCESS_MESSAGE.RESUME = i18n.t("Constants.SuccessMessages.Resume");
  SUCCESS_MESSAGE.UPLOAD = i18n.t("Constants.SuccessMessages.Upload");
};
fill();
i18n.on("languageChanged", fill);

export const LANGUAGE_CODES = {
  English: { value: "en", label: "EN" },
  Chinese: { value: "zh", label: "ZH" },
  Japanese: { value: "jp", label: "JP" },
  Portuguese: { value: "pt", label: "PT" },
  Spanish: { value: "es", label: "ES (Spain)" },
  Mexico: { value: "es-m", label: "ES (Mexico)" },
  French: { value: "fr", label: "FR" },
  Italian: { value: "it", label: "IT" },
  Dutch: { value: "nl", label: "NL" },
  Sweden: { value: "se", label: "SE" },
  Czech: { value: "cs", label: "CS" },
  Hungarian: { value: "hu", label: "HU" },
  Polish: { value: "pl", label: "PL" },
  Slovak: { value: "sk", label: "SK" },
  German: { value: "de", label: "DE" },
};

export const dateLanguageLookup = {
  en: "en-US",
  es: "es-ES",
  cs: "cs-CZ",
  de: "de-DE",
  "es-m": "es-MX",
  fr: "fr-FR",
  hu: "hu-HU",
  it: "it-IT",
  jp: "ja-JP",
  nl: "nl-NL",
  pl: "pl-PL",
  pt: "pt-BR",
  se: "sv-SE",
  zh: "zh-CN",
  sk: "sk-SK",
};

export const TEMPLATE_TYPE = {
  building: "building",
  land: "land",
  unit: "unit",
};

export const TEMPLATE_TYPE_UI_MAPPING = {
  building: "Building",
  land: "Land",
  unit: "Unit",
};

export const TEMPLATE_FIELDS = { marketingDescription: "Marketing Description", keyFeatures: "Key Features" };

export const TEMPLATE_FIELD_UI_MAPPING = {
  land: {
    marketingDescription: TEMPLATE_FIELDS.marketingDescription,
    keyFeatures: TEMPLATE_FIELDS.keyFeatures,
  },
  building: {
    marketingDescription: TEMPLATE_FIELDS.marketingDescription,
    keyFeatures: TEMPLATE_FIELDS.keyFeatures,
  },
  unit: { keyFeatures: TEMPLATE_FIELDS.keyFeatures },
};

export const KEY_FEATURE_TEMPLATE_KEYS = {
  feature1: { templateKey: "templateFeature1", fieldKey: "feature1" },
  feature2: { templateKey: "templateFeature2", fieldKey: "feature2" },
  feature3: { templateKey: "templateFeature3", fieldKey: "feature3" },
  feature4: { templateKey: "templateFeature4", fieldKey: "feature4" },
};

export const PROPERTY_KEY_FEATURE_TEMPLATE_KEYS = {
  ...KEY_FEATURE_TEMPLATE_KEYS,
  feature5: { templateKey: "templateFeature5", fieldKey: "feature5" },
  feature6: { templateKey: "templateFeature6", fieldKey: "feature6" },
};

export const COUNTRY_NAME_IN_LOCAL_LOOKUP = {
  belgium: ["Belgium", "Belgique"],
  slovakia: ["Slovakia", "Slovénie"],
  brazil: ["Brazil", "Brasil"],
  italy: ["Italy", "Italia"],
  spain: ["Spain", "España"],
  canada: ["Canada"],
  sweden: ["Sweden", "Sverige"],
  china: ["China", "中国"],
  mexico: ["Mexico", "México"],
  united_kingdom: ["United Kingdom"],
  czech_republic: ["Czech Republic", "Česká republika"],
  netherlands: ["Netherlands", "Nederland"],
  united_states: ["United States"],
  france: ["France"],
  poland: ["Poland", "Polska"],
  germany: ["Germany", "Deutschland"],
  singapore: ["Singapore", "Singapura"],
  japan: ["Japan", "日本"],
  hungary: ["Hungary", "Magyarország"],
};

export const MEASURMENT_UNIT_CODES = {
  SQM: "SQM",
  SF: "SF",
  TSU: "TSUBO",
  HA: "HA",
  AC: "AC",
  MU: "MU",
};

export const MEASUREMENT_TYPE = {
  US_CUSTOMARY: "uscustomary",
  JAPANESE: "japanese",
  METRIC: "metric",
};

export const MEASUREMENT_UNITS_LOOKUP = {
  METER: "m²",
  SF: "ft²",
  TSU: "tsu",
  HA: "ha",
  AC: "ac",
};

export const MEASUREMENTS_LOOKUP = {
  [`${MEASURMENT_UNIT_CODES.SF}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.SF}:${MEASUREMENT_TYPE.METRIC}`]: convertSfToSqm,
  [`${MEASURMENT_UNIT_CODES.SF}:${MEASUREMENT_TYPE.JAPANESE}`]: convertSfToTsubo,
  [`${MEASURMENT_UNIT_CODES.SQM}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: convertSqmToSf,
  [`${MEASURMENT_UNIT_CODES.SQM}:${MEASUREMENT_TYPE.METRIC}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.SQM}:${MEASUREMENT_TYPE.JAPANESE}`]: convertSqmToTsubo,
  [`${MEASURMENT_UNIT_CODES.TSU}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: convertTsuboToSf,
  [`${MEASURMENT_UNIT_CODES.TSU}:${MEASUREMENT_TYPE.METRIC}`]: convertTsuboToSqm,
  [`${MEASURMENT_UNIT_CODES.TSU}:${MEASUREMENT_TYPE.JAPANESE}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.HA}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: convertHaToAc,
  [`${MEASURMENT_UNIT_CODES.HA}:${MEASUREMENT_TYPE.METRIC}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.HA}:${MEASUREMENT_TYPE.JAPANESE}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.AC}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: value => value,
  [`${MEASURMENT_UNIT_CODES.AC}:${MEASUREMENT_TYPE.METRIC}`]: convertActoHa,
  [`${MEASURMENT_UNIT_CODES.AC}:${MEASUREMENT_TYPE.JAPANESE}`]: convertActoHa,
  [`${MEASURMENT_UNIT_CODES.MU}:${MEASUREMENT_TYPE.US_CUSTOMARY}`]: convertMuToAc,
  [`${MEASURMENT_UNIT_CODES.MU}:${MEASUREMENT_TYPE.METRIC}`]: convertMuToHa,
  [`${MEASURMENT_UNIT_CODES.MU}:${MEASUREMENT_TYPE.JAPANESE}`]: convertMuToHa,
};

export const OUTBOUND_API_TYPES = {
  BUILDING: "building",
  UNIT: "unit",
};

export const UNMARKET_UI_MAPPING = {
  unmarketed: "Not marketing",
  not_added: "Never Published",
  vacant: "Vacant",
  future_vacant: "Future Vacancy",
};

export const UNIT_UNMARKET_REASON_DROPDOWN = [
  {
    label: "Unit is already leased. Opportunity has not been completely closed out in Salesforce",
    value: "Unit is already leased. Opportunity has not been completely closed out in Salesforce",
  },
  {
    label: "Unit is already leased. Opportunity has been closed and Yardi is still reflecting it as available in DPM",
    value: "Unit is already leased. Opportunity has been closed and Yardi is still reflecting it as available in DPM",
  },
  { label: "Mezzanine space", value: "Mezzanine space" },
  {
    label:
      "Unit exists in SalesForce/Yardi only to account for the SF difference. Once a lease expires, it will be embedded into another unit",
    value:
      "Unit exists in SalesForce/Yardi only to account for the SF difference. Once a lease expires, it will be embedded into another unit",
  },
  { label: "Re-development site – not ready for marketing", value: "Re-development site – not ready for marketing" },
  { label: "Essentials showroom", value: "Essentials showroom" },
  { label: "Market Officer Decision", value: "other" },
];

export const AUDIT_STATUS = {
  audit_issue: "audit_issue",
  ready_for_review: "ready_for_review",
  audit_passed: "audit_passed",
};

export const AUDIT_STATUS_UI_MAPPING = {
  audit_issue: i18n.t("AddEditProperty.Audit.AuditStatusUiMapping.AuditIssue"),
  ready_for_review: i18n.t("AddEditProperty.Audit.AuditStatusUiMapping.ReadyForReview"),
  audit_passed: i18n.t("AddEditProperty.Audit.AuditStatusUiMapping.AuditPassed"),
};

export const BUILDING_TABS_LOOKUP = {
  buildingInfo: { tabTitle: i18n.t("AddEditProperty.Tabs.BuildingInformation"), key: "1" },
  keyFeatures: { tabTitle: i18n.t("AddEditProperty.Tabs.KeyFeatures"), key: "2" },
  contacts: { tabTitle: i18n.t("AddEditProperty.Tabs.Contacts"), key: "3" },
  media: { tabTitle: i18n.t("AddEditProperty.Tabs.Media"), key: "4" },
  unitInfo: { tabTitle: i18n.t("AddEditProperty.Tabs.UnitInformation"), key: "5" },
  auditIssues: { tabTitle: i18n.t("AddEditProperty.Tabs.AuditIssues"), key: "6" },
};

export const LAND_TABS_LOOKUP = {
  landInfo: { tabTitle: i18n.t("AddEditLand.Tabs.LandInformation"), key: "1" },
  keyFeatures: { tabTitle: i18n.t("AddEditLand.Tabs.KeyFeatures"), key: "2" },
  contacts: { tabTitle: i18n.t("AddEditLand.Tabs.Contacts"), key: "3" },
  media: { tabTitle: i18n.t("AddEditLand.Tabs.Media"), key: "4" },
  auditIssues: { tabTitle: i18n.t("AddEditLand.Tabs.AuditIssues"), key: "5" },
};

export const AUDIT_LANGUAGE_NAME_LOOKUP = {
  en: "English",
  zh: "Chinese",
  jp: "Japanese",
  pt: "Portuguese",
  es: "Spanish",
  "es-m": "Spanish (Mexico)",
  fr: "French",
  it: "Italian",
  nl: "Dutch",
  se: "Swedish",
  cs: "Czech",
  hu: "Hungarian",
  pl: "Polish",
  sk: "Slovak",
  de: "German",
};

export const FORM_FIELDS_LOOKUP = {
  building: {
    buildingInfo: {
      market: { key: "market", label: "Market" },
      submarket: { key: "submarket", label: "Submarket" },
      streetAddress1: { key: "streetAddress1", label: "Street Address 1" },
      streetAddress2: { key: "streetAddress2", label: "Street Address 2" },
      city: { key: "city", label: "City" },
      district: { key: "district", label: "District" },
      neighborhood: { key: "neighborhood", label: "Neighborhood" },
      postTown: { key: "postTown", label: "PostTown" },
      province: { key: "province", label: "Province" },
      prefecture: { key: "prefecture", label: "Prefecture" },
      state: { key: "state", label: "State" },
      dosi: { key: "dosi", label: "Do si" },
      postalCode: { key: "postalCode", label: "Postal Code" },
      cedex: { key: "cedex", label: "Cedex" },
      totalBuildingSize: { key: "totalBuildingSize", label: "Total Building Size" },
      minSpaceAvailable: { key: "minSpaceAvailable", label: "Min Space Available" },
      maxSpaceAvailable: { key: "maxSpaceAvailable", label: "Max Space Available" },
      propertySubtype: { key: "propertySubtype", label: "Property Type" },
      marketingPropertyName: { key: "marketingPropertyName", label: "Marketing Property Name" },
      marketingDescription: { key: "marketingDescription", label: "Marketing Description" },
      availableDate: { key: "availableDate", label: "Available Date" },
      digitalTourUrl: { key: "digitalTourUrl", label: "Digital Tour URL" },
      videoUrl: { key: "videoUrl", label: "Video Url" },
      micrositeUrl: { key: "micrositeUrl", label: "Microsite Url" },
      latitude: { key: "latitude", label: "Latitude" },
      longitude: { key: "longitude", label: "Longitude" },
      truckCourtDepth: { key: "truckCourtDepth", label: "Truck Court Depth" },
      railServed: { key: "railServed", label: "Rail Served" },
      propertyRollUp: { key: "propertyRollUp", label: "Park" },
    },
    keyFeatures: {
      feature1: { key: "feature1", label: "Key Feature 1" },
      feature2: { key: "feature2", label: "Key Feature 2" },
      feature3: { key: "feature3", label: "Key Feature 3" },
      feature4: { key: "feature4", label: "Key Feature 4" },
      feature5: { key: "feature5", label: "Key Feature 5" },
      feature6: { key: "feature6", label: "Key Feature 6" },
    },
    contacts: {
      prologisContact: { key: "prologisContact", label: "Primary Contact" },
      secondaryContacts: { key: "secondaryContacts", label: "Additional Contacts (Employee OR Broker)" },
      esmContact: { key: "esmContact", label: "Essential Solutions Manager (ESM)" },
    },
    media: {
      hero: { key: "hero", label: "Hero" },
      aerial: { key: "aerial", label: "Aerial" },
      sitePlan: { key: "sitePlan", label: "Site Plan" },
      propertyMarketingCollateral: { key: "propertyMarketingCollateral", label: "Property Marketing Collateral" },
      gallery: { key: "gallery", label: "Image Gallery" },
    },
    unitInfo: {
      unitCode: { key: "unitCode", label: "Unit Code" },
      clearHeightUOM: { key: "clearHeightUOM", label: "Clear Height" },
      officeSizeUOM: { key: "officeSizeUOM", label: "Office Size UMO" },
      maxSpaceAvailableUOM: { key: "maxSpaceAvailableUOM", label: "Max Space Available UMO" },
      minSpaceAvailableUOM: { key: "minSpaceAvailableUOM", label: "Min Space Available UMO" },
      unitMarketingCollateral: { key: "unitMarketingCollateral", label: "Unit Marketing Collateral" },
      minSpaceAvailable: { key: "minSpaceAvailable", label: "Min Space Available" },
      maxSpaceAvailable: { key: "maxSpaceAvailable", label: "Max Space Available" },
      officeSize: { key: "officeSize", label: "Office Size" },
      mezzanineSpace: { key: "mezzanineSpace", label: "Mezzanine Space" },
      keyFeatures: {
        feature1: { key: "feature1", label: "Key Feature 1" },
        feature2: { key: "feature2", label: "Key Feature 2" },
        feature3: { key: "feature3", label: "Key Feature 3" },
        feature4: { key: "feature4", label: "Key Feature 4" },
      },
      marketingUnitName: { key: "marketingUnitName", label: "Marketing Unit Name" },
      warehouseLightingType: { key: "warehouseLightingType", label: "Warehouse Lighting Type" },
      clearHeight: { key: "clearHeight", label: "Clear Height" },
      mainBreakerSize: { key: "mainBreakerSize", label: "Main Breaker Size (AMPS)" },
      fireSuppressionSystem: { key: "fireSuppressionSystem", label: "Fire Suppression System" },
      gradeLevelDoors: { key: "gradeLevelDoors", label: "# of Grade Level Doors" },
      dockHighDoors: { key: "dockHighDoors", label: "# of Dock High Doors" },
    },
  },
  land: {
    landInfo: {
      futureBuildingSizeUOM: { key: "futureBuildingSizeUOM", label: "Future Building Size UOM" },
      landSize: { key: "landSize", label: "Land Size" },
      market: { key: "market", label: "Market" },
      subMarket: { key: "subMarket", label: "Submarket" },
      streetAddress1: { key: "streetAddress1", label: "Street Address 1" },
      streetAddress2: { key: "streetAddress2", label: "Street Address 2" },
      city: { key: "city", label: "City" },
      district: { key: "district", label: "District" },
      neighborhood: { key: "neighborhood", label: "Neighborhood" },
      postTown: { key: "postTown", label: "PostTown" },
      province: { key: "province", label: "Province" },
      state: { key: "state", label: "State" },
      dosi: { key: "dosi", label: "Do si" },
      postalCode: { key: "postalCode", label: "Postal Code" },
      prefecture: { key: "prefecture", label: "Prefecture" },
      cedex: { key: "cedex", label: "Cedex" },
      digitalTourUrl: { key: "digitalTourUrl", label: "Digital Tour URL" },
      videoUrl: { key: "videoUrl", label: "Video Url" },
      micrositeUrl: { key: "micrositeUrl", label: "Microsite Url" },
      marketingPropertyName: { key: "marketingPropertyName", label: "Marketing Property Name" },
      marketingDescription: { key: "marketingDescription", label: "Marketing Description" },
      propertySubtype: { key: "propertySubtype", label: "Land Type" },
      availableDate: { key: "availableDate", label: "Available Date" },
      minFutureBuildingSize: { key: "minFutureBuildingSize", label: "Min Future Building Size" },
      maxFutureBuildingSize: { key: "maxFutureBuildingSize", label: "Max Future Building Size" },
      latitude: { key: "latitude", label: "Latitude" },
      longitude: { key: "longitude", label: "Longitude" },
      propertyRollUp: { key: "propertyRollUp", label: "Park" },
    },
    keyFeatures: {
      feature1: { key: "feature1", label: "Key Feature 1" },
      feature2: { key: "feature2", label: "Key Feature 2" },
      feature3: { key: "feature3", label: "Key Feature 3" },
      feature4: { key: "feature4", label: "Key Feature 4" },
      feature5: { key: "feature5", label: "Key Feature 5" },
      feature6: { key: "feature6", label: "Key Feature 6" },
    },
    contacts: {
      prologisContact: { key: "prologisContact", label: "Primary Contact" },
      secondaryContacts: { key: "secondaryContacts", label: "Additional Contacts (Employee OR Broker)" },
      esmContact: { key: "esmContact", label: "Essential Solutions Manager (ESM)" },
    },
    media: {
      hero: { key: "hero", label: "Hero" },
      aerial: { key: "aerial", label: "Aerial" },
      sitePlan: { key: "sitePlan", label: "Site Plan" },
      propertyMarketingCollateral: { key: "propertyMarketingCollateral", label: "Property Marketing Collateral" },
      gallery: { key: "gallery", label: "Image Gallery" },
    },
  },
};
export const COUNTRY_NAME = {
  BRAZIL: "brazil",
  CANADA: "canada",
  MEXICO: "mexico",
  UNITED_STATES: "united states",
  CHINA: "china",
  JAPAN: "japan",
  SINGAPORE: "singapore",
  SOUTH_KOREA: "south korea",
  AUSTRIA: "austria",
  BELGIUM: "belgium",
  CZECH_REPUBLIC: "czech republic",
  FRANCE: "france",
  Germany: "germany",
  HUNGARY: "hungary",
  ITALY: "italy",
  NETHERLANDS: "netherlands",
  POLAND: "poland",
  ROMANIA: "romania",
  SLOVAKIA: "slovakia",
  SPAIN: "spain",
  SWEDEN: "sweden",
  UNITED_KINGDOM: "united kingdom",
};

export const CONTACT_TYPE_UI_MAPPING = {
  broker: "Broker",
  employee: "Employee",
};

export const FORM_FIELDS_TYPE = {
  regular: "regular",
};

export const BUILDING_REQUIRED_FIELDS_LOOKUP = {
  [FORM_FIELDS_TYPE.regular]: {
    building: BUILDING_REQUIRED_FIELDS.filter(field => field[0] === BUILDING_TABS_KEYS[1]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    keyFeatures: BUILDING_REQUIRED_FIELDS.filter(field => field[0] === BUILDING_TABS_KEYS[2]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    contacts: BUILDING_REQUIRED_FIELDS.filter(field => field[0] === BUILDING_TABS_KEYS[3]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    media: BUILDING_REQUIRED_FIELDS.filter(field => field[0] === BUILDING_TABS_KEYS[4]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    unitInfo: BUILDING_REQUIRED_FIELDS.filter(field => field[0] === BUILDING_TABS_KEYS[5]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
  },
};

export const LAND_REQUIRED_FIELDS_LOOKUP = {
  [FORM_FIELDS_TYPE.regular]: {
    land: LAND_REQUIRED_FIELDS.filter(field => field[0] === LAND_TABS_KEYS[1]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    keyFeatures: LAND_REQUIRED_FIELDS.filter(field => field[0] === LAND_TABS_KEYS[2]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    contacts: LAND_REQUIRED_FIELDS.filter(field => field[0] === LAND_TABS_KEYS[3]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
    media: LAND_REQUIRED_FIELDS.filter(field => field[0] === LAND_TABS_KEYS[4]).reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[currentValue.length - 1]]: true }),
      {},
    ),
  },
};

export const USER_TYPE = {
  BROKER: "broker",
  EMPLOYEE: "employee",
  ESM: "employee",
};

export const USER_ESM_TYPE = {
  JOB_TITLE: "Essentials Solutions Manager",
};

export const PROPERTY_CONTACT_TYPE = {
  primary: "primary",
  secondary: "secondary",
  esm: "esm",
};

export const PROPERTY_CONTACT_TYPE_UI_MAPPING = {
  primary: "Primary",
  secondary: "Secondary",
  esm: "ESM",
};

export const PROPERTY_DRAFT_VERSION_UI_MAPPING = {
  draft_version: i18n.t("PropertiesTable.LatestDraftVersion"),
};

export const MARKET_TYPE_KEYS = {
  market: "market",
  unmarket: "unmarket",
};

export const SUGGESTION_SEARCH_STATE = {
  unitVacancy: "unitVacancy",
  landVacancy: "landVacancy",
  property: "property",
  audit: "audit",
};

export const PROPERTY_CHARACTERISTICS = [
  {
    label: i18n.t("PropertyCharacteristics.CarParking"),
    value: "parking",
  },
  {
    label: i18n.t("PropertyCharacteristics.TrailerParking"),
    value: "trailerParking",
  },
  {
    label: i18n.t("BuildingInfo.TruckCourtDepth"),
    value: "truckCourtDepth",
  },
  {
    label: i18n.t("PropertyCharacteristics.SpeedByDepth"),
    value: "speedBayDepth",
  },
  {
    label: i18n.t("PropertyCharacteristics.ColumnSpacingWidth"),
    value: "typicalColumnSpacing1",
  },
  {
    label: i18n.t("PropertyCharacteristics.ColumnSpacingDepth"),
    value: "typicalColumnSpacing2",
  },
  {
    label: i18n.t("PropertyCharacteristics.DesignedFloorFlatnessLevelness"),
    value: "designedFloorFlatness",
  },
  {
    label: i18n.t("PropertyCharacteristics.SiteSecurity"),
    value: "siteSecurity",
  },
  {
    label: i18n.t("BuildingInfo.RailServed"),
    value: "railServed",
  },
  {
    label: i18n.t("PropertyCharacteristics.CertificationType"),
    value: "greenCertificateType",
  },
  {
    label: i18n.t("PropertyCharacteristics.BackupGenerator"),
    value: "backupGenerator",
  },
  {
    label: i18n.t("PropertyCharacteristics.ElectricCarCharging"),
    value: "electricCarCharging",
  },
  {
    label: i18n.t("PropertyCharacteristics.ElectricTruckCharging"),
    value: "electricTruckCharging",
  },
  {
    label: i18n.t("PropertyCharacteristics.TransformerAmperage"),
    value: "mainTransformerAmp",
  },
  {
    label: i18n.t("PropertyCharacteristics.TransformerVoltage"),
    value: "mainTransformerVoltage",
  },
  {
    label: i18n.t("PropertyCharacteristics.BuildingDepth"),
    value: "buildingDepth",
  },
  {
    label: i18n.t("PropertyCharacteristics.BuildingLength"),
    value: "buildingLength",
  },
  {
    label: i18n.t("PropertyCharacteristics.CrossDock"),
    value: "crossDock",
  },
  {
    label: i18n.t("PropertyCharacteristics.NumberOfFloors"),
    value: "numberOfFloors",
  },
  {
    label: i18n.t("PropertyCharacteristics.UnitOfMeasure"),
    value: "unitOfMeasure",
  },
  {
    label: i18n.t("PropertyCharacteristics.BackSpeedBayDepthMeasurement"),
    value: "backSpeedBayDepthMeasurement",
  },
  {
    label: i18n.t("PropertyCharacteristics.BackTruckCourtDepthMeasurement"),
    value: "backTruckCourtDepthMeasurement",
  },
  {
    label: i18n.t("PropertyCharacteristics.FiberBackboneFlag"),
    value: "fiberBackboneFlag",
  },
];

export const UK_BROKER_IMAGES = {
  jll,
  dtre,
  cbre,
  savills,
  colliers,
  lewis_co: lewisCo,
  m1_agency: m1Agency,
  gerald_eve: geraldEve,
  ilp_partners: ilpPartners,
  avison_young: avisonYoung,
  montagu_evans: montaguEvans,
  logix_property: logixProperty,
  burbage_realty: burbageRealty,
  cushman_wakefield: cushmanWakefield,
  budworth_hardcastle: budworthHardcastle,
  lambert_smith_hampton: lambertSmithHampton,
  colliers_international: colliersInternational,
  dowley_turner_real_estate: dowleyTurnerRealEstate,
};

export const PROPERTY_KIND = [
  { value: false, text: "Permanent", label: "Permanent" },
  { value: true, text: "Temporary", label: "Temporary" },
  { value: undefined, text: "Both", label: "Both" },
];

export const PROPERTY_KIND_LOOKUP = {
  permanent: false,
  temporary: true,
};

export const HOME_UNITS_TYPE = {
  currentVacantProperties: "vacantProperties",
  upcomingVacantProperties: "upcomingVacantProperties",
  unMarketedProperties: "notMarketingProperties",
};

export const HOME_PROPERTIES_TITLE = {
  currentVacantProperties: "current vacant properties",
  upcomingVacantProperties: "upcoming vacant properties",
  unMarketedProperties: "unmarketed properties",
  openAuditIssues: "Open Audits",
};

export const HOME_PROPERTIES_TYPE = {
  vacantProperties: "currentVacantProperties",
  upcomingProperties: "upcomingVacantProperties",
  unmnarketedProperties: "unMarketedProperties",
  openAuditIssues: "openAuditIssues",
};
