import _filter from "lodash/filter";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import buildingService from "../../services/building.service";
import EmployeeService from "../../services/employee.service";
import propertyService from "../../services/property.service";
import { ERROR_MESSAGE, SEARCH_QUERY, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  BUILDING_LOADING: "loading",
  AUDIT_LOADING: "auditLoading",
  USERS_LOADING: "usersLoading",
};

export function buildingRequest(key = LOADING.BUILDING_LOADING) {
  return { type: ACTIONS.BUILDING_REQUEST, key };
}

export function buildingSuccess(response) {
  return { type: ACTIONS.BUILDING_SUCCESS, response };
}

export function buildingCodeSuccess(response) {
  return { type: ACTIONS.BUILDING_CODE_SUCCESS, response };
}

export function publishedUnitsSuccess(response) {
  return { type: ACTIONS.BUILDING_PUBLISHED_UNITS_SUCCESS, response };
}

export function buildingUOMSuccess(response) {
  return { type: ACTIONS.BUILDING_UOM_SUCCESS, response };
}

export function totalBuildingSizeSuccess(response) {
  return { type: ACTIONS.BUILDING_TOTAL_BUILDING_SIZE_SUCCESS, response };
}

export function buildingOccupiedSpaceSuccess(response) {
  return { type: ACTIONS.BUILDING_OCCUPIED_SPACE_SUCCESS, response };
}

export function buildingError(error) {
  return { type: ACTIONS.BUILDING_ERROR, error };
}

export function updateCombinedUnitNumber(maxCombinedUnitNumber) {
  return { type: ACTIONS.BUILDING_COMBINED_UNIT_NUMBER, maxCombinedUnitNumber };
}

export function getMarketUsersSuccess() {
  return { type: ACTIONS.GET_MARKET_USERS_SUCCESS };
}

export function unitDetailSuccess(response) {
  return { type: ACTIONS.BUILDING_UNIT_DETAIL_SUCCESS, response };
}

/**
 * To update building record which doesn't have
 * never published status
 */
export function updateBuilding(id, buildingInfo, successMsg) {
  return async dispatch => {
    dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    try {
      const [err, response] = await to(buildingService.updateBuildingById(id, buildingInfo));
      if (err) throwError(err);

      toastMessage("success", successMsg || SUCCESS_MESSAGE.EDITED);
      dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

/**
 * To update unit record for which building doesn't have
 * never published status
 */
export function updateUnit(body, successMsg, unitIndexToSave) {
  return async dispatch => {
    dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    try {
      const [err, response] = await to(buildingService.updateUnit(body));
      const updatedUnitInfo = _get(response, "unitInfo", []);
      const unitDetail = { source: updatedUnitInfo, index: unitIndexToSave };
      if (err) throwError(err);
      dispatch(unitDetailSuccess(unitDetail));

      toastMessage("success", successMsg || SUCCESS_MESSAGE.EDITED);
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

/**
 * To add building record which have
 * never published status
 */
export function addBuilding(buildingInfo) {
  return async dispatch => {
    dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    try {
      const [err, response] = await to(buildingService.addBuilding(buildingInfo));
      if (err) throwError(err);

      toastMessage("success", SUCCESS_MESSAGE.ADDED);
      dispatch(buildingSuccess({}));
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.ADDED);
      throwError(error);
    }
  };
}

/**
 * To get building record along with its characteristics
 * and details coming from snowflake
 */
export function getBuildingById(id) {
  return async dispatch => {
    dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    try {
      let building, err, pCode;

      /**
       * Building details with characteristics and available units
       * from snowflake
       */
      [err, pCode] = await to(propertyService.getPropertyCodes({ ...SEARCH_QUERY, id }));

      /**
       * Building details added into DPM
       */
      [err, building] = await to(buildingService.getBuildingById(id));

      if (err) throwError(err);

      if (_isEmpty(pCode) && _isEmpty(building)) {
        toastMessage("error", "No record found!");
        return dispatch(buildingSuccess({}));
      }
      const { unitInfo, ...rest } = pCode;
      const _uInfo = unitInfo || [];

      /**
       * Merge snowflake units and new units created in DPM
       */
      const pCodeUnits = new Set(_uInfo.map(d => d.unitId));
      const _unitInfo = [..._uInfo, ...(building?.unitInfo || []).filter(d => !pCodeUnits.has(d?.unitId))];
      const _availableUnits = _map(_unitInfo, ({ unitId: _unitId, ...r }) => {
        const isAvailable = _findIndex(building?.unitInfo || [], { unitId: _unitId }) === -1;
        return {
          ...r,
          unitId: _unitId,
          isAvailable,
          isNew: !pCodeUnits.has(_unitId),
        };
      });

      /**
       * Filter units with less than 1000 square foot area.
       */
      const filteredUnits = _filter(
        _availableUnits,
        u =>
          !(
            u.unitAreaSF < 1000 && _isEmpty(_find(building.unitInfo, buildingUnit => buildingUnit.unitId === u.unitId))
          ),
      );

      pCode = { ...rest, units: filteredUnits };

      // characteristics
      if (!building) {
        building = {
          status: pCode.status,
          market: { ...pCode?.market },
          subMarket: pCode?.subMarket,
          buildingInfo: {
            propertyCode: pCode?.id,
            marketingPropertyName: pCode?.propertyInfo?.marketingPropertyName || 0,
            truckCourtDepth: Number(pCode?.characteristics?.truckCourtDepth || 0).toFixed(4),
            railServed: pCode?.characteristics?.railServed === "1",
            totalBuildingSize: pCode?.propertyInfo?.totalBuildingSize,
            totalBuildingSizeUOM: pCode?.propertyInfo?.totalBuildingSizeUOM,
            address: {
              streetAddress1: pCode?.address?.streetAddress1,
              streetAddress2: pCode?.address?.streetAddress2,
              postalCode: pCode?.address?.zipCode,
              city: pCode?.address?.city,
            },
          },
          latitude: pCode?.latitude,
          longitude: pCode?.longitude,
          // units
        };
      } else {
        building = {
          ...building,
        };
      }

      /**
       * To assign unmarket state of unit which comes from snowflake
       * details table
       */

      building.unitInfo = _map(building.unitInfo, _unit => {
        const { isUnmarket } = _find(_availableUnits, { unitId: _unit.unitId });
        return { ..._unit, isUnmarket };
      });
      dispatch(buildingCodeSuccess(pCode));

      if (!_isEmpty(building)) {
        dispatch(buildingSuccess(building));
        return building;
      }
      return {};
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      return throwError(error);
    }
  };
}

export function getMarketUsers(query) {
  return async dispatch => {
    dispatch(buildingRequest(LOADING.USERS_LOADING));
    try {
      const [err, response] = await to(EmployeeService.suggestions(query));
      if (err) throwError(err);
      dispatch(getMarketUsersSuccess());
      return response;
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

/**
 * To update audit status of a building
 * which only Admins and Auditors can perform
 */
export function updateBuildingAuditStatus(id, body) {
  return async dispatch => {
    try {
      const [err, response] = await to(buildingService.updateAuditStatus(id, body));
      await dispatch(getBuildingById(id));
      if (err) throwError(err);
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

/**
 * To update building record which doesn't have
 * never published status and
 * update audit status of a building
 * which only Admins and Auditors can perform
 */
export function buildingPublishAndPassAudit(id, buildingInfo, successMsg, auditStatus) {
  const { propertyCode } = buildingInfo.buildingInfo;
  return async dispatch => {
    dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    try {
      const [err, response] = await to(buildingService.updateBuildingById(id, buildingInfo));
      if (err) throwError(err);

      const [auditErr, auditResponse] = await to(buildingService.updateAuditStatus(propertyCode, auditStatus));
      if (err) throwError(err);

      toastMessage("success", successMsg || SUCCESS_MESSAGE.EDITED);
      dispatch(buildingRequest(LOADING.BUILDING_LOADING));
    } catch (error) {
      dispatch(buildingError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}
