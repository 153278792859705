import FeatureFlagService from "../../services/featureFlag.service";
import { ERROR_MESSAGE } from "../../utils/constants";
import { setLocalFeatureFlags, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const FEATURE_FLAG_LOADING = "loading";

export function featureFlagRequest(key = FEATURE_FLAG_LOADING) {
  return { type: ACTIONS.FEATURE_FLAG_REQUEST, key };
}

export function featureFlagListSuccess(response) {
  return { type: ACTIONS.FEATURE_FLAG_LIST_SUCCESS, response };
}

export function featureFlagSuccess(response) {
  return { type: ACTIONS.FEATURE_FLAG_SUCCESS, response };
}

export function featureFlagError(error, key = FEATURE_FLAG_LOADING) {
  return { type: ACTIONS.FEATURE_FLAG_ERROR, error, key };
}

export function toggleFeatureFlags(payload) {
  return async (dispatch, getState) => {
    dispatch(featureFlagRequest());
    try {
      const [err, response] = await to(FeatureFlagService.update(payload.id, payload));
      if (err) throwError(err);
      dispatch(featureFlagSuccess({}));
    } catch (error) {
      dispatch(featureFlagError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function getAllFeatureFlags() {
  return async dispatch => {
    dispatch(featureFlagRequest());
    try {
      const [err, response] = await to(FeatureFlagService.getAll());
      if (err) throwError(err);
      const flagsLookup = {};
      if (response.length > 0) {
        response.forEach(element => {
          if (!flagsLookup[element.id]) {
            flagsLookup[element.id] = element;
          }
        });
      }
      setLocalFeatureFlags(flagsLookup);
      dispatch(featureFlagSuccess(flagsLookup));
      dispatch(featureFlagListSuccess(response));
    } catch (error) {
      dispatch(featureFlagError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}
