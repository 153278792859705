import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import dayjs from "dayjs";
import _find from "lodash/find";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PopUp from "../../components/PopUp/PopUp";
import PropertyCodeSelect from "../../components/PropertyCodeSelect/PropertyCodeSelect";
import { useLanguage } from "../../hooks";
import { sendAnalyticsEvent } from "../../store/actions/analytics.actions";
import { addBuilding } from "../../store/actions/building.actions";
import { addLand } from "../../store/actions/land.actions";
import {
  activePropertySubMarketsSuccess,
  getAllPropertyMarketsSuccess,
  getPropertyAllMarkets,
  getPropertyCodes,
  propertyCodesSuccess,
} from "../../store/actions/property.actions";
import {
  GTAG_EVENTS,
  HELPER_TEXT_KEYS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
  ROUTES,
  SEARCH_QUERY,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
} from "../../utils/constants";
import {
  getFeatureFlags,
  getTooltip,
  toastMessage,
  validateLatitude,
  validateLongitude,
  validateMaxValue,
  validateMinValue,
} from "../../utils/utils";
import "./AddPropertyForm.scss";

const { Option } = Select;

const AddPropertyForm = ({
  onClose,
  propertyType,
  description,
  newPropertyLabel,
  propertyCodeLabel,
  propertyCodePlaceholder,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { lookupList } = useSelector(state => state.helperText);
  const {
    marketList: markets,
    subMarketActiveList: subMarkets,
    loadingSubMarkets,
  } = useSelector(state => ({
    ...state.property,
  }));
  const { language } = useLanguage();
  const [loadingData, setLoadingData] = useState(false);
  const history = useHistory();
  const [editTimestamp, setEditTimestamp] = useState();
  const [propertyCodeError, setPropertyCodeError] = useState({});
  const [property, setProperty] = useState({});
  const [isTemporaryProperty, setIsTemporaryProperty] = useState(false);
  const [popupVisible, togglePopupVisibility] = useState(false);

  const dispatch = useDispatch();

  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    setEditTimestamp(dayjs().valueOf());
    return clearStoreState;
  }, []);

  const clearStoreState = () => {
    form.resetFields();
    dispatch(propertyCodesSuccess([]));
    if (FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess([]));
  };

  const _setSelectedProperty = async params => {
    setLoadingData(true);
    try {
      setProperty({});
      const _property = await dispatch(getPropertyCodes({ ...SEARCH_QUERY, id: params.value }));

      form.setFieldsValue({
        propertyCode: params.value,
        market: _property?.market?.id,
        subMarket: typeof _property?.subMarket === "object" ? _property?.subMarket?.id : _property?.subMarket,
        latitude: _property?.latitude,
        longitude: _property?.longitude,
      });
      if (FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess([]));
      setProperty(_property);
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setLoadingData(false);
    }
  };

  const _setIsTemporaryProperty = isTemp => {
    setIsTemporaryProperty(isTemp);
    if (isTemp) dispatch(getPropertyAllMarkets());
    else dispatch(getAllPropertyMarketsSuccess({ list: [] }));
  };

  const _onChangeMarket = key => {
    const _values = form.getFieldsValue();
    const market = _find(markets, m => m.id === key);
    const subMarketList = _get(market, "subMarkets", []);
    if (FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess(subMarketList));
    form.setFieldsValue({
      ..._values,
      subMarket: undefined,
    });
  };

  const createProperty = async values => {
    switch (propertyType) {
      case PROPERTY_TYPE_KEYS.building:
        try {
          await dispatch(addBuilding(values));
          dispatch(sendAnalyticsEvent(GTAG_EVENTS.DRAFT_PROPERTY, { propertyCode: values.id }));
          history.push({ pathname: `${ROUTES.EDIT_BUILDING.path}/${values.id}` });
          onClose();
        } catch (err) {
          console.log(err);
        }
        break;
      case PROPERTY_TYPE_KEYS.land:
        try {
          await dispatch(addLand(values));
          history.push({ pathname: `${ROUTES.EDIT_LAND.path}/${values.id}` });
          onClose();
        } catch (err) {
          console.log(err);
        }
        break;

      default:
        break;
    }
  };

  /**
   * Function for generating a payload
   * and invoking the API for creating building
   */
  const saveForLater = () => {
    const keysToValidate = [["propertyCode"], ["market"]];

    if (!_isEmpty(propertyCodeError)) {
      return toastMessage("error", propertyCodeError.help || t("ErrorMessages.SelectPropertyCode"));
    }

    setLoadingData(true);
    return form
      .validateFields(keysToValidate)
      .then(async () => {
        let values = { ...form.getFieldsValue() };
        const { latitude, longitude, propertyCode } = values;
        const propertyForm = {
          [`${propertyType}Info`]: {
            propertyCode,
            propertyType,
          },
        };

        values = {
          ...values,
          id: propertyCode,
          status: PROPERTY_STATUS.never_published,
          latitude: latitude ? Number(latitude) : 0,
          longitude: longitude ? Number(longitude) : 0,
          isTemp: isTemporaryProperty,
          isDraft: true,
          ...propertyForm,
          editTimestamp,
        };
        createProperty(values);
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        toastMessage("error", errorFields?.[0]?.errors?.[0] || t("ErrorMessages.SelectPropertyCode"));
      })
      .finally(() => setLoadingData(false));
  };

  const isPropertyDisable = key =>
    !isTemporaryProperty && !_isEmpty(property) && (_isNumber(property[key]) || !_isEmpty(property[key]));

  const latDisabled = isPropertyDisable("latitude");
  const lonDisabled = isPropertyDisable("longitude");
  const subMarketDisabled = isPropertyDisable("subMarket");

  return (
    <>
      <p>{description}</p>
      <Form form={form} layout="vertical" name="nest-messages" validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}>
        <Row gutter={20}>
          <Col span={8} xs={24} sm={12} lg={10}>
            <PropertyCodeSelect
              newCodeLabel={newPropertyLabel}
              label={propertyCodeLabel}
              placeholder={propertyCodePlaceholder}
              queryParam={{
                filterCriteria: {
                  status: [PROPERTY_STATUS.never_published],
                  isDraft: false,
                  propertyType: [propertyType],
                },
              }}
              formKey={["propertyCode"]}
              isEditView={false}
              setSelectedProperty={_setSelectedProperty}
              viewOnlyMode={false}
              setIsTemporaryProperty={_setIsTemporaryProperty}
              propertyCodeError={propertyCodeError}
              setPropertyCodeError={setPropertyCodeError}
              tooltip={lookupList[HELPER_TEXT_KEYS[propertyType].propertyCode]}
            />
            <Form.Item
              tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS[propertyType].market], language)}
              label={t("Property.Market")}
              name={["market"]}
              rules={[{ required: true }]}
              shouldUpdate
            >
              <Select
                onChange={_onChangeMarket}
                showSearch
                placeholder={t("Property.MarketPlaceholder")}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                autoComplete="off"
                disabled={!isTemporaryProperty}
              >
                {_map(markets, market => (
                  <Option key={market.id} value={market.id}>
                    {market.id}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} xs={24} sm={12} lg={14}>
            <Row gutter={20}>
              <Col span={12} xs={24} sm={12}>
                <Form.Item
                  tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS[propertyType].latitude], language)}
                  label={t("Property.Latitude")}
                  name={["latitude"]}
                  rules={[
                    {
                      validator: (rule, value) => validateLatitude(value),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("Property.Latitude"), -90),
                    },
                    {
                      validator: (rule, value) => validateMaxValue(value, t("Property.Latitude"), 90),
                    },
                  ]}
                >
                  <Input placeholder={t("Property.Latitude")} disabled={latDisabled} />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <Form.Item
                  tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS[propertyType].longitude], language)}
                  label={t("Property.Longitude")}
                  name={["longitude"]}
                  rules={[
                    {
                      validator: (rule, value) => validateLongitude(value),
                    },
                    {
                      validator: (rule, value) => validateMinValue(value, t("Property.Longitude"), -180),
                    },
                    {
                      validator: (rule, value) => validateMaxValue(value, t("Property.Longitude"), 180),
                    },
                  ]}
                >
                  <Input placeholder={t("Property.Longitude")} disabled={lonDisabled} />
                </Form.Item>
              </Col>
            </Row>
            {FEATURE_FLAG.SUBMARKET?.status && (
              <Form.Item
                tooltip={getTooltip(lookupList[HELPER_TEXT_KEYS[propertyType].submarket], language)}
                label={t("Property.Submarket")}
                name={["subMarket"]}
                shouldUpdate
              >
                <Select
                  loading={loadingSubMarkets}
                  showSearch
                  placeholder={t("Property.SubmarketPlaceholder")}
                  optionFilterProp="value"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  autoComplete="off"
                  disabled={subMarketDisabled}
                >
                  {_map(subMarkets, subMarket => (
                    <Option key={subMarket.id} value={subMarket.id}>
                      {subMarket.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <Row justify="center" gutter={24}>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block onClick={onClose}>
            {t("Buttons.Cancel")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Button block disabled={loadingData} loading={loadingData} onClick={saveForLater} type="primary">
            {t("Table.Add")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

AddPropertyForm.propTypes = {
  onClose: PropTypes.func,
  propertyType: PropTypes.string,
  description: PropTypes.string,
  newPropertyLabel: PropTypes.string,
  propertyCodeLabel: PropTypes.string,
  propertyCodePlaceholder: PropTypes.string,
};

export default AddPropertyForm;
