import { message, notification } from "antd";
import dayjs from "dayjs";
import advanceFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import _isNumber from "lodash/isNumber";
import _isNaN from "lodash/isNaN";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isString from "lodash/isString";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import React from "react";
import CustomIcon from "../components/CustomIcon/CustomIcon";
import i18n from "../i18n";

dayjs.extend(timezone);
dayjs.extend(advanceFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "just now",
    m: "a minute ago",
    mm: "%d minutes ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a day ago",
    dd: "%d days ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

export const TableConfig = columnName => ({
  page: 0,
  size: 20,
  sort: `${columnName},asc`,
});
export const to = promise => promise.then(data => [null, data]).catch(err => [err]);

export const throwError = (errMessage, log) => {
  if (log === true) {
    // eslint-disable-next-line no-console
    console.error(errMessage);
  }
  throw errMessage;
};

export const ellipsis = (str, characterLimit) => {
  if (typeof str === "string" && str.length >= characterLimit) {
    return `${str.substring(0, characterLimit)}...`;
  }
  return str;
};

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const toastMessage = (type, description, title, obj = {}, onClick = null) => {
  const object = obj;
  object.key = Date.now();

  if (type === "success") {
    object.message = title || "Success";
  }
  if (type === "info") {
    object.message = title || "Info";
  }
  if (type === "warning") {
    object.message = title || "Warning";
  }
  if (type === "error") {
    object.message = title || "Error";
  }

  object.description = description || object.description;
  object.onClick = () => {
    if (onClick) onClick();
    notification.close(object.key);
  };

  return notification[type]({
    ...object,
  });
};

export const beforeUpload = file => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return Promise.reject(new Error(true));
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error("Image must smaller than 10MB!");
    return Promise.reject(new Error(true));
  }
  return false;
};

export const beforeUploadExcel = file => {
  const fileTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  const isValid = fileTypes.includes(file.type);
  if (!isValid) {
    toastMessage("error", "You can only upload xls, xlsx or csv file!");
  }
  return isValid;
};

export const isJSON = str => {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    if (str === "null") {
      // TODO: check
      return true;
    }
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
};

export const queryGenerator = ({ currentPage, pageSize, sorter, filters }) => {
  let sort;
  if (!_isEmpty(sorter)) {
    const order = sorter.order === "ascend" ? "asc" : "desc";
    sort = `${sorter.field},${order}`;
  }
  return {
    page: currentPage - 1,
    size: pageSize,
    sort,
    direction: sorter.order,
    ...filters,
  };
};

export const searchQueryGenerator = ({ keyword, filterCriteria, currentPage, pageSize, sorter, filters, ...args }) => {
  let sort;
  if (!_isEmpty(sorter)) {
    if (_isArray(sorter)) {
      sort = _map(sorter, v => {
        const order = v.order === "ascend" || v.order === "asc" ? "asc" : "desc";
        return `${v.field},${order}`;
      });
    } else {
      const order = sorter.order === "ascend" || sorter.order === "asc" ? "asc" : "desc";
      sort = `${sorter.field},${order}`;
    }
  }
  return {
    ...args,
    page: currentPage - 1,
    size: pageSize || 20,
    sort,
    keyword,
    filterCriteria,
    ...filters,
  };
};

export const getSearchListQuery = query => {
  let params = `?from=0&size=20`;
  if (query) {
    params = `?from=${query.page || 0}&size=${query.size || 20}`;
    if (!_isEmpty(query.sort)) {
      params += `&sort=${query.sort}`;
    }
    if (query.keyword) {
      params += `&keyword=${query.keyword}`;
    }
    if (query.propertyType) {
      params += `&propertyType=${query.propertyType}`;
    }
    if (query.filterCriteria) {
      checkEmptyObj(query.filterCriteria);
      params += `&filterCriteria=${encodeURIComponent(JSON.stringify(query.filterCriteria))}`;
    }
    if (query.filters) {
      const { filters } = query;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        // eslint-disable-next-line no-prototype-builtins
        if (filters.hasOwnProperty(key)) {
          const element = filters[key];
          params += `&${key}=${element}`;
        }
      }
    }
  }

  return params;
};

export const getQueryParams = query => {
  checkEmptyObj(query.filterCriteria);
  const sort = _isArray(query.sort) ? query.sort : [query.sort];
  const queryParam = {
    ...query,
    from: query.page || 0,
    filterCriteria: query.filterCriteria,
    sort: JSON.stringify(sort),
  };
  delete queryParam.page;
  return queryParam;
};

export const getMapQueryParams = query => ({ from: 0, size: 10000, fields: query ? JSON.stringify(query) : [] });

export const checkEmptyObj = data => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === "object") {
        checkEmptyObj(data[key]);
      }
      if (
        data[key] === "" ||
        data[key] === null ||
        data[key] === undefined ||
        (typeof data[key] === "object" && _isEmpty(data[key])) ||
        (typeof data[key] === "string" && _isEmpty(data[key]?.trim()))
      ) {
        delete data[key];
      }
    }
  }
  return data;
};

export const validatePhoneNumber = phone => {
  phone = phone.replace(/\s/g, "");
  const phoneValidator = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
  );
  return phoneValidator.test(phone);
};

export const isValidPhoneNumber = (rule, value) => {
  if (value && !validatePhoneNumber(value)) {
    return Promise.reject(new Error(`${i18n.t("ErrorMessages.InvalidNumber")}`));
  }
  return Promise.resolve();
};

export const validateCode = code => {
  const codeValidator = new RegExp(/^[A-Z]+([.]?[_]([&]_)?[0-9A-Z]+(['][A-Z]+[0-9A-Z]*)*)*$/);
  return codeValidator.test(code);
};

export const validateAlphaNumeric = code => {
  const codeValidator = new RegExp(/^([^*|":<>[\]{}`\\/!~+=()';@&%^$#]*)?$/);
  return codeValidator.test(code);
};

export const validateAlphaNumericWithoutSpace = code => {
  const codeValidator = new RegExp(/^([A-Za-z\d][\d\w-]*)?$/);
  return codeValidator.test(code);
};

export const validateNumericField = (code, label) => {
  const codeValidator = new RegExp(/^([-+]?[0-9]+(\.[0-9]+([eE][-+]?[0-9]+)?|([eE][-+]?[0-9]+)?)?)?$/);
  if (code && !codeValidator.test(code)) {
    return Promise.reject(new Error(`${label} ${i18n.t("ErrorMessages.NumericOnly")}`));
  }
  return Promise.resolve();
};

export const validateLatitude = code => {
  const codeValidator = new RegExp(/^[+-]?\d{1,2}([.]\d{1,8})?$/);
  if (code && (!codeValidator.test(code) || Number(code) === 0)) {
    return Promise.reject(new Error(i18n.t("ErrorMessages.LatitudeFormat")));
  }
  return Promise.resolve();
};

export const validateLongitude = code => {
  const codeValidator = new RegExp(/^[+-]?\d{1,3}([.]\d{1,8})?$/);
  if (code && (!codeValidator.test(code) || Number(code) === 0)) {
    return Promise.reject(new Error(i18n.t("ErrorMessages.LongitudeFormat")));
  }
  return Promise.resolve();
};

export const isBase64 = str => {
  if (str === "" || (_isString(str) && str.trim() === "")) {
    return false;
  }
  try {
    return window.btoa(window.atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const downloadFile = (file, filename) => {
  const link = document.createElement("a");
  if (!isBase64(file)) {
    link.href = file;
    link.onclick = "window.open(this.href,'_blank');return false";
  } else {
    link.href = `data:application/octet-stream;base64,${file}`;
    link.setAttribute("download", filename);
    link.target = "_blank";
  }
  document.body.appendChild(link);
  link.click();
};

export const validateMinMax = ({ currValue, depValue, type, fieldName, depLabel }) => {
  if (type === "min") {
    if (!currValue || !depValue || +depValue <= +currValue) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(`${fieldName} ${i18n.t("ErrorMessages.CannotLess")} ${depLabel}`));
  }
  if (type === "max") {
    if (!currValue || !depValue || +depValue >= +currValue) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(`${fieldName} ${i18n.t("ErrorMessages.CannotGreater")} ${depLabel}`));
  }
  return Promise.reject(new Error("validation error"));
};

export const validateInteger = (value, field) => {
  if (!_isEmpty(value) && _isNumber(+value) && !Number.isInteger(+value)) {
    return Promise.reject(new Error(`${field} ${i18n.t("ErrorMessages.WithoutDecimals")}`));
  }
  return Promise.resolve();
};

export const validateMinValue = (value, field, minValueRequired) => {
  if (!_isEmpty(value) && _isNumber(+value) && Number(value) < +minValueRequired) {
    return Promise.reject(new Error(`${field} ${i18n.t("ErrorMessages.CannotLess")} ${minValueRequired}`));
  }
  return Promise.resolve();
};

export const validateMarketingDescription = (value, field) => {
  if (!_isEmpty(value) && !_isEmpty(value.trim())) {
    if (value.includes("[") || value.includes("]")) {
      return Promise.reject(new Error(`${field} ${i18n.t("FormMessages.AddValidDescription")}`));
    }
  }
  return Promise.resolve();
};

export const validateOccupiedSpaceLimit = (occupiedValue, availableSpace) =>
  Number(occupiedValue) <= +availableSpace
    ? Promise.resolve()
    : Promise.reject(
      new Error(`The Unit(s) 'Min / Max Building Space' cannot be greater than Total Available Building Size.`),
    );

export const validateMinValueGreater = (value, field, minValueRequired) =>
  Number(value) > +minValueRequired
    ? Promise.resolve()
    : Promise.reject(new Error(`${field} ${i18n.t("ErrorMessages.GreaterThan")} ${minValueRequired}`));

export const validateMaxValue = (value, field, maxValueRequired) =>
  Number(value) <= +maxValueRequired
    ? Promise.resolve()
    : Promise.reject(new Error(`${field} ${i18n.t("ErrorMessages.CannotGreater")} ${maxValueRequired}`));

export const removeUnderscore = str => str && str.replace("_", " ");

export const getMatchedValues = (src, target) => {
  const _tags = _map(src, val => {
    if (target.some(t => t?.value === val || t?.id === val)) return val;
    return false;
  });
  return removeEmptyElements(_tags);
};

export const removeEmptyElements = arr => arr.filter(el => el);

export const replaceIfNull = (ele, subs) => ele || subs || "-";

export const isImageFileType = item => item.asset_category_type === "image";

export const isPDFFileType = item => item.asset_category_type === "document";

export const validateDependant = (form, parentKey, keys, index) => {
  const _keysToValidate = [];
  _forEach(keys, (v1, i) => {
    if (index !== undefined) {
      _keysToValidate.push([parentKey, index, v1]);
    } else {
      _keysToValidate.push([parentKey, v1]);
    }
  });
  form.validateFields([..._keysToValidate]);
};

export const getDateDifference = date => {
  dayjs.extend(duration);
  const timeDuration = dayjs.duration(date);
  const days = timeDuration.days();
  const hours = timeDuration.hours();
  const minutes = timeDuration.minutes();
  return `${days} Days, ${hours} Hours and ${minutes} Minutes`;
};

const createCustomEvent = (eventName, additionalData) => {
  if (window) {
    return new CustomEvent(eventName, {
      detail: {
        additionalData,
      },
    });
  }
  return null;
};

export const dispatchCustomEvent = (eventName, extraData) => {
  const typeOfEvent = createCustomEvent(eventName, extraData);
  document.dispatchEvent(typeOfEvent);
};

export const getSorterFilter = (sorter, payload) =>
  _isArray(sorter) && sorter.length > 1 ? { ...payload, sorter: sorter[sorter.length - 1] } : payload;

export const getSorterObj = sort => {
  let sorter;
  if (_isArray(sort)) {
    sorter = _map(sort, v => {
      const sortArr = v.split(",");
      const order = sortArr[1] === "ascend" || sortArr[1] === "asc" ? "ascend" : "descend";
      return { field: sortArr[0], order };
    });
  } else {
    const sortArr = sort.split(",");
    const order = sortArr[1] === "ascend" || sortArr[1] === "asc" ? "ascend" : "descend";
    sorter = { field: sortArr[0], order };
  }
  return sorter;
};

export const filterOption = (arr, matchedValue, key = "value") => {
  const obj = _find(arr, c => c[key] === matchedValue);
  return obj?.value || undefined;
};

export const setCountryAddressFormFields = (form, countryAddressFields, info) => {
  const { address, ..._info } = form.getFieldValue(info);
  form.setFieldsValue({
    [info]: {
      ..._info,
      address: {
        ...address,
        country: countryAddressFields?.country,
        dosi: filterOption(countryAddressFields?.dosi, address?.dosi),
        state: filterOption(countryAddressFields?.state, address?.state),
        prefecture: filterOption(countryAddressFields?.prefecture, address?.prefecture),
        province: filterOption(countryAddressFields?.province, address?.province),
      },
    },
  });
};

export const updateCountryAddressFields = (country, data, callback) => {
  try {
    const fields = _find(data, { id: country?.toLocaleLowerCase() });
    callback(fields);
  } catch (ex) {
    console.log("ex", ex);
  }
};

export function trimObject(data) {
  if (data) {
    Object.keys(data).forEach(item => {
      if (typeof data[item] === "string") data[item] = data[item].trim();
      else if (_isArray(data[item])) data[item] = data[item].map(e => trimObject(e));
      else if (typeof data[item] === "object") data[item] = trimObject(data[item]);
    });
  }
  return data;
}

export const populateDisplayName = form => {
  const firstName = form.getFieldValue("firstName") || "";
  let lastName = form.getFieldValue("lastName") || "";
  lastName = lastName ? `${lastName.trim()}, ` : "";
  let email = form.getFieldValue("email") || "";
  email = email ? ` - ${email.trim()}` : "";
  let phone = form.getFieldValue("phone") || "";
  phone = _isString(phone) ? `, ${phone.trim()}` : "";

  const displayName = `${lastName}${firstName.trim()}${email}${phone}`;

  form.setFieldsValue({ displayName });
};

export const getTranslatedHelperText = (helperTextItem, currentLanguage) => {
  if (!_isEmpty(helperTextItem)) {
    if (
      currentLanguage.current !== "en" &&
      helperTextItem.translations &&
      helperTextItem.translations[currentLanguage.current]
    ) {
      return helperTextItem.translations[currentLanguage.current].helperText;
    }
    return helperTextItem.helperText;
  }
  return null;
};

export const getDangerouslySetHTML = html => <div dangerouslySetInnerHTML={{ __html: html }} />;

export const getTooltip = (helperText, currentLanguage) =>
  _isEmpty(helperText)
    ? null
    : {
      title: getDangerouslySetHTML(getTranslatedHelperText(helperText, currentLanguage)),
      icon: <CustomIcon name="InfoCircleOutlined" />,
    };

export const convertToSlug = text =>
  text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");

export const convertToInternatialFormat = x => x.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

export const getFormattedDate = date => dayjs(date).format("MM/DD/YYYY HH:mm z");

export const submitSearchForm = (flag = true, option, pagination, form, onSubmitCB, applySort) => {
  let sort = flag,
    _option = option;
  if (flag && _isEmpty(_option) && !_isEmpty(pagination) && !_isEmpty(pagination.labelInValue)) {
    sort = false;
    _option = pagination.labelInValue;
  } else if (!_isEmpty(pagination) && _isEmpty(pagination.sort)) {
    sort = false;
  }

  if (applySort) applySort(sort);

  if (_isEmpty(_option)) {
    form.submit();
  } else {
    onSubmitCB(form.getFieldsValue(), _option);
  }
};

export const setActiveTab = (_tabs, _tabsKey, defaultTab, cb) => {
  const activeTab = window.location.hash?.split("#")?.pop();
  cb(_tabs[activeTab]?.key || defaultTab);
};

export const setSessionStorage = (key, payload) => sessionStorage.setItem(key, JSON.stringify(payload));

export const getSessionStorage = key => JSON.parse(sessionStorage.getItem(key));

export const validateMaxAvailableUnitSpaces = (form, unitInfo) => {
  const keysToValidate = [];
  _map(unitInfo, (v, i) => keysToValidate.push(["unitInfo", i, "maxSpaceAvailable"]));
  form.validateFields(keysToValidate);
};

export const combineUnitsMaxSpaces = unitInfo =>
  unitInfo?.reduce((acc, cur) => acc + (+cur?.maxSpaceAvailable || 0), 0);

export const extractContent = s => {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const getTemplateList = (templates, key, type) =>
  templates
    .filter(v => v.field.value === key && v.type.includes(type))
    .map(item => ({ ...item, value: item.id, label: item.name }));

export const findInObject = (obj, value, key = "value") => Object.values(obj).find(_obj => _obj[key] === value);

export const sortAlphabetically = arr => _sortBy(arr, i => (i.name || i.label || i.text || i.id).toUpperCase());

export const sortAlphabeticallyBySingleParameter = arr => _sortBy(arr, i => i.toUpperCase());

export const sortByDate = (arr, dateKey) => _sortBy(arr, i => dayjs(i[dateKey])).reverse();

export const getFeatureFlags = () => (isJSON(localStorage.featureFlag) ? JSON.parse(localStorage.featureFlag) : {});

export const setLocalFeatureFlags = data => localStorage.setItem("featureFlag", JSON.stringify(data));

export const ifIncludes = (arr, value) => arr.includes(value);

export const getFieldLabel = (propertyType, field, fieldLookup, tabsLookup) => {
  let _label;
  if (field.tab === tabsLookup?.[5]) {
    if (field.name?.includes("feature")) {
      _label = fieldLookup?.building?.unitInfo?.keyFeatures?.[field.name]?.label;
    } else {
      _label = fieldLookup?.building?.unitInfo?.[field.name]?.label;
    }
  } else {
    _label = fieldLookup?.[propertyType]?.[field.tab]?.[field.name]?.label;
  }
  return _label;
};

export const renderAvatarName = string => {
  const strings = string.split(" ");
  const first = string.charAt(0);
  const last = strings[strings.length - 1].charAt(0);

  return first + last;
};

export const getTimeAgo = date => dayjs(date).fromNow();

export const getUploadQueryParams = query => `?filenames=${encodeURIComponent(JSON.stringify(query))}`;

export const getFormDataFiles = filesList => {
  const formData = new FormData();
  filesList.forEach(file => {
    formData.append("file", file.originFileObj || file);
  });
  return formData;
};

export const validateNumberValue = value => value && _isNumber(value) && !_isNaN(value);

export const removedEmptyObject = data => {
  const results = _filter(data, element => {
    if (Object.keys(element).length !== 0) {
      return true;
    }
    return false;
  });
  return results;
};
