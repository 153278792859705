import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const propertyRollUp = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.PROPERTYROLLUP_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.PROPERTYROLLUP_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.suggestionLoading = false;
      return draft;
    case ACTIONS.PROPERTYROLLUP_ERROR:
      draft[action.key] = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.GET_ALL_PROPERTYROLLUP_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    default:
      return draft;
  }
});

export default propertyRollUp;
