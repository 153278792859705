import React from "react";
import { useSelector } from "react-redux";
import SearchUserForm from "../../components/SearchUserForm/SearchUserForm";
import { getBrokers, getBrokersSuggestion } from "../../store/actions/broker.actions";
import "./SearchBroker.scss";

const SearchBroker = () => {
  const { pagination, suggestionLoading } = useSelector(state => state.broker);

  return (
    <div className="employee-search">
      <SearchUserForm
        pagination={pagination}
        loading={suggestionLoading}
        getList={getBrokers}
        getSuggestions={getBrokersSuggestion}
        filterCriteria={{ type: "broker" }}
      />
    </div>
  );
};

export default SearchBroker;
