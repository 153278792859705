import { Button, Col, Row, Typography, Skeleton, Space, Checkbox, Input } from "antd";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _times from "lodash/times";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import _mapKeys from "lodash/mapKeys";
import _range from "lodash/range";
import _get from "lodash/get";
import _forEach from "lodash/forEach";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import AppModal from "../../components/AppModal/AppModal";
import FilePreview from "../../components/FilePreview";
// import defaultImageFile from "../../images/defaultImageFile.svg";
import {
  BUILDING_TABS_KEYS,
  FORM_FIELDS_LOOKUP,
  FORM_FIELDS_TYPE,
  LAND_TABS_KEYS,
  LANGUAGE_CODES,
  MEDIA_TYPES,
  PROPERTY_TYPE_KEYS,
} from "../../utils/constants";
import { isImageFileType, isPDFFileType, toastMessage } from "../../utils/utils";
import CustomDroppable from "./CustomDroppable";
import "./PropertyMedia.scss";
import PDFIcon from "./PDFIcon";
import CustomTooltipHelper from "../../components/CustomTooltipHelper/CustomTooltipHelper";
import CustomFormItem from "../../components/CustomFormItem/CustomFormItem";

const { Text } = Typography;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RANDOM = new Date().getTime();
/**
 * Moves an item from one list to another list.
 */
const move = ({ sourceList, destinationList, source, destination, draggableId, allList }) => {
  const sourceClone = [...sourceList];
  const destClone = [...destinationList];
  const allListClone = [];

  const result = {};

  const removed = sourceClone.find(d => d.asset_id === draggableId);
  let removedFromSource = [];
  if ([MEDIA_TYPES.HERO, MEDIA_TYPES.AERIAL, MEDIA_TYPES.SITE_PLAN].includes(destination.droppableId)) {
    removedFromSource = destClone.splice(0, 1, removed);
  } else destClone.push(removed);

  // If source & destination is not an All Media Section, then move the removed item to All Media Section
  if (![source.droppableId, source.droppableId].includes("all")) allListClone.push(...allList, ...removedFromSource);
  else sourceClone.push(...removedFromSource);

  const newSrcArr = sourceClone.filter(d => d.asset_id !== removed.asset_id);
  result[source.droppableId] = newSrcArr;
  result[destination.droppableId] = destClone;
  result.allList = allListClone;

  return result;
};

const getListStyle = (isDraggingOver, gallery) => ({
  background: isDraggingOver ? "lightblue" : "#fff" || "rgb(239 239 239)",
  overflow: "hidden",
  display: "flex",
  flexWrap: "wrap",
  borderTop: "2px solid #e4e4e4",
  // height: 320,
  height: gallery ? 320 : 495,
  // justifyContent: "space-evenly",
});

const getCollateralListStyle = (isDraggingOver, isPropertyMarketingCollateral, translatedMedia) => ({
  background: isDraggingOver ? "lightblue" : "#fff" || "rgb(239 239 239)",
  overflow: "hidden",
  display: "flex",
  flexWrap: "wrap",
  borderTop: "2px solid #e4e4e4",
  height: isPropertyMarketingCollateral || translatedMedia ? 250 : 152,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#FFF" || "rgb(239 239 239)",
  // styles we need to apply on draggables */
  userSelect: "text",
  ...draggableStyle,
});

const mapAllMedia = (selectedMedia, propertyMedia, translatedSelectedMedia = {}) => {
  const _hero = _mapKeys(selectedMedia.hero, "asset_id");
  const _aerial = _mapKeys(selectedMedia.aerial, "asset_id");
  const _gallery = _mapKeys(selectedMedia.gallery, "asset_id");
  const _sitePlan = _mapKeys(selectedMedia.sitePlan, "asset_id");
  const _propertyMarketingCollateral = _mapKeys(selectedMedia.propertyMarketingCollateral, "asset_id");
  const _translatedMedia = [];

  Object.keys(translatedSelectedMedia).forEach(item => {
    if (!_isEmpty(translatedSelectedMedia[item]?.media?.sitePlan)) {
      _translatedMedia.push(translatedSelectedMedia[item].media.sitePlan[0].asset_id);
    }

    if (!_isEmpty(translatedSelectedMedia[item]?.media?.propertyMarketingCollateral)) {
      _forEach(translatedSelectedMedia[item].media.propertyMarketingCollateral, _item => {
        if (_item?.asset_id) {
          _translatedMedia.push(_item.asset_id);
        }
      });
    }
  });

  return _filter(
    propertyMedia,
    i =>
      !_hero[i.asset_id] &&
      !_aerial[i.asset_id] &&
      !_gallery[i.asset_id] &&
      !_sitePlan[i.asset_id] &&
      !_propertyMarketingCollateral[i.asset_id] &&
      _translatedMedia.indexOf(i.asset_id) === -1,
  );
};

const getMainListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#fff" || "#f5f5f5",
  border: "1px #f5f5f5 solid",
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  height: "665px",
});

const PlaceHolder = ({ loading, children, cols }) => {
  const _placeholders = [];
  _times(cols, i => {
    _placeholders.push(
      <Col span={8} key={i}>
        <Space className="w-full" direction="vertical" align="center">
          <Skeleton.Image />
          <Skeleton.Button active size="small" />
        </Space>
      </Col>,
    );
  });
  return loading ? <Row className="w-full mg-top-20">{_map(_placeholders)}</Row> : children;
};

class PropertyMediaContainer extends Component {
  state = {
    all: [],
    propertyMarketingCollateral: [],
    sitePlan: [],
    gallery: [],
    aerial: [],
    hero: [],
    translatedMedia: {},
    category: { image: false, pdf: false },
    isSectionDisabled: {
      all: false || this.props.viewOnlyMode,
      propertyMarketingCollateral: true,
      sitePlan: true,
      gallery: true,
      aerial: true,
      hero: true,
    },
    isModalOpen: false,
    previewItem: null,
    dragDisabledID: "",
    isCheckedSitePlanLang: false,
    isCheckedPropertyMarketingLang: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
    },
  };

  componentWillReceiveProps(nextProps) {
    const { propertyMedia, selectedMedia, selectedPropertyCode, translatedSelectedMedia } = nextProps;

    let newState = {};

    if (this.props.isEditView) {
      /* --- EDIT --- */
      if (
        !_isEqual(this.props.propertyMedia, propertyMedia) ||
        !_isEqual(this.props.selectedMedia, selectedMedia) ||
        !_isEqual(this.props.translatedSelectedMedia, translatedSelectedMedia)
      ) {
        newState.all = mapAllMedia(selectedMedia, propertyMedia, translatedSelectedMedia);
        newState = { ...newState, ...selectedMedia, translatedMedia: translatedSelectedMedia };

        if (!_isEmpty(selectedMedia) && !_isEmpty(translatedSelectedMedia)) {
          Object.keys(translatedSelectedMedia).forEach(item => {
            if (
              !_isEmpty(selectedMedia.sitePlan) &&
              !_isEmpty(translatedSelectedMedia[item]?.media?.sitePlan) &&
              selectedMedia?.sitePlan[0]?.asset_id === translatedSelectedMedia[item]?.media?.sitePlan[0]?.asset_id
            ) {
              newState.isCheckedSitePlanLang = true;
            }
            if (
              !_isEmpty(selectedMedia.propertyMarketingCollateral) &&
              !_isEmpty(translatedSelectedMedia[item]?.media?.propertyMarketingCollateral)
            ) {
              _forEach(selectedMedia.propertyMarketingCollateral, (_item, index) => {
                if (
                  _item.asset_id === translatedSelectedMedia[item]?.media?.propertyMarketingCollateral[index]?.asset_id
                ) {
                  if (newState.isCheckedPropertyMarketingLang) {
                    newState.isCheckedPropertyMarketingLang[index] = true;
                  } else {
                    newState.isCheckedPropertyMarketingLang = {
                      [index]: true,
                    };
                  }
                }
              });
            }
          });
        }
      }
    } else if (
      !_isEqual(this.props.selectedPropertyCode, selectedPropertyCode) ||
      !_isEqual(this.props.propertyMedia, propertyMedia) /*  && !_isEmpty(this.props.selectedMedia) */
    ) {
      // Clear Selected Media
      newState.all = mapAllMedia({}, propertyMedia, {});
      newState.hero = [];
      newState.aerial = [];
      newState.gallery = [];
      newState.sitePlan = [];
      newState.propertyMarketingCollateral = [];
      newState.translatedMedia = {};
      // }
    }
    this.setState(newState);
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the all container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    all: "all",
    propertyMarketingCollateral: "propertyMarketingCollateral",
    sitePlan: "sitePlan",
    gallery: "gallery",
    aerial: "aerial",
    hero: "hero",
  };

  getList = id => this.state[this.id2List[id]];

  updateTranslatedMediaFields = (items, key, source = {}, destination = {}) => {
    const { language } = this.props;
    const { isCheckedSitePlanLang, isCheckedPropertyMarketingLang, translatedMedia, propertyMarketingCollateral } =
      this.state;

    const { index: destinationIndex, droppableId: destinationKey } = destination || { index: 0, droppableId: "" };
    const { index: sourceIndex, droppableId: sourceKey } = source || { index: 0, droppableId: "" };
    const isEnglish = language.current === LANGUAGE_CODES.English.value;
    let _state = {};

    if (isEnglish) {
      if (key === "sitePlan") {
        _state = {
          [key]: items,
        };
      } else {
        _state = {
          [key]: [],
        };

        _state[key] = [...items];
      }
      if (isCheckedSitePlanLang && key === "sitePlan" && !_isEmpty(language.secondary)) {
        _forEach(language.secondary, _language => {
          _state = {
            ..._state,
            translatedMedia: {
              ...this.state.translatedMedia,
              ..._get(_state, "translatedMedia"),
              [_language]: {
                media: {
                  ..._get(this.state, `translatedMedia.${_language}.media`),
                  ..._get(_state, `translatedMedia.${_language}.media`),
                  [key]: items,
                },
              },
            },
          };
        });
        if (sourceKey === "sitePlan" && isCheckedSitePlanLang) {
          _forEach(language.secondary, _langauge => {
            _state = {
              ..._state,
              translatedMedia: {
                ..._state.translatedMedia,
                [_langauge]: {
                  media: {
                    ..._get(this.state, `translatedMedia.${_langauge}.media`),
                    ..._get(_state, `translatedMedia.${_langauge}.media`),
                    [sourceKey]: [],
                  },
                },
              },
            };
          });
        }

        if (sourceKey === "sitePlan") {
          _state = {
            ..._state,
            isCheckedSitePlanLang: false,
          };
        }
      }
      if (
        sourceKey === "propertyMarketingCollateral" &&
        !_isEmpty(language.secondary) &&
        destinationKey !== sourceKey
      ) {
        _state = {
          ..._state,
          translatedMedia: {
            ...translatedMedia,
          },
          isCheckedPropertyMarketingLang: {
            ...isCheckedPropertyMarketingLang,
          },
        };
        if (isCheckedPropertyMarketingLang[sourceIndex]) {
          _state.isCheckedPropertyMarketingLang[sourceIndex] = false;

          _forEach(language.secondary, _langauge => {
            if (sourceIndex === _state.translatedMedia[_langauge].media[sourceKey].length - 1) {
              delete _state.translatedMedia[_langauge].media[sourceKey].splice(sourceIndex, 1);
            } else {
              delete _state.translatedMedia[_langauge].media[sourceKey][sourceIndex];
            }
          });
        } else {
          _forEach(language.secondary, _langauge => {
            _state.translatedMedia[_langauge].media[sourceKey].splice(sourceIndex, 1);
          });
        }
      } else if (
        destinationKey === "propertyMarketingCollateral" &&
        destinationKey === sourceKey &&
        destinationIndex !== sourceIndex
      ) {
        _state = {
          ..._state,
          propertyMarketingCollateral: [...propertyMarketingCollateral],
          translatedMedia: {
            ...translatedMedia,
          },
          isCheckedPropertyMarketingLang: {
            ...isCheckedPropertyMarketingLang,
          },
        };

        const sourceTemp = { ..._state.propertyMarketingCollateral[sourceIndex] };
        const destinationTemp = { ..._state.propertyMarketingCollateral[destinationIndex] };

        _state.propertyMarketingCollateral[sourceIndex] = { ...destinationTemp };
        _state.propertyMarketingCollateral[destinationIndex] = { ...sourceTemp };
        _state.isCheckedPropertyMarketingLang[sourceIndex] = isCheckedPropertyMarketingLang[destinationIndex];
        _state.isCheckedPropertyMarketingLang[destinationIndex] = isCheckedPropertyMarketingLang[sourceIndex];

        if (isCheckedPropertyMarketingLang[sourceIndex]) {
          _forEach(language.secondary, _langauge => {
            _state.translatedMedia[_langauge].media.propertyMarketingCollateral[destinationIndex] = { ...sourceTemp };
          });
        } else {
          _forEach(language.secondary, _langauge => {
            _state.translatedMedia[_langauge].media.propertyMarketingCollateral[destinationIndex] = undefined;
          });
        }
        if (isCheckedPropertyMarketingLang[destinationIndex]) {
          _forEach(language.secondary, _langauge => {
            _state.translatedMedia[_langauge].media.propertyMarketingCollateral[sourceIndex] = { ...destinationTemp };
          });
        } else {
          _forEach(language.secondary, _langauge => {
            _state.translatedMedia[_langauge].media.propertyMarketingCollateral[sourceIndex] = undefined;
          });
        }
      }
    } else if (!isEnglish) {
      if (key === "sitePlan") {
        _state = {
          translatedMedia: {
            ...this.state.translatedMedia,
            [language.current]: {
              media: {
                ..._get(this.state, `translatedMedia[${language.current}].media`),
                [sourceKey]: [],
                [key]: items,
              },
            },
          },
        };
      } else {
        _state = {
          ..._state,
          translatedMedia: {
            ...translatedMedia,
            [language.current]: {
              media: {
                ..._get(translatedMedia, `[${language.current}].media`),
                [sourceKey]: [],
                [key]: [],
              },
            },
          },
        };

        _state.translatedMedia[language.current].media[key] = items.filter(_item => _item !== undefined);
      }
    }

    return _state;
  };

  onDragEnd = result => {
    const { source, destination, draggableId, index } = result;
    const { language } = this.props;
    const isEnglish = language.current === LANGUAGE_CODES.English.value;
    const { isCheckedPropertyMarketingLang } = this.state;

    // dropped outside the list
    if (
      !destination ||
      (!isEnglish &&
        source.droppableId === MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL &&
        isCheckedPropertyMarketingLang[index]) ||
      (!isEnglish &&
        source.droppableId === MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.getMedia(source.droppableId), source.index, destination.index);

      let state = { all: items };
      if (source.droppableId === MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL) {
        // state = { propertyMarketingCollateral: items };
        state = this.updateTranslatedMediaFields(items, "propertyMarketingCollateral", source, destination);
      } else if (source.droppableId === MEDIA_TYPES.SITE_PLAN) {
        // state = { sitePlan: items };
        state = this.updateTranslatedMediaFields(items, "sitePlan");
      } else if (source.droppableId === MEDIA_TYPES.GALLERY) {
        state = { gallery: items };
      } else if (source.droppableId === MEDIA_TYPES.AERIAL) {
        state = { aerial: items };
      } else if (source.droppableId === MEDIA_TYPES.HERO) {
        state = { hero: items };
      }
      // this.setState(state);
      this.setState(state, () => {
        this.props.updateFiles(this.state, this.state.translatedMedia);
      });
    } else if (
      destination.droppableId === MEDIA_TYPES.GALLERY &&
      this.getMedia(destination.droppableId).length === 10
    ) {
      toastMessage(
        "error",
        "You can only add 10 Images in Gallery. Please Remove Image if you want to add more images to Gallery",
        null,
        { duration: 0 },
      );
    } else {
      const resultState = move({
        sourceList: this.getMedia(source.droppableId),
        destinationList: this.getMedia(destination.droppableId),
        source,
        destination,
        draggableId,
        allList: this.getMedia("all"),
      });

      let state = {};
      if (resultState.propertyMarketingCollateral) {
        state = {
          ...state,
          ...this.updateTranslatedMediaFields(
            resultState.propertyMarketingCollateral,
            "propertyMarketingCollateral",
            source,
            destination,
          ),
        };
      }
      if (resultState.sitePlan) {
        // state = { ...state, sitePlan: resultState.sitePlan };
        state = { ...state, ...this.updateTranslatedMediaFields(resultState.sitePlan, "sitePlan", source) };
      }
      if (resultState.gallery) {
        state = { ...state, gallery: resultState.gallery };
      }
      if (resultState.aerial) {
        state = { ...state, aerial: resultState.aerial };
      }
      if (resultState.hero) {
        state = { ...state, hero: resultState.hero };
      }
      if (resultState.all || resultState.allList) {
        state = { ...state, all: resultState.all || resultState.allList };
      }

      this.setState(state, () => {
        this.props.updateFiles(this.state, this.state.translatedMedia);
      });
    }
  };

  onDragStart = ({ source, draggableId }) => {
    const { language } = this.props;
    this.setState(({ category, isSectionDisabled, ...rs }) => {
      isSectionDisabled.hero = true;
      isSectionDisabled.aerial = true;
      isSectionDisabled.gallery = true;
      isSectionDisabled.sitePlan = true;
      isSectionDisabled.propertyMarketingCollateral = true;

      const mediaSource =
        language.current === LANGUAGE_CODES.English.value ||
        (source.droppableId !== MEDIA_TYPES.SITE_PLAN &&
          source.droppableId !== MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL)
          ? rs[source.droppableId]
          : _get(rs, `translatedMedia.${language.current}.media.${source.droppableId}`);
      const draggedItem = _find(mediaSource || rs.all, { asset_id: draggableId }) || {};
      // const draggedItem = _find(_get(rs, `translatedMedia.${language.current}.media.${source.droppableId}`) || rs.all, { asset_id: draggableId }) || {};

      if (draggedItem.thumbnails_webimage_url && isImageFileType(draggedItem || draggedItem.thumbnails_webimage_url)) {
        isSectionDisabled.hero = false;
        isSectionDisabled.aerial = false;
        isSectionDisabled.gallery = false;
        isSectionDisabled.sitePlan = false;
      }
      if (draggedItem.thumbnails_webimage_url && isPDFFileType(draggedItem || draggedItem.thumbnails_webimage_url)) {
        isSectionDisabled.sitePlan = false;
        isSectionDisabled.propertyMarketingCollateral = false;
      }

      if (source.droppableId === MEDIA_TYPES.HERO) {
        isSectionDisabled.hero = true;
      }
      if (
        [MEDIA_TYPES.HERO, MEDIA_TYPES.AERIAL, MEDIA_TYPES.GALLERY, MEDIA_TYPES.SITE_PLAN].includes(
          source.droppableId,
        ) &&
        isImageFileType(draggedItem || draggedItem.thumbnails_webimage_url)
      ) {
        isSectionDisabled.hero = false;
        isSectionDisabled.aerial = false;
        isSectionDisabled.gallery = false;
        isSectionDisabled.sitePlan = false;
      }
      if (
        [MEDIA_TYPES.SITE_PLAN, MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL].includes(source.droppableId) &&
        isPDFFileType(draggedItem || draggedItem.thumbnails_webimage_url)
      ) {
        isSectionDisabled.sitePlan = false;
        isSectionDisabled.propertyMarketingCollateral = false;
      }
      if (source.droppableId === "all") {
        isSectionDisabled.all = false;
      }
      return { isSectionDisabled };
    });
  };

  onChangeCategory = key => {
    this.setState(({ category }) => ({ category: { ...category, [key]: !category[key] } }));
  };

  toggleModal = modalState => {
    this.setState({ isModalOpen: modalState });
  };

  previewFile = item => {
    this.toggleModal(true);
    this.setState({ previewItem: item });
  };

  _renderMessage = () => {
    // const { t } = useTranslation();
    const { t } = this.props;
    const showMessage =
      _isEmpty(this.state.all) &&
      _isEmpty(this.state.aerial) &&
      _isEmpty(this.state.gallery) &&
      _isEmpty(this.state.hero) &&
      _isEmpty(this.state.propertyMarketingCollateral) &&
      _isEmpty(this.state.sitePlan);

    return (
      showMessage && (
        <Text type="secondary" className="helper-text">
          {this.props.selectedPropertyCode ? t("PropertyMedia.Message1") : t("PropertyMedia.Message2")}
        </Text>
      )
    );
  };

  setDragDisabledID = param => {
    this.setState({ dragDisabledID: param });
  };

  getMedia = key => {
    const { language } = this.props;
    const isEnglish = language.current === LANGUAGE_CODES.English.value;

    if ((key === MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL || key === MEDIA_TYPES.SITE_PLAN) && !isEnglish) {
      const translatedFile = _get(this.state, `translatedMedia.${language.current}.media.${key}`, []);

      return translatedFile;
    }
    return this.state[key];
  };

  getTranslatedMedia = (key, index) => {
    const { language } = this.props;
    const isEnglish = language.current === LANGUAGE_CODES.English.value;

    if (key === MEDIA_TYPES.SITE_PLAN && !isEnglish) {
      const translatedFile = _get(this.state, `translatedMedia.${language.current}.media.${key}`, []);

      return translatedFile;
    }
    if (key === MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL) {
      if (isEnglish) {
        return this.state[key][index];
      }
      const translatedFile = _get(this.state, `translatedMedia.${language.current}.media.${key}[${index}]`, []);
      return translatedFile;
    }
    return this.state[key];
  };

  _onChangeCheckbox = (event, key, index) => {
    const { checked } = event.target;
    const { language, selectedMedia, translatedSelectedMedia, propertyMedia } = this.props;
    const { secondary } = language;
    const { translatedMedia } = this.state;
    const checkedKey = key === "sitePlan" ? "isCheckedSitePlanLang" : "isCheckedPropertyMarketingLang";

    if (checked) {
      this.setState(
        prevState => {
          let _state = {};

          if (key === "sitePlan") {
            _state = { [checkedKey]: checked };
          } else {
            _state = {
              [checkedKey]: {
                ...prevState[checkedKey],
                [index]: checked,
              },
            };
          }

          secondary.forEach(_langauge => {
            if (key === "sitePlan") {
              _state = {
                ..._state,
                translatedMedia: {
                  ..._state.translatedMedia,
                  [_langauge]: {
                    media: {
                      ..._get(prevState, `translatedMedia.${_langauge}.media`, {}),
                      [key]: prevState[key],
                    },
                  },
                },
              };
            } else {
              _state = {
                ..._state,
                translatedMedia: {
                  ..._state.translatedMedia,
                  [_langauge]: {
                    media: {
                      ..._get(prevState, `translatedMedia.${_langauge}.media`, {}),
                      [key]: [..._get(prevState, `translatedMedia.${_langauge}.media.${key}`, [])],
                    },
                  },
                },
              };
              _state.translatedMedia[_langauge].media[key][index] = prevState[key][index];
            }
          });

          return _state;
        },
        () => {
          this.setState({
            all: mapAllMedia(selectedMedia, propertyMedia, translatedSelectedMedia),
          });
          this.props.updateFiles(this.state, this.state.translatedMedia);
        },
      );
    } else {
      this.setState(
        prevState => {
          let _state = {};
          if (key === "sitePlan") {
            _state = { [checkedKey]: checked };
          } else {
            _state = {
              [checkedKey]: {
                ...prevState[checkedKey],
                [index]: checked,
              },
            };
          }
          secondary.forEach(_langauge => {
            if (key === "sitePlan") {
              _state = {
                ..._state,
                translatedMedia: {
                  [_langauge]: {
                    media: {
                      ..._get(prevState, `translatedMedia.${_langauge}.media`, {}),
                      [key]: [],
                    },
                  },
                },
              };
            } else {
              _state = {
                ..._state,
                translatedMedia: {
                  ...translatedMedia,
                },
              };
              _state.translatedMedia[_langauge].media[key][index] = undefined;
            }
          });
          return _state;
        },
        () => {
          this.setState({
            all: mapAllMedia(selectedMedia, propertyMedia, translatedSelectedMedia),
          });
          this.props.updateFiles(this.state, this.state.translatedMedia);
        },
      );
    }
  };

  shouldRenderError = index => {
    const { propertyMarketingCollateral, isCheckedPropertyMarketingLang, translatedMedia } = this.state;

    const arr = [];
    arr.push(propertyMarketingCollateral);
    if (!_isEmpty(translatedMedia)) {
      Object.keys(translatedMedia).forEach(key => {
        const value = translatedMedia[key].media.propertyMarketingCollateral;
        arr.push(value);
      });
    }

    const maxLengthArray = arr.reduce(
      (acc, currentArray) => (currentArray.length > acc.length ? currentArray : acc),
      [],
    );

    return this.props.mediaErrors.propertyMarketingCollateral && maxLengthArray.length > index;
  };

  setHeightOnTranslatedMedia = () => {
    if (!_isEmpty(this.props.language.secondary) && !_isEmpty(this.state.translatedMedia)) {
      return this.props.language.secondary.some(
        _langauge => this.state.translatedMedia[_langauge]?.media?.propertyMarketingCollateral.length > 0,
      );
    }
    return false;
  };

  render() {
    const { t, language, languageStatus, form, auditPanelDrawerVisible, propertyType } = this.props;
    const { dragDisabledID, isCheckedSitePlanLang, isCheckedPropertyMarketingLang } = this.state;
    const { viewOnlyMode, helperText } = this.props;
    const filteredImages = _filter(this.state.all, i => {
      if (!this.state.category.image && !this.state.category.pdf) return true;
      if (this.state.category.image && isImageFileType(i || i.thumbnails_webimage_url)) return true;
      if (this.state.category.pdf && isPDFFileType(i || i.thumbnails_webimage_url)) return true;
      return false;
    });

    const fieldsType = FORM_FIELDS_TYPE.regular;

    const _tabsKey = propertyType === PROPERTY_TYPE_KEYS.land ? LAND_TABS_KEYS : BUILDING_TABS_KEYS;
    const _fieldName =
      propertyType === PROPERTY_TYPE_KEYS.land ? FORM_FIELDS_LOOKUP.land.media : FORM_FIELDS_LOOKUP.building.media;

    return (
      <>
        <h2>{t("PropertyMedia.MediaContent")}</h2>
        <p>{t("PropertyMedia.Description1")}</p>
        <p>{t("PropertyMedia.Description2")}</p>
        <p>
          {t("PropertyMedia.Description3")}
          <a href="https://prologis.box.com/s/ujose16zohhxqbanagvlucqnwg4gkwyj" target="_blank">
            {t("PropertyMedia.LinkHere")}
          </a>
          {t("PropertyMedia.Description4")}
        </p>
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <Row className="fields-row" gutter={[16, 16]} type="flex">
            <Col xs={24} sm={12} lg={14}>
              <Row className="fields-row row-vertical-padding-8" gutter={[16, 24]} type="flex">
                <Col span={6} xs={24} sm={12} lg={6}>
                  <div className="droppable-container">
                    <div>
                      <h5 className={fieldsType === FORM_FIELDS_TYPE.regular ? "required" : ""}>
                        <CustomTooltipHelper
                          label={t("PropertyMedia.Hero")}
                          tooltip={helperText.hero}
                          form={form}
                          tab={_tabsKey[4]}
                          fieldName={_fieldName.hero.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                        />
                        {/* {!_isEmpty(helperText.hero) && (
                          <Tooltip title={getDangerouslySetHTML(getTranslatedHelperText(helperText.hero, language))}>
                            <CustomIcon name="InfoCircleOutlined" />
                          </Tooltip>
                        )} */}
                      </h5>
                      <h6 className="lable-with-margin">{t("PropertyMedia.ImageOnly")}</h6>
                    </div>
                    <CustomDroppable
                      className="mg-left-8 mg-right-8 mg-bottom-8"
                      list={this.state.hero}
                      isDropDisabled={this.state.isSectionDisabled.hero}
                      type={MEDIA_TYPES.HERO}
                      fileType="image"
                      previewFile={this.previewFile}
                      placeholderText={t("PropertyMedia.Placeholder")}
                      isDragDisabled={viewOnlyMode}
                      altLabel={t("PropertyMedia.altLabel")}
                    />
                  </div>
                  {this.props.mediaErrors.hero && (
                    <div className="ant-form-item-has-error ">
                      <div className="ant-form-item-explain ant-form-item-explain-error text-center">
                        {t("PropertyMedia.HeroImage")}
                      </div>
                    </div>
                  )}
                </Col>
                <Col span={6} xs={24} sm={12} lg={6}>
                  <div className="droppable-container">
                    <div>
                      <h5>
                        <CustomTooltipHelper
                          label={t("PropertyMedia.Aerial")}
                          tooltip={helperText.aerial}
                          form={form}
                          tab={_tabsKey[4]}
                          fieldName={_fieldName.aerial.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                        />
                        {/* {!_isEmpty(helperText.aerial) && (
                          <Tooltip title={getDangerouslySetHTML(getTranslatedHelperText(helperText.aerial, language))}>
                            <CustomIcon name="InfoCircleOutlined" />
                          </Tooltip>
                        )} */}
                      </h5>
                      <h6 className="lable-with-margin">{t("PropertyMedia.ImageOnly")}</h6>
                    </div>

                    <CustomDroppable
                      className="mg-left-8 mg-right-8 mg-bottom-8"
                      list={this.state.aerial}
                      isDropDisabled={this.state.isSectionDisabled.aerial}
                      type={MEDIA_TYPES.AERIAL}
                      fileType="image"
                      previewFile={this.previewFile}
                      placeholderText={t("PropertyMedia.Placeholder")}
                      isDragDisabled={viewOnlyMode}
                      altLabel={t("PropertyMedia.altLabel")}
                    />
                  </div>
                </Col>
                <Col span={6} xs={24} sm={12} lg={6}>
                  <div className="droppable-container">
                    <div>
                      <h5>
                        <CustomTooltipHelper
                          label={t("PropertyMedia.SitePlan")}
                          tooltip={helperText.sitePlan}
                          form={form}
                          tab={_tabsKey[4]}
                          fieldName={_fieldName.sitePlan.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                        />
                        {/* {!_isEmpty(helperText.sitePlan) && (
                          <Tooltip
                            title={getDangerouslySetHTML(getTranslatedHelperText(helperText.sitePlan, language))}
                          >
                            <CustomIcon name="InfoCircleOutlined" />
                          </Tooltip>
                        )} */}
                      </h5>
                      <h6 className={`${!_isEmpty(language.secondary) ? "" : "lable-with-margin"}`}>
                        {t("PropertyMedia.ImageorPdf")}
                      </h6>
                      {!_isEmpty(language.secondary) && (
                        <Checkbox
                          onChange={event => this._onChangeCheckbox(event, "sitePlan")}
                          className="media-langauge-checkbox"
                          disabled={
                            viewOnlyMode ||
                            language.current !== LANGUAGE_CODES.English.value ||
                            _isEmpty(this.state.sitePlan)
                          }
                          checked={isCheckedSitePlanLang}
                        >
                          Apply for all languages
                        </Checkbox>
                      )}
                    </div>
                    <CustomDroppable
                      className="mg-left-8 mg-right-8 mg-bottom-8"
                      // list={this.state.sitePlan}
                      list={this.getMedia("sitePlan")}
                      isDropDisabled={
                        this.state.isSectionDisabled.sitePlan ||
                        (language.current !== LANGUAGE_CODES.English.value && isCheckedSitePlanLang)
                      }
                      type={MEDIA_TYPES.SITE_PLAN}
                      fileType="pdf"
                      previewFile={this.previewFile}
                      placeholderText={t("PropertyMedia.Placeholder2")}
                      isDragDisabled={
                        viewOnlyMode || (language.current !== LANGUAGE_CODES.English.value && isCheckedSitePlanLang)
                      }
                      altLabel={t("PropertyMedia.altLabel")}
                    />
                  </div>
                  {this.props.mediaErrors.sitePlan && (
                    <div className="ant-form-item-has-error ">
                      <div className="ant-form-item-explain ant-form-item-explain-error text-center">
                        {t("ErrorMessages.SelectBothTranslatedAndLocal")}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="fields-row row-vertical-padding-8" gutter={[16]} type="flex">
                <Col xs={24} sm={24} lg={24}>
                  <div className="droppable-container">
                    <div>
                      <h5>
                        <CustomTooltipHelper
                          label={t("PropertyMedia.ImageGallery")}
                          tooltip={helperText.imageGallery}
                          form={form}
                          tab={_tabsKey[4]}
                          fieldName={_fieldName.gallery.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                        />
                        {/* {!_isEmpty(helperText.imageGallery) && (
                          <Tooltip
                            title={getDangerouslySetHTML(getTranslatedHelperText(helperText.imageGallery, language))}
                          >
                            <CustomIcon name="InfoCircleOutlined" />
                          </Tooltip>
                        )} */}
                      </h5>
                      <h6>{t("PropertyMedia.UpToTenImages")}</h6>
                    </div>
                    {/* ------------------------------ */}
                    <div className="property-media-gallery__container">
                      <Droppable
                        droppableId={MEDIA_TYPES.GALLERY}
                        direction="horizontal"
                        isDropDisabled={this.state.isSectionDisabled.gallery}
                      >
                        {(provided1, snapshot1) => (
                          <div
                            ref={provided1.innerRef}
                            style={{
                              ...getListStyle(snapshot1.isDraggingOver, _isEmpty(this.state.gallery)),
                            }}
                          >
                            {_map(_range(10), index => {
                              const item = this.state.gallery[index];
                              if (!item) {
                                return (
                                  <div
                                    key={`${MEDIA_TYPES.GALLERY}-${index}`}
                                    className="property-media-gallery__item property-media-gallery__placeholder placeholder"
                                    style={{
                                      ...getItemStyle(false, {}),
                                      background: "#efefef",
                                      border: "2px solid #e4e4e4",
                                    }}
                                  >
                                    <div
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <div className="text-center" style={{}}>
                                        {t("PropertyMedia.SelectanImage")}
                                      </div>
                                      <div className="text-center">
                                        <Button type="primary" shape="circle" size="small">
                                          {index + 1}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }

                              const _url = `${item.thumbnails_webimage_url}?ver=${RANDOM}`;
                              const _alt = item.name;

                              return (
                                <Draggable
                                  key={item.asset_id}
                                  draggableId={item.asset_id}
                                  index={index}
                                  isDragDisabled={viewOnlyMode || dragDisabledID === item.asset_id}
                                >
                                  {(provided, snapshot) => (
                                    <div className="property-media-gallery__item">
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                        }}
                                      >
                                        <div
                                          className="property-media-gallery__item-hover"
                                          onDoubleClick={() => this.previewFile(item)}
                                        >
                                          <img className="item-image" src={_url} alt={_alt} />
                                          <div
                                            className="id-container"
                                            onMouseEnter={() => this.setDragDisabledID(item.asset_id)}
                                            onMouseLeave={() => this.setDragDisabledID(undefined)}
                                          >
                                            {item.asset_id}
                                          </div>
                                        </div>
                                      </div>
                                      {!snapshot.isDragging && (
                                        <CustomFormItem
                                          label={t("PropertyMedia.altLabel", { title: `Image ${index + 1}` })}
                                          name={["media", MEDIA_TYPES.GALLERY, index, "alt_text"]}
                                          rules={[
                                            {
                                              required: true,
                                              max: 100,
                                              message: t("ErrorMessages.ImageDescriptionMessage"),
                                            },
                                          ]}
                                          className="alt-details"
                                        >
                                          <Input />
                                        </CustomFormItem>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided1.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    {/* ------------------------------ */}
                  </div>
                </Col>
              </Row>
              <Row className="fields-row row-vertical-padding-8" gutter={[16]} type="flex">
                <Col xs={24} sm={24} lg={24}>
                  <div className="collateral-droppable-container">
                    <div>
                      <h5>
                        <CustomTooltipHelper
                          label={this.props.marketingCollateralLabel}
                          tooltip={helperText.marketingCollateral}
                          form={form}
                          tab={_tabsKey[4]}
                          fieldName={_fieldName.propertyMarketingCollateral.key}
                          auditPanelDrawerVisible={auditPanelDrawerVisible}
                        />
                      </h5>
                    </div>
                    {/* ------------------------------ */}
                    <div className="property-media-gallery__container">
                      <Droppable
                        droppableId={MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL}
                        direction="horizontal"
                        isDropDisabled={this.state.isSectionDisabled.propertyMarketingCollateral}
                      >
                        {(provided1, snapshot1) => (
                          <div
                            ref={provided1.innerRef}
                            style={{
                              ...getCollateralListStyle(
                                snapshot1.isDraggingOver,
                                !_isEmpty(this.state.propertyMarketingCollateral),
                                this.setHeightOnTranslatedMedia(),
                              ),
                            }}
                          >
                            {_map(_range(5), index => {
                              const item = this.getTranslatedMedia("propertyMarketingCollateral", index);
                              if (_isEmpty(item)) {
                                return (
                                  <div
                                    key={`${MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL}-${index}`}
                                    className="property-media-gallery__item property-media-gallery__placeholder placeholder"
                                    style={{
                                      ...getItemStyle(false, {}),
                                      background: "#efefef",
                                      border: "2px solid #e4e4e4",
                                    }}
                                  >
                                    <div
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <div className="text-center" style={{}}>
                                        {t("PropertyMedia.SelectanPDF")}
                                      </div>
                                      <div className="text-center">
                                        <Button type="primary" shape="circle" size="small">
                                          {index + 1}
                                        </Button>
                                      </div>
                                    </div>
                                    {this.shouldRenderError(index) && (
                                      <div className="ant-form-item-has-error">
                                        <div className="ant-form-item-explain ant-form-item-explain-error text-center">
                                          {t("ErrorMessages.SelectBothTranslatedAndLocal")}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              const _url = `${item.thumbnails_webimage_url}?ver=${RANDOM}`;
                              const _alt = item.name;

                              return (
                                <Draggable
                                  key={item.asset_id}
                                  draggableId={item.asset_id}
                                  index={index}
                                  isDragDisabled={
                                    viewOnlyMode ||
                                    dragDisabledID === item.asset_id ||
                                    (language.current !== LANGUAGE_CODES.English.value &&
                                      isCheckedPropertyMarketingLang[index])
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div className="property-media-gallery__item">
                                      {!_isEmpty(language.secondary) && (
                                        <Checkbox
                                          onChange={event =>
                                            this._onChangeCheckbox(event, "propertyMarketingCollateral", index)
                                          }
                                          className="media-langauge-checkbox"
                                          disabled={
                                            viewOnlyMode ||
                                            language.current !== LANGUAGE_CODES.English.value ||
                                            _isEmpty(this.state.propertyMarketingCollateral)
                                          }
                                          checked={isCheckedPropertyMarketingLang[index]}
                                        >
                                          Apply for all languages
                                        </Checkbox>
                                      )}
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                          height: !_isEmpty(language.secondary) ? "117px" : "140px",
                                        }}
                                      >
                                        <div
                                          className="property-media-marketing-collateral__item-hover"
                                          onDoubleClick={() => this.previewFile(item)}
                                        >
                                          <img className="item-image" src={_url} alt={_alt} />
                                          <div
                                            className="id-container"
                                            onMouseEnter={() => this.setDragDisabledID(item.asset_id)}
                                            onMouseLeave={() => this.setDragDisabledID(undefined)}
                                          >
                                            {item.asset_id}
                                          </div>
                                        </div>
                                      </div>
                                      {!snapshot.isDragging && (
                                        <CustomFormItem
                                          label={t("PropertyMedia.BrochureTitle", { title: `Image ${index + 1}` })}
                                          name={["media", MEDIA_TYPES.PROPERTY_MARKETING_COLLATERAL, index, "alt_text"]}
                                          rules={[
                                            {
                                              required: true,
                                              max: 100,
                                              message: t("ErrorMessages.BrochureTitleMessage"),
                                            },
                                          ]}
                                          className="alt-details brochure-error"
                                        >
                                          <Input />
                                        </CustomFormItem>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided1.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    {/* ------------------------------ */}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12} lg={10} className="pad-0">
              <div className="property-media__all-container droppable-container">
                <h5 className="text-center mg-bottom-10">{t("PropertyMedia.Image&PDFLibrary")}</h5>
                <p className="text-center mg-bottom-0">{t("PropertyMedia.DragandDrop")}</p>
                <small className="mg-bottom-5 font-bold text-center">{t("PropertyMedia.DoubleClick")}</small>
                <div className="filter-buttons">
                  <Button
                    type={this.state.category.image ? "primary" : "default"}
                    size="small"
                    className="mg-right-20"
                    onClick={() => this.onChangeCategory("image")}
                  >
                    {t("PropertyMedia.Image")}
                  </Button>
                  <Button
                    type={this.state.category.pdf ? "primary" : "default"}
                    size="small"
                    onClick={() => this.onChangeCategory("pdf")}
                  >
                    {t("PropertyMedia.PDF")}
                  </Button>
                </div>
                <Droppable droppableId="all" direction="horizontal" isDropDisabled={this.state.isSectionDisabled.all}>
                  {(provided1, snapshot1) => (
                    <div ref={provided1.innerRef} style={getMainListStyle(snapshot1.isDraggingOver)}>
                      <PlaceHolder loading={this.props.loading}>
                        {filteredImages.length
                          ? filteredImages.map((item, index) => {
                            const _url = `${item.thumbnails_webimage_url}?ver=${RANDOM}`;
                            const _alt = item.asset_name;

                            const isPDF = isPDFFileType(item || item.thumbnails_webimage_url);
                            const _style = {};

                            return (
                              <Draggable
                                key={item.asset_id}
                                draggableId={item.asset_id}
                                index={index}
                                isDragDisabled={viewOnlyMode || dragDisabledID === item.asset_id}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="property-media-all__item"
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <div
                                      onDoubleClick={() => this.previewFile(item)}
                                      className="property-media-all__item-hover"
                                    >
                                      <img
                                        className={`item-image ${isPDF ? "pdf" : ""}`}
                                        src={_url}
                                        alt={_alt}
                                        style={{
                                          // objectFit: "fill",
                                          ..._style,
                                        }}
                                      />

                                      <div
                                        className="id-container text-center label-hover"
                                        style={
                                            {
                                              /* height: "20%" */
                                            }
                                          }
                                        onMouseEnter={() => this.setDragDisabledID(item.asset_id)}
                                        onMouseLeave={() => this.setDragDisabledID(undefined)}
                                      >
                                        {item.asset_id}
                                        <PDFIcon item={item} isPDF={isPDF} />
                                      </div>
                                      {/* <div className="text-center">{item.name}</div> */}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                          : this._renderMessage()}
                      </PlaceHolder>
                      {provided1.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </Col>
          </Row>
        </DragDropContext>
        <AppModal
          className="media-preview"
          title="File Preview"
          width="80%"
          visible={this.state.isModalOpen}
          onCancel={() => {
            this.toggleModal(false);
          }}
          maxHeight="100vh"
          overFlowY="auto"
          footer={null}
          destroyOnClose
        >
          <FilePreview file={this.state.previewItem} />
        </AppModal>
      </>
    );
  }
}

PropertyMediaContainer.propTypes = {
  marketingCollateralLabel: PropTypes.string,
  selectedMedia: PropTypes.object,
  translatedSelectedMedia: PropTypes.object,
  updateFiles: PropTypes.func,
  propertyMedia: PropTypes.array,
  mediaErrors: PropTypes.object,
  isEditView: PropTypes.bool,
  viewOnlyMode: PropTypes.bool,
  selectedPropertyCode: PropTypes.string,
  loading: PropTypes.bool,
  helperText: PropTypes.object,
  t: PropTypes.func,
  language: PropTypes.object,
  languageStatus: PropTypes.string,
  form: PropTypes.object,
  auditPanelDrawerVisible: PropTypes.bool,
  propertyType: PropTypes.string,
};

PropertyMediaContainer.defaultProps = {
  propertyMedia: [],
  selectedMedia: {},
};

PlaceHolder.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
  cols: PropTypes.number,
};

PlaceHolder.defaultProps = {
  cols: 3,
};

export default withTranslation()(PropertyMediaContainer);
