import * as msal from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const PATH = window.BASE_PATH;
const { AZURE_CLIENT_ID } = window;
const msalConfig = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: "https://login.microsoftonline.com/2cf835d1-453b-472b-9b90-3f6d854ad75b/",
    postLogoutRedirectUri: `${PATH}/logoutResponse`,
    redirectUri: `${PATH}/loginResponse`,
    // postLogoutRedirectUri: `${window.location.origin}/logoutResponse`,
    // redirectUri: `${window.location.origin}/loginResponse`,
    // knownAuthorities: [],
    // cloudDiscoveryMetadata: "",
    navigateToLoginRequestUrl: false,
    // clientCapabilities: ["CP1"]
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
      piiLoggingEnabled: true,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

// Add here scopes for access token to be used at MS Graph API endpoints.
// export const tokenRequest = {
//   scopes: ["openid"],
//   // scopes: ["openid", "profile", "User.Read", "Mail.Read"],
//   forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
// };

export const AuthService = new msal.PublicClientApplication(msalConfig);
console.log("AuthService===>", AuthService);

export async function signIn(params = {}) {
  const loginReq = {
    scopes: ["profile"],
    ...params,
  };
  return AuthService.loginRedirect(loginReq);
}

export function signOut(accountId) {
  // const logoutRequest = {
  //   account: AuthService.getAccountByUsername(accountId),
  // };
  return AuthService.logout();
}
