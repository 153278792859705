import homeService from "../../services/home.service";
import { ERROR_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

export const LOADING = {
  HOME_LOADING: "loading",
};

export function homeRequest(key = LOADING.HOME_LOADING) {
  return { type: ACTIONS.HOME_REQUEST, key };
}
export function unitsToReviewSuccess(response) {
  return { type: ACTIONS.UNITS_TO_REVIEW_SUCCESS, response };
}

export function vacantUnitsSuccess(response) {
  return { type: ACTIONS.VACANT_UNITS_SUCCESS, response };
}

export function upcomingVacantUnitsSuccess(response) {
  return { type: ACTIONS.UPCOMING_VACANT_UNITS_SUCCESS, response };
}

export function activeAuditsSuccess(response) {
  return { type: ACTIONS.ACTIVE_AUDITS_SUCCESS, response };
}

export function notMarketingUnitsSuccess(response) {
  return { type: ACTIONS.NOT_MARKETING_UNITS_SUCCESS, response };
}

export function marketableUnitsSuccess(response) {
  return { type: ACTIONS.MARKETABLE_UNITS_SUCCESS, response };
}

export function homeError(error, key = LOADING.HOME_LOADING) {
  return { type: ACTIONS.HOME_ERROR, error, key };
}

export function homePaginationSuccess(response) {
  return { type: ACTIONS.HOME_PAGINATION_SUCCESS, response };
}

export function homeTagsSuccess(response) {
  return { type: ACTIONS.HOME_TAGS_SUCCESS, response };
}

export function getUnitsToReview() {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.unitsToReview());
      if (err || !response) throwError(err);
      dispatch(unitsToReviewSuccess({ list: response }));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getUnitsToReviewWithFilters(filterCriteria) {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.unitsToReviewWithFilters(filterCriteria));
      if (err || !response) throwError(err);
      dispatch(unitsToReviewSuccess({ list: response }));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getActiveAudits() {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.activeAudits());
      if (err || !response) throwError(err);
      dispatch(activeAuditsSuccess({ list: response }));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getActiveAuditsWithFilters(filterCriteria) {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.activeAuditsWithFilters(filterCriteria));
      if (err || !response) throwError(err);
      dispatch(activeAuditsSuccess({ list: response }));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getMarketableUnits() {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.marketableUnits());
      if (err || !response) throwError(err);
      dispatch(marketableUnitsSuccess(response));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getMarketableUnitsWithFilters(filterCriteria) {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.marketableUnitsWithFilters(filterCriteria));
      if (err || !response) throwError(err);
      dispatch(marketableUnitsSuccess(response));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getMarketableUnitsCount() {
  return async dispatch => {
    dispatch(homeRequest(LOADING.HOME_LOADING));
    try {
      const [err, response] = await to(homeService.marketableUnits());
      if (err || !response) throwError(err);
      dispatch(marketableUnitsSuccess(response));
    } catch (error) {
      dispatch(homeError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}
