import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const subMarket = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.SUB_MARKET_REQUEST:
      draft[action.key] = true;
      return draft;
    case ACTIONS.SUB_MARKET_SUGGESTION_SUCCESS:
      draft.suggestionLoading = false;
      draft.error = null;
      return draft;
    case ACTIONS.SUB_MARKET_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.value = action.response;
      return draft;
    case ACTIONS.SUB_MARKET_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.list = action.response.list;
      draft.pagination = action.response.pagination;
      return draft;
    case ACTIONS.SUB_MARKET_SELECTED_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.selectedList = action.response.selectedList;
      draft.pagination = action.response.pagination;
      return draft;
    case ACTIONS.ACTIVE_SUB_MARKET_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.activeList = action.response;
      return draft;
    case ACTIONS.SUB_MARKET_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default subMarket;
