import { Button, Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../components/AppModal/AppModal";
import SearchListView from "../../components/SearchListView/SearchListView";
import { getPropertyRollUps } from "../../store/actions/propertyRollUp.action";
import { DATE_FORMAT_TIME, PAGINATOIN, SORT_DIRECTION } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { removeUnderscore, replaceIfNull } from "../../utils/utils";
import AddEditPropertyRollUp from "../AddEditPropertyRollUp/AddEditPropertyRollUp";

const PropertyRollUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canCreatePropertyRollUp = permissionsUtil.checkAuth("property_rollup_create");

  const { list, loading, pagination } = useSelector(store => store.propertyRollUp);

  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchPropertyRollUps = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getPropertyRollUps(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const columns = [
    {
      title: t("Property.Region"),
      dataIndex: "region.name",
      key: "region",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "region.name" && sortInfo?.order,
      render: (text, record) => replaceIfNull(record.region?.name, t("Table.NA")),
    },
    {
      title: t("Property.Country"),
      dataIndex: "country",
      key: "country",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "country" && sortInfo?.order,
      render: country => replaceIfNull(removeUnderscore(country), t("Table.NA")),
      className: "text-capitalize",
    },
    {
      title: t("Table.PropertyRollUpCode"),
      dataIndex: "id",
      key: "id",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "id" && sortInfo?.order,
      render: text => replaceIfNull(text, t("Table.NA")),
    },
    {
      title: t("AddEditPropertyRollUp.PropertyRollUpName"),
      dataIndex: "propertyRollUpName",
      key: "propertyRollUpName",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyRollUpName" && sortInfo?.order,
      render: text => replaceIfNull(text, t("Table.NA")),
    },
    {
      title: t("Table.MarketName"),
      dataIndex: "marketName",
      key: "marketName",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "marketName" && sortInfo?.order,
      render: text => replaceIfNull(text, t("Table.NA")),
    },
    {
      title: t("Table.Active"),
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? t("Table.True") : t("Table.False")),
    },
    {
      title: t("Table.ModifiedOn"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
    {
      title: t("Table.ModifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: text => replaceIfNull(text, t("Table.NA")),
    },
  ];

  const onCreatePropertyRollUpClicked = () => {
    toggleModal(true);
  };

  const toggleModal = async modalState => {
    setIsModalOpen(modalState);
  };
  return (
    <div className="property-roll-up">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canCreatePropertyRollUp && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block type="primary" onClick={onCreatePropertyRollUpClicked}>
              {t("Table.Add")}
            </Button>
          </Col>
        )}
      </Row>
      <SearchListView
        paginationKey={PAGINATOIN.propertyRollUp}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        loading={loading}
        dataSource={list}
        columns={columns}
        getList={fetchPropertyRollUps}
        pagination={pagination}
        scroll={{ x: 1575, y: "calc(100vh - 560px)" }}
      />
      <AppModal
        title={t("PropertyRollUp.CreatePropertyRollUp")}
        width={600}
        visible={isModalOpen}
        onCancel={() => {
          toggleModal(false);
        }}
        footer={null}
        destroyOnClose
      >
        <AddEditPropertyRollUp onSuccess={fetchPropertyRollUps} onCloseModal={toggleModal} />
      </AppModal>
    </div>
  );
};

export default PropertyRollUp;
