import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const upload = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.UPLOAD_REQUEST:
      draft[action.key] = !draft[action.key];
      return draft;
    case ACTIONS.UPLOAD_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.UPLOAD_SUCCESS:
      draft.loading = false;
      return draft;
    default:
      return draft;
  }
});

export default upload;
