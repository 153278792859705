import HelperTextService from "../../services/helperText.service";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../../utils/constants";
import { throwError, to, toastMessage, downloadFile } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  HELPER_TEXT_LOADING: "loading",
};

export function helperTextRequest(key = LOADING.HELPER_TEXT_LOADING) {
  return { type: ACTIONS.HELPER_TEXT_REQUEST, key };
}

export function helperTextSuccess(response) {
  return { type: ACTIONS.HELPER_TEXT_SUCCESS, response };
}

export function helperTextListSuccess(response) {
  return { type: ACTIONS.HELPER_TEXT_LIST_SUCCESS, response };
}

export function helperTextError(error, key = LOADING.SUB_MARKET_LOADING) {
  return { type: ACTIONS.HELPER_TEXT_ERROR, error, key };
}

export function getAllHelperTexts() {
  return async dispatch => {
    dispatch(helperTextRequest());
    try {
      const [err, response] = await to(HelperTextService.get());
      if (err) throwError(err);
      const lookupList = response.reduce((c, item) => {
        c[item.id] = item;
        return c;
      }, {});
      dispatch(
        helperTextListSuccess({ list: response.map(item => ({ ...item, type: item.type || "generic" })), lookupList }),
      );
    } catch (error) {
      dispatch(helperTextError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function createHelperText(payload) {
  return async dispatch => {
    dispatch(helperTextRequest());
    try {
      const [err, response] = await to(HelperTextService.create(payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(helperTextSuccess({}));
    } catch (error) {
      dispatch(helperTextError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function updateHelperText(id, payload) {
  return async dispatch => {
    dispatch(helperTextRequest());
    try {
      const [err, response] = await to(HelperTextService.update(id, payload));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(helperTextSuccess({}));
    } catch (error) {
      dispatch(helperTextError(error));
      toastMessage("error", ERROR_MESSAGE.EDITED);
      throwError(error);
    }
  };
}

export function deleteHelperText(id) {
  return async dispatch => {
    dispatch(helperTextRequest());
    try {
      const [err, response] = await to(HelperTextService.remove(id));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.DELETED);
      dispatch(helperTextSuccess({}));
    } catch (error) {
      dispatch(helperTextError(error));
      toastMessage("error", ERROR_MESSAGE.DELETED);
      throwError(error);
    }
  };
}

export function exportHelperTexts() {
  return async dispatch => {
    dispatch(helperTextRequest());
    try {
      const [err, response] = await to(HelperTextService.export());
      if (err) throwError(err);
      downloadFile(response, "HelperTexts.xlsx");
      dispatch(helperTextSuccess({}));
    } catch (error) {
      dispatch(helperTextError(error));
      toastMessage("error", ERROR_MESSAGE.FILE);
      throwError(error);
    }
  };
}
