import { Button, Col, Input, Row } from "antd";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppModal from "../../components/AppModal/AppModal";
import CustomFormItem from "../../components/CustomFormItem/CustomFormItem";
import LabelWithDropDown from "../../components/LabelWithDropDown/LabelWithDropDown";
import { useLanguage } from "../../hooks";
import { LANGUAGE_CODES } from "../../utils/constants";

const UnitKeyFeature = ({
  viewOnlyMode,
  label,
  templateName,
  form,
  templateList,
  unitIndex,
  tooltip,
  tab,
  fieldName,
  auditPanelDrawerVisible,
  ...props
}) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState(null);
  const [modalVisible, setModalVisibility] = useState(false);
  const { language, languageStatus } = useLanguage();

  const _onCancel = () => {
    setModalVisibility(false);
    const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));

    _unitInfo[unitIndex] = {
      ..._unitInfo[unitIndex],
      keyFeatures: { [templateName[2]]: templateId },
    };

    form.setFieldsValue({ unitInfo: _unitInfo });
  };

  const _onConfirm = () => {
    populateDescription(form.getFieldValue(["unitInfo", unitIndex, "keyFeatures", templateName[2]]));
    setModalVisibility(false);
  };

  const setTemplateDescription = data => {
    if (!_isEmpty(data)) {
      if (_isEmpty(form.getFieldValue(["unitInfo", unitIndex, "keyFeatures", props.name[2]]))) {
        populateDescription(data);
      } else {
        setModalVisibility(true);
      }
    }
  };

  const populateDescription = data => {
    if (data) {
      const country = form.getFieldValue(["buildingInfo", "address", "country"]);
      const selectedTemplate = templateList.find(item => item.id === data);
      const templateData = _get(
        selectedTemplate,
        `translations.${
          country === "Mexico" && language.current !== LANGUAGE_CODES.English.value
            ? LANGUAGE_CODES.Mexico.value
            : language.current
        }.template`,
        selectedTemplate?.template,
      );
      const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));

      const obj = {};
      if (languageStatus !== "primary") {
        obj.translations = {
          [language.current]: {
            ..._unitInfo[unitIndex]?.translations?.[language.current],
            keyFeatures: {
              ..._unitInfo[unitIndex]?.translations?.[language.current].keyFeatures,
              [props.name[2]]: templateData,
            },
          },
        };
      } else {
        obj.keyFeatures = { ..._unitInfo[unitIndex]?.keyFeatures, [props.name[2]]: templateData };
      }
      _unitInfo[unitIndex] = {
        ..._unitInfo[unitIndex],
        ...obj,
      };
      form.setFieldsValue({ unitInfo: _unitInfo });
      setTemplateId(data);
    }
  };

  return (
    <>
      <CustomFormItem
        {...props}
        className="form-item-label-with-drop-down"
        label={
          <LabelWithDropDown
            label={t(label)}
            templateId={templateId}
            changeHandler={setTemplateDescription}
            options={templateList}
            viewOnlyMode={viewOnlyMode}
            name={templateName}
            tooltip={tooltip}
            tab={tab}
            fieldName={fieldName}
            auditPanelDrawerVisible={auditPanelDrawerVisible}
            unitIndex={unitIndex}
            form={form}
          />
        }
        isUnit
      >
        <Input.TextArea placeholder={t(label)} disabled={viewOnlyMode} rows={2} />
      </CustomFormItem>
      <AppModal
        centered
        wrapClassName="modal-associated-properties"
        title={t("Template.Confirmation")}
        width={600}
        minHeight="10vh"
        maxHeight="60vh"
        overFlowY="auto"
        visible={modalVisible}
        onCancel={_onCancel}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onCancel}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block type="primary" onClick={_onConfirm}>
                {t("Buttons.Confirm")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <p>{t("Template.ConfirmationMessageKeyFeatures")}</p>
      </AppModal>
    </>
  );
};
UnitKeyFeature.propTypes = {
  viewOnlyMode: PropTypes.bool,
  label: PropTypes.string,
  unitIndex: PropTypes.number,
  templateName: PropTypes.array,
  name: PropTypes.array,
  templateList: PropTypes.array,
  form: PropTypes.object,
  tooltip: PropTypes.object,
  tab: PropTypes.string,
  fieldName: PropTypes.string,
  auditPanelDrawerVisible: PropTypes.bool,
};
export default UnitKeyFeature;
