import { get, put, post, remove, patch } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const propertyService = {
  exportPropertyContacts: () => get("propertyContactHistory/export", { responseType: "base64" }),
  getPropertyContactsList: () => get(`propertyContactHistory`),
  searchPropertyContacts: query => get(`propertyContactHistory/search${getSearchListQuery(query)}`),
  suggestions: keyword => get(`propertyContactHistory/suggestion?keyword=${keyword}`),
};
export default propertyService;
