import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import unitVacancyService from "../../services/unitVacancy.service";
import { ERROR_MESSAGE, PAGINATOIN, PROPERTY_STATUS, SEARCH_QUERY, SUCCESS_MESSAGE, TAG } from "../../utils/constants";
import { setSessionStorage, throwError, to, toastMessage } from "../../utils/utils";
import ACTIONS from "./types.actions";

const LOADING = {
  UNIT_VACANCY_LOADING: "loading",
  SUGGESTION_LOADING: "suggestionLoading",
};

export function unitVacancyRequest(key = LOADING.UNIT_VACANCY_LOADING) {
  return { type: ACTIONS.UNIT_VACANCY_REQUEST, key };
}

export function unitVacancySuccess(response) {
  return { type: ACTIONS.UNIT_VACANCY_SUCCESS, response };
}

export function unitVacancySuggestionSuccess(response) {
  return { type: ACTIONS.UNIT_VACANCY_SUGGESTION_SUCCESS, response };
}

export function unitVacancyError(error, key = LOADING.UNIT_VACANCY_LOADING) {
  return { type: ACTIONS.UNIT_VACANCY_ERROR, error, key };
}

export function unitVacancyListSuccess(response) {
  return { type: ACTIONS.UNIT_VACANCY_LIST_SUCCESS, response };
}

export function upcomingVacantListSuccess(response) {
  return { type: ACTIONS.UPCOMING_VACANT_PROPERTY_LIST_SUCCESS, response };
}

export function unitVacancyTagsSuccess(response) {
  return { type: ACTIONS.UNIT_VACANCY_TAGS_SUCCESS, response };
}

export function upcomingVacantPropertiesTagsSuccess(response) {
  return { type: ACTIONS.UPCOMING_VACANT_PROPERTIES_TAG_SUCCESS, response };
}

export function getUnitVacancyList(query, isFromDashboard = false, getPropertyType) {
  return async dispatch => {
    dispatch(unitVacancyRequest());
    try {
      const _tags = [...(query?.filterCriteria?.status || []), ...(query?.filterCriteria?.market || [])];
      let err, response;
      if (isFromDashboard) {
        query.isMarketable = true;
        [err, response] = await to(unitVacancyService.searchDashboardUnitVacancy(query, getPropertyType));
      } else {
        [err, response] = await to(unitVacancyService.searchUnitVacancy(query));
      }
      if (err) throwError(err);
      const pagination = { ...query, totalElements: response.total };
      if (!isFromDashboard) {
        setSessionStorage(TAG.unitVacancy, []);
        dispatch(unitVacancyListSuccess({ list: response.content, pagination }));
        setSessionStorage(PAGINATOIN.unitVacancy, pagination);
        dispatch(unitVacancyTagsSuccess(_tags));
        setSessionStorage(TAG.unitVacancy, _tags);
      } else {
        setSessionStorage(TAG.upcomingVacantProperties, []);
        dispatch(upcomingVacantListSuccess({ list: response.content, pagination }));
        setSessionStorage(PAGINATOIN.upcomingPropertyPagination, pagination);
        dispatch(upcomingVacantPropertiesTagsSuccess(_tags));
        setSessionStorage(TAG.upcomingVacantProperties, _tags);
      }
    } catch (error) {
      dispatch(unitVacancyError(error));
      toastMessage("error", ERROR_MESSAGE.LIST);
      throwError(error);
    }
  };
}

export function getUnitVacancySuggestion(payload) {
  return async dispatch => {
    dispatch(unitVacancyRequest(LOADING.SUGGESTION_LOADING));
    try {
      const [err, response] = await to(unitVacancyService.suggestions(payload));
      if (err) throwError(err);
      dispatch(unitVacancySuggestionSuccess());
      return response;
    } catch (error) {
      dispatch(unitVacancyError(error, LOADING.SUGGESTION_LOADING));
      toastMessage("error", ERROR_MESSAGE.LIST);
      return throwError(error);
    }
  };
}

export function getBuildingById(id) {
  return async dispatch => {
    dispatch(unitVacancyRequest());
    try {
      let building, err, pCode;
      // eslint-disable-next-line prefer-const
      [err, pCode] = await to(unitVacancyService.getBuildingFromSnowflake({ ...SEARCH_QUERY, id }));

      [err, building] = await to(unitVacancyService.getBuildingById(id));

      if (err) throwError(err);

      if (_isEmpty(pCode) && _isEmpty(building)) {
        toastMessage("error", "No record found!");
        return dispatch(unitVacancySuccess({}));
      }

      // selected Property in AddBuilding
      const { unitInfo } = pCode;
      const _uInfo = unitInfo?.filter(u => u.unitAreaSF >= 1000) || [];
      // merge snowflake units and new units created via DPM 2.0
      const pCodeUnits = new Set(_uInfo.map(d => d.unitId));
      const _unitInfo = [..._uInfo, ...(building?.unitInfo || []).filter(d => !pCodeUnits.has(d?.unitId))];

      const _availableUnits = _map(_unitInfo, ({ unitId: _unitId, ...r }) => {
        const isAvailableForBuilding = _findIndex(building?.unitInfo || [], { unitId: _unitId }) === -1;
        let _unit = {
          ...r,
          unitId: _unitId,
          isAvailableForBuilding,
          isNew: !pCodeUnits.has(_unitId),
          unmarketReason: r?.unmarketReason?.unmarketingReason,
          unmarketReasonId: r?.unmarketReason?.id,
          moreInformationRequired: r?.unmarketReason?.moreInformationRequired,
          unmarketAdditionalInformation: r?.unmarketAdditionalInformation,
          dateFieldLabel: r?.unmarketReason?.dateFieldLabel,
          dateFieldRequired: r?.unmarketReason?.dateFieldRequired,
        };
        if (!isAvailableForBuilding) {
          _unit = { ..._unit, ...(building?.unitInfo || []).find(d => d?.unitId === _unitId) };
        }
        if (_unit.isUnmarket) {
          _unit.updatedAt = _unit.lastUnmarketDate;
        } else if (_unit.status === PROPERTY_STATUS.published) {
          _unit.updatedAt = _unit.lastPublishDate;
        } else {
          _unit.updatedAt = _unit.updatedAt || pCode.createdAt;
        }

        if (!_unit?.status) {
          _unit.status = PROPERTY_STATUS.never_published;
        }
        return _unit;
      });

      // characteristics
      if (!building) {
        building = {
          status: pCode.status,
          buildingInfo: {
            propertyCode: pCode?.id,
            marketingPropertyName: pCode?.propertyInfo?.marketingPropertyName || 0,
          },
        };
      }
      building.unitInfo = _availableUnits
        .filter(u => !u.isNew)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      if (!_isEmpty(building)) {
        dispatch(unitVacancySuccess(building));
        return building;
      }
      return {};
    } catch (error) {
      dispatch(unitVacancyError(error));
      toastMessage("error", ERROR_MESSAGE.INFO);
      return throwError(error);
    }
  };
}

export function unitVacancyUnmarketUnit(values) {
  return async dispatch => {
    dispatch(unitVacancyRequest());
    try {
      const [err, response] = await to(unitVacancyService.unmarketUnit(values));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(unitVacancySuccess());
    } catch (error) {
      dispatch(unitVacancyError(error));
      throwError(error);
    }
  };
}

export function unitVacancyMarketUnit(values) {
  return async dispatch => {
    dispatch(unitVacancyRequest());
    try {
      const [err, response] = await to(unitVacancyService.marketUnit(values));
      if (err) throwError(err);
      toastMessage("success", SUCCESS_MESSAGE.EDITED);
      dispatch(unitVacancySuccess());
    } catch (error) {
      dispatch(unitVacancyError(error));
      throwError(error);
    }
  };
}
