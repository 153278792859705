import { Col, Row, Tag, Tooltip, Typography } from "antd";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _mapValues from "lodash/mapValues";
import React from "react";
import CustomIcon from "../components/CustomIcon/CustomIcon";
import {
  AUDIT_STATUS,
  AUDIT_STATUS_UI_MAPPING,
  CONTACT_TYPE_UI_MAPPING,
  COUNTRY_NAME,
  COUNTRY_NAME_IN_LOCAL_LOOKUP,
  MEASUREMENTS_LOOKUP,
  MEASUREMENT_TYPE,
  MEASURMENT_UNIT_CODES,
  OUTBOUND_API_TYPES,
  PROPERTY_DRAFT_VERSION_UI_MAPPING,
  PROPERTY_STATUS,
  PROPERTY_STATUS_UI_MAPPING,
  UNMARKET_UI_MAPPING,
  FIELD_TYPE,
  LEASE_STATUS,
  LEASE_STATUS_UI_MAPPING,
} from "./constants";
import {
  convertSfToSqm,
  convertSfToTsubo,
  convertSqmToSf,
  convertSqmToTsubo,
  convertTsuboToSf,
  convertTsuboToSqm,
} from "./conversion.util";
import { getFormattedDate, ifIncludes } from "./utils";

const mapAltText = (formValues, _lang, type, galleryIndex) => {
  if (_lang) {
    if (type === "gallery" || type === "propertyMarketingCollateral") {
      return formValues?.translations?.[_lang]?.media?.[type]?.[galleryIndex]?.alt_text;
    }
    return formValues?.translations?.[_lang]?.media?.[type]?.[0]?.alt_text;
  }

  if (type === "gallery" || type === "propertyMarketingCollateral") {
    return formValues?.media?.[type]?.[galleryIndex]?.alt_text;
  }
  return formValues?.media?.[type]?.[0]?.alt_text;
};

export const mapMediaContentWithAlt = (files = {}, translatedFiles = {}, form = {}, _lang) => {
  const { hero, aerial, gallery, sitePlan, propertyMarketingCollateral } = files;
  const { sitePlan: translatedSitePlan, propertyMarketingCollateral: translatedPropertyMarketingCollateral } =
    translatedFiles;
  const sitePlanToMap = _lang ? translatedSitePlan : sitePlan;
  const propertyMarketingCollateralToMap = _lang ? translatedPropertyMarketingCollateral : propertyMarketingCollateral;

  const formValues = { ...form.getFieldsValue() };
  return {
    hero: !_isEmpty(hero)
      ? [{ ..._mapValues(hero[0], e => e || ""), alt_text: mapAltText(formValues, _lang, "hero") }]
      : [],
    aerial: !_isEmpty(aerial)
      ? [{ ..._mapValues(aerial[0], e => e || ""), alt_text: mapAltText(formValues, _lang, "aerial") }]
      : [],
    gallery: !_isEmpty(gallery)
      ? _map(gallery, (g, galleryIndex) => ({
        ..._mapValues(g, e => e || ""),
        alt_text: mapAltText(formValues, _lang, "gallery", galleryIndex),
      }))
      : [],
    sitePlan: !_isEmpty(sitePlanToMap)
      ? [{ ..._mapValues(sitePlanToMap[0], e => e || ""), alt_text: mapAltText(formValues, _lang, "sitePlan") }]
      : [],
    propertyMarketingCollateral: !_isEmpty(propertyMarketingCollateralToMap)
      ? _map(propertyMarketingCollateralToMap, (g, marketingCollateralIndex) => ({
        ..._mapValues(g, e => e || ""),
        alt_text: mapAltText(formValues, _lang, "propertyMarketingCollateral", marketingCollateralIndex),
      }))
      : [],
  };
};

export const removeMediaContent = media => {
  const { hero, aerial, gallery, sitePlan, propertyMarketingCollateral } = media;
  return {
    hero: _map(hero, ({ alt_text: altText, asset_id: assetId }) => ({ alt_text: altText, asset_id: assetId })),
    aerial: _map(aerial, ({ alt_text: altText, asset_id: assetId }) => ({ alt_text: altText, asset_id: assetId })),
    gallery: _map(gallery, ({ alt_text: altText, asset_id: assetId }) => ({ alt_text: altText, asset_id: assetId })),
    sitePlan,
    propertyMarketingCollateral,
  };
};

export const renderAuditStatus = (_status, wrapInTag) => {
  let type = "danger";
  const mappedStatus = AUDIT_STATUS_UI_MAPPING[_status];

  if (_status === AUDIT_STATUS.audit_issue) type = "error";
  else if (_status === AUDIT_STATUS.ready_for_review) {
    if (wrapInTag) type = "processing";
    else type = "info";
  } else if (_status === AUDIT_STATUS.audit_passed) type = "success";
  if (wrapInTag) {
    return (
      <Tag className="text-capitalize" color={type}>
        {mappedStatus}
      </Tag>
    );
  }
  return <Typography.Text type={type}>{mappedStatus}</Typography.Text>;
};

export const wrapUnmarketInTag = unmarketDate => {
  if (!_isEmpty(unmarketDate)) {
    return (
      <>
        <Tag className="text-capitalize">{UNMARKET_UI_MAPPING.unmarketed}</Tag>
        on&nbsp;
        {getFormattedDate(unmarketDate)}
      </>
    );
  }
  return <Tag className="text-capitalize">{UNMARKET_UI_MAPPING.unmarketed}</Tag>;
};

/* Displays vacant tag in publish and availabillity units */
export const wrapVacantInTag = vacantDate => {
  if (vacantDate && !_isEmpty(vacantDate)) {
    return (
      <>
        <Tag className="text-capitalize">{UNMARKET_UI_MAPPING.vacant}</Tag>
        on&nbsp;
        {getFormattedDate(vacantDate)}
      </>
    );
  }
  return <Tag className="text-capitalize">{UNMARKET_UI_MAPPING.vacant}</Tag>;
};

/* Displays future vacancy tag in publish and availabillity units */
export const wrapFutureInTag = _unit => {
  const leaseEndDate = new Date(_unit.leaseInfo.leaseEndDate);
  const today = new Date();
  const twelveMonthsFromNow = new Date();
  twelveMonthsFromNow.setMonth(today.getMonth() + 12);

  if (leaseEndDate >= today && leaseEndDate <= twelveMonthsFromNow) {
    return (
      <>
        <Tag className="text-capitalize">{UNMARKET_UI_MAPPING.future_vacant}</Tag>
        &nbsp;
      </>
    );
  }
  return null;
};

export const getPropertyModifiedDate = (status, propertyOrUnit, propertyType) => {
  let className = "";
  let _status = "";

  if (propertyType === FIELD_TYPE.unit) className = "unit-status";

  const mappedStatus = PROPERTY_STATUS_UI_MAPPING[status];
  if (status !== PROPERTY_STATUS.never_published && propertyOrUnit) {
    const { lastPublishDate, updatedAt, lastUnpublishDate } = propertyOrUnit;

    if (!lastPublishDate && !updatedAt) {
      return <span className={`property-status ${className}`}>{PROPERTY_STATUS_UI_MAPPING[status]}</span>;
    }

    let formattedDate;

    if (status === PROPERTY_STATUS.published) {
      formattedDate = getFormattedDate(lastPublishDate);
    } else if (status === PROPERTY_STATUS.unpublished) {
      formattedDate = getFormattedDate(lastUnpublishDate);
    }
    _status = `${mappedStatus} on ${formattedDate}`;
  } else _status = mappedStatus;

  return <span className={`property-status ${className}`}>{_status}</span>;
};

export const isAsianCountry = (_form, parentKey, values) => {
  let _country;

  if (_form) {
    _country = _form.getFieldValue([parentKey, "address", "country"]);
  } else {
    _country = _get(values, `${parentKey}.address.country`, "");
  }
  return _country === "China" || _country === "Japan";
};

export const joinAddressFieldsWithComma = address => {
  const addressFieldsFiltered = address.filter(Boolean);
  return addressFieldsFiltered.join(", ");
};

export const buildAddressForPreview = (address, translatedAddress = {}, language) => {
  const {
    country,
    streetAddress1,
    streetAddress2 = "",
    postalCode = "",
    city = "",
    neighborhood = "",
    province = "",
    state = "",
    cedex = "",
  } = address;
  const translatedStreetAddress1 = _get(translatedAddress, "streetAddress1", "");
  const translatedStreetAddress2 = _get(translatedAddress, "streetAddress2", "");
  const _streetAddress1 = language === "en" ? streetAddress1 : translatedStreetAddress1;
  const _streetAddress2 = language === "en" ? streetAddress2 : translatedStreetAddress2;

  let addr = "";

  if (
    typeof country !== "undefined" ||
    typeof cedex !== "undefined" ||
    typeof streetAddress1 !== "undefined" ||
    typeof streetAddress2 !== "undefined" ||
    typeof neighborhood !== "undefined" ||
    typeof city !== "undefined" ||
    typeof postalCode !== "undefined" ||
    typeof state !== "undefined" ||
    typeof province !== "undefined"
  ) {
    if (
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.belgium, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.slovakia, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.brazil, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.italy, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.spain, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.canada, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.sweden, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.china, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.mexico, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.united_kingdom, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.czech_republic, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.netherlands, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.united_states, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.france, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.poland, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.germany, country) ||
      ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.singapore, country)
    ) {
      addr += `${_streetAddress1} ${_streetAddress2} `;
      if (
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.belgium, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.slovakia, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.sweden, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.czech_republic, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.netherlands, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.poland, country) ||
        ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.germany, country)
      ) {
        addr += `${postalCode} ${city} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.brazil, country)) {
        addr += `${neighborhood} ${city} ${province} ${postalCode} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.italy, country)) {
        addr += `${postalCode} ${city} ${province} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.spain, country)) {
        addr += `${postalCode} ${city} ${province} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.canada, country)) {
        addr += `${city} ${province} ${postalCode} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.china, country)) {
        addr += `${neighborhood} ${city} ${postalCode} ${province} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.mexico, country)) {
        addr += joinAddressFieldsWithComma([neighborhood, postalCode, city, province]);
        addr += ` ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.united_kingdom, country)) {
        addr += `${city} ${postalCode} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.united_states, country)) {
        addr += `${(city ? `${city}, ` : "") + (state ? `${state}, ` : "") + postalCode} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.france, country)) {
        addr += `${postalCode} ${city} ${typeof cedex !== "undefined" ? cedex : ""} ${country}`;
      } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.singapore, country)) {
        addr += `${postalCode} ${country}`;
      }
    } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.hungary, country)) {
      addr += `${city} ${_streetAddress1} ${_streetAddress2} ${postalCode} ${country}`;
    } else if (ifIncludes(COUNTRY_NAME_IN_LOCAL_LOOKUP.japan, country)) {
      addr += `${
        (_streetAddress1 ? `${_streetAddress1}, ` : "") +
        (_streetAddress2 ? `${_streetAddress2}, ` : "") +
        (city ? `${city}, ` : "") +
        (province ? `${province}, ` : "") +
        postalCode
      } ${country}`;
    } else {
      addr += `${_streetAddress1} ${_streetAddress2} ${neighborhood} ${city} ${postalCode} ${province} ${country}`;
    }
  }
  return addr;
};

export const getTotalAvailableForPreview = _measurement => {
  const max = _measurement.max_contiguous.value;
  const min = _measurement.min_divisible.value;

  if (min.toString() === max.toString()) {
    return min.toLocaleString("en-US");
  }
  return `${min.toLocaleString("en-US")} - ${max.toLocaleString("en-US")}`;
};

export const transformVideoLink = url => {
  const fullreg = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
  let youtubeVideoKey = "";

  const match = url.match(fullreg);
  if (match && match.length > 0) {
    youtubeVideoKey = url.split(regex);
    return `https://www.youtube.com/embed/${youtubeVideoKey[1]}`;
  }

  return url;
};

export const extractMediaUrl = asset => {
  let url = null;
  const dpmUrl = _get(asset, "dpm_url", "") ? _get(asset, "dpm_url", "").trim() : "";
  const originalUrl = _get(asset, "original_url", "") ? _get(asset, "original_url", "").trim() : "";
  if (dpmUrl !== "") url = dpmUrl;
  else if (originalUrl !== "") url = originalUrl;
  return url;
};

export const getAvailableSpaceForBuilding = (property, measurementType, maxSpace) => {
  const unitInfo = _get(property, "unitInfo", []);
  if (unitInfo.length === 0) {
    return maxSpace;
  }
  const unitSpaceSum = {
    sf: 0,
    sqm: 0,
    tsu: 0,
  };
  unitInfo
    .filter(unit => unit.status === PROPERTY_STATUS.published && !unit.isDraft)
    .forEach(unit => {
      if (unit.minSpaceAvailableUOM === "SF") unitSpaceSum.sf += unit.maxSpaceAvailable;
      if (unit.minSpaceAvailableUOM === "SQM") unitSpaceSum.sqm += unit.maxSpaceAvailable;
      if (unit.minSpaceAvailableUOM === "TSUBO") unitSpaceSum.tsu += unit.maxSpaceAvailable;
    });
  if (measurementType === "SF") {
    unitSpaceSum.sqm = convertSqmToSf(unitSpaceSum.sqm);
    unitSpaceSum.tsu = convertTsuboToSf(unitSpaceSum.tsu);
  }
  if (measurementType === "SQM") {
    unitSpaceSum.sf = convertSfToSqm(unitSpaceSum.sf);
    unitSpaceSum.tsu = convertTsuboToSqm(unitSpaceSum.tsu);
  }
  if (measurementType === "TSUBO") {
    unitSpaceSum.sf = convertSfToTsubo(unitSpaceSum.sf);
    unitSpaceSum.sqm = convertSqmToTsubo(unitSpaceSum.tsu);
  }
  const sum = unitSpaceSum.sf + unitSpaceSum.sqm + unitSpaceSum.tsu;
  return sum === 0 ? maxSpace : sum;
};

const getMeasurementData = (value, unit, measurementType, unitSymbol) => {
  const lookupKey = `${unit}:${measurementType}`;
  const measuredValue = MEASUREMENTS_LOOKUP[lookupKey] && value !== null ? MEASUREMENTS_LOOKUP[lookupKey](value) : null;
  return {
    value: measuredValue,
    units_type: unitSymbol,
  };
};

const getMeasurement = (
  minSpace,
  maxSpace,
  availableSpace,
  totalBuildingSize,
  officeSize,
  unit,
  measurementType,
  unitSymbol,
  apiType,
) => ({
  unit_type: measurementType,
  data: {
    available_space: getMeasurementData(availableSpace, unit, measurementType, unitSymbol),
    min_divisible: getMeasurementData(minSpace, unit, measurementType, unitSymbol),
    max_contiguous: getMeasurementData(maxSpace, unit, measurementType, unitSymbol),
    ...(apiType === OUTBOUND_API_TYPES.BUILDING && {
      building_size: getMeasurementData(totalBuildingSize, unit, measurementType, unitSymbol),
    }),
    // TODO : this will be coming from yardi therefore keeping it null for now
    ...(apiType === OUTBOUND_API_TYPES.UNIT && {
      marketing_office_area: getMeasurementData(null, unit, measurementType, unitSymbol),
    }),
    ...(apiType === OUTBOUND_API_TYPES.UNIT && {
      office_size: getMeasurementData(officeSize, unit, measurementType, unitSymbol),
    }),
  },
});

export const getMeasurements = (minSpace, maxSpace, totalBuildingSize, unit, apiType, availableSpace, officeSize) => [
  getMeasurement(
    minSpace,
    maxSpace,
    availableSpace,
    totalBuildingSize,
    officeSize,
    unit,
    MEASUREMENT_TYPE.US_CUSTOMARY,
    MEASURMENT_UNIT_CODES.SF,
    apiType,
  ),
  getMeasurement(
    minSpace,
    maxSpace,
    availableSpace,
    totalBuildingSize,
    officeSize,
    unit,
    MEASUREMENT_TYPE.METRIC,
    MEASURMENT_UNIT_CODES.SQM,
    apiType,
  ),
  getMeasurement(
    minSpace,
    maxSpace,
    availableSpace,
    totalBuildingSize,
    officeSize,
    unit,
    MEASUREMENT_TYPE.JAPANESE,
    MEASURMENT_UNIT_CODES.TSU,
    apiType,
  ),
];

const getLandMeasurement = (
  landSize,
  maxFutureBuildingSize,
  landunit,
  maxFutureBuildingUnit,
  measurementType,
  landUnitSymbol,
  futureBuildingSymbol,
) => ({
  unit_type: measurementType,
  data: {
    buildable_area: getMeasurementData(
      maxFutureBuildingSize,
      maxFutureBuildingUnit,
      measurementType,
      futureBuildingSymbol,
    ),
    land_area: getMeasurementData(landSize, landunit, measurementType, landUnitSymbol),
  },
});

export const getLandMeasurements = (landSize, maxFutureBuildingSize, landUnit, maxFutureBuildingUnit) => [
  getLandMeasurement(
    landSize,
    maxFutureBuildingSize,
    landUnit,
    maxFutureBuildingUnit,
    MEASUREMENT_TYPE.US_CUSTOMARY,
    MEASURMENT_UNIT_CODES.AC,
    MEASURMENT_UNIT_CODES.SF,
  ),
  getLandMeasurement(
    landSize,
    maxFutureBuildingSize,
    landUnit,
    maxFutureBuildingUnit,
    MEASUREMENT_TYPE.METRIC,
    MEASURMENT_UNIT_CODES.HA,
    MEASURMENT_UNIT_CODES.SQM,
  ),
  getLandMeasurement(
    landSize,
    maxFutureBuildingSize,
    landUnit,
    maxFutureBuildingUnit,
    MEASUREMENT_TYPE.JAPANESE,
    MEASURMENT_UNIT_CODES.HA,
    MEASURMENT_UNIT_CODES.TSU,
  ),
];

export const getClearHeight = (clearHeight, clearHeightUOM, measurementSystem) => {
  if (measurementSystem === MEASUREMENT_TYPE.US_CUSTOMARY) {
    if (clearHeightUOM === "FT") {
      return clearHeight;
    }
    return (clearHeight * 3.281).toPrecision(3);
  }
  if (clearHeightUOM === "FT") {
    return (clearHeight / 3.281).toPrecision(3);
  }
  return clearHeight;
};

export const isUSProperty = market => market?.country?.toLowerCase() === COUNTRY_NAME.UNITED_STATES;

/**
 * Display the status of a property wrapped in tag
 */
export const renderPropertyStatus = status => {
  let type = "error";
  if (status === PROPERTY_STATUS.published) type = "success";
  else if (status === PROPERTY_STATUS.unpublished) type = "processing";
  return (
    <Tag className="text-capitalize" color={type}>
      {PROPERTY_STATUS_UI_MAPPING[status]}
    </Tag>
  );
};

export const renderLeaseStatus = (status, _unit) => {
  if (_unit.leaseInfo && _unit.leaseInfo.leaseStatus === "N/A") {
    return wrapVacantInTag(_unit.leaseInfo.vacantDate);
  }
  if (LEASE_STATUS.current && _unit.leaseInfo && _unit.leaseInfo.leaseEndDate) {
    return wrapFutureInTag(_unit);
  }
  return null;
};

/**
 * Formats a contact object into a contact label string.
 * @param {Object} contact - The contact object containing properties like lastName, firstName, email, type, and company.
 * @returns {string} The formatted contact label string.
 */
export const getContactLabel = contact =>
  `${contact.lastName}, ${contact.firstName} - ${contact.email} (${CONTACT_TYPE_UI_MAPPING[contact.type]}) (${
    contact.company
  })`;

/**
 * Breaks a contact label string into separate properties.
 * @param {string} contactLabel - The contact label string to be parsed.
 * @returns {Object} An object containing separate properties for lastName, firstName, email, contactType, and company.
 */
export const breakContactLabel = contactLabel => {
  const regex = /(.+), (.+) - (.+) \((.+)\) \((.+)\)/;
  const [, lastName, firstName, email, contactType, company] = contactLabel.match(regex);
  return {
    lastName,
    firstName,
    email,
    contactType,
    company,
  };
};

export const getUnitLabel = label => (_isArray(label) && label[2]) || label;

/**
 * Display the draft status of the property with draft date
 */
export const getPropertyDraftVersion = draftDate => (
  <span className="property-status unit-status">
    {`${PROPERTY_DRAFT_VERSION_UI_MAPPING.draft_version} on ${getFormattedDate(draftDate)}`}
  </span>
);

export const renderPropertyStatusWithModifiedDate = (selected = {}, isTemporaryProperty) => {
  const { status, lastModifiedDate } = selected;
  return (
    <Row className="property-status text-end">
      <Col md={24}>
        {isTemporaryProperty && (
          <Tooltip title="Temporary Property">
            <CustomIcon className="mg-right-10" name="FileProtectOutlined" style={{ color: "green" }} />
          </Tooltip>
        )}
        {getPropertyModifiedDate(status, selected)}
      </Col>
      {selected?.isDraft && <Col md={24}>{getPropertyDraftVersion(lastModifiedDate)}</Col>}
    </Row>
  );
};

export const renderUnmarketDateAndLabel = unmarketData =>
  unmarketData?.unmarketLeaseDate && (
    <>
      {unmarketData?.dateFieldLabel}
      :&nbsp;
      {getFormattedDate(unmarketData?.unmarketLeaseDate)}
    </>
  );
