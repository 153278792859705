import { List } from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./CustomTableFilterList.scss";

const { Item } = List;

const CustomTableFilterList = ({ dataSource, confirm, setValue, language }) => {
  const _onClick = _item => {
    setValue(_item);
    confirm({ closeDropdown: true });
  };

  return (
    <div className="language-filter-list">
      <List
        dataSource={dataSource}
        renderItem={item => (
          <Item onClick={() => _onClick(item)} className={language?.value === item.value ? "selected" : ""}>
            {item.label}
          </Item>
        )}
      />
    </div>
  );
};

CustomTableFilterList.propTypes = {
  confirm: PropTypes.func,
  setValue: PropTypes.func,
  dataSource: PropTypes.array,
  language: PropTypes.object,
};

export default CustomTableFilterList;
