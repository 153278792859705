import { Button, Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchListView from "../../components/SearchListView/SearchListView";
import { exportPropertyContacts, getPropertyContactsList } from "../../store/actions/propertyContactHistory.action";
import { DATE_FORMAT_TIME, PAGINATOIN, PROPERTY_CONTACT_TYPE_UI_MAPPING, SORT_DIRECTION } from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import { removeUnderscore, replaceIfNull } from "../../utils/utils";
import "./PropertyContactsHistory.scss";

const rednderContacts = text => {
  const priorContacts = text.join(", ");
  return <p className="text-capitalize">{replaceIfNull(priorContacts, "N/A")}</p>;
};

const PropertyContactsHistory = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canExportPropertyContactHistory = permissionsUtil.checkAuth("property_contact_history_export");
  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const [exportingPropertyContacts, setExportingPropertyContacts] = useState(false);

  const { list, loading, pagination } = useSelector(store => store.propertyContactHistory);

  const fetchPropertyContacts = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getPropertyContactsList(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const exportAction = async () => {
    try {
      setExportingPropertyContacts(true);
      await dispatch(exportPropertyContacts());
    } catch (ex) {
      console.log(ex);
    } finally {
      setExportingPropertyContacts(false);
    }
  };

  const columns = [
    {
      title: t("Table.PropertyCode"),
      dataIndex: "propertyCode",
      key: "propertyCode",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyCode" && sortInfo?.order,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.PriorPropertyCode"),
      dataIndex: "priorPropertyCode",
      key: "priorPropertyCode",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "priorPropertyCode" && sortInfo?.order,
      render: text => replaceIfNull(text, "N/A"),
    },
    {
      title: t("Table.PropertyType"),
      dataIndex: "propertyType",
      key: "propertyType",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "propertyType" && sortInfo?.order,
      render: text => <p className="text-capitalize">{replaceIfNull(removeUnderscore(text), "N/A")}</p>,
    },
    {
      title: t("Table.PriorPropertyType"),
      dataIndex: "priorPropertyType",
      key: "priorPropertyType",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "priorPropertyType" && sortInfo?.order,
      render: text => <p className="text-capitalize">{replaceIfNull(removeUnderscore(text), "N/A")}</p>,
    },
    {
      title: t("Table.ContactType"),
      dataIndex: "contactType",
      key: "contactType",
      render: text => <p className="text-capitalize">{replaceIfNull(PROPERTY_CONTACT_TYPE_UI_MAPPING[text], "N/A")}</p>,
    },
    {
      title: t("Table.PriorContact"),
      dataIndex: "previousContact",
      key: "previousContact",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "previousContact" && sortInfo?.order,
      render: (text, record) => rednderContacts(text, record),
    },
    {
      title: t("Table.NewContact"),
      dataIndex: "currentContact",
      key: "currentContact",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "currentContact" && sortInfo?.order,
      render: (text, record) => rednderContacts(text, record),
    },
    {
      title: t("Table.LastModifiedDate"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: SORT_DIRECTION.reverse,
      sortOrder: sortInfo?.field === "updatedAt" && sortInfo?.order,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : "N/A"),
    },
    {
      title: t("Table.ModifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === "modifiedBy" && sortInfo?.order,
      render: text => <p className="text-capitalize">{replaceIfNull(text, "N/A")}</p>,
    },
  ];

  return (
    <div className="property-contact-history">
      <Row className="action-btns row-vertical-padding-8" gutter={[8, 12]} type="flex" justify="end">
        {canExportPropertyContactHistory && (
          <Col xs={24} sm={24} md={4} lg={4} xl={3} xxl={3}>
            <Button block onClick={exportAction} loading={exportingPropertyContacts}>
              {t("Table.Export")}
            </Button>
          </Col>
        )}
      </Row>
      <SearchListView
        paginationKey={PAGINATOIN.contactHistory}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        loading={loading}
        dataSource={list}
        columns={columns}
        getList={fetchPropertyContacts}
        pagination={pagination}
        scroll={{ y: "calc(100vh - 560px)", x: 1400 }}
      />
    </div>
  );
};

export default PropertyContactsHistory;
