import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SearchListView from "../../components/SearchListView/SearchListView";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  DATE_FORMAT_TIME,
  DEFAULT_DOWNTIME_REPORT_SORTER,
  DOWNTIME_REPORT_COLUMN_NAME,
  PAGINATOIN,
  ROUTES,
  SORT_DIRECTION,
} from "../../utils/constants";
import { replaceIfNull } from "../../utils/utils";
import { getDowntimeReport } from "../../store/actions/downtimeReport.actions";
const DowntimeReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterInfo, setFilterInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);

  const { list, loading, pagination } = useSelector(store => store.downtimeReport);

  const fetchDowntimeReport = async query => {
    try {
      let _query = query;
      if (!_query) {
        _query = pagination;
      }
      await dispatch(getDowntimeReport(_query));
    } catch (ex) {
      console.log(ex);
    }
  };

  const columns = [
    {
      title: t(DOWNTIME_REPORT_COLUMN_NAME.MONTH_YEAR.title),
      dataIndex: DOWNTIME_REPORT_COLUMN_NAME.MONTH_YEAR.key,
      key: DOWNTIME_REPORT_COLUMN_NAME.MONTH_YEAR.key,
      sorter: true,
      sortDirections: SORT_DIRECTION.threeDirection,
      sortOrder: sortInfo?.field === DOWNTIME_REPORT_COLUMN_NAME.MONTH_YEAR.key && sortInfo?.order,
      render: (text, record) => replaceIfNull(record.month),
    },
    {
      title: t(DOWNTIME_REPORT_COLUMN_NAME.TIMEOUT_DURATION.title),
      dataIndex: DOWNTIME_REPORT_COLUMN_NAME.TIMEOUT_DURATION.key,
      key: DOWNTIME_REPORT_COLUMN_NAME.TIMEOUT_DURATION.key,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === DOWNTIME_REPORT_COLUMN_NAME.TIMEOUT_DURATION.key && sortInfo?.order,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
    {
      title: t(DOWNTIME_REPORT_COLUMN_NAME.THROTTLE_DURATION.title),
      dataIndex: DOWNTIME_REPORT_COLUMN_NAME.THROTTLE_DURATION.key,
      key: DOWNTIME_REPORT_COLUMN_NAME.THROTTLE_DURATION.key,
      sorter: true,
      sortDirections: SORT_DIRECTION.twoDirection,
      sortOrder: sortInfo?.field === DOWNTIME_REPORT_COLUMN_NAME.THROTTLE_DURATION.key && sortInfo?.order,
      render: date => (date ? dayjs(date).format(DATE_FORMAT_TIME) : t("Table.NA")),
    },
  ];

  return (
    <>
      <PageTitle title={ROUTES.DOWNTIME_REPORT.title} />
      <SearchListView
        defaultSort={DEFAULT_DOWNTIME_REPORT_SORTER}
        paginationKey={PAGINATOIN.downtimeReport}
        setFilterInfo={setFilterInfo}
        setSortInfo={setSortInfo}
        rowKey="id"
        loading={loading}
        dataSource={list}
        columns={columns}
        getList={fetchDowntimeReport}
        pagination={pagination}
        scroll={{ x: 950, y: 350 }}
      />
    </>
  );
};

export default DowntimeReport;
