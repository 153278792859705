import { Button, Checkbox, Col, Form, Radio, Row, Space, Spin, Table, Tag, Tooltip } from "antd";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _find from "lodash/find";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Sticky, StickyContainer } from "react-sticky";
import AppModal from "../../components/AppModal/AppModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import ListView from "../../components/ListView/ListView";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useLanguage } from "../../hooks";
import { setLanguage } from "../../store/actions/language.actions";
import {
  getBuildingById,
  unitVacancyMarketUnit,
  unitVacancySuccess,
  unitVacancyUnmarketUnit,
} from "../../store/actions/unitVacancy.actions";
import { getAllUnmarketReasons } from "../../store/actions/unmarketReasons.action";
import {
  FIELD_TYPE,
  FILTERS_PROPERTY_MARKETING_STATUS,
  FILTERS_UNIT_VACANCY_STATUS,
  HELPER_TEXT_KEYS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_UI_MAPPING,
  ROUTES,
} from "../../utils/constants";
import permissionsUtil from "../../utils/permissions.util";
import {
  renderPropertyStatus,
  renderPropertyStatusWithModifiedDate,
  renderUnmarketDateAndLabel,
  wrapUnmarketInTag, wrapVacantInTag, wrapFutureInTag, renderLeaseStatus,
} from "../../utils/property.util";
import { getDangerouslySetHTML, getTranslatedHelperText, toastMessage } from "../../utils/utils";
import UnmarketForm from "../UnmarketForm/UnmarketForm";
import "./AddEditUnitVacancy.scss";

const AddEditUnitVacancy = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id } = useParams();
  const { language } = useLanguage();
  const { lookupList } = useSelector(state => state.helperText);
  const { loading, value: selected } = useSelector(state => ({
    ...state.unitVacancy,
  }));

  const { list } = useSelector(state => ({
    ...state.unmarketReason,
  }));

  const [filterPropertyUnitStatus, setFilterPropertyUnitStatus] = useState("all");
  const [filterPropertyMarketingStatus, setFilterPropertyMarketingStatus] = useState("all");
  const [unitsToBeUnmarket, setUnitsToBeUnmarket] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketModalOpen, setMarketModalOpen] = useState(false);
  const [nonMarketUnits, setNonMarketUnits] = useState([]);
  const [unitsToBeMarket, setUnitsToBeMarket] = useState([]);

  const [reasonToBeEdit, setReasonToBeEdit] = useState([]);
  const [isEditUnmarketReason, setIsEditUnmarketReason] = useState(false);
  const _title = `${t("UnitVacancy.Title")} ${id}`;
  const canUpdateUnitVacancy = permissionsUtil.checkAuth("unit_vacancy_update");

  useEffect(() => {
    getBuilding();
    return clearStoreState;
  }, [id, t]);

  useEffect(() => {
    setNonMarketUnits(selected?.unitInfo?.filter(unit => unit.isUnmarket));
  }, [selected]);

  const getBuilding = async () => {
    setLoadingData(true);
    setUnitsToBeUnmarket([]);
    try {
      if (id) {
        await dispatch(getBuildingById(id));
        await dispatch(getAllUnmarketReasons());
      }
    } catch (ex) {
      console.log("ex", ex);
    } finally {
      setLoadingData(false);
    }
  };

  const clearStoreState = () => {
    dispatch(unitVacancySuccess({}));
    dispatch(setLanguage());
  };

  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);
  const toggleMarketModalVisibility = () => setMarketModalOpen(!marketModalOpen);
  const toggleEditReasonModalVisibility = () => setIsEditUnmarketReason(!isEditUnmarketReason);

  const _onClose = () => {
    form.resetFields();
    setReasonToBeEdit([]);
    toggleModalVisibility();
  };

  const onMarketModalClose = () => {
    toggleMarketModalVisibility();
    setUnitsToBeMarket([]);
  };
  const onEditReasonModalClose = () => {
    toggleEditReasonModalVisibility();
    setReasonToBeEdit([]);
  };

  const onEditReason = () => {
    toggleEditReasonModalVisibility();
  };

  const onNextToEditReason = async () => {
    toggleEditReasonModalVisibility();

    setIsModalOpen(true);
  };

  const onSelectUnitsToEditReason = selectedUnits => {
    setReasonToBeEdit([...selectedUnits]);
  };

  const onUnitsUnmarketSubmit = async () => {
    const _unitInfo = form.getFieldValue();
    const _units = !_isEmpty(reasonToBeEdit) ? reasonToBeEdit : unitsToBeUnmarket;

    setLoadingData(true);
    return form
      .validateFields()
      .then(async () => {
        const unitInfo = _units.map(unit => {
          const _unit = _unitInfo[unit.unitId];

          if (_unitInfo?.isSameReason) {
            const _sameUnmarketReason = _find(list, resason => resason.id === _unitInfo.sameReason.value);

            const _data = {
              unitId: unit.unitId,
              unitCode: unit.unitCode,
              unmarketReason: _unitInfo.sameReason.value,
              unmarketReasonLabel: _sameUnmarketReason.unmarketingReason,
              status: unit.status,
              ...(_unitInfo.sameReason?.unmarketAdditionalInformation && {
                unmarketAdditionalInformation: _unitInfo.sameReason.unmarketAdditionalInformation,
              }),
              ...(_unitInfo.sameReason?.unmarketLeaseDate && {
                unmarketLeaseDate: _unitInfo.sameReason.unmarketLeaseDate
                  ? dayjs(_unitInfo.sameReason.unmarketLeaseDate).valueOf()
                  : undefined,
              }),
              dateFieldLabel: _sameUnmarketReason.dateFieldLabel,
            };
            return { ..._data };
          }

          const _unmarketReason = _find(list, resason => resason.id === _unit.value);

          const _data = {
            unitId: unit.unitId,
            unmarketReason: _unit.value,
            unitCode: unit.unitCode,
            unmarketReasonLabel: _unmarketReason.unmarketingReason,
            status: unit.status,
            ...(_unit?.unmarketAdditionalInformation && {
              unmarketAdditionalInformation: _unit.unmarketAdditionalInformation,
            }),
            ...(_unit?.unmarketLeaseDate && {
              unmarketLeaseDate: _unit.unmarketLeaseDate ? dayjs(_unit.unmarketLeaseDate).valueOf() : undefined,
            }),
            dateFieldLabel: _unmarketReason.dateFieldLabel,
          };
          return { ..._data };
        });
        const values = {
          parent: id,
          unitInfo,
        };
        await dispatch(unitVacancyUnmarketUnit(values));
        setIsModalOpen(false);
        setReasonToBeEdit([]);
        form.resetFields();
        await getBuilding();
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        toastMessage("error", errorFields?.[0]?.errors?.[0]);
      })
      .finally(() => setLoadingData(false));
  };

  const filteredFields = _filter(
    selected?.unitInfo,
    _unit =>
      (filterPropertyUnitStatus === "all" || filterPropertyUnitStatus === _unit?.status) &&
      (filterPropertyMarketingStatus === "all" || filterPropertyMarketingStatus === _unit?.isUnmarket),
  );

  const _renderUnmarketUnitStatus = _unit => _unit?.isUnmarket && wrapUnmarketInTag(_unit.lastUnmarketDate);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setUnitsToBeUnmarket(selectedRows);
  };

  const _onSelectFromAllPages = () => {
    const selectedRows = selected?.unitInfo.filter(
      record =>
        record.status !== PROPERTY_STATUS.published &&
        !record.isUnmarket &&
        (filterPropertyUnitStatus === "all" || filterPropertyUnitStatus === record?.status),
    );
    setUnitsToBeUnmarket(selectedRows);
  };

  const selectAllUnitsDropdownVisible =
    filterPropertyUnitStatus !== "published" && !_isEmpty(filteredFields) && filteredFields.some(_r => !_r.isUnmarket);

  const getSelections = () => {
    if (selectAllUnitsDropdownVisible) {
      return [
        {
          key: Table.SELECTION_ALL,
          text: t("UnitVacancy.SelectFromAllPages"),
          onSelect: _onSelectFromAllPages,
        },
        Table.SELECTION_NONE,
      ];
    }
    return null;
  };

  const getRowSelection = () =>
    canUpdateUnitVacancy
      ? {
        onChange: onSelectChange,
        selections: getSelections(),
        getCheckboxProps: record => ({
          disabled: record.status === PROPERTY_STATUS.published || record.isUnmarket,
        }),
        selectedRowKeys: unitsToBeUnmarket.map(_unit => _unit.unitId),
      }
      : null;

  const _onSelectUnitsToMarket = selectedUnits => {
    setUnitsToBeMarket([...selectedUnits]);
  };

  const onUnitsMarketSubmit = async () => {
    const payload = {
      parent: id,
      units: unitsToBeMarket,
    };
    try {
      setLoadingData(true);
      await dispatch(unitVacancyMarketUnit(payload));
      setMarketModalOpen(false);
      setUnitsToBeMarket([]);
      await getBuilding();
    } catch (ex) {
      console.log("ex", ex);
      const { errorFields } = ex;
      toastMessage("error", errorFields?.[0]?.errors?.[0]);
    } finally {
      setLoadingData(false);
    }
  };

  const columns = [
    {
      title: t("UnitInfoForm.UnitCode"),
      key: "unitCode",
      dataIndex: "unitCode",
      width: 60,
      render: (text, record) => (
        <a>
          {text}
          {record?.isDraft && (
            <>
              <Tag className="text-capitalize float-right" color="warning">
                {t("PropertyStatusUiMapping.draft")}
              </Tag>
            </>
          )}
        </a>
      ),
    },
    {
      title: t("UnitInfoList.UnitStatus"),
      key: "status",
      dataIndex: "status",
      width: 55,
      render: text => renderPropertyStatus(text),
    },
    {
      title: t("UnitVacancy.VacancyStatus"),
      key: "leaseStatus",
      dataIndex: "leaseStatus",
      width: 55,
      render: (text, record) => renderLeaseStatus(text, record),
    },

    {
      title: t("UnitVacancy.AvailableUnit"),
      key: "isAvailable",
      dataIndex: "isAvailable",
      width: 50,
      render: (text, record) => (record.isAvailable ? t("UnitVacancy.Yes") : t("UnitVacancy.No")),
    },
    {
      title: t("UnitVacancy.NotMarketingStateWithTimeStamp"),
      key: "isUnmarket",
      dataIndex: "isUnmarket",
      width: 130,
      render: (text, record) => _renderUnmarketUnitStatus(record),
    },
    {
      title: t("UnitVacancy.NotMarketingReason"),
      key: "unmarketReason",
      dataIndex: "unmarketReason",
      width: 120,
      render: (text, record) => record?.unmarketReason?.unmarketingReason || record?.unmarketReason || "",
    },
    {
      title: t("UnitVacancy.AdditionalInformation"),
      key: "unmarketAdditionalInformation",
      dataIndex: "unmarketAdditionalInformation",
      width: 100,
      render: (text, record) => record.unmarketAdditionalInformation && text,
    },
    {
      title: t("UnitVacancy.UnavailableReasonDate"),
      key: "unmarketLeaseDate",
      dataIndex: "unmarketLeaseDate",
      width: 100,
      render: (text, record) => renderUnmarketDateAndLabel(record),
    },
  ];

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky topOffset={-60}>
      {({ style, isSticky }) => (
        <div
          className={`custom-sticky-tab-bar ${selected?.isDraft ? "isDraft" : ""}`}
          style={{
            ...style,
            marginTop: isSticky ? 56 : 0,
          }}
        >
          <PageTitle
            title={_title}
            items={[ROUTES.UNIT_VACANCY, { title: id }]}
            propertyStatus={renderPropertyStatusWithModifiedDate(selected)}
          />

          <Row className="UnitInfoList__filters-row" align="middle">
            <Col className="UnitInfoList__filter-col">
              <label htmlFor="UnitInfoList__filter-unit-status">
                {t("UnitInfoList.UnitStatus")}
                &nbsp;
                {!_isEmpty(lookupList[HELPER_TEXT_KEYS.unit.unitStatusFilter]) && (
                  <Tooltip
                    title={getDangerouslySetHTML(
                      getTranslatedHelperText(lookupList[HELPER_TEXT_KEYS.unit.unitStatusFilter], language),
                    )}
                  >
                    <CustomIcon name="InfoCircleOutlined" />
                  </Tooltip>
                )}
              </label>
              <Radio.Group
                size="middle"
                name="UnitInfoList__filter-unit-status"
                className="UnitInfoList__filter"
                value={filterPropertyUnitStatus}
                onChange={e => setFilterPropertyUnitStatus(e.target.value)}
                optionType="button"
              >
                {FILTERS_UNIT_VACANCY_STATUS.map(unitStatus => (
                  <Radio.Button value={unitStatus.value} key={unitStatus.label}>
                    {unitStatus.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            <Col className="UnitInfoList__filter-col">
              <label htmlFor="UnitInfoList__filter-unit-status">{t("AddEditUnitVacancy.marketingStatus")}</label>
              <Radio.Group
                size="middle"
                name="AddEditUnitVacancy-marketing-status"
                className="UnitInfoList__filter"
                value={filterPropertyMarketingStatus}
                onChange={e => setFilterPropertyMarketingStatus(e.target.value)}
                optionType="button"
              >
                {FILTERS_PROPERTY_MARKETING_STATUS.map(marketingStatus => (
                  <Radio.Button value={marketingStatus.value} key={marketingStatus.label}>
                    {marketingStatus.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            {canUpdateUnitVacancy && (
              <Col>
                <Space>
                  <Button disabled={_isEmpty(nonMarketUnits)} type="primary" onClick={onEditReason} loading={loading}>
                    {t("Buttons.EditReason")}
                  </Button>
                  <Button
                    disabled={_isEmpty(nonMarketUnits)}
                    type="primary"
                    onClick={toggleMarketModalVisibility}
                    loading={loading}
                  >
                    {t("Buttons.Marketing")}
                  </Button>
                  <Button
                    disabled={_isEmpty(unitsToBeUnmarket)}
                    onClick={toggleModalVisibility}
                    type="primary"
                    loading={loading}
                  >
                    {t("Buttons.Unmarket")}
                  </Button>
                </Space>
              </Col>
            )}
          </Row>
        </div>
      )}
    </Sticky>
  );

  return (
    <div className="edit-unit-vacancy-container">
      {loadingData ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <StickyContainer>
        {renderTabBar()}
        <ListView
          rowKey="unitId"
          dataSource={filteredFields}
          columns={columns}
          loading={loading}
          scroll={{ x: 350, y: 650 }}
          rowSelection={getRowSelection()}
        />
      </StickyContainer>
      <AppModal
        title={t("UnitUnmarket.Title", { propertyType: PROPERTY_TYPE_UI_MAPPING.unit })}
        width={700}
        minHeight="20vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={isModalOpen}
        onCancel={_onClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block onClick={_onClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block loading={loadingData} onClick={onUnitsUnmarketSubmit} type="primary">
                {t("Buttons.Save")}
              </Button>
            </Col>
          </Row>
        }
      >
        <UnmarketForm
          form={form}
          unmarketData={!_isEmpty(reasonToBeEdit) ? reasonToBeEdit : unitsToBeUnmarket}
          propertyType={FIELD_TYPE.building}
        />
      </AppModal>

      <AppModal
        title={t("UnitMarket.MarketTitle", { propertyType: FIELD_TYPE.unit })}
        width={800}
        minHeight="40vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={marketModalOpen}
        onCancel={onMarketModalClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={onMarketModalClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                disabled={_isEmpty(unitsToBeMarket)}
                loading={loadingData}
                type="primary"
                onClick={onUnitsMarketSubmit}
              >
                {t("Buttons.Market", { propertyType: PROPERTY_TYPE_UI_MAPPING.unit })}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Checkbox.Group
              className="block"
              name="unitsToBeMarket"
              value={unitsToBeMarket}
              onChange={_onSelectUnitsToMarket}
            >
              <Row gutter={[18, 18]}>
                {_map(nonMarketUnits, (val, key) => (
                  <Col xs={24} key={key}>
                    <Row gutter={[8, 8]}>
                      <Col xs={6}>
                        <Checkbox key={key} value={val?.unitId}>
                          {val?.unitCode}
                        </Checkbox>
                      </Col>
                      <Col xs={6}>{renderPropertyStatus(val?.status)}</Col>
                      <Col xs={10}>{_renderUnmarketUnitStatus(val)}</Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </AppModal>
      <AppModal
        title={t("UnitUnmarket.EditReasonTitle", { propertyType: PROPERTY_TYPE_UI_MAPPING.unit })}
        width={800}
        minHeight="40vh"
        maxHeight="70vh"
        overFlowY="auto"
        visible={isEditUnmarketReason}
        onCancel={onEditReasonModalClose}
        destroyOnClose
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Button block onClick={onEditReasonModalClose}>
                {t("Buttons.Discard")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button
                block
                disabled={_isEmpty(reasonToBeEdit)}
                loading={loadingData}
                type="primary"
                onClick={onNextToEditReason}
              >
                {t("Buttons.Next")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Checkbox.Group
              className="block"
              name="reasonToBeEdit"
              value={reasonToBeEdit}
              onChange={onSelectUnitsToEditReason}
            >
              <Row gutter={[18, 18]}>
                {_map(nonMarketUnits, (val, key) => (
                  <Col xs={24} key={key}>
                    <Row gutter={[8, 8]}>
                      <Col xs={6}>
                        <Checkbox key={key} value={val}>
                          {val?.unitCode}
                        </Checkbox>
                      </Col>
                      <Col xs={6}>{renderPropertyStatus(val?.status)}</Col>
                      <Col xs={10}>{_renderUnmarketUnitStatus(val)}</Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </AppModal>
    </div>
  );
};

export default AddEditUnitVacancy;
