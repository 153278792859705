import { Button, Checkbox, Col, Form, Row, Spin } from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";
import _mapValues from "lodash/mapValues";
import _reduce from "lodash/reduce";
import _some from "lodash/some";
import _values from "lodash/values";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import AppModal from "../../components/AppModal/AppModal";
import LangModal from "../../components/LangModal/LangModal";
import PopUp from "../../components/PopUp/PopUp";
import { useLanguage, useLocationHash } from "../../hooks";
import { sendAnalyticsEvent } from "../../store/actions/analytics.actions";
import {
  addBuilding,
  buildingCodeSuccess,
  buildingOccupiedSpaceSuccess,
  buildingSuccess,
  buildingUOMSuccess,
  getBuildingById,
  buildingPublishAndPassAudit,
  publishedUnitsSuccess,
  totalBuildingSizeSuccess,
  unitDetailSuccess,
  updateBuilding,
  updateCombinedUnitNumber,
} from "../../store/actions/building.actions";
import { setLanguage } from "../../store/actions/language.actions";
import {
  activePropertySubMarketsSuccess,
  auditSuccess,
  getAllCountryAddressFields,
  getAllPropertyRollUpSuccess,
  getAuditList,
  getPropertyAllMarkets,
  getPropertyBrokers,
  getPropertyESMContacts,
  getPropertyEmployeeContacts,
  getPropertyMedia,
  propertyAddressFieldsListSuccess,
  propertyCodesSuccess,
  propertyMediaSuccess,
} from "../../store/actions/property.actions";
import {
  CLEAR_HEIGHT_MEASUREMENT_UNITS,
  GTAG_EVENTS,
  KEY_FEATURE_TEMPLATE_KEYS,
  LANGUAGE_CODES,
  MEASUREMENT_UNITS,
  PROPERTY_KEY_FEATURE_TEMPLATE_KEYS,
  PROPERTY_STATUS,
  PROPERTY_TYPE_KEYS,
  ROUTES,
  SEARCH_QUERY,
  USER_ESM_TYPE,
  USER_TYPE,
  VALIDATE_FORM_MESSAGES_TEMPLATE,
  BUILDING_TABS_LOOKUP as _tabs,
  BUILDING_TABS_KEYS as _tabsKey,
  BUILDING_REQUIRED_FIELDS as requiredFields,
  BUILDING_TRANSLATION_REQUIRED_FIELDS as requiredTranslationFields,
  UNIT_INFO_KEYS as unitInfoKeys,
  PROPERTY_AUDIT_STATUS,
} from "../../utils/constants";
import {
  getContactLabel,
  getUnitLabel,
  isUSProperty,
  mapMediaContentWithAlt,
  removeMediaContent,
} from "../../utils/property.util";
import {
  filterOption,
  getFeatureFlags,
  getTemplateList,
  removedEmptyObject,
  setCountryAddressFormFields,
  throwError,
  to,
  toastMessage,
  updateCountryAddressFields,
} from "../../utils/utils";
import PropertySearchPreview from "../PropertySearchPreview/PropertySearchPreview";
import "./AddEditBuilding.scss";
import BuildingFormGroup from "./BuildingFormGroup";

const uomKeys = ["minSpaceAvailableUOM", "maxSpaceAvailableUOM", "totalBuildingSizeUOM"];

const errorTabsInitialObj = {
  buildingInfo: false,
  keyFeatures: false,
  contacts: false,
  media: false,
  unitInfo: false,
};

const AddEditProperty = ({ componentProps: { viewOnlyMode = false } }) => {
  const { t } = useTranslation();
  const { activeTabKey, setActiveTabKey } = useLocationHash({ defaultTabKey: _tabs.buildingInfo.key, _tabs, _tabsKey });
  const history = useHistory();
  const [_files, _setFiles] = useState({});
  const [_translatedFiles, _setTranslatedFiles] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [errorTabs, setErrorTabs] = useState({
    primary: { ...errorTabsInitialObj },
    secondary: {},
  });

  const [isCallByAudit, setIsCallByAudit] = useState(false);
  const [countryAddressFields, setCountryAddressFields] = useState({});
  const [editTimestamp, setEditTimestamp] = useState();
  const [status, setStatus] = useState("");
  const [mediaErrors, setMediaErrors] = useState({});
  const [isTranslated, setIsTranslated] = useState({});
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [isTemporaryProperty, setIsTemporaryProperty] = useState(false);
  const [notAvailable, setNotAvailable] = useState({
    truckCourtDepth: false,
    unitInfo: [],
  });
  const [unitsToPublish, setUnitsToPublish] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLangModalOpen, setIsLangModalOpen] = useState(false);
  const [popupVisible, togglePopupVisibility] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isPreviewActive, setIsPreviewActive] = useState(false);

  const [form] = Form.useForm();
  const _filesRef = useRef(_files);
  const { allCountryAddressFields, employeeList, brokerList } = useSelector(state => ({
    ...state.property,
  }));
  const { language, languageStatus } = useLanguage();
  const {
    codeDetail: selectedProperty,
    loading,
    selected,
    publishedUnits,
    uom,
    unitDetail,
  } = useSelector(state => ({
    ...state.building,
  }));
  const { marketingDescriptionTemplateList, keyFeatureTemplateList, unitKeyFeatureTemplateList } = useSelector(
    state => ({
      marketingDescriptionTemplateList: getTemplateList(state.template.list, "marketingDescription", "building"),
      keyFeatureTemplateList: getTemplateList(state.template.list, "keyFeatures", "building"),
      unitKeyFeatureTemplateList: getTemplateList(state.template.list, "keyFeatures", "unit"),
    }),
  );
  const [_employeeList, _setEmployeeList] = useState(employeeList);
  const [_brokerList, _setBrokerList] = useState(brokerList);
  const FEATURE_FLAG = getFeatureFlags();

  useEffect(() => {
    const langKey = {};

    if (!_isEmpty(language.secondary) && !errorTabs.secondary[language.secondary[0]]) {
      _forEach(language.secondary, _language => {
        langKey.secondary = {
          ...langKey.secondary,
          [_language]: {
            ...errorTabsInitialObj,
          },
        };
      });
    }
    setErrorTabs({ ...errorTabs, ...langKey });
  }, [language]);

  useEffect(() => {
    _setEmployeeList([..._employeeList, ...employeeList]);
  }, [employeeList]);

  useEffect(() => {
    _setBrokerList([..._brokerList, ...brokerList]);
  }, [brokerList]);

  const dispatch = useDispatch();
  const { id } = useParams();
  const isEditView = !!id;

  const initialValues = {
    buildingInfo: {},
    unitInfo: [],
    status,
    translations: {},
    addUnits: 0,
  };

  const mapTranslatedMedia = (_translations = {}) => {
    const translatedMedia = {};
    Object.keys(_translations).forEach(item => {
      translatedMedia[item] = {
        media: _get(_translations, `${item}.media`, {}),
      };
    });

    return translatedMedia;
  };

  const setFiles = (files, _translations) => {
    const filesObj = { ...files };
    const translatedMedia = mapTranslatedMedia(_translations);
    _setFiles(filesObj);
    _setTranslatedFiles(translatedMedia);
    _filesRef.current = filesObj;
  };

  useEffect(() => {
    if (!viewOnlyMode) {
      setEditTimestamp(dayjs().valueOf());
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      try {
        await dispatch(getAllCountryAddressFields());
        if (id) {
          if (viewOnlyMode) dispatch(sendAnalyticsEvent(GTAG_EVENTS.VIEW_PROPERTY, { propertyCode: id }));
          else dispatch(sendAnalyticsEvent(GTAG_EVENTS.EDIT_PROPERTY, { propertyCode: id }));
          await dispatch(getBuildingById(id));
          await dispatch(getAuditList(id));
          dispatch(getPropertyMedia({ id, propertyType: PROPERTY_TYPE_KEYS.building }));
        } else {
          dispatch(sendAnalyticsEvent(GTAG_EVENTS.ADD_PROPERTY, { propertyCode: undefined }));
        }
        const filterCriteriaBroker = {
          isActive: true,
        };
        const filterCriteriaEmployee = { isActive: true, type: USER_TYPE.EMPLOYEE };
        const query = {
          ...SEARCH_QUERY,
          sort: "lastName,asc",
        };
        dispatch(getPropertyBrokers({ ...query, filterCriteria: filterCriteriaBroker }));
        dispatch(getPropertyEmployeeContacts({ ...query, filterCriteria: filterCriteriaEmployee }));
        if (FEATURE_FLAG.ESMCONTACT?.status) {
          const filterCriteriaESM = { isActive: true, type: USER_TYPE.ESM, jobTitle: USER_ESM_TYPE.JOB_TITLE };

          dispatch(getPropertyESMContacts({ ...query, filterCriteria: filterCriteriaESM }));
        }
      } catch (ex) {
        console.log("ex", ex);
      } finally {
        setLoadingData(false);
      }
    })();
    return clearStoreState;
  }, [id, t]);

  useEffect(() => {
    const _unitsToPublish = _map(publishedUnits, _publishedUnit => {
      if (_publishedUnit.unitCode.length > 1) {
        return _publishedUnit.ghostUnitCode;
      }
      return _publishedUnit.unitCode[0]?.value;
    });
    setUnitsToPublish(_unitsToPublish);
  }, [publishedUnits]);

  useEffect(() => {
    if (!_isEmpty(uom)) {
      const _buildingInfo = form.getFieldValue("buildingInfo");
      const _unitInfo = _cloneDeep(form.getFieldValue("unitInfo"));
      const _uomKeys = {};
      _map(uomKeys, key => {
        _uomKeys[key] = uom;
      });
      _map(_unitInfo, (v, index) => {
        const unitUOMKeys = { ..._uomKeys };
        if (form.getFieldValue(["unitInfo", index, "officeSize"])) {
          unitUOMKeys.officeSizeUOM = uom;
        }
        if (form.getFieldValue(["unitInfo", index, "mezzanineSpace"])) {
          unitUOMKeys.mezzanineSpaceUOM = uom;
        }
        _unitInfo[index] = { ..._unitInfo[index], ...unitUOMKeys };
      });

      form.setFieldsValue({ buildingInfo: { ..._buildingInfo, ..._uomKeys }, unitInfo: _unitInfo });
    }
  }, [uom]);

  useEffect(() => {
    // To disable Publish Button
    const _property = selectedProperty || selected;
    if (isEditView && !_isEmpty(_property) && disabledStatus) {
      form.validateFields([["buildingInfo", "propertyCode"]]);
    }
  }, [form, isEditView, selectedProperty, selected]);

  useEffect(() => {
    (async () => {
      if (!_isEmpty(selected) && !loading && !isPopulated) {
        await setFormValues();
        if (history?.location?.state?.translateLang) {
          _onLanguageChange(history?.location?.state?.translateLang);
        } else {
          const payload = {};
          _forEach(language?.secondary, _lang => {
            if (_isEmpty(_get(selected, `translations.${_lang}`, {}))) {
              payload[_lang] = false;
            } else {
              payload[_lang] = true;
            }
          });
          setIsTranslated(payload);
        }
        setIsPopulated(true);
      }
    })();
  }, [selected, history, language.secondary, isPopulated]);

  useEffect(() => {
    (async () => {
      if (!_isEmpty(unitDetail)) {
        await setUnitFormValues();
        dispatch(unitDetailSuccess({}));
      }
    })();
  }, [unitDetail]);

  useEffect(() => {
    if (!_isEmpty(countryAddressFields)) {
      setCountryAddressFormFields(form, countryAddressFields, "buildingInfo");
    }
  }, [countryAddressFields, form]);

  const clearStoreState = () => {
    dispatch(propertyCodesSuccess([]));
    dispatch(buildingSuccess({}));
    dispatch(buildingCodeSuccess({}));
    dispatch(propertyMediaSuccess([])); // to clear current state
    dispatch(publishedUnitsSuccess([]));
    dispatch(buildingUOMSuccess([]));
    dispatch(propertyAddressFieldsListSuccess([]));
    dispatch(totalBuildingSizeSuccess(undefined));
    dispatch(buildingOccupiedSpaceSuccess(undefined));
    dispatch(auditSuccess([]));
    dispatch(setLanguage());
    dispatch(getAllPropertyRollUpSuccess([]));
    if (FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess([]));
  };

  const fetchCountryAddressFields = args => {
    const country = _get(args, "country", "");
    const subMarkets = _get(args, "subMarkets", []);
    updateCountryAddressFields(country, allCountryAddressFields, fields => {
      setCountryAddressFields({ ...fields });
      if (!viewOnlyMode && FEATURE_FLAG.SUBMARKET?.status) dispatch(activePropertySubMarketsSuccess(subMarkets));
    });
  };

  /**
   * Function for open building publish modal on audit pass button
   */
  const openModalByAuditPass = () => {
    setIsCallByAudit(true);
    setIsModalOpen(true);
  };

  /**
   * Function for generating a payload
   * and invoking the API for creating and updating buildings
   */
  const onFormFinish = async newStatus => {
    let values = { ...form.getFieldsValue(true) };

    const { contacts } = values;
    const { marketId, subMarketId, latitude, longitude } = values.buildingInfo;

    if (contacts?.prologisContact?.value) {
      contacts.prologisContact = contacts?.prologisContact?.value;
    }

    contacts.esmContact = contacts?.esmContact?.value;

    if (contacts.secondaryContacts?.length > 0) {
      contacts.secondaryContacts = [...contacts.secondaryContacts.map(_secondaryContact => _secondaryContact.value)];
    }

    values = {
      ...values,
      id: values?.buildingInfo?.propertyCode?.value,
      status: newStatus,
      market: marketId,
      subMarket: subMarketId,
      latitude: Number(latitude),
      longitude: Number(longitude),
      isTemp: isTemporaryProperty,
      isDraft: false,
      buildingInfo: {
        ...values.buildingInfo,
        propertyCode: values?.buildingInfo?.propertyCode?.value,
        propertyType: PROPERTY_TYPE_KEYS.building,
        availableDate: values?.buildingInfo?.availableDate
          ? dayjs(values.buildingInfo.availableDate).valueOf()
          : undefined,
        minSpaceAvailable: +values?.buildingInfo?.minSpaceAvailable,
        maxSpaceAvailable: +values?.buildingInfo?.maxSpaceAvailable,
        totalBuildingSize: +values?.buildingInfo?.totalBuildingSize,
        truckCourtDepth: values?.buildingInfo?.truckCourtDepth
          ? Number(values?.buildingInfo?.truckCourtDepth).toFixed(4)
          : "0",
        propertyRollUp: values?.buildingInfo?.propertyRollUp?.value,
      },
      contacts,
      media: mapMediaContentWithAlt(_files, false, form),
      unitInfo: values.unitInfo.reduce((prev, next) => {
        let _units;
        let _unit;
        /**
         * identifying merge units
         */
        if (next.unitCode.length > 1) {
          _units = next.unitCode.map(u => {
            const unitStatus = unitsToPublish.includes(next.ghostUnitCode) ? PROPERTY_STATUS.published : next.status;
            const isUnitBeingPulbished = unitsToPublish.includes(next.unitCode[0].value);

            _unit = {
              ...next,
              id: getUnitLabel(u.label),
              unitId: u.value,
              unitCode: getUnitLabel(u.label),
              status: unitStatus,
              mainBreakerSize: next.mainBreakerSize ? +next.mainBreakerSize : undefined,
              minSpaceAvailable: next.minSpaceAvailable ? +next.minSpaceAvailable : undefined,
              minSpaceAvailableUOM: next.minSpaceAvailable ? next.minSpaceAvailableUOM : undefined,
              maxSpaceAvailable: next.maxSpaceAvailable ? +next.maxSpaceAvailable : undefined,
              maxSpaceAvailableUOM: next.maxSpaceAvailable ? next.maxSpaceAvailableUOM : undefined,
              officeSize: next.officeSize ? +next.officeSize : undefined,
              officeSizeUOM: next.officeSize ? next.officeSizeUOM : undefined,
              mezzanineSpace: next.mezzanineSpace ? +next.mezzanineSpace : undefined,
              mezzanineSpaceUOM: next.mezzanineSpace ? next.mezzanineSpaceUOM : undefined,
              clearHeight: next.clearHeight ? +Number(next.clearHeight).toFixed(4) : 0,
              clearHeightUOM: next.clearHeight ? next.clearHeightUOM : undefined,
              gradeLevelDoors: next.gradeLevelDoors ? +next.gradeLevelDoors : 0,
              dockHighDoors: next.dockHighDoors ? +next.dockHighDoors : 0,
              initialPublishDate: next.initialPublishDate ? dayjs(next.initialPublishDate).valueOf() : undefined,
              lastPublishDate: next.lastPublishDate ? dayjs(next.lastPublishDate).valueOf() : undefined,
              lastUnpublishDate: next.lastUnpublishDate ? dayjs(next.lastUnpublishDate).valueOf() : undefined,
              unitMarketingCollateral: _mapValues(next.unitMarketingCollateral, e => e || ""),
              combinedUnits: next.unitCode.map(_code => _code.value),
              isDraft: !isUnitBeingPulbished && next?.isDraft,
            };

            delete _unit.unitOptions;
            delete _unit.label;

            return _unit;
          });
          if (
            _units[0].status === PROPERTY_STATUS.published &&
            !_units[0].isDraft &&
            !_isEmpty(language.secondary) &&
            ((!_isEmpty(_units[0].unitMarketingCollateral) &&
              _some(language.secondary, _lang =>
                _isEmpty(_get(_units[0], `translations.${_lang}.unitMarketingCollateral`, {})),
              )) ||
              (_isEmpty(_units[0].unitMarketingCollateral) &&
                _some(
                  language.secondary,
                  _lang => !_isEmpty(_get(_units[0], `translations.${_lang}.unitMarketingCollateral`, {})),
                )))
          ) {
            toastMessage("error", `${t("ErrorMessages.UnitMediaCollateralVersion")}${_units[0].ghostUnitCode}`);
            setActiveTabKey(_tabs.unitInfo.key);
            throwError(`${t("ErrorMessages.UnitMediaCollateralVersion")}${_units[0].ghostUnitCode}`);
          }
        } else {
          const unitStatus = unitsToPublish.includes(next.unitCode[0].value) ? PROPERTY_STATUS.published : next.status;
          const isUnitBeingPulbished = unitsToPublish.includes(next.unitCode[0].value);

          _unit = {
            ...next,
            id: getUnitLabel(next.unitCode[0].label),
            unitCode: getUnitLabel(next.unitCode[0].label),
            unitId: next.unitCode[0].value,
            status: unitStatus,
            mainBreakerSize: next.mainBreakerSize ? +next.mainBreakerSize : undefined,
            minSpaceAvailable: next.minSpaceAvailable ? +next.minSpaceAvailable : undefined,
            minSpaceAvailableUOM: next.minSpaceAvailable ? next.minSpaceAvailableUOM : undefined,
            maxSpaceAvailable: next.maxSpaceAvailable ? +next.maxSpaceAvailable : undefined,
            maxSpaceAvailableUOM: next.maxSpaceAvailable ? next.maxSpaceAvailableUOM : undefined,
            officeSize: next.officeSize ? +next.officeSize : undefined,
            officeSizeUOM: next.officeSize ? next.officeSizeUOM : undefined,
            mezzanineSpace: next.mezzanineSpace ? +next.mezzanineSpace : undefined,
            mezzanineSpaceUOM: next.mezzanineSpace ? next.mezzanineSpaceUOM : undefined,
            clearHeight: next.clearHeight ? +Number(next.clearHeight).toFixed(4) : 0,
            clearHeightUOM: next.clearHeight ? next.clearHeightUOM : undefined,
            gradeLevelDoors: next.gradeLevelDoors ? +next.gradeLevelDoors : 0,
            dockHighDoors: next.dockHighDoors ? +next.dockHighDoors : 0,
            initialPublishDate: next.initialPublishDate ? dayjs(next.initialPublishDate).valueOf() : undefined,
            lastPublishDate: next.lastPublishDate ? dayjs(next.lastPublishDate).valueOf() : undefined,
            lastUnpublishDate: next.lastUnpublishDate ? dayjs(next.lastUnpublishDate).valueOf() : undefined,
            unitMarketingCollateral: _mapValues(next.unitMarketingCollateral, e => e || ""),
            isDraft: !isUnitBeingPulbished && next?.isDraft,
          };
          if (
            _unit.status === PROPERTY_STATUS.published &&
            !_unit.isDraft &&
            !_isEmpty(language.secondary) &&
            ((!_isEmpty(_unit.unitMarketingCollateral) &&
              _some(language.secondary, _lang =>
                _isEmpty(_get(_unit, `translations.${_lang}.unitMarketingCollateral`, {})),
              )) ||
              (_isEmpty(_unit.unitMarketingCollateral) &&
                _some(
                  language.secondary,
                  _lang => !_isEmpty(_get(_unit, `translations.${_lang}.unitMarketingCollateral`, {})),
                )))
          ) {
            toastMessage("error", `${t("ErrorMessages.UnitMediaCollateralVersion")}${_unit.id}`);
            setActiveTabKey(_tabs.unitInfo.key);
            throwError(`${t("ErrorMessages.UnitMediaCollateralVersion")}${_unit.id}`);
          }

          delete _unit.unitOptions;
          delete _unit.ghostUnitCode;
          delete _unit.label;

          _units = [_unit];
        }
        return [...prev, ..._units];
      }, []),
      editTimestamp,
    };

    if (!values?.buildingInfo?.availableDate) {
      delete values.buildingInfo.availableDate;
    }
    delete values.buildingInfo.marketId;
    delete values.buildingInfo.subMarketId;
    delete values.buildingInfo.latitude;
    delete values.buildingInfo.longitude;

    try {
      if (isEditView && status === PROPERTY_STATUS.never_published && !selected.isDraft) {
        Object.keys(values.translations || {}).forEach(item => {
          values.translations[item].media = mapMediaContentWithAlt(_files, _translatedFiles[item]?.media, form, item);
          values.translations[item].media = removeMediaContent(values.translations[item].media);
        });
        await dispatch(addBuilding(values));
        dispatch(sendAnalyticsEvent(GTAG_EVENTS.PUBLISH_PROPERTY, { propertyCode: values.id }));
      } else {
        Object.keys(values.translations || {}).forEach(item => {
          values.translations[item].media = mapMediaContentWithAlt(_files, _translatedFiles[item]?.media, form, item);
          values.translations[item].media = removeMediaContent(values.translations[item].media);
        });

        const _payload = { ...values };
        delete _payload.createdAt;
        delete _payload.updatedAt;
        delete _payload.lastPublishDate;
        delete _payload.initialPublishDate;
        delete _payload.lastUnpublishDate;
        delete _payload.id;

        /**
         * If the user is an auditor and is performing the publish building,
         * then published and passed the audit otherwise simply published
         */
        if (isCallByAudit) {
          const auditPayload = {
            auditStatus: PROPERTY_AUDIT_STATUS.passed,
          };
          await dispatch(
            buildingPublishAndPassAudit(
              id,
              _payload,
              `${t("AddEditProperty.Building")} ${newStatus} and ${t(
                "AddEditProperty.Audit.AuditStatusUiMapping.AuditPassed",
              )} ${t("AddEditProperty.Successfully")}`,
              auditPayload,
            ),
          );
        } else {
          await dispatch(
            updateBuilding(
              id,
              _payload,
              `${t("AddEditProperty.Building")} ${newStatus} ${t("AddEditProperty.Successfully")}`,
            ),
          );
        }
        dispatch(sendAnalyticsEvent(GTAG_EVENTS.PUBLISH_PROPERTY, { propertyCode: id }));
      }
      history.push(ROUTES.PROPERTIES.path);
    } catch (err) {
      console.log(err);
    } finally {
      setIsCallByAudit(false);
      _onCloseModal();
    }
  };

  /**
   * function to pre-fill form fields for updated unit
   * with data from the API
   */
  const setUnitFormValues = async () => {
    const unitInfo = _get(unitDetail, "source", []);
    const updatedUnitIndex = _get(unitDetail, "index", []);
    const combinedUnits = {};

    _forEach(unitInfo, _unit => {
      if (_unit.ghostUnitCode && combinedUnits[_unit.ghostUnitCode]) {
        combinedUnits[_unit.ghostUnitCode] = {
          ..._unit,
          unitCode: [
            ...combinedUnits[_unit.ghostUnitCode].unitCode,
            { label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId },
          ],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
          isUnmarket: combinedUnits[_unit.ghostUnitCode].isUnmarket || _unit.isUnmarket,
        };
      } else if (_unit.ghostUnitCode && !combinedUnits[_unit.ghostUnitCode]) {
        combinedUnits[_unit.ghostUnitCode] = {
          ..._unit,
          unitCode: [{ label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId }],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
        };
      } else {
        combinedUnits[_unit.unitCode] = {
          ..._unit,
          unitCode: [{ label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId }],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
        };
      }
    });

    const _combinedUnits = _values(combinedUnits).map(_combinedUnit => {
      _combinedUnit = {
        ..._combinedUnit,
        unitOptions: selectedProperty.units.map(_unitOption => {
          if (_combinedUnit.unitCode.some(_unitCode => _unitCode.value === _unitOption.unitId)) {
            _unitOption = {
              ..._unitOption,
              isAvailable: true,
            };
          }
          return {
            isUnmarket: combinedUnits[_unitOption.unitId]?.isUnmarket,
            ..._unitOption,
            value: _unitOption.unitId,
            label: getUnitLabel(_unitOption.unitCode),
          };
        }),
      };

      return _combinedUnit;
    });

    const _unitInfo = form.getFieldsValue().unitInfo || [];
    _unitInfo[updatedUnitIndex] = _get(_combinedUnits, "[0]", {});

    form.setFieldsValue({
      unitInfo: _unitInfo,
    });
  };

  /**
   * function to pre-fill form fields with data from the API
   */
  const setFormValues = async () => {
    const _selected = _cloneDeep(selected);
    const buildingInfo = _get(_selected, "buildingInfo", {});
    const keyFeatures = _get(_selected, "keyFeatures", {});
    const contacts = { ..._selected?.contacts };
    const media = _get(_selected, "media", {});
    const unitInfo = _get(_selected, "unitInfo", []);
    const date = _get(buildingInfo, "availableDate", undefined);
    const availableDate = date ? dayjs(date) : undefined;
    const _isTemp = _get(_selected, "isTemp", false);
    const combinedUnits = {};

    setIsTemporaryProperty(_isTemp);
    if (_isTemp && !viewOnlyMode) dispatch(getPropertyAllMarkets(_isTemp));

    if (!_isEmpty(contacts?.prologisContact)) {
      contacts.prologisContact = {
        ...contacts.prologisContact,
        value: contacts.prologisContact.id,
        label: contacts.prologisContact.displayName,
      };
    }

    if (contacts?.secondaryContacts?.length > 0) {
      contacts.secondaryContacts = [
        ...contacts.secondaryContacts.map(_secondaryContact => ({
          ..._secondaryContact,
          value: _secondaryContact.id,
          label: getContactLabel(_secondaryContact),
        })),
      ];
    }

    if (!_isEmpty(contacts?.esmContact)) {
      contacts.esmContact = {
        ...contacts.esmContact,
        value: contacts.esmContact.id,
        label: contacts.esmContact.displayName,
      };
    }

    setStatus(selected.status);
    setFiles(media, _selected.translations);

    const _notAvailable = { ...notAvailable };

    if (buildingInfo?.truckCourtDepth === "0" || buildingInfo?.truckCourtDepth === "NaN") {
      _notAvailable.truckCourtDepth = true;
      buildingInfo.truckCourtDepth = undefined;
    }

    _forEach(unitInfo, _unit => {
      if (_unit.ghostUnitCode && combinedUnits[_unit.ghostUnitCode]) {
        combinedUnits[_unit.ghostUnitCode] = {
          ..._unit,
          unitCode: [
            ...combinedUnits[_unit.ghostUnitCode].unitCode,
            { label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId },
          ],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
          isUnmarket: combinedUnits[_unit.ghostUnitCode].isUnmarket || _unit.isUnmarket,
        };
      } else if (_unit.ghostUnitCode && !combinedUnits[_unit.ghostUnitCode]) {
        combinedUnits[_unit.ghostUnitCode] = {
          ..._unit,
          unitCode: [{ label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId }],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
        };
      } else {
        combinedUnits[_unit.unitCode] = {
          ..._unit,
          unitCode: [{ label: getUnitLabel(_unit.unitCode), key: _unit.unitId, value: _unit.unitId }],
          officeSizeUOM: filterOption(MEASUREMENT_UNITS, _unit?.officeSizeUOM),
          mezzanineSpaceUOM: filterOption(MEASUREMENT_UNITS, _unit?.mezzanineSpaceUOM),
          clearHeightUOM: filterOption(CLEAR_HEIGHT_MEASUREMENT_UNITS, _unit?.clearHeightUOM),
          minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.minSpaceAvailableUOM),
          maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, _unit?.maxSpaceAvailableUOM),
          keyFeatures: {
            ..._unit.keyFeatures,
            templateFeature1: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature1),
            templateFeature2: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature2),
            templateFeature3: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature3),
            templateFeature4: filterOption(unitKeyFeatureTemplateList, _unit.keyFeatures?.templateFeature4),
          },
        };
      }
    });

    const _combinedUnits = _values(combinedUnits).map(_combinedUnit => {
      _combinedUnit = {
        ..._combinedUnit,
        unitOptions: selectedProperty.units.map(_unitOption => {
          if (_combinedUnit.unitCode.some(_unitCode => _unitCode.value === _unitOption.unitId)) {
            _unitOption = {
              ..._unitOption,
              isAvailable: true,
            };
          }
          return {
            isUnmarket: combinedUnits[_unitOption.unitId]?.isUnmarket,
            ..._unitOption,
            value: _unitOption.unitId,
            label: getUnitLabel(_unitOption.unitCode),
          };
        }),
      };
      const obj = {};
      _forEach(["gradeLevelDoors", "dockHighDoors"], key => {
        obj[key] = false;
        if (!_combinedUnit[key]) {
          obj[key] = true;
          _combinedUnit[key] = undefined;
        }
      });
      _notAvailable.unitInfo.push(obj);

      return _combinedUnit;
    });

    const unitKeys = Object.keys(combinedUnits).filter(_ghostUnitCode => _ghostUnitCode.indexOf("~MRG") !== -1);
    const combinedUnitNumbers = unitKeys.map(_unitKey => +_unitKey.split("~MRG")[1]);
    const maxCombinedUnitNumber = combinedUnitNumbers.length ? Math.max(...combinedUnitNumbers) : 0;
    await dispatch(updateCombinedUnitNumber(maxCombinedUnitNumber));
    form.setFieldsValue({
      // id: selected.id,
      propertyAvailable: selected?.propertyAvailable,
      buildingInfo: {
        ...buildingInfo,
        marketId: selected?.market?.id,
        region: selected?.market?.region?.code,
        propertyRollUp: selected?.buildingInfo?.propertyRollUp?.id
          ? {
            value: selected?.buildingInfo?.propertyRollUp?.id,
            label: selected?.buildingInfo?.propertyRollUp?.id,
          }
          : undefined,
        subMarketId: typeof selected?.subMarket === "object" ? selected?.subMarket?.id : selected?.subMarket,
        latitude: selected?.latitude === 0 ? undefined : selected?.latitude,
        longitude: selected?.longitude === 0 ? undefined : selected?.longitude,
        propertyCode: { label: buildingInfo.propertyCode, value: buildingInfo.propertyCode },
        availableDate,
        minSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, buildingInfo?.minSpaceAvailableUOM),
        maxSpaceAvailableUOM: filterOption(MEASUREMENT_UNITS, buildingInfo?.maxSpaceAvailableUOM),
        totalBuildingSizeUOM: filterOption(MEASUREMENT_UNITS, buildingInfo?.totalBuildingSizeUOM),
        templateId: filterOption(marketingDescriptionTemplateList, buildingInfo?.templateId),
      },
      keyFeatures: {
        ...keyFeatures,
        templateFeature1: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature1),
        templateFeature2: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature2),
        templateFeature3: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature3),
        templateFeature4: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature4),
        templateFeature5: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature5),
        templateFeature6: filterOption(keyFeatureTemplateList, keyFeatures?.templateFeature6),
      },
      contacts: {
        prologisContact: contacts?.prologisContact?.isActive ? contacts.prologisContact : undefined,
        esmContact: contacts?.esmContact?.isActive ? contacts.esmContact : undefined,
        secondaryContacts: contacts.secondaryContacts?.filter(_secondaryContact => _secondaryContact.isActive),
      },
      media: { ...media },
      unitInfo: _combinedUnits,
      translations: { ..._selected.translations },
    });

    setNotAvailable({ ..._notAvailable });
    fetchCountryAddressFields(_selected?.market);
  };

  const saveAndPublish = newStatus => {
    form
      .validateFields()
      .then(async values => {
        const isSitePlanError =
          (!_isEmpty(_files.sitePlan) &&
            _some(language.secondary, _lang => _isEmpty(_get(_translatedFiles, `${_lang}.media.sitePlan`, {})))) ||
          (_isEmpty(_files.sitePlan) &&
            _some(language.secondary, _lang => !_isEmpty(_get(_translatedFiles, `${_lang}.media.sitePlan`, {}))));
        const isPropertyMarketingCollateralError =
          (!_isEmpty(_files.propertyMarketingCollateral) &&
            _some(
              language.secondary,
              _lang =>
                _files.propertyMarketingCollateral.length !==
                removedEmptyObject(_get(_translatedFiles, `${_lang}.media.propertyMarketingCollateral`, {})).length,
            )) ||
          (_isEmpty(_files.propertyMarketingCollateral) &&
            _some(
              language.secondary,
              _lang =>
                _files.propertyMarketingCollateral.length !==
                removedEmptyObject(_get(_translatedFiles, `${_lang}.media.propertyMarketingCollateral`, {})).length,
            ));
        if (_isEmpty(_files.hero)) {
          setActiveTabKey(_tabs.media.key);
          setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });
        } else if (_isEmpty(values.unitInfo)) {
          toastMessage("error", t("ErrorMessages.AtleactOneUnit"));
          setActiveTabKey(_tabs.unitInfo.key);
        } else if (language.secondary && (isSitePlanError || isPropertyMarketingCollateralError)) {
          const _mediaErrors = {};
          if (isSitePlanError) {
            _mediaErrors.sitePlan = "error";
          }
          if (isPropertyMarketingCollateralError) {
            _mediaErrors.propertyMarketingCollateral = "error";
          }
          setMediaErrors(_mediaErrors);
          setActiveTabKey(_tabs.media.key);
          toastMessage("error", t("ErrorMessages.SelectBothTranslatedAndLocal"));
        } else {
          await onFormFinish(newStatus);
          setMediaErrors({});
        }
      })
      .catch(ex => {
        console.log("ex", ex);
        const { errorFields } = ex;
        const currentTabHasError = errorFields?.some(({ name }) => name.indexOf(_tabsKey[activeTabKey]) > -1);
        const _tabKey = _tabs?.[errorFields?.[0]?.name[0]]?.key;
        if (!currentTabHasError) setActiveTabKey(_tabKey);

        if (_isEmpty(_files.hero)) setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });

        toastMessage("error", t("ErrorMessages.RequiredFields"));
      })
      .finally(() => {
        _onCloseModal();
        togglePopupVisibility(false);
      });
  };

  let _title = isEditView ? `${t("AddEditProperty.EditBuilding")} ${id}` : t("AddEditProperty.AddBuilding");
  if (viewOnlyMode) _title = `${t("AddEditProperty.ViewBuilding")} ${id}`;

  const _validateFields = (field, allFields) => {
    const _unitInfo = form.getFieldValue("unitInfo");
    const hasErrors =
      _isEmpty(_unitInfo) ||
      form.getFieldsError().filter(({ name, errors }) => {
        const checkNotAvailable =
          (name[1] === "truckCourtDepth" && notAvailable.truckCourtDepth) ||
          (name.length > 2 &&
            name[2] === "gradeLevelDoors" &&
            notAvailable.unitInfo.length > name[1] &&
            notAvailable.unitInfo[name[1]].gradeLevelDoors) ||
          (name.length > 2 &&
            name[2] === "dockHighDoors" &&
            notAvailable.unitInfo.length > name[1] &&
            notAvailable.unitInfo[name[1]].dockHighDoors);

        if (checkNotAvailable) return false;
        return errors.length;
      }).length;
    let _disabledStatus = false;
    const untouchFields = allFields
      .filter(({ value }) => (!_isNumber(value) && !_isBoolean(value) && _isEmpty(value)) || value === "NaN")
      .map(({ name }) => name);

    const mediaFieldsError = allFields.some(
      ({ value, name }) =>
        (name.includes("media") && !_isNumber(value) && !_isBoolean(value) && _isEmpty(value)) || value === "NaN",
    );

    if (mediaFieldsError) {
      _disabledStatus = true;
    }

    if (hasErrors) {
      _disabledStatus = true;
    } else {
      const _requiredFields = [...requiredFields];
      _requiredFields.push(...requiredTranslationFields);

      // Validate only in regular property
      if (!notAvailable.truckCourtDepth) {
        _requiredFields.push(["buildingInfo", "truckCourtDepth"]);
      }
      _forEach(notAvailable.unitInfo, val => {
        if (!val.gradeLevelDoors) {
          _requiredFields.push(["unitInfo", "gradeLevelDoors"]);
        }
        if (!val.dockHighDoors) {
          _requiredFields.push(["unitInfo", "dockHighDoors"]);
        }
      });

      for (let index = 0; index < untouchFields.length; index += 1) {
        if (
          _requiredFields.some(val =>
            val[0] !== "translations"
              ? val[0] === untouchFields[index][0] && val[1] === untouchFields[index][untouchFields[index].length - 1]
              : untouchFields[index][0] !== _tabsKey[5] &&
                val[1] === untouchFields[index][2] &&
                val[val.length - 1] === untouchFields[index][untouchFields[index].length - 1],
          )
        ) {
          if (untouchFields[index].length > 2 && _get(notAvailable, untouchFields[index])) {
            // eslint-disable-next-line no-continue
            continue;
          }
          _disabledStatus = true;
          break;
        }
      }
    }

    setDisabledStatus(_disabledStatus);
  };

  const _onSelectUnitsToPublish = selectedUnits => {
    const _unitsToPublish = _map(publishedUnits, _publishedUnit => {
      if (_publishedUnit.unitCode.length > 1) {
        return _publishedUnit.ghostUnitCode;
      }
      return _publishedUnit.unitCode[0]?.value;
    });
    setUnitsToPublish([..._unitsToPublish, ...selectedUnits]);
  };

  const _onCloseModal = () => {
    setIsModalOpen(false);
    setIsCallByAudit(false);
    _onSelectUnitsToPublish([]);
  };

  const _onCloseLangModal = () => {
    setIsLangModalOpen(false);
    dispatch(setLanguage({ current: LANGUAGE_CODES.English.value, secondary: language.secondary }));
  };

  const _onClickTranslate = () => {
    history.push({ pathname: `${ROUTES.EDIT_BUILDING.path}/${id}`, state: { translateLang: language.current } });
  };

  /**
   * To filter out those units that have draft version or newly added to a building
   */
  const nonPublishedUnits = useMemo(
    () => _filter(form.getFieldValue("unitInfo"), u => u?.status !== PROPERTY_STATUS.published || u?.isDraft),
    [form.getFieldValue("unitInfo")],
  );

  const getFieldsError = (key, _lang) =>
    form.getFieldsError().filter(({ name, errors }) => {
      const checkNotAvailable =
        (name[1] === "truckCourtDepth" && notAvailable.truckCourtDepth) ||
        (name.length > 2 &&
          name[2] === "gradeLevelDoors" &&
          notAvailable.unitInfo.length > name[1] &&
          notAvailable.unitInfo[name[1]].gradeLevelDoors) ||
        (name.length > 2 &&
          name[2] === "dockHighDoors" &&
          notAvailable.unitInfo.length > name[1] &&
          notAvailable.unitInfo[name[1]].dockHighDoors);

      if (checkNotAvailable) return false;

      let errorFlag =
        (_lang === LANGUAGE_CODES.English.value ? name[0] === key : name[1] === _lang && name[2] === key) &&
        errors.length;
      if (key === _tabsKey[5]) {
        if (_lang !== LANGUAGE_CODES.English.value) {
          errorFlag = name[0] === key && name[3] === _lang && errors.length;
        } else {
          errorFlag = name[0] === key && name.length <= 3 && errors.length;
        }
      }
      return errorFlag;
    }).length;

  const getKeysToValidate = (_lang, key, fields) => {
    const _requiredFields = [...fields];

    if (!notAvailable.truckCourtDepth) {
      _requiredFields.push(["buildingInfo", "truckCourtDepth"]);
    }

    if (_lang === LANGUAGE_CODES.English.value) {
      return [..._requiredFields.filter(val => val[0] === key)];
    }
    _requiredFields.push(...requiredTranslationFields);

    return _reduce(
      _requiredFields,
      (prev, curr) => {
        if (curr[0] === key && _lang === LANGUAGE_CODES.English.value) {
          prev.push(curr);
        } else if (curr[1] === key) {
          prev.push([...curr.slice(0, 1), _lang, ...curr.slice(1)]);
        } else if (curr[2] === key) {
          prev.push(curr);
        }
        return prev;
      },
      [],
    );
  };

  const onTabChange = async (acKey, lang, _state) => {
    if (!viewOnlyMode) {
      const _lang = lang || language.current;
      const _errorTabs = _state || errorTabs;
      let err = true;
      let error;
      const key = _tabsKey[activeTabKey];
      const hasErrors = getFieldsError(key, _lang);
      const unitKeys = unitInfoKeys;
      const _requiredFields = [...requiredFields];

      if (isUSProperty(selected.market)) {
        _requiredFields.push(["buildingInfo", "address", "state"], ["buildingInfo", "address", "city"]);
      }

      let keysToValidate = [];
      if (key === _tabsKey[5]) {
        const _unitInfo = form.getFieldValue("unitInfo");
        if (_isEmpty(_unitInfo)) {
          err = false;
        } else {
          _forEach(_unitInfo, (v, i) => {
            _map(unitKeys, v1 =>
              v1 === "marketingUnitName" && _lang !== LANGUAGE_CODES.English.value
                ? keysToValidate.push(["unitInfo", i, "translations", _lang, v1])
                : keysToValidate.push(["unitInfo", i, v1]),
            );
          });

          _forEach(notAvailable.unitInfo, (val, i) => {
            if (!val.gradeLevelDoors) {
              keysToValidate.push(["unitInfo", i, "gradeLevelDoors"]);
            }
            if (!val.dockHighDoors) {
              keysToValidate.push(["unitInfo", i, "dockHighDoors"]);
            }
          });
        }
      } else if (key === _tabsKey[4]) {
        const mediaFields = form.getFieldsValue().media || {};

        if (_isEmpty(_files.hero)) {
          setMediaErrors({ hero: t("ErrorMessages.SelectHeroImage") });
        } else if (!_isEmpty(mediaFields)) {
          const mediaFieldsToValidate = [];
          Object.keys(mediaFields).forEach(mediaFieldType => {
            _forEach(mediaFields[mediaFieldType], (field, i) => {
              const mediaField = [];
              if (_lang !== LANGUAGE_CODES.English.value) {
                mediaField.push(["translations", _lang, "media", mediaFieldType, i, "alt_text"]);
              } else {
                mediaField.push(["media", mediaFieldType, i, "alt_text"]);
              }
              mediaFieldsToValidate.push(...mediaField);
            });
          });
          if (mediaFieldsToValidate.length) {
            keysToValidate = getKeysToValidate(_lang, key, [..._requiredFields, ...mediaFieldsToValidate]);
          }
        } else {
          err = false;
        }
      } else {
        keysToValidate = getKeysToValidate(_lang, key, _requiredFields);
      }

      if ((key !== _tabsKey[4] && !hasErrors) || (key === _tabsKey[4] && !_isEmpty(_files.hero) && !hasErrors)) {
        [error] = await to(form.validateFields(keysToValidate));
        if (!error) err = false;
        if (languageStatus === "primary") {
          _errorTabs.primary = {
            ..._errorTabs.primary,
            [key]:
              err &&
              error?.errorFields?.some(
                ({ name }) =>
                  (name[0] !== "translations" && name[0] !== "unitInfo") ||
                  (name[0] === "unitInfo" && name[2] !== "translations"),
              ),
          };
        } else {
          _errorTabs.secondary = {
            ..._errorTabs.secondary,
            [_lang]: {
              ..._errorTabs.secondary[_lang],
              [key]:
                err &&
                error?.errorFields?.some(
                  ({ name }) =>
                    name[0] === "translations" ||
                    (name[0] === "unitInfo" && name[2] === "translations") ||
                    requiredTranslationFields.every(field => field[field.length - 1] !== name[name.length - 1]),
                ),
            },
          };
        }
      } else if (_lang === LANGUAGE_CODES.English.value) {
        _errorTabs.primary = { ..._errorTabs.primary, [key]: err };
      } else {
        _errorTabs.secondary = {
          ..._errorTabs.secondary,
          [_lang]: {
            ..._errorTabs.secondary[_lang],
            [key]: err,
          },
        };
      }
      setErrorTabs({ ..._errorTabs });
    }
    setActiveTabKey(acKey);
    window.location.hash = _tabsKey[acKey];
  };

  const modalTitle = t("AddEditProperty.ModalTitle");
  const langModalTitle = t("AddEditProperty.LangModalTitle");

  const _disablePublish = _isEmpty(unitsToPublish) && !_isEmpty(nonPublishedUnits) && _isEmpty(publishedUnits);

  const getTemplate = (templateList, country, templateId) => {
    const selectedTemplate = templateList.find(item => item.id === templateId);
    return _get(
      selectedTemplate,
      `translations.${country === "Mexico" ? LANGUAGE_CODES.Mexico.value : language.current}.template`,
      selectedTemplate?.template,
    );
  };

  const copyLocalizeFields = lang => {
    const formValues = form.getFieldsValue(true);
    let marketingDescription;
    const templateFeatures = {};
    const country = form.getFieldValue(["buildingInfo", "address", "country"]);

    if (formValues?.buildingInfo?.templateId) {
      marketingDescription = getTemplate(
        marketingDescriptionTemplateList,
        country,
        formValues?.buildingInfo?.templateId,
      );
    }

    _forEach(PROPERTY_KEY_FEATURE_TEMPLATE_KEYS, _value => {
      templateFeatures[_value.fieldKey] =
        getTemplate(keyFeatureTemplateList, country, formValues?.keyFeatures?.[_value.templateKey]) ||
        formValues?.keyFeatures?.[_value.fieldKey];
    });

    form.setFieldsValue({
      unitInfo: formValues.unitInfo?.map(unitInfoItem => {
        const unitTemplateFeatures = {};
        _forEach(KEY_FEATURE_TEMPLATE_KEYS, _value => {
          unitTemplateFeatures[_value.fieldKey] =
            getTemplate(unitKeyFeatureTemplateList, country, unitInfoItem.keyFeatures?.[_value.templateKey]) ||
            unitInfoItem.keyFeatures?.[_value.fieldKey];
        });

        return {
          ...unitInfoItem,
          translations: {
            ...unitInfoItem?.translations,
            [lang]: {
              ...(!_isEmpty(unitInfoItem?.translations) && unitInfoItem?.translations[lang]),
              marketingUnitName: unitInfoItem.marketingUnitName,
              keyFeatures: {
                ...unitInfoItem.keyFeatures,
                ...unitTemplateFeatures,
              },
            },
          },
        };
      }),
      translations: {
        [lang]: {
          buildingInfo: {
            address: {
              streetAddress1: _get(formValues, "buildingInfo.address.streetAddress1", ""),
              streetAddress2: _get(formValues, "buildingInfo.address.streetAddress2", ""),
            },
            marketingDescription: marketingDescription || formValues?.buildingInfo?.marketingDescription,
            marketingPropertyName: formValues.buildingInfo.marketingPropertyName,
            digitalTourUrl: formValues.buildingInfo.digitalTourUrl,
            videoUrl: formValues.buildingInfo.videoUrl,
            micrositeUrl: formValues.buildingInfo.micrositeUrl,
          },
          keyFeatures: {
            ...formValues.keyFeatures,
            ...templateFeatures,
          },
        },
      },
    });
    setIsTranslated({ ...isTranslated, [lang]: true });
  };

  const updateErrorState = prevLang => {
    form.validateFields(["buildingInfo", "propertyCode"]);
    const hasErrors = getFieldsError(_tabsKey[activeTabKey], prevLang);

    let _state = {};

    if (prevLang === LANGUAGE_CODES.English.value) {
      _state = {
        ...errorTabs,
        primary: { ...errorTabs.primary, [_tabsKey[activeTabKey]]: !!hasErrors },
      };
    } else {
      _state = {
        ...errorTabs,
        secondary: {
          ...errorTabs.secondary,
          [prevLang]: {
            ...errorTabs.secondary[prevLang],
            [_tabsKey[activeTabKey]]: !!hasErrors,
          },
        },
      };
    }
    setErrorTabs(_state);
    return _state;
  };

  const checkTabError = (lang, prevLang) => {
    const _state = updateErrorState(prevLang);
    onTabChange(activeTabKey, lang, _state);
  };

  const _onLanguageChange = (lang, prevLang) => {
    if (!isTranslated[lang] && lang !== LANGUAGE_CODES.English.value) {
      if (viewOnlyMode) {
        setIsLangModalOpen(true);
      } else {
        copyLocalizeFields(lang);
      }
    }
    checkTabError(lang, prevLang);
  };

  const buildingFormProps = {
    viewOnlyMode,
    form,
    _tabs,
    status,
    activeTabKey,
    setActiveTabKey,
    errorTabs,
    setErrorTabs,
    onTabChange,
    notAvailable,
    setNotAvailable,
    disabledStatus,
    isTemporaryProperty,
    setLoadingData,
    _setFiles: setFiles,
    t,
    mediaErrors,
    setMediaErrors,
    setIsModalOpen,
    fetchCountryAddressFields,
    countryAddressFields,
    isEditView,
    _files,
    editTimestamp,
    isTranslated,
    marketingDescriptionTemplateList,
    keyFeatureTemplateList,
    setIsPopulated,
    _translatedFiles,
    updateErrorState,
  };

  const getPopupTitle = isUnmarketed => {
    let _titles;
    if (!_isEmpty(language.secondary) && isUnmarketed) {
      _titles = `${t("AddEditProperty.ConfirmPropertyLocalized")}
      ${t("AddEditProperty.UnMarketedPropertyLocalized")}`;
    } else if (!_isEmpty(language.secondary)) {
      _titles = t("AddEditProperty.ConfirmPropertyLocalized");
    } else if (isUnmarketed) {
      _titles = t("AddEditProperty.UnMarketedPropertyLocalized");
    }
    return _titles;
  };

  const renderPublishButton = () => {
    const isUnmarketed = unitsToPublish.some(unitCode => {
      const foundUnit = nonPublishedUnits.find(info => {
        if (unitCode?.indexOf("MRG") !== -1) {
          return info.ghostUnitCode === unitCode;
        }
        return info.unitId === unitCode;
      });

      return foundUnit ? foundUnit.isUnmarket : false;
    });

    return !_isEmpty(language.secondary) || isUnmarketed ? (
      <PopUp
        overlayClassName="add-edit-building__publish-pop-up"
        title={getPopupTitle(isUnmarketed)}
        onConfirm={() => saveAndPublish(PROPERTY_STATUS.published)}
        okText={t("Buttons.Yes")}
        cancelText={t("Buttons.No")}
        disabled={_disablePublish}
        popupVisible={popupVisible}
        onVisibleChange={() => togglePopupVisibility(!popupVisible)}
      >
        <Button disabled={_disablePublish} block type="primary" loading={loading}>
          {t("Buttons.Publish")}
        </Button>
      </PopUp>
    ) : (
      <Button
        block
        type="primary"
        onClick={() => saveAndPublish(PROPERTY_STATUS.published)}
        loading={loading}
        disabled={_disablePublish}
      >
        {t("Buttons.Publish")}
      </Button>
    );
  };

  return (
    <>
      {loadingData ? (
        <div className="edit-form__spin">
          <Spin size="large" tip={t("ErrorMessages.Loading")} />
        </div>
      ) : null}
      <Form
        form={form}
        className="add-edit-building"
        layout="vertical"
        name="nest-messages"
        onFieldsChange={_validateFields}
        initialValues={initialValues}
        validateMessages={VALIDATE_FORM_MESSAGES_TEMPLATE}
      >
        <BuildingFormGroup
          {...buildingFormProps}
          lngCode={LANGUAGE_CODES.English.value}
          propertyStatus={status}
          title={_title}
          onLanguageChange={_onLanguageChange}
          setIsPreviewActive={setIsPreviewActive}
          openModalByAuditPass={openModalByAuditPass}
        />
      </Form>
      <AppModal
        wrapClassName="units-to-publish"
        title={modalTitle}
        width={600}
        minHeight="20vh"
        maxHeight="100vh"
        overFlowY="auto"
        visible={isModalOpen}
        onCancel={_onCloseModal}
        footer={
          <Row justify="center" gutter={24}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Button block disabled={loading} onClick={_onCloseModal}>
                {t("Buttons.Cancel")}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              {renderPublishButton()}
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Checkbox.Group name="unitsToPublish" value={unitsToPublish} onChange={_onSelectUnitsToPublish}>
              <Row gutter={[8, 8]}>
                {_map(nonPublishedUnits, (val, key) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} key={key}>
                    <Checkbox
                      key={key}
                      value={val?.unitCode && (val?.unitCode?.length > 1 ? val.ghostUnitCode : val?.unitCode[0]?.value)}
                    >
                      {val?.unitCode &&
                        (val?.unitCode?.length > 1 ? val.ghostUnitCode : getUnitLabel(val?.unitCode[0]?.label))}
                    </Checkbox>
                  </Col>
                ))}
                {_map(publishedUnits, (val, key) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} key={key}>
                    <Checkbox
                      key={key}
                      value={val?.unitCode && (val?.unitCode?.length > 1 ? val.ghostUnitCode : val?.unitCode[0]?.value)}
                      disabled
                    >
                      {val?.unitCode &&
                        (val?.unitCode?.length > 1 ? val.ghostUnitCode : getUnitLabel(val?.unitCode[0]?.label))}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </AppModal>
      <AppModal
        className="property-preview-modal"
        title={t("PropertyPreview.PropertyPreview")}
        width="100%"
        visible={isPreviewActive}
        onCancel={() => setIsPreviewActive(false)}
        overFlowY="auto"
        footer={null}
        destroyOnClose
        isNotDraggable
      >
        <PropertySearchPreview
          form={form}
          selected={selected}
          media={_files}
          translatedMedia={_translatedFiles}
          propertyType="building"
          employeeList={_employeeList}
          brokerList={_brokerList}
        />
      </AppModal>

      <LangModal
        isVisible={isLangModalOpen}
        title={langModalTitle}
        onClose={_onCloseLangModal}
        onTranslate={_onClickTranslate}
      />
    </>
  );
};

AddEditProperty.propTypes = {
  componentProps: PropTypes.shape({
    viewOnlyMode: PropTypes.bool,
  }),
};

AddEditProperty.defaultProps = {
  componentProps: {},
};

export default AddEditProperty;
